import React, {useContext} from 'react';
import {Form, Formik} from "formik";
import ButtonSubmit from "../../../../../../components/Atomes/Buttons/ButtonSubmit";
import InputLabelErrorMessage from "../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import {SchemaCollaborator} from "./Config/SchemaCollaborator";
import {InitialValuesCollaborator} from "./Config/InitialValuesCollaborator";
import {dataRolesSelectIng} from "../../../../../../utils/UserRolesUtils";
import {dataWLSelectCollaborator} from "../../../../../../utils/UserWLUtils";
import DirRespService from "../../../../../../services/Ing/DirResp/DirRespService";
import {StateRequest} from "../CollaboratorsViewIngDir";
import {dataRegions} from "../../../../../../utils/RegionsUtils";

const FormAddCollaborator = ({setShowPopUp}) => {

	const {setStateRequest} = useContext(StateRequest);

	const onSubmit = async (values, actions) => {
		values.regions = [values.regions.additionalProp1]
		values.roles = [values.roles.additionalProp1]
		values.package = parseInt(values.package)
		values.baseH = parseInt(values.baseH)
		const request = await DirRespService.addCollaborator(values)
		actions.resetForm()
		setShowPopUp(false)
		if (request.request.status === 201) {
			setStateRequest("success add")
		} else {
			setStateRequest("error")
		}
	}

	const dataWL = dataWLSelectCollaborator
	const dataRoles = dataRolesSelectIng

	return (
		<>
			<Formik
				initialValues={InitialValuesCollaborator.Add}
				validationSchema={SchemaCollaborator}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => (
					<Form>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"text"} label={"Nom"} name={"lastname"}/>
							<InputLabelErrorMessage type={"text"} label={"Prénom"} name={"firstname"}/>
						</div>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"text"} label={"Téléphone"} name={"phone"}/>
							<InputLabelErrorMessage type={"text"} label={"Adresse e-mail"} name={"email"}/>
						</div>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} data={dataRoles}
							                        label={"Rôle"} name={"roles.additionalProp1"}/>
							<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} label={"Niveau de travail"}
							                        name={"wl"} data={dataWL}/>
						</div>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} label={"Base horaire"}
							                        data={[{label: "N/A", value: "0"}, {label: "7", value: "7"}, {
								                        label: "8",
								                        value: "8"
							                        }]} name={"baseH"}/>
							<InputLabelErrorMessage type={"number"} label={"Forfait"} name={"package"}/>
						</div>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"number"} label={"Bonus sur marge"} name={"bonus"}/>
							<InputLabelErrorMessage type={"number"} label={"Objectif chiffre d’affaires mensuel (HT)"}
							                        name={"objective"}/>
						</div>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} label={"Zone géographique"}
							                        data={dataRegions} name={"regions.additionalProp1"}/>
							<InputLabelErrorMessage type={"select"} label={"Genre"} keyLabel={"label"} keyValue={"value"}
							                        name={"gender"} data={[{label: "Homme", value: "M"}, {
								label: "Femme",
								value: "W"
							}, {label: "Non-genrée", value: "NA"}]}/>
						</div>
						<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
						              title={"Ajouter le collaborateur"}/>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormAddCollaborator;
