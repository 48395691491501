import api from "./APIService";
import { ConvertObjectToUrlParams } from "../utils/StringUtils";

const getBookings = async (params) => {
	return await api.getAllData(ConvertObjectToUrlParams("/bookings", params));
}
const postBooking = async (url, data) => {
  return await api.postBody(url, data)
}
const delBookings = async (params) => {
	return await api.getAllData(ConvertObjectToUrlParams("/bookings/admin/remove-blocked", params));
}

const bookingBlockedService = async (data) => {
  return await api.postBody("/bookings/admin/blocked", data)
}

const BookingService = {
  getBookings,
  postBooking, 
  delBookings,
  bookingBlockedService
}

export default BookingService;