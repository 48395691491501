import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { Form, Formik } from 'formik';
import { InitialValuesCustomerAccount } from './Config/InitialValuesCustomerAccount';
import { SchemaCustomerAccount } from './Config/SchemaCustomerAccount';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import ButtonSubmit from '../../../../../../components/Atomes/Buttons/ButtonSubmit';

import api from "../../../../../../services/APIService";
import axios from 'axios';

const ProfileCustomerAccountForm = ({ accountFormData }) => {

	// const [isDifferentAddress, setIsDifferentAddress] = useState(false);
	const [profilePicture, setProfilePicture] = useState(null);
	const [profilePicturePreview, setProfilePicturePreview] = useState(null);

	const [isLoading, setIsLoading] = useState(true);
	const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };
	const [notValidFileErrorMessage, setNotValidFileErrorMessage] = useState(null);

	function isValidFileType(fileName, fileType) {
		return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
	}

	const changeProfilePicture = (event) => {
		if (!isValidFileType(event.target.files[0].name, 'image')) {
			setNotValidFileErrorMessage('Le format de l\'image n\'est pas valide');
			return;
		}
		if (event.target.files[0].size > 15000000) {
			setNotValidFileErrorMessage('La taille de l\'image ne doit pas dépasser 15Mo');
			return;
		}
		setProfilePicture(event.target.files[0]);
		setProfilePicturePreview(URL.createObjectURL(event.target.files[0]));
	};

	const getUrlAvatar = () => {
		let avatar = localStorage.getItem("avatar").replace(/"/g, "")
		setProfilePicture(avatar);
		setIsLoading(false);
	}

	const setInitialValues = () => {
		if (accountFormData) {
			InitialValuesCustomerAccount.gender = accountFormData.gender;
			if (accountFormData.gender === "S") {
				InitialValuesCustomerAccount.agencyName = accountFormData.agencyName;
			}
			InitialValuesCustomerAccount.firstname = accountFormData.firstname;
			InitialValuesCustomerAccount.lastname = accountFormData.lastname;
			InitialValuesCustomerAccount.email = accountFormData.email;
			InitialValuesCustomerAccount.phone = accountFormData.phone;
			InitialValuesCustomerAccount.address = accountFormData.billingAddress.street;
			InitialValuesCustomerAccount.postalCode = accountFormData.billingAddress.postalCode;
			InitialValuesCustomerAccount.city = accountFormData.billingAddress.city;
			InitialValuesCustomerAccount.addressComplement = accountFormData.billingAddress.addressComplement;
		}
	}

	useEffect(() => {
		// InitialValuesCustomerAccount.differentAddress = isDifferentAddress;
		getUrlAvatar();
		setInitialValues();
	}, []);
	// }, [isDifferentAddress]);

	const onSubmit = async (values) => {
		let user = JSON.parse(JSON.parse(localStorage.getItem("user")))
		let userId = localStorage.getItem("userId");
		let gender = values.gender;
		let firstname = values.firstname;
		let lastname = values.lastname;
		let email = values.email;
		let phone = values.phone;
		let data = {
			agencyName: values.agencyName,
			gender: gender,
			firstname: firstname,
			lastname: lastname,
			email: email,
			phone: phone,
			billingAddress: {
				street: values.address,
				postcode: parseInt(values.postalCode),
				city: values.city,
				detail: values.addressComplement
			},
			roles: ["ROLE_CLIENT"]
		};
		let request = null;
		request = await api.patch(`clients/${userId}`, data)

		if (request.status === 200) {
			if (profilePicturePreview !== null) {
				const file = new FileReader();
				file.readAsDataURL(profilePicture);
				const requestAvatar = axios.create({
					baseURL: process.env.REACT_APP_PROD_API_URL,
					headers: {
						Authorization: `Bearer ${user ? user.token : ""}`,
						'Content-Type': `multipart/form-data`
					}
				})
				const requestAvatarPatch = await requestAvatar.post(`users/${userId}/avatar`, { file: profilePicture })
				if (requestAvatarPatch.status === 201) {
					localStorage.setItem("avatar", requestAvatarPatch.data.urlAvatar);
					setProfilePicture(requestAvatarPatch.data.urlAvatar);
					window.location.reload();
				}
			}
			toast.success("Vos informations ont bien été modifiées");
		} else {
			toast.error("Une erreur est survenue");
		}


	};

	return (
		<div className='mbe-profile-container mbe-section'>
			<h3 className='color-primary'>Mes informations personnelles</h3>
			<Formik
				enableReinitialize={true}
				initialValues={InitialValuesCustomerAccount}
				validationSchema={SchemaCustomerAccount}
				onSubmit={onSubmit}
			>
				{({ isSubmitting, values }) => (
					<Form>
						<div className={"form-row-element mbe-profile-form-picture"}>
							<label
								htmlFor='profilePicture'
							>
								{isLoading ?
									(
										<Skeleton circle={true} height={120} width={120} />
									) : (
										<img
											src={profilePicturePreview ? profilePicturePreview : `${process.env.REACT_APP_PROD_URL}/${profilePicture}`}
											alt='profilePicture' />
									)
								}
							</label>
							<input type='file' name='profilePicture' id='profilePicture' accept="image/*"
								onChange={changeProfilePicture} />
							<p className='color-red text-primary'>{notValidFileErrorMessage}</p>
						</div>
						<div className={"form-row-element"}>
							<InputLabelErrorMessage
								keyLabel={"label"}
								label="Civilité"
								keyValue={"value"}
								name="gender"
								type="select"
								data={[
									{ label: "Monsieur", value: "M" },
									{ label: "Madame", value: "W" },
									{ label: "Mademoiselle", value: "Miss" },
									{ label: "Société", value: "S" },
									{ label: "Non-genrée", value: "NA" }
								]}
							/>
						</div>

						{
							values.gender === "S" && (
								<div className={"form-row-element"}>
									<InputLabelErrorMessage
										label="Raison sociale"
										name="agencyName"
										type="text"
									/>
								</div>
							)
						}

						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage
								label="Nom"
								name="lastname"
								type="text"
							/>
							<InputLabelErrorMessage
								label="Prénom"
								name="firstname"
								type="text"
							/>
						</div>

						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage
								label="Adresse e-mail"
								name="email"
								type="text"
							/>
							<InputLabelErrorMessage
								label="Téléphone"
								name="phone"
								type="text"
							/>
						</div>

						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage
								label="Adresse de facturation"
								name="address"
								type="text"
							/>
							<InputLabelErrorMessage
								label="Ville de facturation"
								name="city"
								type="text"
							/>
						</div>

						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage
								label="Code postal de facturation"
								name="postalCode"
								type="text"
							/>
							<InputLabelErrorMessage
								label="Complément d'adresse de facturation"
								name="addressComplement"
								type="text"
							/>
						</div>

						{/* <div className={"form-row-element form-row-two-elements"}>
              <InputLabelErrorMessage
                label="Adresse du projet"
                name="address"
                type="text"
              />
              <InputLabelErrorMessage
                label="Ville du projet"
                name="city"
                type="text"
              />
            </div>

            <div className={"form-row-element form-row-two-elements"}>
              <InputLabelErrorMessage
                label="Code postal du projet"
                name="postalCode"
                type="text"
              />
              <InputLabelErrorMessage
                label="Complément d'adresse du projet"
                name="addressComplement"
                type="text"
              />
            </div> */}

						{/* <div className='mbe-profile-form-choice'>
              <p className={"text-primary color-dark-grey"}>L'adresse de facturation est différente que l'adresse du projet ?</p>
              <div className='mbe-profile-form-choice-inputs'>
                <div>
                  <input type="radio"
                    name='differentAddress'
                    id="differentAddress-yes"
                    defaultChecked={isDifferentAddress}
                    onClick={() => setIsDifferentAddress(true)}
                    value={isDifferentAddress}
                  />
                  <label
                    htmlFor="differentAddress-yes"
                    className={"text-primary color-primary"}>Oui</label>
                </div>
                <div>
                  <input type="radio"
                    name='differentAddress'
                    id="differentAddress-no"
                    defaultChecked={!isDifferentAddress}
                    onClick={() => setIsDifferentAddress(false)}
                    value={isDifferentAddress}
                  />
                  <label
                    htmlFor="differentAddress-no"
                    className={"text-primary color-primary"}>Non</label>
                </div>
              </div>
            </div> */}

						{/* {isDifferentAddress && (
              <>
                <div className={"form-row-element form-row-two-elements"}>
                  <InputLabelErrorMessage
                    keyLabel={"label"}
                    label="Civilité"
                    keyValue={"value"}
                    name="billingGender"
                    type="select"
                    data={[
                      { label: "Monsieur", value: "M" },
                      { label: "Madame", value: "W" },
                      { label: "Société", value: "S" },
                      { label: "Non-genrée", value: "NA" },
                    ]}
                  />
                  <InputLabelErrorMessage
                    label="Société (optionnel)"
                    name="billingCompany"
                    type="text"
                  />
                </div>

                <div className={"form-row-element form-row-two-elements"}>
                  <InputLabelErrorMessage
                    label="Nom"
                    name="billingLastname"
                    type="text"
                  />
                  <InputLabelErrorMessage
                    label="Prénom"
                    name="billingFirstname"
                    type="text"
                  />
                </div>

                <div className={"form-row-element form-row-two-elements"}>
                  <InputLabelErrorMessage
                    label="Adresse de facturation"
                    name="billingAddress"
                    type="text"
                  />
                  <InputLabelErrorMessage
                    label="Ville du projet"
                    name="billingCity"
                    type="text"
                  />
                </div>

                <div className={"form-row-element form-row-two-elements"}>
                  <InputLabelErrorMessage
                    label="Code postal du projet"
                    name="billingPostalCode"
                    type="text"
                  />
                  <InputLabelErrorMessage
                    label="Complément d'adresse du projet"
                    name="billingAddressComplement"
                    type="text"
                  />
                </div>
              </>
            )} */}

						<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
							title={"Enregistrer"} />
					</Form>
				)}
			</Formik>
		</div>
	)
}

export default ProfileCustomerAccountForm;
