import React from "react";
import ButtonPopupDatepicker from "../../Atomes/Buttons/ButtonPopupDatepicker";
import Select from "../../Atomes/Select/Select";

const FilterTwoSelectsDatepicker =
	({
		 defaultValue,
		 options,
		 firstValue,
		 secondDefaultValue,
		 secondOptions,
		 secondValue,
		 onChange,
		 onSecondChange,
		 onChangeDate,
		 resetDateSelected,
		 date

	 }) => {
		return (
			<div className={"filter-datepicker filter-datepicker-two-selects"}>
				<Select onChange={onChange} styleSelect={"select-custom-secondary"} value={firstValue}
								defaultValue={defaultValue} data={options}/>
				<Select styleSelect={"select-custom-secondary"} onChange={onSecondChange} defaultValue={secondDefaultValue}
								data={secondOptions} value={secondValue}/>
				<ButtonPopupDatepicker date={date} onChange={onChangeDate} resetDateSelected={resetDateSelected}/>
			</div>
		)
	}
export default FilterTwoSelectsDatepicker;
