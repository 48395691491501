import React, {useContext} from "react";
import {StateRequest} from "../../../views/Pages/Admin/Home/Home";
import ProjectService from "../../../services/ProjectService";
import {toast} from "react-toastify";

const PopUpArchiveHomeAdmin = ({id, onClick}) => {
	const {setStateRequest} = useContext(StateRequest);

	const handleSubmit = async () => {
		const request = await ProjectService.ClosingTheRelaunch(id)
		if (request.request.status === 200) {
			setStateRequest("success cloture")
		} else {
			toast.error("Une erreur est survenue")
		}
		onClick()
	}

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"}/>
				<img src={"/images/medias/popup.svg"} alt="img form"/>
				<h4>Voulez-vous vraiment archiver la relance ?</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={() => onClick()}>Non</button>
					<button className={"btn btn-delete"} onClick={(event) => handleSubmit(event)}>Oui</button>
				</div>
			</div>
		</div>
	);
}

export default PopUpArchiveHomeAdmin
