import { useNavigate } from "react-router-dom";

const PopUpExecutionClass = ({id, onClick, approve}) => {

    const navigate = useNavigate()

    return (
        <div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"}/>
				<h4 className={"text-primary color-black"}>Merci de compléter le formulaire de classe d'exécution si vous proposez une solution de renfort en acier. Voulez-vous ignorer la classe d'exécution ?</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={() => navigate(`/dossier-etude/${id}/classe-execution`)}>Non</button>
					<button className={"btn btn-send"} onClick={() => approve()}>Oui</button>

				</div>
			</div>
		</div>
    )
}

export default PopUpExecutionClass;