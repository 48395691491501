import React, {useEffect, useState} from 'react';
import InputSearch from "../../../../../../components/Atomes/Inputs/InputSearch";
import CardCollaborator from "../../../../../../components/Molecules/Cards/Collaborator/CardCollaborator";
import DirRespService from "../../../../../../services/Ing/DirResp/DirRespService";

const MyCollaboratorIngRes = () => {

	const [search, setSearch] = useState("")
	const [collaborators, setCollaborators] = useState([])
	const [allProjectUnasisgned, setAllProjectUnasisgned] = useState([])
	const [updateData, setUpdateData] = useState(false)
	const [updateDataProjects, setUpdateDataProjects] = useState(false)

	const allCompetences = [
		{
			"title": "Ingénieur junior",
			"id": 1,
			"type": "#90CAF9"
		},
		{
			"title": "Structure métallique",
			"id": 2,
			"type": "#FDC510"
		},
		{
			"title": "Structure en bois",
			"id": 3,
			"type": "#FDC510"
		},
		{
			"title": "Structure béton",
			"id": 4,
			"type": "#FDC510"
		},
		{
			"title": "Autocad",
			"id": 5,
			"type": "#FDC510"
		},
		{
			"title": "Autocad LT",
			"id": 6,
			"type": "#FDC510"
		},
		{
			"title": "Robot Structural Analysis",
			"id": 7,
			"type": "#FDC510"
		},
		{
			"title": "Confirmé",
			"id": 8,
			"type": "#FDC510"
		},
		{
			"title": "Diagnostic pathologie du bâtiment",
			"id": 9,
			"type": "#FDC510"
		},
		{
			"title": "Sika CarboDur",
			"id": 10,
			"type": "#FDC510"
		}
	]

	const allRoles = [
		{
			role: "Visite et réalisation du compte rendu",
			id: "1"
		},
		{
			role: "Correcteur vérificateur",
			id: "2"
		},
		{
			role: "Rôle 3",
			id: "3"
		},
		{
			role: "Rôle 4",
			id: "4"
		},
	]

	const getCollaborators = async () => {
		const request = await DirRespService.getAllCollaboratorsHighRoles({
			search: search,
		})
		if (request.status === 200) {
			setCollaborators(request.data["hydra:member"])
		}
	}

	const getUnasisgnedProject = async () => {
		const request = await DirRespService.getAllUnassignedProjects({})
		if (request.status === 200) {
			setAllProjectUnasisgned(request.data["hydra:member"])
		}
	}

	useEffect(() => {
		getCollaborators()
		getUnasisgnedProject()
	}, [search, updateData, updateDataProjects]);

	const test = () => {
		setUpdateData(!updateData)
	}

	const updateDataProjet = () => {
		setUpdateDataProjects(!updateDataProjects)
	}

	return (
		<div className={"my-collaborator-container"}>
			<h3 className="color-primary">Collaborateurs</h3>
			<InputSearch
				onChangeSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
				onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
				placeholder={"Rechercher un collaborateur"}
			/>

			<div className={"my-collaborator-container-list"}>
				{collaborators.map((collaborator, index) => {
						return (
							<CardCollaborator
								updateData={() => test()}
								collaborator={collaborator}
								allCompetences={allCompetences}
								updateDataProjet={() => updateDataProjet()}
								allProjects={allProjectUnasisgned}
								allRoles={allRoles}
								key={index}
							/>
						)
					}
				)}
			</div>

		</div>
	);
};

export default MyCollaboratorIngRes;
