import React, {useState} from 'react';

const PopupProjectStageStudyModification = ({ showPopup, closeAction, validAction }) => {
  return (
    <div className={`project-step-payment__popup pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"pop-up-simple center"}>
        <img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} alt={"cross"} onClick={closeAction} />
        <img className={"pop-up-simple-img"} src='/images/medias/popup.svg' alt='popup forms' />
        <p className={"text-primary color-primary margin-bottom-25"}>
          Vous êtes sur le point de demander des modifications sur le dossier d'étude structure. Voulez-vous continuer ?
        </p>
        <div className={"pop-up-simple-btns"}>
          <button className={"btn-default btn-cancel"} onClick={closeAction}>Annuler</button>
          <button className={`btn-default btn-valid`} onClick={validAction}>Demander des modifications</button>
        </div>
      </div>
    </div>
  )
}

export default PopupProjectStageStudyModification;