import React, {useState} from 'react';
import {AdminService} from '../../../../../../services/AdminService';
import {toast} from 'react-toastify';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from "react-responsive-carousel"; // Import carousel styles

const PopUpWatchDocument = ({images, initialImageIndex, onClose}) => {
	const [currentIndex, setCurrentIndex] = useState(initialImageIndex);

	const downloadFile = (url, filename) => {
		if (url !== undefined && filename !== undefined) {
			AdminService.getFile(url, filename);
			toast.success('Téléchargement en cours');
		} else {
			toast.error('Une erreur est survenue');
		}
	};

	const handleImageChange = newIndex => {
		setCurrentIndex(newIndex);
	};

	return (
		<div className={'pop-up-base-style active'}>
			<div className={'pop-up-show-document-element'}>
				<div className={'pop-up-show-document-element-header'}>
					<h4 className={'color-white text-primary'}>{images[currentIndex].name}</h4>
					<div className={'pop-up-show-document-element-header-actions'}>
						<img
							src={'/images/pictos/download-white.svg'}
							alt={'download'}
							onClick={() =>
								downloadFile(images[currentIndex].downloadLink, images[currentIndex].filename)
							}
						/>
						<a href={images[currentIndex].img} target={'_blank'} rel={'noopener noreferrer'}>
							<img src={'/images/pictos/big-screen-white.svg'} alt={'zoom'}/>
						</a>
						<img src={'/images/pictos/close-white.svg'} alt={'close'} onClick={onClose}/>
					</div>
				</div>
				<Carousel showArrows={true} selectedItem={currentIndex} onChange={handleImageChange}>
					{images.map((image, index) => (
						<div key={index}>
							<img
								src={image.img}
								className={'pop-up-show-document-element-img'}
								alt={`img form ${index}`}
							/>
						</div>
					))}
				</Carousel>
			</div>
		</div>
	);
};

export default PopUpWatchDocument;
