import React from 'react';

const TitleSection = ({title}) => {
	return (
		<div className={"section-title"}>
			<h3 className={"color-primary"}>{title}</h3>
		</div>
	);
};

export default TitleSection;
