import React, { useRef, useState } from 'react';
import moment from 'moment';

import MbeCalendarSidebar from './MbeCalendarSidebar';

import frLocale from '@fullcalendar/core/locales/fr';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';


const MbeCalendar = ({
	tasks, taskManager, taskCreatorFunction, taskEditorFunction,
	withNotifications, notifications, notificationValidator
}) => {

	const calendarRef = useRef();
	const [startDate, setStartDate] = useState(new Date());

	const EventDetail = ({ event }) => {
		return (
			<>
				<div className='mbeCalendar-event-render mbeCalendar-event-render-monthView'
					title={`${event.title}`}
				>
					<span>
						{
							event.extendedProps.type === "task-project-ing-visit" || event.extendedProps.type === "task-project-ing-visit-extra" ? (
								`${event.extendedProps.client} - ${moment(event.startStr).format("HH:mm")} - ${moment(event.endStr).format("HH:mm")}`
							) : (
								moment(event.endStr).diff(moment(event.startStr), 'days') >= 2 ?
									(
										`${moment(event.startStr).format("HH:mm")} - ${moment(event.endStr).format("HH:mm")} - ${event.title}`
									) : (
										`${moment(event.startStr).format("HH:mm")} - ${moment(event.endStr).format("HH:mm")}`
									)
							)
						}
					</span>
				</div>

				<div className='mbeCalendar-event-render mbeCalendar-event-render-notMonthView'
				     title={`${event.title}`}
				>
					<p>
						{
							event.extendedProps.type === "task-project-ing-visit" || event.extendedProps.type === "task-project-ing-visit-extra" ?
								(
									`${moment(event.startStr).format("HH:mm")} - ${moment(event.endStr).format("HH:mm")} ${event.extendedProps.client} ${event.extendedProps.address} ${event.extendedProps.ing}`
								) : (
									`${moment(event.startStr).format("HH:mm")} - ${moment(event.endStr).format("HH:mm")} ${event.title}`
								)
						}
					</p>
				</div>
			</>
		)
	};

	const handleEventClick = (info) => {
		if (info.event.extendedProps.clickable !== false && taskManager) {
		  taskEditorFunction(info);
		} else {
		  info.jsEvent.preventDefault();
		}
	  };

	return (
		<section className='mbeCalendar-container'>
			<MbeCalendarSidebar calendarRef={calendarRef} startDate={startDate} setStartDate={setStartDate}
				withNotifications={withNotifications} notifications={notifications}
				notificationValidator={notificationValidator} />
			<div className='mbeCalendar-container-main'>
				<FullCalendar
					ref={calendarRef}
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					events={tasks}
					dateClick={taskManager ? taskCreatorFunction : null}
					eventClick={handleEventClick}
					eventContent={EventDetail}
					locale={frLocale}
					height='auto'
					allDaySlot={false}
					slotDuration='01:00:00'
					slotMinTime='08:00'
					slotMaxTime='19:00'
					slotLabelInterval={'01:00:00'}
					weekends={true}
					initialView="timeGridWeek"
					views={{
						timeGridWeek: {
							dayHeaderFormat: { weekday: "long", day: "2-digit" }
						},
						timeGridDay: {
							dayHeaderFormat: { weekday: "long", day: "2-digit" }
						},
						dayGridMonth: {
							dayHeaderFormat: { weekday: "long" }
						}
					}}
					headerToolbar={{
						center: 'prev,today,next',
						right: 'timeGridDay,timeGridWeek,dayGridMonth',
						left: 'title',
					}}
				/>
			</div>
		</section>
	);
};

export default MbeCalendar;

