export const initialValuesEditPrestation = {
	title: "",
	price: "",
	zone: "",
	spent: "",
	recommendedPrice: "",
	categoryIri: "",
	description: "",
	reference: "",
}
