import moment from "moment/moment";

export const PARTNER_STAT_COLUMNS = [
	{
		Header: "Date",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = new Date(cellInfo.row.original.createdAt);
			date = moment(date).format("DD/MM/YYYY");
			return date
		}
	},
	{
		Header: "Étape",
		accessor: "stage",
		Cell: cellInfo => {
			return (
				<div className={"statut"}>
					{cellInfo.row.original.stage === "Chatbot non-complété" ? <p className={"orange-info"}>{cellInfo.row.original.stage}</p> : null}
					{cellInfo.row.original.stage === "Chatbot complété" ? <p className={"orange-info"}>{cellInfo.row.original.stage}</p> : null}
					{cellInfo.row.original.stage === "Devis envoyé" ? <p className={"orange-info"}>{cellInfo.row.original.stage}</p> : null}
					{cellInfo.row.original.stage === "Devis signé" ? <p className={"green-info"}>{cellInfo.row.original.stage}</p> : null}
				</div>
			)
		}
	},
{
		Header: "Numéro Client",
		accessor: "client.reference",
},
{
		Header: "Nom client",
		accessor: "client.niceNames",
},
	]