const FileMimeType = ({mimeType}) => {
	let fileMimeTypeInfo = null;

	switch (mimeType) {
		case 'application/pdf':
		case 'application/x-pdf':
		case 'application/acrobat':
			fileMimeTypeInfo = {
				icon: <img src="/images/pictos/mime-types/pdf.svg" alt="pdf icon"/>,
			}
			return fileMimeTypeInfo.icon
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		case 'application/msword':
		case 'application/vnd.oasis.opendocument.text':
			fileMimeTypeInfo = {
				icon: <img src="/images/pictos/mime-types/doc.svg" alt="docx icon"/>,
			}
			return fileMimeTypeInfo.icon
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		case 'application/vnd.ms-excel':
		case 'application/vnd.oasis.opendocument.spreadsheet':
			fileMimeTypeInfo = {
				icon: <img src="/images/pictos/mime-types/xls.svg" alt="xls icon"/>,
			}
			return fileMimeTypeInfo.icon
		case 'image/jpeg':
		case 'image/png':
		case 'image/gif':
		case 'image/svg+xml':
			fileMimeTypeInfo = {
				icon: <img src="/images/pictos/mime-types/img.svg" alt="img icon"/>,
			}
			return fileMimeTypeInfo.icon
		case 'video/mp4':
			fileMimeTypeInfo = {
				icon: <img src="/images/pictos/mime-types/mp4.png" alt="img icon"/>,
			}
			return fileMimeTypeInfo.icon
		default :
			fileMimeTypeInfo = {
				icon: <img src="/images/pictos/mime-types/unknown.svg" alt="unknown icon"/>,
			}
			return fileMimeTypeInfo.icon
	}
}

export default FileMimeType;
