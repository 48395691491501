import React, {useEffect, useState} from 'react';
import ChatLogo from "./Partials/ChatLogo";
import ChatOpen from "./Popup/ChatOpen";
import DirRespService from "../../services/Ing/DirResp/DirRespService";
import {toast} from "react-toastify";
import {useAuth} from "../../services/useAuth";
const Chat = () => {
	const [chatIsOpen, setChatIsOpen] = React.useState(false);
	const [search, setSearch] = useState("");
	const [chat, setChat] = useState([]);
	const [loading, setLoading] = useState(true);

	const [numberUnreadMessageVariable, setNumberUnreadMessageVariable] = useState(0)

	const {userId} = useAuth()

	const [newMessage, setNewMessage]= useState(false)

	const [newClientMessages, setNewClientMessages] = useState("");
	const [newInterneMessages, setNewInterneMessages] = useState("");

	const {role} = useAuth();

	const getAllChat = async () => {
		const request = await DirRespService.getAllChat({search: search})

		if (request.status === 200) {
			setChat(request.data["hydra:member"])

			let chat = request.data["hydra:member"]
			setLoading(false)
			chat.forEach(item => {
				const evenSourceClient = new EventSource(`https://hub.monbureaudetudes.com/.well-known/mercure?topic=${process.env.REACT_APP_PROD_URL}/api/chats/` + item.id + "/" + item.uuid + "/client-messages");
				evenSourceClient.onmessage = event => {
					const temp = JSON.parse(event.data)
					setNewClientMessages(JSON.parse(event.data));
					if(temp.senderId !== userId) {
						setNewMessage(true)
					}
				}

				if (role[0] !== "ROLE_CLIENT") {
					const evenSourceInterne = new EventSource(`https://hub.monbureaudetudes.com/.well-known/mercure?topic=${process.env.REACT_APP_PROD_URL}/api/chats/` + item.id + "/" + item.uuid + "/company-messages");
					evenSourceInterne.onmessage = event => {

						setNewInterneMessages(JSON.parse(event.data));
						const temp = JSON.parse(event.data)
						if(temp.senderId !== userId) {
							setNewMessage(true)
						}

					}
				}

			})
		} else {
			toast.error("Une erreur est survenue lors de la récupération des données")
		}
	}


	useEffect(() => {
		let numberUnreadMessage = chat.filter(item => !item.read).length;
		if (numberUnreadMessage > 0) {
			setNewMessage(true);
		} else {
			setNewMessage(false);
		}
		setNumberUnreadMessageVariable(numberUnreadMessage)

	}, [chatIsOpen, chat]);

	useEffect(() => {
		setLoading(true)
		getAllChat()
	}, [search]);

	return (
		<section className={"section-chat"}>
			{
				!loading ? (<ChatLogo newMessage={newMessage} numberUnreadMessageVariable={numberUnreadMessageVariable} onClick={() => setChatIsOpen(!chatIsOpen)}/>) : null
			}

			{
				chatIsOpen ? (
					<ChatOpen
						chat={chat}
						loading={loading}
						onClickClose={() => setChatIsOpen(!chatIsOpen)}
						onChangeSearch={(event) => (event.key === "Enter" || event.target.value.length === 0) ? setSearch(event.target.value.toLowerCase()) : null}
						onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
						newInterneMessages={newInterneMessages}
						newClientMessages={newClientMessages}
					/>
				) : null
			}
		</section>
	);
};

export default Chat;
