const CardMacroroutine = ({ macroroutine, openPopupDelete, openPopupEdit }) => {
  return (
    <div className="card-macroroutine">
      {
        !macroroutine.mandatory && (
          <img
            className="card-macroroutine__delete"
            src={"/images/pictos/cross-background-red.svg"}
            onClick={() => openPopupDelete(macroroutine.id, macroroutine.title)}
          />
        )
      }

      <span className="card-macroroutine__name text-primary color-white">
        {macroroutine.title}
      </span>

      <div className="card-macroroutine__answer">
        <p className="text-primary color-white">
          <img src={"/images/pictos/help-white.svg"} />
          {macroroutine.triggerTitle}
        </p>
      </div>
      
      {
        macroroutine.chatbotMiniRoutines.map((miniroutine, index) => (
          <div key={index} className="card-macroroutine__miniroutine">
            <span className="text-tertiary color-primary paragraph_bold">
              <img src={"/images/pictos/route-primary.svg"} />
              {miniroutine.title}
            </span>
          </div>
        ))
      }

      {
        !macroroutine.mandatory && (
          <button
            className={"btn-default btn-default-primary card-macroroutine__button"}
            onClick={() => openPopupEdit(macroroutine.id)}
            type={"button"}
          >
            Modifier la macro-routine
          </button>
        )
      }


    </div>
  )
}

export default CardMacroroutine;