const Add = {
  firstname: "",
  lastname: "",
  email: "",
  roles: {
    additionalProp1: "",
  },
  regions: {
    additionalProp1: "",
  },
  wl: "",
  package: "",
  objective: "",
  bonus: "",
  phone: "",
  gender: "",
}

const Edit = {
  firstname: "",
  lastname: "",
  email: "",
  roles: {
    additionalProp1: "",
  },
  regions: {
    additionalProp1: "",
  },
  wl: "",
  package: "",
  objective: "",
  bonus: "",
  phone: "",
  gender: ""
}

export const InitialValuesCollaborator = {
  Add,
  Edit
}
