import {useNavigate} from "react-router-dom";
import {useState} from "react";
import PopUpPartnersAdmin from "../../../../components/Molecules/PopUp/PopUpPartnersAdmin";
import PopUpForm from "../../../../components/Molecules/PopUp/PopUpForm";
import FormEditPartners from "../../../../components/Molecules/Form/Partners/FormEditPartners";

const PartnersActions = ({cellInfo}) => {

	const navigate = useNavigate();
	const [edit, setEdit] = useState(false);
	const [deletePartner, setDeletePartner] = useState(false);
	return (
		<>
			<div className={"actions-controller"}>
				<img onClick={() => navigate(`/liens-partenaires/${cellInfo.id}`)} src={"/images/pictos/stat-blue.svg"}/>
				<img onClick={() => setEdit(true)} src={"/images/pictos/pencil-blue.svg"}/>
				<img onClick={() => setDeletePartner(true)} src={"/images/pictos/red-cross.svg"}/>
			</div>

			{
				deletePartner ? (
					 <PopUpPartnersAdmin onClick={() => setDeletePartner(false)} id={cellInfo.id} identifiant={cellInfo.title}/>
				) : null
			}
			{
				edit ? (
					<PopUpForm title={"Modifier le lien"} large={true} form={<FormEditPartners setIsActive={setEdit} id={cellInfo.id}/>} onClick={() => setEdit(false)} show={edit}/>
				) : null
			}
		</>
	)
}

export default PartnersActions