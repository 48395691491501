import React, { useState, useEffect, useContext } from "react";
import { ChatbotAdminActionsContext } from "../../ChatbotAdmin";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

import { Formik, Form, FieldArray, Field, ErrorMessage } from 'formik';
import { MiniroutineSchema } from './Config/MiniroutineSchema';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import MultiSelect from '../../../../../../components/Atomes/Select/MultiSelect';

import { AdminService } from "../../../../../../services/AdminService";

const PopupEditMiniroutine = ({ miniroutineId }) => {

  const chatbotContext = useContext(ChatbotAdminActionsContext)

  const [miniroutine, setMiniroutine] = useState(null)
  const [miniroutineLoading, setMiniroutineLoading] = useState(true)
  const chatbotCategories = [
    {
      label: "Définition du profil du demandeur",
      value: "mini-routine-profile"
    },
    {
      label: "Type de projet avec étage",
      value: "mini-routine-profile-floors"
    },
    {
      label: "Compléments de document et prestations additionnelles",
      value: "mini-routine-administrative-complement"
    },
    {
      label: "Informations clients",
      value: "mini-routine-administrative-client-info"
    },
    {
      label: "Fin du chatbot",
      value: "mini-routine-administrative-final"
    },
    {
      label: "Autre",
      value: "mini-routine-others"
    }
  ];
  const [chatbotQuestionsOptions, setChatbotQuestionsOptions] = useState([]);

  useEffect(() => {
    getMiniroutine();
  }, []);

  const getMiniroutine = async () => {
    setMiniroutineLoading(true);
    let request = await AdminService.get(`/chatbot-mini-routines/${miniroutineId}`);
    if (request.status === 200) {
      getChatbotQuestionsOptions();
      setMiniroutine(request.data);
    }
  }

  const getChatbotQuestionsOptions = async () => {
    let request = await AdminService.get(`/chatbot-questions?pagination=false`);
    if (request.status === 200) {
      let chatbotQuestionsOptions = request.data['hydra:member'].map((chatbotQuestion) => {
        return {
          label: chatbotQuestion.title,
          value: `/api/chatbotQuestions/${chatbotQuestion.id}`
        }
      });
      setChatbotQuestionsOptions(chatbotQuestionsOptions);
      setMiniroutineLoading(false);
    }
  }

  const submitForm = async (values, setSubmitting) => {
    setSubmitting(true);
    let data = {
      categoryType: values.categoryType,
      title: values.title,
      chatbotQuestionsIri: values.routines.map((routine) => {
        return routine.name
      })
    }
    let request = await AdminService.patch(`/chatbot-mini-routines`, miniroutineId, data);
    if (request.status === 200) {
      toast.success(`La mini-routine a bien été modifiée`);
      getMiniroutine();
      chatbotContext.setChatbotAdminActionsContext({
        "name": "mini-routine"
      });
    } else {
      toast.error(`Une erreur est survenue`);
    }
    setSubmitting(false);
  }

  return (
    <>
      {
        miniroutineLoading ? (
          <div className='adminChatbot-popup__skeleton'>
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
          </div>
        ) : (
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              submitForm(values, setSubmitting);
            }}
            initialValues={{
              categoryType: miniroutine.category.type,
              title: miniroutine.title,
              routines: miniroutine.chatbotQuestions.map((routine, index) => {
                return {
                  id: index + 1,
                  name: `/api/chatbotQuestions/${routine.id}`
                }
              }),
            }}
            validationSchema={MiniroutineSchema}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <div className='form-row-two-elements'>
                  <div className='form-row-label-input'>
                    <label className={"text-micro paragraph_bold color-dark-grey"} htmlFor='categoryType'>Catégorie de la Mini-Routine</label>
                    <Field
                      name={`categoryType`}
                      id={`categoryType`}
                      placeholder='Catégorie de la Mini-Routine'
                      options={chatbotCategories}
                      isMulti={false}
                      component={MultiSelect}
                    />
                    <ErrorMessage name={`categoryType`} component={"p"} className={"color-error text-primary"} />
                  </div>
                  <div className='form-row-element'>
                    <InputLabelErrorMessage
                      name="title"
                      type="text"
                      label="Titre de la Mini-Routine"
                    />
                  </div>
                </div>
                <FieldArray
                  name="routines"
                  render={arrayHelpers => (
                    <div className={`adminChatbot-popup__manyanswers ${values.routines.length > 1 ? 'adminChatbot-popup__manyanswers-true' : ''} `}>
                      {
                        values.routines.map((routine, index) => (
                          <span key={index}>
                            <label className={"text-micro paragraph_bold color-dark-grey"}>Question-Réponse n°{index + 1}</label>
                            <div className='form-row-element' >
                              <div className='adminChatbot-popup__manyanswers-arrows'>
                                {
                                  values.routines.length > 1 && (
                                    <>
                                      <button onClick={() => arrayHelpers.move(index, index - 1)} type='button'>
                                        <img src='/images/pictos/arrow-up-white.svg' />
                                      </button>
                                      <button onClick={() => arrayHelpers.move(index, index + 1)} type='button'>
                                        <img src='/images/pictos/arrow-down-white.svg' />
                                      </button>
                                    </>
                                  )
                                }
                              </div>
                              <div className='form-row-label-input'>
                                <Field
                                  name={`routines.${index}.name`}
                                  id={`routines.${index}.name`}
                                  placeholder='Nom de la Question-Réponse'
                                  options={chatbotQuestionsOptions}
                                  isMulti={false}
                                  component={MultiSelect}
                                />
                              </div>
                              {
                                values.routines.length > 1 && (
                                  <img
                                    className='adminChatbot-popup__manyanswers-delete'
                                    src="/images/pictos/delete-file.svg"
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                )
                              }
                            </div>
                            <ErrorMessage name={`routines.${index}.name`} component={"p"} className={"color-error text-primary"} />
                          </span>
                        ))
                      }
                      <button
                        onClick={() => arrayHelpers.push({ id: values.routines.length + 1, name: '' })}
                        className={"btn-default btn-default-secondary plain"}
                        type='button'>
                        Ajouter une Question-Réponse à la Mini-Routine
                      </button>
                    </div>
                  )}
                />
                <button className={"btn-default btn-default-secondary plain"} disabled={isSubmitting} type='submit'>
                  Modifier la mini-routine
                </button>
              </Form>
            )
            }
          </Formik >
        )
      }
    </>
  )
}

export default PopupEditMiniroutine;