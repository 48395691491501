import React from 'react';
import {Form, Formik} from "formik";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import InputLabelFileErrorMessage from "../../../Atomes/Formik/InputLabelFileErrorMessage";
import {InitialValuesIngPriValidateDoc} from "../../../../config/InitialValues/Engineer/InitialValuesIngPriValidateDoc";
import moment from "moment";
import {toast} from "react-toastify";
import {PriService} from "../../../../services/Engineer/PriService";
import {
	InitialValuesIngPriRefusValidateDoc
} from "../../../../config/InitialValues/Engineer/InitialValuesIngPriRefusValidateDoc";
import {IngPriRefusValidateDocSchema} from "../../../../schemas/Engineer/IngPriRefusValidateDocSchema";

const FormValidatePriRefusClient = ({priProject, setShowPopUpPriRefus, setSubmitForm, idProject}) => {

	const handleSubmit = async (values, actions) => {
		let request = await PriService.postPriFile(values, idProject);
		if (request.status === 201) {
			toast.success("Document ajouté avec succès");
			actions.resetForm()
			setSubmitForm(true)
			setShowPopUpPriRefus(false)
			window.location.href = '/'
		} else {
			toast.error("Une erreur est survenue");
		}
	}

	return (
		<>
			<Formik
				initialValues={InitialValuesIngPriRefusValidateDoc}
				validationSchema={IngPriRefusValidateDocSchema}
				onSubmit={handleSubmit}
			>
				{({isSubmitting}) => (
					<Form className="pop-up-pri-validation-file">
						<div className="pop-up-pri-validation-file-content">
							<h4 className={"color-primary"}>Validation principe de structure (PRI)</h4>
							<p className={"text-primary color-primary"}>
								Le client a refusé le principe de structure, vous trouverez ci-dessous la raison. Nous vous
								invitons à mettre à jour le principe de structure pour le renvoyer au client pour
								validation.
							</p>
							<div className="list-commentaires">
								{
									priProject.priValidation.comments.map((comment, index) => {
										return (
											<div key={index} className="list-commentaires-item">
												<div className="list-commentaires-item-content">
													<p className="text-primary color-primary paragraph_bold">
														{moment(comment.createdAt).format("DD/MM/YYYY")} à {moment(comment.createdAt).format("HH:mm")}
													</p>
													<p className="text-primary color-primary">
														{comment.content}
													</p>
												</div>
											</div>
										)
									})
								}
							</div>
							<div className="form-row-element big-element pop-up-pri-validation-file-content-upload">
								<div className={"form-row-label-input"}>
									<div className={"form-row-element form-row-two-elements input-file-container"}>
										<InputLabelFileErrorMessage
											picture={InitialValuesIngPriValidateDoc.file}
											name={"file"}
										/>
									</div>
								</div>
							</div>
						</div>


						<div className="pop-up-pri-validation-file-button">
							<ButtonSubmit
								title={"Envoyer"}
								isActive={false}
								styleButton={"btn-default btn-default-secondary plain"}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormValidatePriRefusClient;
