import React, { useState, useEffect } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

import ProjectHeader from '../../Partials/ProjectHeader';
import ProjectSectionMarketplace from "../../Partials/SectionMarketplace";

import { ProjectStageService } from '../../../../services/ProjectStageService';

const ProjectStageRefund = () => {

  const { projectId } = useParams();
  const navigate = useNavigate();
  const [projectStepVerificator, setProjectStepVerificator] = useState(false);
  const [project, setProject] = useState(null);

  useEffect(() => {
    getProjectData();
  }, []);

  const getProjectData = async () => {
    let projectInfos = await ProjectStageService.getSingleProject(projectId);
    if (projectInfos.stage !== 'ADMIN_refund') {
      navigate("/projet/" + projectId, { replace: true });
    } else {
      setProjectStepVerificator(true);
      setProject(projectInfos);
    }
  }

  return (
    <>
      {
        !projectStepVerificator ? (
          <>
            <Skeleton height={300} />
            <Skeleton height={40} />
          </>
        ) : (
          <>
            <ProjectHeader
              project={project}
            />

            <div className='project-container'>
              <div className="project-container-step__header">
                <h3 className="color-black">Validation de votre avoir en cours</h3>
                <p className="text-primary">
                  Nous sommes en train de générer votre avoir. Pour que nos équipes du service paiement puissent valider votre avoir, il faut que vous remplissiez vos coordonnées bancaires depuis votre profil. Si vous n’avez pas rempli ses informations, vous pouvez cliquer sur le bouton ci-dessous pour ajouter vos coordonnées bancaires. Le remboursement sera réalisé le dernier jour du mois en cours. Pour toute question complémentaire, merci de vous rapprocher de votre commercial.
                </p>
                <Link to={"/profil#informationsBancaires"} className={`btn-default btn-default-primary project-container-btn`}>
                  Ajouter mon RIB
                </Link>
              </div>

              <ProjectSectionMarketplace />
            </div>
          </>
        )
      }
    </>
  );
}

export default ProjectStageRefund;