import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";
import moment from 'moment';
import { toast } from "react-toastify";

import { Formik, Form } from 'formik';
import { initialValuesAssignment } from '../../../../config/InitialValues/MbeCalendar/InitialValuesAssignment';
import { SchemaAssignment } from '../../../../schemas/Calendar/SchemaAssignment';
import InputLabelErrorMessage from '../../../Atomes/Formik/InputLabelErrorMessage';
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";

import TaskService from '../../../../services/TaskService';
import getUserService from '../../../../services/getUser';

const FormAddAssignment = ({ setShowPopUp, newTask, role }) => {

    const userId = getUserService.getUserId();
    const [isLoading, setIsLoading] = useState(true);
    const [myNewTask, setMyNewTask] = useState(newTask);

    const onSubmit = async (values, actions) => {
        setMyNewTask(newTask);
        let startAt = moment(values.startDate + " " + values.startTime).format('YYYY-MM-DD hh:mm:ss a');
        let endAt = moment(values.endDate + " " + values.endTime).format('YYYY-MM-DD hh:mm:ss a');
        let assignment = {
            "userIri": '/api/users/' + userId,
            "title": values.taskName,
            "startAt": startAt.toString(),
            "endAt": endAt.toString(),
            "categoryType": values.taskType,
            "valid": true
        }

        const request = await TaskService.createAssignment("assignments", assignment)
        setShowPopUp(false)
        actions.resetForm()

        if (request.request.status === 201) {
            toast.success("Tâche ajoutée avec succès")
        } else {
            toast.error("La création de la tâche a échoué")
        }
    }

    const setUpNewTask = () => {
        setMyNewTask(newTask);
        initialValuesAssignment.startDate = moment(newTask.date).format('YYYY-MM-DD');
        initialValuesAssignment.startTime = moment(newTask.date).format('HH:mm');
        setIsLoading(false);
    }

    useEffect(() => {
        setUpNewTask();
    }, [newTask])

    const dataTypes = [
        {
            "label": "Devis",
            "value": "task-project-com-quote",
        },
        {
            "label": "Prospection",
            "value": "task-project-com-prospection",
        },
        {
            "label": "Rendez-vous physique",
            "value": "task-project-com-meeting",
        },
        {
            "label": "Rendez-vous téléphonique",
            "value": "task-project-com-call",
        }
    ]

    const dataTypesIngs = [
        {
            "label": "Rédaction dossier d'étude structure",
            "value": 'task-project-ing-writing',
        },
        {
            "label": "Dimensionnement",
            "value": 'task-project-ing-measure',
        },
        {
            "label": "Projetage",
            "value": 'task-project-ing-projecting',
        },
        {
            "label": "Échange client",
            "value": 'task-project-ing-meeting',
        },
        {
            "label": "Prise de connaissance projet",
            "value": 'task-project-ing-analyse',
        },
        {
            "label": "Visite de suivi de travaux",
            "value": 'task-project-ing-supervision',
        },
        {
            "label": "Demande complémentaire",
            "value": 'task-project-ing-additional-request',
        },
        {
            "label": "Service Après-Vente (SAV)",
            "value": 'task-project-ing-customer-service',
        },
        {
            "label": "Déplacement",
            "value": 'task-project-ing-travel',
        }
    ]

    return (
        <>
            <Formik
                initialValues={initialValuesAssignment}
                validationSchema={SchemaAssignment}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }) => (
                    isLoading ? <Skeleton height={200} /> :
                        <Form>
                            <div className={"form-row-element"}>
                                <InputLabelErrorMessage type={"text"} label={"Nom de la tâche"} name={"taskName"} />
                            </div>
                            <div className={"form-row-element"}>
                                <InputLabelErrorMessage
                                    type={"select"}
                                    keyLabel={"label"}
                                    keyValue={"value"}
                                    data={role === "INGS" ? dataTypesIngs : dataTypes}
                                    label={"Type de tâche"}
                                    name={"taskType"} />
                            </div>
                            <div className={"form-row-element form-row-two-elements"}>
                                <InputLabelErrorMessage type={"date"} label={"Date de début"} name={"startDate"} />
                                <InputLabelErrorMessage type={"time"} label={"Heure de début"} name={"startTime"} />
                            </div>
                            <div className={"form-row-element form-row-two-elements"}>
                                <InputLabelErrorMessage type={"date"} label={"Date de fin"} name={"endDate"} />
                                <InputLabelErrorMessage type={"time"} label={"Heure de fin"} name={"endTime"} />
                            </div>
                            <ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Ajouter la tâche"} />
                        </Form>
                )}
            </Formik>
        </>
    )
}

export default FormAddAssignment;
