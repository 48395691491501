const PopupAddMediaAdmin = ({ showPopup, closeAction, file, errorFile, validAction }) => {
  return (
    <div className={`pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"pop-up-simple center"}>
        <img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} alt={"cross"} onClick={closeAction} />
        <img className={"pop-up-simple-img"} src='/images/medias/popup.svg' alt='popup forms' />
        {
          errorFile != null && errorFile.isError ? (
            <>
              <p className='text-primary color-red'> {errorFile.message} </p>
            </>
          ) : (
            <>
              <span className='text-primary color-primary'>
                Voici le nom du fichier chargé : {file?.media?.name}
              </span>
              <div className={"pop-up-simple-btns"}>
                <button className={"btn-default btn-cancel"} onClick={closeAction}>Annuler</button>
                <button className={"btn-default btn-valid"} onClick={validAction}>Valider</button>
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default PopupAddMediaAdmin;