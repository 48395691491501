import api from "./APIService";

const getCategoriesCollection = async (url) => {
	 return await api.getAllData(url)
}

const getServicePrestation = async () => {
	return await api.getAllData("categories?type=service&order[title]=asc")
}


const CategorieService = {
	getCategoriesCollection,
	getServicePrestation
}

export default CategorieService;
