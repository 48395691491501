import React from "react";
import UsersActions from "./Actions/UsersActions";
import { userRoleTranslate } from "../../../utils/UserRolesUtils";

function convertGenderToGenderString(item) {
	if (item.gender === "M") {
		item.genderString = "Monsieur"
	} else if (item.gender === "W") {
		item.genderString = "Madame"
	} else if (item.gender === "S") {
		item.genderString = "Raison sociale"
	} else if (item.gender === "Miss") {
		item.genderString = "Mademoiselle"
	} else if (item.gender === "NA") {
		item.genderString = "Non genré"
	} else {
		item.genderString = "N/A"
	}
}

function convertRoleToRoleString(item) {
	item.role = userRoleTranslate(item.roles[0])
}

export const USERS_COLUMNS = [
	{
		Header: "Civilité",
		accessor: "genderString",
		Cell: cellInfo => {
			convertGenderToGenderString(cellInfo.row.original)
			return (
				<>
					{cellInfo.row.original.genderString}
				</>
			)
		}
	},
	{
		Header: "Nom client",
		accessor: "lastname",
	},
	{
		Header: "Prénom client",
		accessor: "firstname",
	},
	{
		Header: "Téléphone",
		accessor: "phone",
	},
	{
		Header: "Adresse e-mail",
		accessor: "email",
	},
	{
		Header: "Rôle",
		accessor: "role",
		Cell: cellInfo => {
			convertRoleToRoleString(cellInfo.row.original)
			return (
				<>
					{cellInfo.row.original.role}
				</>
			)
		}
	},
	{
		Header: "Actions",
		accessor: "",
		type: "actions",
		Cell: cellInfo => {
			return (
				<UsersActions cellInfo={cellInfo.row.original} />
			)
		}
	},
]
