export const dataWLSelectCollaborator = [
	{
		label: "N/A",
		value: "N/A"
	},
	{
		label: "WL-1",
		value: "160"
	},
	{
		label: "WL-2",
		value: "120"
	},
	{
		label: "WL-3",
		value: "105"
	},{
		label: "WL-4",
		value: "85"
	},{
		label: "WL-N",
		value: "75"
	}
]