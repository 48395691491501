import api from "./APIService";
import axios from "axios";
import fileDownload from 'js-file-download'
import { ConvertObjectToUrlParams } from "../utils/StringUtils";

const getCustomerInfos = async (id) => {
  return await api.getAllData(`/clients/${id}/`)
}
const getCustomerProjects = async (id, params) => {
  return await api.getAllData(ConvertObjectToUrlParams(`/projects/clients/${id}`, params))
}

const getSingleProject = async (id) => {
  let request = await api.getAllData(`/projects/${id}/context/client`);
  if (request.request.status === 200) {
    return request.data;
  }
};

// medias
const getProjectMedias = async (id) => {
  return await api.getAllData(`/projects/${id}/medias`)
}
const getProjectMedia = async (url, filename) => {
  const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
  axios.get(process.env.REACT_APP_PROD_URL + url, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    responseType: 'blob',
  })
    .then((res) => {
      fileDownload(res.data, filename)
    })

}
const postProjectMedia = async (infos) => {
  let user = JSON.parse(JSON.parse(localStorage.getItem("user")))
  const file = new FileReader();
  file.readAsDataURL(infos.media);
  const media = axios.create({
    baseURL: process.env.REACT_APP_PROD_API_URL,
    headers: {
      Authorization: `Bearer ${user ? user.token : ""}`,
      'Content-Type': `multipart/form-data`
    }
  })
  return await media.post(`/medias`,
    {
      file: infos.media,
      title: infos.media.name,
      projectIri: `/api/projects/${infos.projectId}`,
      categoryType: infos.categoryType
    })
}
const delProjectMedia = async (id) => {
  return await api.del(`/media/${id}`)
}

const getComInfos = async (id) => {
  return await api.getAllData(`/coms/${id}`)
}

const patchComent = async (url, data) => {
  return await api.patch(url, data)
}

export const ComService = {
  getCustomerInfos,
  getCustomerProjects,
  getSingleProject,
  getProjectMedias,
  getProjectMedia,
  postProjectMedia,
  delProjectMedia,
  getComInfos,
  patchComent
}