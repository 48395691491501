import React from 'react';

const TitlePopUpWithCloseArrow = ({title, onClick}) => {
	return (
		<div className={"pop-up-form-container-title"}>
			<h4 className={"color-primary"}>{title}</h4>
			<div onClick={onClick} className={"pop-up-form-container-title-close-button"}>
				<img src={"/images/pictos/cross-close.svg"} alt={"croix qui ferme la modale"}/>
			</div>
		</div>
	);
};

export default TitlePopUpWithCloseArrow;
