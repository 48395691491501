import React, { useState } from 'react';
import PrivateRoutes from "./PrivateRoutes";
import { IsLoggedIn } from "../utils/RolesChecker";
import Chatbot from '../views/Chatbot/Chatbot';
import PublicRoutes from './PublicRoutes';

const Router = () => {

	const [isChatbotUrl, setIsChatbotUrl] = useState(true);

	return (
		<>
			{
				IsLoggedIn() ? (
					<PrivateRoutes />
				) : (
					window.location.pathname === "/chatbot"  ?
						(
							<Chatbot />
						) : (
							<div className={"page-connection"}>
								<PublicRoutes />
							</div>
						)
				)
			}
		</>
	);
};

export default Router;
