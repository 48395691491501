import React, {useContext} from "react";
import {FilterContext} from "../HomeCustomerProjects";

import InputSearch from "../../../../../../components/Atomes/Inputs/InputSearch";
import Select from "../../../../../../components/Atomes/Select/Select";
import {Link} from "react-router-dom";

const HomeCustomerProjectsHeading = ({getSearchValue}) => {

	const projectSortingOptions = [
		"Trier par ville",
		"Trier par typologie de projet",
	];

	const filterContext = useContext(FilterContext);

	return (
		<div className="homeCustomerProjects-heading">
			<h3 className={"color-primary"}>Vos projets</h3>

			<div className="homeCustomerProjects-heading_actions">
				<InputSearch
					placeholder={"Rechercher un projet"}
					name={"SearchProject"}
					onChangeSearch={(event) => event.key === "Enter" ? getSearchValue(event.target.value.toLowerCase()) : null}
					onChangeSearchValue={(event) => event.target.value.length === 0 ? getSearchValue("") : null}
				/>
				<Select
					styleSelect={"select-custom-secondary"}
					defaultValue={"Tous les projets"}
					value={projectSortingOptions}
					data={projectSortingOptions}
					onChange={(event) => filterContext.setFilterSelected(event.target.value)}
				/>
				<Link to={"/chatbot"} className={"btn btn-add-project"}>
					Demande de nouveau projet
					<img src="/images/pictos/add-plus-circle-primary.svg" alt="cross add blue"/>
				</Link>
			</div>
		</div>
	)
}

export default HomeCustomerProjectsHeading;
