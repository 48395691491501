import React from 'react';
import {SortingTable} from "../../../../../components/Molecules/Tables/SortingTable";
import {
	ARCHITECT_QUOTES_IN_PROGRESS_COLUMNS
} from "../../../../../config/ArrayColumns/Architect/QuotesInProgressColumns";

const QuotesInProgress = ({quotes}) => {
	return (
		<div className={"quotes-in-progress"}>
			<h3 className={"color-primary"}>Devis en cours</h3>
			<div className={"section-array-quotes-in-progress"}>
				<SortingTable dataTable={quotes} columnsTable={ARCHITECT_QUOTES_IN_PROGRESS_COLUMNS}/>
			</div>
		</div>
	);
};

export default QuotesInProgress;
