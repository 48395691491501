import React from 'react';
import {SortingTable} from "./SortingTable";
import {Link} from "react-router-dom";

const TableTitle = ({data, columnTable, title, className, link, linkTitle, numberElement}) => {
	return (
		<div className={className}>
			{
				title !== "" ? (
					<div className={"table-title-container"}>

						<h3 className={"color-primary"}>{title}</h3>
						{
							link && linkTitle ? <Link to={link}>{linkTitle}</Link> : ""
						}
					</div>
				) : null
			}

			<div className={`${className}-container`}>
				<SortingTable columnsTable={columnTable} dataTable={data} numberElement={numberElement}/>
			</div>
		</div>
	);
};

export default TableTitle;
