import React, {useContext} from 'react';
import {StateRequest} from "../../../views/Pages/Coms/Com/Home/Home";
import ProjectService from "../../../services/ProjectService";

const PopUpDeleteNewRequest = ({idProject, onClick, clientName}) => {

    const {stateRequest, setStateRequest} = useContext(StateRequest);

    const handleDelete = async () => {
        const request = await ProjectService.DeleteNewRequest(idProject)
        onClick()
        if (request.request.status === 204) {
            setStateRequest('success delete')
        } else {
            setStateRequest('errror')
        }
    }

    return (
        <div className={"pop-up-base-style active"}>
            <div className={"pop-up-user-delete"}>
                <img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"fermer la pop up"}/>
                <img src={"/images/medias/popup.svg"} alt="test"/>
                <h4>
                    Attention, cette action est irréversible. <br/>Voulez-vous supprimer la demande de {clientName} ?
                </h4>
                <div className={"pop-up-user-delete-buttons"}>
                    <button className={"btn btn-cancel"} onClick={onClick}>Non</button>
                    <button className={"btn btn-delete"} onClick={handleDelete}>Oui</button>
                </div>


            </div>
        </div>
    );
};

export default PopUpDeleteNewRequest;