import React, { useState } from "react";
import PopUpForm from "../../../components/Molecules/PopUp/PopUpForm";
import FormEditPrestation from "../../../components/Molecules/Form/Prestations/FormEditPrestation";
import PopUpDeletePrestation from "../../../components/Molecules/PopUp/PopUpDeletePrestation";

const PrestationsActions = ({ cellInfo }) => {
	const [showPopUp, setShowPopUp] = useState(false);
	const [showPopUpDelete, setShowPopUpDelete] = useState(false);

	const DisplayEditModalCollaborator = () => {
		setShowPopUp(!showPopUp)
	}

	const DisplayDeleteModalCollaborator = () => {
		setShowPopUpDelete(!showPopUpDelete)
	}

	return (
		<div className={"actions-controller"}>
			<img onClick={DisplayEditModalCollaborator} src={"/images/pictos/pencil-blue.svg"} alt="pencil blue" />
			<img onClick={DisplayDeleteModalCollaborator} src={"/images/pictos/red-cross.svg"} alt="cross red" />
			{
				showPopUp ? (
					<PopUpForm large={true} title={"Modifier une prestation"} onClick={DisplayEditModalCollaborator} show={showPopUp}
						form={<FormEditPrestation setShowPopUp={setShowPopUp} id={cellInfo.id} />} />
				) : null
			}
			{

				showPopUpDelete ? (
					<PopUpDeletePrestation onClick={() => setShowPopUpDelete(false)} id={cellInfo.id} reference={cellInfo.reference} />
				) : null
			}

		</div>
	)
}
export default PrestationsActions
