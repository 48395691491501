import React from 'react';

const HomeIngProdTaskDetail = () => {
    return (
        <div>
            <p className="text-primary color-primary">Dossier étude structure</p>
        </div>
    );
};

export default HomeIngProdTaskDetail;