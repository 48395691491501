import React from 'react';
import KpiSimpleCard from '../../../../../../components/Atomes/Kpis/KpiSimpleCard';

import TitleSection from "../../../../../../components/Atomes/Title/TitleSection";

const OverviewIngRes = ({
	                        projectsInProgress,
	                        projectsFinished,
	                        projectsWaitingPayment,
	                        projectsInRecovery,
	                        projectsFolderLate
                        }) => {

	return (
		<>
			<TitleSection title={"Tableau de bord"}/>
			<div className={"section-kpi-overview"}>
				<div className="kpi-container-simpleCards">
					<KpiSimpleCard title={"Projets en cours"} value={projectsInProgress.total}/>
					<KpiSimpleCard title={"Dossiers rendu "} value={projectsFinished.total}/>
				</div>
				<div className="kpi-container-simpleCards">
					<KpiSimpleCard title={"Dossiers en attente de paiement final"} value={projectsWaitingPayment.total}/>
					<KpiSimpleCard title={"Projets en recouvrement"} value={projectsInRecovery.total}/>
				</div>
				<div className={"kpi-container-simpleCards"}>
					<KpiSimpleCard title={"Dossiers en retard de rendu"} value={projectsFolderLate.total}/>
				</div>
			</div>
		</>
	);
};

export default OverviewIngRes;
