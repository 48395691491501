import React, {createContext, useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "./useLocalStorage";
import jwt_decode from "jwt-decode";
import api from "./APIService";
import {toast} from "react-toastify";

const AuthContext = createContext();
const API_URL = process.env.REACT_APP_PROD_API_URL;

export const AuthProvider = ({children}) => {
	const [user, setUser] = useLocalStorage("user", null);
	const [role, setRole] = useLocalStorage("role", null);
	const [refreshToken, setRefreshToken] = useLocalStorage("refresh_token", null);
	const [userId, setUserId] = useLocalStorage("userId", null);
	const [avatar, setAvatar] = useLocalStorage("avatar", null);
	const [allInformations, setAllInformations] = useLocalStorage("allInformations", null);
	
	const navigate = useNavigate();
	
	const login = async (data, stayConnected) => {
		const request = await api.postLogin(API_URL + "login", data)
		if (request.request.status === 200){
			window.location.reload();
			if (stayConnected) {
				setRefreshToken(request.data.refresh_token)
			}
			setUser(JSON.stringify(request.data))
			let decrypteToken = jwt_decode(request.data.token)
			setAllInformations(decrypteToken)
			setUserId(decrypteToken.id)
			setRole([decrypteToken.roles[0]])
			setAvatar(decrypteToken.avatar)
		}else{
			toast.error(request.response.data.message)
		}
	};

	const logout = () => {
		setAllInformations(null)
		setUser(null);
		setRole(null);
		setRefreshToken(null)
		setUserId(null)
		setAvatar(null)
		navigate("/", {replace: true});
	};

	const value = useMemo(
		() => ({
			user,
			userId,
			role,
			refreshToken,
			login,
			logout,
			allInformations

		}),
		[user, role, refreshToken]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};
