import api from "./APIService";
import axios from "axios";

const getFirstQuestion = async (partnerToken) => {
  let data = null;
  if (partnerToken) {
    data = {
      partnerToken: partnerToken
    };
  } else {
    data = {}
  }
  const response = await axios.post(process.env.REACT_APP_PROD_API_URL + "chatbot-projects", data , {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

const getNextQuestion = async (id, data) => {
  return await api.patch(`/chatbot-projects/${id}`, data);
}

const getUserToken = async (data) => {
  const request = await axios.post(process.env.REACT_APP_PROD_API_URL + "login", data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return request;
}

const validChatbot = async (id, data, token) => {

  if (token) {
    const axiosCustom = axios.create({
      baseURL: process.env.REACT_APP_PROD_API_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    return await axiosCustom.patch(`/chatbot-projects/${id}/validate`, data)
  } else {
    return await api.patch(`/chatbot-projects/${id}/validate`, data)
  }
}

const postMedias = async (token, infos, projectId) => {
  const file = new FileReader();
  file.readAsDataURL(infos.file);
  const media = axios.create({
    baseURL: process.env.REACT_APP_PROD_API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `multipart/form-data`
    }
  })
  return await media.post(`/medias`,
    {
      file: infos.file,
      title: infos.name,
      projectIri: `/api/projects/${projectId}`,
      categoryType: infos.categoryType ? infos.categoryType : "media-project"
    })
}

export const ChatbotService = {
  getFirstQuestion,
  getNextQuestion,
  getUserToken,
  validChatbot,
  postMedias
}