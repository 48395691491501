import React from 'react';
import ButtonSubmit from "../../Atomes/Buttons/ButtonSubmit";
import exportFileService from "../../../services/ExportFileService";

const TitleWithExport = ({title, url, fileName, fileType, buttonExport}) => {

	const downloadFile = () => {
		exportFileService.exportFile(url, fileName, {}, fileType)
	}


	return (
		<div className='collaborators-tableListingHeading'>
			<h3 className={"color-primary"}>{title}</h3>
			{
				buttonExport === false ? (
				<ButtonSubmit onClick={downloadFile} title={"Exporter"} isActive={false} styleButton={"btn-default btn-default-blue btn-download color-primary"} />
				) : null
			}
		</div>
	);
};

export default TitleWithExport;
