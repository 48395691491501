import * as Yup from "yup";
import moment from "moment";

export const ingTeamSchema = Yup.object().shape({
	ingenieur: Yup.string().required("Veuillez renseigner un Ingénieur Chargé d'Affaires"),
	verificateur: Yup.string().required("Veuillez renseigner un vérificateur"),
	manager: Yup.string().required("Veuillez renseigner un manager"),
	ingenieurHour: Yup.string().required("Veuillez renseigner une durée"),
	verificateurHour: Yup.string().required("Veuillez renseigner une durée"),
	managerHour: Yup.string().required("Veuillez renseigner une durée"),
	ingenieurDateStart: Yup.string().required("Veuillez renseigner une date"),
	ingenieurDateEnd: Yup.string().required("Veuillez renseigner une date").test(
		"is-ingenieurDateEnd-greater-than-or-equal",
		"La date de fin ne peut pas être antérieure à la date de début",
		function (ingenieurDateEnd) {
			const { ingenieurDateStart } = this.parent;
			return moment(ingenieurDateEnd).isSameOrAfter(ingenieurDateStart);
		}
	),
	verificateurDateStart: Yup.string().required("Veuillez renseigner une date"),
	verificateurDateEnd: Yup.string().required("Veuillez renseigner une date").test(
		"is-verificateurDateEnd-greater-than-or-equal",
		"La date de fin ne peut pas être antérieure à la date de début",
		function (verificateurDateEnd) {
			const { verificateurDateStart } = this.parent;
			return moment(verificateurDateEnd).isSameOrAfter(verificateurDateStart);
		}
	),
	managerDateStart: Yup.string().required("Veuillez renseigner une date"),
	managerDateEnd: Yup.string().required("Veuillez renseigner une date").required("Veuillez renseigner une date")
		.test(
			"is-managerDateEnd-greater-than-or-equal",
			"La date de fin ne peut pas être antérieure à la date de début",
			function (managerDateEnd) {
				const { managerDateStart } = this.parent;
				return moment(managerDateEnd).isSameOrAfter(managerDateStart);
			}
		),
})
