import React, {useContext, useState} from 'react';
import ProjectService from '../../../services/ProjectService';
import {StateRequest} from '../../../views/Pages/Ings/Man/Projects/ProjectsListIng'

const PopUpDemandeDeCorrection = ({idProject, onClick}) => {

    const {stateRequest, setStateRequest} = useContext(StateRequest);

    const handleDemandeDeCorrection = async () => {
        const request = await ProjectService.CorrectionRequest(idProject, {validation: false})
        onClick()
        if(request.request.status === 200) {
            setStateRequest("success correction request")
        } else {
            setStateRequest("error")
        }
    }


    return (
        <div className={"pop-up-base-style active"}>
            <div className={"pop-up-user-delete"}>
                <img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"fermer la pop up"}/>
                <img src={"/images/medias/popup.svg"} alt="test"/>
                <h4>
                    Voulez-vous envoyer une demande de correction pour le projet {idProject} ?
                </h4>

                <div className={"pop-up-user-delete-buttons"}>
                    <button className={"btn btn-cancel"} onClick={onClick}>Non</button>
                    <button className={`btn btn-primary`} onClick={handleDemandeDeCorrection}>Oui</button>
                </div>


            </div>
        </div>
    );

}

export default PopUpDemandeDeCorrection;