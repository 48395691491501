import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import ProjectHeader from '../../Partials/ProjectHeader';
import ProjectNav from '../../Partials/ProjectNav';
import ProjectSectionMarketplace from '../../Partials/SectionMarketplace';

import { ProjectStageService } from '../../../../services/ProjectStageService';

const ProjectStageVisiteMarketplace = () => {

  const navigate = useNavigate();
  const { projectId } = useParams();
  const [projectStepVerificator, setProjectStepVerificator] = useState(false);
  const [project, setProject] = useState(null);
  const [projectVisite, setProjectVisite] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectData();
  }, []);

  const getProjectData = async () => {
    let projectInfos = await ProjectStageService.getSingleProject(projectId);
    if (projectInfos.stage === 'ING_report-visit' || ((projectInfos.stage === 'ING_visit' || projectInfos.stage === 'ADMIN_validate-visit') && projectInfos.visit !== null)) {
      setProjectStepVerificator(true);
      setProject(projectInfos);
      setProjectVisite({
        id: projectInfos.visit.id,
        date: moment(projectInfos.visit.startAt).format('DD/MM/YYYY'),
        time: moment(projectInfos.visit.startAt).format('HH:mm') + ' - ' + moment(projectInfos.visit.endAt).format('HH:mm'),
        address: projectInfos.address.street + ' ' + projectInfos.address.postcode + ' ' + projectInfos.address.city,
        inLessThan10days: moment(projectInfos.visit.startAt).diff(moment(), 'days') <= 5 ? true : false
      });
    } else {
      navigate(`/projet/${projectId}/`, { replace: true });
    }
  }

  const editProjectVisit = async () => {
    let request = await ProjectStageService.delProjectVisite(projectVisite.id);
    if (request.status === 204) {
      navigate(`/projet/${projectId}/reserver-un-creneau/`, { replace: true });
    }
  }

  return (
    <>
      {
        !projectStepVerificator ? (
          <>
            <Skeleton height={300} />
            <Skeleton height={40} />
          </>
        ) : (
          <>
            <ProjectHeader
              project={project}
            />

            <section className='project-container'>
              <ProjectNav
                step={2}
                visit={project.visitType}
              />

              {
                  project.stage === 'ADMIN_validate-visit' ? (
                    <div className="project-container-step__header marketplace">
                      <div>
                        <h3>Une demande de visite est en cours pour le {projectVisite.date} de {projectVisite.time} au {projectVisite.address}</h3>
                        <p className="text-primary">Cette demande est soumise à validation par nos équipes. En attendant, vous pouvez consulter nos prestations complémentaires en parcourant notre marketplace.</p>
                      </div>
                    </div>
                  ) : (

                    project.visitType === "off-platform" ? (
                      <div className="project-container-step__header marketplace">
                        <div>
                          <h3>Réservation de visite avec l’ingénieur diagnostiqueur</h3>
                          <p className="text-primary"> L’ingénieur structure va vous contacter pour valider avec vous la date et le créneau de visite à venir. Dès qu’il aura fixé une date et un créneau de visite, les informations relatives à la visite seront affichées sur votre espace personnel. De plus, vous recevrez un e-mail avec toutes les informations relatives à la visite de l’ingénieur diagnostiqueur.</p>
                        </div>
                      </div>
                    ) : (
                      <div className="project-container-step__header marketplace">
                      <div>
                        <h3>Votre visite est prévu le {projectVisite.date} de {projectVisite.time} au {projectVisite.address}</h3>
                        <p className="text-primary">Vous pouvez replanifier votre créneau de visite 5 jours ouvrés avant la date de visite initiale. Si vous avez dépassé cette date limite, vous pouvez contacter votre commercial.</p>
                      </div>
                      <div>
                        {
                          !projectVisite.inLessThan10days && (
                            <button
                              onClick={editProjectVisit}
                              className="btn-default btn-default-primary">
                              Modifier rendez-vous
                            </button>
                          )
                        }
                      </div>
                    </div>
                    )
                  )
              }

              <ProjectSectionMarketplace />

            </section>
          </>
        )
      }
    </>
  );
}

export default ProjectStageVisiteMarketplace;