import React from "react";
import Select from "../../Atomes/Select/Select";

const FilterTwoSelects = ({
    defaultValue, options, firstValue, firstOnChange,
    secondDefaultValue, secondOptions, secondValue, secondOnChange
}) => {
    return (
        <div className={"filter-two-selects"}>
            <Select styleSelect={"select-custom-secondary"} onChange={firstOnChange}
                defaultValue={defaultValue} data={options} value={firstValue} />

            <Select styleSelect={"select-custom-secondary"} onChange={secondOnChange}
                defaultValue={secondDefaultValue} data={secondOptions} value={secondValue} />
        </div>
    )
}

export default FilterTwoSelects;