const PopupMarketplaceCustomer = ({showPopup, validAction, closeAction, titlePartner}) => {
  return (
    <div className={`project-step-payment__popup pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"pop-up-simple center"}>
        <img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} alt={"cross"} onClick={closeAction} />
        <img className={"pop-up-simple-img"} src='/images/medias/popup.svg' alt='popup forms' />
        <p className={"text-primary color-primary"}>
          Acceptez-vous de transmettre vos informations personnelles à {titlePartner} pour qu'il vous recontacte ?
        </p>
        <div className={"pop-up-simple-btns"}>
          <button className={"btn-default btn-cancel"} onClick={closeAction}>Non</button>
          <button className={"btn-default btn-valid"} onClick={validAction}>Oui</button>
        </div>
      </div>
    </div>
  )
}
export default PopupMarketplaceCustomer;