import React, { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper.css';
import moment from "moment";

const MbeBookSlotSlider = ({ bookings, selectBookingSlot, bookingSlotCreator, bookingSlotDeletor, selectedPeriod }) => {
	const navigationPrevRef = useRef(null)
	const navigationNextRef = useRef(null)

	const [bookingsMap, setBookingsMap] = useState([])
	const [bookingSlotSelected, setBookingSlotSelected] = useState(null)

	const mapBookings = () => {
		let allDays = []
		let sortedBookings = bookings.sort((a, b) => {
			if (a.startAt < b.startAt) {
				return -1;
			}
			if (a.startAt > b.startAt) {
				return 1;
			}
		})

		const bookingsByDay = sortBookingsByDay(sortedBookings)
		allDays = Object.entries(bookingsByDay).map(([key, value]) => {
			let day = key.toString().toUpperCase();
			return [
				day,
				...value
			]
		})
		setBookingsMap(allDays)
	}

	function sortBookingsByDay(bookings) {
		return bookings.reduce((acc, booking) => {
			const day = moment(booking.startAt).format('dddd DD MMMM')
			const dayBookings = acc[day] || []
			return {
				...acc,
				[day]: [...dayBookings, booking]
			}
		}, {})
	}

	const isSlotSelected = (slot) => {
		const selectedSlot = selectedPeriod.filter((period) => {
			return period.startAt === slot.startAt && period.endAt === slot.endAt;
		});
		return selectedSlot.length > 0;
	};
	
	useEffect(() => {
		setBookingsMap([])
		mapBookings()
	}, [bookings])

	useEffect(() => {
		if(selectedPeriod){
		if(selectedPeriod.length === 0) {
			setBookingSlotSelected(null)
		} 
	}
	}, [selectedPeriod])

	return (

		<div className="mbe-bookingSlot-slider">
			<div className="mbe-bookingSlot-slider-prevArrow" ref={navigationPrevRef}>
				<img src="/images/pictos/arrow-slider.svg" alt="previous arrow" />
			</div>
			<div className="mbe-bookingSlot-slider-nextArrow" ref={navigationNextRef}>
				<img src="/images/pictos/arrow-slider.svg" alt="next arrow" />
			</div>
			<Swiper
				modules={[Navigation]}
				navigation={{
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}
				onBeforeInit={(swiper) => {
					swiper.params.navigation.prevEl = navigationPrevRef.current;
					swiper.params.navigation.nextEl = navigationNextRef.current;
				}}
				slidesPerView={6}
				slidesPerGroup={6}
				spaceBetween={30}
			>
				{
					bookingsMap.map((day, index) => {
						return (
							<SwiperSlide key={index}>
								<div className="mbe-bookingSlot-slider__week">
									<div className="mbe-bookingSlot-slider__day">
										<span className="color-dark_grey text-tertiary paragraph_bold">{day[0]}</span>
										{
											day.map((slot, index) => {
												{
													if (slot.startAt) {
														return (
															<div key={index}>
																{
																	bookingSlotDeletor && slot.status === "blocked" && (
																		<img
																			{...bookingSlotCreator ? { onClick: (e) => { selectBookingSlot(e); setBookingSlotSelected(slot) } } : {}}
																			src="/images/pictos/red-cross.svg"
																			attr-startat={slot.startAt}
																			attr-endat={slot.endAt}
																			attr-blocked={bookingSlotDeletor && slot.status === "blocked" ? "blocked": "free"}
																		/>
																	)
																}
																<button
																	{...bookingSlotCreator ? { onClick: (e) => { selectBookingSlot(e); setBookingSlotSelected(slot) } } : {}}
																	className={selectedPeriod?.length > 0 ? (isSlotSelected(slot) ? 'selected' : '') : (bookingSlotSelected === slot ? 'selected' : '')}
																	disabled={slot.available === false}
																	attr-startat={slot.startAt}
																	attr-endat={slot.endAt}
																	attr-blocked={bookingSlotDeletor && slot.status === "blocked" ? "blocked": "free"}
																>
																	{moment(slot.startAt).format('HH:mm')} - {moment(slot.endAt).format('HH:mm')}
																</button>
															</div>
														)
													}
												}
											})
										}
									</div>
								</div>
							</SwiperSlide>
						)
					})
				}
			</Swiper>
		</div>
	)
}

export default MbeBookSlotSlider;