import React, { useContext, useEffect, useState } from 'react';
import { initialValuesAddPrestation } from "../../../../config/InitialValues/InitialValuesAddPrestation";
import { AddPrestationSchema } from "../../../../schemas/Prestation/AddPrestationSchema";
import { Form, Formik } from "formik";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import CategorieService from "../../../../services/CategoriesService";
import { toast } from "react-toastify";
import prestationService from "../../../../services/PrestationService";
import { Editor } from "react-draft-wysiwyg";
import draftToHtmlPuri from "draftjs-to-html";
import { convertFromHTML, convertToRaw, EditorState } from "draft-js";
import ContentState from "draft-js/lib/ContentState";

import { StateRequest as StateRequestComDir }  from "../../../../views/Pages/Coms/Dir/Prestations/PrestationsComsDir";
import { StateRequest as StateRequestComRes }  from "../../../../views/Pages/Coms/Res/Prestations/PrestationsComRes";
import {dataRegions} from "../../../../utils/RegionsUtils";

const FormAddPrestation = ({ setShowPopUp }) => {

	const { stateRequest, setStateRequest } = useContext(StateRequestComDir["_currentValue"] !== undefined ? StateRequestComDir : StateRequestComRes);
	const [categories, setCategories] = useState([])
	const [initialValueTitle, setInitialValueTitle] = useState("");
	const getCategories = async () => {
		let request = await CategorieService.getServicePrestation()
		if (request.request.status === 200) {
			setCategories(request.data["hydra:member"])
		} else {
			toast.error("Une erreur est survenue lors de la récupération des catégories")
		}
		setInitialValueTitle("")
	}

	useEffect(() => {
		getCategories()
	}, []);

	const onSubmit = async (values, actions) => {
		values.categoryIri = `/api/categories/${categories.filter(category => category.title === values.categoryIri)[0].id}`
		values.description = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()))
		actions.resetForm()
		const request = await prestationService.createService(values)
		setShowPopUp(false)
		if (request.request.status === 201) {
			setStateRequest("success add")
		} else {
			setStateRequest("error")
		}
	}

	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromBlockArray(
				convertFromHTML(initialValueTitle)
			)
		)
	);

	const handleChangeEditorState = (valueOfEditor) => {
		setEditorState(valueOfEditor)
		let value = draftToHtmlPuri(convertToRaw(valueOfEditor.getCurrentContent()))
		initialValuesAddPrestation.description = value
	}

	return (
		<>
			<Formik
				initialValues={initialValuesAddPrestation}
				validationSchema={AddPrestationSchema}
				onSubmit={onSubmit}
			>
				{({ isSubmitting }) => (
					<Form>
						<div className={"form-row-element"}>
							<div className={"form-row-label-input"}>
								<label className={"text-micro paragraph_bold color-dark-grey"}>Description prestation
									<Editor
										initialEditorState={editorState}
										editorState={editorState}
										wrapperClassName="demo-wrapper"
										localization={{
											locale: 'fr',
										}}
										toolbar={{
											options: ["inline", "colorPicker", 'link', "list", 'fontSize', 'fontFamily', 'textAlign'],
											inline: {
												options: ["bold", "italic", "underline", "strikethrough"],
											},
											colorPicker: {
												colors: ["#000000", "#ffffff", "#090754", "#4AA9C2", "#9DDFF1", "#648DE5", "#c13e39", "#5FD086", "#eed128"],
											}
										}}
										onEditorStateChange={(e) => {
											handleChangeEditorState(e)
										}
										}
									/>
								</label>
							</div>
						</div>
						<div className={"form-row-element form-row-two-elements"}>

							<InputLabelErrorMessage type={"select"} label={"Typologie"} name={"categoryIri"} keyLabel={"title"}
								keyValue={"title"} data={categories} />
							<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} label={"Zone"}
								data={dataRegions} name={"zone"} />
						</div>

						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"number"} label={"Prix déboursé (HT)"} name={"spent"} />
							<InputLabelErrorMessage type={"number"} label={"Marge (HT)"} name={"recommendedPrice"} />
						</div>
						<div className={"form-row-element form-row-two-elements"}>	
							<InputLabelErrorMessage type={"number"} label={"Prix de vente (HT)"} name={"price"}/>
							<InputLabelErrorMessage type={"text"} label={"Code article"} name={"reference"} />
						</div>
						<div className={"form-row-element form-row-two-elements"}>
							
							<InputLabelErrorMessage type={"text"} label={"Titre"} name={"title"} />
						</div>

						<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Ajouter la prestation"} />
					</Form>
				)}
			</Formik>
		</>
	)
}

export default FormAddPrestation;
