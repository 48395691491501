import React, {useContext, useEffect, useState} from 'react';
import {Form, Formik} from "formik";
import FormEditUserAdminContent from "./FormEditUserAdminContent";
import {addUsersSchema} from "../../../../schemas/Admin/Users/AddUsersSchema";
import {dataRolesSelect} from "../../../../utils/UserRolesUtils";
import userService from "../../../../services/UserService";
import {initialValuesEditUser} from "../../../../config/InitialValues/InitialValuesEditUser";
import {StateRequest} from "../../../../views/Pages/Admin/Users/UsersAdmin";

const FormEditUsers = ({id, setIsActive}) => {

	const {updateUser,getSingleUser} = userService


	const { stateRequest, setStateRequest } = useContext(StateRequest);


	/*Patch Edit user */
	const onSubmit = async (values) =>{
		const request = await updateUser(`/users/${id}`, values.user)
		if (request.request.status === 200){
			setIsActive(false)
			setStateRequest("success edit")
		}else{
			setStateRequest("error")
		}
	}

	const [user,setUser] = useState([]);

	/*get User to edit */
	const getUser = async () => {
		const request = await userService.getSingleUser(id)
		if (request.request.status===200){
			setUser(request.data)
		}else{
			console.log(request.request.status)
		}
	}

	useEffect((actions) => {
		setUser([])
		getUser()
	},[id, setIsActive])

	const dataRoles = dataRolesSelect

	return (
		<>
			<Formik
				initialValues={initialValuesEditUser}
				validationSchema={addUsersSchema}
				onSubmit={onSubmit}
			>
				{({ isSubmitting}) => {
					return(
					<Form>
						<FormEditUserAdminContent user={user} dataRoles={dataRoles}/>
					</Form>
				)}}
			</Formik>
		</>
	)
}

export default FormEditUsers;
