import React, {useEffect, useState} from 'react';
import getUserService from "../../../services/getUser";
import Skeleton from "react-loading-skeleton";
import {toast} from "react-toastify";

const TitleHelloUser = () => {

	const [firstname, setFirstname] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const getFirstname = async () => {
		const request = await getUserService.getUser()
		if (request.request.status === 200){
			setFirstname(request.data.firstname)
			setIsLoading(false)
		}else{
			toast.error('Une erreur est survenue lors de la récupération de votre utilisateur')
		}
	}

	useEffect(() => {
		getFirstname()
	})


	return (
		<div className={`title-page`}>
			<h1 className={"color-primary"}><span className={"lighter"}>Bonjour </span> {isLoading ? <Skeleton width={120} height={30}/> : firstname } 👋</h1>
		</div>
	);
};

export default TitleHelloUser;
