import * as Yup from 'yup';

export const ingCollaboratorProjectSchema = Yup.object().shape({
	selectProject: Yup.string().required('Veuillez renseigner un projet'),
	roleAttribue: Yup.string().required('Veuillez renseigner un role'),
	roleHour: Yup.string().required('Veuillez renseigner une durée'),
	commentaireProjectCollaborator: Yup.string(),
	dateStart: Yup.string().required('Veuillez renseigner une date de début'),
	dateEnd: Yup.string().required('Veuillez renseigner une date de fin'),
})
