import {ConvertObjectToUrlParams} from "../utils/StringUtils";
import api from "./APIService";

const getService = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("services", param) : "services")
}

const updateService = async (url, data) => {
	return await api.patch(url, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log(error);
		});
}

const deleteService = async (id) => {
	return await api.del(`/services/${id}`);
}

const createService = async (data) => {
	const request =  await api.postBody("services", data)
	return request
}

const getSingleService = async (id) => {
	const request = await api.getAllData(`services/${id}`)
	return request
}

const PrestationService = {
	getService,
	updateService,
	getSingleService,
	deleteService,
	createService
}
export default PrestationService;