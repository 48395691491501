import {ConvertObjectToUrlParams} from "../utils/StringUtils";
import api from "./APIService";


const patchQuotes = async (id,data)=>
{
	const request = await api.patch(`/projects/${id}/quotes`, data)
	return request
}


const deleteQuote = async (id)=>
{
	const request = await api.del(`/quotes/${id}`)
	return request
}

const deleteOrder = async (id)=>
{
	const request = await api.del(`/orders/${id}`)
	return request
}



const exportQuote = async (id)=>
{
	const request = await api.get(`/exports/quotes/${id}`)
	return request
}


const QuotesService = {
	patchQuotes,
	deleteQuote,
	deleteOrder,
	exportQuote
}

export default QuotesService;
