import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import { LoginValidationSchema } from "../../../../schemas/login/LoginValidationSchema";
import InputCheckBox from "../../../../components/Atomes/Inputs/InputCheckBox";
import { useAuth } from "../../../../services/useAuth";
import { initialValuesLogin } from "../../../../config/InitialValues/initialValuesLogin";
import InputLabelErrorMessage from "../../../../components/Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../../components/Atomes/Buttons/ButtonSubmit";
import 'react-toastify/dist/ReactToastify.css';

const LoginForm = ({ onClick }) => {

	const { login } = useAuth();
	const [stayConnected, setStayConnected] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const onSubmit = (values, actions) => {
		values.username = values.username.toLowerCase()
		login(values, stayConnected)
	}

	const onClickStayConnected = () => {
		setStayConnected(!stayConnected)
	}

	const changeInputType = (e) => {
		e.preventDefault();
		setShowPassword(!showPassword);
	}

	return (
		<>
			<div className="login-form">
				<div className={"login-form-title"}>
					<h4>Connexion</h4>
				</div>
				<div className="login-form-container">
					<Formik initialValues={initialValuesLogin} validationSchema={LoginValidationSchema} onSubmit={onSubmit}>
						{({ isSubmitting }) => (
							<Form>
								<div className={"form-row-element form-row-single-element"}>
									<InputLabelErrorMessage name={"username"} type={"text"}
										label={"Identifiant"} placeholder={"Adresse e-mail"} />
								</div>
								<div className={"form-row-element form-row-element__pwd form-row-single-element"}>
									<InputLabelErrorMessage name={"password"} type={showPassword ? "text" : "password"} label={"Mot de passe"} />
									<img className="login-form__pwdIcon" src="/images/pictos/eyes-blue.svg" alt="blue gear" onClick={changeInputType} />
								</div>
								<div className={"form-row-element form-row-single-element"}>
									<InputCheckBox id={"stayConnected"} name={"stayConnected"} onClick={onClickStayConnected}
										label={"Rester connecté"} />
								</div>
								<ButtonSubmit title={"Connexion"} isActive={false}
									styleButton={"btn-default btn-default-secondary plain btn-default-full-width"} />
							</Form>
						)}
					</Formik>
					<div className={"form-row-element form-row-single-element forgot-password"}>
						<Link to={"/mot-de-passe-oublie"} className={"text-primary color-blue-jeans"}>Mot de passe
							oublié</Link>
					</div>
				</div>
			</div>
		</>
	)
}
export default LoginForm;
