import api from "./APIService";
import {ConvertObjectToUrlParams} from "../utils/StringUtils";

const getCctp = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`cctps`, param))
}

const CctpService = {
	getCctp
}

export default CctpService;