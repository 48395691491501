import React, {useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";

const CardTeamUser = ({pictures, title, alert, link}) => {
	//const [showAlert, setShowAlert] = useState(false)

	const blocImgRef = useRef(null);

	/*let backgroundColorMessage = "";
	let arrowMessage = "";
	if (alert === "majeur"){
			backgroundColorMessage = "#F9564F";
			arrowMessage = "./images/pictos/arrow-message-red.svg";
	} else if(alert === "moyen"){
			backgroundColorMessage = "#FF9B5F";
			arrowMessage = "./images/pictos/arrow-message-orange.svg";
	}*/

	useEffect(() => {
		const blocImg = blocImgRef.current;

		if (blocImg) {
			if (blocImg.childElementCount === 2) {
				blocImg.style.width = "77px";
			} else if (blocImg.childElementCount === 3) {
				blocImg.style.width = "102px";
			} else if (blocImg.childElementCount > 3) {
				blocImg.style.width = "132px";
			}
		}
	}, [pictures]);

	const navigate = useNavigate();

	return (
		<div onClick={() => navigate(link)} className={`card-task-people-item ${alert ? "alert-dossier-team" : ""}`}>
			<p className="text-primary color-primary">{title}</p>
			<div className="card-task-people-item-content">
				{/*{
                    (alert === "moyen" || alert === "majeur") ? (
                        <div className="card-task-people-item-content-alert" onMouseEnter={() => setShowAlert(true)} onMouseLeave={() => setShowAlert(false)}>
                            <img src="./images/pictos/warning.svg" alt="alert"/>
                        </div>
                    ) : null
                }
                {
                    showAlert ? (
                        <div className="card-task-people-item-content-alert-message">
                            <p
                                className="card-task-people-item-content-alert-message-content text-primary color-white"
                                style={{backgroundColor: backgroundColorMessage}}
                            >
                                Le dossier d'étude structure a un défaut <span className="text-bold">{alert}</span>
                            </p>
                            <img className="card-task-people-item-content-alert-message-arrow" src={arrowMessage} alt=""/>
                        </div>
                    ) : null
                }*/}
				<div className="card-task-people-item-content-pictures" ref={blocImgRef}>
					{pictures.length > 3 ? (
						<p
							className="card-task-people-item-content-pictures-nb card-task-people-item-content-pictures-image text-primary paragraph_bold color-white">
							+{(pictures.length) - 3}
						</p>
					) : null}
					{pictures.map((picture, index) => (
						<img
							className="card-task-people-item-content-pictures-image"
							key={index}
							src={`${process.env.REACT_APP_PROD_URL}/${picture}`}
							alt=""
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default CardTeamUser;
