export const InitialValuesCustomerAccount = {
  profilePicture: "",
  gender : "",
  lastname: "",
  firstname: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  postalCode: "",
  addressComplement: "",
  agencyName: "",
  // differentAddress: false,
  // billingGender: "",
  // billingLastname: "",
  // billingFirstname: "",
  // billingCompany: "",
  // billingAddress: "",
  // billingCity: "",
  // billingPostalCode: "",
  // billingAddressComplement: "",
};