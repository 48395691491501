import React from 'react';
import {ErrorMessage, Field} from "formik";

const InputTextareaErrorMessage = ({
	                                name,
	                                placeholder,
	                                readonly,
	                                className
                                }) => {


		return (
			<div className={"form-row-label-input"}>
				<Field readOnly={readonly} as="textarea" name={name} className={className}
				       placeholder={placeholder}/>
				<ErrorMessage name={name} component={"p"} className={"color-error text-primary"}/>
			</div>
		)
};

export default InputTextareaErrorMessage;
