export const InitialValuesTsr2 = {
	g: '', //
	q: '', //
	wallThicknessToBeOpened: '', //
	ClearBayWidth: '', //
	clearanceHeight: '', //
	floorLoadStrip: '', //
	methodUsed: '', //
	supportLengthsLeft: '', //
	supportLengthsRight: '', //
	HAPostSteels: "",
	typicalSplicingPosition: '',
	counterPosts: '',
	lintel: '',
	numberOfBackToBackLintels: '', //
	QtyTF: '',
	lintelInstallation: '',
	bedBases: '',
	stools: '',
	stoolsQty: '',
	concreteOrSteelBeamClass: '',
	concreteOrSteelPostClass: '',
	HABeamUpperAxisSteels: '',
	HABeamLowerAxisSteels: '',
	counterPostsMade: '',
	counterPostsLength: '',
	beamPostAssBoltsQty: '',
	beamPostAssBoltsDiameter: '',
	beamPostAssBoltsCLass: '',
	beamSplicingPosition: '',
	beamSplicingQty: '',
	beamSplicingDiameter: '',
	beamSplicingClass: '',
	typicalSplicing: '',
	postFootPlate: '',
	beamWeldingBeads: '',
	postWeldingBeads: '',
	fireStabilityRequired: ''
}
