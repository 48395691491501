import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';


const MbeCalendarSidebarNotifs = ({notifications, notificationValidator}) => {
    
    moment.locale('fr');
    
    return (
        <div className='mbeCalendar-container-sidebar-notifications'>
            <h3>Nouveaux rendez-vous</h3>
            <div className='mbeCalendar-notifications'>
                {notifications.map((notification) => (

                    <div className="mbeCalendar-notifications-item" key={notification.id}>
                        <div className="mbeCalendar-notifications-item-head">
                            <div>
                                <p> {moment(notification.startAt).format('h:mm')} - {moment(notification.endAt).format('h:mm')} </p>
                                <p> {moment(notification.startAt).format('dddd Do MMMM')} </p>
                            </div>
                            <span onClick={() => notificationValidator(notification.id)}>
                                <img src="/images/pictos/checkmark.svg" alt="checkmark"></img>
                            </span>
                        </div>
                        
                        <p className="mbeCalendar-notifications-item-foot">
                            {notification.title}
                        </p>
                    </div>

                ))}

            </div>
        </div>
    )
}

export default MbeCalendarSidebarNotifs;