import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {useAuth} from '../../services/useAuth';
import PopupLogout from './Popup/PopupLogout';

const SideBar = ({user, allowedSidebar, toggleSidebar, isSidebarExpand}) => {

	const {logout} = useAuth();
	const [showPopup, setShowPopup] = useState(false);
	const [avatarPath, setAvatarPath] = useState("");

	const getAvatarPath = () => {
		let avatar = localStorage.getItem("avatar").replace(/"/g, "")
		setAvatarPath(process.env.REACT_APP_PROD_URL + avatar)
	}

	const displayPopupLogout = () => {
		setShowPopup(!showPopup);
	}

	useEffect(() => {
		getAvatarPath()
	}, [])

	const userRole = localStorage.getItem("role");
	const isUserCustomerOrArchitect = userRole === '["ROLE_CLIENT"]' || userRole === '["ROLE_ARCH"]';

	return (
		<>
			<section
				className={`section-side-bar ${isUserCustomerOrArchitect && isSidebarExpand ? "section-side-bar-expand" : ''}`}>

				<aside className={"section-side-bar-container"}>

					<nav className={"section-side-bar-container-nav"}>
						<ul className={"section-side-bar-container-nav-list"}>
							{allowedSidebar.map((item, index) => {
								return (
									<li className={"section-side-bar-container-nav-list-item"} key={index}>
										<NavLink to={item.path} activeclassname={"active"}>
											<img src={item.img} alt={item.title}/>
											{
												isUserCustomerOrArchitect && isSidebarExpand && (
													<span className='text-primary color-white'>{item.title}</span>
												)
											}
										</NavLink>
									</li>
								)
							})}
						</ul>
					</nav>

					{
						isUserCustomerOrArchitect && (
							<div className={`section-side-bar-expand-toggle ${isSidebarExpand ? 'active' : ''}`}
							     onClick={toggleSidebar}>
								<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7 13L1 7L7 1" stroke="#090754" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</div>
						)
					}


					<div className={"section-side-bar-container-user"}>
						<div className={"section-side-bar-container-user-profil-picture"}>
							<NavLink to="/profil">
								<img src={avatarPath}/>
							</NavLink>
						</div>
						<div className={"section-side-bar-container-user-logout"} onClick={displayPopupLogout}>
							<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9.5 4L8.1 5.4L10.7 8H0.5V10H10.7L8.1 12.6L9.5 14L14.5 9L9.5 4ZM18.5 16H10.5V18H18.5C19.6 18 20.5 17.1 20.5 16V2C20.5 0.9 19.6 0 18.5 0H10.5V2H18.5V16Z"
									fill="white"/>
							</svg>
							{
								isUserCustomerOrArchitect && isSidebarExpand && (
									<span className='text-primary color-white'>Déconnexion</span>
								)
							}
						</div>
					</div>

				</aside>
			</section>

			<PopupLogout showPopup={showPopup} validAction={logout} closeAction={displayPopupLogout}/>
		</>
	);
};

export default SideBar;
