const PopupProjectStageStudyValidation = ({ showPopup, downloadAction, closeAction }) => {
  return (
    <div className={`project-step-payment__popup pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"pop-up-simple center"}>
        <img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} alt={"cross"} onClick={closeAction} />
        <p className={"text-primary color-primary"}>
          Vous avez validé votre dossier d'étude structure. Vous pouvez dès maintenant le télécharger.
        </p>
        <div className={"pop-up-simple-btns"}>
          <button className={"btn-default btn-default-primary"} onClick={downloadAction}>Télécharger dossier d'étude structure</button>
        </div>
      </div>
    </div>
  )
}

export default PopupProjectStageStudyValidation;