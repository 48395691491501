import React, {useContext, useEffect, useState} from 'react';
import partnerService from "../../../../services/PartnerService";
import PartnerService from "../../../../services/PartnerService";
import {Form, Formik} from "formik";
import FormEditPartnersAdmin from "./FormEditPartnerAdmin";
import {initialValuesEditPartner} from "../../../../config/InitialValues/InitialValuesEditPartner";
import {PartnersSchema} from "../../../../schemas/Admin/Partners/PartnersSchema";
import {StateRequest} from "../../../../views/Pages/Admin/Partners/Partners";

const FormEditPartner = ({id, setIsActive}) => {

	const {setStateRequest} = useContext(StateRequest);


	// Patch Partner
	const onSubmit = async (values) => {
		if (values.active === "false") {
			values.active = false
		} else if (values.active === "true") {
			values.active = true
		}

		const request = await PartnerService.editPartnerLink(id, values)
		if (request.request.status === 200) {
			setIsActive(false)
			setStateRequest("success edit")
		} else {
			setStateRequest("error")
		}
	}

	const [partner, setPartner] = useState([]);

	const getPartner = async () => {
		const request = await partnerService.getSinglePartner(id)
		if (request.request.status === 200) {
			setPartner(request.data)
		} else {
			console.log(request.request.status)
		}
	}

	useEffect((actions) => {
		setPartner([])
		getPartner()
	}, [id, setIsActive])

	return (
		<div className={"form-partner"}>
			<Formik
				initialValues={initialValuesEditPartner}
				validationSchema={PartnersSchema}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => {
					return (
						<Form>
							<FormEditPartnersAdmin partner={partner}/>
						</Form>
					)
				}}
			</Formik>
		</div>
	);
};

export default FormEditPartner;
