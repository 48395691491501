import React, {useState} from "react";
import {Link} from "react-router-dom";
import PopUpForm from "../../../components/Molecules/PopUp/PopUpForm";
import FormEditCommentBills from "../../../components/Molecules/Form/FormEditCommentBills";

export const StateProjectPayments = [
	{
		Header: "Numéro de facture",
		accessor: "reference",
	},
	{
		Header: "Numéro de devis",
		accessor: "relatedDeposit.reference",
	},
	{
		Header: "Nom client",
		accessor: "client.niceNames",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.niceNames}</Link>
			)
		}
	},
	{
		Header: "Montant HT",
		accessor: "amount",
		Cell: cellInfo => {
			return (
				<div>{cellInfo.row.original.amount.toFixed(2)} €</div>
			)
		}
	},
	{
		Header: "Montant TTC",
		accessor: "amountWithTax",
		Cell: cellInfo => {
			return (
				<div>{cellInfo.row.original.amountWithTax.toFixed(2)} €</div>
			)
		}
	}
]
