import React, {useContext, useEffect, useState} from "react";
import {StateRequest} from "../../../../views/Pages/Admin/MarketPlace/MarketPlace";
import {Form, Formik} from "formik";
import {
	InitialValuesAddPartnerMarketPlace,
} from "../../../../config/InitialValues/Admin/InitialValuesAddPartnerMarketPlace";
import {MarketPlaceSchema} from "../../../../schemas/Admin/MarketPlace/MarketPlaceSchema";
import Skeleton from "react-loading-skeleton";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import InputLabelFileErrorMessage from "../../../Atomes/Formik/InputLabelFileErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import MarketPlaceService from "../../../../services/MarketPlaceService";
import axios from "axios";

const FormEditPartnerMarketPlace = ({id, setShowPopUp}) => {
	const {setStateRequest} = useContext(StateRequest);

	const [categories, setCategories] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [initialValues, setInitialValues] = useState(
		InitialValuesAddPartnerMarketPlace
	);

	useEffect(() => {
		getCategories();
		const fetchData = async () => {
			setIsLoading(true);
			const request = await MarketPlaceService.getPartner(id);
			if (request.request.status === 200) {
				const partnerData = request.data;
				setInitialValues({
					title: partnerData.title,
					email: partnerData.email,
					description: partnerData.description,
					categoryIri: partnerData.categoryIri.split("/")[3],
					transmission: partnerData.transmission,
					active: partnerData.active,
					logoUrl: partnerData.logoUrl.split("/")[4],
					imageUrl: partnerData.imageUrl.split("/")[4],
				});
			}
		};
		fetchData();
	}, [id]);

	useEffect(() => {
		if (initialValues.title !== "" && initialValues.active !== "" && initialValues.email !== "" && initialValues.description !== "" && initialValues.categoryIri !== "" && initialValues.transmission !== "" && initialValues.logoUrl !== "" && initialValues.imageUrl !== "") {
			setIsLoading(false)
		}
	}, [initialValues]);

	const onSubmit = async (values) => {
		const user = JSON.parse(JSON.parse(localStorage.getItem("user")));
		values.categoryIri = `/api/categories/${values.categoryIri}`;
		if (values.active === "true" || values.active === true) {
			values.active = true;
		} else {
			values.active = false;
		}

		const request = await MarketPlaceService.patchPartner(id, {
			title: values.title,
			active: values.active,
			email: values.email,
			description: values.description,
			categoryIri: values.categoryIri,
			transmission: values.transmission,
		});

		if (request.request.status === 200) {
			const requete = axios.create({
				baseURL: process.env.REACT_APP_PROD_API_URL,
				headers: {
					Authorization: `Bearer ${user ? user.token : ""}`,
					"Content-Type": `multipart/form-data`,
				},
			});

			if (values.logoUrl !== initialValues.logoUrl) {
				await requete.post(`partners/${request.data.id}/logo`, {
					file: values.logoUrl,
				});
			}

			if (values.imageUrl !== initialValues.imageUrl) {
				await requete.post(`partners/${request.data.id}/image`, {
					file: values.imageUrl,
				});
			}

			setStateRequest("success edit partner");
			setShowPopUp(false);
		}
	};

	const getCategories = async () => {
		const request = await MarketPlaceService.getPartnerCategories({
			pagination: "false",
		});
		if (request.request.status === 200) {
			setCategories(request.data["hydra:member"]);
		}
	};

	return (
		<div className={"form-partner"}>
			<div className={"form-partner"}>
				{isLoading ? (
					<Skeleton height={550}/>
				) : (
					<Formik
						initialValues={initialValues}
						validationSchema={MarketPlaceSchema}
						onSubmit={onSubmit}
					>
						{({isSubmitting, errors, touched}) => (
							isLoading ? (
								<Skeleton height={550}/>
							) : (
								<Form>
									<div className={"form-row-element"}>
										<InputLabelErrorMessage
											type={"text"}
											label={"Nom"}
											name={"title"}
										/>
									</div>

									<div className={"form-row-element form-row-two-elements input-file-container"}>
										<InputLabelFileErrorMessage
											picture={initialValues.logoUrl}
											name={"logoUrl"}
											label={"Logo"}
										/>
										<InputLabelFileErrorMessage
											picture={initialValues.imageUrl}
											name={"imageUrl"}
											label={"Illustration"}
										/>
									</div>

									<div className={"form-row-element"}>
										<InputLabelErrorMessage
											type={"text"}
											label={"Description"}
											name={"description"}
										/>
									</div>

									<div className={"form-row-element form-row-two-elements"}>
										<InputLabelErrorMessage
											type={"select"}
											label={"Catégorie"}
											name={"categoryIri"}
											data={categories}
											keyLabel={"title"}
											keyValue={"id"}
										/>
										<InputLabelErrorMessage
											type={"select"}
											label={"Transmission Lead"}
											name={"transmission"}
											data={[
												{value: "partner", label: "Partenaire"},
												{
													value: "email",
													label: "Commerciaux mon bureau d'études",
												},
											]}
											keyLabel={"label"}
											keyValue={"value"}
										/>
									</div>

									<div className={"form-row-element form-row-two-elements"}>
										<InputLabelErrorMessage
											type={"text"}
											label={"Adresse e-mail"}
											name={"email"}
										/>
										<InputLabelErrorMessage
											type={"select"}
											label={"Actif"}
											name={"active"}
											keyValue={"value"}
											keyLabel={"label"}
											data={[
												{label: "Oui", value: true},
												{label: "Non", value: false},
											]}
										/>
									</div>

									<ButtonSubmit
										styleButton={"btn-default btn-default-secondary plain"}
										isActive={isSubmitting}
										title={"Modifier le partenaire"}
									/>
								</Form>
							)
						)}
					</Formik>
				)}
			</div>
		</div>
	);
};

export default FormEditPartnerMarketPlace;
