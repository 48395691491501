import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import exportFileService from "../../services/ExportFileService";

export const ADDITIONAL_QUOTE_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "project.client.reference"
	},
	{
		Header: "Nom client",
		accessor: "project.client.lastname",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.project.client.id}`}>{cellInfo.row.original.project.client.firstname} {cellInfo.row.original.project.client.lastname}</Link>
			)
		}
	},
	/* {
		Header: "Typologie projet",
		accessor: "niceType"
	}, */
	{
		Header: "Montant devis (HT)",
		accessor: "total",
		Cell: cellInfo => {
			let total = 0
			if (cellInfo.row.original.total) {
				total = cellInfo.row.original.total.toFixed(2) + " €"
			} else {
				total = "0.00 €"
			}
			return total
		}
	},
	{
		Header: "Date demande",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = new Date(cellInfo.row.original.createdAt);
			date = moment(date).format("DD/MM/YYYY");
			return date
		}
	},
	{
		Header: "Date signature",
		accessor: "signedAt",
		Cell: cellInfo => {
			let date = "NA";
			if (cellInfo.row.original.signedAt) {
				date = new Date(cellInfo.row.original.signedAt)
				date = moment(date).format("DD/MM/YYYY");
			}
			return date
		}
	},
	{
		Header: "Date visite",
		accessor: "",
		Cell: cellInfo => {
			let date = "Pas de visite";
			if (cellInfo.row.original.project.visit) {
				date =  new Date(cellInfo.row.original.project.visit.startAt);
				date = moment(date).format("DD/MM/YYYY");
			}
			return date
		}
	},
	{
		Header: "Action",
		accessor: "",
		Cell: cellInfo => {
			const downloadFile = () => {
				exportFileService.exportFile(`/quotes/${cellInfo.row.original.id}`, `devis-complementaire`, {}, "pdf")
			}

			return (
				<>
					<div className={"actions-controller"}>
						<p src={"/images/pictos/download.svg"} onClick={() => downloadFile()} title={"Télécharger le devis complémentaire"}className="btn-little btn-default-primary color-primary">Télécharger le devis</p>
					</div>
				</>
			)
		}

	}

]