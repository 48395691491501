import React, {useContext} from 'react';
import {StateRequest} from "../../../views/Pages/Coms/Dir/Prestations/PrestationsComsDir";
import PrestationService from "../../../services/PrestationService";
import {toast} from "react-toastify";

const PopUpDeleteProjectCollaborator = ({id, onClickClose, onClickDelete }) => {

	//const { stateRequest, setStateRequest } = useContext(StateRequest);

	const handleSubmit = async () => {



		/*const request = await PrestationService.deleteService(id)
		onClick()
		if (request.request.status == 204){
			setStateRequest('success delete')
		}else{
			setStateRequest('error')
		}*/
	}
	return (
		<div className="pop-up-base-style pop-up-delete-project-progress active">
			<div className={"pop-up-user-delete"}>
				<img alt={"img close"} onClick={onClickClose} className={"cross-close"} src={"/images/pictos/cross-close.svg"}/>
				<img src={"/images/medias/popup.svg"} alt="test"/>
				<h4>Voulez-vous supprimer l'assignation au projet ?</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={onClickClose}>Non</button>
					<button className={"btn btn-delete"} onClick={onClickDelete}>Oui</button>
				</div>
			</div>
		</div>
	);
}

export default PopUpDeleteProjectCollaborator;
