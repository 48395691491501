import api from "./APIService";
import {ConvertObjectToUrlParams} from "../utils/StringUtils";

const getAverageBasket = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/average-basket", param) :"kpi/sales/average-basket")
}

const getTransformRate = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/transform-rate", param) : `kpi/sales/transform-rate`)
}

const getRevenueByType = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/revenues-by-type", param) : "kpi/sales/revenues-by-type")
}

const getRevenue = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/revenues", param) :`kpi/sales/revenues`)
}

const getRevenueByMonth = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/revenues-by-month", param) : "kpi/sales/revenues-by-month")
}

const getRequestsCount = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/requests", param) : "kpi/sales/requests")
}
const getCollaboratorRequestsCount = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/requests/${userId}`, param))
}

const getSignedQuotesCount = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/signed-quotes-count", param) : "kpi/sales/signed-quotes-count")
}
const getCollaborateurSignedQuotesCount = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/signed-quotes-count/${userId}`, param))
}

const getProcessingQuotesCount = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/processing-quotes-count", param) : "kpi/sales/processing-quotes-count")
}
const getCollaborateurProcessingQuotesCount = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/processing-quotes-count/${userId}`, param))
}

const getProcessingQuotes = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/processing-quotes", param) : "kpi/sales/processing-quotes")
}
const getCollaborateurProcessingQuotes = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/processing-quotes/${userId}`, param))
}

const getRecoveryCount = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/recovery-count", param) : "kpi/sales/recovery-count")
}
const getCollaborateurRecoveryCount = async (userId, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`kpi/sales/recovery-count/${userId}`, param))
}

const getAverageBasketByMonth = async (param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams("kpi/sales/average-basket-by-month", param) : "kpi/sales/average-basket-by-month")
}

const getCollaborateurObjective = async (userId, param) => {
	return await api.getAllData(param !== undefined ? ConvertObjectToUrlParams(`kpi/sales/objectives/${userId}`, param) : `kpi/sales/objectives/${userId}`)
}

const KpiService = {
	getAverageBasket,
	getTransformRate,
	getRevenueByType,
	getRevenue,
	getRevenueByMonth, 
	getRequestsCount,
	getCollaboratorRequestsCount,
	getSignedQuotesCount,
	getCollaborateurSignedQuotesCount,
	getProcessingQuotesCount,
	getCollaborateurProcessingQuotesCount,
	getProcessingQuotes,
	getCollaborateurProcessingQuotes,
	getRecoveryCount,
	getCollaborateurRecoveryCount,
	getAverageBasketByMonth,
	getCollaborateurObjective
}

export default KpiService;
