import { useContext } from "react"
import { StateRequest } from "../../../views/Pages/Coms/Com/Archive/Archive"
import RequestService from "../../../services/RequestService";

const PopUpReopenFile = ({idProject, reference, onClickNo, stage}) => {
    const {stateRequest, setStateRequest} = useContext(StateRequest)
    
    const reopenFile = async () => {
        const statusTemp = stage === "COM_quotation" ? "open" : "processing"
        const request = await RequestService.reopenFileArchived(idProject, {
            "status" : statusTemp
        })
        onClickNo()
        if (request.request.status === 200) {
			setStateRequest('success reopen')
		} else {
			setStateRequest('error')
		}
    }

    return (
        <div className={"pop-up-base-style active"}>
            <div className={"pop-up-user-delete"}>
                <img onClick={onClickNo} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"fermer la pop up"}/>
                <img src={"/images/medias/popup.svg"} alt="test"/>
                <h4>
                    Êtes-vous sur de vouloir rouvrir le projet {reference} ?
                </h4>
                <div className={"pop-up-user-delete-buttons"}>
                    <button className={"btn btn-cancel"} onClick={onClickNo}>Non</button>
                    <button className={"btn btn-primary"} onClick={() => reopenFile()}>Oui</button>
                </div>


            </div>
        </div>
    );
};

export default PopUpReopenFile