import React, {useState} from "react";
import PopUpForm from "../../../components/Molecules/PopUp/PopUpForm";
import FormEditDPGF from "../../../components/Molecules/Form/Admin/FormEditDPGF";
import PopUpAdminDeleteDPGF from "../../../components/Molecules/PopUp/PopUpAdminDeleteDPGF";

export const DPGF_COLUMNS = [
	{
		Header: "Désignation ouvrages",
		accessor: "content",
		Cell: cellInfo => {
			return (
				/*<div>
                    <h3 className="adminDpgfView-sortingTable--title">{ cellInfo.row.original.designation_ouvrages_title }</h3>
                    <p>{ cellInfo.row.original.designation_ouvrages }</p>
                </div>*/
				<div dangerouslySetInnerHTML={{__html: cellInfo.row.original.content}}></div>
			)
		}
	},
	{
		Header: "Référence CCTP",
		accessor: "cctpReference"
	},
	{
		Header: "Unité",
		accessor: "unit"
	},
	{
		Header: "TVA",
		accessor: "vta",
		Cell: cellInfo => {
			let vta = 0
			if (cellInfo.row.original.vta) {
				vta = cellInfo.row.original.vta * 100 + " %"
			} else {
				vta = "0 %"
			}
			return <div className="nowrap">{vta}</div>
		}
	},
	{
		Header: "Total HT",
		accessor: "total",
		Cell: cellInfo => {
			let total = 0
			if (cellInfo.row.original.total) {
				total = cellInfo.row.original.total.toFixed(2) + " €"
			} else {
				total = "0.00 €"
			}
			return <div className="nowrap">{total}</div>
		}
	},
	{
		Header: "Total TTC",
		accessor: "total_ttc",
		Cell: cellInfo => {
			let total = 0
			if (cellInfo.row.original.total) {
				total = cellInfo.row.original.total * (1 + cellInfo.row.original.vta)
				total = total.toFixed(2) + " €"
			} else {
				total = "0.00 €"
			}
			return <div className="nowrap">{total}</div>
		}
	}, {
		Header: "Actions",
		accessor: "",
		Cell: cellInfo => {

			const [showPopup, setShowPopup] = useState(false)
			const [deleteUser, setDeleteUser] = useState(false)

			return (
				<>
					<div className="actions-controller">
						<img onClick={() => setShowPopup(true)} src={"/images/pictos/pencil-blue.svg"}/>
						<img src={"/images/pictos/remove-icon.svg"} onClick={() => setDeleteUser(true)}/>
					</div>
					{
						showPopup ? (
							<PopUpForm show={showPopup} onClick={() => setShowPopup(false)} title={"Modifier la DPGF"} large={true}
							           form={<FormEditDPGF cellInfo={cellInfo.row.original} setShowPopUp={setShowPopup}/>}/>
						) : null
					}

					{
						deleteUser ? (
							<PopUpAdminDeleteDPGF onClick={() => setDeleteUser(false)} id={cellInfo.row.original.id}/>
						) : null
					}

				</>
			)
		}
	}
]
