import React, {useContext} from "react";
import {StateRequest} from "../../../../views/Pages/Admin/MarketPlace/MarketPlace";
import {Form, Formik} from "formik";
import Skeleton from "react-loading-skeleton";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import InputLabelFileErrorMessage from "../../../Atomes/Formik/InputLabelFileErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import {
	InitialValuesAddCategoryMarketPlace
} from "../../../../config/InitialValues/Admin/InitialValuesAddCategoryMarketPlace";
import {MarketPlaceAddCategorySchema} from "../../../../schemas/Admin/MarketPlace/MarketPlaceAddCategorySchema";
import MarketPlaceService from "../../../../services/MarketPlaceService";

const FormAddCategoryMarketPlace = ({setIsActive}) => {
	const { setStateRequest } = useContext(StateRequest);

	const onSubmit = async (values) => {
		const request = await MarketPlaceService.postCategory(values)
		if (request.request.status === 201) {
			setStateRequest("success add category")
			setIsActive(false)
		}
	}

	return (
		<div className={"form-partner"}>
			<Formik
				initialValues={InitialValuesAddCategoryMarketPlace}
				validationSchema={MarketPlaceAddCategorySchema}
				onSubmit={onSubmit}
			>
				{({isSubmitting, errors, touched}) => (
						<Form>
							<div className={"form-row-element"}>
								<InputLabelErrorMessage type={"text"} label={"Titre"} name={"title"}/>
							</div>

							<div className={"form-row-element"}>
								<InputLabelErrorMessage type={"text"} label={"Description"} name={"description"}/>
							</div>

							<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
							              title={"Ajouter la catégorie"}/>
						</Form>
				)}
			</Formik>
		</div>
	)
}

export default FormAddCategoryMarketPlace;