import React, {useEffect} from 'react'
import {toast} from 'react-toastify';

import {Form, Formik} from 'formik';
import {InitialValuesCustomerBank} from './Config/InitialValuesCustomerBank';
import {SchemaCustomerBank} from './Config/SchemaCustomerBank';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import ButtonSubmit from '../../../../../../components/Atomes/Buttons/ButtonSubmit';

import api from "../../../../../../services/APIService";

const ProfileCustomerBankForm = ({bankFormData}) => {

	const setInitialValues = () => {
		InitialValuesCustomerBank.name = bankFormData.accountHolder;
		InitialValuesCustomerBank.iban = bankFormData.iban;
		InitialValuesCustomerBank.bic = bankFormData.bic;
	}

	useEffect(() => {
		setInitialValues();
	}, []);

	const onSubmit = async (values) => {
		let userId = localStorage.getItem("userId");
		let accountHolder = values.name;
		let iban = values.iban;
		let bic = values.bic;
		let data = {
			accountHolder: accountHolder,
			iban: iban,
			bic: bic,
			roles: ["ROLE_CLIENT"]
		}
		let request = await api.patch(`clients/${userId}`, data)
		if (request.status === 200) {
			toast.success("Vos informations bancaires ont été mises à jour avec succès");
		} else {
			toast.error("Une erreur est survenue, veuillez réessayer plus tard");
		}
	};

	return (
		<div className='mbe-profile-container mbe-section' id='informationsBancaires'>
			<h3 className='color-primary'>Mes informations bancaires</h3>
			<Formik
				enableReinitialize={true}
				initialValues={InitialValuesCustomerBank}
				validationSchema={SchemaCustomerBank}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => (
					<Form>
						<div className={"form-row-element"}>
							<InputLabelErrorMessage
								label="Titulaire du compte"
								name="name"
								type="text"
							/>
						</div>

						<div className={"form-row-element"}>
							<InputLabelErrorMessage
								label="IBAN"
								name="iban"
								type="text"
							/>
						</div>

						<div className={"form-row-element"}>
							<InputLabelErrorMessage
								label="BIC"
								name="bic"
								type="text"
							/>
						</div>

						<ButtonSubmit
							styleButton={"btn-default btn-default-secondary plain"}
							isActive={isSubmitting} title={"Enregistrer"}
						/>

					</Form>
				)}
			</Formik>
		</div>
	)
}

export default ProfileCustomerBankForm
