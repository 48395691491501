import React from "react";
import { Link } from "react-router-dom";
import CardCircuitDeValidation
	from "../../../../../../components/Molecules/Cards/CircuitDeValidation/CardCircuitDeValidation";
import { COMS_DIR_VALIDATION_CIRCUIT } from "../../../../../../config/UrlConstants";

const ApprovalCircuit = (approvalCircuit) => {

	const allApprovalCircuit = approvalCircuit.approvalCircuit
	return (
		<div className={"section-circuit-de-validation mbe-section"}>
			<div className={"section-circuit-de-validation-title"}>
				<h3 className={"color-primary"}>Circuit de validation</h3>
				{
					!(allApprovalCircuit.length < 0) &&
					<Link to={COMS_DIR_VALIDATION_CIRCUIT}>Voir tous les circuits de validation</Link>
				}
			</div>
			{
				allApprovalCircuit.length <= 0 ? (
					<p className="section-circuit-de-validation-empty text-primary color-primary">Pas de validation en attente</p>
				) : (
					<div className={"section-circuit-de-validation-container"}>
						{allApprovalCircuit.map((circuit, index) =>
							<CardCircuitDeValidation
								id={circuit.id}
								key={index}
								title={circuit.niceStage}
								status={circuit.niceStageStatus}
								niceNames={circuit.com ? circuit.com.niceNames : ""}
								numberFolder={circuit.reference}
								devisId={circuit.validateQuoteId}
								img={circuit.com.urlAvatar}
								color={
									circuit.niceStageStatus === "Validé" ? "green" : circuit.niceStageStatus === "En cours" ? "orange" : "red"
								}
								styleButton={
									circuit.niceStageStatus === "Validé" ? "green" : circuit.niceStageStatus === "En cours" ? "orange" : "red"
								}
							/>
						)}
					</div>
				)
			}
		</div >
	)
}
export default ApprovalCircuit;
