import React from 'react';
import {ErrorMessage, Field} from "formik";

const InputLabelErrorMessageOnChange = ({
	                                name,
	                                type,
	                                label,
	                                placeholder,
	                                data,
	                                keyValue,
	                                keyLabel,
	                                readonly,
	                                canBeNull,
									onChange,
	                                className
                                }) => {

	if (type === "select") {
		return (
			<div className={"form-row-label-input"}>
				<label htmlFor={name} className={"text-micro paragraph_bold color-dark-grey"}>{label}</label>
				<Field multiple={false}
					   className={className === undefined ? "select-custom-secondary" : className}
					   as={type}
					   name={name}
					   placeholder={label}
					   onChange={onChange}
				>
					<option hidden>{placeholder === undefined ? label : placeholder}</option>
					{data.map((item, index) => {
						if (readonly === "readonly") {
							return (
								<option disabled key={index} value={item[keyValue]}>{item[keyLabel]}</option>
							)
						} else {

							return (
								<option key={index} value={item[keyValue]}>{item[keyLabel]}</option>
							)
						}
					})}
					{canBeNull ? <option value={null}>N/A</option> : ""}
				</Field>
				<ErrorMessage name={name} component={"p"} className={"color-error text-primary"}/>
			</div>
		);
	} else {
		return (
			<div className={"form-row-label-input"}>
				<label htmlFor={name} className={"text-micro paragraph_bold color-dark-grey"}>{label}</label>
				<Field readOnly={readonly} type={type} name={name} className={className}
				       placeholder={placeholder === undefined ? label : placeholder}/>
				<ErrorMessage name={name} component={"p"} className={"color-error text-primary"}/>
			</div>
		)
	}
};

export default InputLabelErrorMessageOnChange;
