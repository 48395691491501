import React, { useEffect, useState, useContext } from 'react'
import { ChatbotAdminActionsContext } from '../../ChatbotAdmin';
import { toast } from 'react-toastify';

import { Formik, Form, FieldArray, Field, ErrorMessage } from 'formik';
import { MiniroutineSchema } from './Config/MiniroutineSchema';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import MultiSelect from '../../../../../../components/Atomes/Select/MultiSelect';

import { AdminService } from '../../../../../../services/AdminService';

const PopupAddMiniroutine = ({ closePopup }) => {

  const chatbotContext = useContext(ChatbotAdminActionsContext)

  const chatbotCategories = [
    {
      label: "Définition du profil du demandeur",
      value: "mini-routine-profile"
    },
    {
      label: "Type de projet avec étage",
      value: "mini-routine-profile-floors"
    },
    {
      label: "Compléments de document et prestations additionnelles",
      value: "mini-routine-administrative-complement"
    },
    {
      label: "Informations clients",
      value: "mini-routine-administrative-client-info"
    },
    {
      label: "Fin du chatbot",
      value: "mini-routine-administrative-final"
    },
    {
      label: "Autre",
      value: "mini-routine-others"
    }
  ];
  const [chatbotQuestionsOptions, setChatbotQuestionsOptions] = useState([]);

  useEffect(() => {
    getChatbotQuestionsOptions();
  }, []);

  const getChatbotQuestionsOptions = async () => {
    let request = await AdminService.get(`/chatbot-questions?pagination=false`);
    if (request.status === 200) {
      let chatbotQuestionsOptions = request.data['hydra:member'].map((chatbotQuestion) => {
        return {
          label: chatbotQuestion.title,
          value:  `/api/chatbotQuestions/${chatbotQuestion.id}`
        }
      });
      setChatbotQuestionsOptions(chatbotQuestionsOptions);
    }
  }

  const submitForm = async (values, setSubmitting) => {
    setSubmitting(true);

    let data = {
      categoryType: values.categoryType,
      title: values.title,
      chatbotQuestionsIri: values.routines.map((routine) => {
        return routine.name;
      })
    }

    let request = await AdminService.post(`/chatbot-mini-routines`, data);
    if (request.status === 201) {
      toast.success('La Mini-Routine a bien été créée !');
      chatbotContext.setChatbotAdminActionsContext({
        "name": "mini-routine"
      })
      closePopup();
    }
    setSubmitting(false);
  }

  return (
    <Formik
      onSubmit={(values, { setSubmitting }) => {
        submitForm(values, setSubmitting);
      }}
      initialValues={{
        categoryType: '',
        title: '',
        routines: [{
          id: 1,
          name: ''
        }]
      }}
      validationSchema={MiniroutineSchema}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className='form-row-two-elements'>
            <div className='form-row-label-input'>
              <label className={"text-micro paragraph_bold color-dark-grey"} htmlFor='categoryType'>Catégorie de la Mini-Routine</label>
              <Field
                name={`categoryType`}
                id={`categoryType`}
                placeholder='Catégorie de la Mini-Routine'
                options={chatbotCategories}
                isMulti={false}
                component={MultiSelect}
              />
              <ErrorMessage name={`categoryType`} component={"p"} className={"color-error text-primary"} />
            </div>
            <div className='form-row-element'>
              <InputLabelErrorMessage
                name="title"
                type="text"
                label="Titre de la Mini-Routine"
              />
            </div>
          </div>
          <FieldArray
            name="routines"
            render={arrayHelpers => (
              <div className={`adminChatbot-popup__manyanswers ${values.routines.length > 1 ? 'adminChatbot-popup__manyanswers-true' : ''} `}>
                {
                  values.routines.map((routine, index) => (
                    <span key={index}>
                      <label className={"text-micro paragraph_bold color-dark-grey"}>Question-Réponse n°{index + 1}</label>
                      <div className='form-row-element' >
                        <div className='adminChatbot-popup__manyanswers-arrows'>
                          {
                            values.routines.length > 1 && (
                              <>
                                <button onClick={() => arrayHelpers.move(index, index - 1)} type='button'>
                                  <img src='/images/pictos/arrow-up-white.svg' />
                                </button>
                                <button onClick={() => arrayHelpers.move(index, index + 1)} type='button'>
                                  <img src='/images/pictos/arrow-down-white.svg' />
                                </button>
                              </>
                            )
                          }
                        </div>
                        <div className='form-row-label-input'>
                          <Field
                            name={`routines.${index}.name`}
                            id={`routines.${index}.name`}
                            placeholder='Nom de la Question-Réponse'
                            options={chatbotQuestionsOptions}
                            isMulti={false}
                            component={MultiSelect}
                          />
                        </div>
                        {
                          values.routines.length > 1 && (
                            <img
                              className='adminChatbot-popup__manyanswers-delete'
                              src="/images/pictos/delete-file.svg"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          )
                        }
                      </div>
                      <ErrorMessage name={`routines.${index}.name`} component={"p"} className={"color-error text-primary"} />
                    </span>
                  ))
                }
                <button
                  onClick={() => arrayHelpers.push({ id: values.routines.length + 1, name: '' })}
                  className={"btn-default btn-default-secondary plain"}
                  type='button'>
                  Ajouter une Question-Réponse à la Mini-Routine
                </button>
              </div>
            )}
          />
          <button className={"btn-default btn-default-secondary plain"} disabled={isSubmitting} type='submit'>
            Creér la mini-routine
          </button>
        </Form>
      )
      }
    </Formik >
  )
}

export default PopupAddMiniroutine;