import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PopUpAdminHome from "../../PopUp/PopUpAdminHome";

export function CardToDo({item, title, styleButton, firstName, lastName, img, numberFolder, status, color}) {

	const [showPopUp, setShowPopUp] = useState(false);
	const [relance, setRelance] = useState(true)

	const checkRelance = () => {
		if (item.niceStage === "Relance de paiement" && item.niceStageStatus === "Validé") {
			setRelance(false)
		} else {
			setRelance(true)
		}
	}

	useEffect(() => {
		checkRelance()
	}, [item])

	return (
		<>
			<div className={"card-to-do"}>
				<div className={"card-to-do-img"}>
					<img src={`${process.env.REACT_APP_PROD_URL}${img}`} alt={`${firstName} ${lastName}`}/>
					<p className="text-primary">{numberFolder}</p>
				</div>
				<div className={"card-to-do-name"}>
					<h4>{`${firstName} ${lastName}`}</h4>
				</div>
				{/*<div className={"card-to-do-dossier"}>
				<CardCircuitDeValidationStatus title={status} color={color}/>
			</div>*/}
				<ButtonSubmit title={title} onClick={() => relance ? setShowPopUp(true) : null}
				              styleButton={`btn-default ${styleButton}`}/>
				{
					item.niceStage === "Débloquer un créneau" ? (
						<Link to={`calendrier`}>Accéder au calendrier</Link>
					) : (
						<Link to={`projet/${item.id}`}>Accéder au dossier</Link>
					)
				}

			</div>

			{
				showPopUp ? (
					<PopUpAdminHome item={item} id={item.id} stage={item.stage} onClick={() => setShowPopUp(false)}/>
				) : null
			}
		</>
	)
}

export default CardToDo;
