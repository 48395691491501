import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import TitleIconNavLink from "../../../../../../../components/Atomes/Title/TitleIconNavLink";
import TabNavigationStudyFolder from "../../Partials/TabNavigationStudyFolder";
import PopUpInformations from "../../Partials/PopUpInformations";
import { HOME_PATH } from "../../../../../../../config/UrlConstants";
import DirRespService from "../../../../../../../services/Ing/DirResp/DirRespService";
import { Form, Formik } from "formik";
import { InitialValuesExecutionCLass } from "../../../../../../../config/InitialValues/Engineer/InitialValuesExecutionClass";
import { IngExecutionClassSchema } from "../../../../../../../schemas/Engineer/IngExecutionClassSchema";
import StudyFolderExecutionPartial from "./StudyFolderExecutionPartial";
import { toast } from "react-toastify";

const StudyFolderExecutionClass = () => {

    const [loading, setLoading] = useState(true);
    const [showInformation, setShowInformation] = useState(false)
    const [studyFolderStep] = useState("CLASSE")
    const [executionClass, setExecutionClass] = useState({})

    const [content, setContent] = useState("");

    const { id } = useParams()

    const getProjectsInformations = async () => {
        const request = await DirRespService.getStudyFolderInformations(id)
        if (request.status === 200) {
            setContent(request.data)
            setLoading(false)
        }
    }

    const onSubmit = async (values) => {
        const data = {
            "skipped": false,
            "choices": {
                0: values.question0,
                1: values.question1,
                2: values.question2,
                3: values.question3,
            },
            "executionClassResult1": values.executionClassResult1,
            "executionClassResult2": values.executionClassResult2
        }

        const request = await DirRespService.patchExecutionClass(id, data)
        if(request.status === 200) {
            toast.success("La classe d'execution a bien été enregistrée")
        }
    }

    const getExecutionClass = async () => {
        const request = await DirRespService.getExecutionClass(id)
        if(request.status === 200) {
            if(request.data.choices.length !== 0) {
                setExecutionClass({
                    question0: request.data.choices[0],
                    question1: request.data.choices[1],
                    question2: request.data.choices[2],
                    question3: request.data.choices[3],
                    executionClassResult1: request.data.executionClassResult1,
                    executionClassResult2: request.data.executionClassResult2,
                })
            }
        }
    }

    useEffect(() => {
        setLoading(true)
        getProjectsInformations()
        getExecutionClass()
    }, [id])

    return (
        <>
            {
                loading ? <Skeleton height={500} /> : (
                    <div className={"section-study-folder-dpgf mbe-section"}>
                        <div className={"section-study-folder-dpgf-title"}>
                            <TitleIconNavLink title={`${content.project.client.niceNames} - ${content.project.address.street}, ${content.project.address.nicePostcode}, ${content.project.address.city}`} link={HOME_PATH} information={true}
                                onClick={() => setShowInformation(true)} />
                        </div>

                        <TabNavigationStudyFolder theStep={studyFolderStep} id={id} needExecutionClass={content.needExecutionClass} needCCTP={content.needCctp}
                            needSummary={content.needSummary} />

                        <PopUpInformations
                            show={showInformation}
                            onClick={() => setShowInformation(false)}
                            large={false}
                            title={"Informations projet"}
                            content={content}
                        />

                        <div className={"section-study-folder-tsr-container"}>
                            <div className={"section-study-folder-tsr-container-editor"}>
                                <div className={"section-study-folder-tsr-container-editor-title"}>
                                    <h3 className={"text-primary color-primary"}>Détermination de la classe d'execution</h3>
                                </div>

                                <Formik
                                    initialValues={InitialValuesExecutionCLass}
                                    validationSchema={IngExecutionClassSchema}
                                    onSubmit={onSubmit}
                                >

                                    <Form>
                                        <StudyFolderExecutionPartial executionClass={executionClass} />
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )

}

export default StudyFolderExecutionClass;