export const dataTypologies = [
  {
      "label": "Ouverture de mur porteur",
      "value": "project-omp"
  },
  {
      "label": "Ouverture de plancher",
      "value": "project-odp"
  },
  {
      "label": "Extension",
      "value": "project-ext"
  },
  {
      "label": "Surélévation",
      "value": "project-sur"
  },
  {
      "label": "Renforcement de plancher",
      "value": "project-rdp"
  },
  {
      "label": "Détermination de capacité portante",
      "value": "project-dcp"
  },
  {
      "label": "Diagnostic structure",
      "value": "project-diag-str"
  },
  {
      "label": "Diagnostic fissuration",
      "value": "project-diag-fiss"
  },
  {
      "label": "Diagnostic post-travaux",
      "value": "project-diag-pt"
  },
  {
      "label": "Modification de charpente",
      "value": "project-mdc"
  },
  {
      "label": "Construction neuve",
      "value": "project-cn"
  },
  {
      "label": "Création de mezzanine",
      "value": "project-cdm"
  },
  {
      "label": "Pathologie du bâtiment",
      "value": "project-pdb"
  },
  {
      "label": "Reprise en sous-œuvre",
      "value": "project-rso"
  },
  {
      "label": "Dépose de cheminée",
      "value": "project-ddc"
  }
]