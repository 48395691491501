import React from 'react';
import HomeIngManTeams from "../Home/Teams/HomeIngManTeams";
import MyCollaboratorIngRes from './Partials/MyCollaboratorIngRes';
import MbeGantt from '../../../../../components/Molecules/Gantt/MbeGantt';

const CalendarIngManager = () => {


	return (
		<section className="mbe-section">
			<HomeIngManTeams />

			<MyCollaboratorIngRes />

			<div className="my-collaborator-container">
				<h3 className="color-primary">Planning de charge</h3>
				<MbeGantt />
			</div>
		</section>
	);
};

export default CalendarIngManager;
