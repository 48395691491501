import React, {useState} from 'react';
import PopUpForm from "../../../../../../components/Molecules/PopUp/PopUpForm";
import FormEditQuotesPartner from "../../../../../../components/Molecules/Form/FormEditQuotesPartner";

const PatnerEdit = ({cellinfo}) => {
	
	const [isActive, setIsActive] = useState(false);
	
	return (
		<>
			<a className="btn-little btn-default-primary color-primary" onClick={(e)=>setIsActive(!isActive)}>Modifier</a>
			{isActive ? (
				<PopUpForm onClick={() => setIsActive(!isActive)} large={true} show={isActive} form={<FormEditQuotesPartner setIsActive={setIsActive} cellinfo={cellinfo}/>} title={"Modifier devis partenaire"}/>
				)
				: ""
			}
		</>
	);
};

export default PatnerEdit;
