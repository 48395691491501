import api from "./APIService";
import { ConvertObjectToUrlParams } from "../utils/StringUtils";

const getProjects = async (params) => {
  return await api.getAllData(ConvertObjectToUrlParams(`projects/context/arch`, params))
}

const getQuotes = async (params) => {
  return await api.getAllData(ConvertObjectToUrlParams(`quotes/context/arch`, params))
}

const getCustomers = async (params) => {
  return await api.getAllData(ConvertObjectToUrlParams(`clients/context/arch`, params))
}


const getCustomerInfos = async (id) => {
  return await api.getAllData(`/clients/${id}/`)
}
const getCustomerProjects = async (id, params) => {
  return await api.getAllData(ConvertObjectToUrlParams(`/projects/clients/${id}`, params))
}

export const ArchService = {
  getProjects,
  getQuotes,
  getCustomers,
  getCustomerInfos,
  getCustomerProjects
}