export const initialValuesIngTeam = {
	diagnostiqueur: "",
	diagnostiqueurHour: "",
	diagnostiqueurSupport: "",
	diagnostiqueurDateStart: "",
	diagnostiqueurDateEnd: "",
	ingenieur: "",
	ingenieurHour: "",
	ingenieurSupport: "",
	ingenieurDateStart: "",
	ingenieurDateEnd: "",
	verificateur: "",
	verificateurHour: "",
	verificateurSupport: "",
	verificateurDateStart: "",
	verificateurDateEnd: "",
	manager: "",
	managerHour: "",
	managerSupport: "",
	managerDateStart: "",
	managerDateEnd: "",
}
