import React, { useEffect, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtmlPuri from "draftjs-to-html";
import { useFormikContext } from "formik";
import ContentState from "draft-js/lib/ContentState";

const EditorCustomDevisDescription = ({ indexOrder, indexQuote, project, content }) => {

    const { values, setFieldValue } = useFormikContext();

    const [initialValue, setInitialValue] = useState("");
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        if (values && values.quotes && values.quotes[indexQuote] && values.quotes[indexQuote].orders && values.quotes[indexQuote].orders[indexOrder]) {
            if (values.quotes[indexQuote].orders[indexOrder].description) {
                setInitialValue(values.quotes[indexQuote].orders[indexOrder].description);
            } else {
                setInitialValue("");
            }
        }
    }, []);

    const [editorState, setEditorState] = useState(() => {
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(initialValue)
            )
        );
    });

    useEffect(() => {
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(initialValue)
            )
        ));
    }, [initialValue]);

    const handleChangeEditorState = (valueOfEditor, indexQuote, indexOrder) => {
        setEditorState(valueOfEditor);
        if (isTyping) {
            let valueDescriptionFreePrestation = draftToHtmlPuri(convertToRaw(valueOfEditor.getCurrentContent()));
            setFieldValue(`quotes.${indexQuote}.orders.${indexOrder}.description`, valueDescriptionFreePrestation);
        }
    };

    useEffect(() => {
        if (content && !isTyping) {
            setInitialValue(content);
        }
    }, [content]);

    const handleFocus = () => {
        setIsTyping(true);
    };

    const handleBlur = () => {
        setIsTyping(false);
    };

    return (
        <Editor
            initialEditorState={editorState}
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            toolbar={{
                options: ["inline", "colorPicker", 'link', "list", 'fontSize', 'fontFamily', 'textAlign'],
                inline: {
                    options: ["bold", "italic", "underline", "strikethrough"],
                },
                colorPicker: {
                    colors: ["#000000", "#ffffff", "#090754", "#4AA9C2", "#9DDFF1", "#648DE5", "#c13e39", "#5FD086", "#eed128"],
                }
            }}
            onEditorStateChange={(e) => {
                handleChangeEditorState(e, indexQuote, indexOrder);
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    );
};

export default EditorCustomDevisDescription;
