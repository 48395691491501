export const dataRegions = [
    {
       label: "IDF",
       value: "IDF"
    }, {
        label: "BDX",
        value: "BDX"
    }, {
        label: "MEL",
        value: "MEL"
    }, {
        label: "LYO",
        value: "LYO"
    }, {
        label: "MAR",
        value: "MAR"
    }
]

export const dataRegionsArray = ["IDF", "BDX", "MEL", "LYO", "MAR"]