import React, { createContext, useEffect, useState } from "react";
import { HOME_PATH } from "../../../../../config/UrlConstants";
import CardCircuitDeValidation
	from "../../../../../components/Molecules/Cards/CircuitDeValidation/CardCircuitDeValidation";
import TitleIconNavLink from "../../../../../components/Atomes/Title/TitleIconNavLink";
import ButtonSubmit from "../../../../../components/Atomes/Buttons/ButtonSubmit";
import ApprovalCircuitService from "../../../../../services/ApprovalCircuitService";
import exportFileService from '../../../../../services/ExportFileService';
import { toast } from "react-toastify";

export const StateRequest = createContext()
const ApprovalCircuitView = () => {
	const [stateRequest, setStateRequest] = useState("");
	const downloadFile = () => {
		exportFileService.exportFile("/sales/validation-process", 'export-circuitvalidation', {}, "xlsx")
	}

	const [approvalCircuit, setApprovalCircuit] = useState([]);

	const getApprovalCircuit = async () => {
		const request = await ApprovalCircuitService.getAllApprovalCircuit({ pagination: "false" })
		if (request.request.status === 200) {
			setApprovalCircuit(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de la liste des circuits de validation")
		}
	}

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("La remise a été effectuée avec succès")
		} else if (type === "success delete") {
			toast.success("La remise n'a pas été validée")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		setApprovalCircuit([])
		getApprovalCircuit()
	}, [stateRequest]);

	return (
		<StateRequest.Provider value={{ stateRequest, setStateRequest }}>
			<div className={"mbe-section"}>
				<div className={"section-circuit-de-validation-title"}>
					<TitleIconNavLink title={"Tableau de bord"} link={HOME_PATH} />
					{
						approvalCircuit.length > 0 &&
						<ButtonSubmit isActive={false} onClick={downloadFile} title={"Exporter"}
							styleButton={"btn-default btn-default-blue btn-download color-primary"} />
					}
				</div>
				{
					approvalCircuit.length <= 0 ? (
						<p className="section-circuit-de-validation-empty text-primary color-primary">Pas de validation en
							attente</p>
					) : (
						<div className={"section-circuit-de-validation-view-container"}>
							{approvalCircuit.map((circuit, index) => <CardCircuitDeValidation
								key={index}
								id={circuit.id}
								title={circuit.niceStage}
								status={circuit.niceStageStatus}
								devisId={circuit.validateQuoteId}
								niceNames={circuit.com ? circuit.com.niceNames : ""}
								numberFolder={circuit.reference}
								img={circuit.com.urlAvatar}
								color={circuit.niceStageStatus === "Validé" ? "green" : circuit.niceStageStatus === "En cours" ? "orange" : "red"}
								styleButton={circuit.niceStageStatus === "Validé" ? "green" : circuit.niceStageStatus === "En cours" ? "orange" : "red"}
							/>)}
						</div>
					)
				}

			</div>
		</StateRequest.Provider>
	)
}

export default ApprovalCircuitView;
