import React, {useContext} from "react";
import {Form, Formik} from "formik";
import {initialValuesAddCommentBills} from "../../../config/InitialValues/Admin/initialValuesAddCommentBills";
import {AddBillsSchema} from "../../../schemas/Admin/Bills/BillsSchema";
import InputLabelErrorMessage from "../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../Atomes/Buttons/ButtonSubmit";
import {BillService} from "../../../services/BillService";
import {StateRequest} from "../../../views/Pages/Admin/Bill/AdminBills";

const FormEditCommentBills = ({id, setShow, comment}) => {

	const {stateRequest, setStateRequest} = useContext(StateRequest);

	const onSubmit = async (values) => {
		setShow(false)
		const request = await BillService.patchBills( `bills/${id}`, values)
		if (request.request.status === 200) {
			setStateRequest("success edit")
		} else {
			setStateRequest("error")
		}
	}

	initialValuesAddCommentBills.comment = comment

	return (
		<>
			<Formik
				initialValues={initialValuesAddCommentBills}
				validationSchema={AddBillsSchema}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => (
					<Form>
						<div className={"form-row-element"}>
							<InputLabelErrorMessage type={"text"} label={"Commentaire"} name={"comment"}/>
							<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
							              title={"Modifier"}/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default FormEditCommentBills