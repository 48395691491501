const CardMiniroutine = ({ miniroutine, openPopupDelete, openPopupEdit }) => {

  const getMiniroutineCategory = () => {
    if (miniroutine.category.title.length > 40) {
      return miniroutine.category.title.substring(0, 40) + "..."
    } else {
      return miniroutine.category.title
    }
  }

  return (
    <div className="card-miniroutine">
      {
        !miniroutine.mandatory && (
          <img
            className="card-miniroutine__delete"
            src={"/images/pictos/cross-background-red.svg"}
            onClick={() => openPopupDelete(miniroutine.id, miniroutine.title)}
          />
        )
      }

      <span className="card-miniroutine__category text-primary color-white">
        {getMiniroutineCategory()}
      </span>

      <article className="card-miniroutine__name">
        <p className="text-primary color-white">
          <img src={"/images/pictos/help-white.svg"} />
          {miniroutine.title}
        </p>
      </article>

      {
        miniroutine.chatbotQuestions.length > 0 && (
          miniroutine.chatbotQuestions.map((routine, index) => (
            <div key={index}>
              <div className="card-miniroutine__routine" >
                <p className="text-primary color-primary paragraph_bold">
                  <img src={"/images/pictos/help-primary.svg"} />
                  {routine.question}
                </p>

                {
                  routine.chatbotAnswers.length > 0 ? (
                    routine.chatbotAnswers.map((reponse, indexRoutine) => (
                      <span key={indexRoutine} className="text-secondary color-primary paragraph_bold">
                        <img src={"/images/pictos/chatbot-primary.svg"} />
                        {
                          reponse.answer !== "" ? (
                            reponse.answer
                          ) : (
                            routine.type === "file" ? ("Sélection de fichiers") : ("Saisie libre de texte")
                          )
                        }
                      </span>
                    ))
                  ) : (
                    <span className="text-secondary color-primary paragraph_bold">
                      <img src={"/images/pictos/chatbot-primary.svg"} />
                      {
                        routine.type === "file" ? ("Sélection de fichiers") : ("Saisie libre de texte")
                      }
                    </span>
                  )
                }

              </div>
              {/* {
              routine.isLinked && (
                <div className="card-miniroutine__cascade">
                  <span className="text-secondary color-primary paragraph_bold">
                    <img src={"/images/pictos/chatbotLink-primary.svg"} />
                    {routine.linkedRoutine.question}
                  </span>
                </div>
              )
            } */}
            </div>
          ))
        )
      }

      {
        !miniroutine.mandatory && (
          <button
            className={"btn-default btn-default-primary card-miniroutine__button"}
            onClick={() => openPopupEdit(miniroutine.id)}
            type={"button"}
          >
            Modifier la mini-routine
          </button>
        )
      }

    </div>
  )
}

export default CardMiniroutine;