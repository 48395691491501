import React from 'react';
import {SortingTable} from "./SortingTable";
import InputSearch from "../../Atomes/Inputs/InputSearch";
import ButtonWithOnClickAction from "../../Atomes/Buttons/ButtonWithOnClickAction";

const TableSearchTitleCreate = ({onChangeSearch, data, columnTable, title, placeHolder,onClick, createName, onChangeSearchValue, numberElement}) => {
	
	return (
		<div className={"table-search-title"}>
			<h3 className={"color-primary"}>{title}</h3>
			<div className={"table-search-title-add"}>
				<InputSearch onChangeSearchValue={onChangeSearchValue} placeholder={placeHolder} onChangeSearch={onChangeSearch}/>
				<ButtonWithOnClickAction onClick={onClick} isActive={true} title={createName} styleButton={"btn-default btn-default-primary"}/>
			</div>
			<div className={`table-search-title-container`}>
				<SortingTable numberElement={numberElement} columnsTable={columnTable} dataTable={data}/>
			</div>
		</div>
	);
};

export default TableSearchTitleCreate;
