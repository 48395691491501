import React from 'react';
import ButtonWithOnClickAction from "../../../../../../components/Atomes/Buttons/ButtonWithOnClickAction";
import ButtonSubmit from '../../../../../../components/Atomes/Buttons/ButtonSubmit';
import exportFileService from "../../../../../../services/ExportFileService";

const TableListingHeading = ({onClick, title, region, dark, search, titleButton, isAdmin=false}) => {

	const downloadFile = () => {
		const url = isAdmin ? "/admin/users" : "/sales/users"
		exportFileService.exportFile(url, 'export-users', {region: region, search: search}, "xlsx")
	}
	

	return (
		<div className='collaborators-tableListingHeading'>
			<h3 className={"color-primary"}>{title}</h3>
			<div className='collaborators-tableListingHeading-btns'>
				<ButtonSubmit onClick={downloadFile} title={"Exporter"} isActive={false} styleButton={"btn-default btn-default-blue btn-download color-primary"} />			
				<ButtonWithOnClickAction onClick={onClick} isActive={true} title={titleButton ? titleButton : "Ajouter un collaborateur"} styleButton={"btn-default" + (dark ? " btn-default-blue btn-download" : " btn-default-primary")}/>
			</div>
		</div>
	);
};

export default TableListingHeading;
