import * as Yup from "yup";

export const AddPrestationSchema = Yup.object().shape({
	title : Yup.string().required("Veuillez renseigner un titre"),
	reference : Yup.string().required("Veuillez renseigner une référence"),
	zone : Yup.string().required("Veuillez renseigner une zone"),
	spent : Yup.number().required("Veuillez renseigner un temps passé"),
	recommendedPrice : Yup.number().required("Veuillez renseigner une marge"),
	categoryIri : Yup.string().required("Veuillez renseigner une catégorie"),
	price : Yup.number().required("Veuillez renseigner un prix"),
})
