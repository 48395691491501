import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

import ProjectHeader from '../../Partials/ProjectHeader';
import ProjectSectionMarketplace from '../../Partials/SectionMarketplace';

import { ProjectStageService } from '../../../../services/ProjectStageService';

const ProjectStageStart = () => {

  const { projectId } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [projectStepVerificator, setProjectStepVerificator] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectData();
  }, []);

  const getProjectData = async () => {
    let projectStep = await ProjectStageService.getSingleProject(projectId);
    if (projectStep.stage === "COM_commercial-exchange" || projectStep.stage === "COM_quotation" || projectStep.stage === "COM_discount-validation") {
      setProjectStepVerificator(true);
      setProject(projectStep);
    } else {
      navigate("/projet/" + projectId, { replace: true });
    }
  }

  return (
    <>
      {
        !projectStepVerificator ? (
          <>
            <Skeleton height={300} />
            <Skeleton height={40} />
          </>
        ) : (
          <>
            <ProjectHeader
              project={project}
            />

            <div className={"project-container"}>
              <div className="project-container-step__header marketplace">
                <div>
                  <h3>Édition du devis</h3>
                  <p className="text-primary">
                    Suite à votre demande d’accompagnement depuis notre chatbot, nos commerciaux sont en train d’étudier votre projet et d’éditer un devis en cohérence avec votre projet. Vous recevrez une notification par e-mail dès que votre devis sera disponible. Vous pourrez ainsi le signer et passer aux étapes suivantes.
                  </p>
                </div>
              </div>
              <ProjectSectionMarketplace />
            </div>
          </>
        )
      }
    </>
  );
};

export default ProjectStageStart;
