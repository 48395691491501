import React, {useEffect, useState} from 'react';
import FilterTwoSelectsDatepicker from "../../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";
import {HOME_PATH} from "../../../../../config/UrlConstants";
import TitleIconNavLink from "../../../../../components/Atomes/Title/TitleIconNavLink";
import {SortingTable} from "../../../../../components/Molecules/Tables/SortingTable";
import InputSearch from "../../../../../components/Atomes/Inputs/InputSearch";
import RequestService from "../../../../../services/RequestService";
import {toast} from "react-toastify";
import moment from "moment";
import {RES_COMMERCIAL_NEW_REQUEST_COLUMNS} from "../../../../../config/ArrayColumns/ResCommercialNewRequest";
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

const NewRequest = () => {

	const options = dataRegionsArray
	const secondOptions = ["2 mois", "3 mois"]
	const secondValues = [2, 3]
	const [region, setRegion] = useState("");
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [date, setDate] = useState(new Date());
	const [newRequest, setNewRequest] = useState([]);
	const [search, setSearch] = useState("")

	const getNewRequest = async () => {
		const request = await RequestService.getNewRequests({
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			pagination: "false",
			region: region,
			search: search
		})
		if (request.request.status === 200) {
			setNewRequest(request.data);
		} else {
			toast.error('Une erreur est survenue lors de la récupération des nouvelles demandes')
		}
	}

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}

	const changeDateMonthLater = (date) => {
		if (date === "Toutes durées") {
			setDateStart("")
			setDateEnd("")
			setDate(new Date())
		} else {
			let dateEnd = moment().format("YYYY-MM-DD")
			let dateBegin = moment().subtract(date, 'months').format("YYYY-MM-DD")
			setDate([new Date(dateBegin), new Date(dateEnd)])
			setDateStart(dateBegin)
			setDateEnd(dateEnd)
		}
	}
	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}

	useEffect(() => {
		setNewRequest([])
		getNewRequest()
	}, [region, dateStart, dateEnd, date, search]);

	return (
		<>
			<FilterTwoSelectsDatepicker
				defaultValue={"Toutes les agences"}
				options={options}
				firstValue={options}
				onChange={changeRegionFilter}
				secondDefaultValue={"Toutes durées"}
				secondValue={secondValues}
				secondOptions={secondOptions}
				onSecondChange={(event) => changeDateMonthLater(event.target.value)}
				styledButton={"btn-default btn-default-primary btn-default-primary color-primary"}
				titleButton={"Selectionnez une période"}
				date={date}
				onChangeDate={(date) => changeDate(date)}
			/>
			<TitleIconNavLink title={"Tableau de bord"} link={HOME_PATH}/>
			<InputSearch placeholder={"Entrer la recherche puis valider"}
			             onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
			             name={"Recherche"}
			             onChangeSearch={(event) => (event.key === "Enter" || event.target.value.length === 0) ? setSearch(event.target.value.toLowerCase()) : null}/>
			<div className={"section-new-request-container"}>
				<SortingTable columnsTable={RES_COMMERCIAL_NEW_REQUEST_COLUMNS} dataTable={newRequest}/>
			</div>

		</>
	);
};

export default NewRequest;
