import * as URL from "./UrlConstants";
import RolesList from "./RolesList";

export default [
	// COMS
	{
		img: "/images/pictos/home-side-bar.svg",
		title: "Home commercial",
		path: URL.HOME_PATH,
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL,
			RolesList.RESPONSABLE_COMMERCIAL,
			RolesList.COMMERCIAL,
			RolesList.ADMIN,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR,
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
		],
	},
	{
		img: "/images/pictos/bill.svg",
		title: "Archives",
		path: URL.COMS_COM_ARCHIVE,
		permission: [
			RolesList.COMMERCIAL
		]
	},
	{
		img: "/images/pictos/kpi-side-bar.svg",
		title: "KPIs",
		path: URL.KPI_PATH,
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL,
			RolesList.RESPONSABLE_COMMERCIAL,
			RolesList.COMMERCIAL,
			RolesList.ADMIN,
		],
	},
	// COMS - Directeur
	{
		img: "/images/pictos/collaborators-sidebar.svg",
		title: "Collaborator director",
		path: URL.COMS_DIR_COLLABORATORS,
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL
		],
	},
	{
		img: "/images/pictos/rocket-side-bar.svg",
		title: "Objectifs director",
		path: URL.COMS_DIR_OBJECTIVES_VIEW,
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL
		],
	},
	{
		img: "/images/pictos/catalogue.svg",
		title: "Prestations",
		path: URL.PRESTATIONS_PATH,
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL,
		],
	},
	// COMS - Responsable
	{
		img: "/images/pictos/calendar.svg",
		title: "Res Calendar",
		path: URL.CALENDAR_PATH,
		permission: [
			RolesList.RESPONSABLE_COMMERCIAL
		],
	},
	{
		img: "/images/pictos/catalogue.svg",
		title: "Prestations",
		path: URL.PRESTATIONS_PATH,
		permission: [
			RolesList.RESPONSABLE_COMMERCIAL,
		],
	},
	// COMS - Commercial
	{
		img: "/images/pictos/past-studies.svg",
		title: "Objectifs director",
		path: URL.COMS_COM_PAST_STUDIES,
		permission: [
			RolesList.COMMERCIAL
		],
	},
	{
		img: "/images/pictos/calendar.svg",
		title: "Com Calendar",
		path: URL.CALENDAR_PATH,
		permission: [
			RolesList.COMMERCIAL
		],
	},

	// Admin
	{
		img: "/images/pictos/users.svg",
		title: "Users",
		path: URL.ADMIN_USERS_PATH,
		permission: [
			RolesList.ADMIN
		],
	},
	{
		img: "/images/pictos/projects.svg",
		title: "Projects",
		path: URL.ADMIN_PROJECTS_PATH,
		permission: [
			RolesList.ADMIN
		],
	},
	{
		img: "/images/pictos/calendar.svg",
		title: "Calendar",
		path: URL.CALENDAR_PATH,
		permission: [
			RolesList.ADMIN
		],
	},
	{
		img: "/images/pictos/bill.svg",
		title: "Bill",
		path: URL.ADMIN_BILL_PATH,
		permission: [
			RolesList.ADMIN
		],
	},
	{
		img: "/images/pictos/pencil.svg",
		title: "Bill",
		path: URL.ADMIN_PARTNERS_PATH,
		permission: [
			RolesList.ADMIN
		],
	},
	{
		img: "/images/pictos/market-place.svg",
		title: "Market place",
		path: URL.ADMIN_MARKET_PLACE_PATH,
		permission: [
			RolesList.ADMIN
		],
	},
	{
		img: "/images/pictos/chat.svg",
		title: "Chatbot",
		path: URL.ADMIN_CHATBOT_PATH,
		permission: [
			RolesList.ADMIN
		],
	},
	{
		img: "/images/pictos/cctp.svg",
		title: "CCTP",
		path: URL.ADMIN_CCTP_PATH,
		permission: [
			RolesList.ADMIN
		],
	},
	{
		img: "/images/pictos/dpgf.svg",
		title: "DPGF",
		path: URL.ADMIN_DPGF_PATH,
		permission: [
			RolesList.ADMIN
		],
	},

	// ARCHITECTE
	{
		img: "/images/pictos/home-side-bar.svg",
		title: "Accueil",
		path: URL.HOME_PATH,
		permission: [
			RolesList.ARCHITECTE
		],
	}, {
		img: "/images/pictos/users.svg",
		title: "Mes clients",
		path: URL.ARCHITECTE_CLIENTS_PATH,
		permission: [
			RolesList.ARCHITECTE,
		],
	},
	// CLIENT
	{
		img: "/images/pictos/home-side-bar.svg",
		title: "Tableau de bord",
		path: URL.HOME_PATH,
		permission: [
			RolesList.CUSTOMER
		],
	},
	{
		img: "/images/pictos/projects.svg",
		title: "Partenaires",
		path: URL.CUSTOMER_MARKETPLACE_PATH,
		permission: [
			RolesList.CUSTOMER
		],
	},
	// INGENIEUR
	// INGENIEUR - DIR - RES
	{
		img: "/images/pictos/kpi-side-bar.svg",
		title: "Mes KPI",
		path: URL.KPI_PATH,
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
		],
	},
	{
		img: "/images/pictos/collaborators-sidebar.svg",
		title: "Liste des collaborateurs",
		path: URL.ENGINEER_DIRECTOR_COLLABORATORS_PATH,
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
		],
	},
	{
		img: "/images/pictos/projects.svg",
		title: "Les projets",
		path: URL.ENGINEER_PROJECTS_PATH,
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
		],
	},
	{
		img: "/images/pictos/sidebar-objectifs-temporels.svg",
		title: "Liste des collaborateurs",
		path: URL.ENGINEER_DIRECTOR_OBJECTIFS_PATH,
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
		],
	},
	/* {
		img: "/images/pictos/eye-white.svg",
		title: "Contrôle",
		path: URL.ENGINEER_DIRECTOR_CONTROLE_PATH,
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
		],
	}, */
	// INGENIEUR - MAN
	{
		img: "/images/pictos/calendar.svg",
		title: "Calendrier détaillé",
		path: URL.ENGINEER_MANAGER_CALENDAR_PATH,
		permission: [
			RolesList.MANAGER_INGENIEUR
		],
	},
	{
		img: "/images/pictos/users.svg",
		title: "Liste des clients",
		path: URL.ARCHITECTE_CLIENTS_PATH,
		permission: [
			RolesList.MANAGER_INGENIEUR,
		],
	},

	// INGENIEUR - PROD
	// INGENIEUR - DIAG
	// INGENIEUR - ASS

]
