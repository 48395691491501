import React, {useMemo} from "react";
import {usePagination, useSortBy, useTable} from "react-table";

export const BasicSortingTable = ({dataTable, columnsTable, change, setShowPopUp, numberElement}) => {


	const columns = useMemo(() => columnsTable, [columnsTable])

	const data = useMemo(() => dataTable, [dataTable])

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		state
	} = useTable({
		columns,
		data,
		initialState: {pageIndex: 0, pageSize: numberElement ? numberElement : 10}
	}, useSortBy, usePagination);

	const {pageIndex, pageSize} = state

	return (
		<>
			<div className={"table-general-style-sortingTable"}>
				<table {...getTableProps()}>
					<thead>
					{
						headerGroups.map((headerGroup, index) => (
							<tr key={index} {...headerGroup.getHeaderGroupProps()}>
								{
									headerGroup.headers.map((column, index) => (
										<td key={index} unselectable={"on"}
										    className={"unselectable"} {...column.getHeaderProps(column.getSortByToggleProps())}>
											{column.render('Header')}
											<span>
											{column.isSorted ? (column.isSortedDesc ?
												<img src={"/images/pictos/sort-down-arrow.svg"} alt={"sort-down-arrow"}/> :
												<img src={"/images/pictos/sort-up-arrow.svg"} alt={"sort-up-arrow"}/>) : ''}
									</span>
										</td>
									))
								}
							</tr>
						))
					}
					</thead>
					<tbody {...getTableBodyProps()}>
					{
						page.map((row, index) => {
							prepareRow(row)
							return (
								<tr key={index} {...row.getRowProps()}>
									{
										row.cells.map((cell, index) => {
											return <td key={index} {...cell.getCellProps()}>
												{cell.render('Cell')}
											</td>
										})
									}
								</tr>
							)
						})
					}
					</tbody>
				</table>
			</div>
			{
				pageOptions.length > 1 ? (
					<div className="pagination">
						<button onClick={() => previousPage()} disabled={!canPreviousPage}>
							{'<'}
						</button>
						{' '}
						<span>
					{pageOptions.map((option, index) => {
						if (index === pageOptions.length - 1 || (index >= pageIndex - 2 && index <= pageIndex + 2)) {
							return (
								<button
									key={option}
									onClick={() => gotoPage(index)}
									className={pageIndex === index ? 'active' : ''}
								>
									{option + 1}
								</button>
							);
						} else if (index === pageOptions.length - 2 && option - pageIndex > 1) {
							return <span key={index}>{' '}...{' '}</span>;
						}
						return null;
					})}
        </span>
						{' '}
						<button onClick={() => nextPage()} disabled={!canNextPage}>
							{'>'}
						</button>
					</div>
				) : null
			}
		</>
	)
}