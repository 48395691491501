import { IsLoggedIn } from "../../../utils/RolesChecker";

const ChatbotPopupEnd = ({ }) => {
  
  const IsLoggedInConst = IsLoggedIn();

  const backHome = () => {
     if (IsLoggedInConst) {
      window.location.href = "/";
    } else {
      window.location.href = process.env.REACT_APP_VITRINE_URL;
    }
  }

  const startProject = () => {
    window.location.reload();
  }

  return (
    <div className={`pop-up-base-style active`}>
      <div className={"pop-up-simple center"}>
        <img className={"pop-up-simple-img"} src='/images/medias/popup.svg' alt='popup forms' />

        <p className='text-primary color-primary'> Nous vous remercions d'avoir soumis le formulaire. </p>
        <p className='text-primary color-primary'> Nous vous recontacterons très vite pour votre devis !</p>

        <div className={"pop-up-simple-btns"}>
          <button className={"btn-default btn-cancel"} onClick={backHome}>Retour</button>
          <button className={"btn-default btn-valid"} onClick={startProject}>Nouvelle demande de projet</button>
        </div>

      </div>
    </div>
  )
}

export default ChatbotPopupEnd;