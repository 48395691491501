import React, { useEffect, useState } from 'react';
import ResBookingPart from './partials/ResBookingPart';
import FilterTwoSelects from '../../../../../components/Molecules/Filter/FilterTwoSelects';
import MbeCalendar from '../../../../../components/Molecules/Calendar/MbeCalendar';
import { dataRegionsArray } from '../../../../../utils/RegionsUtils';
import TaskService from '../../../../../services/TaskService';
import { ConvertVisitsToFullCalendarEvents } from '../../../../../utils/TaskUtils';
import { toast } from 'react-toastify';
import { ConvertObjectToUrlParams } from '../../../../../utils/StringUtils';
import CollaboratorService from '../../../../../services/CollaboratorService';
import ComCalendarPart from '../../Com/Calendar/partials/ComCalendarPart';

const ResCalendarView = () => {
    const [view, setView] = useState('personnal');

    const firstSelectDefaultValue = "Toutes les agences";
    const firstSelectValue = dataRegionsArray;
    const firstSelectOptions = dataRegionsArray;
    const [region, setRegion] = useState("");
    const filterByRegion = (event) => {
        if (event.target.value === "Toutes les agences") {
        setRegion("")
        } else {
        setRegion(event.target.value)
        }
    }

      // Tasks
    const [tasks, setTasks] = useState([]);
    const getTasks = async () => {
        const request = await TaskService.getTasksUser("visits", { 'pagination': 'false', 'user.id': collaboratorFilter, 'region': region })
        if (request.request.status === 200) {
        let tasks = request.data['hydra:member'];
        tasks = ConvertVisitsToFullCalendarEvents(tasks)
        setTasks(tasks)
        } else {
        toast.error("Une erreur est survenue lors de la récupération des tâches")
        }
    };

    // "ObjectifsViewIngRes" filter settings
  const secondSelectDefaultValue = "Tous les collaborateurs";
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorFilter, setCollaboratorFilter] = useState("");
  const filterByCollaborator = (event) => {
    if (event.target.value === "Tous les collaborateurs") {
      setCollaboratorFilter("")
    } else {
      setCollaboratorFilter(event.target.value)
    }
  }

    const getCollaborators = async () => {
        const urlIngDiag = ConvertObjectToUrlParams("ings", 
        { 
          pagination: 'false',
          regions: region, 
        })
        const request = await CollaboratorService.getCollaborators(urlIngDiag)
        if (request.request.status === 200) {
          let ings = request.data['hydra:member']
          let availableIngs = []
          ings.forEach(ing => {

            if (ing.roles.includes("ROLE_ING_DIAG")) {
              availableIngs.push(ing)
            }
          })
          setCollaborators(availableIngs)
        } else {
          toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
        }
      }

    useEffect(() => {
        if(view === 'all') {
            getTasks()
            getCollaborators()
        }else {
            setCollaboratorFilter("")
            setRegion("")
        }
      }, [view, region, collaboratorFilter]);

    return (
        <>
            <div className='calendar-tabs'>
{/*                 <button onClick={() => setView('calendar')} className={` ${view === 'calendar' ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Calendrier</button>
 */}                <button onClick={() => setView('personnal')} className={` ${view === 'personnal' ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Mon calendrier</button>
                <button onClick={() => setView('booking')} className={` ${view === 'booking' ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Créneaux de visites</button>
                <button onClick={() => setView('all')} className={` ${view === 'all' ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Créneau réservé</button>
            </div>

            {view === 'personnal' ? (
              <ComCalendarPart />
            ) : view === 'booking' ? (
                <>
                    <section className='mbe-section mbe-bookingSlot-container'>
                        {/* <div className="mbe-bookingSlot-container-headline">
                            <h3 className="color-black">Prochains créneaux de visite</h3>
                        </div> */}
                        <ResBookingPart />
                    </section>
                </>
            ) : view === 'all' ? (
                <>
                    <FilterTwoSelects
                        defaultValue={firstSelectDefaultValue}
                        firstValue={firstSelectValue}
                        options={firstSelectOptions}
                        firstOnChange={filterByRegion}
                        secondDefaultValue={secondSelectDefaultValue}
                        secondValue={collaborators.map(collaborator => collaborator.id)}
                        secondOptions={collaborators.map(collaborator => collaborator.niceNames)}
                        secondOnChange={filterByCollaborator}
                    />
                    <MbeCalendar
                        tasks={tasks}
                        taskManager={false}
                        withNotifications={false}
                    />
                </>
            ): null}
        </>
    );
};

export default ResCalendarView;

