import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

import ProjectHeader from '../../Partials/ProjectHeader';
import { toast } from 'react-toastify';
import exportFileService from '../../../../services/ExportFileService';

import {ProjectStageService} from '../../../../services/ProjectStageService';
import {useAuth} from "../../../../services/useAuth";
import ButtonWithOnClickAction from '../../../../components/Atomes/Buttons/ButtonWithOnClickAction';

const ProjectStageEnd = () => {

	const navigate = useNavigate();
	const { role } = useAuth()
	const {projectId} = useParams();
	const [project, setProject] = useState({});
	const [projectStepVerificator, setProjectStepVerificator] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		getProjectData();
	}, []);

	const getProjectData = async () => {
		let projectInfos = await ProjectStageService.getSingleProject(projectId);
		if (projectInfos.stage === 'COM_feedback') {
			setProjectStepVerificator(true);
			setProject(projectInfos);
		} else {
			navigate("/projet/" + projectId, {replace: true});
		}
	}

	const downloadFile = () => {
		toast.info("Votre dossier d'étude structure est en cours de téléchargement");
		exportFileService.exportFile(`/studies/${project.study.id}`, 'dossier-etude', {}, "pdf")
	  }

	return (
		<>
			{
				!projectStepVerificator ? (
					<>
						<Skeleton height={300}/>
						<Skeleton height={40}/>
					</>
				) : (
					<>
						<ProjectHeader
							projectStep={"finished"}
							project={project}
						/>

						<div className={"project-container"}>


						<div className="project-container-step__header">
                  <div>
                        <h3>Rendu dossier d'étude structure</h3>
                        <p className="text-primary">Nous vous invitons à prendre connaissance de votre dossier d'étude structure. Si vous n'avez pas de remarques ou des questions, vous pouvez cliquer sur le bouton poser une question en bas de la page. Nous sommes à votre écoute si vous avez des demandes.</p>
                    
                  </div>
                </div>

				<ButtonWithOnClickAction onClick={() =>  downloadFile()}
                                           title={"Télécharger dossier d'étude structure"} styleButton={"margin-bottom-40 button-align-center btn-default btn-default-secondary plain"}
                                           isActive={true}/>


							<div className="project-container-step__header">
								<h3>Merci d'avoir choisi mon bureau d’études. pour vous accompagner dans votre projet.</h3>
								<p className="text-primary">
								Votre retour d'expérience est primordial, nous vous invitons à nous laisser un  <a
									href='https://g.page/r/CVZbFQ7ZFDCPEAg/review' target='_blank' rel="noreferrer">avis</a>. Retrouvez l'ensemble de
									vos documents dans l'onglet "Documents".<br/><br/> Pour toute question complémentaire,
									nous vous invitons à nous envoyer une demande depuis le chat interne en cliquant
									en bas à droite sur l’icône bleu. L’ingénieur chargé(e) d’affaires pourra répondre à
									vos questions et apporter des modifications sur votre dossier d’étude structure si
									nécessaire.
								</p>
							</div>

							<div className='project-step project-step-finished'>
								<div className='project-step-finished__item'>
									<h4>Vous avez un autre projet à réaliser ?</h4>
									<img src='/images/medias/indicators-project-finished/starter.svg' alt='start new project'/>
									<p className='text-primary color-black'>Nous serions ravis de vous accompagner.</p>
									<Link to={"/chatbot"} className='btn-default btn-default-secondary'>Commencer un nouveau projet</Link>
								</div>

								<div className='project-step-finished__item'>
									<h4>Vous avez des questions sur votre dossier ?</h4>
									<img src='/images/medias/indicators-project-finished/documents.svg' alt='start new project'/>
									<p className='text-primary color-black'>Poser vos questions depuis le chat interne.</p>
								</div>

								{
									role[0] === "ROLE_CLIENT" || role[0] === "ROLE_ARCH" ? (
										<div className='project-step-finished__item'>
											<h4>Laissez nous un avis.</h4>
											<img src='/images/medias/indicators-project-finished/marketplace.svg' alt='start new project'/>
											<p className='text-primary color-black'>Votre retour d’expérience est primordial.</p>
											<Link to={"https://g.page/r/CVZbFQ7ZFDCPEAg/review"} target={"_blank"}
											      className='btn-default btn-default-secondary'>Laissez un avis</Link>
										</div>
									) : null
								}

							</div>

						</div>
					</>
				)
			}
		</>
	)
}

export default ProjectStageEnd;
