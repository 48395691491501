export const InitialValuesTsr = {
	zipCode: '',
	dateConstuction: '',
	yearBuilt: '',
	numberOfFloors: '',
	loceauxDestination: '',
	wallConstructionMaterial: '',
	homogenousWall: "",
	floorConstructionAndFinishing: '',
	floorConstructionAndFinishingMateriel: '',
	roofType: '',
	ifTerrace: '',
	roofComposition: '',
	charpente: '',
	chimney: '',
	currentFloorToBeOpened: '',
	floorSpanDirection: '',
	OPMToBeCarriedOut: '',
	convertibleAtticOrTerrace: '',
}
