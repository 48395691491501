import React from 'react';
import TitleIconNavLink from '../../../components/Atomes/Title/TitleIconNavLink';
import ProfileAccountForm from './Partials/Account/Form';
import ProfilePasswordForm from './Partials/Password/Form';

const Profile = () => {
  return (
    <section>
      <TitleIconNavLink title={"Tableau de bord"} link={"/"} />
      <ProfileAccountForm />
      <ProfilePasswordForm />
    </section>
  );
}

export default Profile;