import React, {useEffect, useState} from 'react';

import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import {blueJeans, primary} from "../../../config/Color";

ChartJS.register(ArcElement, Tooltip, Legend);

export function ChartDonut({title, legend, data1, data2, rate, className}) {

	const [stateData1, setStateData1] = useState(0);
	const [stateData2, setStateData2] = useState(0);
	const [stateRate, setStateRate] = useState(0);

	useEffect(() => {
		setStateData1(data1)
		setStateData2(data2)
		setStateRate(parseFloat((rate * 100).toFixed(1)))
	}, [data1, data2, rate]);

	const data = {
		datasets: [{
			data: [stateData1, (stateData2 - stateData1) < 0 ? 0 : (stateData2 - stateData1) ], backgroundColor: [primary(), blueJeans(),], borderWidth: 0,
		},],
	};

	const options = {
		responsive: true, cutout: 140, rotation: 120, weight: 2
	}

	return (<div className={`element-kpi element-kpi-l donuts`}>
		<div className={"element-kpi-title"}>
			<h4 className={"color-primary"}>{title}</h4>
		</div>
		<div className={"element-kpi-legend"}>
			{legend.map((item, index) => <p key={index}
																			className={"text-primary color-" + item.color}>{item.title}</p>)}
		</div>
		<div className={"chart-donut"}>
			<h1 className={"color-black"}>
				{stateRate}%
			</h1>
			<Doughnut options={options} data={data}/>
		</div>
	</div>)
}

export default ChartDonut;
