import React, {useState} from 'react';
import CollaboratorDetailTable from "./CollaboratorDetailTable";
import Select from '../../../../../../components/Atomes/Select/Select';

const CollaboratorDetailView = (collaborators) => {
	const AllCollaborators = collaborators.collaborators
	const [selectedCollaborator, setSelectedCollaborator] = useState(false);
	const [collaborator, setCollaborator] = useState({});

	const changeCollaborator = (event) => {
		if(event.target.value === "Choisissez un collaborateur"){
			setSelectedCollaborator(!selectedCollaborator)
		} else {
			if (selectedCollaborator === false) {
				setSelectedCollaborator(!selectedCollaborator)
			}
			setCollaborator(AllCollaborators.find((collaborator) => collaborator.id === parseInt(event.target.value)))
		}

	}

	return (
		<section className='collaborators-detailView'>

			<div className='collaborators-detailView-heading'>
				<h3 className={"color-primary"}>Objectif détaillé</h3>
				<Select defaultValue={"Choisissez un collaborateur"} onChange={changeCollaborator}
								data={AllCollaborators.map(collaborators => collaborators.lastname)}
								value={AllCollaborators.map(collaborators => collaborators.id)}
								styleSelect={"select-custom-secondary"}/>
			</div>

			{
				selectedCollaborator &&
				<div>
					<CollaboratorDetailTable collaborator={collaborator}/>
				</div>
			}
		</section>
	);
};

export default CollaboratorDetailView;
