import React, { useState, createContext } from "react";

import TitleSectionWithButton from "../../../../components/Molecules/Title/TitleSectionWithButton";
import PopUpForm from "../../../../components/Molecules/PopUp/PopUpForm";

import ChatbotMegaRoutineAdmin from "./Mega-Routine/ChatbotMegaRoutine";

import ChatbotMacroRoutineAdmin from "./Macro-Routine/ChatbotMacroRoutine";
import PopupAddMacroroutine from "./Macro-Routine/Popup/PopupAddMacroroutine";

import ChatbotMiniRoutineAdmin from "./Mini-Routine/ChatbotMiniRoutine";
import PopupAddMiniroutine from "./Mini-Routine/Popup/PopupAddMiniroutine";

import ChatbotRoutineAdmin from "./Routine/ChatbotRoutine";
import PopupAddRoutine from "./Routine/Popup/PopupAddRoutine";

export const ChatbotAdminActionsContext = createContext();

const ChatbotAdmin = () => {

  const tabs = [
    {
      "name": "mega-routine",
      "title": "Paramétrage des mega-routines",
      "isAddFeature": false,
      "navTitle": "Méga-Routine de dialogue",
      "component": <ChatbotMegaRoutineAdmin />
    },
    {
      "name": "macro-routine",
      "title": "Paramétrage des macro-routines",
      "isAddFeature": true,
      "buttonTitle": "Ajouter une macro-routine",
      "onClick": () => setActivePopup(
        {
          "title": "Ajouter une macro-routine",
          "form": <PopupAddMacroroutine closePopup={closePopup} />
        }),
      "navTitle": "Macro-routine de dialogue",
      "component": <ChatbotMacroRoutineAdmin />
    },
    {
      "name": "mini-routine",
      "title": "Paramétrage des mini-routines",
      "isAddFeature": true,
      "buttonTitle": "Ajouter une mini-routine",
      "onClick": () => setActivePopup(
        {
          "title": "Ajouter une mini-routine",
          "form": <PopupAddMiniroutine closePopup={closePopup} />
        }),
      "navTitle": "Mini-routine de dialogue",
      "component": <ChatbotMiniRoutineAdmin />
    },
    {
      "name": "question-reponse",
      "title": "Paramétrage des questions-réponses",
      "isAddFeature": true,
      "buttonTitle": "Ajouter une question-réponse",
      "onClick": () => setActivePopup(
        {
          "title": "Créer une question-réponse",
          "form": <PopupAddRoutine closePopup={closePopup} />
        }),
      "navTitle": "Liste de Questions-Réponses",
      "component": <ChatbotRoutineAdmin />
    }
  ]
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [activePopup, setActivePopup] = useState(null);
  const [chatbotAdminActionsContext, setChatbotAdminActionsContext] = useState({});

  const tabManager = (key) => {
    const tab = tabs.filter((tab) => tab.name === key);
    setActiveTab(tab[0]);
  };

  const closePopup = () => {
    setActivePopup(null);
  }

  return (
    <ChatbotAdminActionsContext.Provider value={{ chatbotAdminActionsContext, setChatbotAdminActionsContext }}>

      {
        activeTab.isAddFeature ? (
          <TitleSectionWithButton
            title={activeTab.title}
            buttonTitle={activeTab.buttonTitle}
            onClick={activeTab.onClick}
            isActive={true}
            styledButton="btn-default btn-default-add btn-default-add-blue"
          />
        ) : (
          <h3 className={"color-primary"}>{activeTab.title}</h3>
        )
      }


      <div className="adminChatbot-nav">
        {tabs.map((section) => (
          <span
            key={section.name}
            className={"text-tertiary color-dark-grey" + (activeTab.name === section.name ? " active" : "")}
            onClick={() => tabManager(section.name)}
          >
            {section.navTitle}
          </span>
        ))}
      </div>

      <div className="adminChatbot-content">
        {activeTab.component}
      </div>

      {
        activeTab.isAddFeature && (
          <PopUpForm
            show={activePopup !== null}
            onClick={() => setActivePopup(null)}
            title={activePopup !== null ? activePopup.title : ""}
            form={activePopup !== null ? activePopup.form : ""}
            large={true}
          />
        )
      }
    </ChatbotAdminActionsContext.Provider>
  );
}

export default ChatbotAdmin;