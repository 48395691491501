import React from "react";
import {Link} from "react-router-dom";

const TitleIconNavLink = ({title, link, information, onClick}) => {
	return (
		<div className={"title-icon-nav-link"}>
			<Link to={link} className={"title-icon-nav-link-container"}>
				<img src={"/images/pictos/arrow-left.svg"} alt=""/>
				<h3>{title}</h3>
			</Link>

			{information ? (
				<img onClick={onClick} src={"/images/pictos/info.svg"} alt="info img"/>
			) : null
			}
		</div>
	);
};

export default TitleIconNavLink;