import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";

export const RES_COMMERCIAL_NEW_REQUEST_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Nom client",
		accessor: "client.lastname",
		Cell: cellInfo => {
			return (				
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.firstname} {cellInfo.row.original.client.lastname}</Link>
			)
		}
	},
	{
		Header: "Typologie projet",
		accessor: "type"
	},
	{
		Header: "Documents",
		accessor: "chatbotDocument",
		Cell: cellInfo => {
			return cellInfo.row.original.chatbotDocument === true ? "Transmis" : "Non transmis";
		}
	},
	{
		Header: "Date demande",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = new Date(cellInfo.row.original.createdAt);
			date = moment(date).format("DD/MM/YYYY");
			return date
		}
	},
	{
		Header: "Demande de rappel",
		accessor: "chatbotContact"
	},
]

