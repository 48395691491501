import api from "./APIService";
import convert from "lodash/fp/convert";
import {ConvertObjectToUrlParams} from "../utils/StringUtils";

const getDpgf = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`dpgfs`, param))
}

const getDpgfById = async (id) => {
	return await api.getAllData(`dpgfs/${id}`)
}

const postDpgf = async (data) => {
	return await api.postBody(`dpgfs`, data)
}

const patchDpgf = async (id, data) => {
	return await api.patch(`dpgfs/${id}`, data)
}

const deleteDPGF = async (id) => {
	return await api.del(`dpgfs/${id}`)
}

const DpgfService = {
	getDpgf,
	postDpgf,
	patchDpgf,
	getDpgfById,
	deleteDPGF
}

export default DpgfService;