import React, {useContext, useEffect, useState} from "react";
import {ChatbotContext} from "../Chatbot";

import {toast} from "react-toastify";
import {Field, FieldArray, Form, Formik} from 'formik';
import {ChatbotService} from "../../../services/ChatbotService";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';

const ChatbotAnswers = () => {

    const contextValue = useContext(ChatbotContext);
    const [suggestionsAddress, setSuggestionsAddress] = useState([]);
    const [addressToSend, setAddressToSend] = useState("");
    const [showSelectAdress, setShowSelectAdress] = useState(false);

    const [value, setValue] = useState(null);
    const [txtValue, setTxtValue] = useState({
        value: "",
        error: true,
        errorText: "Le champ ne doit pas être vide"
    });

    const [addressValue, setAddressValue] = useState({
        value: "",
        error: true,
        errorText: "Le champ ne doit pas être vide"
    });

    const [sendFileSelected, setSendFile] = useState({
        status: false,
        files: []
    });

    const apiKey = 'AIzaSyCF8Gbyp4UKpI-6Xs-QrJA5W9bwXD2HS9I';

    const submitValueFile = (event) => {
        let files = event.target.files;
        let filesBase64 = [];
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let fileType = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
                let allowedTypes;

                if (contextValue.activeQuestion.categoryType === "media-project") {
                    allowedTypes = [".png", ".jpeg", ".jpg"];
                } else {
                    allowedTypes = [".png", ".jpeg", ".jpg", ".pdf"];
                }

                if (!allowedTypes.includes(fileType)) {
                    alert("Type de fichier non autorisé : " + fileType);
                    event.target.value = null; // Réinitialiser l'input file pour effacer la sélection non autorisée
                    return;
                }

                if(file.size <= 16000000) {
                    filesBase64.push({
                        file: file,
                        name: file.name,
                        categoryType: contextValue.activeQuestion.categoryType
                    });
                    if (filesBase64.length === files.length) {
                        setSendFile({
                            status: true,
                            files: filesBase64
                        });
                    }
                } else {
                    alert("La taille de l'image " + file.name + " est supérieure à 15 Mo.");
                    removeFiles()
                }
            }

        } else {
            setSendFile({
                status: false,
                files: []
            });
        }
    }
    const removeFiles = () => {
        setSendFile({
            status: false,
            files: []
        });
    }

    const submitAnswerWithLogin = (values) => {
        let actualQuestion = contextValue.allQuestionsAnswered.find((item) => item.id === contextValue.activeQuestion.id);
        if (contextValue.userToken && actualQuestion.answer === null) {
            let data = {
                id: contextValue.activeQuestion.id,
                type: contextValue.activeQuestion.type,
                chatbotQuestionIri: `/api/chatbotQuestions/${contextValue.activeQuestion.id}`,
                chatbotMegaRoutineIri: contextValue.activeQuestion.chatbotMegaRoutineIri,
                chatbotMacroRoutineIri: contextValue.activeQuestion.chatbotMacroRoutineIri,
                chatbotMiniRoutineIri: contextValue.activeQuestion.chatbotMiniRoutineIri,
                answers: values,
                stringAnswers: values.answer,
                chatbotAnswersIri: [`/api/chatbotAnswers/${values.id}`],
                splitIri: values.chatbotMiniRoutineIri ? values.chatbotMiniRoutineIri : null
            }
            addAnswerToActiveQuestion(data);
        }
    }

    const handleAddressChange = (addressClick) => {
        geocodeByPlaceId(addressClick.place_id)
            .then(results => {
                setSuggestionsAddress(results)
            })
            .catch(error => console.error(error));
    };

    //CHATBOT ADRESSE (formatage adresse cliquée)
    useEffect(() => {
        if (suggestionsAddress[0]) {
                if (suggestionsAddress[0].address_components[0]) {
                    if (suggestionsAddress[0].address_components[0]?.long_name) {
                        const postalCode = suggestionsAddress[0].address_components[6]?.long_name
                        const street = suggestionsAddress[0].address_components[0]?.long_name + " " + suggestionsAddress[0].address_components[1]?.long_name
                        const city = suggestionsAddress[0].address_components[2]?.long_name
                        const completeAdress = street + '|' + city + '|' + postalCode
                        setAddressToSend(completeAdress)
                    }
                }
        }

    }, [suggestionsAddress]);

    useEffect(() => {
        setTxtValue(txtValue)
    }, [txtValue.value]);

    useEffect(() => {
        if (contextValue.activeQuestion) {
            let values = contextValue.activeQuestion.chatbotAnswers.find((answer) => answer.action === "login");
            submitAnswerWithLogin(values);
        }
    }, [contextValue.activePopup]);


    const submitAnswer = (values) => {

        let data = {
            id: contextValue.activeQuestion.id,
            type: contextValue.activeQuestion.type,
            chatbotQuestionIri: `/api/chatbotQuestions/${contextValue.activeQuestion.id}`,
            chatbotMegaRoutineIri: contextValue.activeQuestion.chatbotMegaRoutineIri,
            chatbotMacroRoutineIri: contextValue.activeQuestion.chatbotMacroRoutineIri,
            chatbotMiniRoutineIri: contextValue.activeQuestion.chatbotMiniRoutineIri,
        }

        if (contextValue.activeQuestion.type === "choices") {
            data.answers = values.answers.filter((answer) => answer.checked === true);
            if (data.answers.length === 0) {
                toast.error("Vous devez sélectionner au moins une réponse");
                return false;
            }
            data.stringAnswers = data.answers.map((answer) => answer.answer).join(", ");
            data.splitIri = contextValue.activeQuestion.splitIri ? contextValue.activeQuestion.splitIri : null
            data.chatbotAnswersIri = data.answers.map((answer) => `/api/chatbotAnswers/${answer.id}`)
        } else if (contextValue.activeQuestion.type === "choice") {
            if (values.action) {
                if (values.action === "login") {
                    contextValue.setActivePopup(true);
                    return submitAnswerWithLogin(values);
                }
            }
            data.answers = values;
            data.stringAnswers = values.answer;
            data.chatbotAnswersIri = [`/api/chatbotAnswers/${values.id}`]
            data.splitIri = values.chatbotMiniRoutineIri ? values.chatbotMiniRoutineIri : null
        } else if (contextValue.activeQuestion.type === "txt") {
            //SI CHAMP ADRESSE POSTALE
            if (contextValue.activeQuestion.chatbotAnswers[0].action === "address") {
                data.answers = addressToSend.trim();

                //FORMAT DATE
                const isValidAddressFormat = (address) => {
                    // const addressFormat = /^\d+\s+[a-zA-ZÀ-ÿ\s'-]+,\s+[a-zA-ZÀ-ÿ\s'-]+,\s+[a-zA-ZÀ-ÿ\s'-]+/;
                    // return addressFormat.test(address);
                    return true;
                };
                if (!isValidAddressFormat(txtValue.value.trim())) {
                    setTxtValue({
                        value: txtValue.value.trim(),
                        error: true,
                        errorText: "L'adresse n'est pas au bon format"
                    })
                    toast.error("Vous devez écrire au format '00 Nom de la rue, Ville, Pays'");
                    return false;
                }
            } else {
                data.answers = txtValue.value.trim();
            }

            if (data.answers.trim().length === 0) {
                setTxtValue({
                    value: txtValue.value.trim(),
                    error: true,
                    errorText: "Le champ ne doit pas être vide"
                })
                toast.error("Vous devez écrire une réponse");
                return false;
            }

            if (contextValue.activeQuestion.chatbotAnswers[0].regex) {
                let regex = contextValue.activeQuestion.chatbotAnswers[0].regex;
                if (!txtValue.value.trim().match(regex)) {
                    setTxtValue({
                        value: txtValue.value.trim(),
                        error: true,
                        errorText: contextValue.activeQuestion.chatbotAnswers[0].errorMessage
                    })
                    toast.error(contextValue.activeQuestion.chatbotAnswers[0].errorMessage);
                    return false;
                }
            }

            data.stringAnswers = txtValue.value.trim();
            data.chatbotAnswersIri = [`/api/chatbotAnswers/${contextValue.activeQuestion.chatbotAnswers[0].id}`]
            data.splitIri = contextValue.activeQuestion.splitIri ? contextValue.activeQuestion.splitIri : null
            setTxtValue({
                value: '',
                error: true,
                errorText: "Le champ ne doit pas être vide"
            })
        } else if (contextValue.activeQuestion.type === "file") {
            if (values === "noFile") {
                data.answers = "Non, joindre le(s) fichier(s) plus tard"
                data.stringAnswers = "Non, joindre le(s) fichier(s) plus tard"
            } else {
                data.answers = values;
                data.stringAnswers = values.map((file) => file.name).join(", ");
            }
            data.splitIri = contextValue.activeQuestion.splitIri ? contextValue.activeQuestion.splitIri : null
            data.chatbotAnswersIri = [`/api/chatbotAnswers/${contextValue.activeQuestion.chatbotAnswers[0].id}`]
            setSendFile({
                status: false,
                files: []
            });
        }
        addAnswerToActiveQuestion(data);
    }

    const addAnswerToActiveQuestion = (data) => {
        let actualQuestion = contextValue.allQuestionsAnswered.find((item) => item.id === contextValue.activeQuestion.id);
        if (actualQuestion) {
            let index = contextValue.allQuestionsAnswered.indexOf(actualQuestion);
            contextValue.allQuestionsAnswered[index].answer = data;
        } else {
            contextValue.allQuestionsAnswered.push({id: contextValue.activeQuestion.id, data, answer: null});
        }
        contextValue.setAllQuestionsAnswered(contextValue.allQuestionsAnswered);
        nextQuestion();
    }

    const nextQuestion = async () => {
        contextValue.setQuestionLoaded(false);
        let actualQuestion = contextValue.allQuestionsAnswered.find((item) => item.id === contextValue.activeQuestion.id);
        let actualQuestionAnswer = null

        switch (contextValue.activeQuestion.type) {
            case "choices":
                actualQuestionAnswer = actualQuestion.answer.answers.map((answer) => answer.answer).join(", ");
                break;
            case "choice":
                actualQuestionAnswer = actualQuestion.answer.answers.answer;
                break;
            case "txt":
                actualQuestionAnswer = actualQuestion.answer.answers;
                break;
            case "file":
                actualQuestionAnswer = actualQuestion.answer.stringAnswers;
                break;
            default:
                break;
        }

        let answerData = {
            "answer": actualQuestionAnswer,
            "chatbotAnswersIri": actualQuestion.answer.chatbotAnswersIri,
            "chatbotQuestionIri": actualQuestion.answer.chatbotQuestionIri,
            "chatbotMegaRoutineIri": actualQuestion.answer.chatbotMegaRoutineIri,
            "chatbotMacroRoutineIri": actualQuestion.answer.chatbotMacroRoutineIri,
            "chatbotMiniRoutineIri": actualQuestion.answer.chatbotMiniRoutineIri,
            "splitIri": actualQuestion.answer.splitIri ? actualQuestion.answer.splitIri : null,
        }

        let request = await ChatbotService.getNextQuestion(contextValue.activeQuestion.chatbotProjectId, answerData);

        if (request.status === 200) {

            let data = request.data;
            if (request.data.question === "Quel est l'adresse du projet ?" || request.data.question === "Quel est l'adresse postale de mon client ?") {
                setShowSelectAdress(true)
            } else {
                setShowSelectAdress(false)
            }
            if (request.data.end === false) {
                contextValue.setActiveQuestion(data);
                contextValue.setAllQuestionsAnswered([
                    ...contextValue.allQuestionsAnswered,
                    {
                        id: data.id,
                        data,
                        answer: null
                    }
                ]);
            } else {
                contextValue.setActiveQuestion(false)
            }
        }

        contextValue.setQuestionLoaded(true);
    }

    return (
        <div className="mbe-chatbot__content-answers">
            {
                !contextValue.questionLoaded ? (
                    <p className="text-primary">Chargement des réponses...</p>
                ) : (
                    <>
                        {
                            contextValue.activeQuestion && (
                                <>
                                    {
                                        contextValue.activeQuestion.type === "txt" && (
                                            <div className="mbe-chatbot__content-answers-text">
                                                <label htmlFor="answer" className="text-micro paragraph_bold color-dark-grey">
                                                    Réponse {
                                                    txtValue.error && (
                                                        <span className="color-error">
                                {txtValue.errorText}
                              </span>
                                                    )
                                                }
                                                </label>
                                                {contextValue.activeQuestion.chatbotAnswers[0].action === "address" ?

                                                    <GooglePlacesAutocomplete
                                                        apiKey={apiKey}
                                                        autocompletionRequest={{
                                                            types: ['address'],
                                                        }}
                                                        selectProps={{
                                                            txtValue,
                                                            styles: {
                                                              input: (provided) => ({
                                                                  ...provided,
                                                                  color: 'black',
                                                                  minHeight: '80px',
                                                                  fontSize: '14px',
                                                                  fontFamily: 'Polygon regular',
                                                              }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: 'black',
                                                                    fontSize: '14px',
                                                                    fontFamily: 'Polygon regular',
                                                                }),
                                                                placeholder: (provided) => ({
                                                                    ...provided,
                                                                    color: 'black',
                                                                    fontSize: '14px',
                                                                    fontFamily: 'Polygon regular',
                                                                }),
                                                                option: (provided) => ({
                                                                    ...provided,
                                                                    color: 'black',
                                                                    fontSize: '14px',
                                                                    fontFamily: 'Polygon regular',
                                                                }),
                                                            },

                                                            onChange: (e) => {
                                                                setTxtValue({
                                                                    value: e.value.description,
                                                                    error: false
                                                                });

                                                                handleAddressChange(e.value)
                                                            },
                                                        }}
                                                    /> : (
                                                        <textarea
                                                            autoFocus
                                                            value={txtValue.value}
                                                            name="answer"
                                                            id="answer"
                                                            placeholder="Merci d'écrire votre réponse"
                                                            rows="4" cols="50"
                                                            onChange={(e) => {
                                                                setTxtValue({
                                                                    value: e.target.value,
                                                                    error: false
                                                                });
                                                            }}
                                                            onKeyUp={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    submitAnswer(txtValue);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }
                                                <img
                                                    src="/images/pictos/arrowSubmit-green.svg"
                                                    onClick={submitAnswer}
                                                />

                                            </div>
                                        )
                                    }
                                    {
                                        contextValue.activeQuestion.type === "choices" && (
                                            <Formik
                                                initialValues={{
                                                    answers: contextValue.activeQuestion.chatbotAnswers.map((answer) => ({
                                                        id: answer.id,
                                                        answer: answer.answer,
                                                        checked: false
                                                    }))
                                                }}
                                                onSubmit={(values) => {
                                                    submitAnswer(values);
                                                }}
                                            >
                                                {({values}) => (
                                                    <Form>
                                                        <div className="mbe-chatbot__content-answers-choices">
                                                            <div className="mbe-chatbot__content-answers-choices-header">
                                                                <p className="text-micro paragraph_bold color-dark-grey">
                                                                    Merci de sélectionner la ou les réponses qui vous conviennent
                                                                </p>
                                                                <button
                                                                    className={"btn-default btn-default-mini btn-default-green" + (values.answers.filter((answer) => answer.checked === true).length === 0 ? " btn-default-inactive" : "")}
                                                                    type="submit"
                                                                    disabled={values.answers.filter((answer) => answer.checked === true).length === 0}
                                                                >
                                                                    Valider la réponse
                                                                </button>
                                                            </div>
                                                            <FieldArray name="answers">
                                                                <>
                                                                    <div>
                                                                        {
                                                                            contextValue.activeQuestion.chatbotAnswers.map((answer, index) => (
                                                                                <label
                                                                                    htmlFor={`answers[${index}].checked`}
                                                                                    className="text-primary color-primary mbe-chatbot__content-answers-choices-item"
                                                                                    key={index}
                                                                                >
                                                                                    <Field
                                                                                        type="checkbox"
                                                                                        name={`answers[${index}].checked`}
                                                                                        id={`answers[${index}].checked`}
                                                                                    />
                                                                                    {answer.answer}
                                                                                </label>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </>
                                                            </FieldArray>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )
                                    }


                                    {
                                        contextValue.activeQuestion.type === "choice" && (
                                            <div className="mbe-chatbot__content-answers-choice">
                                                <p className="text-micro paragraph_bold color-dark-grey">
                                                    Merci de sélectionner la réponse qui vous convient
                                                </p>
                                                <div>
                                                    {
                                                        contextValue.activeQuestion.chatbotAnswers.map((answer, index) => (
                                                            <button
                                                                onClick={() => submitAnswer(answer)}
                                                                className="btn-default btn-default-blue"
                                                                key={index}
                                                            >
                                                                {answer.answer}
                                                            </button>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        contextValue.activeQuestion.type === "file" && (
                                            <div className="mbe-chatbot__content-answers-file">
                                                {
                                                    !sendFileSelected.status ? (
                                                        <>
                                                            <label htmlFor="answer" className="btn-default">
                                                                Oui, joindre le(s) fichier(s)
                                                            </label>
                                                            <input
                                                                type="file"
                                                                id="answer"
                                                                accept={contextValue.activeQuestion.categoryType === "media-project" ? ".png,.jpeg,.jpg" : ".png,.jpeg,.jpg,.pdf"}
                                                                name="answer"
                                                                onChange={submitValueFile}
                                                                multiple
                                                            />
                                                            <button
                                                                onClick={() => submitAnswer("noFile")}
                                                                className="btn-default btn-default-secondary noHover">Non,
                                                                joindre le(s) fichier(s) plus
                                                                tard
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <div className="mbe-chatbot__content-answers-file__valid">
                                                            <p className="text-primary">Soumettre ces fichiers : <span> </span>
                                                                {
                                                                    sendFileSelected.files.map((file, index) => (
                                                                        <span key={index}>
                                      {
                                          index > 0 && (
                                              <span>, </span>
                                          )
                                      }
                                                                            {file.name}
                                    </span>
                                                                    ))
                                                                }
                                                            </p>
                                                            <button
                                                                className="btn-default btn-default-secondary noHover"
                                                                onClick={() => removeFiles()}
                                                            >
                                                                Annuler
                                                            </button>
                                                            <button
                                                                className="btn-default btn-default-green"
                                                                onClick={() => submitAnswer(sendFileSelected.files)}
                                                            >
                                                                Valider la réponse
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default ChatbotAnswers;



<div className=" css-1nmdiq5-menu" id="react-select-2-listbox"><div className=" css-1n6sfyn-MenuList"><div className=" css-eubq20-option" aria-disabled="false" id="react-select-2-option-0" tabIndex="-1">8 Allée Pierre Loti, Pierrelatte, France</div><div className=" css-1vcz6za-option" aria-disabled="false" id="react-select-2-option-1" tabIndex="-1">8 Allée Pierre Loti, Clamart, France</div><div className=" css-1vcz6za-option" aria-disabled="false" id="react-select-2-option-2" tabIndex="-1">8 Allée Pierre Loti, Antony, France</div><div className=" css-1vcz6za-option" aria-disabled="false" id="react-select-2-option-3" tabIndex="-1">8 Allée Pierre Loti, Lormont, France</div><div className=" css-1vcz6za-option" aria-disabled="false" id="react-select-2-option-4" tabIndex="-1">8 Allée Pierre Loti, Guidel, France</div></div></div>
