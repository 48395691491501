import React from "react";
import PrestationsActions from "./Actions/PrestationsActions";
import parse from 'html-react-parser'

export const CATALOG_PAGE_COLUMNS = [
	{
		Header: "Code Article",
		accessor: "reference"
	},
	{
		Header: "Zone",
		accessor: "zone"
	},
	{
		Header: "Titre",
		accessor: "title"
	},
	{
		Header: "Description prestation",
		accessor: "description",
		Cell: cellInfo => {
			return (
				<span> {parse(cellInfo.row.original.description)} </span>
			)
		}
	},
	{
		Header: "Typologie",
		accessor: "niceType"
	},
	{
		Header: "Marge",
		accessor: "margin",
		Cell: cellInfo => {
			return (
				<span>
					{(cellInfo.row.original.price - cellInfo.row.original.spent).toFixed(2)} €
				</span>
			)
		}
	},
	{
		Header: "Prix de vente (HT)",
		accessor: "price",
		Cell: cellInfo => {
			return (
				<span>
					{cellInfo.row.original.price.toFixed(2)} €
				</span>
			)
		}
	},
	{
		Header: "Actions",
		accessor: "details",
		Cell: cellInfo => {
			return (
				<PrestationsActions cellInfo={cellInfo.row.original}/>
			)
		}
	}
];
