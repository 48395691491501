import React from "react";
import ButtonPopupDatepicker from "../../Atomes/Buttons/ButtonPopupDatepicker";
import Select from "../../Atomes/Select/Select";

const FilterSelectDatepicker =
	({
		 defaultValue,
		 options,
		 values,
		 date,
		 onChangeDate,
		 onChange,
		 resetDateSelected
	 }) => {
		return (
			<div className={"filter-select-button"}>
				<Select
					onChange={onChange}
					styleSelect={"select-custom-secondary"}
					defaultValue={defaultValue}
					data={options}
					value={values}
				/>
				<ButtonPopupDatepicker
					date={date}
					onChange={onChangeDate}
					resetDateSelected={resetDateSelected}
				/>
			</div>
		)
	}
export default FilterSelectDatepicker;