import React from 'react';
import Select from "../../Atomes/Select/Select";

const FilterSelect = ({
	onChange,
	defaultValue,
	values,
	options,
}) => {
	return (
		<div className={"filter-search-select"}>
			<Select defaultValue={defaultValue} value={values} data={options} styleSelect={"select-custom-secondary"}
				onChange={onChange} />
		</div>
	);
};

export default FilterSelect;
