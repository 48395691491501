import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./services/useAuth";
import {ToastContainer} from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
	<AuthProvider>
		<App/>
		<ToastContainer/>
	</AuthProvider>
	</BrowserRouter>);
