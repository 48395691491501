import React, {useState} from "react";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import exportFileService from "../../../services/ExportFileService";
import PopUpArchiveHomeAdmin from "../../../components/Molecules/PopUp/PopUpArchiveHomeAdmin";

export const REVIVAL_COMMERCIAL_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Nom client",
		accessor: "nom_du_client",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.niceNames}</Link>
			)
		}
	},
	{
		Header: "Téléphone",
		accessor: "client.phone"
	},
	{
		Header: "Adresse e-mail",
		accessor: "client.email"
	},
	{
		Header: "Statut",
		accessor: "statut",
		Cell: cellInfo => {
			if (cellInfo.row.original.stageStatus === "En cours") {
				return (
					<div className="badge badge-success">
						<div className={"pin-success"}></div>
						En cours</div>
				)
			} else if (cellInfo.row.original.stageStatus === "En attente") {
				return (
					<div className="badge badge-waiting">
						<div className={"pin-wainting"}></div>
						Dossier en retard</div>
				)
			} else {
				return (
					<div className="badge badge-error">
						<div className={"pin-error"}></div>
						{cellInfo.row.original.niceStage}</div>
				)
			}

		}
	},
	{
		Header: "Date",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = "N/A";
			if (cellInfo.row.original.createdAt) {
				date = new Date(cellInfo.row.original.createdAt);
				date = moment(date).format("DD/MM/YYYY");
			}
			return date
		}
	},
	{
		Header: "Actions",
		accessor: "",
		Cell: cellInfo => {

			const downloadFile = () => {
				const date = moment().format("L").split("/").join("-")
				exportFileService.exportFile(`/projects/${cellInfo.row.original.id}/recovery`, `export-relance-suivi-facture-${date}`, {}, "zip")
			}

			const handleSubmit = () => {
				exportFileService.exportFile(`/projects/${cellInfo.row.original.id}/recovery-letter`, 'export-lettre-de-recouvrement', {}, "pdf")
			}
			const [showPopUpClosing, setShowPopUpClosing] = useState(false);

			return (
				<>
					<div className={"actions-controller"}>
						<img src={"/images/pictos/download.svg"} onClick={() => downloadFile()} title={"Télécharger le dossier"}/>
						<img src={"/images/pictos/file.svg"} onClick={() => handleSubmit()}
						     title={"Générer la lettre de recouvrement"}/>
						<img src={"/images/pictos/box.svg"} onClick={() => setShowPopUpClosing(true)}
						     title={"Archiver la relance"}/>
					</div>

					{
						showPopUpClosing ? (
							<PopUpArchiveHomeAdmin id={cellInfo.row.original.id} onClick={() => setShowPopUpClosing(false)}/>
						) : null
					}
				</>
			)
		}
	},
]
