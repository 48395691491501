import React, {useState} from 'react';
import PopUpWatchDocument from "./PopUpWatchDocument";
import FileMimeType from "../../../../../../utils/FileMimeType";
import {AdminService} from "../../../../../../services/AdminService";
import {toast} from "react-toastify";

const StudyFolderMenuShowElement = ({
	                                    fakeDataProject,
	                                    titleSection,
	                                    input,
	                                    onClickDelete,
	                                    onClickUpload,
	                                    className
                                    }) => {

	const [show, setShow] = useState(false);

	const [showPopups, setShowPopups] = useState(Array(fakeDataProject.length).fill(false));
	const handleDownload = (url, filename) => {
		if (url !== undefined && filename !== undefined) {
			AdminService.getFile(url, filename);
			toast.success('Téléchargement en cours');
		} else {
			toast.error('Une erreur est survenue');
		}
	}

	const togglePopup = (index) => {
		const updatedPopups = [...showPopups];
		updatedPopups[index] = !updatedPopups[index];
		setShowPopups(updatedPopups);
	};

	return (
		<div className={"section-study-folder-menu-content-element-container"}>
			<div className={"section-study-folder-menu-content-element-container-element"}>
				<p className={"text-primary paragraph_bold color-white"}>{titleSection}</p>
				{input}
			</div>

			<div className={"section-study-folder-menu-content-element-container-result"}>
				<>
					{
						fakeDataProject.map((plan, index) => {
							return (
								<div key={index}
								     className={"section-study-folder-menu-content-element-container-result-element"}>
									{
										titleSection === "Images" ? (
											<>
												<img src={process.env.REACT_APP_PROD_URL + plan.imageUrl} alt={"file"} className={"img"}
												     onClick={() => togglePopup(index)}/>
												{showPopups[index] && (
													<PopUpWatchDocument
														images={fakeDataProject.map(item => ({
															img: process.env.REACT_APP_PROD_URL + item.imageUrl,
															name: item.title,
															downloadLink: item.downloadUrl,
															filename: item.filename,
														}))}
														initialImageIndex={index}
														onClose={() => togglePopup(index)}
													/>
												)}
											</>
										) : (
											<div onClick={() => handleDownload(plan.downloadUrl, plan.filename)}>
												<FileMimeType mimeType={plan.mimeType}/>
											</div>
										)
									}
									{
										plan.extension === "pdf" ? (
											<div className={"upload-pdf-study-folder"}>
												<img src={"/images/pictos/add-plus-circle-primary.svg"} alt={"file"} className={"img"}
												     onClick={() => onClickUpload(plan.id)}/>
											</div>
										) : null
									}
									<img src={"/images/pictos/red-cross.svg"} alt={`delete file ${plan.id}`} className={"delete-file"}
									     onClick={() => onClickDelete(plan.id)} // Pass index and ID to onClickDelete
									/>
									<p className={className ? className : "color-white"}>{plan.title}</p>
								</div>
							)
						})
					}
				</>
			</div>

		</div>
	);
};

export default StudyFolderMenuShowElement;
