import React, { useEffect, useState } from "react";
import KpiService from "../../../../../../services/KpiService";
import { toast } from "react-toastify";
import moment from 'moment';

const PriceCalculated = ({ quotes, userFilter, dateEnd, dateStart }) => {

	const objectiveStart = dateStart ? moment(dateStart) : moment().subtract(1, 'year');
	const objectiveEnd = dateEnd ? moment(dateEnd) : moment();
	const daysPeriod = moment(objectiveEnd).diff(moment(objectiveStart), 'days');
	const objective = userFilter?.objective ?? 0;
	const dailyObjective = objective * 12 / 365;
	const objectiveOnPeriod = dailyObjective * daysPeriod;

	let [objectives, setObjectives] = useState(0);

	const getObjectiveKpis = async () => {
		const request = await KpiService.getCollaborateurObjective(userFilter.id, {
			"dateStart": objectiveStart.format('YYYY-MM-DD'),
			"dateEnd": objectiveEnd.format('YYYY-MM-DD')
		})
		if (request.request.status === 200) {
			setObjectives(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des données")
		}
	}

	useEffect(() => {
		getObjectiveKpis();
	}, [quotes])

	return (
		<>
			<div className={"engineer-objectives-section-total"}>
				<div className={"engineer-objectives-section-total-container"}>
					<div className={"engineer-objectives-section-total-container-name"}>
						<p className="text-primary paragraph_bold">&nbsp;</p>
						<p className="text-primary paragraph_bold">Total CA (HT)</p>
						<p className="text-primary paragraph_bold">Total déboursé (HT)</p>
						<p className="text-primary paragraph_bold">Total marge commerciale (HT)</p>
						<p className="text-primary paragraph_bold">Objectif commercial</p>
						<p className="text-primary paragraph_bold">Objectif commercial réalisé</p>
						<p className="text-primary paragraph_bold">Bonus commercial ({(userFilter?.bonus * 100).toFixed(2)}%)</p>
					</div>
					<div className={"engineer-objectives-section-total-container-price"}>
						<p className="text-primary">Du {objectiveStart.format('DD/MM/YYYY')} au {objectiveEnd.format('DD/MM/YYYY')}</p>
						<p className="text-primary paragraph_bold">{objectives.total?.toFixed(2)} €</p>
						<p className="text-primary paragraph_bold">{objectives.totalSpent?.toFixed(2)} €</p>
						<p className="text-primary paragraph_bold">{objectives.totalMargin?.toFixed(2)} €</p>
						<p className="text-primary paragraph_bold">{objectiveOnPeriod.toFixed(2)} €</p>
						<p className="text-primary paragraph_bold">{(objectives.total - objectiveOnPeriod) > 0 ? "+" + (objectives.total - objectiveOnPeriod).toFixed(2) : (objectives.total - objectiveOnPeriod).toFixed(2)} €</p>
						<p className="text-primary paragraph_bold">{(objectives.total - objectiveOnPeriod) > 0 ?((objectives.total - objectiveOnPeriod) * userFilter?.bonus).toFixed(2) : 0} €</p>
					</div>
				</div>
			</div>
		</>
	);
};
export default PriceCalculated;
