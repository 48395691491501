import React, { useState, useEffect, useContext, useRef } from 'react';
import { ChatbotAdminActionsContext } from '../ChatbotAdmin';

import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper.css';

import InputSearch from '../../../../../components/Atomes/Inputs/InputSearch'
import CardMacroroutine from '../../../../../components/Molecules/Cards/Chatbot/CardMacroroutine'
import PopupDeleteMacroroutine from './Popup/PopupDeleteMacroroutine';
import PopupEditMacroroutine from './Popup/PopupEditMacroroutine';
import PopUpForm from '../../../../../components/Molecules/PopUp/PopUpForm';

import { AdminService } from '../../../../../services/AdminService';

const ChatbotMacroRoutineAdmin = () => {

  const chatbotContext = useContext(ChatbotAdminActionsContext)

  const selectFilterOptions = [
    "Définition du profil",
    "Informations administratives",
    "Autre",
  ]
  const selectFilterValues = [
    "macro-routine-profile",
    "macro-routine-administrative",
    "macro-routine-others",
  ]

  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [macroroutines, setMacroroutines] = useState([])
  const [macroroutinesLoading, setMacroroutinesLoading] = useState(true)
  const [showPopupDelete, setShowPopupDelete] = useState(false)
  const [macroroutineToDelete, setMacroroutineToDelete] = useState(null)
  const [showPopupEdit, setShowPopupEdit] = useState(false)
  const [macroroutineToEdit, setMacroroutineToEdit] = useState(null)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    getMacroroutines();
  }, [filter, search])
  
  useEffect(() => {
    if (chatbotContext.chatbotAdminActionsContext.name === "macro-routine") {
      getMacroroutines();
      chatbotContext.chatbotAdminActionsContext = {}
    }
  }, [chatbotContext])

  const getMacroroutines = async () => {
    setMacroroutinesLoading(true)
    let request = await AdminService.getWithParams("/chatbot-macro-routines", { 'search': search, 'category.type': filter, 'pagination': 'false' })
    if (request.status === 200) {
      setMacroroutines(request.data["hydra:member"])
      setMacroroutinesLoading(false)
    }
  }

  const closePopup = () => {
    setShowPopupDelete(false)
    setMacroroutineToDelete(null)
    setShowPopupEdit(false)
    setMacroroutineToEdit(null)
  }

  const openPopupDelete = (id, name) => {
    setMacroroutineToDelete({
      id: id,
      title: name,
    })
    setShowPopupDelete(true)
  }

  const confirmDeleteMacroroutine = async () => {
    let request = await AdminService.deleteById('/chatbot-macro-routines', macroroutineToDelete.id)
    if (request.status === 204) {
      toast.success("La macro-routine a bien été supprimée")
      closePopup()
      getMacroroutines()
    }
  }

  const openPopupEdit = (macroroutineId) => {
    setMacroroutineToEdit(macroroutineId)
    setShowPopupEdit(true)
  }

  return (
    <section className='adminChatbot-macroroutine'>
      <div className='adminChatbot-macroroutine__header'>
        <div className={"select"}>
          <select className={"select-custom-secondary"} onChange={(e) => setFilter(e.target.value)}>
            <option value={""} defaultValue={""}>Catégorie</option>
            {selectFilterOptions.map((item, index) => <option key={index} value={selectFilterValues[index]}>{item}</option>)}
          </select>
        </div>
        <InputSearch
          name="search"
          placeholder="Rechercher une macro-routine"
          onChangeSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
          onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
        />
        {
          macroroutines.length > 3 && (
            <div>
              <img src="/images/pictos/arrow-slider.svg" alt="previous arrow" ref={navigationPrevRef} />
              <img src="/images/pictos/arrow-slider.svg" alt="next arrow" ref={navigationNextRef} />
            </div>
          )
        }
      </div>
      {
        macroroutinesLoading ? (
          <>
            <div className='adminChatbot-macroroutine__skeleton'>
              <Skeleton height={500} />
              <Skeleton height={500} />
              <Skeleton height={500} />
            </div>
          </>
        ) : (
          <>
            <div className='adminChatbot-macroroutine__wrapper'>
              {
                macroroutines.length === 0 && (
                  <p className='text-primary paragraph_bold'>Aucune macro-routine trouvée</p>
                )
              }
              <Swiper
                modules={[Navigation]}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                spaceBetween={15}
                slidesPerView={3}
                slidesPerGroup={3}
              >
                {
                  macroroutines.map((macroroutine, index) => (
                    <SwiperSlide
                      key={index}
                    >
                      <CardMacroroutine
                        macroroutine={macroroutine}
                        openPopupDelete={openPopupDelete}
                        openPopupEdit={openPopupEdit}
                      />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>

            {
              showPopupDelete && (
                <PopupDeleteMacroroutine
                  macroroutine={macroroutineToDelete}
                  showPopup={showPopupDelete}
                  closeAction={closePopup}
                  validAction={confirmDeleteMacroroutine}
                />
              )
            }

            {
              showPopupEdit && (
                <PopUpForm
                  large={true}
                  show={showPopupEdit}
                  form={<PopupEditMacroroutine macroroutineId={macroroutineToEdit} />}
                  onClick={() => setShowPopupEdit(false)}
                  title={"Modifier une macro-routine"}
                />
              )
            }

          </>
        )
      }
    </section>
  )
}

export default ChatbotMacroRoutineAdmin