import React, { useState } from 'react';
import FormEditCollaborator from "./../Forms/FormEditCollaborator";
import PopUpForm from "../../../../../../components/Molecules/PopUp/PopUpForm";
import {userRoleTranslate} from "../../../../../../utils/UserRolesUtils";

const TableListing = ({ collaborators }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [collaboratorID, setCollaboratorID] = useState(0);

  const DisplayEditModalCollaborator = (id) => {
    setCollaboratorID(id)
    setShowPopUp(!showPopUp)
  }

  return (
    <div className='collaborators-tableListing'>

      <table className='collaborators-tableListing-container customTable table-alternateBgColor'>
        <thead>
          <tr>

            <th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Identifiant</th>
            <th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary customTable-cellBg'>Nom</th>
            <th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Prénom</th>
            <th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary customTable-cellBg'>Rôle</th>
            <th className='customTable-cellMaxWidth text-tertiary'>Niveau</th>
            <th className='customTable-cellMaxWidth text-tertiary customTable-cellBg'>Taux horaire</th>
            <th className='customTable-cellMaxWidth text-tertiary'>Base horaire</th>
            <th className='customTable-cellMaxWidth text-tertiary customTable-cellBg'>Forfait</th>
            <th className='customTable-cellMaxWidth text-tertiary'>Obj Prev. An.</th>
            <th className='customTable-cellMaxWidth text-tertiary customTable-cellBg'>Présence</th>
            <th className='customTable-cellMaxWidth text-tertiary'>Obj Prev. Mens.</th>
            <th className='customTable-cellMaxWidth text-tertiary customTable-cellBg'>Actions</th>
          </tr>
        </thead>

        <tbody>
          {
            collaborators.map((collaborator) => {
              const role = userRoleTranslate(collaborator.highestRole)
              return (
              <tr key={collaborator.id}>

                <td className='customTable-cellAlignLeft text-tertiary'>{collaborator.reference}</td>
                <td className='text-tertiary customTable-cellAlignLeft customTable-cellBg'>{collaborator.lastname}</td>
                <td className='customTable-cellAlignLeft text-tertiary'>{collaborator.firstname}</td>
                <td className='customTable-cellAlignLeft text-tertiary customTable-cellBg'>{role}</td>
                <td className='text-tertiary '>{collaborator.wl ? collaborator.wl : "N/A"}</td>
                <td className='text-tertiary customTable-cellBg'>{collaborator.rateH ? collaborator.rateH : "N/A"}</td>
                <td className='text-tertiary '>{collaborator.baseH ? collaborator.baseH : "N/A"}</td>
                <td className='text-tertiary customTable-cellBg'>{collaborator.package}</td>
                <td className='text-tertiary '>{(collaborator.objective ?? 0) * 12}</td>
                <td className='text-tertiary customTable-cellBg'>{collaborator.presence}</td>
                <td className='text-tertiary '>{(collaborator.objective ?? 0).toFixed(2)}</td>
                <td className='text-tertiary color-blue-jeans customTable-cellBg'>
                  <img alt={"pencil edit collaborator"} onClick={() => DisplayEditModalCollaborator(collaborator.id)} src={"/images/pictos/pencil-blue.svg"} />
                </td>

              </tr>
              )
            })
          }
        </tbody>

      </table>
      <PopUpForm large={true} title={"Modifier le collaborateur"} form={<FormEditCollaborator setShowPopUp={setShowPopUp} id={collaboratorID} />}
        onClick={DisplayEditModalCollaborator} show={showPopUp} />
    </div>
  );
};

export default TableListing;
