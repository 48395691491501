import React from "react";

export const CCTP_COLUMNS = [
	{
		Header: "Titre et contenu",
		accessor: "titre_contenu",
		Cell: cellInfo => {
			return (
				<div>
					{
						cellInfo.row.original.position &&
						<h3 className="adminCctpView-sortingTable--title">{cellInfo.row.original.position}</h3>
					}
					<p dangerouslySetInnerHTML={{__html: cellInfo.row.original.content}}></p>
				</div>
			)
		}
	},
	{
		Header: "Référence",
		accessor: "reference",
	}
]
