
import React, {useState} from "react";
import {GetAllowedRoutes, GetAllowedSidebarElement} from "../utils/RolesChecker";
import PrivateRoutesConfig from "../config/PrivateRoutesConfig";
import MapAllowedRoutes from "./routes/MapAllowedRoutes";
import SidebarConfig from "../config/SidebarConfig";
import SideBar from "../views/Layouts/SideBar";
import TitleHelloUser from "../components/Atomes/Title/TitleHelloUser";
import {useLocation} from "react-router-dom";
import Chatbot from "../views/Chatbot/Chatbot";
import {useAuth} from "../services/useAuth";
import {dataChatInterneAllowed} from "../utils/UserRolesUtils";
import Chat from "../views/Chat/Chat";
import MenuBurger from "../views/Layouts/MenuBurger";

function PrivateRoutes() {

	let allowedRoutes = [];
	let allowedSidebar = [];
	const {role} = useAuth();
	const dataChatInterne = dataChatInterneAllowed;

	let allowedRoutesChatInterne = dataChatInterne.includes(role[0]);

	allowedRoutes = GetAllowedRoutes(PrivateRoutesConfig);
	allowedSidebar = GetAllowedSidebarElement(SidebarConfig);

	const pathname = useLocation();
	const isChatbot = pathname.pathname === "/chatbot";
	let titleHelloUser = PrivateRoutesConfig.filter(route => route.path === pathname.pathname)[0]?.titleHelloUser;

	const [isSidebarExpand, setIsSidebarExpand] = useState(false);
	const toggleSidebar = () => {
		setIsSidebarExpand(!isSidebarExpand);
	}


	return (
		<>
			{
				isChatbot ? (
					<Chatbot/>
				) : (
					<>
						<SideBar allowedSidebar={allowedSidebar} toggleSidebar={toggleSidebar} isSidebarExpand={isSidebarExpand}/>
						<MenuBurger allowedSidebar={allowedSidebar} />
						<div className={`page-container ${isSidebarExpand ? "page-container-collapsed" : ""}`}>
							<div>
								{titleHelloUser || titleHelloUser !== undefined ? <TitleHelloUser/> : null}
								<MapAllowedRoutes
									routes={allowedRoutes}
									basePath="/"
									isAddNotFound
								/>
								{
									allowedRoutesChatInterne ? (
										<Chat/>
									) : null
								}
							</div>
						</div>
					</>
				)
			}
		</>
	)
}

export default PrivateRoutes;
