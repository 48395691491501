import React, { useEffect, useState } from 'react';
import ComCalendarPart from './partials/ComCalendarPart';
import ComBookingPart from './partials/ComBookingPart';
import ComSlotsPart from "./partials/ComSlotsPart";
import MbeCalendar from '../../../../../components/Molecules/Calendar/MbeCalendar';
import TaskService from '../../../../../services/TaskService';
import { ConvertVisitsToFullCalendarEvents } from '../../../../../utils/TaskUtils';
import { toast } from 'react-toastify';
import { dataRegionsArray } from '../../../../../utils/RegionsUtils';
import { ConvertObjectToUrlParams } from '../../../../../utils/StringUtils';
import CollaboratorService from '../../../../../services/CollaboratorService';
import FilterTwoSelects from '../../../../../components/Molecules/Filter/FilterTwoSelects';

const ComCalendarView = () => {
    const [view, setView] = useState('calendar'); // calendar, booking, slots

      // "Regions" filter settings
  const firstSelectDefaultValue = "Toutes les agences";
  const firstSelectValue = dataRegionsArray;
  const firstSelectOptions = dataRegionsArray;
  const [region, setRegion] = useState("");
  const filterByRegion = (event) => {
    if (event.target.value === "Toutes les agences") {
      setRegion("")
    } else {
      setRegion(event.target.value)
    }
  }

  const getCollaborators = async () => {
    const urlIngDiag = ConvertObjectToUrlParams("ings", { 
      regions: region ,
      pagination: 'false',
    })
    const request = await CollaboratorService.getCollaborators(urlIngDiag)
    if (request.request.status === 200) {
      let ings = request.data['hydra:member']
      let availableIngs = []
      ings.forEach(ing => {
        if (ing.roles.includes("ROLE_ING_DIAG")) {
          availableIngs.push(ing)
        }
      })
      setCollaborators(availableIngs)
    } else {
      toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
    }
  }

  // "ObjectifsViewIngRes" filter settings
  const secondSelectDefaultValue = "Tous les collaborateurs";
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorFilter, setCollaboratorFilter] = useState("");
  const filterByCollaborator = (event) => {
    if (event.target.value === "Tous les collaborateurs") {
      setCollaboratorFilter("")
    } else {
      setCollaboratorFilter(event.target.value)
    }
  }

    // Tasks
  const [tasks, setTasks] = useState([]);
  const getTasks = async () => {
    const request = await TaskService.getTasksUser("visits", { 'pagination': 'false', 'user.id': collaboratorFilter, 'region': region })
    if (request.request.status === 200) {
      let tasks = request.data['hydra:member'];
      tasks = ConvertVisitsToFullCalendarEvents(tasks)
      setTasks(tasks)
    } else {
      toast.error("Une erreur est survenue lors de la récupération des tâches")
    }
  };

  useEffect(() => {
    if(view === 'all') {
        getTasks()
        getCollaborators()
    }else {
        setCollaboratorFilter("")
        setRegion("")
    }
  }, [view, region, collaboratorFilter]);

    return (
        <>
            <div className='calendar-tabs'>
                <button onClick={() =>setView('calendar')} className={` ${view === 'calendar' ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Mon calendrier</button>
                <button onClick={() => setView('booking')} className={` ${view === 'booking' ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Réserver une visite</button>
                <button onClick={() => setView('slots')} className={` ${view === 'slots' ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Créneaux de visites</button>
                <button onClick={() => setView('all')} className={` ${view === 'all' ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Créneau réservé</button>
            </div>


            {view === 'calendar' ? (
                <ComCalendarPart />
            ) : view === 'booking' ? (
                <section className='mbe-section mbe-bookingSlot-container'>
                    <div className="mbe-bookingSlot-container-headline">
                        <h3 className="color-black">Réserver un créneau de visite pour le projet d’un client</h3>
                        <p className="text-primary">
                            Sélectionner le numéro d’un projet ainsi que le nom d’un client pour sélectionner un créneau de visite.
                            <br/>
                            Votre demande sera envoyée à l’administrateur qui devra valider votre demande depuis son espace
                        </p>
                    </div>
                    <ComBookingPart />
                </section>
            ) : view === 'slots' ? (
                <section className='mbe-section mbe-bookingSlot-container'>
                    <ComSlotsPart />
                </section>
            ): view === 'all' ? (
                <>
                    <FilterTwoSelects
                        defaultValue={firstSelectDefaultValue}
                        firstValue={firstSelectValue}
                        options={firstSelectOptions}
                        firstOnChange={filterByRegion}
                        secondDefaultValue={secondSelectDefaultValue}
                        secondValue={collaborators.map(collaborator => collaborator.id)}
                        secondOptions={collaborators.map(collaborator => collaborator.niceNames)}
                        secondOnChange={filterByCollaborator}
                    />
                    <MbeCalendar
                        tasks={tasks}
                        taskManager={false}
                        withNotifications={false}
                    />
                </>
            ) : null}
        </>
    );
};

export default ComCalendarView;

