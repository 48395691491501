import React, {createContext, useEffect, useState} from 'react';
import Skeleton from "react-loading-skeleton";
import {SortingTable} from "../../../../components/Molecules/Tables/SortingTable";
import {PARTNER_COLUMNS} from "../../../../config/ArrayColumns/Admin/PartnersColumns";
import {toast} from "react-toastify";
import PartnerService from "../../../../services/PartnerService";
import TableHeadingAdd from "../../Coms/Dir/Collaborators/Partials/TableHeadingAdd";
import PopUpForm from "../../../../components/Molecules/PopUp/PopUpForm";
import FormAddPartnerAdmin from "../../../../components/Molecules/Form/Partners/FormAddPartnerAdmin";

export const StateRequest = createContext()
const Partners = () => {

	const [stateRequest, setStateRequest] = useState("")
	const [partnersLink, setPartnersLink] = useState([]);
	const [addLink, setAddLink] = useState(false)
	const [loading, setLoading] = useState(true)

	const getPartnersLink = async () => {
		const request = await PartnerService.getAllPartnersLink({pagination: "true"})
		if (request.request.status === 200) {
			setLoading(false)
			setPartnersLink(request.data)

		} else {
			toast.error("Une erreur est survenue lors de la récupération des liens partenaires")
		}
	}

	const DisplayToast = (type) => {
		if (type === "success edit") {
			toast.success("Le partenaire a bien été modifié")
		} else if (type === "success delete") {
			toast.success("Le partenaire a bien été supprimé")
		} else if (type === "success add") {
			toast.success("Le lien partenaire a bien été ajouté")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	useEffect(() => {
		setLoading(true)
		if (stateRequest !== "") {
			window.scrollTo(0, 0);
			DisplayToast(stateRequest)
		}
		getPartnersLink()
	}, [stateRequest]);


	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<TableHeadingAdd addTitle={"Ajouter un lien partenaire"} title={"Lien partenaires"}
			                 onClick={() => setAddLink(true)}/>
			<div className={"section-partners-admin-container"}>
				{loading ? <Skeleton height={1000}/> :
					<div className="mbe-section-row adminProjectsView-sortingTable"><SortingTable numberElement={20}
					                                                                              columnsTable={PARTNER_COLUMNS}
					                                                                              dataTable={partnersLink}/>
					</div>}
			</div>

			{
				addLink ? (
					<PopUpForm title={"Ajouter un lien partenaire"} large={true}
					           form={<FormAddPartnerAdmin setIsActive={setAddLink}/>} show={addLink}
					           onClick={() => setAddLink(false)}/>
				) : null
			}
		</StateRequest.Provider>
	);
};

export default Partners;
