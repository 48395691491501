import api from "./APIService";

const deleteUser = async (id) => {
    return await api.del(`/users/${id}`);
};

const getAllUsers = async (url) => {
    const request = await api.getAllData(url)
    return request
}


const getSingleUser = async (id) => {
    const request =  await api.getAllData(`users/${id}`)
    return request
}

const updateUser = async (url, data) => {
  const request = await api.patch(url, data)
  return request
}

const addUser = async (url,data) => {
    const request =  await api.postBody(url, data)
    return request
}

const editPassword = async (id, data) => {
    return await api.patch(`/users/${id}`, data)
}

const UserService = {
    deleteUser,
    getAllUsers,
    updateUser,
    addUser,
    getSingleUser,
    editPassword
};

export default UserService;
