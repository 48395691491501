import React from "react";
import CardTasksInProgress from "../../../../../../components/Molecules/Cards/Tasks/CardTasksInProgress";


const HomeIngProdTasks = ({projects, taskValidated}) => {

	return (
		<div className="projects-in-progress">
			<h3 className="color-primary">Tâches en cours</h3>
			<div className="projects-in-progress-container projects-in-progress-engineer-tasks">
				{projects.map((project, index) => {
					return (
						<CardTasksInProgress
							key={index}
							project={project}
							taskValidated={taskValidated}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default HomeIngProdTasks;