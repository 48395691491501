import api from "./APIService";
import {ConvertObjectToParams, ConvertObjectToUrlParams} from "../utils/StringUtils";

const getPartners = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`partners`, param))
}

const getPartner = async (id) => {
	return await api.getAllData(`partners/${id}`)
}

const getPartnerCategories = async (param) => {
	return await api.getAllData(ConvertObjectToParams(`categories?type=partner`, param))
}

const postPartner = async (data) => {
	return await api.postBody(`partners`, data)
}

const postCategory = async (data) => {
	return await api.postBody(`categories/context/partner`, data)
}

const patchPartner = async (id, data) => {
	return await api.patch(`partners/${id}`, data)
}

const deletePartner = async (id) => {
	return await api.del(`partners/${id}`)
}

const deleteCategory = async (id) => {
	return await api.del(`categories/${id}`)
}

const postLogoPartner = async (id, data) => {
	return await api.postBody(`partners/${id}/logo`, data)
}

const postImgPartner = async (id, data) => {
	return await api.postBody(`partners/${id}/image`, data)
}

const MarketPlaceService = {
	getPartners,
	postPartner,
	patchPartner,
	deletePartner,
	postLogoPartner,
	postImgPartner,
	getPartner,
	getPartnerCategories,
	deleteCategory,
	postCategory
}

export default MarketPlaceService;