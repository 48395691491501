import * as Yup from "yup";

export const MiniroutineSchema = Yup.object().shape({
  categoryType: Yup.string().required("Ce champ est requis"),
  title: Yup.string().required("Ce champ est requis"),
  routines: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Ce champ est requis"),
    })
  ),
});

