export const initialValuesAddUser =

	{
        /*User information*/
		user: {
			firstname: "",
			lastname: "",
			phone: "",
			email: "",
			gender: "",
			agencyName: "",
			roles:{
				additionalProp1: ""
			},
			regions: {

			},
		},

	}
