import React, {createContext, useEffect, useState} from "react";

import InputSearch from "../../../../components/Atomes/Inputs/InputSearch";
import TitleSectionWithButton from "../../../../components/Molecules/Title/TitleSectionWithButton";

import {SortingTable} from "../../../../components/Molecules/Tables/SortingTable";
import {PROJECTS_COLUMNS} from "../../../../config/ArrayColumns/Admin/ProjectsColumns";
import ProjectService from "../../../../services/ProjectService";
import Skeleton from "react-loading-skeleton";
import moment from "moment/moment";
import exportFileService from "../../../../services/ExportFileService";
import {toast} from "react-toastify";

export const StateRequest = createContext()
const ProjectsAdmin = () => {

	const [search, setSearch] = useState("");
	const [projectCollection, setProjectCollection] = useState([]);
	const [stateRequest, setStateRequest] = useState("");
	const [loading, setLoading] = useState(true);

	const getProjects = async () => {
		const request = await ProjectService.getProjects({search: search, "pagination": "true"})
		if (request.request.status === 200) {
			let data = request.data
			// data.map((item) => {
			// 	item.price = 200
			// 	item.priceTTC = 220
			// })
			setProjectCollection(data)
			setLoading(false)
		}
	}

	const downloadFile = () => {
		const date = moment().format("L").split("/").join("-")
		exportFileService.exportFile(`/admin/projects`, `export-projects-${date}`, {search: search}, "xlsx")
	}

	const DisplayToast = (type) => {
		if (type === "success cloture") {
			toast.success("Le dossier a bien été cloturé")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	useEffect(() => {
		setLoading(true)
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getProjects()
	}, [search, stateRequest]);


	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<InputSearch placeholder={"Entrer la recherche puis valider"}

			             onChangeSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
			             onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
			/>
			<div className="mbe-section-row">
				<TitleSectionWithButton title={"Projets"} buttonTitle={"Exporter"} isActive={true}
				                        styledButton={"btn-default btn-default-blue btn-download color-primary"} onClick={downloadFile}/>
			</div>
			{loading ? <Skeleton height={1000}/> :
				<div className="mbe-section-row adminProjectsView-sortingTable">
					<SortingTable columnsTable={PROJECTS_COLUMNS}
					              dataTable={projectCollection}
					/>
				</div>}
		</StateRequest.Provider>
	)
}

export default ProjectsAdmin;
