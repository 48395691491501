import React, {useEffect, useState} from 'react';
import InputSearch from "../../../../../../components/Atomes/Inputs/InputSearch";
import StudyFolderMenuShowElement from "./StudyFolderMenuShowElement";
import DirRespService from "../../../../../../services/Ing/DirResp/DirRespService";
import {toast} from "react-toastify";
import {ProjectStageService} from "../../../../../../services/ProjectStageService";
import Skeleton from "react-loading-skeleton";
import PopupAddMediaArch from "../../../../../ProjectStages/Medias/Popup/PopupAddMedia";
import PDFCard from "./PDFCard";
import {AdminService} from "../../../../../../services/AdminService";
import PopupDeleteMediaAdmin from "../../../../Admin/DocumentView/Popup/PopupDeleteMedia";
import PopUpAddPdf from "../../../../Admin/DocumentView/Popup/PopUpAddPdf";
import {useParams} from "react-router-dom";

const StudyFolderMenu = ({content, projectId, change}) => {
	const [iconsActive, setIconsActive] = useState("File")
	const [mediaToDelete, setMediaToDelete] = useState(null);
	const [mediaPdfToUpload, setMediaPdfToUpload] = useState(null);
	const [showPopupAddPdf, setShowPopupAddPdf] = useState(false)

	const [allAnnexe, setAllAnnexe] = useState([]);

	// Filter
	const [filterActive, setFilterActive] = useState(false)
	const [filterPlan, setFilterPlan] = useState(true)
	const [filterCalcul, setFilterCalcul] = useState(true)
	const [filterImages, setFilterImages] = useState(true)
	const [filterIngMedia, setFilterIngMedia] = useState(true)

	const [errorFile, setErrorFile] = useState(null);
	const [showPopupAdd, setShowPopupAdd] = useState(false);
	const [file, setFile] = useState(null);
	const [mediaUploaded, setMediaUploaded] = useState(false);
	const [showPopupDelete, setShowPopupDelete] = useState(false);

	const [imgLoading, setImgLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [medias, setMedias] = useState([]);
	const [menuIsOpen, setMenuIsOpen] = useState(true)

	const [allPdf, setAllPdf] = useState([])

	const {id} = useParams()

	const closePopup = () => {
		// setMediaToDelete(null);
		setShowPopupDelete(false);
		setShowPopupAdd(false);
		setShowPopupAddPdf(false);
		setErrorFile(null);
	}

	const validFileExtensions = {
		"media-project": ['png', 'jpeg', 'jpg'],
		"media-plan": ['pdf', 'dwg', 'skp', 'rvt', 'dxf', 'png', 'jpeg', 'jpg', 'dwg'],
		"media-calc": ['xlsx', 'xls', 'docx', 'pdf', 'doc'],
		"media-engineer": ['xlsx', 'xls', 'docx', 'pdf', 'doc', 'png', 'jpeg', 'jpg', 'dwg']
	};

	const addMedia = async () => {
		let request = await ProjectStageService.postProjectMedia(file);
		if (request) {
			toast.success("Document ajouté avec succès");
			setMediaUploaded(!mediaUploaded);
			setFile(null);
			setShowPopupAdd(false);
		} else {
			toast.error("Une erreur est survenue");
		}
	}


	const isValidFileType = (fileName, fileType) => {
		let isValid = fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
		return isValid;
	}

	const handleDeleteFile = async (id) => {
		setMediaToDelete(id);
		setShowPopupDelete(true);
	}

	const uploadPdfFile = async (id) => {
		setMediaPdfToUpload(id);
		setShowPopupAddPdf(true)
	}


	const uploadPdf = async () => {
		const exist = allAnnexe.appendicesIri.filter((media) => media === `/api/medias/${mediaPdfToUpload}`)

		if (exist.length > 0) {
			toast.info("Ce document est déjà présent dans la liste");

		} else {
			const newMedia = [...allAnnexe.appendicesIri, `/api/medias/${mediaPdfToUpload}`]
			setAllAnnexe({...allAnnexe, appendicesIri: newMedia})

			const request = await DirRespService.patchAnnexe(id, {appendicesIri: newMedia})
			if (request.status === 200) {
				toast.success("Document ajouté avec succès");
				setMediaPdfToUpload(null);
				setShowPopupAddPdf(false);
				change()
			} else {
				toast.error("Une erreur est survenue");
			}
		}

	}

	const getAllPdf = async () => {
		const request = await ProjectStageService.getAllPdf()
		if (request.status === 200) {
			setAllPdf(request.data["hydra:member"])
		}
	}


	const deleteMedia = async () => {
		let request = await AdminService.deleteById(`/media`, mediaToDelete);
		if (request.status === 204) {
			setMediaToDelete(null);
			toast.success("Document supprimé avec succès");
			closePopup();
		}
	}

	const UploadAnnexePdf = async (filename) => {

		const exist = allAnnexe.appendicesIri.filter((media) => media === `${filename}`)

		if (exist.length > 0) {
			toast.info("Ce document est déjà présent dans la liste");

		} else {
			const newMedia = [...allAnnexe.appendicesIri, `${filename}`]
			setAllAnnexe({...allAnnexe, appendicesIri: newMedia})

			const request = await DirRespService.patchAnnexe(id, {appendicesIri: newMedia})
			if (request.status === 200) {
				toast.success("Document ajouté avec succès");
				setMediaPdfToUpload(null);
				setShowPopupAddPdf(false);
				change()
			} else {
				toast.error("Une erreur est survenue");
			}
		}
	}

	const uploadFile = (e, category, inputId) => {
		if (e !== undefined) {

			if (!isValidFileType(e.name, category)) {
				setErrorFile({
					isError: true,
					message: 'Le format du fichier n\'est pas valide, format accepté : ' + validFileExtensions[category].join(', ')
				});
			} else {
				setErrorFile({
					isError: false,
					message: ''
				});
			}

			if (e.size > 15000000) {
				setErrorFile({
					isError: true,
					message: 'La taille du fichier est trop grande, taille maximum : 15Mo'
				});
			}

			setFile({
				media: e,
				categoryType: category,
				projectId: projectId
			})
			document.getElementById(inputId).value = null;
			setShowPopupAdd(true);
		}
	}

	const getImgStudyFolder = async () => {
		const request = await DirRespService.getStudyFolderImg(content.project.id, {search: search})
		if (request.request.status === 200) {
			let medias = request.data['hydra:member'];
			let mediasSorted = [];
			mediasSorted['Image projet'] = [];
			mediasSorted['Plan'] = [];
			mediasSorted['Feuille de calcul'] = [];
			mediasSorted['Document ingénieur'] = [];
			medias.forEach(media => {
				if (media.category === 'media-project') {
					mediasSorted['Image projet'].push(media);
				} else if (media.category === 'media-plan') {
					mediasSorted['Plan'].push(media);
				} else if (media.category === 'media-calc') {
					mediasSorted['Feuille de calcul'].push(media);
				} else if ((media.category === 'media-engineer') || (media.category === 'media-study-execution-class-generated') ||(media.category === 'media-study-execution-class-static') ) {
					mediasSorted['Document ingénieur'].push(media)
				}
			});

			setMedias(mediasSorted);
			setImgLoading(false);
		} else {
			toast.error('Une erreur est survenue, veuillez réessayer plus tard', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 3000,
			})
		}
	}

	const getAnnexe = async () => {
		const request = await DirRespService.getAnnexe(id)
		if (request.status === 200) {
			setAllAnnexe(request.data)
		}
	}

	useEffect(() => {
		setImgLoading(true)
		getAnnexe()
		getAllPdf()
		window.scrollTo(0, 0)
	}, []);

	useEffect(() => {
		getImgStudyFolder()
	}, [mediaUploaded, search, showPopupDelete]);

	return (
		<div className={`section-study-folder-menu-all-container ${menuIsOpen ? "open" : "close"} `}>
			<div onClick={() => setMenuIsOpen(!menuIsOpen)}
			     className={`section-study-folder-menu-close ${menuIsOpen ? "open" : "close"}`}>
				<img src={"/images/pictos/study-folder-menu-open.svg"} alt={"close"}/>
			</div>
			{
				imgLoading ? <Skeleton height={500}/> : (
					<section className={`section-study-folder-menu ${menuIsOpen ? "open" : "close"}`}>
						{
							menuIsOpen ? (
								<>
									<div className={"section-study-folder-menu-icons"}>
										{
											iconsActive === "File" ? (
												<>
													<div className={"active"}>
														<img src={"/images/pictos/file.svg"} alt={"folder"}/>
													</div>
												</>
											) : (
												<div>
													<img src={"/images/pictos/file-text-white.svg"} alt={"folder"}
													     onClick={() => setIconsActive("File")}/>
												</div>
											)
										}

										{
											iconsActive === "Archive" ? (
												<div className={"active"}>
													<img className={"archive-blue"} src={"/images/pictos/archive-blue.svg"} alt={"archive"}/>
												</div>
											) : (
												<div>
													<img src={"/images/pictos/archive-menu.svg"} alt={"archive"}
													     onClick={() => setIconsActive("Archive")}/>
												</div>
											)
										}
									</div>
									<div className={"section-study-folder-menu-content"}>
										{
											iconsActive === "File" ? (
												<>
													<h4 className={"color-white"}>Bibliothèque</h4>
													<InputSearch placeholder={"Rechercher un fichier"} name={"Recherche"}
													             onChangeSearch={(event) => (event.key === "Enter" || event.target.value.length === 0) ? setSearch(event.target.value.toLowerCase()) : null}
													             onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}/>
													<div className={"filtrer"} onClick={() => setFilterActive(!filterActive)}>
														<p className={"color-white text-primary"}>Filtrer</p>
														<img src={"/images/pictos/white-arrow.svg"} className={filterActive ? "filter-open" : ""}
														     alt={"filter"}/>
													</div>


													{
														filterActive ? (
															<div className={"filter-active"}>
																<div className={"filter-active-content"}>
																	<input type={"checkbox"} id={"plan"} name={"plan"} value={"plan"} checked={filterPlan}
																	       onChange={() => setFilterPlan(!filterPlan)}/>
																	<label htmlFor={"plan"}>Plans</label>
																</div>
																<div className={"filter-active-content"}>
																	<input type={"checkbox"} id={"calcul"} name={"calcul"} value={"calcul"}
																	       checked={filterCalcul}
																	       onChange={() => setFilterCalcul(!filterCalcul)}/>
																	<label htmlFor={"calcul"}>Calculs</label>
																</div>
																<div className={"filter-active-content"}>
																	<input type={"checkbox"} id={"images"} name={"images"} value={"images"}
																	       checked={filterImages}
																	       onChange={() => setFilterImages(!filterImages)}/>
																	<label htmlFor={"images"}>Images</label>
																</div>
																<div className={"filter-active-content"}>
																	<input type={"checkbox"} id={"ing"} name={"ing"} value={"ing"}
																	       checked={filterIngMedia}
																	       onChange={() => setFilterIngMedia(!filterIngMedia)}/>
																	<label htmlFor={"ing"}>Documents ingénieur</label>
																</div>
															</div>
														) : null
													}
													{
														filterPlan ? (
															<StudyFolderMenuShowElement fakeDataProject={medias['Plan']}
															                            onClickDelete={handleDeleteFile}
															                            onClickUpload={uploadPdfFile}
															                            titleSection={"Plans"}
															                            input={<>
																                            <input
																	                            type={"file"}
																	                            onChange={(e) => uploadFile(e.target.files[0], 'media-plan', 'addPlanFileCard')}
																	                            id={"addPlanFileCard"}
																                            />
																                            <label htmlFor={"addPlanFileCard"}>
																	                            <img alt={"img add"}
																	                                 src={"/images/pictos/add-plus-circle-primary.svg"}/>
																                            </label>
															                            </>}
															/>
														) : null
													}
													{
														filterCalcul ? (
															<StudyFolderMenuShowElement fakeDataProject={medias['Feuille de calcul']}
															                            onClickDelete={handleDeleteFile} // Pass the updated handleDeleteFile function
															                            titleSection={"Calculs"} input={
																<>
																	<input
																		type={"file"}
																		onChange={(e) => uploadFile(e.target.files[0], 'media-calc', 'addImgFileCard')}
																		id={"addCalcFileCard"}
																		accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
																	/>
																	<label htmlFor={"addCalcFileCard"}>
																		<img alt={"img add"}
																		     src={"/images/pictos/add-plus-circle-primary.svg"}/>
																	</label>
																</>
															}
															/>

														) : null
													}

													{
														filterImages ? (
															<StudyFolderMenuShowElement fakeDataProject={medias['Image projet']}
															                            onClickDelete={handleDeleteFile} // Pass the updated handleDeleteFile function
															                            titleSection={"Images"}
															                            acceptImg={'.png, .jpeg, .jpg'}
															                            input={
																                            <>
																	                            <input
																		                            type={"file"}
																		                            onChange={(e) => uploadFile(e.target.files[0], 'media-project', 'addImgFileCard')}
																		                            id={"addImgFileCard"}
																		                            accept={'.png, .jpeg, .jpg'}
																	                            />
																	                            <label htmlFor={"addImgFileCard"}>
																		                            <img alt={"img add"}
																		                                 src={"/images/pictos/add-plus-circle-primary.svg"}/>
																	                            </label>
																                            </>
															                            }

															/>
														) : null
													}

													{
														filterIngMedia ? (
															<StudyFolderMenuShowElement fakeDataProject={medias['Document ingénieur']}
															                            onClickUpload={uploadPdfFile}
															                            onClickDelete={handleDeleteFile} // Pass the updated handleDeleteFile function
															                            titleSection={"Document ingénieur"}
															                            acceptImg={'.xlsx, .xls, .doc, .docx, .pdf, .png, .jpeg, .jpg'}
															                            input={
																                            <>
																	                            <input
																		                            type={"file"}
																		                            onChange={(e) => uploadFile(e.target.files[0], 'media-engineer', 'addImgFileCard')}
																		                            id={"addIngFileCard"}
																		                            accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, .jpeg, .jpg'}
																	                            />
																	                            <label htmlFor={"addIngFileCard"}>
																		                            <img alt={"img add"}
																		                                 src={"/images/pictos/add-plus-circle-primary.svg"}/>
																	                            </label>
																                            </>
															                            }

															/>
														) : null
													}
												</>
											) : null
										}
										{
											iconsActive === "Archive" ? (
												<>
													<h4 className={"color-white text-primary"}>Documents de référence</h4>
													<div className={"section-study-folder-menu-content-historic"}>
														{
															allPdf.map((filename, index) => (
																<PDFCard key={index} filename={filename} uploadPdfFile={(filename) => UploadAnnexePdf(filename)}/>
															))
														}
													</div>
												</>
											) : null
										}
									</div>
								</>
							) : null
						}

					</section>
				)
			}

			<PopupDeleteMediaAdmin
				showPopup={showPopupDelete}
				closeAction={closePopup}
				validAction={deleteMedia}
			/>

			<PopUpAddPdf
				showPopup={showPopupAddPdf}
				closeAction={closePopup}
				validAction={uploadPdf}

			/>

			<PopupAddMediaArch
				showPopup={showPopupAdd}
				closeAction={closePopup}
				file={file}
				errorFile={errorFile}
				validAction={addMedia}
			/>
		</div>
	);
};

export default StudyFolderMenu;
