import React from "react";
import HomeCustomerBanner from "./Banner/HomeCustomerBanner";
import HomeProjectsCustomer from "./Projects/HomeCustomerProjects";

const HomeCustomer= () => {
  return (
    <section className="mbe-section">
      <HomeCustomerBanner />
      <HomeProjectsCustomer />
    </section>
  )
}

export default HomeCustomer;