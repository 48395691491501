import React, { useContext } from "react";
import { ChatbotContext } from "../Chatbot";

const ChatbotRecap = () => {

  const contextValue = useContext(ChatbotContext);

  return (
    <section className="mbe-chatbot__end">
      <h4 className="color-primary">Merci d'avoir rempli les différentes informations, voici le récapitulatif :</h4>
      <ul>
        {
          contextValue.allQuestionsAnswered.map((item) => {
            return (
              <li key={item.id}>
                <p className="text-primary color-black paragraph_bold">{item.data.question}</p>
                <p className="text-primary color-black">{item.answer.stringAnswers}</p>
              </li>
            )
          })
        }
      </ul>
    </section>
  )
}

export default ChatbotRecap;