import React from 'react';
import {Form, Formik} from "formik";
import {InitialValuesIngDPGF} from "../../../../config/InitialValues/Engineer/InitialValuesIngDPGF";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import {ingDPGFIngSchema} from "../../../../schemas/Engineer/IngDPGFIngSchema";

const FormEditDPGFIng = ({onSubmit, onClick, values}) => {

	const handleSubmit = async (values, actions) => {
		onSubmit(values)
		actions.resetForm()
		onClick()
	}

	InitialValuesIngDPGF.title = values.title
	InitialValuesIngDPGF.total = values.total
	InitialValuesIngDPGF.unit = values.unit
	InitialValuesIngDPGF.quantity = values.quantity

	return (
		<Formik
			initialValues={InitialValuesIngDPGF}
			validationSchema={ingDPGFIngSchema}
			onSubmit={handleSubmit}
		>
			{({isSubmitting}) => (
				<Form>
					<div className={"form-row-element form-row-two-elements"}>
						<InputLabelErrorMessage type={"text"} label={"Désignation ouvrages"} name={"title"}/>
						<InputLabelErrorMessage type={"number"} label={"P.V HT"} name={"total"}/>
					</div>
					<div className={"form-row-element form-row-two-elements"}>
						<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"}
						                        data={
							                        [{label: 'U', value: 'U'}, {label: 'M2', value: 'M2'}, {
								                        label: 'M3',
								                        value: 'M3'
							                        }, {label: 'KG', value: 'KG'}, {label: 'ML', value: 'ML'}, {
								                        label: 'ENS.',
								                        value: 'ENS.'
							                        }, {label: 'EUR', value: 'EUR'}]
						                        } label={"Unité"} name={"unit"}/>
						<InputLabelErrorMessage type={"number"} label={"Quantité"} name={"quantity"}/>
					</div>

					<div>
						<ButtonSubmit title={"Enregistrer"} isActive={false}
						              styleButton={"btn-default btn-default-secondary plain"}/>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormEditDPGFIng;
