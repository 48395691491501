import React from 'react';
import CardToDo from "../../../../../components/Molecules/Cards/CircuitDeValidation/CardToDo";

const ToDo = ({approvalCircuit}) => {
	return (
		<div className={"section-to-do"}>
			<div className={"section-to-do-title"}>
				<h3 className={"color-primary"}>Tâches à faire</h3>
			</div>
			<div className={"section-to-do-container"}>
				{
					approvalCircuit.map((item, index) =>
						<CardToDo
							key={index}
							item={item}
							title={item.niceStage}
							numberFolder={item.reference}
							firstName={item.client.firstname}
							lastName={item.client.lastname}
							img={item.client.urlAvatar}

							styleButton={
								item.stageStatus === "valid" || item.stageStatus === "processing" ? "green" : item.stageStatus === "problem" ? "orange" : "red"
							}
							color={
								item.stageStatus === "valid" || item.stageStatus === "processing" ? "green" : item.stageStatus === "problem" ? "orange" : "red"
							}
						/>
					)
				}
			</div>
		</div>
	);
};

export default ToDo;
