import React from 'react';
import {Form, Formik} from "formik";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import {IngCreditRequestSchema} from "../../../../schemas/Engineer/IngCreditRequestSchema";
import {ingCreditRequestValues} from "../../../../config/InitialValues/Engineer/ingCreditRequestValues";
import DirRespService from "../../../../services/Ing/DirResp/DirRespService";
import {toast} from "react-toastify";

const FormSendCreditRequest = ({onSubmit, onClick, id, setShowPopUp}) => {
	const handleSubmit = async (values, actions) => {
		//onSubmit(values)
		//actions.resetForm()
		//onClick()

		let request = await DirRespService.patchCreditRequest(id, {refundRequest: true, refundReason: values.reasonCredit})
		if (request.status === 200) {
			toast.success("Demande d'avoir envoyée avec succès");
			actions.resetForm()
			setShowPopUp(false)
		} else {
			toast.error("Une erreur est survenue");
		}
	}

	return (
		<>
			<Formik
				initialValues={ingCreditRequestValues}
				validationSchema={IngCreditRequestSchema}
				onSubmit={handleSubmit}
			>
				{({isSubmitting}) => (
					<Form className="project-form-request-credit">
						<h4 className={"color-primary"}>Demande d'envoi d'un avoir à l'administrateur</h4>
						<p className={"text-primary color-primary"}>
							Veuillez sélectionner une raison pour la demande d'avoir.
						</p>
						<div className={"form-row-element big-element"}>
							<div className={"form-row-label-input"}>
								<div className={"form-row-element form-row-two-elements input-file-container"}>
									<InputLabelErrorMessage
										type={"select"}
										keyLabel={"label"}
										keyValue={"value"}
										label={"Raison de la demande d'avoir"}
										data={[
											{
												label: "Retard rendu dossier d’étude structure",
												value: "Retard rendu dossier d’étude structure"
											},
											{label: "Changement prestation mur non-porteur", value: "Changement prestation mur non-porteur"},
											{label: "Date rendez-vous trop éloignée", value: "Date rendez-vous trop éloignée"},
											{
												label: "ESDE abandonné pas d’ouverture de mur porteur",
												value: "ESDE abandonné pas d’ouverture de mur porteur"
											},
											{label: "Projet annulé", value: "Projet annulé"},
											{
												label: "Prestation chiffrée devis complémentaire",
												value: "Prestation chiffrée devis complémentaire"
											},
											{label: "Signature mauvais devis", value: "Signature mauvais devis"},
											{label: "Prestation non réalisée", value: "Prestation non réalisée"},
											{label: "Changement prestation projet", value: "Changement prestation projet"},
											{label: "Droit rétractation 14 jours ouvrés", value: "Droit rétractation 14 jours ouvrés"},
											{label: "Avoir litige client", value: "Avoir litige client"},
											{label: "Erreur diagnostic structure", value: "Erreur diagnostic structure"},
											{label: "Erreur planification projet", value: "Erreur planification projet"},
											{label: "Prestation annulé mur non-porteur", value: "Prestation annulé mur non-porteur"},
											{label: "Erreur prise de rendez-vous", value: "Erreur prise de rendez-vous"},
											{label: "Prestation ESDE non réalisée", value: "Prestation ESDE non réalisée"},
											{label: "Prestation EDFT non réalisée", value: "Prestation EDFT non réalisée"},
											{label: "Projet annulé délai créneau trop long", value: "Projet annulé délai créneau trop long"},
											{label: "Projet annulé client non-propriétaire", value: "Projet annulé client non-propriétaire"},
											{label: "Remise commerciale prestation", value: "Remise commerciale prestation"},
											{label: "Geste commercial partenariat", value: "Geste commercial partenariat"},
											{label: "Erreur génération facture acompte", value: "Erreur génération facture acompte"},
											{label: "Erreur génération facture finale", value: "Erreur génération facture finale"}
										]}
										name={"reasonCredit"}
									/>
								</div>
							</div>
						</div>

						<div>
							<ButtonSubmit
								title={"Envoyer"}
								isActive={false}
								styleButton={"btn-default btn-default-secondary plain"}/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormSendCreditRequest;
