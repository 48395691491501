import React from 'react';
import FilterSelectDatepicker from "../../../../../../components/Molecules/Filter/FilterSelectDatepicker";

const KpisViewFilters = ({date, onChangeDate, onChangeDuree, resetDateSelected}) => {
	const options = ["1 mois", "2 mois", "3 mois"]
	const values = [1, 2, 3]

	return (
			<FilterSelectDatepicker
				defaultValue={"Toutes durées"}
				options={options}
				values={values}
				date={date}
				onChangeDate={onChangeDate}
				onChange={onChangeDuree}
				resetDateSelected={resetDateSelected}
			/>
    )

}
export default KpisViewFilters;
