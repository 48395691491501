import React, { createContext } from 'react';
import TitleSection from '../../../../../components/Atomes/Title/TitleSection';
import FormDevisRefonte from './PartialsRefonte/FormDevisRefonte';

export const StateRequestArch = createContext()

const DevisRefonte = () => {

    return (
        <>
            <TitleSection title={"Création d’un nouveau projet"}/>
            <FormDevisRefonte />
        </>
    )
}

export default DevisRefonte;