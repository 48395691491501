import * as Yup from "yup";

export const SchemaBookSlot = Yup.object().shape({
    firstName: Yup.string().required("Ce champ est requis"),
    lastName: Yup.string().required("Ce champ est requis"),
    phone: Yup.string()
        .min(10, "La longueur doit être de minimum 10 caractères")
        .max(14, "La longueur doit être de maximum 14 caractères")
        .required('Veuillez renseigner un numéro de téléphone'),
    additionalInformation: Yup.string(),
});