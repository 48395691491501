import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";

export const COMMERCIAL_FILE_IN_VALIDATION_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Nom client",
		accessor: "client.lastname",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.firstname} {cellInfo.row.original.client.lastname}</Link>
			)
		}
	},
	{
		Header: "Typologie projet",
		accessor: "type"
	},
	{
		Header: "Documents",
		accessor: "chatbotDocument",
		Cell: cellInfo => {
			return cellInfo.row.original.chatbotDocument === true ? "Transmis" : "Non transmis";
		}
	},
	{
		Header: "Date demande",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = new Date(cellInfo.row.original.createdAt);
			date = moment(date).format("DD/MM/YYYY");
			return date
		}
	},
	{
		Header: "Statut de la remise",
		accessor: "niceStageStatus",
		Cell: cellInfo => {
			const status = cellInfo.row.original.niceStageStatus;
			if (status === "Problème") {
				return (
					<div className="badge badge-waiting">
						<div className={"pin-wainting"}></div>
						{status}</div>
				)
			} else if (status === "Validé") {
				return (
					<div className="badge badge-success">
						<div className={"pin-success"}></div>
						{status}</div>
				)
			}  else {
				return (
					<div className="badge badge-error">
						<div className={"pin-error"}></div>
						{status}</div>
				)
			}
		}
	},
	{
		Header: "",
		accessor: "details",
		Cell: cellInfo => {
			return (
				<a className="btn-little btn-default-primary color-primary" href={`/devis/${cellInfo.row.original.id}`}>Éditer le devis</a>
			)
		}
	}
]

