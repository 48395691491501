import React from "react";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import PatnerEdit from "../../views/Pages/Coms/Com/Home/Partials/PatnerEdit";

export const PARTNER_QUOTES_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "project.client.reference"
	},
	{
		Header: "Nom client",
		accessor: "project.client.lastname",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.project.client.id}`}>{cellInfo.row.original.project.client.firstname} {cellInfo.row.original.project.client.lastname}</Link>
			)
		}
	},
	{
		Header: "Date signature",
		accessor: "signedAt",
		Cell: cellInfo => {
			let date = "NA";
			if (cellInfo.row.original.signedAt) {
				date = new Date(cellInfo.row.original.signedAt)
				date = moment(date).format("DD/MM/YYYY");
			}
			return date
		}
	},
	{
		Header: "Montant devis (HT)",
		accessor: "total",
		Cell: cellInfo => {
			return (
				<span>{cellInfo.row.original.total.toFixed(2)} €</span>
			)
		}
	},
	{
		Header: "Partenaire",
		accessor: "partner.title"
	},
	{
		Header: "Montant apport affaire (HT)",
		accessor: "profit",
		Cell: cellInfo => {
			let profit = "NA";
			if (cellInfo.row.original.profit) {
				profit = `${cellInfo.row.original.profit.toFixed(2)} €`;
			}
			return profit
		}
	},
	{
		Header: "État demande",
		accessor: "status",
		Cell: cellInfo => {
			if (cellInfo.row.original.status === "processing") {
				return (
					<div className="badge badge-waiting">
						<div className={"pin-wainting"}></div>
						En cours</div>
				)
			} else if (cellInfo.row.original.status === "signed") {
				return (
					<div className="badge badge-success">
						<div className={"pin-success"}></div>
						Signé
					</div>
					)

			}  else if (cellInfo.row.original.status === "paid") {
				return (
					<div className="badge badge-success">
						<div className={"pin-success"}></div>
						Payé
					</div>
				)
				
			}else if (cellInfo.row.original.status === "canceled") {
				return (
					<div className="badge badge-error">
						<div className={"pin-error"}></div>
						Annulé
					</div>
				)
			}  else if (cellInfo.row.original.status === "open") {
				return (
					<div className="badge badge-success">
						<div className={"pin-success"}></div>
						Ouvert
					</div>
				)
				
			}else if (cellInfo.row.original.status === "follow-up") {
				return (
					<div className="badge badge-waiting">
						<div className={"pin-wainting"}></div>
						Suivi
					</div>
				)
			}
		}
	},
	{
		Header: "Action",
		accessor: "edit",
		Cell: cellInfo => {
			return (
				<PatnerEdit cellinfo={cellInfo.row.original}/>
			)
		}
	}
];
