import React, {memo} from "react";
import {Route, Routes} from "react-router-dom";
import NotFound from "../../views/404/NotFound";

function MapAllowedRoutes({routes, isAddNotFound}) {
	return (
		<Routes>
			{routes.map((route) => {
				const {
					path,
					component: Component,
					title,
					permission,
					...rest
				} = route;
				return (
					<Route
						{...rest}
						key={path}
						path={path}
						element={Component}
					/>
				)
			})}
			{isAddNotFound && <Route element={<NotFound/>} path="*"></Route>}
		</Routes>
	)
}

export default memo(MapAllowedRoutes);
