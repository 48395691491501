import React from 'react';
import ButtonWithOnClickAction from "../../../../../../components/Atomes/Buttons/ButtonWithOnClickAction";
import ButtonSubmit from '../../../../../../components/Atomes/Buttons/ButtonSubmit';
import exportFileService from "../../../../../../services/ExportFileService";

const TableHeadingAdd = ({onClick, title, dark, addTitle}) => {


	return (
		<div className='collaborators-tableListingHeading'>
			<h3 className={"color-primary"}>{title}</h3>
				<ButtonWithOnClickAction onClick={onClick} isActive={true} title={addTitle} styleButton={"btn-default" + (dark ? " btn-default-blue btn-download" : " btn-default-primary")}/>
		</div>
	);
};

export default TableHeadingAdd;
