import api from "./APIService";

const getPartenerQuote = async () =>{
	return await api.getAllData("partners")
}


const patchProject = async (id, data) =>{
	return await api.patch(`projects/${id}/quotes`, data)
}


export const partenerQuoteService  = {
	getPartenerQuote,
	patchProject
}
