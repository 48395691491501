import React from "react";

const Button = ({onClick, styleButton, isActive, title}) => {

	return (
		<div className="button-component">
			<button onClick={onClick} disabled={!isActive}
							className={styleButton + (!isActive ? " btn-default-inactive" : "")}>{title}</button>
		</div>
	);
};

export default Button;
