import React from 'react';
import FilterTwoSelectsDatepicker from "../../../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";

const KpisViewFilters = ({ date, collaborators, onChangeDate, onChangeDuree, onChangeCollaborator, resetDateSelected }) => {
	const secondOptions = ["1 mois", "2 mois", "3 mois"]
	const secondValues = [1, 2, 3]

	return (
		<>
			<FilterTwoSelectsDatepicker
				defaultValue={"Tous les collaborateurs"}
				options={collaborators.map(collaborators => collaborators.lastname)}
				firstValue={collaborators.map(collaborators => collaborators.id)}
				onChangeDate={onChangeDate}
				onSecondChange={onChangeDuree}
				onChange={onChangeCollaborator}
				secondDefaultValue={"Toutes durées"}
				secondOptions={secondOptions}
				secondValue={secondValues}
				styledButton={"btn-default btn-default-primary btn-default-primary color-primary"}
				titleButton={"Selectionnez une période"}
				date={date}
				resetDateSelected={resetDateSelected}
			/>
		</>
	)

}
export default KpisViewFilters;