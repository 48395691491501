import React, {useState} from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import PopUpArchiveFile from "../../components/Molecules/PopUp/PopUpArchiveFile";
import PopUpForm from "../../components/Molecules/PopUp/PopUpForm";
import FormEditCommentHomeCom from "../../components/Molecules/Form/FormEditCommentHomeCom";
import PopUpDeleteNewRequest from "../../components/Molecules/PopUp/PopUpDeleteNewRequest";


export const COMMERCIAL_FILE_IN_PROGRESS_COLUMNS = [
  {
    Header: "Numéro client",
    accessor: "project.client.reference",
  },
  {
    Header: "Nom client",
    accessor: "project.client.lastname",
    Cell: (cellInfo) => {
      return (
        <Link to={`/client/${cellInfo.row.original.project.client.id}`}>
          {cellInfo.row.original.project.client.firstname}{" "}
          {cellInfo.row.original.project.client.lastname}
        </Link>
      );
    },
  },
  {
    Header: "Typologie projet",
    accessor: "project.type",
  },
  {
    Header: "Date demande",
    accessor: "createdAt",
    Cell: (cellInfo) => {
      let date = new Date(cellInfo.row.original.createdAt);
      date = moment(date).format("DD/MM/YYYY");
      return date;
    },
  },
  {
    Header: "Type devis",
    accessor: "niceAdditionalQuote",
  },
  {
    Header: "Montant devis (HT)",
    accessor: "total",
    Cell: (cellInfo) => {
      let total = 0;
      if (cellInfo.row.original.total) {
        total = cellInfo.row.original.total.toFixed(2) + " €";
      } else {
        total = "0.00 €";
      }
      return total;
    },
  },
  {
    Header: "Statut projet",
    accessor: "niceStatus",
    Cell: (cellInfo) => {
      if (cellInfo.row.original.niceStatus === "En cours") {
        return (
          <div className="badge badge-waiting">
            <div className={"pin-wainting"}></div>
            {cellInfo.row.original.niceStatus}
          </div>
        );
      } else if (cellInfo.row.original.niceStatus === "Ouvert") {
        return (
          <div className="badge badge-success">
            <div className={"pin-success"}></div>
            {cellInfo.row.original.niceStatus}
          </div>
        );
      } else {
        return (
          <div className="badge badge-error">
            <div className={"pin-error"}></div>
            {cellInfo.row.original.niceStatus}
          </div>
        );
      }
    },
  },
  {
    Header: "Commentaire",
    accessor: "comment"
  },
  {
    Header: "",
    accessor: "details",
    Cell: (cellInfo) => {
      const [isActive, setIsActive] = useState(false)
      const [show, setShow] = useState(false);
      const DisplayEditModalBills = () => {
				setShow(!show)
			}
      const [isDelete, setIsDelete] = useState(false);

      return (
        <div className={"actions-controller"}>
        <a className="btn-little btn-default-primary color-primary" href={`/devis/${cellInfo.row.original.project.id}`}>
          Éditer
        </a>
        <p src={"/images/pictos/box.svg"} onClick={() => setIsActive(!isActive)} title={"Archiver"} className="btn-little btn-default-primary color-primary">Archiver</p> 

        <img onClick={() => setShow(true)} src={"/images/pictos/pencil-blue.svg"}
						     title={"Modifier le commentaire"}/>

        <img onClick={() => setIsDelete(!isActive)} src={"images/pictos/red-cross.svg"} alt={"delete new demande"} title={"Supprimer un dossier en cours"} />
      {
        isActive ? (
          <PopUpArchiveFile onClickNo={() => setIsActive(!isActive)} idProject={cellInfo.row.original.id} reference={cellInfo.row.original.reference} />
        ) : null
      }

      {
						show ? (
							<PopUpForm large={true} onClick={DisplayEditModalBills} show={show} title={"Modifier le commentaire"}
							           form={<FormEditCommentHomeCom id={cellInfo.row.original.id} setShow={setShow}
							                                       comment={cellInfo.row.original.comment}/>}
							/>
						) : null
					}
{
        isDelete ? (
          <PopUpDeleteNewRequest onClick={() => setIsDelete(false)} clientName={ cellInfo.row.original.project.client.firstname + " " + cellInfo.row.original.project.client.lastname} idProject={cellInfo.row.original.project.id} />
        ) : null
      }
        </div>
      );
    },
  },
];
