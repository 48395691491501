import React, { useEffect, useState, useContext } from 'react'
import { ChatbotAdminActionsContext } from "../../ChatbotAdmin";
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify';

import { Formik, Form, FieldArray, Field, ErrorMessage } from 'formik';
import { MegaroutineSchema } from './Config/MegaroutineSchema';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import MultiSelect from '../../../../../../components/Atomes/Select/MultiSelect';

import { AdminService } from '../../../../../../services/AdminService';

const PopupEditMegaroutine = ({ megaroutineId }) => {

  const chatbotContext = useContext(ChatbotAdminActionsContext)

  const [megaroutine, setMegaroutine] = useState(null)
  const [megaroutineLoading, setMegaroutineLoading] = useState(true)
  const [macroroutinesOptions, setMacroroutinesOptions] = useState([]);

  useEffect(() => {
    getMegaroutine();
  }, []);

  const getMegaroutine = async () => {
    setMegaroutineLoading(true);
    let request = await AdminService.get(`/chatbot-mega-routines/${megaroutineId}`);
    if (request.status === 200) {
      getMacroroutinesOptions();
      setMegaroutine(request.data);
    }
  }

  const getMacroroutinesOptions = async () => {
    let request = await AdminService.get(`/chatbot-macro-routines?pagination=false`);
    if (request.status === 200) {
      let macroroutinesOptions = request.data['hydra:member'].map((macroroutine) => {
        return {
          label: macroroutine.title,
          value: `/api/chatbotMacroRoutines/${macroroutine.id}`
        }
      });
      setMacroroutinesOptions(macroroutinesOptions);
      setMegaroutineLoading(false);
    }
  }

  const submitForm = async (values, setSubmitting) => {
    setSubmitting(true);

    let data = {
      title: values.title,
      triggerTitle: values.triggerTitle,
      chatbotMacroRoutinesIri : values.macroroutines.map((macroroutine) => {
        return macroroutine.name
      })
    }

    let request = await AdminService.patch(`/chatbot-mega-routines`, megaroutineId, data);
    if (request.status === 200) {
      toast.success("La Méga-Routine a bien été modifiée");
      getMegaroutine();
      chatbotContext.setChatbotAdminActionsContext({
        "name": "mega-routine"
      });
    } else {
      toast.error("Une erreur est survenue");
    }
    setSubmitting(false);
  }

  return (
    <>
      {
        megaroutineLoading ? (
          <div className='adminChatbot-popup__skeleton'>
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
          </div>
        ) : (
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              submitForm(values, setSubmitting);
            }}
            initialValues={{
              title: megaroutine.title,
              triggerTitle: megaroutine.triggerTitle,
              macroroutines: megaroutine.chatbotMacroRoutines.map((macroroutine) => {
                return {
                  id: macroroutine.id,
                  name: `/api/chatbotMacroRoutines/${macroroutine.id}`
                }
              })
            }}
            validationSchema={MegaroutineSchema}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <div className='form-row-element'>
                  <InputLabelErrorMessage
                    name="title"
                    type="text"
                    label="Titre de la Méga-Routine"
                  />
                </div>
                <div className='form-row-element'>
                  <InputLabelErrorMessage
                    name="triggerTitle"
                    type="text"
                    label="Question posée au client"
                  />
                </div>
                <FieldArray
                  name="macroroutines"
                  render={arrayHelpers => (
                    <div className={`adminChatbot-popup__manyanswers ${values.macroroutines.length > 1 ? 'adminChatbot-popup__manyanswers-true' : ''} `}>
                      {
                        values.macroroutines.map((routine, index) => (
                          <span key={index}>
                            <label className={"text-micro paragraph_bold color-dark-grey"}>Macro-Routine n°{index + 1}</label>
                            <div className='form-row-element' >
                              <div className='adminChatbot-popup__manyanswers-arrows'>
                                {
                                  values.macroroutines.length > 1 && (
                                    <>
                                      <button onClick={() => arrayHelpers.move(index, index - 1)} type='button'>
                                        <img src='/images/pictos/arrow-up-white.svg' />
                                      </button>
                                      <button onClick={() => arrayHelpers.move(index, index + 1)} type='button'>
                                        <img src='/images/pictos/arrow-down-white.svg' />
                                      </button>
                                    </>
                                  )
                                }
                              </div>
                              <div className='form-row-label-input'>
                                <Field
                                  name={`macroroutines.${index}.name`}
                                  id={`macroroutines.${index}.name`}
                                  placeholder='Nom de la Macro-Routine'
                                  options={macroroutinesOptions}
                                  isMulti={false}
                                  component={MultiSelect}
                                />
                              </div>
                              {
                                values.macroroutines.length > 1 && (
                                  <img
                                    className='adminChatbot-popup__manyanswers-delete'
                                    src="/images/pictos/delete-file.svg"
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                )
                              }
                            </div>
                            <ErrorMessage name={`macroroutines.${index}.name`} component={"p"} className={"color-error text-primary"} />
                          </span>
                        ))
                      }
                      <button
                        onClick={() => arrayHelpers.push({ id: values.macroroutines.length + 1, name: '' })}
                        className={"btn-default btn-default-secondary plain"}
                        type='button'>
                        Ajouter une Macro-Routine à la Méga-Routine
                      </button>
                    </div>
                  )}
                />
                <button className={"btn-default btn-default-secondary plain"} disabled={isSubmitting} type='submit'>
                  Modifier la Méga-Routine
                </button>
              </Form>
            )
            }
          </Formik >
        )
      }
    </>

  )
}

export default PopupEditMegaroutine;