import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ForgottenPasswordValidationSchema } from "../../../../schemas/login/ForgottenPasswordValidationSchema";

import PasswordService from "../../../../services/PasswordService";

const ForgetPassword = () => {

  const [requestSubmitted, setRequestSubmitted] = useState(false);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await PasswordService.resetPassword(values)
    if (response.status === 200) {
      setRequestSubmitted(true)
    } else {
      toast.error("Aucune adresse mail ne correspond à votre compte")
    }
  }


  return (
    <div className={"section-login"}>
      <img className={"section-login-logo"} src={"/images/pictos/logo_mon_bureau_detudes_baseline.png"} alt={"Mon bureau d'études logo"} />
      <div className={"section-login-form"}>
        <div className={"forgotten-password-form"}>
          <div className={"forgotten-password-form-title"}>
            <h4>Mot de passe oublié</h4>
          </div>

          {
            requestSubmitted ? (
              <p className="text-primary color-dark-grey info">
                Votre demande a bien été prise en compte, vous allez recevoir un e-mail avec un lien pour réinitialiser votre mot de passe.
              </p>
            ) : (
              <>
                <p className="text-primary color-dark-grey info">
                  Veuillez saisir l’adresse mail correspondante à votre compte, un lien de réinitialisation vous sera envoyé par mail.
                </p>

                <div className={"forgotten-password-form-container"}>

                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={ForgottenPasswordValidationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting, values }) => (
                      <Form>
                        <div className={"form-row-element form-row-single-element"}>
                          <div className={"form-row-label-input"}>
                            <Field
                              name="email"
                              type="text"
                              placeholder="Adresse e-mail"
                              className={"input-text-custom-secondary"}
                            />
                            <ErrorMessage name="email" component="p" className={"text-primary color-error"} />
                          </div>
                        </div>


                        <button
                          type="submit"
                          className={"btn-default btn-default-primary color-primary btn-default-full-width"}
                        >
                          Envoyer
                        </button>

                      </Form>
                    )}
                  </Formik>
                  <div className={"forgot-password"}>
                    <Link to={"/"} className={"text-primary color-blue-jeans"}>Se connecter</Link>
                  </div>
                </div>
              </>
            )
          }

        </div>
      </div>
    </div>
  )
}

export default ForgetPassword;