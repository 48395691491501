import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, useFormikContext} from "formik";

const SelectDetailledService = ({singleOrderIndex, singleQuoteIndex, dataSelect, update, project, setUpdate}) => {
	const {values, setFieldValue} = useFormikContext()
	/*useState of all service Collection*/
	const [servicesCollection, setServicesCollection] = useState([]);
	useEffect(() => {
		let dataToPush = []
		dataSelect.map((itemCat, index) => {
			if (itemCat.services) {
				itemCat.services.map((itemService) => {
					dataToPush.push(itemService)
				})
			}
		})
		setServicesCollection(dataToPush)
	}, [update]);

	/*Set services inside "Service Détaillé"*/
	const [serviceSelected, setServiceSelected] = useState([]);
	useEffect(() => {
		let idGeneralService = values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.idGeneralService
		if (idGeneralService) {
			let dataToPush = []
			let ServiceIri = `/api/categories/${idGeneralService}`
			servicesCollection.map((item) => {
				if (item.categoryIri == ServiceIri) {
					dataToPush.push(item)
				}
			})
			setServiceSelected(dataToPush)
		}

	}, [servicesCollection, values.quotes, update]);


	/*Found element selected inside "Service detaillé"*/
	const [elementSelected, setElementSelected] = useState({});
	useEffect(() => {
		if (values) {
			let generalServiceId = values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.idGeneralService
			let idService = values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.idService
			if (generalServiceId) {
				if (idService) {
					setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.serviceIri`, `/api/services/${idService}`)
					let elementToFind = serviceSelected.find((element) => element.id == idService)
					if (elementToFind) {
						setElementSelected(elementToFind)
					} else {
						setElementSelected({})
					}
				}
			}
		}


	}, [values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.idGeneralService, values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.idService, update]);


	/*If the id General service change re set to empty string the serviceIri"*/
	useEffect(() => {
		setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.serviceIri`, "")
	}, [values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.idGeneralService, update]);


	/*Initial Value of price and change price in function of what is selected*/
	useEffect(() => {
		if (values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.idService) {
			let idService = parseInt(values.quotes[singleQuoteIndex].orders[singleOrderIndex].idService)
			setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.idService`, idService)
			let valuesPrice = values.quotes[singleQuoteIndex].orders[singleOrderIndex].price
			if (values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.id) {
				let projectPrice
				if (project && project.quotes && project.quotes.length > 0) {
					const singleQuote = project.quotes[singleQuoteIndex];
					if (singleQuote && singleQuote.orders && singleQuote.orders.length > 0) {
						const singleOrder = singleQuote.orders[singleOrderIndex];
						if (singleOrder && singleOrder.price) {
							projectPrice = parseFloat(singleOrder.price);
						}
					}
					if (project &&
						project.quotes?.[singleQuoteIndex] &&
						project.quotes[singleQuoteIndex].orders?.[singleOrderIndex] &&
						idService === project.quotes[singleQuoteIndex].orders[singleOrderIndex].idService &&
						valuesPrice === projectPrice) {

						setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.price`, projectPrice)
						/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.spent`, elementSelected.spent) */
						/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.description`, elementSelected.description) */
/* 						setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.margin`, (projectPrice - elementSelected.spent).toFixed(2))
 */						/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.title`, elementSelected.title) */
					} else if (idService === project.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.idService && valuesPrice !== projectPrice) {
						/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.spent`, elementSelected.spent) */
						/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.description`, elementSelected.description) */
						/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.margin`, (valuesPrice - elementSelected.spent).toFixed(2)) */
						/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.title`, elementSelected.title) */
					} else {
						setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.price`, elementSelected.price)
						setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.title`, elementSelected.title)
						setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.description`, elementSelected.description)
						setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.spent`, elementSelected.spent)
						setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.margin`, (elementSelected.price - elementSelected.spent).toFixed(2))
						project.quotes[singleQuoteIndex].orders[singleOrderIndex].idService = idService
					}
				}
			} else {
				if ((idService === values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.idService)) {
					/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.spent`, elementSelected.spent) */
					/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.title`, elementSelected.title)
					setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.description`, elementSelected.description) */
					/* setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.margin`, (valuesPrice - elementSelected.spent).toFixed(2)) */
				} else {
					setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.price`, elementSelected.price.toFixed(2))
					setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.spent`, elementSelected.spent.toFixed(2))
					setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.title`, elementSelected.title)
					setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.description`, elementSelected.description)
					setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.margin`, (elementSelected.price - elementSelected.spent).toFixed(2))
				}
			}
		}
		setUpdate(update + 0.000001) // Mineur update

	}, [elementSelected, values.quotes?.[singleQuoteIndex]?.orders?.[singleOrderIndex]?.price]);

	return (
		<div className={"form-row-label-input"}>
			<label htmlFor={`quotes[${singleQuoteIndex}].orders[${singleOrderIndex}].idService`}
			       className={"text-micro paragraph_bold color-dark-grey"}>Code article</label>
			<Field name={`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.idService`} multiple={false}
			       className={"select-custom-secondary"} as={"select"} placeholder={"Service détaillé"}>
				<option hidden> {"Service détaillé"}</option>
				{serviceSelected.map((item, index) => {
					return (
						<option key={index} value={item.id}>{item.reference}</option>
					)
				})}
			</Field>
			<ErrorMessage name={`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.idService`} component={"p"}
			              className={"color-error text-primary"}/>
		</div>
	);
};

export default SelectDetailledService;
