import React, {useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {Form, Formik, getActiveElement, useFormikContext} from "formik";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import userService from "../../../../services/UserService";
import InputLabelTwoSelectErrorMessage from "../../../Atomes/Formik/InputLabelTwoSelectErrorMessage";
import InputTextareaErrorMessage from "../../../Atomes/Formik/InputTextareaErrorMessage";
import {initialValuesIngCollaboratorProjectEdit} from "../../../../config/InitialValues/Engineer/initialValuesIngCollaboratorProjectEdit";
import {ingCollaboratorProjectEditSchema} from "../../../../schemas/Engineer/ingCollaboratorProjectEditSchema";

const FormIngEditProject = ({collaborator, allProjects, allRoles, projectData, progressItem}) => {
	const {addUser} = userService

	const [roleSelected1, setRoleSelected1] = useState({})
	const [roleSelected2, setRoleSelected2] = useState({})
	const [roleSelected3, setRoleSelected3] = useState({})
	const [roleSelected4, setRoleSelected4] = useState({})

	const [rolesData1, setRolesData1] = useState(allRoles)
	const [rolesData2, setRolesData2] = useState(allRoles)
	const [rolesData3, setRolesData3] = useState(allRoles)
	const [rolesData4, setRolesData4] = useState(allRoles)

	const [showBtnAssignRole, setShowBtnAssignRole] = useState(false)
	const [roleCount, setRoleCount] = useState(1);


	const onChangeSelectRole = (e, setFieldValue) => {
		const selectedValue = e.target.value;
		setFieldValue(`roleAttribue${roleCount}`, selectedValue);

		e.target.name === `roleAttribue${roleCount}` ? setShowBtnAssignRole(true) : null
		const setRoleSelected = [setRoleSelected1, setRoleSelected2, setRoleSelected3, setRoleSelected4];
		for (let i = 1; i <= 4; i++){
			if (e.target.name === `roleAttribue${i}`){
				setRoleSelected[i-1](allRoles.filter(roles => roles.id === e.target.value)[0])
			}
		}
	}


	const assignRoleSupp = () => {
		for (let i = 1; i <= 4; i++){
			const selectAttribue = document.querySelector(`select[name = 'roleAttribue${i}']`)
			roleCount === i ? selectAttribue.disabled = true : null
		}


		roleCount < 4 ? setRoleCount(roleCount + 1) : null
		setShowBtnAssignRole(false)
	};

	const handleIngFieldClose = () => {
		setRoleCount(roleCount - 1)
		setShowBtnAssignRole(true)

		for (let i = 1; i <= 4; i++){
			const selectAttribue = document.querySelector(`select[name = 'roleAttribue${i - 1}']`)
			roleCount === (i) ? selectAttribue.disabled = false : null
		}
	};


	useEffect(() => {
		roleCount === 4 ? setShowBtnAssignRole(false) : null

		setRolesData2(allRoles.filter((role) => role !== roleSelected1))
		setRolesData3(allRoles.filter((role) => (role !== roleSelected1 && role !== roleSelected2)))
		setRolesData4(allRoles.filter((role) =>  (role !== roleSelected1 && role !== roleSelected2 && role !== roleSelected3)))
	}, [roleCount, showBtnAssignRole, roleSelected1, roleSelected2, roleSelected3, roleSelected4])


	const onSubmit = async (values, actions) => {
		/*const request = await addUser(`/users`, values.user)
		if (request.request.status === 201){
			//setStateRequest("success add")
			setShowPopUp(false)
			actions.resetForm();
		} else {
			//setStateRequest("error")
		}*/
		actions.resetForm()
	}



	return (
		<Formik
			initialValues={initialValuesIngCollaboratorProjectEdit}
			validationSchema={ingCollaboratorProjectEditSchema}
			onSubmit={onSubmit}
		>

			{({ isSubmitting, setFieldValue }) => (
			<Form className={"pop-up-form-container-collaborator"}>
				<div className={"card-collaborator-title"}>
					<img src={collaborator.img}/>
					<div className={"card-collaborator-title-text"}>
						<h4 className={"color-primary"}>{collaborator.niceNames}</h4>
						<p className={"text-primary color-primary"}>{collaborator.job}</p>
					</div>
				</div>
				<p className={"text-primary paragraph_bold color-primary"}>{progressItem.adress}</p>
				<div className="pop-up-form-container-team-dates">
					<div className="pop-up-form-container-team-dates-item">
						<p className="text-micro paragraph_bold color-dark-grey">Date de la visite</p>
						<p className="text-primary color-primary">Date</p>
					</div>
					<div className="pop-up-form-container-team-dates-item">
						<p className="text-micro paragraph_bold color-dark-grey">Validation principe structure</p>
						<p className="text-primary color-primary">Date</p>
					</div>
					<div className="pop-up-form-container-team-dates-item">
						<p className="text-micro paragraph_bold color-dark-grey">Date de rendu</p>
						<p className="text-primary color-primary">date</p>
					</div>
				</div>
				<div>
					<div id="cardAddProjectAddRole">
						{[...Array(roleCount)].map((_, index) => (
							<InputLabelTwoSelectErrorMessage type={"select"}
															 key={index}
															 closeField={handleIngFieldClose}
															 cross={index > 0}
															 onChangeSelect={(e, id) => onChangeSelectRole(e, setFieldValue)}
															 keyLabel={"role"}
															 keyValue={"id"}
															 keyLabelHours={"label"}
															 keyValueHours={"value"}
															 keyLabelSupport={"label"}
															 keyValueSupport={"value"}
															 nameRole={`roleAttribue${index + 1}`}
															 nameHour={`roleHour${index + 1}`}
															 nameSupport={`roleSupport${index + 1}`}
															 label={`Rôle attribué ${index + 1}`}
															 data={index === 0 ? rolesData1
																 : index === 1 ? rolesData2
																	 : index === 2 ? rolesData3
																		 : rolesData4}
															 labelSupport={`Ingénieur support ${index + 1}`}
															 dataSupport={[{
																 value: "IngeSupport1",
																 label: "Ingé Support #1"

															 },{
																 value: "IngeSupport2",
																 label: "Ingé Support #2"

															 },{
																 value: "IngeSupport3",
																 label: "Ingé Support #3"
															 }
															 ]}
							/>
						))}
					</div>
					{
						showBtnAssignRole ?
							<div className="form-row-label-input-two-select-add" onClick={assignRoleSupp}>
								<p className="text-primary color-blue-jeans">Attribuer un rôle supplémentaire au collaborateur</p>
								<img src="/images/pictos/add-blue-jeans.svg" alt=""/>
							</div>
						: null
					}
				</div>
				<div className="list-commentaires">
					{
						progressItem.commentaires.map((project, index) => {
							return(
								<div key={index} className="list-commentaires-item">
									<img  className="list-commentaires-item-image" src={project.img} alt=""/>
									<div className="list-commentaires-item-content">
										<p className="text-primary color-primary paragraph_bold">
											{project.date} à {project.heure}
										</p>
										<p className="text-primary color-primary">
											{project.contenu}
										</p>

									</div>
								</div>
						)
					})
					}
				</div>
				<InputTextareaErrorMessage
					name={"commentaireProjectCollaborator"}
					placeholder={"Nouveau commentaire"}
					className={"commentaire-textarea"}
				/>
				<ButtonSubmit title={"Modifier"}
							  isActive={false}
							  styleButton={"btn-default btn-default-secondary plain"}
				/>
			</Form>)}
		</Formik>
	);
};

export default FormIngEditProject;
