import React, {useContext, useEffect, useRef} from "react";
import {ChatbotContext} from "../Chatbot";

const ChatbotMessage = () => {

	const contextValue = useContext(ChatbotContext);

	const messagesEndRef = useRef(null)
	const scrollToBottom = () => {
		messagesEndRef.current.scrollIntoView({behavior: "smooth"})
	}

	useEffect(() => {
		if (contextValue.activeQuestion) {
			scrollToBottom()
		}
	}, [contextValue.allQuestionsAnswered, contextValue.questionLoaded]);

	return (
		<div className="mbe-chatbot__content-messages">

			{/* <div className="item-mbe">
        <p className="text-primary color-blue-jeans">Chatbot MonBureaud'Etudes</p>
        <p className="text-primary color-black">
          Bonjour, vous souhaitez estimer votre projet et/ou recevoir un devis en ligne? Répondez à ces quelques questions !
        </p>
      </div> */}

			{
				contextValue.allQuestionsAnswered.map((item, index) => (
					<section key={index}>

						{
							item.data.chatbotMegaRoutineTitle && (
								<div className="item-mbe">
									<p className="text-primary color-blue-jeans">mon bureau d’études.</p>
									<p className="text-primary color-black">
										{item.data.chatbotMegaRoutineTitle}
									</p>
								</div>
							)
						}

						<div className="item-mbe">
							<p className="text-primary color-blue-jeans">mon bureau d’études.</p>
							<p className="text-primary color-black">
								{item.data.question}
							</p>
						</div>

						{
							item.answer && (
								<div className="item-user">
									<p className="text-primary color-blue-jeans">Moi</p>
									<p className="text-primary color-black">
										{item.answer.stringAnswers}
									</p>
								</div>
							)
						}

					</section>
				))
			}

			{
				!contextValue.questionLoaded && (
					<div className="lds-ellipsis">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				)
			}
			<div ref={messagesEndRef}/>

		</div>
	)
}

export default ChatbotMessage;
