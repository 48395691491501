import React from 'react';

const ButtonDeleteElementForm = ({title ,onClick}) => {
  return (
    <div className={"button-remove-element"} onClick={onClick}>
      <img src={"/images/pictos/remove-icon.svg"} alt={"icon supprimer"}/>
      <p className={"text-primary color-error"}>{title}</p>
    </div>
  );
};

export default ButtonDeleteElementForm;
