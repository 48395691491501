import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ConvertStringToFloat } from "../../../utils/StringUtils";
import moment from "moment";
import exportFileService from "../../../services/ExportFileService";
import PopUpProjectsAdmin from "../../../components/Molecules/PopUp/PopUpProjectsAdmin";

export const PROJECTS_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Nom client",
		accessor: "client.lastname",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.lastname}</Link>
			)
		}
	},
	{
		Header: "Prénom client",
		accessor: "client.firstname"
	},
	{
		Header: "Téléphone",
		accessor: "client.phone"
	},
	{
		Header: "Adresse e-mail",
		accessor: "client.email"
	},
	{
		Header: "Prix HT",
		accessor: "price",
		Cell: cellInfo => {
			return (
				<div>{ConvertStringToFloat(cellInfo.row.original.totalInvoices)}</div>
			)
		}
	},
	{
		Header: "Prix TTC",
		accessor: "priceTTC",
		Cell: cellInfo => {
			return (
				<div>{ConvertStringToFloat(cellInfo.row.original.totalInvoicesWithTax)}</div>
			)
		}
	},
	{
		Header: "Actions",
		accessor: "",
		Cell: cellInfo => {

			const [showPopUp, setShowPopUp] = useState(false);

			const downloadFile = () => {
				const date = moment().format("L").split("/").join("-")
				exportFileService.exportFile(`/projects/${cellInfo.row.original.id}`, `export-projects-${date}`, {}, "zip")
			}

			return (
				<>
					<div className={"actions-controller"}>
						{
							cellInfo.row.original.archive === false ? (
								<img src={"/images/pictos/folder.svg"} title="Clôturer le dossier" onClick={() => setShowPopUp(true)} />
							) : null
						}

						<img src={"/images/pictos/download.svg"} title="Télécharger le dossier" onClick={downloadFile} />
					</div>

					{
						showPopUp ? (
							<PopUpProjectsAdmin id={cellInfo.row.original.id} onClick={() => setShowPopUp(false)} />
						) : null
					}

				</>
			)
		}
	}
]
