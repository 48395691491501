import React from 'react';

const ButtonAddElementForm = ({title ,onClick}) => {
  return (
    <div className={"button-add-element"} onClick={onClick}>
      <img src={"/images/pictos/add-icon.svg"} alt={"icon plus"}/>
      <p className={"text-primary color-primary"}>{title}</p>
    </div>
  );
};

export default ButtonAddElementForm;
