import React, {useEffect, useState} from 'react';
import TitleIconNavLink from "../../../../../../../components/Atomes/Title/TitleIconNavLink";
import {HOME_PATH} from "../../../../../../../config/UrlConstants";
import TabNavigationStudyFolder from "../../Partials/TabNavigationStudyFolder";
import PopUpInformations from "../../Partials/PopUpInformations";
import PopUpSendStudyFolder from "../../Partials/PopUpSendStudyFolder";
import StudyFolderMenu from "../../Partials/StudyFolderMenu";
import PopUpForm from "../../../../../../../components/Molecules/PopUp/PopUpForm";
import FormAddCCTP from "../../../../../../../components/Molecules/Form/Engineer/FormAddCCTP";
import PopUpResetForm from "../../Partials/PopUpResetForm";
import PopUpGenerateCCTP from "../../Partials/PopUpGenerateCCTP";
import {useParams} from "react-router-dom";
import DirRespService from "../../../../../../../services/Ing/DirResp/DirRespService";
import Skeleton from "react-loading-skeleton";
import {toast} from "react-toastify";

const StudyFolderCCTP = () => {

	const [showInformation, setShowInformation] = useState(false)
	const [studyFolderStep] = useState("CCTP")
	const [showPopUp, setShowPopUp] = useState(false)
	const [addCCTP, setAddCCTP] = useState(false)

	const [loadingCCTP, setLoadingCCTP] = useState(true)
	const [loading, setLoading] = useState(true);

	const [allCCTP, setAllCCTP] = useState(null)

	const [showResetForm, setShowResetForm] = useState(false)
	const [showGenerateCCTP, setShowGenerateCCTP] = useState(false)

	const [content, setContent] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const generateCCTP = () => {
		console.log("generateCCTP")
	}

	const {id} = useParams()

	const handleFormSubmit = (values) => {
		const newData = [...allCCTP.studyCctps, values];
		setAllCCTP({
			...allCCTP,
			studyCctps: newData
		})
	};

	const resetForm = () => {
		window.location.reload();
	}

	const getProjectsInformations = async () => {
		const request = await DirRespService.getStudyFolderInformations(id)
		if (request.status === 200) {
			setContent(request.data)
			setLoading(false)
		}
	}

	const getCCTP = async () => {
		const request = await DirRespService.getStudyFolderCCTP(id)
		if (request.status === 200) {
			let newData = [...request.data.studyCctps]
			//order new data by origin
			newData = newData.sort((a, b) => {
				return a.origin - b.origin
			})
			setAllCCTP({
				...request.data,
				studyCctps: newData
			})
			setLoadingCCTP(false)
		}
	}

	const sendCCTP = async () => {
		let newData = [...allCCTP.studyCctps]
		newData = newData.map((item) => {
			return {
				...item,
				origin: parseInt(item.origin)
			}
		})
		const request = await DirRespService.sendCCTP(id, {
			...allCCTP,
			studyCctps: newData
		})
		if (request.status === 200) {
			toast.success("CCTP envoyé avec succès")
		} else {
			toast.error("Une erreur est survenue")
		}
	}

	useEffect(() => {
		setLoading(true)
		setLoadingCCTP(true)
		getProjectsInformations()
		getCCTP()
	}, [id])

	return (
		<>
			{
				loadingCCTP || loading ?
					<Skeleton height={500}/> : (

						<div className={"section-study-folder-cctp mbe-section"}>
							<div className={"section-study-folder-cctp-title"}>
								<TitleIconNavLink title={`${content.project.client.niceNames} - ${content.project.address.street}, ${content.project.address.nicePostcode}, ${content.project.address.city}`} link={HOME_PATH} information={true}
								                  onClick={() => setShowInformation(true)}/>
							</div>
							<TabNavigationStudyFolder theStep={studyFolderStep} id={id} needExecutionClass={content.needExecutionClass} needCCTP={content.needCctp}
							                          needSummary={content.needSummary}/>
							<div className={"section-study-folder-cctp-container"}>

								<div className={"section-study-folder-cctp-container-editor"}>
									<div className={"section-study-folder-cctp-container-editor-title"}>
										<h3 className={"text-primary color-primary"}>CCTP</h3>
										<div className={"section-study-folder-cctp-container-editor-title-actions"}>
											<img alt={"img add"} className={"btn"} src={"/images/pictos/add-cctp.svg"}
											     onClick={() => setAddCCTP(!addCCTP)}/>
											<img alt={"img generate"} className={"btn"} src={"/images/pictos/generate-cctp.svg"}
											     onClick={() => setShowGenerateCCTP(!showGenerateCCTP)}/>
											<img alt={"img refresh"} className={"btn"} src={"/images/pictos/refresh-cctp.svg"}
											     onClick={() => setShowResetForm(!showResetForm)}/>
											<img alt={"img save"} className={"btn-blue"} src={"/images/pictos/Save.svg"}
											     onClick={() => sendCCTP()}/>
										</div>
									</div>

									<div className={"section-study-folder-cctp-container-editor-sommaire"}>
										<p className={"text-primary color-primary"}>MENU DOCUMENT</p>
										<div className={"section-study-folder-cctp-container-editor-sommaire-element"}>
											<ul>
												{
													allCCTP?.studyCctps?.map((item, index) => {
														const sommaire = `#${item.title.split(" ").join("-").toLowerCase()}-${index}`;
														return (
															<li key={index}>
																<a href={sommaire} className="text-primary color-primary paragraph_bold">
																	{item.title}
																</a>
																{index !== allCCTP.studyCctps.length - 1 && " - "}

															</li>
														);
													})
												}
											</ul>
										</div>
									</div>

									<div className={"section-study-folder-cctp-container-editor-content"}>
										{
											allCCTP?.studyCctps?.map((item, index) => {
												const sommaire = `${item.title.split(" ").join("-").toLowerCase()}-${index}`;
												return (
													<div key={index} className={"section-study-folder-cctp-container-editor-content-element"}>
														<p className={"text-primary color-primary"} id={sommaire}>{item.title}</p>
														<p className={"text-primary color-primary"}
														   dangerouslySetInnerHTML={{__html: item.content}}></p>
													</div>
												);
											})
										}
									</div>
								</div>

								<StudyFolderMenu content={content} projectId={content.project.id}/>

							</div>

							<PopUpInformations
								show={showInformation}
								onClick={() => setShowInformation(false)}
								large={false}
								title={"Informations projet"}
								content={content}
							/>

							{
								addCCTP ? (
									<PopUpForm onClick={() => setAddCCTP(!addCCTP)}
									           form={<FormAddCCTP onSubmit={handleFormSubmit} onClick={() => setAddCCTP(!addCCTP)}/>}
									           title={"Ajout d’une prescription particulière"} show={addCCTP} large={true}/>
								) : null
							}

							{
								showPopUp ? (
									<PopUpSendStudyFolder id={1} onClick={() => {
										setShowPopUp(!showPopUp)
										sendCCTP()
									}}/>
								) : null
							}

							{
								showResetForm ? (
									<PopUpResetForm
										onClick={() => setShowResetForm(!showResetForm)}
										handleSubmit={() => resetForm()}
										message={"Les prescriptions spécifiques que vous avez ajouté sont sur le point d’être éffacées. Êtes-vous sûr de vouloir les  réinitialiser ? Cette action est irréversible."}
										no={"Non, ne rien effacer"}
										yes={"Oui, réinitialiser"}
									/>
								) : null
							}

							{
								showGenerateCCTP ? (
									<PopUpGenerateCCTP onClick={() => setShowGenerateCCTP(!showGenerateCCTP)}
									                   handleSubmit={() => generateCCTP()}/>
								) : null
							}

						</div>
					)
			}
		</>

	);
};

export default StudyFolderCCTP;
