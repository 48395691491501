import api from "./HttpService";
import apiNoToken from "./LoginService";
import refreshTokenService from "./RefreshTokenService";

const get = async (url) => {
	try{
		const response = await api.get(url)
		return response.data
	}catch (error){
		if (error.response.status === 401){
			refreshTokenService.refreshToken()
		}else{
			return error
		}
	}
};

const getAllData = async (url) => {
	try{
		const response = await api.get(url)
		return response
	}catch (error){
		if (error.response.status === 401){
			refreshTokenService.refreshToken()
		}else{
			return error
		}
	}
};

const post = async (url, data) => {
	try{
		return await api.post(
			url,
			null,
			{
				params: data
			}
		)
	}catch (error){
		if (error.response.status === 401 && (error.response.data.message === "JWT Refresh Token Not Found" || error.response.data.message === "Expired JWT Token")){
			//remove user from local storage to log user out
			localStorage.removeItem("user");
			localStorage.removeItem("role");
			window.location.reload();
		}else{
			return error
		}
	}

}

const postLogin = async (url, data) => {
	try{
		return await apiNoToken.post(url, data)
	}catch (error){
		if (error.response.status === 401 && (error.response.data.message === "JWT Refresh Token Not Found" || error.response.data.message === "Expired JWT Token")){
			localStorage.removeItem("user");
			localStorage.removeItem("role");
			window.location.reload();
		}else{
			return error
		}
	}
}

const postBody = async (url, data) => {

	try{
		return await api.post(url, data)
	}catch (error){
		if (error.response.status === 401 && (error.response.data.message === "JWT Refresh Token Not Found" || error.response.data.message === "Expired JWT Token")){
			localStorage.removeItem("user");
			localStorage.removeItem("role");
			window.location.reload();
		}else{
			return error
		}
	}
}

const patch = async (url, data) => {
	try{
		return await api.patch(
			url,
			data,
		)
	}catch (error){
		if (error.response.status === 401 && (error.response.data.message === "JWT Refresh Token Not Found" || error.response.data.message === "Expired JWT Token")){
			localStorage.removeItem("user");
			localStorage.removeItem("role");
			window.location.reload();
		}else{
			return error
		}
	}
}

const del = async (url) => {
	try{
		return await api.delete(url)
	}catch (error){
		if (error.response.status === 401 && (error.response.data.message === "JWT Refresh Token Not Found" || error.response.data.message === "Expired JWT Token")){
			//remove user from local storage to log user out
			localStorage.removeItem("user");
			localStorage.removeItem("role");
			window.location.reload();
		}else{
			return error
		}
	}
}

const ApiService = {
	get,
	post,
	postBody,
	patch,
	del,
	getAllData,
	postLogin
}

export default ApiService;
