import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

import CardPartnerMarketplace from '../../../../components/Molecules/Cards/PartnerMarketplace/CardPartnerMarketplace';
import PopupMarketplaceCustomer from './Popup/PopupMarketplace';

import {CustomerService} from '../../../../services/CustomerService';

const MarketplaceCustomer = () => {

	const [showPopup, setShowPopup] = useState(false);

	const [partnerSelected, setPartnerSelected] = useState(null);
	const [partners, setPartners] = useState([]);
	const [partnersLoaded, setPartnersLoaded] = useState(false);
	const getPartners = async () => {
		const request = await CustomerService.getPartnersActive();
		if (request.status === 200) {
			let datas = request.data['hydra:member'];
			let partnersSorted = [];
			datas.map((data) => {
				if (partnersSorted[data.niceCategory] === undefined) {
					partnersSorted[data.niceCategory] = [];
				}
				partnersSorted[data.niceCategory].push(data);
			});
			setPartnersLoaded(true);
			setPartners(partnersSorted);
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		getPartners();
	}, []);

	const openPopup = (partner) => {
		setPartnerSelected(partner);
		setShowPopup(true);
	};

	const closeAction = () => {
		setShowPopup(false);
	};

	const validAction = async () => {
		let request = await CustomerService.postCustomerDataToPartner(partnerSelected.id, {"projectIri": ""});
		if (request.status === 200) {
			toast.success('Votre demande a bien été envoyée');
			setShowPopup(false);
		}
	}

	return (
		<>
			<div className='customer-marketplace page'>
				<div className='customer-marketplace__heading'>
					<h2 className='color-primary'>Marketplace partenaires</h2>
					<h3>Bienvenue sur la marketplace de mon bureau d’études. 🎉</h3>
					<p className="text-primary">Découvrez nos partenaires qui peuvent vous accompagner dans la suite de votre
						projet. Cliquez sur un de nos partenaires pour qu'il puisse vous recontacter et échanger avec vous sur vos
						besoins.</p>
				</div>

				{!partnersLoaded ? <Skeleton count={3} height={400}/> : (
					Object.keys(partners).map((partner, index) => (
						<div className='customer-marketplace__category' key={index}>
							<h3>{partner}</h3>
							<div className='customer-marketplace__category__items'>
								{
									partners[partner].map((partner, index) => (
										<CardPartnerMarketplace
											key={index}
											partner={partner}
											index={index}
											openPopup={openPopup}
										/>
									))
								}
							</div>
						</div>
					))
				)}
			</div>

			{
				showPopup && (
					<PopupMarketplaceCustomer
						showPopup={showPopup}
						closeAction={closeAction}
						validAction={validAction}
						titlePartner={partnerSelected.title}
					/>
				)
			}
		</>
	);
}

export default MarketplaceCustomer;
