import React, { useState, useEffect, useContext } from 'react'
import { ChatbotAdminActionsContext } from '../ChatbotAdmin';

import Skeleton from 'react-loading-skeleton'

import CardMegaroutine from '../../../../../components/Molecules/Cards/Chatbot/CardMegaroutine'
import PopUpForm from '../../../../../components/Molecules/PopUp/PopUpForm';
import PopupEditMegaroutine from './Popup/PopupEditMegaroutine'

import { AdminService } from '../../../../../services/AdminService'

const ChatbotMegaRoutineAdmin = () => {

  const chatbotContext = useContext(ChatbotAdminActionsContext)

  const [megaroutines, setMegaroutines] = useState([]);
  const [megaroutinesLoading, setMegaroutinesLoading] = useState(true);
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [megaroutineToEdit, setMegaroutineToEdit] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0)
    getMegaroutines()
  }, [])

  useEffect(() => {
    if (chatbotContext.chatbotAdminActionsContext.name === "mega-routine") {
      getMegaroutines();
      chatbotContext.chatbotAdminActionsContext = {}
    }
  }, [chatbotContext])

  const getMegaroutines = async () => {
    setMegaroutinesLoading(true)
    let request = await AdminService.getWithParams("/chatbot-mega-routines", { 'pagination': 'false' })
    if (request.status === 200) {
      setMegaroutines(request.data["hydra:member"])
      setMegaroutinesLoading(false)
    }
  }

  const openPopupEdit = (megaroutine) => {
    setMegaroutineToEdit(megaroutine)
    setShowPopupEdit(true)
  }

  const closePopupEdit = () => {
    setShowPopupEdit(false)
    setMegaroutineToEdit(null)
  }

  return (
    <section className='adminChatbot-megaroutine'>
      {
        megaroutinesLoading ? (
          <div className='adminChatbot-megaroutine__skeleton'>
            <Skeleton height={500} />
            <Skeleton height={500} />
            <Skeleton height={500} />
          </div>
        ) : (
          <>
            {
              megaroutines.length > 0 ? (
                <div className='adminChatbot-megaroutine__wrapper'>
                  {
                    megaroutines.map((megaroutine, index) => (
                      <CardMegaroutine key={index} megaroutine={megaroutine} openPopupEdit={openPopupEdit} />
                    ))
                  }
                </div>
              ) : (
                <p className='text-primary paragraph_bold'>Aucune méga-routine trouvée</p>
              )
            }

            {
              showPopupEdit && (
                <PopUpForm
                  large={true}
                  show={showPopupEdit}
                  form={<PopupEditMegaroutine megaroutineId={megaroutineToEdit} />}
                  onClick={() => closePopupEdit()}
                  title={"Modifier une méga-routine"}
                />
              )
          }
          </>
        )
      }
    </section>
  )
}

export default ChatbotMegaRoutineAdmin