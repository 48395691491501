import React from 'react';
import {Form, Formik} from "formik";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import InputLabelFileErrorMessage from "../../../Atomes/Formik/InputLabelFileErrorMessage";
import {InitialValuesIngPriValidateDoc} from "../../../../config/InitialValues/Engineer/InitialValuesIngPriValidateDoc";
import {IngPriValidateDocSchema} from "../../../../schemas/Engineer/IngPriValidateDocSchema";
import {PriService} from "../../../../services/Engineer/PriService";
import {toast} from "react-toastify";

const FormValidatePri = ({onSubmit, onClick, setShowConfirmValidation, setSubmitForm, id}) => {
	//const [showConfirmation, setShowConfirmation] = useState(false)

	const handleSubmit = async (values, actions) => {

		let request = await PriService.postPriFile(values, id);
		if (request.status === 201) {
			toast.success("Document ajouté avec succès");
			actions.resetForm()
			setSubmitForm(true)
			setShowConfirmValidation(false)
			window.location.href = '/'
		} else {
			toast.error("Une erreur est survenue");
		}
	}

	return (
		<>
			<Formik
				initialValues={InitialValuesIngPriValidateDoc}
				validationSchema={IngPriValidateDocSchema}
				onSubmit={handleSubmit}
			>
				{({isSubmitting}) => (
					<Form className="pop-up-pri-validation-file">
						<div className="pop-up-pri-validation-file-content">
							<h4 className={"color-primary"}>Validation principe de structure (PRI)</h4>
							<p className={"text-primary color-primary"}>
								Veuillez charger le document de référence pour faire valider aux client
								le principe de structure. Il pourra refuser en précisant les raisons.
							</p>
							<div className="form-row-element big-element pop-up-pri-validation-file-content-upload">
								<div className={"form-row-label-input"}>
									<div className={"form-row-element form-row-two-elements input-file-container"}>
										<InputLabelFileErrorMessage
											picture={InitialValuesIngPriValidateDoc.file}
											name={"file"}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="pop-up-pri-validation-file-button">
							<ButtonSubmit
								title={"Envoyer"}
								isActive={false}
								styleButton={"btn-default btn-default-secondary plain"}
							/>
						</div>

						{/*{
						showConfirmation ? (
							<PopUpValidatePriClient
								onClickClose={() => setShowConfirmation(false)}
								onClickDelete={() => console.log('send')}
							/>
						) : null
					}*/}
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormValidatePri;
