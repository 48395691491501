export const initialValuesIngCollaboratorProjectEdit = {
	selectProject: "",
	roleAttribue1: "",
	roleAttribue2: "",
	roleAttribue3: "",
	roleAttribue4: "",
	roleHour1: "",
	roleHour2: "",
	roleHour3: "",
	roleHour4: "",
	roleSupport1: "",
	roleSupport2: "",
	roleSupport3: "",
	roleSupport4: "",
    commentaireProjectCollaborator: ""
}
