import React from 'react';

const PopUpDeleteDevis = ({data,setIsActive,action}) => {
	
	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={(e)=>setIsActive(false)} className={"cross-close"} src={"/images/pictos/cross-close.svg"}/>
				<img src={"/images/medias/popup.svg"} alt="test"/>
				<h4 className={"color-black"}>Voulez vous vraiment supprimer <br/>le Devis n°{data.index + 1}</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button type={"button"} className={"btn btn-cancel"} onClick={(e)=>setIsActive(false)}>Non</button>
					<button type={"button"} className={"btn btn-delete"} onClick={action}>Oui</button>
				</div>
			</div>
		</div>
	);
};

export default PopUpDeleteDevis;
