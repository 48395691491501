import { useContext } from "react"
import { ComService } from "../../../services/ComService"
import { StateRequest } from "../../../views/Pages/Coms/Com/Home/Home"
import { initialValuesAddCommentComHome } from "../../../config/InitialValues/initialValuesAddCommentComHome"
import { EditCommentSchema } from "../../../schemas/commercial/EditCommentSchema"
import { Form, Formik } from "formik"
import InputLabelErrorMessage from "../../Atomes/Formik/InputLabelErrorMessage"
import ButtonSubmit from "../../Atomes/Buttons/ButtonSubmit"

const FormEditCommentHomeCom = ({id, setShow, comment}) => {

    const {stateRequest, setStateRequest} = useContext(StateRequest)

    const onSubmit = async (values) => {
		setShow(false)
		const request = await ComService.patchComent( `quotes/${id}`, values)
		if (request.request.status === 200) {
			setStateRequest("success edit coment")
		} else {
			setStateRequest("error")
		}
	}

    initialValuesAddCommentComHome.comment = comment

	return (
		<>
			<Formik
				initialValues={initialValuesAddCommentComHome}
				validationSchema={EditCommentSchema}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => (
					<Form>
						<div className={"form-row-element"}>
							<InputLabelErrorMessage type={"text"} label={"Commentaire"} name={"comment"}/>
							<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
							              title={"Modifier"}/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	)

}

export default FormEditCommentHomeCom