import React, { useEffect, useState } from 'react';

import TitleIconNavLink from '../../../../components/Atomes/Title/TitleIconNavLink';
import ProfileArchitectAccountForm from './Partials/Account/Form';
import ProfileArchitectBankForm from './Partials/Bank/Form';
import ProfileArchitectPasswordForm from './Partials/Password/Form';

import api from "../../../../services/APIService";
import Skeleton from 'react-loading-skeleton';

const ProfileArchitect = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [accountFormData, setAccountFormData] = useState(null);
  const [bankFormData, setBankFormData] = useState(null);

  const getUserData = async () => {
    const userId = localStorage.getItem("userId");
    if (userId != null) {
      let request = await api.get(`archs/${userId}`);
      setAccountFormData({
        gender: request.gender != "" ? request.gender : "NA",
        firstname: request.firstname,
        lastname: request.lastname,
        email: request.email,
        phone: request.phone,
        agencyName: request.agencyName != "" ? request.agencyName : "",
        billingAddress: {
          street: request.billingAddress.street,
          city: request.billingAddress.city,
          postalCode: request.billingAddress.postcode,
          detail: request.billingAddress.detail,
        }
      });
      setBankFormData({
        accountHolder: request.accountHolder,
        iban: request.iban,
        bic: request.bic,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <section>
      <TitleIconNavLink title={"Tableau de bord"} link={"/"} />
      {
        isLoading ? <Skeleton height={400} /> :
          <>
            <ProfileArchitectAccountForm
              accountFormData={accountFormData}
            />
            <ProfileArchitectBankForm
              bankFormData={bankFormData}
            />
            <ProfileArchitectPasswordForm />
          </>
      }
    </section>
  )
}

export default ProfileArchitect;