import React, { useEffect, useState } from "react";
import CardTeam from "../../../../../../components/Molecules/Cards/Teams/CardTeam";
import DirRespService from "../../../../../../services/Ing/DirResp/DirRespService";

const HomeIngManTeams = () => {

	const [roleExtra, setRoleExtra] = useState([]);
	const [roleDiagnostic, setRoleDiagnostic] = useState([]);
	const [roleManager, setRoleManager] = useState([]);
	const [roleVerificateur, setRoleVerificateur] = useState([]);
	const [roleChargeAffaire, setRoleChargeAffaire] = useState([]);

	const [timeToRealod, setTimeToReload] = useState(false)

	const [projectUnassigned, setProjectUnassigned] = useState([])

	const [selectValue, setSelectValue] = useState(null);
	const [dateDeVisiteEnabled, setDateDeVisiteEnabled] = useState(false)
	const [dateDeRenduEnabled, setDateDeRenduEnabled] = useState(false)

	const [allIngsUsers, setAllIngsUsers] = useState([])

	const getVerificateur = async () => {
		const request = await DirRespService.getUsersByRole({ roles: "ROLE_ING_VERIF" })
		if (request.request.status === 200) {
			setRoleVerificateur(request.data["hydra:member"])
		}
	}

	const getProjectUnassigned = async () => {
		const params = {
			"users.id": selectValue,
		};
	
		// Ajoutez conditionnellement les paramètres en fonction des boolean
		if (dateDeVisiteEnabled) {
			params['order[visit.startAt]'] = "asc"; // Remplacez someDateDeVisiteValue par la valeur que vous voulez envoyer
		}
		if (dateDeRenduEnabled) {
			params['order[study.estimatedEndAt]'] = "asc"; // Remplacez someDateDeRenduValue par la valeur que vous voulez envoyer
		}
		const request = await DirRespService.getAllUnassignedProjects(params)
		if (request.status === 200) {
			setProjectUnassigned(request.data["hydra:member"])
		}
	}

	const getManager = async () => {
		const request = await DirRespService.getUsersByRole({ roles: "ROLE_ING_MANAGER" })
		if (request.request.status === 200) {
			setRoleManager(request.data["hydra:member"])
		}
	}

	const getDiagnostic = async () => {
		const request = await DirRespService.getUsersByRole({ roles: "ROLE_ING_DIAG" })
		if (request.request.status === 200) {
			setRoleDiagnostic(request.data["hydra:member"])
		}
	}

	const filterByCollaborator = (event) => {
		if (event.target.value === "all") {
			setSelectValue("")
		} else {
			setSelectValue(event.target.value)
		}
	}

	const getExtra = async () => {
		const request = await DirRespService.getUsersByRole({ roles: "ROLE_ING_EXTRA" })
		if (request.request.status === 200) {
			setRoleExtra(request.data["hydra:member"])
		}
	}

	const getAffaire = async () => {
		const request = await DirRespService.getUsersByRole({ roles: "ROLE_ING_AFF" })
		if (request.request.status === 200) {
			setRoleChargeAffaire(request.data["hydra:member"])
		}
	}

	const getAllIngs = async () => {
		const request = await DirRespService.getAllCollaboratorsHighRoles({})
		if (request.request.status === 200) {
			setAllIngsUsers(request.data["hydra:member"])
		}
	}

	const filterPicker = async (visite) => {
		if (visite === "visite") {
			setDateDeRenduEnabled(false)
			setDateDeVisiteEnabled(true)
		} else {
			setDateDeVisiteEnabled(false)
			setDateDeRenduEnabled(true)
		}

		getProjectUnassigned()
	}

	useEffect(() => {
		getManager()
		getVerificateur()
		getDiagnostic()
		getExtra()
		getAffaire()
		getAllIngs()
		getProjectUnassigned()
	}, []);

	useEffect(() => {
		getProjectUnassigned()
	}, [timeToRealod, selectValue, dateDeRenduEnabled, dateDeVisiteEnabled])

	return (
		<div className="projects-in-progress">
			<div className="projects-in-progress-title">
				<h3 className="color-primary">Projets à attribuer</h3>
				<div className="projects-in-progress-title-container-filter">
					<select
						className={"select-custom-secondary"}
						onChange={filterByCollaborator}
					>
						<option value="all">Tous les collaborateurs</option>
						{allIngsUsers.map((collaborator, index) => (
							<option key={index} value={collaborator.id}>
								{collaborator.niceNames}
							</option>
						))}
					</select>

					<p onClick={() => filterPicker("visite")} className={`${dateDeVisiteEnabled ? "active" : ""} text-primary`}>Tri par date de visite</p>
					<p onClick={() => filterPicker("rendu")} className={`${dateDeRenduEnabled ? "active" : ""} text-primary`}>Tri par date de rendu</p>
				</div>
			</div>
			<div className="projects-in-progress-container projects-in-progress-engineer-tasks">
				{projectUnassigned.map((team, index) => {
					return (
						<CardTeam
							key={index}
							team={team}
							manager={roleManager}
							verificateur={roleVerificateur}
							diagnostic={roleDiagnostic}
							extra={roleExtra}
							affaire={roleChargeAffaire}
							timeToRealod={() => setTimeToReload(!timeToRealod)}
							dateDeVisiteEnabled={dateDeVisiteEnabled}
							dateDeRenduEnabled={dateDeRenduEnabled}
						/>
					)
				}
				)}
			</div>
		</div>
	)
}

export default HomeIngManTeams;
