import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, useField} from "formik";

const InputLabelTwoSelectErrorMessageOnChange = ({
	                                                 nameRole,
	                                                 nameHour,
	                                                 nameSupport,
	                                                 type,
	                                                 label,
	                                                 labelSupport,
	                                                 placeholder,
	                                                 keyValue,
	                                                 keyLabel,
	                                                 keyValueHours,
	                                                 keyLabelHours,
	                                                 keyValueSupport,
	                                                 keyLabelSupport,
	                                                 data,
	                                                 dataSupport,
	                                                 readonly,
	                                                 canBeNull,
	                                                 cross,
	                                                 closeField,
	                                                 onChangeSelect,
	                                                 ...props
                                                 }) => {

	const [showIngSupport, setShowIngSupport] = useState(false)
	const [showIng, setShowIng] = useState(true)
	const [field, meta, helpers] = useField(nameSupport)

	const [hours, setHours] = useState([])
	const selectHours = () => {
		let arrayTemp = []
		for (let i = 1; i <= 100; i++) {
			if (i === 1) {
				arrayTemp.push({value: i, label: `${i} heure`})
			} else {
				arrayTemp.push({value: i, label: `${i} heures`})
			}
		}
		setHours(arrayTemp)
	}

	useEffect(() => {
		selectHours()
	}, [])
	
	const closeSupportField = () => {
		setShowIngSupport(false)
		helpers.setValue("")
	}

	if (showIng) {
		if (type === "select") {
			return (
				<div className={"form-row-label-input"}>
					<div className="form-row-label-input-two-select">
						<label htmlFor={nameRole} className={"text-micro paragraph_bold color-dark-grey"}>{label}</label>
						<Field multiple={false} className={"select-custom-mini-primary"} as={type} name={nameHour}
						       placeholder={label}>
							<option hidden>{placeholder === undefined ? "0 heure" : placeholder}</option>
							{hours.map((item, index) => {
								if (readonly === "readonly") {
									return (
										<option disabled key={index} value={item[keyValueHours]}>{item[keyLabelHours]}</option>
									)
								} else {
									return (
										<option key={index} value={item[keyValueHours]}>{item[keyLabelHours]}</option>
									)
								}
							})}
						</Field>
					</div>
					{
						cross === false ?
							<Field
								multiple={false}
								className={"select-custom-primary"}
								as={type}
								name={nameRole}
								placeholder={label}
								onChange={onChangeSelect}
							>
								<option hidden>{placeholder === undefined ? "Aucun ingénieur attribué" : placeholder}</option>
								{data.map((item, index) => {
									if (readonly === "readonly") {
										return (
											<option disabled key={index} value={item[keyValue]}>{item[keyLabel]}</option>
										)
									} else {
										return (
											<option key={index} value={item[keyValue]}>{item[keyLabel]}</option>
										)
									}
								})}
								{canBeNull ? <option value={null}>N/A</option> : ""}
							</Field>
							:
							<div className="form-label-input-close">

								<Field
									multiple={false}
									className={"select-custom-primary"}
									as={type}
									name={nameRole}
									placeholder={label}
									onChange={onChangeSelect}
								>
									<option hidden>{placeholder === undefined ? "Aucun ingénieur attribué" : placeholder}</option>
									{data.map((item, index) => {
										if (readonly === "readonly") {
											return (
												<option disabled key={index} value={item[keyValue]}>{item[keyLabel]}</option>
											)
										} else {

											return (
												<option key={index} value={item[keyValue]}>{item[keyLabel]}</option>
											)
										}
									})}
									{canBeNull ? <option value={null}>N/A</option> : ""}
								</Field>
							</div>
					}

					<ErrorMessage name={nameRole} component={"p"} className={"color-error text-primary"}/>
					<ErrorMessage name={nameHour} component={"p"} className={"color-error text-primary"}/>

				</div>
			);
		}
	}
};

export default InputLabelTwoSelectErrorMessageOnChange;
