import React from "react";
import { Link } from "react-router-dom";
import { COMS_DIR_VALIDATION_CIRCUIT } from "../../../../../../config/UrlConstants";
import CardCircuitDeValidationIng
	from "../../../../../../components/Molecules/Cards/CircuitDeValidation/CardCircuitDeValidationIng";

const ApprovalCircuitIngRes = ({ approvalCircuit }) => {

	const allApprovalCircuit = approvalCircuit
	return (
		<div className={"section-circuit-de-validation mbe-section"}>
			<div className={"section-circuit-de-validation-title"}>
				<h3 className={"color-primary"}>Circuit de validation</h3>
				{
					!(allApprovalCircuit.length < 0) &&
					<Link to={COMS_DIR_VALIDATION_CIRCUIT}>Voir tous les circuits de validation</Link>
				}
			</div>
			{
				allApprovalCircuit.length <= 0 ? (
					<p className="section-circuit-de-validation-empty text-primary color-primary">Pas de validation en attente</p>
				) : (
					<div className={"section-circuit-de-validation-container"}>
						{allApprovalCircuit.map((circuit, index) =>
							<CardCircuitDeValidationIng
								id={circuit.id}
								key={index}
								title={circuit.niceStage}
								status={circuit.niceStageStatus}
								niceNames={circuit.client ? circuit.client.niceNames : ""}
								numberFolder={circuit.reference}
								devisId={circuit.id}
								img={circuit.client ? circuit.client.urlAvatar : ""}
								color={circuit.studyControl === "minor" ? "orange" : "red"}
								styleButton={circuit.studyControl === "minor" ? "orange" : "red"}
								niceStudyStatus={circuit.niceStudyStatus}
							/>
						)}
					</div>
				)
			}
		</div>
	)
}
export default ApprovalCircuitIngRes;
