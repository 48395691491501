import api from "./APIService";
import { ConvertObjectToUrlParams } from "../utils/StringUtils";

// get all tasks
const getAllTasks = async (url) => {
    return await api.getAllData(url)
}

// get all tasks of a user
const getTasksUser = async (url, param) => {
    return await api.getAllData(ConvertObjectToUrlParams(url, param))
}

// valid task of a user
const validTaskUser = async (url, data) => {
    return await api.patch(url, data)
}

// get an assignment
const getAssignment = async (url) => {
    return await api.getAllData(url)
}

// post an assignment
const createAssignment = async (url, data) => {
    return await api.postBody(url, data)
}
// edit an assignment
const editAssignment = async (url, taskId, data) => {
    const editUrl = url + '/' + taskId
    return await api.patch(editUrl, data)
}
// delete an assignment
const deleteAssignment = async (url, data) => {
    const delUrl = url + '/' + data
    return await api.del(delUrl)
}

const getControls = async (url) => {
    return await api.getAllData(url)
}

// End Task Service
const TaskService = {
    getAllTasks,
    getTasksUser,
    validTaskUser,
    getAssignment,
    createAssignment,
    editAssignment,
    deleteAssignment,
    getControls
}
export default TaskService;