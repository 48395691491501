import {Form, Formik} from "formik";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import React, {useContext, useEffect, useState} from "react";
import {
	InitialValuesAddPartnerMarketPlace
} from "../../../../config/InitialValues/Admin/InitialValuesAddPartnerMarketPlace";
import InputLabelFileErrorMessage from "../../../Atomes/Formik/InputLabelFileErrorMessage";
import {MarketPlaceSchema} from "../../../../schemas/Admin/MarketPlace/MarketPlaceSchema";
import MarketPlaceService from "../../../../services/MarketPlaceService";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import {StateRequest} from "../../../../views/Pages/Admin/MarketPlace/MarketPlace";

const FormAddPartnerMarketPlace = ({setIsActive}) => {

	const {setStateRequest} = useContext(StateRequest);

	const [categories, setCategories] = useState([]);
	const [isLoading, setIsLoading] = useState(true);


	const onSubmit = async (values) => {
		const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
		values.categoryIri = `/api/categories/${values.categoryIri}`
		values.active = values.active === "true";

		const request = await MarketPlaceService.postPartner({
			"title": values.title,
			"active": values.active,
			"email": values.email,
			"description": values.description,
			"categoryIri": values.categoryIri,
			"transmission": values.transmission,
		})

		if (request.request.status === 201) {
			const requete = axios.create({
				baseURL: process.env.REACT_APP_PROD_API_URL,
				headers: {
					Authorization: `Bearer ${user ? user.token : ""}`,
					'Content-Type': `multipart/form-data`
				}
			})
			const requestLogo = await requete.post(`partners/${request.data.id}/logo`, {file: values.logoUrl})
			const requestImage = await requete.post(`partners/${request.data.id}/image`, {file: values.imageUrl})

			if (requestLogo.request.status === 201 && requestImage.request.status === 201) {
				setStateRequest("success add partner")
				setIsActive(false)
			}
		}
	}

	const getCategories = async () => {
		const request = await MarketPlaceService.getPartnerCategories({"pagination": "false"})
		if (request.request.status === 200) {
			setCategories(request.data['hydra:member'])
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getCategories()

	}, [])

	InitialValuesAddPartnerMarketPlace.logoUrl = ""
	InitialValuesAddPartnerMarketPlace.imageUrl = ""
	InitialValuesAddPartnerMarketPlace.active = ""
	InitialValuesAddPartnerMarketPlace.categoryIri = ""
	InitialValuesAddPartnerMarketPlace.transmission = ""
	InitialValuesAddPartnerMarketPlace.email = ""
	InitialValuesAddPartnerMarketPlace.description = ""
	InitialValuesAddPartnerMarketPlace.title = ""

	return (
		<div className={"form-partner"}>
			<Formik
				initialValues={InitialValuesAddPartnerMarketPlace}
				validationSchema={MarketPlaceSchema}
				onSubmit={onSubmit}
			>
				{({isSubmitting, errors, touched}) => (
					isLoading ? <Skeleton height={550}/> :
						<Form>
							<div className={"form-row-element"}>
								<InputLabelErrorMessage type={"text"} label={"Nom"} name={"title"}/>
							</div>

							<div className={"form-row-element form-row-two-elements input-file-container"}>
								<InputLabelFileErrorMessage name={"logoUrl"} label={"Logo"}/>
								<InputLabelFileErrorMessage name={"imageUrl"} label={"Illustration"}/>
							</div>

							<div className={"form-row-element"}>
								<InputLabelErrorMessage type={"text"} label={"Description"} name={"description"}/>
							</div>

							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"select"} label={"Catégorie"} name={"categoryIri"} data={categories}
								                        keyLabel={"title"} keyValue={"id"}/>
								<InputLabelErrorMessage type={"select"} label={"Transmission Lead"} name={"transmission"}
								                        data={[{"value": "partner", "label": "Partenaire"}, {
									                        "value": "email",
									                        "label": "Commerciaux mon bureau d'études"
								                        }]} keyLabel={"label"} keyValue={"value"}/>
							</div>

							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"text"} label={"Adresse e-mail"} name={"email"}/>
								<InputLabelErrorMessage type={"select"} label={"Actif"} name={"active"} keyValue={"value"}
								                        keyLabel={"label"}
								                        data={[{label: "Oui", value: true}, {label: "Non", value: false}]}/>
							</div>

							<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
							              title={"Ajouter le partenaire"}/>
						</Form>
				)}
			</Formik>
		</div>
	)

}

export default FormAddPartnerMarketPlace;
