import React from "react";

const ButtonSubmit = ({title, styleButton, isActive, onClick, name, value}) => {
	return (
		<div className="button-component" onClick={onClick}>
			<button type={"submit"} name={name} value={value} disabled={isActive}
			        className={styleButton + (isActive ? " btn-default-inactive" : "")}>{title}</button>
		</div>
	);
};

export default ButtonSubmit;
