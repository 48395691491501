import React from "react";

const PopUpSendToClient = ({onClick, approve}) => {
    return (
        <div className={"pop-up-base-style active"}>
            <div className={"pop-up-user-delete"}>
                <img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"}/>
                <h4 className={"text-primary color-black"}>Le dossier d'étude structure va être envoyé au client pour validation.<br/>Êtes-vous sûr de vouloir l'envoyer ?</h4>
                
                <div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={() => onClick()}>Non, retourner aux modifications</button>
					<button className={"btn btn-send"} onClick={() => approve()}>Oui, envoyer au client</button>
				</div>
            </div>
        </div>
    )
}

export default PopUpSendToClient