import api from "./APIService";

const resetPassword = async (data) => {
    const request = await api.postBody("reset-password", data)
    return request
}

const newPassword = async (data) => {
    const request = await api.patch("reset-password", data)
    return request  
}

const PasswordService = {
    resetPassword,
    newPassword
};

export default PasswordService;
