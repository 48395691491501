import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";
import moment from 'moment';
import { toast } from "react-toastify";

import { Formik, Form } from 'formik';
import { initialValuesAssignmentEdit } from '../../../../config/InitialValues/MbeCalendar/InitialValuesAssignmentEdit';
import { SchemaAssignment } from '../../../../schemas/Calendar/SchemaAssignment';
import InputLabelErrorMessage from '../../../Atomes/Formik/InputLabelErrorMessage';

import Button from '../../../Atomes/Buttons/Button';
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import ButtonDeleteElementForm from "../../../Atomes/Buttons/ButtonDeleteElementForm";

import TaskService from '../../../../services/TaskService';
import getUserService from '../../../../services/getUser';

const FormEditAssignment = ({ setShowPopUp, editTask, role }) => {

    const userId = getUserService.getUserId();
    const [isLoading, setIsLoading] = useState(true);
    const [myEditTask, setMyEditTask] = useState();
    const [isDelete, setIsDelete] = useState(false);
    const dataTypes = [
        {
            "label": "Devis",
            "value": "task-project-com-quote",
        },
        {
            "label": "Prospection",
            "value": "task-project-com-prospection",
        },
        {
            "label": "Rendez-vous physique",
            "value": "task-project-com-meeting",
        },
        {
            "label": "Rendez-vous téléphonique",
            "value": "task-project-com-call",
        }
    ]
    const dataTypesIngs = [
        {
            "label": "Rédaction dossier d'étude structure",
            "value": 'task-project-ing-writing',
        },
        {
            "label": "Dimensionnement",
            "value": 'task-project-ing-measure',
        },
        {
            "label": "Projetage",
            "value": 'task-project-ing-projecting',
        },
        {
            "label": "Échange client",
            "value": 'task-project-ing-meeting',
        },
        {
            "label": "Prise de connaissance projet",
            "value": 'task-project-ing-analyse',
        },
        {
            "label": "Visite de suivi de travaux",
            "value": 'task-project-ing-supervision',
        },
        {
            "label": "Demande complémentaire",
            "value": 'task-project-ing-additional-request',
        },
        {
            "label": "Service Après-Vente (SAV)",
            "value": 'task-project-ing-customer-service',
        },
        {
            "label": "Déplacement",
            "value": 'task-project-ing-travel',
        }
    ]


    const onSubmit = async (values, actions) => {
        setMyEditTask(editTask);
        let startAt = moment(values.startDate + " " + values.startTime).format('YYYY-MM-DD hh:mm:ss a');
        let endAt = moment(values.endDate + " " + values.endTime).format('YYYY-MM-DD hh:mm:ss a');
        let assignment = {
            "userIri": '/api/users/' + userId,
            "title": values.taskName,
            "startAt": startAt.toString(),
            "endAt": endAt.toString(),
            "categoryType": values.taskType,
        }
        const request = await TaskService.editAssignment("assignments", myEditTask.id, assignment)
        setShowPopUp(false)
        actions.resetForm()

        if (request.request.status === 200) {
            toast.success("Tâche modifiée avec succès")
        } else if(request.request.status === 403) {
            toast.info("Il n'est pas possible de modifier une visite")
        } else {
            toast.error("La modification de la tâche a échoué")
        }
    }

    const setUpEditTask = async () => {
        setIsDelete(false);
        setIsLoading(true);
        if (!Array.isArray(editTask)) {
            const request = await TaskService.getAssignment("tasks/" + editTask.event.id);
            if (request.status === 200) {
                editTask = request.data;
                setMyEditTask(editTask);
                initialValuesAssignmentEdit.taskName = editTask.title;
                initialValuesAssignmentEdit.startDate = moment(editTask.startAt).format('YYYY-MM-DD');
                initialValuesAssignmentEdit.startTime = moment(editTask.startAt).format('HH:mm');
                initialValuesAssignmentEdit.endDate = moment(editTask.endAt).format('YYYY-MM-DD');
                initialValuesAssignmentEdit.endTime = moment(editTask.endAt).format('HH:mm');
                initialValuesAssignmentEdit.taskType = editTask.type;

                setIsLoading(false);
            } else {
                toast.error("Une erreur est survenue lors de la récupération des tâches")
            }
        }
    }

    const showDelete = async () => {
        setIsDelete(!isDelete);
    }
    const deleteAssignment = async () => {
        const request = await TaskService.deleteAssignment("tasks", myEditTask.id);
        setShowPopUp(false);
        if (request.request.status === 204) {
            toast.success("Tâche supprimée avec succès")
        } else {
            toast.error("La suppression de la tâche a échoué")
        }
    }


    useEffect(() => {
        setUpEditTask();
    }, [editTask])

    return (
        <>
            {
                !isDelete ? (
                    <Formik
                        initialValues={initialValuesAssignmentEdit}
                        validationSchema={SchemaAssignment}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting }) => (
                            isLoading ? <Skeleton height={200} /> :
                                <Form>
                                    <div className={"form-row-element"}>
                                        <InputLabelErrorMessage type={"text"} label={"Nom de la tâche"} name={"taskName"} />
                                    </div>
                                    <div className={"form-row-element"}>
                                        <InputLabelErrorMessage
                                            type={"select"}
                                            keyLabel={"label"}
                                            keyValue={"value"}
                                            data={role === "INGS" ? dataTypesIngs : dataTypes}
                                            label={"Type de tâche"}
                                            name={"taskType"} />
                                    </div>
                                    <div className={"form-row-element form-row-two-elements"}>
                                        <InputLabelErrorMessage type={"date"} label={"Date de début"} name={"startDate"} />
                                        <InputLabelErrorMessage type={"time"} label={"Heure de début"} name={"startTime"} />
                                    </div>
                                    <div className={"form-row-element form-row-two-elements"}>
                                        <InputLabelErrorMessage type={"date"} label={"Date de fin"} name={"endDate"} />
                                        <InputLabelErrorMessage type={"time"} label={"Heure de fin"} name={"endTime"} />
                                    </div>
                                    <div className={"form-row-element form-row-two-elements form-row-buttons"}>
                                        <ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Editer la tâche"} />
                                        <ButtonDeleteElementForm onClick={showDelete} title={"Supprimer la tâche"} />
                                    </div>
                                </Form>
                        )}
                    </Formik>

                ) : (
                    <div className={"pop-up-delete-container-delete-action"}>
                        <h3 className="color-primary">Voulez-vous vraiment supprimer la tâche ?</h3>
                        <div className='pop-up-delete'>
                            <Button styleButton={"btn-default btn-border btn-border-blue_jeans"} isActive={true} onClick={showDelete} title={"Non"} />
                            <Button styleButton={"btn-default btn-delete"} isActive={true} onClick={deleteAssignment} title={"Oui"} />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default FormEditAssignment;
