import React, {useContext} from 'react';

import {StateRequest as StateRequestComRes} from "../../../views/Pages/Coms/Res/Prestations/PrestationsComRes";
import {StateRequest as StateRequestComDir} from "../../../views/Pages/Coms/Dir/Prestations/PrestationsComsDir";

import PrestationService from "../../../services/PrestationService";
import {useAuth} from "../../../services/useAuth";

const PopUpDeletePrestation = ({id, onClick, reference }) => {

	const {role} = useAuth();

	const { setStateRequest } = useContext(
		role[0] === "ROLE_COM_RES" ? StateRequestComRes : StateRequestComDir
	);

	const handleSubmit = async () => {
		const request = await PrestationService.deleteService(id)
		onClick()
		if (request.request.status == 204){
			setStateRequest('success delete')
		}else{
			setStateRequest('error')
		}
	}

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img alt={"img close"} onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"}/>
				<img src={"/images/medias/popup.svg"} alt="test"/>
				<h4>Voulez-vous supprimer <br/>la prestation {reference} ?</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={onClick}>Non</button>
					<button className={"btn btn-delete"} onClick={handleSubmit}>Oui</button>
				</div>
			</div>
		</div>
	);
}

export default PopUpDeletePrestation;
