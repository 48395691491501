import React, {createContext, useEffect, useState} from "react";
import {SortingTable} from "../../../../components/Molecules/Tables/SortingTable";
import {PartnersMarketPlace} from "../../../../config/ArrayColumns/Admin/PartnersMarketPlace";
import {toast} from "react-toastify";
import TableHeadingAdd from "../../Coms/Dir/Collaborators/Partials/TableHeadingAdd";
import MarketPlaceTab from "./Partials/MarketPlaceTab";
import MarketPlaceService from "../../../../services/MarketPlaceService";
import PopUpForm from "../../../../components/Molecules/PopUp/PopUpForm";
import FormAddPartnerMarketPlace from "../../../../components/Molecules/Form/Admin/FormAddPartnerMarketPlace";
import MarketPlaceCategoriesContainer from "./Partials/MarketPlaceCategoriesContainer";
import FormAddCategoryMarketPlace from "../../../../components/Molecules/Form/Admin/FormAddCategoryMarketPlace";

export const StateRequest = createContext()
const MarketPlace = () => {
	const [activeSection, setActiveSection] = useState("partners");

	// Pop up
	const [addPartner, setAddPartner] = useState(false);
	const [addCategory, setAddCategory] = useState(false);

	//State request
	const [stateRequest, setStateRequest] = useState("");

	//Data
	const [partners, setPartners] = useState([]);
	const [categories, setCategories] = useState([]);

	const DisplayToast = (type) => {
		if (type === "success edit partner") {
			toast.success("Le partenaire a bien été modifiée")
		} else if (type === "success add partner") {
			toast.success("Le partenaire a bien été ajouté")
		} else if (type === "success add category") {
			toast.success("La catégorie a bien été ajoutée")
		} else if (type === "success delete partner") {
			toast.success("Le partenaire a bien été supprimé")
		} else if (type === "success delete category") {
			toast.success("La catégorie a bien été supprimée")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	const getPartners = async () => {
		const request = await MarketPlaceService.getPartners({"pagination": "true"})
		if (request.request.status === 200) {
			setPartners(request.data)
		}
	}

	const getCategories = async () => {
		const request = await MarketPlaceService.getPartnerCategories({"pagination": "true"})
		if (request.request.status === 200) {
			setCategories(request.data)
		}
	}

	useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getCategories()
		getPartners()
	}, [stateRequest]);

	const sectionToReturn = () => {
		if (activeSection === "partners") {
			return (
				<>
					<TableHeadingAdd addTitle={"Ajouter un partenaire"} title={"Marketplace partenaire"}
					                 onClick={() => setAddPartner(true)}/>
					<MarketPlaceTab activeSection={activeSection} setActiveSection={setActiveSection}/>
					<SortingTable columnsTable={PartnersMarketPlace} dataTable={partners}/>

					{
						addPartner ? (
							<PopUpForm title={"Ajouter un partenaire"} large={true} show={addPartner}
							           onClick={() => setAddPartner(false)}
							           form={<FormAddPartnerMarketPlace setIsActive={setAddPartner}/>}/>
						) : null
					}
				</>
			)
		} else if (activeSection === "categories") {
			return (
				<>
					<TableHeadingAdd addTitle={"Ajouter une catégorie"} title={"Marketplace partenaire"}
					                 onClick={() => setAddCategory(true)}/>
					<MarketPlaceTab activeSection={activeSection} setActiveSection={setActiveSection}/>
					<MarketPlaceCategoriesContainer categories={categories}/>

					{
						addCategory ? (
							<PopUpForm title={"Ajouter une catégorie"} large={true} show={addCategory}
							           onClick={() => setAddCategory(false)}
							           form={<FormAddCategoryMarketPlace setIsActive={setAddCategory}/>}/>
						) : null
					}
				</>
			)
		}
	}

	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			{sectionToReturn()}
		</StateRequest.Provider>
	)

}

export default MarketPlace;