import * as Yup from 'yup';

export const AddAssetGeneratorSchema = Yup.object().shape({
	reference: Yup.string().required("Veuillez renseigner une référence"),
	datePayment: Yup.string().required("Veuillez renseigner une date de paiement").matches(
		/^([0-2]\d|3[0-1])\/(0\d|1[0-2])\/\d{4}$/,
		"Le format de date doit être DD/MM/YYYY"
	),
	assetEditor: Yup.string().required("Veuillez renseigner un éditeur"),
	amountRefund: Yup.number().required("Veuillez renseigner un montant").min(1, "LE montant ne peut pas être inférieur à 1"),
	/*date: Yup.string().required("Veuillez renseigner une date").matches(
		/^([0-2]\d|3[0-1])\/(0\d|1[0-2])\/\d{4}$/,
		"Le format de date doit être DD/MM/YYYY"
	),*/
	reasonForTheAsset: Yup.string().required("Veuillez renseigner une raison"),
})