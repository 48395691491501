import React, {useContext, useEffect, useState} from 'react';
import ButtonSubmit from "../../Atomes/Buttons/ButtonSubmit";
import {Form, Formik} from "formik";
import {initialValuesAddUser} from "../../../config/InitialValues/InitialValuesAddUser";
import InputLabelErrorMessage from "../../Atomes/Formik/InputLabelErrorMessage";
import {addUsersSchema} from "../../../schemas/Admin/Users/AddUsersSchema";
import {dataRolesSelect} from "../../../utils/UserRolesUtils";
import userService from "../../../services/UserService";
import {StateRequest} from "../../../views/Pages/Admin/Users/UsersAdmin";

const FormAddUser = ({setShowPopUp}) => {

    const { setStateRequest } = useContext(StateRequest);

    const {addUser} = userService


    const onSubmit = async (values,actions) => {
        const request = await addUser(`/users`, values.user)
        if (request.request.status === 201){
            setStateRequest("success add")
            setShowPopUp(false)
            actions.resetForm();
        } else {
            setStateRequest("error")
        }

  }

  const dataRoles = dataRolesSelect

  return (
      <>
      <Formik
          initialValues={initialValuesAddUser}
          validationSchema={addUsersSchema}
          onSubmit={onSubmit}
      >
          {({ isSubmitting, values }) => (
              <Form>
                  <div className={"form-row-element form-row-two-elements"}>
                      <InputLabelErrorMessage type={"select"} label={"Civilité"} name={"user.gender"} keyValue={"value"} keyLabel={"label"} data={[{value : "M" , label :"Monsieur"},{value : "W" , label :"Madame"},{value : "Miss" , label :"Mademoiselle"},{value : "NA" , label :"Non genré"},{value : "S" , label :"Raison sociale"} ]} />
                      <InputLabelErrorMessage type={"select"} label={"Rôle"} name={"user.roles.additionalProp1"} keyValue={"value"} keyLabel={"label"} data={dataRoles} />
                  </div>
                  {
                    values.user.gender === "S" && (values.user.roles.additionalProp1 === "ROLE_CLIENT" || values.user.roles.additionalProp1 === "ROLE_ARCH") && (
                        <div className={"form-row-element"}>
                            <InputLabelErrorMessage type={"text"} label={"Raison sociale"} name={"user.agencyName"} />
                        </div>
                    )
                  }
                  <div className={"form-row-element form-row-two-elements"}>
                      <InputLabelErrorMessage type={"text"} label={"Nom"} name={"user.lastname"} />
                      <InputLabelErrorMessage type={"text"} label={"Prénom"} name={"user.firstname"} />
                  </div>
                  <div className={"form-row-element form-row-two-elements"}>
                      <InputLabelErrorMessage type={"text"} label={"Téléphone"} name={"user.phone"}/>
                      <InputLabelErrorMessage type={"text"} label={"Adresse e-mail"} name={"user.email"}/>
                  </div>
                  <ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Ajouter"}/>
              </Form>
          )}
      </Formik>
      </>
  )
}

export default FormAddUser;
