import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Formik, Form } from 'formik';
import { InitialValuesProfilePassword } from './Config/InitialValuesProfilePassword';
import { SchemaProfilePassword } from './Config/SchemaProfilePassword';
import api from "../../../../../../services/APIService";
import InputLabelErrorMessage from "../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../../../../components/Atomes/Buttons/ButtonSubmit";

const ProfilePasswordForm = () => {

  const [userRole, setUserRole] = useState(null);
  const onSubmit = async (values, actions) => {
    const userId = localStorage.getItem("userId");
    if (userId != null) {
      let request = await api.get(`users/${userId}`);
      setUserRole(request.roles[0]);
    }
    
    let data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      roles: [userRole]
    };
    
    let requestPasswordPatch = null;
    if (userRole === "ROLE_COM_DIR" || userRole === "ROLE_COM" || userRole === "ROLE_COM_RES") {
      await api.patch(`coms/${userId}`, data)
    } else if (userRole === "ROLE_ADMIN") {
      await api.patch(`admins/${userId}`, data)
    }
    
    if (requestPasswordPatch.status === 200) {
      toast.success("Votre mot de passe a bien été modifié");
    } else {
      toast.error("Une erreur est survenue lors de la modification de votre mot de passe");
    }
    
    actions.resetForm();
  };

  return (
    <div className='mbe-profile-container mbe-section'>
      <h3 className='color-primary'>Mot de passe</h3>
      <Formik
        initialValues={InitialValuesProfilePassword}
        validationSchema={SchemaProfilePassword}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>

            <div className={"form-row-element"}>
              <InputLabelErrorMessage
                label="Mot de passe actuel"
                name="oldPassword"
                type="password"
              />
            </div>

            <div className={"form-row-element"}>
              <InputLabelErrorMessage
                label="Nouveau mot de passe"
                name="newPassword"
                type="password"
              />
            </div>

            <div className={"form-row-element"}>
              <InputLabelErrorMessage
                label="Confirmation du mot de passe"
                name="newPasswordConfirm"
                type="password"
              />
            </div>

            <ButtonSubmit
              styleButton={"btn-default btn-default-secondary plain"}
              isActive={isSubmitting} title={"Sauvegarder"}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ProfilePasswordForm;