import React from "react";
import {useAuth} from "../../../../../../services/useAuth";

const PopUpSendStudyFolder = ({onClick, approve, projectData}) => {

	const { userId } = useAuth();

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"}/>
				{
					userId === projectData.ingChecker?.id ? (
						<h4 className={"text-primary color-black"}>Le dossier d'étude structure va être envoyé au client pour validation.<br/>Êtes-vous sûr de vouloir l'envoyer ?</h4>
					) : (
						<h4 className={"text-primary color-black"}>Le dossier d'étude structure va être envoyé à l'ingénieur vérificateur pour validation. <br/> Êtes-vous sûr de vouloir l'envoyer ?</h4>
					)
				}

				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={() => onClick()}>Non, retourner aux modifications</button>
					{
						userId === projectData.ingChecker?.id ? (
							<button className={"btn btn-send"} onClick={() => approve()}>Oui, envoyer au client</button>
						) : (
							<button className={"btn btn-send"} onClick={() => approve()}>Oui, envoyer pour vérification</button>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default PopUpSendStudyFolder
