import React from "react";

export const BUSINESS_OBJECTIVES_COLUMNS = [
	{
		Header: "Numéro dossier",
		accessor: "project.reference",
	},
	{
		Header: "Nom client",
		accessor: "project.client.lastname"
	},
	{
		Header: "Montant déboursé (HT)",
		accessor: "project.spent",
		Cell: cellInfo => {
			return (
				<>{cellInfo.row.original.project.spent} €</>
			)
		}
	},
	{
		Header: "Montant devis signé (HT)",
		accessor: "total",
		Cell: cellInfo => {
			return (
				<>{cellInfo.row.original.total.toFixed(2)} €</>
			)
		}
	},
	{
		Header: "Marge commerciale (HT)",
		accessor: "",
		Cell: cellInfo => {
			const total = (cellInfo.row.original.total - cellInfo.row.original.project.spent).toFixed(2)
			return (
				<>{total} €</>
			)
		}
	}, 
	// {
	// 	Header: "Bonus sur marge",
	// 	accessor: "",
	// 	Cell: cellInfo => {
	// 		const bonus = ((cellInfo.row.original.totalQuotes - cellInfo.row.original.spent).toFixed(2)) * (cellInfo.row.original.com.bonus)
	// 		return (
	// 			<>{bonus.toFixed(2)} €</>
	// 		)
	// 	}
	// }
]