import React, {useState, useRef, useEffect} from "react";
import PopUpRangeDates from '../../Molecules/PopUp/PopUpRangeDates';

const ButtonPopupDatepicker = ({title, date, onChange, resetDateSelected }) => {

	const ref = useRef(null);
	const [showPopUp, setShowPopUp] = useState(false);

	const tooglePopUpRangeDates = () => {
		setShowPopUp(!showPopUp)
	}
	
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowPopUp(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	return (
		<div ref={ref} className="button-open-popup-with-dates-range">
			<button onClick={tooglePopUpRangeDates} className={"btn-default btn-default-primary btn-default-primary color-primary"}>
				Sélectionner une période
			</button>
			<PopUpRangeDates isActive={showPopUp} 
				date={date} onChange={onChange} 
				validDateSelected={tooglePopUpRangeDates}
				resetDateSelected={resetDateSelected}
			/>
		</div>
	)
}
export default ButtonPopupDatepicker;