import React from 'react';
import {toast} from 'react-toastify';

import {Form, Formik} from 'formik';
import {InitialValuesCustomerPassword} from './Config/InitialValuesCustomerPassword';
import {SchemaCustomerPassword} from './Config/SchemaCustomerPassword';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import ButtonSubmit from '../../../../../../components/Atomes/Buttons/ButtonSubmit';

import api from "../../../../../../services/APIService";

const ProfileCustomerPasswordForm = () => {

	const onSubmit = async (values, actions) => {
		const userId = localStorage.getItem("userId");
		let data = {
			oldPassword: values.oldPassword,
			newPassword: values.newPassword,
			roles: ["ROLE_CLIENT"]
		};

		let request = await api.patch(`clients/${userId}`, data)
		if (request.status === 200) {
			toast.success("Votre mot de passe a été mis à jour avec succès");
		} else {
			toast.error("Une erreur est survenue, veuillez réessayer plus tard");
		}

		actions.resetForm();
	};

	return (
		<div className='mbe-profile-container mbe-section'>
			<h3 className='color-primary'>Mot de passe</h3>
			<Formik
				initialValues={InitialValuesCustomerPassword}
				validationSchema={SchemaCustomerPassword}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => (
					<Form>

						<div className={"form-row-element"}>
							<InputLabelErrorMessage
								label="Mot de passe actuel"
								name="oldPassword"
								type="password"
							/>
						</div>

						<div className={"form-row-element"}>
							<InputLabelErrorMessage
								label="Nouveau mot de passe"
								name="newPassword"
								type="password"
							/>
						</div>

						<div className={"form-row-element"}>
							<InputLabelErrorMessage
								label="Confirmation du mot de passe"
								name="newPasswordConfirm"
								type="password"
							/>
						</div>

						<ButtonSubmit
							styleButton={"btn-default btn-default-secondary plain"}
							isActive={isSubmitting} title={"Enregistrer"}
						/>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export default ProfileCustomerPasswordForm;
