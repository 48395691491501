import React from "react";
import LoginForm from "./Partials/LoginForm";

const Login = () => {
	return (
		<div className={"section-login"}>
			<img className={"section-login-logo"} src={"/images/pictos/logo_mon_bureau_detudes_baseline.png"} alt={"Mon bureau d'études logo"} />
			<div className={"section-login-form"}>
				<LoginForm/>
			</div>
		</div>
	)
}
export default Login;
