import * as Yup from "yup";

export const SchemaCustomerBank = Yup.object().shape({
  name: Yup.string().required("Veuillez renseigner le titulaire du compte"),
  iban: Yup.string().required("Veuillez renseigner l'IBAN du compte")
    .length(27, "La longueur doit être de 27 caractères")
    .matches(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{23}$/, "Le format de l'IBAN est incorrect"),
  bic: Yup.string().required("Veuillez renseigner le BIC du compte")
    .min(8, "La longueur doit être de 8 ou 11 caractères")
    .max(11, "La longueur doit être de 8 ou 11 caractères")
    .matches(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, "Le format du BIC est incorrect"),
});