import React from "react";
import "./style/main.css";
import Routes from "./router/Router";

const App = () => {
	return (
		<Routes/>   
	);
}
export default App;
