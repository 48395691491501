import React, {useEffect, useRef, useState} from 'react';
import {toast} from "react-toastify";
import DirRespService from "../../../services/Ing/DirResp/DirRespService";
import {useAuth} from "../../../services/useAuth";

const ChatAllMessage = ({messagesClient, messagesInterne, idChat}) => {
	const [clientMessages, setClientMessages] = useState([]);
	const [interneMessages, setInterneMessages] = useState([]);
	const [isCurrentlyFormatted, setIsCurrentlyFormatted] = useState(false);
	const [sendMessage, setSendMessage] = useState("");
	const [activeDiscussion, setActiveDiscussion] = useState(0)
	const id = localStorage.getItem("userId")
	const chatContainerRef = useRef(null);

	const {role} = useAuth();

	const setMessages = () => {
		if (messagesClient.messages?.length !== 0) {
			const sortedClientMessages = [...messagesClient.messages];
			sortedClientMessages.forEach(item => {
				item.date = item.sentAt;
			});
			sortedClientMessages.sort((a, b) => a.sentAt - b.sentAt);
			setClientMessages(sortedClientMessages)
		}

		if (messagesInterne.messages?.length !== 0 && role[0] !== "ROLE_CLIENT") {
			const sortedInterneMessages = [...messagesInterne.messages];
			sortedInterneMessages.forEach(item => {
				item.date = item.sentAt;
			});
			sortedInterneMessages.sort((a, b) => a.sentAt - b.sentAt);
			setInterneMessages(sortedInterneMessages)
		}
		setIsCurrentlyFormatted(true)
	}

	const submitMessage = async () => {
		if (activeDiscussion === 0) {
			const request = await DirRespService.sendMessageToClient(messagesClient.projectId, {content: sendMessage})
			if (request.status === 201) {
				setSendMessage("")
			} else {
				toast.error("Une erreur est survenue lors de l'envoi du message")
			}
		} else if (activeDiscussion === 1 && role[0] !== "ROLE_CLIENT") {
			const request = await DirRespService.sendMessageToInterne(messagesInterne.projectId, {content: sendMessage})
			if (request.status === 201) {
				setSendMessage("")
			} else {
				toast.error("Une erreur est survenue lors de l'envoi du message")
			}
		} else {
			toast.error("Une erreur est survenue lors de l'envoi du message")
		}
	}

	let previousDate = null;

	useEffect(() => {
		if (clientMessages.length !== messagesClient.messages.length || interneMessages.length !== messagesInterne.messages.length) {
			setMessages()
		}
		const chatContainer = document.querySelector(".chat-all-message-container-message");
		chatContainer.scrollTop = chatContainer.scrollHeight;
	}, [activeDiscussion, messagesInterne, messagesClient]);

	useEffect(() => {
		if (sendMessage.length !== 0) {
			submitMessage()
		}
	}, [sendMessage]);

	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	}, [clientMessages, interneMessages]);

	return (
		<div className={`chat-all-message`}>
			{
				role[0] !== "ROLE_CLIENT" ? (
					<div className={"chat-all-message-select-conversation"}>
						<p className={`text-primary color-dark-grey ${activeDiscussion === 0 ? "active" : null}`}
						   onClick={() => setActiveDiscussion(0)}>Discussion avec client</p>
						<p className={`text-primary color-dark-grey ${activeDiscussion === 1 ? "active" : null}`}
						   onClick={() => setActiveDiscussion(1)}>Discussion Interne</p>
					</div>
				) : null
			}


			<div className={"chat-all-message-container"}>
				<div className={`chat-all-message-container-message ${role[0] === "ROLE_CLIENT" ? "client-connected" : null}`} ref={chatContainerRef}>
					{activeDiscussion === 0 ? (
						clientMessages.map((message, index) => {
								const isCurrentUser = message.senderId === parseInt(id);
								let temps = 0
								temps = new Date(message.sentAt * 1000);
								const dayFormat = temps.getDate();
								const monthFormat = new Intl.DateTimeFormat("fr-FR", {month: "short"}).format(temps);
								const yearFormat = temps.getFullYear();
								let hour = temps.getHours();
								if (hour < 10) {
									hour = `0${hour}`;
								}
								let minute = temps.getMinutes();
								if (minute < 10) {
									minute = `0${minute}`;
								}
								let formattedDate = `${dayFormat} ${monthFormat} ${yearFormat} ${hour}:${minute}`;

								const formatedDay = temps.getDay();
								const formatedMonth = temps.getMonth();
								const formatedYear = temps.getFullYear();
								let formattedTime = `${formatedDay} ${formatedMonth} ${formatedYear}`;

								// Vérifiez si la date du message est différente de la date précédente.
								const shouldDisplayDate = formattedTime !== previousDate;
								const timestampDate = new Date(temps);

								const day = timestampDate.getDate();
								const month = new Intl.DateTimeFormat("fr-FR", {month: "short"}).format(timestampDate);
								const year = timestampDate.getFullYear();
								previousDate = formattedTime;
								formattedTime = `${day} ${month} ${year}`;

								const timestampDateActualDay = new Date();

								const actualDay = timestampDateActualDay.getDate();
								const actualMonth = new Intl.DateTimeFormat("fr-FR", {month: "short"}).format(timestampDateActualDay);
								const actualYear = timestampDateActualDay.getFullYear();
								const formattedActualDate = `${actualDay} ${actualMonth} ${actualYear}`;

								return (
									<>
										{/* Affichez la date si nécessaire */}
										{shouldDisplayDate && (
											<>
												<p className="message-date text-primary color-blue-jeans">
													{formattedTime === formattedActualDate ? "Aujourd'hui" : formattedTime}
												</p>
											</>
										)}
										<div
											key={index}
											className={`message ${isCurrentUser ? "message-right" : "message-left"}`}
										>

											<p className={"message-content text-primary color-black"}>
												{!isCurrentUser && (
													<p className={"message-name text-primary color-blue-jeans"}>
														{message.sender}
													</p>
												)}
												{message.content}
											</p>
											<p className={"message-time text-primary"}>
												{formattedDate}
											</p>
										</div>
									</>
								);
							}
						)
					) : (
						interneMessages.map((message, index) => {
								const isCurrentUser = message.senderId === parseInt(id);
								let temps = 0
								temps = new Date(message.sentAt * 1000);
								const dayFormat = temps.getDate();
								const monthFormat = new Intl.DateTimeFormat("fr-FR", {month: "short"}).format(temps);
								const yearFormat = temps.getFullYear();
								let hour = temps.getHours();
								if (hour < 10) {
									hour = `0${hour}`;
								}
								let minute = temps.getMinutes();
								if (minute < 10) {
									minute = `0${minute}`;
								}
								let formattedDate = `${dayFormat} ${monthFormat} ${yearFormat} ${hour}:${minute}`;

								const formatedDay = temps.getDay();
								const formatedMonth = temps.getMonth();
								const formatedYear = temps.getFullYear();
								let formattedTime = `${formatedDay} ${formatedMonth} ${formatedYear}`;


								// Vérifiez si la date du message est différente de la date précédente.
								const shouldDisplayDate = formattedTime !== previousDate;
								const timestampDate = new Date(temps);

								const day = timestampDate.getDate();
								const month = new Intl.DateTimeFormat("fr-FR", {month: "short"}).format(timestampDate);
								const year = timestampDate.getFullYear();
								previousDate = formattedTime;
								formattedTime = `${day} ${month} ${year}`;

								const timestampDateActualDay = new Date();

								const actualDay = timestampDateActualDay.getDate();
								const actualMonth = new Intl.DateTimeFormat("fr-FR", {month: "short"}).format(timestampDateActualDay);
								const actualYear = timestampDateActualDay.getFullYear();
								const formattedActualDate = `${actualDay} ${actualMonth} ${actualYear}`;


								return (
									<>
										{/* Affichez la date si nécessaire */}
										{shouldDisplayDate && (
											<>
												<p className="message-date text-primary color-blue-jeans">
													{formattedTime === formattedActualDate ? "Aujourd'hui" : formattedTime}
												</p>
											</>
										)}
										<div
											key={index}
											className={`message ${isCurrentUser ? "message-right" : "message-left"}`}
										>

											<p className={"message-content text-primary color-black"}>
												{!isCurrentUser && (
													<p className={"message-name text-primary color-blue-jeans"}>
														{message.sender}
													</p>
												)}
												{message.content}
											</p>
											<p className={"message-time text-primary"}>
												{formattedDate}
											</p>
										</div>
									</>
								);
							}
						)
					)}
				</div>

				<div className={"chat-all-message-container-input"}>
					<input
						type={"text"}
						placeholder={"Tapez un message"}
						onKeyDown={(event) => {
							if (event.key === "Enter" && event.target.value.length !== 0) {
								setSendMessage(event.target.value);
								// Effacez l'input après avoir appuyé sur Entrée
								event.target.value = "";
							}
						}}
					/>
				</div>
				{activeDiscussion === 0 ? (
					<>
						{
							role[0] !== "ROLE_CLIENT" ? (
								<p className={"text-primary color-red information"}>Vous êtes en train de discuter avec un client !</p>
							) : null
						}
					</>
				) : (
					<p className={"text-primary color-red information"}>Vous êtes en train de discuter en interne !</p>
				)}
			</div>
		</div>
	);

};

export default ChatAllMessage;
