import * as Yup from "yup";

export const SchemaArchitectAccount = Yup.object().shape({    
  gender: Yup.string().required("Veuillez renseigner une civilité"),
  lastname: Yup.string().required("Veuillez renseigner votre nom"),
  firstname: Yup.string().required("Veuillez renseigner votre prénom"),
  email: Yup.string().email("Veuillez renseigner une adresse e-mail valide").required("Veuillez renseigner votre adresse e-mail"),
  phone: Yup.string()
  .min(10, "La longueur doit être de minimum 10 caractères")
  .max(14, "La longueur doit être de maximum 14 caractères")
  .required('Veuillez renseigner un numéro de téléphone'),
  address: Yup.string().required("Veuillez renseigner l'adresse du projet"),
  city: Yup.string().required("Veuillez renseigner la ville du projet"),
  postalCode: Yup.string().required("Veuillez renseigner le code postal du projet")
    .min(4, "La longueur doit être de minimum 4 caractères")
    .max(5, "La longueur doit être de maximum 5 caractères"),
  agencyName: Yup.string().when("gender", {
    is: "S",
    then: Yup.string().required("Veuillez renseigner la raison sociale")
  })
})