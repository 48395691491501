import React, {createContext, useEffect, useState} from 'react';
import {toast} from "react-toastify";

import TableListingHeading from "./Partials/CollaboratorsHeadingListingTable";
import TableListing from "./Partials/CollaboratorsListingTable";
import CollaboratorDetailView from "./Partials/CollaboratorDetailView";

import PopUpForm from "../../../../../components/Molecules/PopUp/PopUpForm";
import Select from "../../../../../components/Atomes/Select/Select";
import FormAddCollaborator from "./Forms/FormAddCollaborator";
import CollaboratorService from "../../../../../services/CollaboratorService";
import {ConvertObjectToUrlParams} from "../../../../../utils/StringUtils";
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";


export const StateRequest = createContext()

const Collaborators = () => {
	const [showPopUp, setShowPopUp] = useState(false);
	const [collaborators, setCollaborators] = useState([]);
	const [filteredCollaborators, setFilteredCollaborators] = useState("");

	const [stateRequest, setStateRequest] = useState("");

	const DisplayCreateModalCollaborator = () => {
		setShowPopUp(!showPopUp)
	}

	const changeRegionFilter = (event) => {

		if (event.target.value === "Toutes les agences") {
			setFilteredCollaborators("")
		} else {
			setFilteredCollaborators(event.target.value)
		}
	}


	const getCollaborators = async () => {
		const urlCom = ConvertObjectToUrlParams("coms", {regions: filteredCollaborators, roles: "ROLE_COM", pagination: "true"})
		const request = await CollaboratorService.getCollaborators(urlCom)
		if (request.request.status === 200) {
			let coms = request.data['hydra:member']
			let availableComs = []
			coms.forEach(com => {
				if (com.roles[0] !== "ROLE_COM_DIR") {
					if(!com.objective) com.objective = 0
					availableComs.push(com)
				}
			})
			setCollaborators(availableComs)
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("Le collaborateur a bien été ajouté")
		} else if (type === "success edit") {
			toast.success("Le collaborateur a bien été modifié")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	useEffect(() => {
		if (stateRequest !== "") {
			setCollaborators([])
			getCollaborators()
			DisplayToast(stateRequest)
		}
		getCollaborators()
	}, [stateRequest, filteredCollaborators]);


	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<Select onChange={changeRegionFilter} defaultValue={"Toutes les agences"} data={dataRegionsArray}
			        value={dataRegionsArray} styleSelect={"select-custom-secondary"}/>
			<TableListingHeading onClick={DisplayCreateModalCollaborator} title={"Collaborateurs"}
			                     titleButton={"Ajouter un collaborateur"} region={filteredCollaborators}/>
			<TableListing collaborators={collaborators} region={filteredCollaborators}/>
			<CollaboratorDetailView collaborators={collaborators}/>
			<PopUpForm large={true} title={"Ajouter un collaborateur"}
			           form={<FormAddCollaborator setShowPopUp={setShowPopUp}/>} onClick={DisplayCreateModalCollaborator}
			           show={showPopUp}/>
		</StateRequest.Provider>
	);

};

export default Collaborators;
