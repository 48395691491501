import React from "react";
import MbeCalendarSidebarNotifs from './MbeCalendarSidebarNotifs';

import Calendar from 'react-calendar';

const MbeCalendarSidebar = ({calendarRef, startDate, setStartDate, 
    withNotifications, notifications, notificationValidator
}) => {
    return (
        <div className='mbeCalendar-container-sidebar'>
            <div className='mbeCalendar-container-sidebar-datepicker'>
                <Calendar 
                    onChange={(date) => {
                        setStartDate(date);
                        calendarRef.current.getApi().gotoDate(new Date(date));
                    }}
                    value={startDate}
                    selectRange={false}
                    locale={"fr-FR"}
                    defaultView={"month"}
                />
            </div>

            { withNotifications && notifications.length > 0 && <MbeCalendarSidebarNotifs notifications={notifications} notificationValidator={notificationValidator} /> }
        </div>
    )
}

export default MbeCalendarSidebar;