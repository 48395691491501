import React, {useEffect, useState} from 'react';

import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {blueJeans, green, lightBlueJeans, primary, purple, yellow} from "../../../config/Color";

ChartJS.register(ArcElement, Tooltip, Legend);


export function ChartPie({title, content, className}) {

	const [stateContent, setStateContent] = useState([{type: "", total: 0}]);
	const [legendNameHover, setLegendNameHover] = useState("")
	const [legendColorHover, setLegendColorHover] = useState(primary());
	const [legendIndexHover, setLegendIndexHover] = useState("");


	useEffect(() => {
		setStateContent(content.length > 0 ? content : [{type: "", total: 0}])
		setLegendNameHover(stateContent[0].type)
	}, [content]);


	const data = {
		labels: stateContent.map((item) => item.type ? item.type : item.niceStage), datasets: [{
			data: stateContent.map((item) => item.total),
			backgroundColor: [
				primary(), blueJeans(), purple(), green(), yellow(),
				lightBlueJeans(), "#FF9B5F", "#F9564F", "#90CAF9",
				"#FFCC80", "#FFAB91", "#A5D6A7", "#B39DDB", "#FFD54F",
			],
			borderWidth: 0,
			hoverOffset: 0
		}]
	}

	const tooltip = {
		callbacks: {
			title: function (chart) {
				setLegendNameHover(chart[0].label)
				setLegendIndexHover(chart[0].dataIndex)
				setLegendColorHover(chart[0].dataset.backgroundColor[legendIndexHover])
			}
		}
	}
	const options = {
		plugins: {
			tooltip,
			legend: {
				display: false,
			}, title: {
				display: false,
			},
		},
	}

	const roundLegendStyle = {
		backgroundColor: legendColorHover
	}

	return (<div className={`element-kpi element-kpi-l`}>
		<div className={"element-kpi-title"}>
			<h4 className={"color-primary"}>{title}</h4>
		</div>
		<div className={"element-kpi-legend-pie"}>
			<div style={roundLegendStyle} className={"element-kpi-legend-pie-round"}></div>
			<p className={"text-primary color-white"}>{legendNameHover}</p>
		</div>
		<div className={"chart-pie"}>
			<Pie data={data} options={options}/>
		</div>
	</div>)
}
