import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import KpisViewFilters from "./partials/Filters";
import KpisViewTitle from "./partials/Title";
import KpisViewCharts from "./partials/Charts";
import KpiService from "../../../../../services/KpiService";
import { toast } from "react-toastify";

const KpisView = () => {

	// Dates
	let currentDate = moment().format('D-M-YYYY');
	let startDateCurrentMonth = moment().startOf('month').format('D-M-YYYY')
	let dateMonthLater = moment().subtract(1, 'months').format('D-M-YYYY')
	let dateYearLater = moment().subtract(1, 'years').format('D-M-YYYY')

	const [date, setDate] = useState(new Date());
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");

	// Kpis
	const month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
	const [revenueByMonth, setRevenueByMonth] = useState([{ month: "", year: "", total: 0 }]);
	const [transformRateToday, setTransformRateToday] = useState(1);
	const [transformRateLastMonth, setTransformRateLastMonth] = useState(1);
	const [transformRateTotal, setTransformRateTotal] = useState(1);
	const [revenueToday, setRevenueToday] = useState(1)
	const [revenueCurrentMonth, setRevenueCurrentMonth] = useState(1)
	const [monthlyObjective, setMonthlyObjective] = useState(1);

	const getTransformRate = async () => {
		let request = await KpiService.getTransformRate({ dateStart: dateStart, dateEnd: dateEnd })
		if (request.request.status === 200) {
			setTransformRateTotal(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du taux de transformation")
		}
	}

	const getTransformRateToday = async () => {
		let request = await KpiService.getTransformRate(`?dateStart=${dateStart}&dateEnd=${currentDate}`)
		if (request.request.status === 200) {
			setTransformRateToday(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du taux de transformation du jour")
		}
	}

	const getTransformRateLastMonth = async () => {
		let request = await KpiService.getTransformRate(`?dateStart=${dateMonthLater}&dateEnd=${currentDate}`)
		if (request.request.status === 200) {
			setTransformRateLastMonth(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du taux de transformation du dernier mois")
		}
	}

	const getRevenue = async () => {
		let request = await KpiService.getRevenue(`?dateStart=${dateStart}&dateEnd=${currentDate}`)
		if (request.request.status === 200) {
			setRevenueToday(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du revenue du jour")
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}

	const changeDateMonthLater = (date) => {
		if (date === "Toutes durées") {
			setDateStart("")
			setDateEnd("")
			setDate(new Date())
		} else {
			let dateEnd = moment().format("YYYY-MM-DD")
			let dateBegin = moment().subtract(date, 'months').format("YYYY-MM-DD")
			setDate([new Date(dateBegin), new Date(dateEnd)])
			setDateStart(dateBegin)
			setDateEnd(dateEnd)
		}
	}

	const getRevenueCurrentMonth = async () => {
		let request = await KpiService.getRevenue(`?dateStart=${startDateCurrentMonth}&dateEnd=${currentDate}`)
		if (request.request.status === 200) {
			setRevenueCurrentMonth(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du revenue du mois actuel")
		}
	}

	const getRevenueByMonth = async () => {
		let request = await KpiService.getRevenueByMonth({ dateStart: dateStart !== "" ? dateStart : dateYearLater, dateEnd: dateEnd })
		if (request.request.status === 200) {
			setMonthlyObjective(request.data.monthlyObjectives.toFixed(2))
			let data = request.data.revenues
			data.sort((a, b) => parseInt(a.month) - parseInt(b.month))
			data.sort((a, b) => parseInt(a.year) - parseInt(b.year))
			setRevenueByMonth([])
			data.map((item) => {
				setRevenueByMonth(revenueByMonth => [...revenueByMonth, {
					date: month[item.month - 1],
					data: parseInt(item.total)
				}])
			})
		} else {
			toast.error("Une erreur est survenue lors de la récupération des revenues par mois")
		}
	}

	const [signedQuotesCount, setSignedQuotesCount] = useState('');
	const getSignedQuotesCount = async () => {
		let request = await KpiService.getSignedQuotesCount({ dateStart: dateStart, dateEnd: dateEnd })
		if (request.request.status === 200) {
			setSignedQuotesCount(request.data.total)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de devis signés")
		}
	}

	const [processingQuotesCount, setProcessingQuotesCount] = useState('')
	const getProcessingQuotesCount = async () => {
		let request = await KpiService.getProcessingQuotesCount({ dateStart: dateStart, dateEnd: dateEnd })
		if (request.request.status === 200) {
			setProcessingQuotesCount(request.data.total)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de devis en cours")
		}
	}

	const [processingQuotes, setProcessingQuotes] = useState('')
	const getProcessingQuotes = async () => {
		let request = await KpiService.getProcessingQuotes({ dateStart: dateStart, dateEnd: dateEnd })
		if (request.request.status === 200) {
			setProcessingQuotes(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des devis en cours")
		}
	}

	const [requestsCount, setRequestsCount] = useState("")
	const getRequestsCount = async () => {
		let request = await KpiService.getRequestsCount({ dateStart: dateStart, dateEnd: dateEnd })
		if (request.request.status === 200) {
			let total = 0
			request.data.map((request) => {
				total += request.total
			})
			setRequestsCount(total)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de demandes")
		}
	}

	const [averageBasketByMonth, setAverageBasketByMonth] = useState([])
	const getAverageBasketByMonth = async () => {
		let request = await KpiService.getAverageBasketByMonth({ dateStart: dateStart, dateEnd: dateEnd })
		if (request.request.status === 200) {
			let data = request.data.monthlyBasket
			data.sort((a, b) => parseInt(a.month) - parseInt(b.month))
			data.sort((a, b) => parseInt(a.year) - parseInt(b.year))
			setAverageBasketByMonth([])
			data.map((item) => {
				setAverageBasketByMonth(averageBasketByMonth => [...averageBasketByMonth, {
					date: month[item.month - 1],
					data: parseInt(item.total)
				}])
			})
		} else {
			toast.error("Une erreur est survenue lors de la récupération du panier moyen par mois")
		}
	}

	useEffect(() => {
		getTransformRate()
		getTransformRateToday()
		getTransformRateLastMonth()
		getRevenue()
		getRevenueCurrentMonth()
		getRevenueByMonth()
		getSignedQuotesCount()
		getProcessingQuotesCount()
		getRequestsCount()
		getProcessingQuotes()
		getAverageBasketByMonth()
	}, [date])

	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	return (
		<>
			<KpisViewFilters
				date={date}
				onChangeDate={(event) => changeDate(event)}
				onChangeDuree={(event) => changeDateMonthLater(event.target.value)}
				resetDateSelected={resetDate}
			/>
			<div className={"mbe-section"}>
				<KpisViewTitle />
				<KpisViewCharts
					dateStart={dateStart}
					monthlyObjective={monthlyObjective}
					transformRateTotal={transformRateTotal}
					transformRateLastMonth={transformRateLastMonth}
					transformRateToday={transformRateToday}
					revenueByMonth={revenueByMonth}
					revenueToday={revenueToday}
					revenueCurrentMonth={revenueCurrentMonth}
					signedQuotesCount={signedQuotesCount}
					processingQuotesCount={processingQuotesCount}
					requestsCount={requestsCount}
					processingQuotes={processingQuotes}
					averageBasketByMonth={averageBasketByMonth}
				/>
			</div>
		</>
	)

}
export default KpisView;
