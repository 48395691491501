import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, useField} from "formik";

const InputLabelTwoSelectErrorMessage = ({
	                                         nameRole,
	                                         nameHour,
	                                         nameSupport,
	                                         nameDateStart,
	                                         nameDateEnd,
	                                         type,
	                                         label,
	                                         labelSupport,
	                                         placeholder,
	                                         keyValue,
	                                         keyLabel,
	                                         keyValueHours,
	                                         keyLabelHours,
	                                         keyValueSupport,
	                                         keyLabelSupport,
	                                         data,
	                                         dataSupport,
	                                         readonly,
	                                         canBeNull,
	                                         cross,
	                                         closeField,
	                                         showIngSupport,
																						withVisit,
	                                         ...props
                                         }) => {

	const [showIng, setShowIng] = useState(true)
	const [canShowIngSupport, setCanShowIngSupport] = useState(showIngSupport)

	const [field, meta, helpers] = useField(nameSupport)

	const [hours, setHours] = useState([])
	const selectHours = () => {
		let arrayTemp = []
		for (let i = 0; i <= 100; i++) {
			if (i === 0) {
				arrayTemp.push({value: i, label: `${i} minute`})
				arrayTemp.push({value: 0.5, label: `30 minutes`})
			} else if(i === 1)  {
				arrayTemp.push({value: i, label: `${i} heure`})
			} else {
				arrayTemp.push({value: i, label: `${i} heures`})
			}
		}
		setHours(arrayTemp)
	}

	useEffect((actions) => {
		selectHours()
	}, [])

	const closeSupportField = () => {
		setCanShowIngSupport(false)
		helpers.setValue("")
	}

	if (showIng) {
		if (type === "select") {
			return (
				<div className={"form-row-label-input"}>
					<div className="form-row-label-input-two-select">
						<label htmlFor={nameRole} className={"text-micro paragraph_bold color-dark-grey"}>{label}</label>
						<Field multiple={false} disabled={withVisit === false} className={"select-custom-mini-primary"} as={type} name={nameHour}
						       placeholder={label}>
							<option hidden>{placeholder === undefined ? "0 heure" : placeholder}</option>
							{hours.map((item, index) => {
								if (readonly === "readonly") {
									return (
										<option disabled key={index} value={item.value}>{item.label}</option>
									)
								} else {
									return (
										<option key={index} value={item.value}>{item.label}</option>
									)
								}
							})}
						</Field>
					</div>
					{
						cross === true ?
							<div className="form-label-input-close">

								<Field
									multiple={false}
									className={"select-custom-primary"}
									disabled={withVisit === false}
									as={type}
									name={nameRole}
									placeholder={label}
								>
									<option hidden>{placeholder === undefined ? "Aucun ingénieur attribué" : placeholder}</option>
									{data.map((item, index) => {
										if (readonly === "readonly" || withVisit === false) {
											return (
												<option disabled key={index} value={item[keyValue]}>{item[keyLabel]}</option>
											)
										} else {

											return (
												<option key={index} value={item[keyValue]}>{item[keyLabel]}</option>
											)
										}
									})}
									{canBeNull ? <option value={null}>N/A</option> : ""}
								</Field>
								<div className="button-close-field" onClick={closeField}>
									<img src="/images/pictos/cross-background-red.svg" alt=""/>
								</div>

							</div>
							:
							<>
								<Field
									multiple={false}
									className={"select-custom-primary"}
									as={type}
									disabled={withVisit === false}
									name={nameRole}
									placeholder={label}
								>
									<option hidden>{placeholder === undefined ? "Aucun ingénieur attribué" : placeholder}</option>
									{data.map((item, index) => {
										if (readonly === "readonly") {
											return (
												<option disabled key={index} value={item[keyValue]}>{item[keyLabel]}</option>
											)
										} else {
											return (
												<option key={index} value={item[keyValue]}>{item[keyLabel]}</option>
											)
										}
									})}
									{canBeNull ? <option value={null}>N/A</option> : ""}
								</Field>
								<label htmlFor={nameDateStart} className={"text-micro paragraph_bold color-dark-grey margintop-10"}>Date
									de début de
									la visite</label>
								<Field type={"datetime-local"} name={nameDateStart} disabled={withVisit !== "off-platform"}/>
								<ErrorMessage name={nameDateStart} component={"p"} className={"color-error text-primary"}/>
								<label htmlFor={nameDateEnd} className={"text-micro paragraph_bold color-dark-grey margintop-10"}>Date
									de fin de la
									visite</label>
								<Field type={"datetime-local"} name={nameDateEnd} disabled={withVisit !== "off-platform"}/>
								<ErrorMessage name={nameDateEnd} component={"p"} className={"color-error text-primary"}/>

							</>
					}

					<ErrorMessage name={nameRole} component={"p"} className={"color-error text-primary"}/>
					<ErrorMessage name={nameHour} component={"p"} className={"color-error text-primary"}/>

					{
						canShowIngSupport ? (
							<>
								<label htmlFor={nameSupport}
								       className={"text-micro paragraph_bold color-dark-grey margintop-10"}>{labelSupport}</label>
								<div className="form-label-input-close select-supplementaire">
									<Field
										multiple={false}
										className={"select-custom-primary"}
										as={type}
										disabled={withVisit === false}
										name={nameSupport}
										placeholder={labelSupport}
									>
										<option
											hidden>{placeholder === undefined ? "Aucun ingénieur support attribué" : placeholder}</option>
										{dataSupport.map((item, index) => {
											if (readonly === "readonly") {
												return (
													<option disabled key={index} value={item[keyValue]}>{item[keyLabel]}</option>
												)
											} else {
												return (
													<option key={index} value={item[keyValue]}>{item[keyLabel]}</option>
												)
											}
										})}
										{canBeNull ? <option value={null}>N/A</option> : ""}
									</Field>
									<div className="button-close-field" onClick={closeSupportField}>
										<img src="/images/pictos/cross-background-red.svg" alt=""/>
									</div>
								</div>
							</>
						) : (
							<div className="form-row-label-input-two-select-add" onClick={() => setCanShowIngSupport(true)}>
								<p className="text-primary color-blue-jeans">Ajouter un ingénieur en support</p>
								<img src="/images/pictos/add-blue-jeans.svg" alt=""/>
							</div>
						)
					}
				</div>
			);
		}
	}
};

export default InputLabelTwoSelectErrorMessage;
