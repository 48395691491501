import React, {useContext} from "react";
import PartnersActions from "./Actions/PartnersActions";
import {StateRequest} from "../../../views/Pages/Admin/Partners/Partners";
import {toast} from "react-toastify";

export const PARTNER_COLUMNS = [
			{
				Header: "Identifiant",
				accessor: "title",
			},
			{
				Header: "Lien",
				accessor: "lien",
				Cell: cellInfo => {
					const { setStateRequest } = useContext(StateRequest);
					const link = `${process.env.REACT_APP_PROD_URL}/chatbot?afl=${cellInfo.row.original.source}`
					return (
						<div className={"actions-controller"} onClick={() => {
							navigator.clipboard.writeText(`${process.env.REACT_APP_PROD_URL}/chatbot?afl=${cellInfo.row.original.source}`)
							toast.success("Lien copié dans le presse-papier")
						}}>
							<a href={"#"}>{link}</a>
							<img src={"/images/pictos/copy-paste.svg"} />
						</div>
						)
				}
			},
			{
				Header: "Statut",
				accessor: "active",
				Cell: cellInfo => {
					return (
						<div className={"statut"}>
							{cellInfo.row.original.active === false ? <div className={"red-info"}>Désactivé</div> : <div className={"green-info"}>Activé</div>}
						</div>
						)
				}
			},
			{
				Header: "Actions",
				accessor: "",
				type: "actions",
				Cell: cellInfo => {
					return (
						<PartnersActions cellInfo={cellInfo.row.original} />
						)
				}
			}
		]
