import React, { useEffect, useState } from 'react';

const KpiSimpleCard = ({ title, value }) => {

  const [price, setPrice] = useState(value);

  useEffect(() => {
    setPrice(value)
  }, [value]);

  return (
    <div className={`element-kpi kpi-simpleCard`}>
      <div className={"element-kpi-title"}>
        <h4 className={"color-primary"}>{title}</h4>
      </div>
      <div className={`kpi-simpleCard-count`}>
        <h1 className={"color-blue-jeans"}>
          {price}
        </h1>
      </div>
    </div>
  )
}

export default KpiSimpleCard;