import React, {useContext, useEffect, useState} from 'react';
import UserService from "../../../services/UserService";
import userService from "../../../services/UserService";
import {StateRequest} from "../../../views/Pages/Admin/Users/UsersAdmin";
import Skeleton from "react-loading-skeleton";

const PopUpUsersAdmin = ({ idUser, onClick}) => {


	const {stateRequest, setStateRequest} = useContext(StateRequest);

	const handleSubmit = async () => {
		const request = await UserService.deleteUser(idUser)
		onClick()
		if (request.request.status === 204) {
			setStateRequest('success delete')
		} else {
			setStateRequest('errror')
		}
	}

	const [name, setName] = useState("");

	/*get User to edit */
	const getUser = async () => {
		const request = await userService.getSingleUser(idUser)
		if (request.request.status === 200) {
			setName(request.data.niceGender + " " + request.data.niceNames)
		} else {
			console.log(request.request.status)
		}
	}

	useEffect(() => {
		setName("")
		getUser()
	}, [idUser])

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				{
					name !== "" ? (
						<>
							<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"}/>
							<img src={"/images/medias/popup.svg"} alt="test"/>
							<h4>
								Attention, cette action est irréversible. <br/>Voulez-vous supprimer {name} ?
							</h4>
							<div className={"pop-up-user-delete-buttons"}>
								<button className={"btn btn-cancel"} onClick={onClick}>Non</button>
								<button className={"btn btn-delete"} onClick={handleSubmit}>Oui</button>
							</div>
						</>
					) : <Skeleton height={200}/>
				}

			</div>
		</div>
	);
};

export default PopUpUsersAdmin;
