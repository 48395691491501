import React, {useEffect, useState} from 'react';
import TitleIconNavLink from "../../../../../../../components/Atomes/Title/TitleIconNavLink";
import {HOME_PATH} from "../../../../../../../config/UrlConstants";
import PopUpInformations from "../../Partials/PopUpInformations";
import TabNavigationStudyFolder from "../../Partials/TabNavigationStudyFolder";
import StudyFolderMenu from "../../Partials/StudyFolderMenu";
import {InitialValuesTsr} from "../../../../../../../config/InitialValues/Engineer/InitialValuesTsr";
import DirRespService from "../../../../../../../services/Ing/DirResp/DirRespService";
import {useParams} from "react-router-dom";
import {Form, Formik} from "formik";
import {IngTSRSchema} from "../../../../../../../schemas/Engineer/IngTSRSchema";
import InputLabelErrorMessage from "../../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../../../../../components/Atomes/Buttons/ButtonSubmit";
import Skeleton from "react-loading-skeleton";
import {toast} from "react-toastify";

const StudyFolderTSR = () => {

	const [showInformation, setShowInformation] = useState(false)
	const [studyFolderStep] = useState("TSR1")
	const [showPopUp, setShowPopUp] = useState(false)

	const [loading, setLoading] = useState(true)
	const [loadingTSR, setLoadingTSR] = useState(true)

	const {id} = useParams()

	const [content, setContent] = useState("");

	const onSubmit = async (values) => {

		const data = {
			"postcode": values.zipCode,
			"constructYear": values.yearBuilt,
			"constructPeriod": values.dateConstuction,
			"totalFloors": values.numberOfFloors,
			"destination": values.loceauxDestination,
			"constructMaterial": values.wallConstructionMaterial,
			"uniformWall": values.homogenousWall,
			"floorConstitution": values.floorConstructionAndFinishing,
			"finition": values.floorConstructionAndFinishingMateriel,
			"roofType": values.roofType,
			"terrace": values.ifTerrace,
			"roofComposition": values.roofComposition,
			"framework": values.charpente,
			"chimney": values.chimney,
			"floorToOpen": values.currentFloorToBeOpened,
			"floorWay": values.floorSpanDirection,
			"openingSide": values.OPMToBeCarriedOut,
			"accessibility": values.convertibleAtticOrTerrace,
			"g": values.g,
			"q": values.q,
			"destroyWidth": parseInt(values.wallThicknessToBeOpened),
			"hopperWidth": values.ClearBayWidth,
			"hopperHeight": values.clearanceHeight,
			"floorLoad": values.floorLoadStrip,
			"method": values.methodUsed,
			"leftSupport": parseInt(values.supportLengthsLeft),
			"rightSupport": parseInt(values.supportLengthsRight),
			"counterpoles": values.counterPosts,
			"lintelType": values.lintel,
			"lintelSideToSide": parseInt(values.numberOfBackToBackLintels),
			"lintelQty": parseInt(values.QtyTF),
			"lintelMode": values.lintelInstallation,
			"frameSize": values.bedBases,
			"stoolType": values.stools,
			"stoolQty": parseInt(values.stoolsQty),
			"concreteBeamClass": values.concreteOrSteelBeamClass,
			"concretePostClass": values.concreteOrSteelPostClass,
			"steelBeamUpSize": values.HABeamUpperAxisSteels,
			"steelBeamDownSize": values.HABeamLowerAxisSteels,
			"steelPost": values.HAPostSteels,
			"actualCounterpoles": values.counterPostsMade,
			"actualCounterpolesLength": values.counterPostsLength,
			"boltQty": parseInt(values.beamPostAssBoltsQty),
			"boltDiam": values.beamPostAssBoltsDiameter,
			"boltClass": values.beamPostAssBoltsCLass,
			"beamSplicePosition": values.beamSplicingPosition,
			"beamSpliceBoltQty": parseInt(values.beamSplicingQty),
			"beamSpliceDiam": values.beamSplicingDiameter,
			"beamSpliceClass": values.beamSplicingClass,
			"beamSpliceType": values.typicalSplicingPosition,
			"beamFootplate": values.postFootPlate,
			"beamWeldBead": parseInt(values.beamWeldingBeads),
			"postWeldBead": parseInt(values.postWeldingBeads),
			"fireResistence": values.fireStabilityRequired,
		}
		const request = await DirRespService.postTsr(id, data)
		if (request.request.status === 200) {
			toast.success("Le TSR a bien été enregistré")
		} else {
			toast.error("Erreur lors de l'enregistrement du TSR")
		}

	}

	const getInformationTSR = async () => {
		const request = await DirRespService.getInformationTSR(id)
		if (request.request.status === 200) {
			// Automatic
			InitialValuesTsr.zipCode = request.data.postcode
			InitialValuesTsr.yearBuilt = request.data.constructYear
			InitialValuesTsr.dateConstuction = request.data.constructPeriod
			InitialValuesTsr.numberOfFloors = request.data.totalFloors
			InitialValuesTsr.loceauxDestination = request.data.destination
			InitialValuesTsr.wallConstructionMaterial = request.data.constructMaterial
			InitialValuesTsr.homogenousWall = request.data.uniformWall
			InitialValuesTsr.floorConstructionAndFinishing = request.data.floorConstitution
			InitialValuesTsr.floorConstructionAndFinishingMateriel = request.data.finition
			InitialValuesTsr.roofType = request.data.roofType
			InitialValuesTsr.ifTerrace = request.data.terrace
			InitialValuesTsr.roofComposition = request.data.roofComposition
			InitialValuesTsr.charpente = request.data.framework
			InitialValuesTsr.chimney = request.data.chimney
			InitialValuesTsr.currentFloorToBeOpened = request.data.floorToOpen
			InitialValuesTsr.floorSpanDirection = request.data.floorWay
			InitialValuesTsr.OPMToBeCarriedOut = request.data.openingSide
			InitialValuesTsr.convertibleAtticOrTerrace = request.data.accessibility
			setLoadingTSR(false)
		}
	}

	const getProjectsInformations = async () => {
		const request = await DirRespService.getStudyFolderInformations(id)
		if (request.status === 200) {
			setContent(request.data)
			setLoading(false)
		}
	}

	useEffect(() => {
		setLoadingTSR(true)
		setLoading(true)
		getInformationTSR()
		getProjectsInformations()
	}, [id])

	return (
		<>
			{
				loading || loadingTSR ? <Skeleton height={500}/> : (
					<div className={"section-study-folder-tsr mbe-section"}>
						<div className={"section-study-folder-tsr-title"}>
							<TitleIconNavLink title={`${content.project.client.niceNames} - ${content.project.address.street}, ${content.project.address.nicePostcode}, ${content.project.address.city}`} link={HOME_PATH} information={true}
							                  onClick={() => setShowInformation(true)}/>
						</div>

						<TabNavigationStudyFolder theStep={studyFolderStep} id={id} needExecutionClass={content.needExecutionClass} needCCTP={content.needCctp}
						                          needSummary={content.needSummary}/>

						<div className={"section-study-folder-tsr-container"}>
							<div className={"section-study-folder-tsr-container-editor"}>
								<div className={"section-study-folder-tsr-container-editor-title"}>
									<h3 className={"text-primary color-primary"}>TSR</h3>
								</div>

								{
									loading ? <Skeleton height={340}/> : (

										<Formik
											initialValues={InitialValuesTsr}
											validationSchema={IngTSRSchema}
											onSubmit={onSubmit}
										>
											{({isSubmitting}) => (
												<Form>

													<h4 className={"color-primary text-primary"}>Remplissage depuis le DIAG AUTO</h4>

													<div className={"form-row-element form-row-two-elements"}>

														<InputLabelErrorMessage type={"number"} label={"Code postal"} name={"zipCode"}/>
														<InputLabelErrorMessage type={"number"} label={"Date de construction"} name={"yearBuilt"}/>
													</div>

													<div className={"form-row-element form-row-two-elements"}>

														<InputLabelErrorMessage type={"select"} label={"Période de construction"}
														                        name={"dateConstuction"}
														                        keyLabel={"year"} keyValue={"year"} data={[
															{"year": "1700-1710"},
															{"year": "1710-1720"},
															{"year": "1720-1730"},
															{"year": "1730-1740"},
															{"year": "1740-1750"},
															{"year": "1750-1760"},
															{"year": "1760-1770"},
															{"year": "1770-1780"},
															{"year": "1780-1790"},
															{"year": "1790-1800"},
															{"year": "1800-1810"},
															{"year": "1810-1820"},
															{"year": "1820-1830"},
															{"year": "1830-1840"},
															{"year": "1840-1850"},
															{"year": "1850-1860"},
															{"year": "1860-1870"},
															{"year": "1870-1880"},
															{"year": "1880-1890"},
															{"year": "1890-1900"},
															{"year": "1900-1910"},
															{"year": "1910-1920"},
															{"year": "1920-1930"},
															{"year": "1930-1940"},
															{"year": "1940-1950"},
															{"year": "1950-1960"},
															{"year": "1960-1970"},
															{"year": "1970-1980"},
															{"year": "1980-1990"},
															{"year": "1990-2000"},
															{"year": "2000-2010"},
															{"year": "2010-2020"},
															{"year": "2020-2030"},
															{"year": "2030-2040"},
															{"year": "2040-2050"}
														]}/>
														<InputLabelErrorMessage type={"select"} label={"Nombre d'étage total"}
														                        name={"numberOfFloors"}
														                        keyLabel={"floor"} keyValue={"floor"} data={[
															{
																floor: "R+00"
															},
															{
																floor: "R+01"
															},
															{
																floor: "R+02"
															}, {
																floor: "R+03"
															},
															{
																floor: "R+04"
															}, {
																floor: "R+05"
															},
															{
																floor: "R+06"
															}, {
																floor: "R+07"
															},
															{
																floor: "R+08"
															}, {
																floor: "R+09"
															},
															{
																floor: "R+10"
															}, {
																floor: "R+11"
															},
															{
																floor: "R+12"
															}, {
																floor: "R+13"
															},
															{
																floor: "R+14"
															}, {
																floor: "R+15"
															}
														]}/>
													</div>

													<div className={"form-row-element form-row-two-elements"}>

														<InputLabelErrorMessage type={"select"} label={"Destination des locaux"}
														                        name={"loceauxDestination"}
														                        keyLabel={"locaux"} keyValue={"locaux"} data={[
															{"locaux": "150 - Hébergement en chambres, salles de jeux et repos des crèches"},
															{"locaux": "250 - Hébergement collectif (dortoirs)"},
															{"locaux": "250 - Salles de restaurants, cafés, cantines de dimension réduites ( places assises<100)"},
															{"locaux": "250 - Bureaux proprement dits"},
															{"locaux": "250 - Salles de réunion avec tables de travail"},
															{"locaux": "400 - Halles diverses (gare, etc...) ou le public se déplace"},
															{"locaux": "250 - Salles d'exposition de moins de 50 m²"},
															{"locaux": "350 - Salles d'exposition de 50 m² ou plus"},
															{"locaux": "500 - Salles de réunion et lieux de culte avec assistance debout"},
															{"locaux": "600 - Salles, tribunes et gradins des lieux de spectacles et sport avec places debout"},
															{"locaux": "400 - Salles de théâtre, salles de conférences, amphithéâtre, tribunes et autres lieux avec sièges"},
															{"locaux": "250 - Cuisines des collectivités, non compris les charges du gros matériel prises en compte indépendamment"},
															{"locaux": "400 - Salles de lecture des bibliothèques"},
															{"locaux": "500 - Boutiques et annexes"},
															{"locaux": "250 - Garages et parc de stationnement de voitures légères, à l'exclusion des ateliers d'entretien et de réparation"},
															{"locaux": "350 - Balcons"},
															{"locaux": "150 - Logements y compris combles aménageables"},
															{"locaux": "250 - Escaliers à l'exclusion des marches isolées, halls d'entrée"},
															{"locaux": "100 - Combles non aménageables, dont l'utilisation n'est pas prévue à priori, non accessible normalement"},
															{"locaux": "250 - Greniers proprement dits"},
															{"locaux": "250 - Étages des caves"},
															{"locaux": "250 - Bureaux proprement dits"},
															{"locaux": "350 - Bureaux paysagers"},
															{"locaux": "250 - Circulations et escaliers"},
															{"locaux": "250 - Halls de réception"},
															{"locaux": "400 - Halls à guichet"},
															{"locaux": "350 - Salles de projection et de conférence à nombre de places limité (<50m²)"},
															{"locaux": "300 - Cantines"},
															{"locaux": "350 - Salles de réunion avec tables"},
															{"locaux": "350 - Zone de dépôts"},
															{"locaux": "250 - Salles d'ordinateurs et de reprographie"},
															{"locaux": "600 - Dépôts de cuisines collectives"},
															{"locaux": "400 - Salles avec assistance debout : circulations, escaliers, surfaces de regroupement, d'abri, de détente, polyvalente"},
															{"locaux": "500 - Cuisines collectives"},
															{"locaux": "400 - Salles de réunions, salles polyvalentes avec sièges, bibliothèques, dépôts, lingerie"},
															{"locaux": "350 - Amphithéâtres, salles de classes remodelables et locaux équivalents, cantines, réfectoires"},
															{"locaux": "250 - Salles de classes, salles à manger de petites dimensions, laboratoires, ateliers, dortoirs, sanitaires, locaux médicaux"},
															{"locaux": "150 - Hébergement individuel"},
															{"locaux": "150 - Locaux d'hébergement chambres"},
															{"locaux": "250 - Locaux d'hébergement circulations internes"},
															{"locaux": "350 - Salles d'opérations, salles de plâtres, salles de travail"},
															{"locaux": "250 - Autres services"},
															{"locaux": "400 - Halls"},
															{"locaux": "400 - Circulations générales"},
															{"locaux": "250 - Postes de personnels et de soins"},
															{"locaux": "250 - Salles de cours"},
															{"locaux": "400 - Salles de réunions, de conférence et de restauration générale, local de réception de surface > 100 m²"},
															{"locaux": "250 - Salles de réunions, de conférence et de restauration générale, local de réception de surface < 50 m²"},
															{"locaux": "150 - Sanitaires"},
															{"locaux": "350 - Buanderies"},
															{"locaux": "500 - Locaux de réserves, dépôts ou stockage"}
														]
														}/>
														<InputLabelErrorMessage type={"select"} label={"Matériau construction du mur"}
														                        name={"wallConstructionMaterial"} keyLabel={"mur"} keyValue={"mur"}
														                        data={[
															                        {"mur": "Voile BA"},
															                        {"mur": "Briques rouges \"Nord\" pleines"},
															                        {"mur": "Briques rouges \"Nord\" creuses"},
															                        {"mur": "Brique \"Maxi brique\" ou \"Monomur\" ou \"Porotherm\""},
															                        {"mur": "Parpaings Pleins B80"},
															                        {"mur": "Parpaings Creux B40"},
															                        {"mur": "Parpaing Allégé B60"},
															                        {"mur": "Siporex"},
															                        {"mur": "Mâchefer / Plâtre"},
															                        {"mur": "Tuffeau de Touraine (Indre et Loire)"},
															                        {"mur": "Billy (Aisne), blanc royal"},
															                        {"mur": "Vierzy (Aisne)"},
															                        {"mur": "Sireuil (Charente), tendre"},
															                        {"mur": "Saint Vaast (Oise), royal blanc"},
															                        {"mur": "Fontvieille (Bouche Du Rhone)"},
															                        {"mur": "La Boulaye (Oise), blanc royal"},
															                        {"mur": "Méry Sur Oise, blanc franc"},
															                        {"mur": "Saint Maximin (Oise), roche douce"},
															                        {"mur": "Saint Meme (Charente)"},
															                        {"mur": "Méry Sur Oise, blanc royal"},
															                        {"mur": "Sireuil (Charente), roche ferme"},
															                        {"mur": "Saint Vaast (Oise), roche douce"},
															                        {"mur": "Savonnières (Meuse)"},
															                        {"mur": "Saint maximin (Oise), roche demi-ferme"},
															                        {"mur": "Estaillades (Vaucluse)"},
															                        {"mur": "Migné (Vienne)"},
															                        {"mur": "Caen (Calvados)"},
															                        {"mur": "Branvilliers (Meuse), liais"},
															                        {"mur": "Lavoux (Vienne), fin"},
															                        {"mur": "Saint Maximin (Oise), roche ferme"},
															                        {"mur": "Lérouvaille (Meuse)"},
															                        {"mur": "Larrys (Yonne), roche ferme"},
															                        {"mur": "Chauvigny (Vienne), roche ferme"},
															                        {"mur": "Euville (Meuse), roche ferme"},
															                        {"mur": "Massangis (Yonne), liais"},
															                        {"mur": "Saint Maximin (Oise), roche dure"},
															                        {"mur": "Vilhonneur (Charente)"},
															                        {"mur": "Massangis (Yonne), roche jaune"},
															                        {"mur": "Echaillon (Isere), blanc"},
															                        {"mur": "Larrys (Yonne), sous-moucheté"},
															                        {"mur": "Larrys (Yonne), moucheté"},
															                        {"mur": "Villebois (Ain)"},
															                        {"mur": "Arudy (Basses Pyrénées)"},
															                        {"mur": "Comblanchien (Cote d'Or)"},
															                        {"mur": "Hydrequent (Pas de Calais)"},
															                        {"mur": "Château Landon Souppes"},
															                        {"mur": "Hauteville (Ain)"},
															                        {"mur": "Corgoloin (Cote d'Or)"},
															                        {"mur": "Granit de Normandie"},
															                        {"mur": "Lave dure du Vésuve"},
															                        {"mur": "Porphyre"},
															                        {"mur": "Comblanchien (Cote d'Or)"},
															                        {"mur": "Grimault"},
															                        {"mur": "Belvoye (Jura)"},
															                        {"mur": "Liais de Courville (Marne)"},
															                        {"mur": "Anstrude (Marne)"},
															                        {"mur": "Liais de Clamart (Seine)"},
															                        {"mur": "Laversine (Aisne)"},
															                        {"mur": "Roche de Bagneux (Seine)"},
															                        {"mur": "Ravières (Yonne)"},
															                        {"mur": "Roche fine de Saint Maximin (Oise)"},
															                        {"mur": "Roche de Chassignelles (Yonne)"},
															                        {"mur": "Lerouville (Meuse)"},
															                        {"mur": "Roche ordinaire de Saint Maximin (Oise)"},
															                        {"mur": "Vitry (Seine)"},
															                        {"mur": "Courson (Yonne)"},
															                        {"mur": "Palotte (Yonne)"},
															                        {"mur": "Marly La Ville"},
															                        {"mur": "Saint Waast (Oise) Blanc royal"},
															                        {"mur": "Abbaye du Val"},
															                        {"mur": "Saint Leu (Oise) Vergelé"},
															                        {"mur": "Saint Maximin (Oise) Vergelé"},
															                        {"mur": "Saint Waast (Oise) Vergelé"},
															                        {"mur": "Lambourdes"},
															                        {"mur": "Meulière"}
														                        ]
														                        }/>
													</div>

													<div className={"form-row-element form-row-two-elements"}>

														<InputLabelErrorMessage type={"select"} label={"Mur homogène"} name={"homogenousWall"}
														                        keyLabel={"homogenous"} keyValue={"homogenous"} data={[
															{
																homogenous: "Homogène"
															},
															{
																homogenous: "Plan de bois"
															},
															{
																homogenous: "Plan de fer"
															}
														]}/>
														<InputLabelErrorMessage type={"select"} label={"Constitution des planchers et finition"}
														                        name={"floorConstructionAndFinishing"} keyLabel={"plancher"}
														                        keyValue={"plancher"} data={[
															{"plancher": "Solivage bois sec"},
															{"plancher": "Solivage bois / Hourdis plâtre"},
															{"plancher": "Poutrelles métal / Hourdis plâtre"},
															{"plancher": "Poutrelles métal / Hourdis terre cuite"},
															{"plancher": "Dalle BA 20cm"},
															{"plancher": "Poutrelles BA / Hourdis béton 12 + 4"},
															{"plancher": "Poutrelles BA / Hourdis béton 16 + 4"},
															{"plancher": "Poutrelles BA / Hourdis béton 20 + 4"},
															{"plancher": "Poutrelles BA / Hourdis béton 25 + 5"},
															{"plancher": "Poutrelles BA / Hourdis terre cuite 12 + 4"},
															{"plancher": "Poutrelles BA / Hourdis terre cuite 16 + 4"},
															{"plancher": "Poutrelles BA / Hourdis terre cuite 20 + 4"},
															{"plancher": "Poutrelles BA / Hourdis terre cuite 25 + 5"},
															{"plancher": "Poutrelles BA / Hourdis polystyrène expansé 12 + 5"},
															{"plancher": "Poutrelles BA / Hourdis polystyrène expansé 16 + 5"},
															{"plancher": "Poutrelles BA / Hourdis polystyrène expansé 20 + 5"},
															{"plancher": "Poutrelles BA / Hourdis polystyrène expansé 25 + 5"},
															{"plancher": "Préfabriqués à éléments jointifs de dalles alvéolées 12"},
															{"plancher": "Préfabriqués à éléments jointifs de dalles alvéolées 16"},
															{"plancher": "Préfabriqués à éléments jointifs de dalles alvéolées 20"},
															{"plancher": "Préfabriqués à éléments jointifs de dalles alvéolées 24"}
														]
														}/>
													</div>

													<div className={"form-row-element form-row-two-elements"}>

														<InputLabelErrorMessage type={"select"} label={"Revêtement des planchers et finition"}
														                        name={"floorConstructionAndFinishingMateriel"} keyLabel={"materiel"}
														                        keyValue={"materiel"} data={[
															{
																materiel: "Revêtement léger"
															},
															{
																materiel: "Revêtement classique"
															},
															{
																materiel: "Revêtement lourd"
															}, {
																materiel: "Chape 6cm avec revêtement"
															}
														]}/>
														<InputLabelErrorMessage type={"select"} label={"Type de toiture"} name={"roofType"}
														                        keyLabel={"toit"}
														                        keyValue={"toit"} data={[
															{
																toit: "Un pan"
															}, {
																toit: "Deux pans"
															}, {
																toit: "Plus de 2 pans"
															}, {
																toit: "Terrasse sur dalle BA"
															}
														]
														}/>
													</div>

													<div className={"form-row-element form-row-two-elements"}>

														<InputLabelErrorMessage type={"select"} label={"Si terrasse"} name={"ifTerrace"}
														                        keyLabel={"terrasse"}
														                        keyValue={"terrasse"} data={[
															{"terrasse": "Asphalte coulé 0,5 cm + 1,5 cm en asphalte coulé et sablé"},
															{"terrasse": "Carreaux d'asphalte, 2 cm scellés au bitume"},
															{"terrasse": "Gravillons ou sable, ep 15cm"},
															{"terrasse": "Carreaux de béton sur 2 cm de sable"},
															{"terrasse": "Terre végétale, ep 20cm et végétation"},
															{"terrasse": "Dalles sur plots"}
														]}/>
														<InputLabelErrorMessage type={"select"} label={"Composition de la toiture"}
														                        name={"roofComposition"}
														                        keyLabel={"toit"} keyValue={"toit"} data={[
															{"toit": "Acier Inox y compris voligeage et tasseaux"},
															{"toit": "Aluminium 8/10 (plaques ondulées)"},
															{"toit": "Aluminium 8/10 y compris voligeage et tasseaux"},
															{"toit": "Ardoises fibres-ciment y compris lattis"},
															{"toit": "Ardoises naturelles y compris lattis"},
															{"toit": "Bardeaux d’asphalte bitumé"},
															{"toit": "Tuile plate"},
															{"toit": "Plaques ondulées fibres-ciment"},
															{"toit": "Plaques polyester ondulées"},
															{"toit": "Tôles ondulées acier galvanisé et bacs nervurés"},
															{"toit": "Tuile à côte (grande)"},
															{"toit": "Zinc y compris voligeage et tasseaux"}
														]
														}/>
													</div>

													<div className={"form-row-element form-row-two-elements"}>

														<InputLabelErrorMessage type={"select"} label={"Charpente"} name={"charpente"}
														                        keyLabel={"charpente"}
														                        keyValue={"charpente"} data={[
															{"charpente": "Charpente bois (fermes, pannes et chevrons)"},
															{"charpente": "Charpente métallique (fermes, pannes et chevrons)"},
															{"charpente": "Étrésillons (planches de 27 mm)"},
															{"charpente": "Solivage en madriers 75 x 225 Espacement 30 cm"},
															{"charpente": "Solivage en madriers 75 x 225 Espacement 40 cm"},
															{"charpente": "Solivage en madriers 75 x 225 Espacement 50 cm"}
														]}/>
														<InputLabelErrorMessage type={"select"} label={"Présence de cheminée"} name={"chimney"}
														                        keyLabel={"toit"} keyValue={"toit"} data={[
															{"toit": "Non"},
															{"toit": "Contre le mur"},
															{"toit": "Dans le mur"},
														]
														}/>
													</div>

													<div className={"form-row-element form-row-two-elements"}>

														<InputLabelErrorMessage type={"select"} label={"Etage courant à ouvrir"}
														                        name={"currentFloorToBeOpened"} keyLabel={"data"} keyValue={"data"}
														                        data={
															                        [
																                        {"data": "PH--2"},
																                        {"data": "PH--1"},
																                        {"data": "PH-R0"},
																                        {"data": "PH-R1"},
																                        {"data": "PH-R2"},
																                        {"data": "PH-R3"},
																                        {"data": "PH-R4"},
																                        {"data": "PH-R5"},
																                        {"data": "PH-R6"},
																                        {"data": "PH-R7"},
																                        {"data": "PH-R8"},
																                        {"data": "PH-R9"},
																                        {"data": "PH-R10"},
																                        {"data": "PH-R11"},
																                        {"data": "PH-R12"},
																                        {"data": "PH-R13"},
																                        {"data": "PH-R14"},
																                        {"data": "PH-R15"}
															                        ]

														                        }/>

														<InputLabelErrorMessage type={"select"} label={"Sens de portée des planchers"}
														                        name={"floorSpanDirection"} keyLabel={"data"} keyValue={"data"}
														                        data={
															                        [
																                        {"data": "Sur le mur"},
																                        {"data": "Parallèle au mur"},
																                        {"data": "Sur 4 côtés"}
															                        ]
														                        }/>

													</div>

													<div className={"form-row-element form-row-two-elements"}>

														<InputLabelErrorMessage type={"select"} label={"OPM à réaliser"} name={"OPMToBeCarriedOut"}
														                        keyLabel={"data"} keyValue={"data"} data={
															[
																{"data": "En refend"},
																{"data": "En facade"},
																{"data": "En pignon"},
																{"data": "Autre mur"},
															]

														}/>

														<InputLabelErrorMessage type={"select"} label={"Combles aménagés ou terrasse acessible"}
														                        name={"convertibleAtticOrTerrace"} keyLabel={"data"}
														                        keyValue={"data"}
														                        data={
															                        [
																                        {"data": "Oui"},
																                        {"data": "Non"},
															                        ]
														                        }/>

													</div>

													<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
													              title={"Sauvegarder"}/>

												</Form>
											)}
										</Formik>
									)}

							</div>
							<StudyFolderMenu content={content} projectId={content.project.id}/>
						</div>


						<PopUpInformations
							show={showInformation}
							onClick={() => setShowInformation(false)}
							large={false}
							title={"Informations projet"}
							content={content}
						/>
					</div>
				)
			}
		</>

	);
};

export default StudyFolderTSR;
