import React, {createContext, useEffect, useState} from 'react';
import RequestService from "../../../../../services/RequestService";
import CollaboratorService from "../../../../../services/CollaboratorService";
import moment from "moment/moment";
import {ConvertObjectToUrlParams} from "../../../../../utils/StringUtils";
import {toast} from "react-toastify";
import {COMS_RES_NEW_REQUESTS} from "../../../../../config/UrlConstants";
import TableTitle from "../../../../../components/Molecules/Tables/TalbeTitle";
import {CALLBACK_REQUEST_COLUMNS} from "../../../../../config/ArrayColumns/CallbackRequestColumns";
import {FILE_IN_PROGRESS_COLUMNS} from "../../../../../config/ArrayColumns/FileInProgressColumns";
import {SIGNED_QUOTES_COLUMNS} from "../../../../../config/ArrayColumns/SignedQuotesColumns";
import {PARTNER_QUOTES_COLUMNS} from "../../../../../config/ArrayColumns/PartnerQuotesColumns";
import {RES_COMMERCIAL_NEW_REQUEST_COLUMNS} from "../../../../../config/ArrayColumns/ResCommercialNewRequest";
import FilterThreeSelectsDatepicker from "../../../../../components/Molecules/Filter/FilterThreeSelectsDatepicker";
import InputSearch from "../../../../../components/Atomes/Inputs/InputSearch";
import Skeleton from "react-loading-skeleton";
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

export const StateRequest = createContext()
const ManagerHome = () => {

	//Search
	const [search, setSearch] = useState("");

	const id = localStorage.getItem("userId")

	const secondOptions = ["1 mois", "2 mois", "3 mois"]
	const secondValues = [1, 2, 3]
	const options = dataRegionsArray
	const [stateRequest, setStateRequest] = useState("");
	const [region, setRegion] = useState("");
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [date, setDate] = useState(new Date());
	const [userFilter, setUserFilter] = useState("")

	const [newRequest, setNewRequest] = useState([]);
	const [newRequestLoading, setNewRequestLoading] = useState(true);
	const [callbackRequest, setCallbackRequest] = useState([]);
	const [callbackRequestLoading, setCallbackRequestLoading] = useState(true);
	const [fileInProgress, setFileInProgress] = useState([]);
	const [fileInProgressLoading, setFileInProgressLoading] = useState(true);
	const [signedQuotes, setSignedQuotes] = useState([]);
	const [signedQuotesLoading, setSignedQuotesLoading] = useState(true);
	const [collaborators, setCollaborators] = useState([]);
	const [collaboratorsLoading, setCollaboratorsLoading] = useState(true);
	const [partnerQuotes, setPartnerQuotes] = useState([]);
	const [partnerQuotesLoading, setPartnerQuotesLoading] = useState(true);

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("La prestation a bien été ajoutée")
		} else if (type === "success edit") {
			toast.success("La prestation a bien été modifiée")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	useEffect(() => {
		if (stateRequest !== "") {
			setPartnerQuotes([])
			DisplayToast(stateRequest)
		}
		getPartnerQuotes()
	}, [stateRequest])

	const getCollaborators = async () => {
		const urlCom = ConvertObjectToUrlParams("coms", {regions: region})
		const request = await CollaboratorService.getCollaborators(urlCom)
		if (request.request.status === 200) {
			let coms = request.data['hydra:member']
			let availableComs = []
			coms.forEach(com => {
				if (com.roles[0] !== "ROLE_COM_DIR") {
					availableComs.push(com)
				}
			})
			setCollaborators(availableComs)
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	const getFolderInProgress = async () => {
		const request = await RequestService.getFolderInProgress({
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"project.region": region,
			"project.users.id": userFilter,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			/*setFileInProgress(request.data["hydra:member"].sort((a, b) => {
				return new Date(b.createdAt) - new Date(a.createdAt);
			}));*/
			setFileInProgress(request.data);
			setFileInProgressLoading(false)
		} else {
			toast.error('Une erreur est survenue lors de la récupération des dossiers en cours')
		}
	}

	const getSignedFiles = async () => {
		const request = await RequestService.getSignedFiles({
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"project.region": region,
			"project.users.id": userFilter,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			setSignedQuotes(request.data);
			setSignedQuotesLoading(false)
		} else {
			toast.error('Une erreur est survenue lors de la récupération des devis signés')
		}
	}

	const getNewRequest = async () => {
		const request = await RequestService.getNewRequests({
			"user.id": id,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			pagination: "true",
		})
		if (request.request.status === 200) {
			setNewRequest(request.data);
			setNewRequestLoading(false)
		} else {
			toast.error('Une erreur est survenue lors de la récupération nouvelles demandes')
		}
	}

	const getCallbackRequests = async () => {
		const request = await RequestService.getCallbackRequests({
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			region: region,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			/*setCallbackRequest(request.data["hydra:member"].sort((a, b) => {
				return new Date(b.createdAt) - new Date(a.createdAt);
			}));*/
			setCallbackRequest(request.data);
			setCallbackRequestLoading(false)
		} else {
			toast.error('Une erreur est survenue lors de la récupération des demandes de rappel')
		}
	}

	const getPartnerQuotes = async () => {
		const request = await RequestService.getPartnerQuotes({
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"project.users.id": userFilter,
			"project.region": region,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			setPartnerQuotes(request.data);
			setPartnerQuotesLoading(false)
		} else {
			toast.error('Une erreur est survenue lors de la récupération des devis partenaires')
		}
	}

	useEffect(() => {
		getCollaborators()
		getFolderInProgress()
		getSignedFiles()
		getNewRequest()
		getCallbackRequests()
		getPartnerQuotes()
	}, [dateStart, dateEnd, region, userFilter, date, search])

	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Tous les collaborateurs") {
			setUserFilter("")
		} else {
			setUserFilter(event.target.value)
		}
	}

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}

	const changeDateMonthLater = (date) => {
		if (date === "Toutes durées") {
			setDateStart("")
			setDateEnd("")
			setDate(new Date())
		} else {
			let dateEnd = moment().format("YYYY-MM-DD")
			let dateBegin = moment().subtract(date, 'months').format("YYYY-MM-DD")
			setDate([new Date(dateBegin), new Date(dateEnd)])
			setDateStart(dateBegin)
			setDateEnd(dateEnd)
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}
	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}
	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<FilterThreeSelectsDatepicker
				defaultValue={"Toutes les agences"}
				options={options}
				onChange={changeRegionFilter}
				firstValue={options}
				secondDefaultValue={"Tous les collaborateurs"}
				secondValue={collaborators.map(collaborators => collaborators.id)}
				secondOptions={collaborators.map(collaborators => collaborators.lastname)}
				styledButton={"btn-default btn-default-primary btn-default-primary color-primary"}
				titleButton={"Selectionnez une période"}
				thirdDefaultValue={"Toutes durées"}
				thirdOptions={secondOptions}
				thirdValue={secondValues}
				onThirdChange={(event) => changeDateMonthLater(event.target.value)}
				onSecondChange={changeCollaboratorFilter}
				date={date}
				onChangeDate={(date) => changeDate(date)}
				resetDateSelected={resetDate}

			/>
			<InputSearch placeholder={"Entrer la recherche puis valider"}
			             onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
			             name={"Recherche"}
			             onChangeSearch={(event) => (event.key === "Enter" || event.target.value.length === 0) ? setSearch(event.target.value.toLowerCase()) : null}/>

			{
				!newRequestLoading ? (
						<TableTitle className={"section-array-new-request mbe-section"} title={"Nouvelles demandes"}
						            columnTable={RES_COMMERCIAL_NEW_REQUEST_COLUMNS} data={newRequest}
						            linkTitle={"Voir toutes les nouvelles demandes"} link={COMS_RES_NEW_REQUESTS}/>
					) :
					(
						<Skeleton height={500}/>
					)
			}

			{
				!callbackRequestLoading ? (
						<TableTitle className={"section-array-callback-request mbe-section"} data={callbackRequest}
						            title={"Demandes de rappel"} columnTable={CALLBACK_REQUEST_COLUMNS}/>
					) :
					(
						<Skeleton height={500}/>
					)
			}

			{
				!callbackRequestLoading ? (
						<TableTitle className={"section-array-callback-request mbe-section"} data={fileInProgress}
						            title={"Dossier en cours"} columnTable={FILE_IN_PROGRESS_COLUMNS}/>
					) :
					(
						<Skeleton height={500}/>
					)
			}

			{
				!signedQuotesLoading ? (
						<TableTitle className={"section-array-signed-quotes mbe-section"} title={"Devis signés"}
						            columnTable={SIGNED_QUOTES_COLUMNS} data={signedQuotes}/>
					) :
					(
						<Skeleton height={500}/>
					)
			}
			{
				!partnerQuotesLoading ? (
						<TableTitle className={"section-array-partner-quotes mbe-section"} title={"Devis partenaires"}
						            columnTable={PARTNER_QUOTES_COLUMNS} data={partnerQuotes}/>
					) :
					(
						<Skeleton height={500}/>
					)
			}

		</StateRequest.Provider>
	);
};

export default ManagerHome;
