import api from "./APIService";
import jwt_decode from "jwt-decode";

const getUser = async () => {
	const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
	const decodedToken = jwt_decode(user.token)
	const id = decodedToken.id
	const url = `users/${id}`

	return await api.getAllData(url)
}


const getSingleUser = async (id) => {
	const url = `users/${id}`
	return await api.get(url)
}


const getUserId = () => {
	const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
	const decodedToken = jwt_decode(user.token)
	const id = decodedToken.id

	return id
}

const getUserService = {
	getUser,
	getUserId,
	getSingleUser
}

export default getUserService;
