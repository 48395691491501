import { ConvertObjectToUrlParams } from "../utils/StringUtils";
import api from "./APIService";

const patchClient = async (id, data) =>{
	return await api.patch(`clients/${id}`, data)
}

const getClient = async (param) =>{
	return await api.getAllData(ConvertObjectToUrlParams(`clients`, param))
}

export const clientService  = {
	patchClient,
	getClient
}
