import api from "./APIService";
import { ConvertObjectToUrlParams } from "../utils/StringUtils";
import axios from "axios";
import fileDownload from 'js-file-download'

const get = async (url) => {
  return await api.getAllData(url)
}
const getWithParams = async (url, params) => {
  return await api.getAllData(ConvertObjectToUrlParams(url, params))
}

const post = async (url, data) => {
  return await api.postBody(url, data)
}

const patch = async (url, id, data) => {
  return await api.patch(`${url}/${id}`, data)
}

const deleteById = async (url, id) => {
  return await api.del(`${url}/${id}`)
}

const getFile = async (url, filename) => {
  const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
  axios.get(process.env.REACT_APP_PROD_URL + url, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    responseType: 'blob',
  })
    .then((res) => {
      fileDownload(res.data, filename)
    })
}

export const GlobalService = {
  get,
  getWithParams,
  post,
  patch,
  deleteById,
  getFile
}