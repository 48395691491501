import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import DirRespService from "../../../../../../../services/Ing/DirResp/DirRespService";
import Skeleton from "react-loading-skeleton";
import {HOME_PATH} from "../../../../../../../config/UrlConstants";
import TitleIconNavLink from "../../../../../../../components/Atomes/Title/TitleIconNavLink";
import TabNavigationStudyFolder from "../../Partials/TabNavigationStudyFolder";
import StudyFolderMenu from "../../Partials/StudyFolderMenu";
import {Form, Formik} from "formik";
import InputLabelErrorMessage from "../../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../../../../../components/Atomes/Buttons/ButtonSubmit";
import {InitialValuesTsr2} from "../../../../../../../config/InitialValues/Engineer/InitialValuesTsr2";
import {IngTSR2Schema} from "../../../../../../../schemas/Engineer/IngTSR2Schema";
import {toast} from "react-toastify";
import PopUpInformations from "../../Partials/PopUpInformations";

const StudyFolderTSR2 = () => {

	const [showInformation, setShowInformation] = useState(false)
	const [studyFolderStep] = useState("TSR2")
	const [showPopUp, setShowPopUp] = useState(false)

	const [loading, setLoading] = useState(true)
	const [loadingTSR, setLoadingTSR] = useState(true)

	const {id} = useParams()

	const [content, setContent] = useState("");

	const getProjectsInformations = async () => {
		const request = await DirRespService.getStudyFolderInformations(id)
		if (request.status === 200) {
			setContent(request.data)
			setLoading(false)
		}
	}

	const onSubmit = async (values) => {

		const data = {
			"g": values.g,
			"q": values.q,
			"destroyWidth": parseInt(values.wallThicknessToBeOpened),
			"hopperWidth": values.ClearBayWidth,
			"hopperHeight": values.clearanceHeight,
			"floorLoad": values.floorLoadStrip,
			"method": values.methodUsed,
			"leftSupport": parseInt(values.supportLengthsLeft),
			"rightSupport": parseInt(values.supportLengthsRight),
			"counterpoles": values.counterPosts,
			"lintelType": values.lintel,
			"lintelSideToSide": parseInt(values.numberOfBackToBackLintels),
			"lintelQty": parseInt(values.QtyTF),
			"lintelMode": values.lintelInstallation,
			"frameSize": values.bedBases,
			"stoolType": values.stools,
			"stoolQty": parseInt(values.stoolsQty),
			"concreteBeamClass": values.concreteOrSteelBeamClass,
			"concretePostClass": values.concreteOrSteelPostClass,
			"steelBeamUpSize": values.HABeamUpperAxisSteels,
			"steelBeamDownSize": values.HABeamLowerAxisSteels,
			"steelPost": values.HAPostSteels,
			"actualCounterpoles": values.counterPostsMade,
			"actualCounterpolesLength": values.counterPostsLength,
			"boltQty": parseInt(values.beamPostAssBoltsQty),
			"boltDiam": values.beamPostAssBoltsDiameter,
			"boltClass": values.beamPostAssBoltsCLass,
			"beamSplicePosition": values.beamSplicingPosition,
			"beamSpliceBoltQty": parseInt(values.beamSplicingQty),
			"beamSpliceDiam": values.beamSplicingDiameter,
			"beamSpliceClass": values.beamSplicingClass,
			"beamSpliceType": values.typicalSplicingPosition,
			"beamFootplate": values.postFootPlate,
			"beamWeldBead": parseInt(values.beamWeldingBeads),
			"postWeldBead": parseInt(values.postWeldingBeads),
			"fireResistence": values.fireStabilityRequired,
			validate: true
		}
		const request = await DirRespService.postTsr(id, data)
		if (request.request.status === 200) {
			toast.success("Le TSR a bien été enregistré")
		} else {
			toast.error("Erreur lors de l'enregistrement du TSR")
		}

	}

	const getInformationTSR = async () => {
		const request = await DirRespService.getInformationTSR(id)
		if (request.request.status === 200) {
			InitialValuesTsr2.g = request.data.g
			InitialValuesTsr2.q = request.data.q
			InitialValuesTsr2.wallThicknessToBeOpened = request.data.destroyWidth
			InitialValuesTsr2.ClearBayWidth = request.data.hopperWidth
			InitialValuesTsr2.clearanceHeight = request.data.hopperHeight
			InitialValuesTsr2.floorLoadStrip = request.data.floorLoad
			InitialValuesTsr2.methodUsed = request.data.method
			InitialValuesTsr2.supportLengthsLeft = request.data.leftSupport
			InitialValuesTsr2.supportLengthsRight = request.data.rightSupport
			InitialValuesTsr2.counterPosts = request.data.counterpoles
			InitialValuesTsr2.lintel = request.data.lintelType
			InitialValuesTsr2.numberOfBackToBackLintels = request.data.lintelSideToSide
			InitialValuesTsr2.QtyTF = request.data.lintelQty
			InitialValuesTsr2.lintelInstallation = request.data.lintelMode
			InitialValuesTsr2.bedBases = request.data.frameSize
			InitialValuesTsr2.stools = request.data.stoolType
			InitialValuesTsr2.stoolsQty = request.data.stoolQty
			InitialValuesTsr2.concreteOrSteelBeamClass = request.data.concreteBeamClass
			InitialValuesTsr2.concreteOrSteelPostClass = request.data.concretePostClass
			InitialValuesTsr2.HABeamUpperAxisSteels = request.data.steelBeamUpSize
			InitialValuesTsr2.HABeamLowerAxisSteels = request.data.steelBeamDownSize
			InitialValuesTsr2.HAPostSteels = request.data.steelPost
			InitialValuesTsr2.counterPostsMade = request.data.actualCounterpoles
			InitialValuesTsr2.counterPostsLength = request.data.actualCounterpolesLength
			InitialValuesTsr2.beamPostAssBoltsQty = request.data.boltQty
			InitialValuesTsr2.beamPostAssBoltsDiameter = request.data.boltDiam
			InitialValuesTsr2.beamPostAssBoltsCLass = request.data.boltClass
			InitialValuesTsr2.beamSplicingPosition = request.data.beamSplicePosition
			InitialValuesTsr2.beamSplicingQty = request.data.beamSpliceBoltQty
			InitialValuesTsr2.beamSplicingDiameter = request.data.beamSpliceDiam
			InitialValuesTsr2.beamSplicingClass = request.data.beamSpliceClass
			InitialValuesTsr2.typicalSplicingPosition = request.data.beamSpliceType
			InitialValuesTsr2.postFootPlate = request.data.beamFootplate
			InitialValuesTsr2.beamWeldingBeads = request.data.beamWeldBead
			InitialValuesTsr2.postWeldingBeads = request.data.postWeldBead
			InitialValuesTsr2.fireStabilityRequired = request.data.fireResistence
			setLoadingTSR(false)
		}
	}

	useEffect(() => {
		setLoadingTSR(true)
		setLoading(true)
		getInformationTSR()
		getProjectsInformations()
	}, [id])


	return (

		<>
			{
				loading || loadingTSR ? <Skeleton height={500}/> : (
					<div className={"mbe-section"}>
						<div className={"section-study-folder-tsr-title"}>
							<TitleIconNavLink title={`${content.project.client.niceNames} - ${content.project.address.street}, ${content.project.address.nicePostcode}, ${content.project.address.city}`} link={HOME_PATH} information={true}
							                  onClick={() => setShowInformation(true)}/>
						</div>

						<TabNavigationStudyFolder theStep={studyFolderStep} id={id} needExecutionClass={content.needExecutionClass} needCCTP={content.needCctp}
						                          needSummary={content.needSummary}/>

						<div className={"section-study-folder-tsr-container"}>
							<div className={"section-study-folder-tsr-container-editor"}>
								<div className={"section-study-folder-tsr-container-editor-title"}>
									<h3 className={"text-primary color-primary"}>TSR</h3>
								</div>

								<Formik initialValues={InitialValuesTsr2} validationSchema={IngTSR2Schema} onSubmit={onSubmit}>
									{({isSubmitting}) => (
										<Form>
											<h4 className={"color-primary text-primary"}>Remplissage à la fin de l'étude par
												l'ingénieur</h4>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"number"} label={"G (ELS, kN/ml)  * ou kN/m² si trémie"}
												                        name={"g"}/>

												<InputLabelErrorMessage type={"number"} label={"Q (ELS, kN/ml)  * ou kN/m² si trémie"}
												                        name={"q"}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Epaisseur de mur à ouvrir (cm)"}
												                        name={"wallThicknessToBeOpened"} keyLabel={"data"} keyValue={"data"}
												                        data={[
													                        {"data": 1},
													                        {"data": 2},
													                        {"data": 3},
													                        {"data": 4},
													                        {"data": 5},
													                        {"data": 6},
													                        {"data": 7},
													                        {"data": 8},
													                        {"data": 9},
													                        {"data": 10},
													                        {"data": 11},
													                        {"data": 12},
													                        {"data": 13},
													                        {"data": 14},
													                        {"data": 15},
													                        {"data": 16},
													                        {"data": 17},
													                        {"data": 18},
													                        {"data": 19},
													                        {"data": 20},
													                        {"data": 21},
													                        {"data": 22},
													                        {"data": 23},
													                        {"data": 24},
													                        {"data": 25},
													                        {"data": 26},
													                        {"data": 27},
													                        {"data": 28},
													                        {"data": 29},
													                        {"data": 30},
													                        {"data": 31},
													                        {"data": 32},
													                        {"data": 33},
													                        {"data": 34},
													                        {"data": 35},
													                        {"data": 36},
													                        {"data": 37},
													                        {"data": 38},
													                        {"data": 39},
													                        {"data": 40},
													                        {"data": 41},
													                        {"data": 42},
													                        {"data": 43},
													                        {"data": 44},
													                        {"data": 45},
													                        {"data": 46},
													                        {"data": 47},
													                        {"data": 48},
													                        {"data": 49},
													                        {"data": 50}
												                        ]
												                        }/>

												<InputLabelErrorMessage type={"number"} label={"Largeur de baie libre (cm)"}
												                        name={"ClearBayWidth"}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"number"} label={"Hauteur de baie libre (cm)"}
												                        name={"clearanceHeight"}/>

												<InputLabelErrorMessage type={"number"} label={"Bande de charge de plancher (cm)"}
												                        name={"floorLoadStrip"}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Méthode retenue"} name={"methodUsed"}
												                        keyLabel={"data"} keyValue={"data"} data={[
													{"data": "Portique métallique sur sommiers"},
													{"data": "Poutre métallique sur sommiers"},
													{"data": "Portique béton sur sommiers"},
													{"data": "Poutre béton sur empochements"},
													{"data": "Portique béton avec tabourets"},
													{"data": "Poutre béton avec tabourets"},
													{"data": "Trémie en plancher béton"},
													{"data": "Trémie en plancher hourdis béton"},
													{"data": "Trémie en plancher métallique avec augets"}
												]}/>

												<InputLabelErrorMessage type={"select"} label={"Longueurs d'appuis gauche (cm)"}
												                        name={"supportLengthsLeft"} keyLabel={"data"} keyValue={"data"}
												                        data={[
													                        {"data": 1},
													                        {"data": 2},
													                        {"data": 3},
													                        {"data": 4},
													                        {"data": 5},
													                        {"data": 6},
													                        {"data": 7},
													                        {"data": 8},
													                        {"data": 9},
													                        {"data": 10},
													                        {"data": 11},
													                        {"data": 12},
													                        {"data": 13},
													                        {"data": 14},
													                        {"data": 15},
													                        {"data": 16},
													                        {"data": 17},
													                        {"data": 18},
													                        {"data": 19},
													                        {"data": 20},
													                        {"data": 21},
													                        {"data": 22},
													                        {"data": 23},
													                        {"data": 24},
													                        {"data": 25},
													                        {"data": 26},
													                        {"data": 27},
													                        {"data": 28},
													                        {"data": 29},
													                        {"data": 30},
													                        {"data": 31},
													                        {"data": 32},
													                        {"data": 33},
													                        {"data": 34},
													                        {"data": 35},
													                        {"data": 36},
													                        {"data": 37},
													                        {"data": 38},
													                        {"data": 39},
													                        {"data": 40},
													                        {"data": 41},
													                        {"data": 42},
													                        {"data": 43},
													                        {"data": 44},
													                        {"data": 45},
													                        {"data": 46},
													                        {"data": 47},
													                        {"data": 48},
													                        {"data": 49},
													                        {"data": 50}
												                        ]}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Longueurs d'appuis droit (cm)"}
												                        name={"supportLengthsRight"} keyLabel={"data"} keyValue={"data"}
												                        data={[
													                        {"data": 1},
													                        {"data": 2},
													                        {"data": 3},
													                        {"data": 4},
													                        {"data": 5},
													                        {"data": 6},
													                        {"data": 7},
													                        {"data": 8},
													                        {"data": 9},
													                        {"data": 10},
													                        {"data": 11},
													                        {"data": 12},
													                        {"data": 13},
													                        {"data": 14},
													                        {"data": 15},
													                        {"data": 16},
													                        {"data": 17},
													                        {"data": 18},
													                        {"data": 19},
													                        {"data": 20},
													                        {"data": 21},
													                        {"data": 22},
													                        {"data": 23},
													                        {"data": 24},
													                        {"data": 25},
													                        {"data": 26},
													                        {"data": 27},
													                        {"data": 28},
													                        {"data": 29},
													                        {"data": 30},
													                        {"data": 31},
													                        {"data": 32},
													                        {"data": 33},
													                        {"data": 34},
													                        {"data": 35},
													                        {"data": 36},
													                        {"data": 37},
													                        {"data": 38},
													                        {"data": 39},
													                        {"data": 40},
													                        {"data": 41},
													                        {"data": 42},
													                        {"data": 43},
													                        {"data": 44},
													                        {"data": 45},
													                        {"data": 46},
													                        {"data": 47},
													                        {"data": 48},
													                        {"data": 49},
													                        {"data": 50}
												                        ]}/>


												<InputLabelErrorMessage type={"select"} label={"Contre poteaux"} name={"counterPosts"}
												                        keyLabel={"data"} keyValue={"data"} data={[
													{"data": "HEA-100"},
													{"data": "HEA-120"},
													{"data": "HEA-140"},
													{"data": "HEA-160"},
													{"data": "HEA-180"},
													{"data": "HEA-200"},
													{"data": "HEA-220"},
													{"data": "HEA-240"},
													{"data": "HEA-260"},
													{"data": "HEA-280"},
													{"data": "HEA-300"},
													{"data": "HEA-320"},
													{"data": "HEA-340"},
													{"data": "HEA-360"},
													{"data": "HEA-400"},
													{"data": "HEB-100"},
													{"data": "HEB-120"},
													{"data": "HEB-140"},
													{"data": "HEB-160"},
													{"data": "HEB-180"},
													{"data": "HEB-200"},
													{"data": "HEB-220"},
													{"data": "HEB-240"},
													{"data": "HEB-260"},
													{"data": "HEB-280"},
													{"data": "HEB-300"},
													{"data": "HEB-320"},
													{"data": "HEB-340"},
													{"data": "HEB-360"},
													{"data": "HEB-400"},
													{"data": "IPN-80"},
													{"data": "IPN-100"},
													{"data": "IPN-120"},
													{"data": "IPN-140"},
													{"data": "IPN-160"},
													{"data": "IPN-180"},
													{"data": "IPN-200"},
													{"data": "IPN-220"},
													{"data": "IPN-240"},
													{"data": "IPN-260"},
													{"data": "IPN-280"},
													{"data": "IPN-300"},
													{"data": "IPN-320"},
													{"data": "IPN-340"},
													{"data": "IPN-360"},
													{"data": "IPN-380"},
													{"data": "IPN-400"},
													{"data": "IPN-450"},
													{"data": "IPN-500"},
													{"data": "IPN-550"},
													{"data": "IPE-80"},
													{"data": "IPE-100"},
													{"data": "IPE-120"},
													{"data": "IPE-140"},
													{"data": "IPE-160"},
													{"data": "IPE-180"},
													{"data": "IPE-200"},
													{"data": "IPE-220"},
													{"data": "IPE-240"},
													{"data": "IPE-270"},
													{"data": "IPE-300"},
													{"data": "IPE-330"},
													{"data": "IPE-360"},
													{"data": "IPE-400"},
													{"data": "IPE-450"},
													{"data": "IPE-550"},
													{"data": "IPE-600"},
													{"data": "UPN-80"},
													{"data": "UPN-100"},
													{"data": "UPN-120"},
													{"data": "UPN-140"},
													{"data": "UPN-160"},
													{"data": "UPN-180"},
													{"data": "UPN-200"},
													{"data": "UPN-220"},
													{"data": "UPN-240"},
													{"data": "UPN-260"},
													{"data": "UPN-280"},
													{"data": "HEM-100"},
													{"data": "HEM-120"},
													{"data": "HEM-140"},
													{"data": "HEM-160"},
													{"data": "HEM-180"},
													{"data": "HEM-200"},
													{"data": "HEM-220"},
													{"data": "HEM-240"},
													{"data": "HEM-260"},
													{"data": "HEM-280"},
													{"data": "HEM-300"},
													{"data": "HEM-320"},
													{"data": "HEM-340"},
													{"data": "HEM-360"},
													{"data": "h20x20"},
													{"data": "h25x20"},
													{"data": "h30x20"},
													{"data": "h35x20"},
													{"data": "h40x20"},
													{"data": "h20x15"},
													{"data": "h25x15"},
													{"data": "h30x15"},
													{"data": "h35x15"},
													{"data": "h40x15"},
													{"data": "h25x25"},
													{"data": "h30x25"},
													{"data": "h35x25"},
													{"data": "h40x25"},
													{"data": "h50x25"},
													{"data": "h25x30"},
													{"data": "h30x30"},
													{"data": "h35x30"},
													{"data": "h40x30"},
													{"data": "h50x30"},
													{"data": "h25x35"},
													{"data": "h30x35"},
													{"data": "h35x35"},
													{"data": "h40x35"},
													{"data": "h50x35"},
													{"data": "h25x40"},
													{"data": "h30x40"},
													{"data": "h35x40"},
													{"data": "h40x40"},
													{"data": "h50x40"}
												]
												}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Linteau (type / lxh)"} name={"lintel"}
												                        keyLabel={"data"} keyValue={"data"} data={[
													{"data": "HEA-100"},
													{"data": "HEA-120"},
													{"data": "HEA-140"},
													{"data": "HEA-160"},
													{"data": "HEA-180"},
													{"data": "HEA-200"},
													{"data": "HEA-220"},
													{"data": "HEA-240"},
													{"data": "HEA-260"},
													{"data": "HEA-280"},
													{"data": "HEA-300"},
													{"data": "HEA-320"},
													{"data": "HEA-340"},
													{"data": "HEA-360"},
													{"data": "HEA-400"},
													{"data": "HEB-100"},
													{"data": "HEB-120"},
													{"data": "HEB-140"},
													{"data": "HEB-160"},
													{"data": "HEB-180"},
													{"data": "HEB-200"},
													{"data": "HEB-220"},
													{"data": "HEB-240"},
													{"data": "HEB-260"},
													{"data": "HEB-280"},
													{"data": "HEB-300"},
													{"data": "HEB-320"},
													{"data": "HEB-340"},
													{"data": "HEB-360"},
													{"data": "HEB-400"},
													{"data": "IPN-80"},
													{"data": "IPN-100"},
													{"data": "IPN-120"},
													{"data": "IPN-140"},
													{"data": "IPN-160"},
													{"data": "IPN-180"},
													{"data": "IPN-200"},
													{"data": "IPN-220"},
													{"data": "IPN-240"},
													{"data": "IPN-260"},
													{"data": "IPN-280"},
													{"data": "IPN-300"},
													{"data": "IPN-320"},
													{"data": "IPN-340"},
													{"data": "IPN-360"},
													{"data": "IPN-380"},
													{"data": "IPN-400"},
													{"data": "IPN-450"},
													{"data": "IPN-500"},
													{"data": "IPN-550"},
													{"data": "IPE-80"},
													{"data": "IPE-100"},
													{"data": "IPE-120"},
													{"data": "IPE-140"},
													{"data": "IPE-160"},
													{"data": "IPE-180"},
													{"data": "IPE-200"},
													{"data": "IPE-220"},
													{"data": "IPE-240"},
													{"data": "IPE-270"},
													{"data": "IPE-300"},
													{"data": "IPE-330"},
													{"data": "IPE-360"},
													{"data": "IPE-400"},
													{"data": "IPE-450"},
													{"data": "IPE-550"},
													{"data": "IPE-600"},
													{"data": "UPN-80"},
													{"data": "UPN-100"},
													{"data": "UPN-120"},
													{"data": "UPN-140"},
													{"data": "UPN-160"},
													{"data": "UPN-180"},
													{"data": "UPN-200"},
													{"data": "UPN-220"},
													{"data": "UPN-240"},
													{"data": "UPN-260"},
													{"data": "UPN-280"},
													{"data": "HEM-100"},
													{"data": "HEM-120"},
													{"data": "HEM-140"},
													{"data": "HEM-160"},
													{"data": "HEM-180"},
													{"data": "HEM-200"},
													{"data": "HEM-220"},
													{"data": "HEM-240"},
													{"data": "HEM-260"},
													{"data": "HEM-280"},
													{"data": "HEM-300"},
													{"data": "HEM-320"},
													{"data": "HEM-340"},
													{"data": "HEM-360"},
													{"data": "h20x20"},
													{"data": "h25x20"},
													{"data": "h30x20"},
													{"data": "h35x20"},
													{"data": "h40x20"},
													{"data": "h20x15"},
													{"data": "h25x15"},
													{"data": "h30x15"},
													{"data": "h35x15"},
													{"data": "h40x15"},
													{"data": "h25x25"},
													{"data": "h30x25"},
													{"data": "h35x25"},
													{"data": "h40x25"},
													{"data": "h50x25"},
													{"data": "h25x30"},
													{"data": "h30x30"},
													{"data": "h35x30"},
													{"data": "h40x30"},
													{"data": "h50x30"},
													{"data": "h25x35"},
													{"data": "h30x35"},
													{"data": "h35x35"},
													{"data": "h40x35"},
													{"data": "h50x35"},
													{"data": "h25x40"},
													{"data": "h30x40"},
													{"data": "h35x40"},
													{"data": "h40x40"},
													{"data": "h50x40"}
												]}/>


												<InputLabelErrorMessage type={"select"} label={"Nombre de linteaux dos à dos"}
												                        name={"numberOfBackToBackLintels"} keyLabel={"data"}
												                        keyValue={"data"}
												                        data={[
													                        {"data": 1},
													                        {"data": 2},
													                        {"data": 3},
													                        {"data": 4},
													                        {"data": 5},
													                        {"data": 6},
													                        {"data": 7},
													                        {"data": 8},
													                        {"data": 9},
													                        {"data": 10},
												                        ]
												                        }/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Qté TF"} name={"QtyTF"} keyLabel={"data"}
												                        keyValue={"data"} data={[
													{"data": 1},
													{"data": 2},
													{"data": 3},
													{"data": 4},
													{"data": 5},
													{"data": 6},
													{"data": 7},
													{"data": 8},
													{"data": 9},
													{"data": 10}
												]}/>


												<InputLabelErrorMessage type={"select"} label={"Mode de pose linteau"}
												                        name={"lintelInstallation"}
												                        keyLabel={"data"} keyValue={"data"} data={[
													{"data": "Bi - encastré"},
													{"data": "Bi - articulé"}
												]
												}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Sommiers (e*L) (cm)"} name={"bedBases"}
												                        keyLabel={"data"} keyValue={"data"} data={
													[
														{"data": "10x10"},
														{"data": "10x15"},
														{"data": "10x20"},
														{"data": "10x25"},
														{"data": "10x30"},
														{"data": "15x10"},
														{"data": "15x15"},
														{"data": "15x20"},
														{"data": "15x25"},
														{"data": "15x30"},
														{"data": "15x35"},
														{"data": "20x10"},
														{"data": "20x15"},
														{"data": "20x20"},
														{"data": "20x25"},
														{"data": "20x30"},
														{"data": "20x35"},
														{"data": "20x40"},
														{"data": "20x45"},
														{"data": "25x10"},
														{"data": "25x15"},
														{"data": "25x20"},
														{"data": "25x25"},
														{"data": "25x30"},
														{"data": "25x35"},
														{"data": "25x40"},
														{"data": "25x45"},
														{"data": "25x50"},
														{"data": "30x15"},
														{"data": "30x20"},
														{"data": "30x25"},
														{"data": "30x30"},
														{"data": "30x35"},
														{"data": "30x40"},
														{"data": "30x45"},
														{"data": "30x50"},
														{"data": "35x10"},
														{"data": "35x15"},
														{"data": "35x20"},
														{"data": "35x25"},
														{"data": "35x30"},
														{"data": "35x35"},
														{"data": "35x40"},
														{"data": "35x45"},
														{"data": "35x50"},
														{"data": "40x10"},
														{"data": "40x15"},
														{"data": "40x20"},
														{"data": "40x25"},
														{"data": "40x30"},
														{"data": "40x35"},
														{"data": "40x40"},
														{"data": "40x45"},
														{"data": "40x50"}
													]

												}/>


												<InputLabelErrorMessage type={"select"} label={"Tabourets (type)"} name={"stools"}
												                        keyLabel={"data"}
												                        keyValue={"data"} data={[
													{"data": "HEA-100"},
													{"data": "HEA-120"},
													{"data": "HEA-140"},
													{"data": "HEA-160"},
													{"data": "HEA-180"},
													{"data": "HEA-200"},
													{"data": "HEA-220"},
													{"data": "HEA-240"},
													{"data": "HEA-260"},
													{"data": "HEA-280"},
													{"data": "HEA-300"},
													{"data": "HEA-320"},
													{"data": "HEA-340"},
													{"data": "HEA-360"},
													{"data": "HEA-400"},
													{"data": "HEB-100"},
													{"data": "HEB-120"},
													{"data": "HEB-140"},
													{"data": "HEB-160"},
													{"data": "HEB-180"},
													{"data": "HEB-200"},
													{"data": "HEB-220"},
													{"data": "HEB-240"},
													{"data": "HEB-260"},
													{"data": "HEB-280"},
													{"data": "HEB-300"},
													{"data": "HEB- 320"},
													{"data": "HEB-340"},
													{"data": "HEB-360"},
													{"data": "HEB-400"},
													{"data": "IPN-80"},
													{"data": "IPN-100"},
													{"data": "IPN-120"},
													{"data": "IPN-140"},
													{"data": "IPN-160"},
													{"data": "IPN-180"},
													{"data": "IPN-200"},
													{"data": "IPN-220"},
													{"data": "IPN-240"},
													{"data": "IPN-260"},
													{"data": "IPN-280"},
													{"data": "IPN-300"},
													{"data": "IPN-320"},
													{"data": "IPN-340"},
													{"data": "IPN-360"},
													{"data": "IPN-380"},
													{"data": "IPN-400"},
													{"data": "IPN-450"},
													{"data": "IPN-500"},
													{"data": "IPN-550"},
													{"data": "IPE-80"},
													{"data": "IPE-100"},
													{"data": "IPE-120"},
													{"data": "IPE-140"},
													{"data": "IPE-160"},
													{"data": "IPE-180"},
													{"data": "IPE-200"},
													{"data": "IPE-220"},
													{"data": "IPE-240"},
													{"data": "IPE-270"},
													{"data": "IPE-300"},
													{"data": "IPE-330"},
													{"data": "IPE-360"},
													{"data": "IPE-400"},
													{"data": "IPE-450"},
													{"data": "IPE-550"},
													{"data": "IPE-600"}
												]

												}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Tabourets quantité"} name={"stoolsQty"}
												                        keyLabel={"data"} keyValue={"data"} data={
													[
														{"data": 1},
														{"data": 2},
														{"data": 3},
														{"data": 4},
														{"data": 5},
														{"data": 6},
														{"data": 7},
														{"data": 8},
														{"data": 9},
														{"data": 10},
														{"data": 11},
														{"data": 12},
														{"data": 13},
														{"data": 14},
														{"data": 15},
														{"data": 16},
														{"data": 17},
														{"data": 18},
														{"data": 19},
														{"data": 20},
														{"data": 21},
														{"data": 22},
														{"data": 23},
														{"data": 24},
													]

												}/>


												<InputLabelErrorMessage type={"select"} label={"Classe de béton ou d'acier poutre"}
												                        name={"concreteOrSteelBeamClass"} keyLabel={"data"}
												                        keyValue={"data"}
												                        data={[
													                        {"data": "S235"},
													                        {"data": "S275"},
													                        {"data": "C20/25"},
													                        {"data": "C25/30"},
													                        {"data": "C30/35"},
													                        {"data": "C35/40"},
													                        {"data": "C40/45"}
												                        ]
												                        }/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Classe de béton ou d'acier poteau"}
												                        name={"concreteOrSteelPostClass"} keyLabel={"data"}
												                        keyValue={"data"}
												                        data={
													                        [
														                        {"data": "S235"},
														                        {"data": "S275"},
														                        {"data": "C20/25"},
														                        {"data": "C25/30"},
														                        {"data": "C30/35"},
														                        {"data": "C35/40"},
														                        {"data": "C40/45"}
													                        ]

												                        }/>


												<InputLabelErrorMessage type={"number"} label={"Aciers HA poutre axe supérieur (cm²)"}
												                        name={"HABeamUpperAxisSteels"}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>


												<InputLabelErrorMessage type={"number"} label={"Aciers HA poutre axe inférieur (cm²)"}
												                        name={"HABeamLowerAxisSteels"}/>
												<InputLabelErrorMessage type={"select"} label={"Aciers HA poutre (cm²)"}
												                        name={"HAPostSteels"}
												                        keyLabel={"data"} keyValue={"data"} data={[
													{"data": "4 HA8"},
													{"data": "6 HA8"},
													{"data": "8 HA8"},
													{"data": "4 HA10"},
													{"data": "6 HA10"},
													{"data": "8 HA10"},
													{"data": "4 HA12"},
													{"data": "6 HA12"},
													{"data": "8 HA12"},
													{"data": "4 HA14"},
													{"data": "6 HA14"},
													{"data": "8 HA14"},
													{"data": "4 HA16"},
													{"data": "6 HA16"},
													{"data": "8 HA16"},
													{"data": "4 HA20"},
													{"data": "6 HA20"},
													{"data": "8 HA20"}
												]
												}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Contre poteaux réalisés"}
												                        name={"counterPostsMade"}
												                        keyLabel={"data"} keyValue={"data"} data={[
													{"data": "Sous fer"},
													{"data": "Contre fer"}
												]}/>
												<InputLabelErrorMessage type={"number"} label={"Contre poteaux longueur"}
												                        name={"counterPostsLength"}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Quantité boulons d'Ass. poutre/poteau"}
												                        name={"beamPostAssBoltsQty"} keyLabel={"data"} keyValue={"data"}
												                        data={[
													                        {"data": 2},
													                        {"data": 4},
													                        {"data": 6},
													                        {"data": 8},
													                        {"data": 10},
													                        {"data": 12}
												                        ]}/>
												<InputLabelErrorMessage type={"select"} label={"Diamètre boulons d'Ass. poutre/poteau"}
												                        name={"beamPostAssBoltsDiameter"} keyLabel={"data"}
												                        keyValue={"data"}
												                        data={
													                        [
														                        {"data": "M6"},
														                        {"data": "M8"},
														                        {"data": "M10"},
														                        {"data": "M12"},
														                        {"data": "M14"},
														                        {"data": "M16"},
														                        {"data": "M18"},
														                        {"data": "M20"},
														                        {"data": "M22"},
														                        {"data": "M24"},
													                        ]
												                        }/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Classe boulons d'Ass. poutre/poteau"}
												                        name={"beamPostAssBoltsCLass"} keyLabel={"data"} keyValue={"data"}
												                        data={[
													                        {"data": "4-6"},
													                        {"data": "4-8"},
													                        {"data": "5-6"},
													                        {"data": "5-8"},
													                        {"data": "6-6"},
													                        {"data": "6-8"},
													                        {"data": "8-8"},
													                        {"data": "10-9"}
												                        ]}/>
												<InputLabelErrorMessage type={"select"} label={"Eclissage de poutre position"}
												                        name={"beamSplicingPosition"} keyLabel={"data"} keyValue={"data"}
												                        data={
													                        [
														                        {"data": "Aucun"},
														                        {"data": "10%"},
														                        {"data": "20%"},
														                        {"data": "30%"},
														                        {"data": "40%"},
														                        {"data": "50%"}
													                        ]
												                        }/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Quantité eclissage de poutre"}
												                        name={"beamSplicingQty"} keyLabel={"data"} keyValue={"data"} data={[
													{"data": 4},
													{"data": 6},
													{"data": 8},
													{"data": 10},
													{"data": 12}
												]}/>
												<InputLabelErrorMessage type={"select"} label={"Diamètre eclissage de poutre"}
												                        name={"beamSplicingDiameter"} keyLabel={"data"} keyValue={"data"}
												                        data={
													                        [
														                        {"data": "M6"},
														                        {"data": "M8"},
														                        {"data": "M10"},
														                        {"data": "M12"},
														                        {"data": "M14"},
														                        {"data": "M16"},
														                        {"data": "M18"},
														                        {"data": "M20"},
														                        {"data": "M22"},
														                        {"data": "M24"},
													                        ]
												                        }/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Classe eclissage de poutre"}
												                        name={"beamSplicingClass"} keyLabel={"data"} keyValue={"data"}
												                        data={[
													                        {"data": "4-6"},
													                        {"data": "4-8"},
													                        {"data": "5-6"},
													                        {"data": "5-8"},
													                        {"data": "6-6"},
													                        {"data": "6-8"},
													                        {"data": "8-8"},
													                        {"data": "10-9"}
												                        ]}/>
												<InputLabelErrorMessage type={"select"} label={"Eclissage type"}
												                        name={"typicalSplicingPosition"} keyLabel={"data"} keyValue={"data"}
												                        data={
													                        [
														                        {"data": "Boulonné"},
														                        {"data": "Soudé"},
														                        {"data": "Plats rapportés"}
													                        ]
												                        }/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Plat pied de poteau (lxpxe) (cm)"}
												                        name={"postFootPlate"} keyLabel={"data"} keyValue={"data"} data={[
													{"data": "5x10x1"},
													{"data": "5x15x1"},
													{"data": "5x20x1"},
													{"data": "5x25x1"},
													{"data": "5x30x1"},
													{"data": "10x10x1"},
													{"data": "10x15x1"},
													{"data": "10x20x1"},
													{"data": "10x25x1"},
													{"data": "10x30x1"},
													{"data": "15x15x1"},
													{"data": "15x20x1"},
													{"data": "15x25x1"},
													{"data": "15x30x1"},
													{"data": "20x10x1"},
													{"data": "20x20x1"},
													{"data": "20x25x1"},
													{"data": "20x30x1"},
													{"data": "25x10x1"},
													{"data": "25x15x1"},
													{"data": "25x25x1"},
													{"data": "25x30x1"},
													{"data": "30x10x1"},
													{"data": "30x15x1"},
													{"data": "30x20x1"},
													{"data": "30x30x1"}
												]
												}/>
												<InputLabelErrorMessage type={"select"} label={"Cordons de soudure sur poutre (mm)"}
												                        name={"beamWeldingBeads"} keyLabel={"data"} keyValue={"data"} data={
													[
														{"data": 3},
														{"data": 4},
														{"data": 5},
														{"data": 6},
														{"data": 7},
														{"data": 8},
														{"data": 9},
														{"data": 10},
														{"data": 12},
														{"data": 14},
													]
												}/>

											</div>

											<div className={"form-row-element form-row-two-elements"}>

												<InputLabelErrorMessage type={"select"} label={"Plat pied de poteau (lxpxe) (cm)"}
												                        name={"postWeldingBeads"} keyLabel={"data"} keyValue={"data"}
												                        data={[
													                        {"data": 3},
													                        {"data": 4},
													                        {"data": 5},
													                        {"data": 6},
													                        {"data": 7},
													                        {"data": 8},
													                        {"data": 9},
													                        {"data": 10},
													                        {"data": 12},
													                        {"data": 14}
												                        ]
												                        }/>
												<InputLabelErrorMessage type={"select"} label={"Stabilité au feu demandée"}
												                        name={"fireStabilityRequired"} keyLabel={"data"} keyValue={"data"}
												                        data={
													                        [
														                        {"data": "R 15"},
														                        {"data": "R 30"},
														                        {"data": "R 60"},
														                        {"data": "R 90"},
														                        {"data": "R 120"},
														                        {"data": "R 180"},
														                        {"data": "R 240"},
													                        ]
												                        }/>

											</div>

											<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
											              title={"Sauvegarder"}/>
										</Form>

									)}

								</Formik>

							</div>
							<StudyFolderMenu content={content} projectId={content.project.id}/>
						</div>
						<PopUpInformations
							show={showInformation}
							onClick={() => setShowInformation(false)}
							large={false}
							title={"Informations projet"}
							content={content}
						/>
					</div>
				)
			}
		</>


	);
};

export default StudyFolderTSR2;
