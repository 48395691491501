import React, {useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import InputTextareaErrorMessage from "../../../Atomes/Formik/InputTextareaErrorMessage";
import {
	initialValuesIngCollaboratorProject
} from "../../../../config/InitialValues/Engineer/initialValuesIngCollaboratorProject";
import {ingCollaboratorProjectSchema} from "../../../../schemas/Engineer/ingCollaboratorProjectSchema";
import InputLabelErrorMessageOnChange from "../../../Atomes/Formik/onChangeSelect/InputLabelErrorMessageOnChange";
import InputLabelTwoSelectErrorMessageOnChange
	from "../../../Atomes/Formik/onChangeSelect/InputLabelTwoSelectErrorMessageOnChange";
import moment from "moment";
import {dataRolesIngSelectCollaborators} from "../../../../utils/UserRolesUtils";
import DirRespService from "../../../../services/Ing/DirResp/DirRespService";
import {toast} from "react-toastify";

const FormIngAssignProject = ({collaborator, allProjects, allRoles, setShowPopUpAssignProject, updateDataProjet}) => {

	const [showDateVisite, setShowDateVisite] = useState("")
	const [showDatePrincipe, setShowDatePrincipe] = useState("")
	const [showDateRendu, setShowDateRendu] = useState("")
	const [showBtnAssignRole, setShowBtnAssignRole] = useState(false)
	const [roleCount, setRoleCount] = useState(1);


	const onChangeProject = (e, setFieldValue) => {
		const selectedValue = e.target.value;
		setFieldValue('selectProject', selectedValue);

		const selectedProject = parseInt(e.target.value);
		const project = allProjects.filter((project) => project.id === selectedProject)[0]

		setShowDateVisite(project.visit ? moment(project.visit.date).format('DD/MM/YYYY') : "N/A");
		setShowDatePrincipe(/*project.principe_structure.date*/"N/A");
		setShowDateRendu(/*project.rendu.date*/ "N/A");
	}

	const [roleSelected, setRoleSelected] = useState()

	const onChangeSelectRole = (e, setFieldValue) => {
		const selectedValue = e.target.value;
		setFieldValue(`roleAttribue`, selectedValue);

		e.target.name === `roleAttribue` ? setShowBtnAssignRole(true) : null
		for (let i = 1; i <= 4; i++) {
			if (e.target.name === `roleAttribue${i}`) {
				setRoleSelected(allRoles.filter(roles => roles.id === e.target.value)[0])
			}
		}
	}

	allProjects.map((project) => {
		project.niceReference = project.reference + " - " + project.client.niceNames
	})

	const handleIngFieldClose = () => {
		setRoleCount(roleCount - 1)
		setShowBtnAssignRole(true)

		for (let i = 1; i <= 4; i++) {
			const selectAttribue = document.querySelector(`select[name = 'roleAttribue']`)
			const crossAttribue = document.querySelector(`select[name = 'roleAttribue'] + .button-close-field`)
			if (roleCount === i) {
				selectAttribue.disabled = false
				if (crossAttribue) {
					crossAttribue.style.display = "block"
				}
			}
		}
	};
	const onSubmit = async (values, actions) => {
		let data = {
			projectIri: `/api/projects/${values.selectProject}`,
			userIri: `/api/users/${collaborator.id}`,
			startAt: values.dateStart,
			endAt: values.dateEnd,
			estimatedDuration: parseFloat(values.roleHour),
			categoryType: values.roleAttribue,
			commentContent: values.commentaireProjectCollaborator,
		}
		let request = await DirRespService.postAssignements(data)

		if (request.request.status === 201) {
			//setStateRequest("success add")

			actions.resetForm();
			setShowPopUpAssignProject()
			toast.success("Le projet a bien été assigné au collaborateur", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
			})
			updateDataProjet()
		} else {
			console.log("error")
			//setStateRequest("error")
		}

	}
	const roleSelect = dataRolesIngSelectCollaborators

	return (
		<Formik
			initialValues={initialValuesIngCollaboratorProject}
			validationSchema={ingCollaboratorProjectSchema}
			onSubmit={onSubmit}
		>

			{({isSubmitting, setFieldValue}) => (
				<Form className={"pop-up-form-container-collaborator"}>
					<div className={"card-collaborator-title"}>
						<img src={`${process.env.REACT_APP_PROD_URL}${collaborator.urlAvatar}`}/>
						<div className={"card-collaborator-title-text"}>
							<h4 className={"color-primary"}>{collaborator.niceNames}</h4>
							<p className={"text-primary color-primary"}>{collaborator.job}</p>
						</div>
					</div>
					<InputLabelErrorMessageOnChange
						type={"select"}
						name={"selectProject"}
						className={"select-custom-primary"}
						label={"Sélectionner un projet"}
						keyLabel={"niceReference"}
						keyValue={"id"}
						data={allProjects}
						onChange={(e) => onChangeProject(e, setFieldValue)}
					/>
					<div className="pop-up-form-container-team-dates">
						<div className="pop-up-form-container-team-dates-item">
							<p className="text-micro paragraph_bold color-dark-grey">Date de la visite</p>
							<p className="pop-up-form-container-team-dates-item-date text-primary color-primary">{showDateVisite}</p>
						</div>
						<div className="pop-up-form-container-team-dates-item">
							<p className="text-micro paragraph_bold color-dark-grey">Validation principe structure</p>
							<p
								className="pop-up-form-container-team-dates-item-date text-primary color-primary">{showDatePrincipe}</p>
						</div>
						<div className="pop-up-form-container-team-dates-item">
							<p className="text-micro paragraph_bold color-dark-grey">Date de rendu</p>
							<p className="pop-up-form-container-team-dates-item-date text-primary color-primary">{showDateRendu}</p>
						</div>
					</div>
					<div>
						<div id="cardAddProjectAddRole" className={"flex-gap"}>
							<InputLabelTwoSelectErrorMessageOnChange type={"select"}
							                                         closeField={handleIngFieldClose}
							                                         onChangeSelect={(e, id) => onChangeSelectRole(e, setFieldValue)}
							                                         keyLabel={"label"}
							                                         keyValue={"value"}
							                                         keyLabelHours={"label"}
							                                         keyValueHours={"value"}
							                                         keyLabelSupport={"label"}
							                                         keyValueSupport={"value"}
							                                         nameRole={`roleAttribue`}
							                                         nameHour={`roleHour`}
							                                         nameSupport={`roleSupport`}
							                                         label={`Rôle attribué`}
							                                         data={roleSelect}
							/>
							<label htmlFor={"dateStart"} className={"text-micro paragraph_bold color-dark-grey margintop-10"}>Date
								de
								début</label>
							<Field type={"date"} name={'dateStart'} className={"input-dark-blue"}/>
							<ErrorMessage name={"dateStart"} component={"p"} className={"color-error text-primary "}/>
							<label htmlFor={"dateEnd"} className={"text-micro paragraph_bold color-dark-grey margintop-10"}>Date
								de
								fin</label>
							<Field type={"date"} name={"dateEnd"} className={"input-dark-blue"}/>
							<ErrorMessage name={"dateEnd"} component={"p"} className={"color-error text-primary"}/>
						</div>
					</div>
					<InputTextareaErrorMessage
						name={"commentaireProjectCollaborator"}
						placeholder={"Nouveau commentaire"}
						className={"commentaire-textarea"}
					/>
					<ButtonSubmit title={"Attribuer"}
					              isActive={false}
					              styleButton={"btn-default btn-default-secondary plain"}
					/>
				</Form>)}
		</Formik>
	);
};

export default FormIngAssignProject;
