import fileDownload from 'js-file-download';
import { ConvertObjectToUrlParams } from "../utils/StringUtils";
import axios from "axios";

const exportFile = async (url, filename, param, fileType) => {
    const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
    axios.get(process.env.REACT_APP_PROD_API_URL + ConvertObjectToUrlParams(`exports${url}`, param), {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
        responseType: 'blob'
    }).then(response => {
        fileDownload(response.data, `${filename}.${fileType}`)
    }).catch((error) => {
        console.log(error);
    })
}

const exportMedia = async (endpoint, filename, fileType) => {
    const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
    axios.get(process.env.REACT_APP_PROD_URL + endpoint, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
        responseType: 'blob'
    }).then(response => {
        fileDownload(response.data, `${filename}.${fileType}`)
    }).catch((error) => {
        console.log(error);
    })
}


const exportFileService = {
    exportFile,
    exportMedia
}

export default exportFileService;
