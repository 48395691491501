import * as Yup from "yup";

export const SchemaProfileINGAccount = Yup.object().shape({
  gender: Yup.string().required("Veuillez renseigner une civilité"),
  lastname: Yup.string().required("Veuillez renseigner votre nom"),
  firstname: Yup.string().required("Veuillez renseigner votre prénom"),
  email: Yup.string().email("Veuillez renseigner une adresse e-mail valide").required("Veuillez renseigner votre adresse e-mail"),
  phone: Yup.string()
    .min(10, "La longueur doit être de minimum 10 caractères")
    .max(14, "La longueur doit être de maximum 14 caractères")
    .required('Veuillez renseigner un numéro de téléphone'),
});