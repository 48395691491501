import React from "react";
import {Link} from "react-router-dom";

export const CALLBACK_REQUEST_COLUMNS = [
	{
		Header: "Nom client",
		accessor: "lastname",
		Cell: cellInfo => {
			return (
				<span>{cellInfo.row.original.firstname} {cellInfo.row.original.lastname}</span>
			)
		}
	},
	{
		Header: "Typologie projet",
		accessor: "type"
	},
	{
		Header: "Téléphone",
		accessor: "phone"
	},
	{
		Header: "Adresse e-mail",
		accessor: "email"
	},
	{
		Header: "Description du projet",
		accessor: "message",
	}
]