import { Editor } from "react-draft-wysiwyg";
import React, {useContext, useEffect, useState} from "react";
import {StateRequest} from "../../../../views/Pages/Admin/Dpgf/View";
import {InitialValuesAddDpgf} from "../../../../config/InitialValues/Admin/InitialValuesAddDpgf";
import {Form, Formik} from "formik";
import {AddDPGFSchema} from "../../../../schemas/Admin/DPGF/AddDPGFSchema";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import draftToHtmlPuri from "draftjs-to-html";
import {convertFromHTML, convertToRaw, EditorState} from "draft-js";
import {initialValuesEditPrestation} from "../../../../config/InitialValues/InitialValuesEditPrestation";
import ContentState from "draft-js/lib/ContentState";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import DpgfService from "../../../../services/DpgfService";
import CctpService from "../../../../services/CctpService";

const FormAddDPGF = ({setAddDpgf}) => {
	const { setStateRequest } = useContext(StateRequest);
	const [initialValueTitle, setInitialValueTitle] = useState("");
	const [cctp, setCctp] = useState([])

	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromBlockArray(
				convertFromHTML(initialValueTitle)
			)
		)
	);
	const onSubmit = async (values, actions) => {
		values.content = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()))
		values.cctpIri = `/api/cctps/${values.cctpIri}`
		values.vta = parseFloat(values.vta)
		actions.resetForm()
		const request = await DpgfService.postDpgf(values)
		setAddDpgf(false)
		if (request.request.status === 201) {
			setStateRequest("success add")
		} else {
			setStateRequest("error")
		}
	}

	const handleChangeEditorState = (valueOfEditor) => {
		setEditorState(valueOfEditor)
		let value = draftToHtmlPuri(convertToRaw(valueOfEditor.getCurrentContent()))
		InitialValuesAddDpgf.content = value
	}

	const getCCPT = async () => {
		const request = await CctpService.getCctp({pagination: "false"})
		if (request.request.status === 200) {
			const cctpTries = request.data['hydra:member'].sort((a, b) => {
				return a.position.localeCompare(b.position, 'fr', {numeric: true});
			});
			setCctp(cctpTries)
		} else {
			setStateRequest("error")
		}
	}

	InitialValuesAddDpgf.content = ""
	InitialValuesAddDpgf.cctpIri = ""
	InitialValuesAddDpgf.total = ""
	InitialValuesAddDpgf.vta = ""
	InitialValuesAddDpgf.unit = ""

	const UNITS = [{value:'U'}, {value:'M2'}, {value:'M3'}, {value:'KG'}, {value:'ML'}, {value:'ENS.'}, {value:'EUR'}]

	useEffect(() => {
		getCCPT()
	}, [])

	return (

	<>
		<Formik
		 			initialValues={InitialValuesAddDpgf}
				  validationSchema={AddDPGFSchema}
				  onSubmit={onSubmit}
		>
			{({isSubmitting}) => (
				<Form>
					<div className={"form-row-element"}>
						<div className={"form-row-label-input"}>
							<label className={"text-micro paragraph_bold color-dark-grey"}>Désignation ouvrages
								<Editor
									initialEditorState={editorState}
									editorState={editorState}
									wrapperClassName="demo-wrapper"
									localization={{
										locale: 'fr',
									}}
									toolbar={{
										options: ["inline", "colorPicker", 'link', "list"],
										inline: {
											options: ["bold", "italic", "underline", "strikethrough"],
										},
										colorPicker: {
											colors: ["#000000", "#ffffff", "#090754", "#4AA9C2", "#9DDFF1", "#648DE5", "#c13e39", "#5FD086", "#eed128"],
										}
									}}
									onEditorStateChange={(e) => {
										handleChangeEditorState(e)
									}
									}
								/>
							</label>
						</div>
					</div>

					<div className={"form-row-element form-row-two-elements"}>
						<InputLabelErrorMessage type={"select"} label={"Unité"} name={"unit"} data={UNITS} keyLabel={"value"} keyValue={"value"} />
						<InputLabelErrorMessage type={"select"} label={"Référence CCTP"} name={"cctpIri"} data={cctp} keyValue={"id"} keyLabel={"position"} />
					</div>

					<div className={"form-row-element form-row-two-elements"}>
						<InputLabelErrorMessage type={"number"} label={"Total HT"} name={"total"} />
						<InputLabelErrorMessage type={"select"} label={"TVA"} name={"vta"} data={[{label: "10%", value:0.1}, {label:"20%", value:0.2}]} keyValue={"value"} keyLabel={"label"} />
					</div>

					<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
					              title={"Ajouter le DPGF"} />
				</Form>
			)}
		</Formik>
	</>
	)
}

export default FormAddDPGF;
