import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import ProjectHeader from '../../Partials/ProjectHeader';
import ProjectNav from '../../Partials/ProjectNav';
import ProjectSectionMarketplace from '../../Partials/SectionMarketplace';

import { ProjectStageService } from '../../../../services/ProjectStageService';
import exportFileService from '../../../../services/ExportFileService';

const ProjectStageStudy = () => {

  const navigate = useNavigate();
  const { projectId } = useParams();
  const [projectStepVerificator, setProjectStepVerificator] = useState(false);
  const [project, setProject] = useState(null);
  const [projectVisite, setProjectVisite] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectData();
  }, []);

  const getProjectData = async () => {
    let projectInfos = await ProjectStageService.getSingleProject(projectId);
    if (projectInfos.stage === 'ING_study' || projectInfos.stage === 'ING_verification-study' || projectInfos.stage === 'ING_validation-study') {
      setProjectStepVerificator(true);
      setProject(projectInfos);
      if (projectInfos.visit) {
        setProjectVisite({
          date: moment(projectInfos.visit.startAt).format('DD/MM/YYYY'),
          time: moment(projectInfos.visit.startAt).format('HH:mm') + ' - ' + moment(projectInfos.visit.endAt).format('HH:mm'),
          address: projectInfos.address.street + ' ' + projectInfos.address.postcode + ' ' + projectInfos.address.city,
        });
      }
    } else {
      navigate("/projet/" + projectId, { replace: true });
    }
  }

  const downloadAvisDePassage = (endpoint) => {
    exportFileService.exportMedia(endpoint, 'avis-de-passage', "pdf")
    toast.success('Téléchargement en cours');
  }

  return (
    <>
      {
        !projectStepVerificator ? (
          <>
            <Skeleton height={300} />
            <Skeleton height={40} />
          </>
        ) : (
          <>
            <ProjectHeader
              project={project}
            />

            <div className={"project-container"}>
              <ProjectNav
                step={4}
                visit={project.visitType}
              />

              <div className="project-container-step__header marketplace">
                <div>
                  <h3>La rédaction de votre dossier d'étude structure est en cours.</h3>
                  {
                    projectVisite ? (
                      <p className="text-primary">Votre visite a eu lieu le {projectVisite.date} de {projectVisite.time} au {projectVisite.address}. Vous pouvez retrouver votre avis de passage ainsi que vos documents dans votre espace "Documents".</p>
                    ) : (
                      <p className="text-primary">Votre dossier d'étude structure est en cours de rédaction</p>
                    )
                  }
                </div>
                {
                  project.reportingNoticeIri && (
                    <div className='telecharger-mon-avis-de-passage'>
                      <button
                        onClick={() => downloadAvisDePassage(project.reportingNoticeIri)}
                        className="btn-default btn-default-secondary"
                      >
                        Télécharger mon avis de passage</button>
                    </div>
                  )
                }
              </div>

              <ProjectSectionMarketplace />

            </div>
          </>
        )
      }
    </>
  )
}

export default ProjectStageStudy;