import React, {useContext} from 'react';
import PartnerService from "../../../services/PartnerService";
import {StateRequest} from "../../../views/Pages/Admin/Partners/Partners";

const PopUpPartnersAdmin = ({id, identifiant, onClick}) => {

	const {stateRequest, setStateRequest} = useContext(StateRequest);
	const handleSubmit = async () => {
		const request = await PartnerService.deletePartner(id)
		onClick()
		if (request.request.status == 204) {
			setStateRequest('success delete')
		} else {
			setStateRequest('error')
		}
	}

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"}/>
				<img src={"/images/medias/popup.svg"} alt="test"/>
				<h4>Voulez-vous supprimer le lien partenaire de {identifiant} ?</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={onClick}>Non</button>
					<button className={"btn btn-delete"} onClick={handleSubmit}>Oui</button>
				</div>
			</div>
		</div>
	);
}

export default PopUpPartnersAdmin;
