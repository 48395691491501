import api from "./APIService";
import { ConvertObjectToUrlParams } from "../utils/StringUtils";

const getProjects = async (params) => {
  return await api.getAllData(ConvertObjectToUrlParams(`projects/context/client`, params))
}

const getPartnersActive = async (param) => {
  return await api.getAllData(`partners?active=true`)
}

const postCustomerDataToPartner = async (id, body) => {
  return await api.patch(`/partners/${id}/send-client`, body)
}


export const CustomerService = {
  getProjects,
  getPartnersActive,
  postCustomerDataToPartner,
}
