import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import exportFileService from "../../services/ExportFileService";

const downloadStudy = (id) => {
	exportFileService.exportFile(`/studies/${id}`, 'study', '', 'pdf')
}

export const PAST_STUDIES_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Nom client",
		accessor: "project.client.lastname",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.firstname} {cellInfo.row.original.client.lastname}</Link>
			)
		}
	},
	{
		Header: "Ingénieur chargé(e) d’affaires",
		accessor: "ingAff.niceNames"
	},
	{
		Header: "Date rendue",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = new Date(cellInfo.row.original.study.endAt);
			date = moment(date).format("DD/MM/YYYY");
			return date
		}
	},
	{
		Header: "Action",
		accessor: "details",
		Cell: cellInfo => {
			return (
				<a onClick={ () => downloadStudy(cellInfo.row.original.study.id)}>Télécharger l'étude</a>
			)
		}
	}
];
