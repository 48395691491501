import React from 'react';

const InputSearch = ({onChangeSearch, placeholder, name, onChangeSearchValue}) => {
	return (
		<div className={"input_search"}>
			<input onChange={onChangeSearchValue} onKeyDown={onChangeSearch}  placeholder={placeholder} name={name} type={"text"}/>
		</div>
	);
};

export default InputSearch;