// USER
export const LOGIN_VIEW_PATH = "/";
export const FORGOT_PASSWORD_VIEW_PATH = "/mot-de-passe-oublie";
export const NEW_PASSWORD_VIEW_PATH = "/nouveau-mot-de-passe";
// CHATBOT
export const CHATBOT_VIEW_PATH = "/chatbot";

// COMMONS
export const HOME_PATH = "/";
export const KPI_PATH = "/indicateurs-de-performance";
export const CALENDAR_PATH = "/calendrier";
export const PRESTATIONS_PATH = "/annuaire-des-prestations";
export const PROFILE_PATH = "/profil";

// COMMERCIAL
export const COMS_CUSTOMER_VIEW = "/client/:id";
export const COMS_PROJECT_VIEW = "/projet/:projectId";
// Dir
export const COMS_DIR_VALIDATION_CIRCUIT = "/circuit-de-validation";
export const COMS_DIR_COLLABORATORS = "/collaborateurs";
export const COMS_DIR_OBJECTIVES_VIEW = "/objectifs-commerciaux";
// Res
export const COMS_RES_NEW_REQUESTS = "/detail-nouvelles-demandes";
// Com
export const COMS_COM_ARCHIVE = "/archives";
export const COMS_COM_PAST_STUDIES = "/dossier-etude-structure";
export const COMS_COM_FORM_DEVIS = "/devis/:id";
export const COMS_COM_CREATE_DEVIS = "/devis";


// Admin
export const ADMIN_USERS_PATH = "/utilisateurs";
export const ADMIN_PROJECTS_PATH = "/projets";
export const ADMIN_BILL_PATH = "/facturation";
export const ADMIN_PARTNERS_PATH = "/liens-partenaires";
export const ADMIN_PARTNERS_STAT_PATH = "/liens-partenaires/:id";
export const ADMIN_CCTP_PATH = "/parametrage-cctp";
export const ADMIN_DPGF_PATH = "/parametrage-dpgf";
export const ADMIN_MARKET_PLACE_PATH = "/marketplace-partenaire";
export const ADMIN_CHATBOT_PATH = "/parametrage-chatbot";
export const ADMIN_CUSTOMER_VIEW_PATH = "/client/:clientId";
export const ADMIN_DOCUMENT_VIEW_PATH = "/projet/:projectId";

// Architecte
export const ARCHITECT_CLIENTS_VIEW_PATH = "/client/:id";
export const ARCHITECTE_CLIENTS_PATH = "/mes-clients";

// Customer
export const CUSTOMER_MARKETPLACE_PATH = "/partenaires";

// Project Steps
export const PROJECT_DOCUMENTS_PATH = "/projet/:projectId/documents/";
export const PROJECT_STEP_INITIAL_PATH = "/projet/:projectId";
export const PROJECT_STEP_START_PATH = "/projet/:projectId/edition-devis";
export const PROJECT_STEP_SIGNATURE_PATH = "/projet/:projectId/signature/";
export const PROJECT_STEP_DEPOSIT_PATH = "/projet/:projectId/acompte/";
export const PROJECT_STEP_VISITE_PATH = "/projet/:projectId/reserver-un-creneau/";
export const PROJECT_STEP_VISITE_MARKETPLACE_PATH = "/projet/:projectId/confirmation-visite/";
export const PROJECT_STEP_PRINCIPE_PATH = "/projet/:projectId/principe-structure/";
export const PROJECT_STEP_STUDY_PATH = "/projet/:projectId/edition-dossier-etude-structure/";
export const PROJECT_STEP_VALIDATION_PATH = "/projet/:projectId/validation-dossier-etude-structure/";
export const PROJECT_STEP_PAYMENT_PATH = "/projet/:projectId/paiement-final/";
export const PROJECT_STEP_REFUND_PATH = "/projet/:projectId/remboursement/";
export const PROJECT_STEP_END_PATH = "/projet/fin-de-projet/:projectId";

// ENGINEER
export const ENGINEER_PROJECTS_PATH = "/bibliotheque-projets";
export const ENGINEER_CUSTOMER_VIEW_PATH = "/client/:clientId";

// Study Folder
export const STUDY_FOLDER_INTRODUCTION_PATH = "/dossier-etude/:id/redaction-du-dossier-etude";
export const STUDY_FOLDER_TSR_PATH = "/dossier-etude/:id/tsr-premiere-partie";
export const STUDY_FOLDER_2_TSR_PATH = "/dossier-etude/:id/tsr-seconde-partie";
export const STUDY_FOLDER_PROJET_SHEET_PATH = "/dossier-etude/:id/fiche-projet";
export const STUDY_FOLDER_CCTP_PATH = "/dossier-etude/:id/cctp";
export const STUDY_FOLDER_DPGF_PATH = "/dossier-etude/:id/dpgf";
export const STUDY_FOLDER_PRI_PATH = "/dossier-etude/:id/pri";
export const STUDY_FOLDER_CLASSE_EXECUTION_PATH = "/dossier-etude/:id/classe-execution";
export const STUDY_FOLDER_ANNEXE_PATH = "/dossier-etude/:id/annexe";


// ENGINEER Dir
export const ENGINEER_DIRECTOR_COLLABORATORS_PATH = "/collaborateurs";
export const ENGINEER_DIRECTOR_OBJECTIFS_PATH = "/objectifs-temporels";
export const ENGINEER_DIRECTOR_CONTROLE_PATH = "/controles";

// ENGINEER Res
export const ENGINEER_RESPONSABLE_COLLABORATORS_PATH = "/collaborateurs";
export const ENGINEER_RESPONSABLE_OBJECTIFS_PATH = "/objectifs-temporels";
export const ENGINEER_RESPONSABLE_CONTROLE_PATH = "/controles";

// ENGINEER Man
export const ENGINEER_MANAGER_CALENDAR_PATH = "/gestion-equipes";
//export const ENGINEER_MANAGER_CLIENTS_PATH = "/mes-clients";

// ENGINEER Prod
export const ENGINEER_PROD_TASK_DETAIL = "/task-ing";
