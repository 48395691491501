import React, {useContext, useEffect, useState} from "react";
import {StateRequest} from "../../../../views/Pages/Admin/Dpgf/View";
import {convertFromHTML, convertToRaw, EditorState} from "draft-js";
import ContentState from "draft-js/lib/ContentState";
import draftToHtmlPuri from "draftjs-to-html";
import {InitialValuesAddDpgf} from "../../../../config/InitialValues/Admin/InitialValuesAddDpgf";
import CctpService from "../../../../services/CctpService";
import {Form, Formik} from "formik";
import {AddDPGFSchema} from "../../../../schemas/Admin/DPGF/AddDPGFSchema";
import {Editor} from "react-draft-wysiwyg";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import DpgfService from "../../../../services/DpgfService";
import Skeleton from "react-loading-skeleton";

const FormEditDPGF = ({cellInfo, setShowPopUp}) => {
	const {stateRequest, setStateRequest} = useContext(StateRequest);

	const [initialValueContent, setInitialValueContent] = useState("");
	const [cctp, setCctp] = useState([])

	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromBlockArray(
				convertFromHTML(initialValueContent)
			)
		)
	);

	const [isLoading, setIsLoading] = useState(true);

	const getCCPT = async () => {
		const request = await CctpService.getCctp({pagination: "false"})
		if (request.request.status === 200) {
			const cctpTries = request.data['hydra:member'].sort((a, b) => {
				return a.position.localeCompare(b.position, 'fr', {numeric: true});
			});
			setCctp(cctpTries)
		} else {
			setStateRequest("error")
		}
	}

	const handleChangeEditorState = (valueOfEditor) => {
		setEditorState(valueOfEditor)
		let value = draftToHtmlPuri(convertToRaw(valueOfEditor.getCurrentContent()))
		InitialValuesAddDpgf.content = value
	}

	const getDpgf = async () => {
		const request = await DpgfService.getDpgfById(cellInfo.id)

		if (request.request.status === 200) {
			const cellInfo = request.data
			const ccptLength=cellInfo.cctpIri.split("/")
			InitialValuesAddDpgf.cctpIri = ccptLength[ccptLength.length-1]
			InitialValuesAddDpgf.total = cellInfo.total
			InitialValuesAddDpgf.vta = cellInfo.vta
			InitialValuesAddDpgf.unit = cellInfo.unit
			setInitialValueContent(cellInfo.content)
			setIsLoading(false)
		} else {
			setStateRequest("error")
			setShowPopUp(false)
		}
	}

	useEffect(() => {
		setCctp([])
		getCCPT()
		setEditorState(EditorState.createWithContent(
			ContentState.createFromBlockArray(
				convertFromHTML(initialValueContent)
			)
		))
		getDpgf()
	}, [initialValueContent, cellInfo]);



	const onSubmit = async (values, actions) => {
		values.content = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()))
		values.vta = parseFloat(values.vta)
		values.cctpIri = `/api/cctps/${values.cctpIri}`
		setShowPopUp(false)
		const request = await DpgfService.patchDpgf(cellInfo.id, values)
		if (request.request.status === 200) {
			setStateRequest("success edit")
		} else {
			setStateRequest("error")
		}
	}
	const UNITS = [{value:'U'}, {value:'M2'}, {value:'M3'}, {value:'KG'}, {value:'ML'}, {value:'ENS.'}, {value:'EUR'}]

	return (

		<>
			<Formik
				initialValues={InitialValuesAddDpgf}
				validationSchema={AddDPGFSchema}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => (
					isLoading ? <Skeleton height={340} /> :
					<Form>
						<div className={"form-row-element"}>
							<div className={"form-row-label-input"}>
								<label className={"text-micro paragraph_bold color-dark-grey"}>Désignation ouvrages
									<Editor
										initialEditorState={editorState}
										editorState={editorState}
										wrapperClassName="demo-wrapper"
										localization={{
											locale: 'fr',
										}}
										toolbar={{
											options: ["inline", "colorPicker", 'link', "list"],
											inline: {
												options: ["bold", "italic", "underline", "strikethrough"],
											},
											colorPicker: {
												colors: ["#000000", "#ffffff", "#090754", "#4AA9C2", "#9DDFF1", "#648DE5", "#c13e39", "#5FD086", "#eed128"],
											}
										}}
										onEditorStateChange={(e) => {
											handleChangeEditorState(e)
										}
										}
									/>
								</label>
							</div>
						</div>

						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"select"} label={"Unité"} name={"unit"} data={UNITS} keyLabel={"value"} keyValue={"value"} />
							<InputLabelErrorMessage type={"select"} label={"Référence CCTP"} name={"cctpIri"} data={cctp} keyValue={"id"} keyLabel={"position"} />
						</div>

						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"number"} label={"Total HT"} name={"total"} />
							<InputLabelErrorMessage type={"select"} label={"TVA"} name={"vta"} data={[{label: "10%", value:0.1}, {label:"20%", value:0.2}]} keyValue={"value"} keyLabel={"label"} />
						</div>

						<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
						              title={"Modifier la DPGF"} />
					</Form>
				)}
			</Formik>
		</>
	)


}

export default FormEditDPGF;
