const HomeCustomerBannerOpen = (props) => {
  return (
    <section className="homeCustomerBanner homeCustomerBanner-open">
      <div className="homeCustomerBanner-open__header">
        <h1 className="color-primary">Vos projets étape par étape</h1>
        <img src="/images/pictos/cross-close.svg" alt="Cross" onClick={props.toggleBanner} />
      </div>

      <div className="homeCustomerBanner-open__grid">
        <div>
          <h4 className="color-primary">1. Visite sur site du diagnostiqueur</h4>
          <img src="/images/medias/forms/polygon.svg" alt="Polygon SVG" />

          <div>
            <p className="color-primary text-primary">
              Après la signature du devis électroniquement et le paiement de l'acompte, vous pourrez passer à l'étape de prise de rendez-vous.
            </p>
            <span className="text-micro color-dark-grey">
              Sélectionner la date et le créneau qui convient
            </span>
          </div>
        </div>

        <div>
          <h4 className="color-primary">2. Édition d'un avis de passage</h4>
          <img src="/images/medias/forms/rectangle.png" alt="Polygon SVG" />
          <div>
            <p className="color-primary text-primary">
              Suite au passage de l'ingénieur sur site, vous recevrez un avis de passage qui atteste la visite de l'ingénieur.          </p>
            <span className="text-micro color-dark-grey">
              Visualiser l'avis de passage de notre diagnostiqueur
            </span>
          </div>
        </div>

        <div>
          <h4 className="color-primary">3. Réception du dossier d'étude structure</h4>
          <img src="/images/medias/forms/ellipse.svg" alt="Polygon SVG" />
          <p className="color-primary text-primary">
            Suite à la visite, nous allons vous faire valider un principe de structure (PRI) pour lancer votre dossier d'étude structure en production.
          </p>
          <span className="text-micro color-dark-grey">
            Valider votre dossier d'étude structure
          </span>
          <span className="text-micro color-dark-grey">
            Réceptionner votre dossier d'étude structure
          </span>
        </div>

      </div>
    </section>
  );
}

export default HomeCustomerBannerOpen;