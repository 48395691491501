import React from "react";

export function CardCircuitDeValidationStatus({title, color}) {
	return (
		<div className={"card-circuit-de-validation-status"}>
			<div className={`card-circuit-de-validation-status-circle circle-${color}`}></div>
			<p className={`text-primary text-${color}`}>{title === true ? "Terminé" : "En cours"}</p>
		</div>
	)
}

export default CardCircuitDeValidationStatus;