import React from 'react';

const Select = ({defaultValue, styleSelect, data, onChange, value}) => {
	return (
		<div className={"select"}>
			<select className={styleSelect} onChange={onChange}>
				<option value={defaultValue} defaultValue={defaultValue}>{defaultValue}</option>
				{data.map((item, index) => <option key={index} value={value ? value[index] : 0 }>{item}</option>)}
			</select>
		</div>);
};

export default Select;
