import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { InitialValuesProfileAccount } from './Config/InitialValuesProfileAccount';
import { SchemaProfileAccount } from './Config/SchemaProfileAccount';
import InputLabelErrorMessage from '../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import ButtonSubmit from '../../../../../components/Atomes/Buttons/ButtonSubmit';

import api from "./../../../../../services/APIService";
import { toast } from 'react-toastify';

const ProfileAccountForm = () => {

  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'webp'] };
  const [notValidFileErrorMessage, setNotValidFileErrorMessage] = useState(null);

  function isValidFileType(fileName, fileType) {
    return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
  }
  const changeProfilePicture = (event) => {
    if (!isValidFileType(event.target.files[0].name, 'image')) {
      setNotValidFileErrorMessage('Le format de l\'image n\'est pas valide');
      return;
    }
    if (event.target.files[0].size > 15000000) {
      setNotValidFileErrorMessage('La taille de l\'image ne doit pas dépasser 15Mo');
      return;
    }
    setNotValidFileErrorMessage(null);
    setProfilePicture(event.target.files[0]);
    setProfilePicturePreview(URL.createObjectURL(event.target.files[0]));
  };
  const getUrlAvatar = () => {
    let avatar = localStorage.getItem("avatar").replace(/"/g, "")
    setProfilePicture(avatar);
    setIsLoading(false);
  }
  const getUserData = async () => {
    const userId = localStorage.getItem("userId");
    if (userId != null) {
      let request = await api.get(`users/${userId}`);
      InitialValuesProfileAccount.gender = request.gender
      InitialValuesProfileAccount.lastname = request.lastname;
      InitialValuesProfileAccount.firstname = request.firstname;
      InitialValuesProfileAccount.email = request.email;
      InitialValuesProfileAccount.phone = request.phone;
      setUserRole(request.roles[0]);
    }
    setUserDataLoaded(true);
  }

  const onSubmit = async (values) => {
    let user = JSON.parse(JSON.parse(localStorage.getItem("user")))
    let userId = localStorage.getItem("userId");
    let gender = values.gender;
    let firstname = values.firstname;
    let lastname = values.lastname;
    let email = values.email;
    let phone = values.phone;
    let data = {
      gender: gender,
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      roles: [userRole]
    };
    let request = null;

    if (userRole === "ROLE_COM_DIR" || userRole === "ROLE_COM" || userRole === "ROLE_COM_RES") {
      request = await api.patch(`coms/${userId}`, data)
    } else if (userRole === "ROLE_ADMIN") {
      request = await api.patch(`admins/${userId}`, data)
    }

    if (request.status === 200) {
      if (profilePicturePreview !== null) {
        const file = new FileReader();
        file.readAsDataURL(profilePicture);
        const requestAvatar = axios.create({
          baseURL: process.env.REACT_APP_PROD_API_URL,
          headers: {
            Authorization: `Bearer ${user ? user.token : ""}`,
            'Content-Type': `multipart/form-data`
          }
        })
        const requestAvatarPatch = await requestAvatar.post(`users/${userId}/avatar`, { file: profilePicture })
        if (requestAvatarPatch.status === 201) {
          localStorage.setItem("avatar", requestAvatarPatch.data.urlAvatar);
          setProfilePicture(requestAvatarPatch.data.urlAvatar);
          window.location.reload();
        }
      }
      toast.success("Vos informations ont bien été modifiées");
    } else {
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    getUrlAvatar();
    getUserData();
  }, [userDataLoaded]);

  return (
    <div className='mbe-profile-container mbe-section'>
      <h3 className='color-primary'>Mes informations personnelles</h3>
      {
        userDataLoaded && (
          <Formik
            initialValues={InitialValuesProfileAccount}
            validationSchema={SchemaProfileAccount}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className={"form-row-element mbe-profile-form-picture"}>
                  <label
                    htmlFor='profilePicture'
                  >
                    {isLoading ?
                      (
                        <Skeleton circle={true} height={120} width={120} />
                      ) : (
                        <img src={profilePicturePreview ? profilePicturePreview : `${process.env.REACT_APP_PROD_URL}${profilePicture}`} alt='profilePicture' />
                      )
                    }
                  </label>
                  <input type='file' name='profilePicture' id='profilePicture' accept="image/*" onChange={changeProfilePicture} />
                  <p className='color-red text-primary'>{notValidFileErrorMessage}</p>
                </div>
                <div className={"form-row-element"}>
                  <InputLabelErrorMessage
                    keyLabel={"label"}
                    label="Civilité"
                    keyValue={"value"}
                    name="gender"
                    type="select"
                    data={[
                      { label: "Monsieur", value: "M" },
                      { label: "Madame", value: "W" },
                      { label: "Mademoiselle", value: "Miss" },
                      { label: "Société", value: "S"},
                      { label: "Non-genrée", value: "NA" }
                    ]}
                  />
                </div>

                <div className={"form-row-element form-row-two-elements"}>
                  <InputLabelErrorMessage
                    label="Nom"
                    name="lastname"
                    type="text"
                  />
                  <InputLabelErrorMessage
                    label="Prénom"
                    name="firstname"
                    type="text"
                  />
                </div>

                <div className={"form-row-element form-row-two-elements"}>
                  <InputLabelErrorMessage
                    label="Adresse e-mail"
                    name="email"
                    type="text"
                  />
                  <InputLabelErrorMessage
                    label="Téléphone"
                    name="phone"
                    type="text"
                  />
                </div>
                <ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Sauvegarder"} />
              </Form>
            )}
          </Formik>
        )
      }

    </div >
  );
}

export default ProfileAccountForm;
