import React, {useEffect, useState} from 'react';
import TitleSection from "../../../../../../../components/Atomes/Title/TitleSection";
import ButtonAddElementForm from "../../../../../../../components/Atomes/Buttons/ButtonAddElementForm";
import CategoriesService from "../../../../../../../services/CategoriesService";
import {FieldArray, useFormikContext} from "formik";
import InputLabelErrorMessage from "../../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import ButtonDeleteElementForm from "../../../../../../../components/Atomes/Buttons/ButtonDeleteElementForm";
import {ConvertObjectToUrlParams} from "../../../../../../../utils/StringUtils";
import SelectDetailledService from "./ProjectInformations/SelectDetailledService";
import {toast} from "react-toastify";
import PopUpDeleteDevis from "../../../../../../../components/Molecules/PopUp/Devis/PopUpDeleteDevis";
import QuotesService from "../../../../../../../services/QuotesService";
import PopUpDeleteElement from "../../../../../../../components/Molecules/PopUp/Devis/PopUpDeleteElement";
import {EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EditorCustomDevisDescription from "../../../../../../../components/Atomes/Editor/EditorCustomDevisDescription";
import RequestService from '../../../../../../../services/RequestService';

const FormDevisProjectInformations = ({currentQuote, setCurrentQuote, setUpdate, update, project, isLoading}) => {

	const {values, setFieldValue} = useFormikContext();
	const [quotes, setQuotes] = useState(values.quotes);
	const [valuesLoading, setValuesLoading] = useState(true);

	const [valueToSet, setValueToSet] = useState("")

	useEffect(() => {
		setValuesLoading(true)
		if (quotes.length !== 0) {
			setValuesLoading(false)
		}
	}, [values, isLoading]);


	/*Function to add a new Quote*/
	const addQuote = () => {
		setValueToSet("")
		setCurrentQuote(quotes.length)
		/*New item Devis*/
		const newItem = {
			orders: [{
				typeOrder: "serviceOrder",
				serviceIri: "",
				title: "",
				idService: "",
				idGeneralService: "",
				description: "",
				price: 0,
				spent: 0,
				margin: 0,
				quantity: 1,
				discount: false
			}]
		}
		setQuotes([...quotes, newItem])
		values.quotes.push(newItem)
	}


	/*Function to change current quote*/
	const changeQuote = (id) => {
		setCurrentQuote(id)
	}

	/*Function to add order to the current quote*/
	const addOrder = (element) => {
		const newOrder = {
			typeOrder: element,
			serviceIri: "",
			title: "",
			idService: 0,
			idGeneralService: "",
			options: [],
			description: "",
			phase: "",
			price: 0,
			spent: 0,
			margin: 0,
			quantity: 1,
			discount: element === "discount" ? true : false,

		}
		values.quotes[currentQuote].orders.push(newOrder)
		setUpdate(update + 1)
		if (element === "freeOrder") {
			let newItemEditorState = EditorState.createEmpty()
		}
	}

	/*Get Categories and services associate*/
	const [categoriesCollection, setCategoriesCollection] = useState([]);
	const getGeneralService = async () => {
		const URL = ConvertObjectToUrlParams("categories", {type: "service", "order[title]" : "asc"})
		const request = await CategoriesService.getCategoriesCollection(URL)
		if (request.request.status === 200) {
			let data = request.data['hydra:member']
			data.map((itemCat) => {
				itemCat.categoryIri = `/api/categories/${itemCat.id}`
			})
			setCategoriesCollection(data)
		}
	}
	useEffect(() => {
		getGeneralService()
	}, []);

	/*Initial Values des project information*/
	useEffect(() => {
		if (project.quotes.length > 0) {
			let quotes = project.quotes
			quotes.map((itemQuote) => {
				for (const key in itemQuote) {
					if (Object.prototype.hasOwnProperty.call(itemQuote, key)) {
						if (key !== 'id' && key !== 'orders') {
							delete itemQuote[key];
						}
					}
				}
				itemQuote.orders.map((itemOrder) => {
					if (itemOrder.typeOrder === "discount") {
						itemOrder.typeOrder = "discount"
					} else if (itemOrder.typeOrder === "serviceOrder") {
						itemOrder.idService = parseInt(itemOrder.service.id)
						categoriesCollection.map((itemCat) => {
							if (itemCat.services) {

								itemCat.services.map((itemService) => {
									if (itemService.id === itemOrder.idService) {
										itemOrder.idGeneralService = itemCat.id
									}
								})
							}
						})
					} else {
						itemOrder.typeOrder = "freeOrder"
					}
				})
			})
			values.quotes = quotes
			setUpdate(update + 0.000001) // Mineur update
		} else {
			setQuotes([{orders: []}])
			values.quotes = [{orders: []}]
		}

	}, [project, categoriesCollection, isLoading]);
	useEffect(() => {
		setQuotes(values.quotes)
	}, [currentQuote, update, values]);



	/*useState info of the quote to delete*/
	const [infoDeleteDevis, setInfoDeleteDevis] = useState({});
	/*useState of isActive pop up delete quote -> if true pop up is display*/
	const [isActiveDeleteDevis, setIsActiveDeleteDevis] = useState(false);

	/*useState info of the order to delete*/
	const [infoDeleteElement, setInfoDeleteElement] = useState({});
	/*useState of isActive pop up delete elemnt of quote -> if true pop up is display*/
	const [isActiveDeleteElement, setIsActiveDeleteElement] = useState(false);


	/*function to open pop up delete quote and set info of the quote to delete*/
	const openPopUpDeleteDevis = (index, id) => {
		setInfoDeleteDevis({id: id, index: index})
		setIsActiveDeleteDevis(true)
	}


	/*function to open pop up delete element of quote and set info of the element of quote to delete*/
	const openPopUpDeleteElement = (index, type, id = 0) => {
		setInfoDeleteElement({index: index, type: type, id: id})
		setIsActiveDeleteElement(true)
	}


	/*function of delete quote*/
	const deleteDevis = async (index, id) => {
		if (values.quotes.length > 1) {
			setCurrentQuote(0)
			if (id) {
				const request = await RequestService.deleteQuotesInformations(id)
				if (request.request.status === 204) {
					project.quotes.splice(index, 1)
					values.quotes.splice(index, 1)
					setUpdate(update + 1)
					setIsActiveDeleteDevis(false)
					toast.success("Le devis a bien été supprimé")
				} else {
					setIsActiveDeleteDevis(false)
					toast.error("Une erreur est survenue lors de la suppression du devis")
				}
			} else {
				values.quotes.splice(index, 1)
				setUpdate(update + 1)
				setIsActiveDeleteDevis(false)
				toast.success("Le devis a bien été supprimé")
			}
		} else {
			setIsActiveDeleteDevis(false)
			toast.error("Vous devez posséder au moins un devis")
		}

	}

	/*function of delete quote element*/
	const deleteElement = async () => {
		setIsActiveDeleteElement(false)
		if (infoDeleteElement.id !== 0) {
			const request = await QuotesService.deleteOrder(infoDeleteElement.id)
			if (request.request.status === 204) {
				let quotes = values.quotes
				quotes[currentQuote].orders.splice(infoDeleteElement.index, 1)
				values.quotes = quotes
				setUpdate(update + 1)
				toast.success("Élément suprrimé avec succès")
			}
		} else {
			let quotes = values.quotes
			quotes[currentQuote].orders.splice(infoDeleteElement.index, 1)
			values.quotes = quotes
			setUpdate(update + 1)
		}
		setUpdate(update + 1)
	}

	/*Change margin of freePrestation*/
	/*useEffect(() => {
		if (values.quotes && values.quotes.length > 0) {
			values.quotes.map((itemQuote, indexItemQuote) => {

				itemQuote.orders.map((itemOrder, indexItemOrder) => {
					if (itemOrder !== undefined) {
						if (itemOrder.typeOrder === "freePrestation") {
							console.log(1)
							if (itemOrder.price !== "" && itemOrder.spent !== "" && itemOrder.spent !== undefined) {
								console.log(2)
								console.log(actualPrice)
								console.log(spend)
								if(actualPrice !== undefined && spend !== undefined) {
									console.log(3)
										if(actualPrice !== itemOrder.price || spend !== itemOrder.spent) {
											console.log(4)
										let margin = itemOrder.price - itemOrder.spent.toFixed(2)
										itemOrder.margin = margin
										setActualPrice(itemOrder.price)
										setSpend(itemOrder.spent)
									}
								}
							}
						}
					}
				})
			})
		}
	}, [values.quotes]);*/
	
	return (
	<div className={"form-devis-container-project-information"}>
		<TitleSection title={"Devis"}/>
		<div className={"form-devis-container-project-information-choice-devis"}>
			<ul>
				{quotes.map((item, index) => {
					return (
						<div key={index}>
							<li className={"text-primary item-devis" + (currentQuote === index ? " active" : " color-dark-grey")}>
								<span className={"text-primary"} onClick={(e) => changeQuote(index)}>Devis n°{index + 1}</span>
								<div onClick={(e) => openPopUpDeleteDevis(index, item.id)} className={"item-devis-delete"}></div>
								{isActiveDeleteDevis ?
									<PopUpDeleteDevis action={(e) => deleteDevis(index, item.id)} setIsActive={setIsActiveDeleteDevis}
									                  data={infoDeleteDevis}/> : ""}
							</li>
						</div>
					)
				})}
			</ul>
			<ButtonAddElementForm onClick={addQuote} title={"Ajouter un devis"}/>
		</div>
		<div>
			<FieldArray name={"quotes"} render={arrayHelpers => (
				<>
					{quotes.map((singleQuote, singleQuoteIndex) => (
							<div
								className={"form-devis-container-project-information-inputs" + (currentQuote === singleQuoteIndex ? " active" : " ")}
								key={singleQuoteIndex}>
								
								<FieldArray name={"orders"} render={arrayHelpersPrestation => (
									singleQuote.orders.map((singleOrder, singleOrderIndex) => {
										if (singleOrder !== undefined) {
											if (singleOrder.typeOrder === "serviceOrder") {
												const changeCodeArticle = (e) => {
													setFieldValue(`quotes.${singleQuoteIndex}.orders.${singleOrderIndex}.description`, e.description)
												}

												return (
													<div key={singleOrderIndex}>
														<div className={"form-devis-container-project-information-inputs-section prestation"}>
															<div className={"form-row-element form-row-two-elements"}>
																<InputLabelErrorMessage
																	name={`quotes.${currentQuote}.orders.${singleOrderIndex}.idGeneralService`}
																	label={"Typologie"} type={"select"} data={categoriesCollection} keyValue={"id"}
																	keyLabel={"title"}/>
																<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} name={`quotes.${currentQuote}.orders.${singleOrderIndex}.phase`}
																	data={[{value: "DIAG", label: "DIAG"},{value: "FAISA", label: "FAISA"},{value: "PRO", label: "PRO"},{value: "EXE", label: "EXE"}]}
																	label={"Phase du projet"}/>
															</div>
															{
																
																valuesLoading ? "" : (
																	<div className={"form-row-element detailled-service"}>
																		<SelectDetailledService setUpdate={setUpdate} project={project} update={update}
																								onChangeCodeArticle={(e) =>changeCodeArticle(e)}
																		                        dataSelect={categoriesCollection}
																		                        singleQuoteIndex={singleQuoteIndex}
																		                        singleOrderIndex={singleOrderIndex}/>
																	</div>
																)
															}

																<InputLabelErrorMessage type={"text"} label={"Titre"} 
																						name={`quotes.${currentQuote}.orders.${singleOrderIndex}.title`} />
																<label className={"text-micro paragraph_bold color-dark-grey"}>Description</label>
																<EditorCustomDevisDescription content={singleQuote.orders[singleOrderIndex].description} project={project} indexQuote={singleQuoteIndex}
																			                              indexOrder={singleOrderIndex}/>

															<div className={"form-row-element all-price"}>


																<InputLabelErrorMessage type={"number"} label={"Prix de vente (HT)"}
																                        name={`quotes.${currentQuote}.orders.${singleOrderIndex}.price`}/>
																<InputLabelErrorMessage type={"number"} label={"Prix déboursé (HT)"}
																                        name={`quotes.${currentQuote}.orders.${singleOrderIndex}.spent`}/>
																<InputLabelErrorMessage type={"number"} label={"Marge"}
																                        name={`quotes.${currentQuote}.orders.${singleOrderIndex}.margin`}/>
																<InputLabelErrorMessage type={"number"} label={"Quantité"}
																                        name={`quotes.${currentQuote}.orders.${singleOrderIndex}.quantity`}/>
															</div>
														</div>
														<ButtonDeleteElementForm
															onClick={(e) => openPopUpDeleteElement(singleOrderIndex, "serviceOrder", singleOrder.id)}
															title={"Supprimer la prestation"}/>
														{isActiveDeleteElement ? <PopUpDeleteElement data={infoDeleteElement}
														                                             action={(e) => deleteElement(singleOrderIndex, singleQuoteIndex, singleOrder.id ? singleOrder.id : 0)}
														                                             setIsActive={setIsActiveDeleteElement}/> : ""}
													</div>
												)
											} else if (singleOrder.typeOrder === "freeOrder") {
												return (
													<div key={singleOrderIndex}>
														<div className={"form-devis-container-project-information-inputs-section prestation"}>
															<div className={"form-row-element form-row-two-elements"}>
																<InputLabelErrorMessage name={`quotes.${currentQuote}.orders.${singleOrderIndex}.title`}
																                        label={"Titre de la prestation"} type={"text"}/>
																<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} name={`quotes.${currentQuote}.orders.${singleOrderIndex}.phase`}
																						data={[{value: "DIAG", label: "DIAG"},{value: "FAISA", label: "FAISA"},{value: "PRO", label: "PRO"},{value: "EXE", label: "EXE"}]}
																						label={"Phase de la prestation"}/>
															</div>
															{
																valuesLoading ? "" : (
																	<div className={"form-row-element"}>
																		<div className={"form-row-label-input"}>
																			<label className={"text-micro paragraph_bold color-dark-grey"}>Description</label>
																			<EditorCustomDevisDescription project={project} indexQuote={singleQuoteIndex}
																			                              indexOrder={singleOrderIndex}/>
																		</div>
																	</div>
																)
															}
															<div className={"form-row-element all-price"}>
																<InputLabelErrorMessage type={"number"} label={"Prix de vente (HT)"}
																                        name={`quotes.${currentQuote}.orders.${singleOrderIndex}.price`}/>
																<InputLabelErrorMessage type={"number"} label={"Prix déboursé (HT)"}
																                        name={`quotes.${currentQuote}.orders.${singleOrderIndex}.spent`}/>
																<InputLabelErrorMessage type={"number"} label={"Marge"} readonly={false}
																                        name={`quotes.${currentQuote}.orders.${singleOrderIndex}.margin`}/>
																<InputLabelErrorMessage type={"number"} label={"Quantité"}
																                        name={`quotes.${currentQuote}.orders.${singleOrderIndex}.quantity`}/>
															</div>
														</div>
														<ButtonDeleteElementForm
															onClick={(e) => openPopUpDeleteElement(singleOrderIndex, "prestation en saisie libre", singleOrder.id)}
															title={"Supprimer la prestation en saisie libre"}/>
														{isActiveDeleteElement ? <PopUpDeleteElement data={infoDeleteElement}
														                                             action={(e) => deleteElement(singleOrderIndex, singleQuoteIndex, singleOrder.id ? singleOrder.id : 0)}
														                                             setIsActive={setIsActiveDeleteElement}/> : ""}


													</div>
												)
											} else if (singleOrder.typeOrder === "discount") {
												return (
													<div key={singleOrderIndex}>
														<div
															className={"form-devis-container-project-information-inputs-section prestation " + (singleOrder.id)}>
															<div className={"form-row-element"}>
																<InputLabelErrorMessage name={`quotes.${currentQuote}.orders.${singleOrderIndex}.title`}
																                        label={"Intitulé de la remise"} type={"text"}/>
															</div>
															<div className={"form-row-element all-price"}>
																<InputLabelErrorMessage type={"number"} label={"Prix de vente (HT)"}
																                        name={`quotes.${currentQuote}.orders.${singleOrderIndex}.price`}/>
															</div>
														</div>
														<ButtonDeleteElementForm
															onClick={(e) => openPopUpDeleteElement(singleOrderIndex, "remise commerciale", singleOrder.id)}
															title={"Supprimer la remise commerciale"}/>
														{isActiveDeleteElement ? <PopUpDeleteElement data={infoDeleteElement}
														                                             action={(e) => deleteElement(singleOrderIndex, singleQuoteIndex, singleOrder.id ? singleOrder.id : 0)}
														                                             setIsActive={setIsActiveDeleteElement}/> : ""}
													</div>
												)
											}
										}
									})
								)}/>
							</div>
						)
					)}
				</>

			)}/>
		</div>
		<div className={"form-devis-container-project-information-buttons-add-element"}>
			<ButtonAddElementForm onClick={() => addOrder("serviceOrder")} title={"Ajouter une prestation"}/>
			<ButtonAddElementForm onClick={() => addOrder("freeOrder")}
			                      title={"Ajouter une prestation en saisie libre"}/>
			<ButtonAddElementForm onClick={() => addOrder("discount")} title={"Ajouter une remise commerciale"}/>
		</div>
	</div>);
};


export default FormDevisProjectInformations;
