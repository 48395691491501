import React from 'react';
import KpiSimpleCard from "../../../../../components/Atomes/Kpis/KpiSimpleCard";
import TitleSection from "../../../../../components/Atomes/Title/TitleSection";
import Card from "../../../../../components/Atomes/Charts/Card";

const OverviewAdmin = ({projectInProgress, projectValidated, numberProjectWaitingPayment, numberProjectInLate,dateEnd, numberProjectRecouvrement} ) => {
	return (
		<div className={"mbe-section"}>
			<TitleSection title={"Tableau de bord"} />
			<div className={"section-kpi-overview"}>
				<div className="kpi-container-simpleCards">
					<KpiSimpleCard title={"Projets en cours"} value={projectInProgress.total} />
					<KpiSimpleCard title={"Dossiers rendu "} value={projectValidated.total} />
				</div>
				<div className="kpi-container-simpleCards">
					<KpiSimpleCard title={"Dossiers en attente de paiement final"} value={numberProjectWaitingPayment.total} />
					{
						dateEnd === "" ? (
							<KpiSimpleCard title={"Dossiers en retard de rendu"} value={numberProjectInLate.total} />
						) : null
					}

				</div>
				<div className={"kpi-container-simpleCards"}>
					<Card className={"devis-valide custom-chart"} title={"Projets en recouvrement"}
					      value={numberProjectRecouvrement.total}/>
				</div>
			</div>
		</div>);
};

export default OverviewAdmin;
