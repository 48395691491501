import React from 'react';

const PopupLogout = ({showPopup, validAction, closeAction}) => {
	return (
		<div className={`pop-up-base-style ${showPopup ? 'active' : ""}`}>
			<div className={"pop-up-simple center"}>
				<img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} alt={"cross"}
				     onClick={closeAction}/>
				<h3 className={"text-primary pop-up-simple-title-primary color-black"}>Déconnexion</h3>
				<h4 className={"pop-up-simple-title text-primary"}>
					Voulez-vous être déconnecté ?
				</h4>
				<div className={"pop-up-simple-btns"}>
					<button className={"btn-default btn-cancel"} onClick={closeAction}>Revenir sur mon espace</button>
					<button className={"btn-default btn-valid"} onClick={validAction}>C’est bon pour moi</button>
				</div>
			</div>
		</div>
	);
};

export default PopupLogout;
