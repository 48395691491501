import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

import { ProjectStageService } from '../../services/ProjectStageService';

const ProjectStageManager = () => {

  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    getProjectStep();
  }, [])


  const getProjectStep = async () => {
    let project = await ProjectStageService.getSingleProject(projectId);
    let url = window.location.href;

    if (url.includes('?event=signing_complete') && project.stage === 'COM_signature') {
      let request = await ProjectStageService.billVerifySignature(projectId, project);
      if (request.status === 200) {
        if (request.data.stage === 'COM_deposit') {
          project.stage = 'COM_deposit';
        }
      }
    }

    if (url.includes('?event=deposit_complete') && (project.stage === 'COM_deposit' || project.stage === 'ADMIN_payment-validation' || project.stage === 'ADMIN_payment-follow-up')) {
      const invoice = project.invoices.find(invoice => invoice.type === "bill-invoice-deposit");
      const invoiceId = invoice ? invoice.id : null;
      if (invoice.payment?.method === 'transfer' && invoice.payment?.status === 'paid') {
        project.stage = 'COM_delivery';
        if (project.visitType) {
          project.stage = 'ING_visit';
        } else {
          project.stage = 'ING_pri';
        }
      } else {
        let request = await ProjectStageService.billVerifyPayment(invoiceId);
        if (request.status === 200) {
          if (project.visitType) {
            project.stage = 'ING_visit';
          } else {
            project.stage = 'ING_pri';
          }
        }
      }
    }

    if (url.includes('?event=payment_complete') && (project.stage === 'COM_payment' || project.stage === 'ADMIN_payment-validation' || project.stage === 'ADMIN_payment-follow-up')) {
      const invoice = project.invoices.find(invoice => invoice.type === "bill-invoice-final");
      const invoiceId = invoice ? invoice.id : null;
      if (invoice.payment?.method === 'transfer' && invoice.payment?.status === 'paid') {
        project.stage = 'COM_delivery';
      } else {
        let request = await ProjectStageService.billVerifyPayment(invoiceId);
        if (request.status === 200) {
          if (request.data.status === 'paid') {
            project.stage = 'COM_delivery';
          }
        }
      }
    }

    if (!url.includes('?event=deposit_complete') && (project.stage === 'ADMIN_payment-follow-up' || project.stage === 'ADMIN_payment-validation')) {
      if (project.study) {
        project.stage = 'COM_payment';
      } else {
        project.stage = 'COM_deposit';
      }
    }

    if ((project.stage === 'ING_visit' || project.stage === 'ADMIN_validate-visit') && project.visit) {
      project.stage = 'ING_report-visit';
    }

    if (project.stage === 'ING_correction-study') {
      project.stage = 'COM_delivery';
    }

    redirectUser(project.stage);
  }


  function redirectUser(projectStep) {
    switch (projectStep) {
      case 'COM_commercial-exchange':
      case 'COM_quotation':
      case 'COM_discount-validation':
        navigate("/projet/" + projectId + "/edition-devis", { replace: true });
        break;
      case 'COM_signature':
        navigate("/projet/" + projectId + "/signature", { replace: true });
        break;
      case 'COM_deposit':
        navigate("/projet/" + projectId + "/acompte", { replace: true });
        break;
      case 'ING_visit':
      case 'ADMIN_validate-visit':
        navigate("/projet/" + projectId + "/reserver-un-creneau", { replace: true });
        break;
      case 'ING_report-visit':
        navigate("/projet/" + projectId + "/confirmation-visite", { replace: true });
        break;
      case 'ING_pri':
        navigate("/projet/" + projectId + "/principe-structure/", { replace: true });
        break;
      case 'ING_study':
      case 'ING_verification-study':
      case 'ING_validation-study':
        navigate("/projet/" + projectId + "/edition-dossier-etude-structure/", { replace: true });
        break;
      case 'COM_payment':
        navigate("/projet/" + projectId + "/paiement-final", { replace: true });
        break;
      case 'COM_delivery':
        navigate("/projet/" + projectId + "/validation-dossier-etude-structure/", { replace: true });
        break;
      case 'COM_feedback':
        navigate("/projet/fin-de-projet/" + projectId, { replace: true });
        break;
      case 'ADMIN_refund':
        navigate("/projet/" + projectId + "/remboursement", { replace: true });
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Skeleton height={300} />
      <Skeleton height={40} />
    </>
  )
}

export default ProjectStageManager;
