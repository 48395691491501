import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";


export const SIGNED_QUOTES_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "project.client.reference"
	},
	{
		Header: "Nom du client",
		accessor: "project.client.lastname",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.project.client.id}`}>{cellInfo.row.original.project.client.firstname} {cellInfo.row.original.project.client.lastname}</Link>
			)
		}
	},
	{
		Header: "Type devis",
		accessor: "niceType"
	},
	{
		Header: "Date de signature",
		accessor: "signedAt",
		Cell: cellInfo => {
			let date = "NA";
			if (cellInfo.row.original.signedAt) {
				date = new Date(cellInfo.row.original.signedAt)
				date = moment(date).format("DD/MM/YYYY");
			}
			return date
		}
	},
	{
		Header: "Remise effectuée",
		accessor: "totalDiscount",
		Cell: cellInfo => {
			let discount = 0
			if (cellInfo.row.original.totalDiscount) {
				discount = cellInfo.row.original.totalDiscount.toFixed(2) + " €"
			} else {
				discount = "0.00 €"
			}
			return discount
		}
	},
	{
		Header: "Date de visite",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = "Pas de visite";
			if (cellInfo.row.original.project.visit) {
				date =  new Date(cellInfo.row.original.project.visit.startAt);
				date = moment(date).format("DD/MM/YYYY");
			}
			return date
		}
	},
	{
		Header: "Montant devis (HT)",
		accessor: "total",
		Cell: cellInfo => {
			let total = 0
			if (cellInfo.row.original.total) {
				total = cellInfo.row.original.total.toFixed(2) + " €"
			} else {
				total = "0.00 €"
			}
			return total
		}
	},

	{
		Header: "",
		accessor: "details",
		Cell: cellInfo => {
			return (
				<Link className="btn-little btn-default-primary color-primary" to={`/projet/${cellInfo.row.original.project.id}`}>Accéder aux documents</Link>
			)
		}
	}
]
