export default {
	// COMMERCIAL
	DIRECTEUR_COMMERCIAL: "ROLE_COM_DIR",
	RESPONSABLE_COMMERCIAL: "ROLE_COM_RES",
	COMMERCIAL: "ROLE_COM",

	// ADMIN
	ADMIN: "ROLE_ADMIN",

	// ARCHITECTE
	ARCHITECTE: "ROLE_ARCH",

	// CUSTOMER
	CUSTOMER: "ROLE_CLIENT",

	// INGENEER
	DIRECTEUR_INGENIEUR: "ROLE_ING_DIR",
	RESPONSABLE_INGENIEUR: "ROLE_ING_RES",
	MANAGER_INGENIEUR: "ROLE_ING_MANAGER",
	AFFAIRES_INGENIEUR: "ROLE_ING_AFF",
	DIAGNOSTIQUEUR_INGENIEUR: "ROLE_ING_DIAG",
	VERIFICATEUR_INGENIEUR: "ROLE_ING_VERIF",
	ASSISTANT_INGENIEUR: "ROLE_ING_EXTRA",
};