import React, { useState } from 'react';
import { useFormikContext } from "formik";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import { initialValuesEditUser } from "../../../../config/InitialValues/InitialValuesEditUser";

const FormEditUserAdminContent = ({ dataRoles, user }) => {

	const { isSubmitting, values } = useFormikContext()

	/*Init values inside form edit*/
	initialValuesEditUser.user.firstname = user.firstname
	initialValuesEditUser.user.lastname = user.lastname
	if (user.gender === undefined) {
		initialValuesEditUser.user.gender = "NA"
	} else {
		initialValuesEditUser.user.gender = user.gender
	}

	user.agencyName ? initialValuesEditUser.user.agencyName = user.agencyName : initialValuesEditUser.user.agencyName = ""

	initialValuesEditUser.user.phone = user.phone
	initialValuesEditUser.user.email = user.email

	if (user.roles) {
		initialValuesEditUser.user.roles.additionalProp1 = user.roles[0]
	}

	return (
		<>
			<div className={"form-row-element form-row-two-elements"}>
				<InputLabelErrorMessage type={"select"} label={"Civilité"} name={"user.gender"} keyValue={"value"} keyLabel={"label"} data={[{ value: "M", label: "Monsieur" }, { value: "W", label: "Madame" }, { value: "Miss", label: "Mademoiselle" }, { value: "NA", label: "Non genré" }, { value: "S", label: "Raison sociale" }]} />
				<InputLabelErrorMessage type={"select"} label={"Rôle"} name={"user.roles.additionalProp1"} keyValue={"value"} keyLabel={"label"} data={dataRoles} />
			</div>
			{
				values.user.gender === "S" && (
					<div className={"form-row-element"}>
						<InputLabelErrorMessage type={"text"} label={"Raison sociale"} name={"user.agencyName"} />
					</div>
				)
			}
			<div className={"form-row-element form-row-two-elements"}>
				<InputLabelErrorMessage type={"text"} label={"Nom"} name={"user.lastname"} />
				<InputLabelErrorMessage type={"text"} label={"Prénom"} name={"user.firstname"} />
			</div>
			<div className={"form-row-element form-row-two-elements"}>
				<InputLabelErrorMessage type={"text"} label={"Téléphone"} name={"user.phone"} />
				<InputLabelErrorMessage readonly={true} type={"text"} label={"Adresse e-mail"} name={"user.email"} />
			</div>
			<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Enregistrer"} />
		</>
	);
};

export default FormEditUserAdminContent;
