import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from "react-router-dom";

import ProjectHeader from '../../Partials/ProjectHeader';
import ProjectNav from '../../Partials/ProjectNav';
import ButtonSubmit from '../../../../components/Atomes/Buttons/ButtonSubmit';
import ProjectStageDepositVir from './Virement/DepositVir';

import { ProjectStageService } from '../../../../services/ProjectStageService';
import ProjectSectionMarketplace from '../../Partials/SectionMarketplace';

const ProjectStageDeposit = () => {

  const navigate = useNavigate();
  const { projectId } = useParams();
  const [depositAmount, setDepositAmount] = useState(0);
  const [projectStepVerificator, setProjectStepVerificator] = useState(false);
  const [project, setProject] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [isCbSelected, setIsCbSelected] = useState(false);
  const [isVirSelected, setIsVirSelected] = useState(false);
  const [isWaitingVirValidation, setIsWaitingVirValidation] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectData();
  }, []);

  const getProjectData = async () => {
    let projectInfos = await ProjectStageService.getSingleProject(projectId);
    if ((projectInfos.stage === 'COM_deposit' || projectInfos.stage === 'ADMIN_payment-validation' || projectInfos.stage === 'ADMIN_payment-follow-up') && !projectInfos.study ) {
      projectInfos.invoices.forEach(element => {
        if (element.isDeposit === true) {

          if (element.payment?.reference && element.payment?.status === 'processing' && element.payment?.method === "card") {
						return navigate("/projet/" + projectId + "/?event=deposit_complete", {replace: true})
					}

          if (element.payment) {
            if (element.payment.status === 'paid') {
            return navigate("/projet/" + projectId + "/?event=deposit_complete", { replace: true });
            }
          }
          setInvoice(element);
          setDepositAmount(element.amountWithTax);
        }
      });
      
      if (projectInfos.stage === 'ADMIN_payment-validation' && projectInfos.stageStatus === 'processing') {
        setIsWaitingVirValidation(true);
      }
      setProject(projectInfos);
      setProjectStepVerificator(true);
    } else {
      navigate("/projet/" + projectId, { replace: true });
    }
  }

  const handleCbChange = () => {
    setIsCbSelected(!isCbSelected);
    setIsVirSelected(false);
  }

  const handleVirChange = () => {
    setIsVirSelected(!isVirSelected);
    setIsCbSelected(false);
  }

  const handleValidMethod = async () => {
    if (isCbSelected) {
      let redirectUrl = `${process.env.REACT_APP_PROD_URL}/projet/${projectId}/?event=deposit_complete`;
      let body = {
        "redirectUrl": redirectUrl
      }
      let request = await ProjectStageService.postPayCb(invoice.id, body)
      if (request.status === 201) {
        window.location.replace(request.data.payment.paymentUrl);
      }
    }
  }

  return (
    <>
      {
        !projectStepVerificator ? (
          <>
            <Skeleton height={300} />
            <Skeleton height={40} />
          </>
        ) : (
          <>
            <ProjectHeader
              project={project}
            />


            <section className='project-container'>
              <ProjectNav
                step={1}
                visit={project.visitType}
              />
              {
                !isWaitingVirValidation && (
                  <div className="project-container-step__header">
                    <h3 className="color-black">Choisissez votre mode de paiement pour l'acompte</h3>
                    <p className="text-primary">
                      Le paiement de l'acompte correspond à 50 % du montant du devis que vous
                      avez signé. À la suite du paiement de l'acompte, vous pourrez passer à
                      l'étape de prise de rendez-vous pour le passage de l'ingénieur. Vous pouvez
                      régler en carte bancaire ou en virement bancaire.
                    </p>
                  </div>
                )
              }

              {
                isWaitingVirValidation && (

                  <>
                    <div className="project-container-step__header">
                      <h3 className="color-black">Nous vérifions votre preuve de paiement</h3>
                      <p className="text-primary">
                        Cette étape peut prendre jusqu'à 24 heures. Vous recevrez un e-mail dès  que votre paiement sera validé.
                        Vous pourrez ensuite passer à l'étape de prise de rendez-vous avec nos ingénieurs.
                      </p>
                    </div>
                    <ProjectSectionMarketplace />
                  </>
                )
              }

              {
                !isWaitingVirValidation && (
                  <>
                    <div className='project-step-payment__selectMethod'>
                      <h4 className='color-black'>Montant acompte : {depositAmount} € TTC</h4>
                      <div className={`project-step-choice ${isCbSelected ? 'valid' : ''}`}>
                        <label htmlFor="cb" className='text-tertiary color-dark-grey'>
                          Régler par carte bancaire
                        </label>
                        <input
                          type='radio' id='cb' name="method"
                          onChange={handleCbChange} checked={isCbSelected}
                        />
                      </div>
                      <div className={`project-step-choice ${isVirSelected ? 'valid' : ''}`}>
                        <label htmlFor="vir" className='text-tertiary color-dark-grey'>
                          Régler par virement bancaire
                        </label>
                        <input
                          type='radio' id='vir' name="method"
                          onChange={handleVirChange} checked={isVirSelected}
                        />
                      </div>
                    </div>

                    {
                      isCbSelected && (
                        <div className='project-step-payment__validMethod'>
                          <ButtonSubmit
                            title='Passer au règlement'
                            styleButton='btn-default btn-default-primary'
                            isActive={!isCbSelected && !isVirSelected}
                            onClick={handleValidMethod}
                          />
                        </div>
                      )
                    }

                    {
                      isVirSelected && (
                        <ProjectStageDepositVir
                          projectInvoice={invoice}
                        />
                      )
                    }

                  </>
                )
              }
            </section>
          </>
        )
      }
    </>
  );
}

export default ProjectStageDeposit;