import React, {useState} from "react";
import {ErrorMessage, useField} from "formik";

const InputLabelFileErrorMessage = ({name, picture, label, ...props}) => {
	const [file, setFile] = useState(picture ? picture : null);
	const [field, meta, helpers] = useField(name);

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
		helpers.setValue(selectedFile);

		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);

	};

	const handleRemoveFile = () => {
		setFile(null);
		helpers.setValue(null);
	};

	return (
		<>
			<div className="input-file">
				<label htmlFor="file-input" className={"text-micro paragraph_bold color-dark-grey"}>{label}</label>
				{file ? (
					<button className="input-file__button input-file__button--loaded" onClick={handleRemoveFile}>
						<span id={name}>
							{file.name ? file.name : picture} <img src="/images/pictos/delete-file.svg"/>
						</span>
					</button>
				) : (
					<label htmlFor={name} className="input-file__label">
          <span className="input-file__button input-file__button--empty">
            Charger un fichier
	          <img src="/images/pictos/upload.svg" alt="upload"/>
          </span>
						<input
							id={name}
							type="file"
							onBlur={field.onBlur}
							onChange={handleFileChange}
							{...props}
						/>
					</label>
				)}
				<ErrorMessage name={name} component={"p"} className={"color-error text-primary"}/>
			</div>
		</>
	)
}

export default InputLabelFileErrorMessage;