import React, { useState, useEffect, useContext } from 'react';
import { ChatbotAdminActionsContext } from '../../ChatbotAdmin';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { Formik, Form, FieldArray, Field, ErrorMessage } from 'formik';
import { RoutineSchema } from './Config/RoutineSchema';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import MultiSelect from '../../../../../../components/Atomes/Select/MultiSelect';

import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtmlPuri from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';


import { AdminService } from '../../../../../../services/AdminService';

const PopupEditRoutine = ({ routineId }) => {

  const chatbotContext = useContext(ChatbotAdminActionsContext)

  const [routine, setRoutine] = useState(null);
  const [routineLoading, setRoutineLoading] = useState(true);
  const answerTypes = [
    {
      value: 'choices',
      label: 'Réponse à choix multiple'
    },
    {
      value: 'choice',
      label: 'Réponse à choix simple'
    },
    {
      value: 'txt',
      label: 'Réponse libre'
    },
    {
      value: 'file',
      label: 'Réponse avec fichier(s)'
    }
  ]
  const [services, setServices] = useState([]);
  const [miniroutines, setMiniroutines] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = error => { };
  };

  const imageUploadCallback = file => new Promise(
    (resolve, reject) => getFileBase64(
      file,
      data => resolve({ data: { link: data } })
    )
  );

  useEffect(() => {
    getRoutineData();
  }, []);

  const getRoutineData = async () => {
    setRoutineLoading(true);
    let request = await AdminService.get(`/chatbot-questions/${routineId}`);
    if (request.status === 200) {

      if (request.data.about) {
        const blocksFromHtml = htmlToDraft(request.data.about);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      setRoutine(request.data);
      const servicesRequest = await AdminService.getWithParams('/services', { pagination: 'false' });
      if (servicesRequest.status === 200) {
        setServices(servicesRequest.data['hydra:member']);
      }

      const miniroutinesRequest = await AdminService.getWithParams('/chatbot-mini-routines', { pagination: 'false' });
      if (miniroutinesRequest.status === 200) {
        setMiniroutines(miniroutinesRequest.data['hydra:member']);
      }

      setRoutineLoading(false);
    }
  }

  const submitForm = async (values, setSubmitting) => {
    setSubmitting(true);

    if (values.type === "choice" || values.type === "choices") {
      let answers = values.answers;
      if (answers.length < 2) {
        toast.error('Il faut au moins 2 réponses !');
        setSubmitting(false);
        return;
      }
      let answersWithoutValue = answers.filter(answer => answer.answer === "");
      if (answersWithoutValue.length > 0) {
        toast.error('Toutes les réponses doivent avoir une valeur !');
        setSubmitting(false);
        return;
      }
    } else {
      values.answers = [routine.chatbotAnswers[0]]
    }

    let data = {
      title: values.title,
      question: values.question,
      type: values.type,
      endMegaRoutine: values.endMegaRoutine,
      chatbotAnswers: values.answers.map(answer => {
        return {
          id: answer.id ? answer.id : null,
          answer: answer.answer,
          servicesIri: answer.servicesIri,
          chatbotMiniRoutineIri: answer.chatbotMiniRoutine ? answer.chatbotMiniRoutineIri : null,
        }
      }),
      about: draftToHtmlPuri(convertToRaw(editorState.getCurrentContent())),
    }

    let request = await AdminService.patch(`/chatbot-questions`, routineId, data);
    if (request.status === 200) {
      toast.success('La question-réponse a bien été modifiée !');
      getRoutineData();
      chatbotContext.setChatbotAdminActionsContext({
        "name": "question-réponse",
      });
    } else {
      toast.error('Une erreur est survenue !');
    }
    setSubmitting(false);
  }

  return (
    <>
      {
        routineLoading ? (
          <div className='adminChatbot-popup__skeleton'>
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
            <Skeleton height={50} width={"100%"} />
          </div>
        ) : (
          <Formik
            initialValues={{
              title: routine.title,
              question: routine.question,
              type: routine.type,
              answers: routine.chatbotAnswers.map(answer => {
                return {
                  id: answer.id,
                  answer: answer.answer,
                  servicesIri: answer.servicesIri,
                  chatbotMiniRoutine: answer.chatbotMiniRoutineIri ? true : false,
                  chatbotMiniRoutineIri: answer.chatbotMiniRoutineIri ? answer.chatbotMiniRoutineIri : '',
                }
              }),
              endMegaRoutine: routine.endMegaRoutine,
            }}
            validationSchema={RoutineSchema}
            onSubmit={(values, { setSubmitting }) => {
              submitForm(values, setSubmitting);
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>

                <div className='form-row-element'>
                  <InputLabelErrorMessage
                    name="title"
                    type="text"
                    label="Titre de la Question-Réponse"
                  />
                </div>

                <div className='form-row-element'>
                  <InputLabelErrorMessage
                    name="question"
                    type="text"
                    label="Question posée au client"
                  />
                </div>


                <div className='form-row-element form-row-label-input'>
                  <label className={"text-micro paragraph_bold color-dark-grey"} htmlFor="type">Type de réponse attendue</label>
                  <Field name="type" id={"type"} as="select" className="select-custom-secondary">
                    <option value={""}>Type de réponse attendue</option>
                    {
                      answerTypes.map((type, index) => (
                        <option key={index} value={type.value}>{type.label}</option>
                      ))
                    }
                  </Field>
                  <ErrorMessage name="type" component="p" className={"color-error text-primary"} />
                </div>

                {
                  (values.type === "choices" || values.type === "choice") && (
                    <>
                      <FieldArray
                        name="answers"
                        render={arrayHelpers => (
                          <>
                            {
                              values.answers.map((anwser, index) => (
                                <section key={index} className={`adminChatbot-routinePopup__manyanswers ${values.answers.length > 2 ? 'adminChatbot-routinePopup__manyanswers-true' : ''}`}>
                                  <div className='form-row-element'>
                                    <div className='form-row-label-input'>
                                      <InputLabelErrorMessage
                                        name={`answers[${index}].answer`}
                                        type="text"
                                        label={`Réponse n°${index + 1}`}
                                      />
                                    </div>

                                    <div className='form-row-label-input'>
                                      <label className={"text-micro paragraph_bold color-dark-grey"} htmlFor={`answers[${index}].servicesIri`}>Code article associée à la réponse n°{index + 1} </label>
                                      <Field
                                        name={`answers[${index}].servicesIri`}
                                        id={`answers[${index}].servicesIri`}
                                        placeholder='Code article associée à la question'
                                        options={
                                          services.map(service => (
                                            { value: `/api/services/${service.id}`, label: service.reference }
                                          ))
                                        }
                                        isMulti={true}
                                        component={MultiSelect}
                                      />
                                    </div>

                                    {
                                      values.answers.length > 2 && (
                                        <img
                                          src="/images/pictos/delete-file.svg"
                                          onClick={() => arrayHelpers.remove(index)}
                                        />
                                      )
                                    }
                                  </div>

                                  <div className='adminChatbot-routinePopup__cascade'>
                                    <label className={"text-tertiary paragraph_bold color-dark-grey"} htmlFor={`answers[${index}].chatbotMiniRoutine`}>
                                      <Field
                                        id={`answers[${index}].chatbotMiniRoutine`}
                                        name={`answers[${index}].chatbotMiniRoutine`}
                                        type="checkbox"
                                      />
                                      Question cascade (entraîne une mini-routine selon la réponse)
                                    </label>

                                    {
                                      values.answers[index].chatbotMiniRoutine && (
                                        <>
                                          <label className={"text-micro paragraph_bold color-dark-grey"} htmlFor={`answers[${index}].chatbotMiniRoutineIri`}>Mini-Routine qui suit la cascade</label>
                                          <Field
                                            name={`answers[${index}].chatbotMiniRoutineIri`}
                                            id={`answers[${index}].chatbotMiniRoutineIri`}
                                            placeholder='Mini-routine'
                                            options={
                                              miniroutines.map(miniroutine => (
                                                { value: `/api/chatbotMiniRoutines/${miniroutine.id}`, label: miniroutine.title }
                                              ))
                                            }
                                            isMulti={false}
                                            component={MultiSelect}
                                          />
                                          <ErrorMessage name={`answers[${index}].chatbotMiniRoutineIri`} component={"p"} className={"color-error text-primary"} />
                                        </>
                                      )
                                    }
                                  </div>
                                </section>
                              ))
                            }
                            <button
                              onClick={() => arrayHelpers.push({ answer: '', servicesIri: [], chatbotMiniRoutine: false, chatbotMiniRoutineIri: '' })}
                              className={"btn-default btn-default-secondary plain adminChatbot-routinePopup__addAnswer"}
                              type='button'>
                              Ajouter une réponse
                            </button>
                          </>
                        )}
                      />
                    </>
                  )
                }

                <div className='form-row-element adminChatbot-routinePopup__checkbox'>
                  <Field
                    name="endMegaRoutine"
                    id="endMegaRoutine"
                    type="checkbox"
                  />
                  <label className={"text-secondary color-dark-grey"} htmlFor='endMegaRoutine'>Cette question-réponse met fin à une méga-routine</label>
                </div>

                <div className='form-row-element form-row-label-input'>
                  <label className={"text-micro paragraph_bold color-dark-grey"}>Bon à savoir de la question</label>
                  <Editor
                    wrapperClassName="mbe-wysiwyg"
                    localization={{
                      locale: 'fr',
                    }}
                    toolbar={{
                      options: ["inline", "colorPicker", "image", 'link', "list"],
                      inline: {
                        options: ["bold", "italic", "underline", "strikethrough"],
                      },
                      colorPicker: {
                        colors: ["#000000", "#ffffff", "#090754", "#4AA9C2", "#9DDFF1", "#648DE5", "#c13e39", "#5FD086", "#eed128"],
                      },
                      image: {
                        uploadCallback: imageUploadCallback,
                        alt: { present: true, mandatory: false },
                        previewImage: true,
                        inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                        defaultSize: {
                          height: "auto",
                          width: "auto",
                        },
                      }
                    }}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                  />
                </div>

                <button className={"btn-default btn-default-secondary plain"} disabled={isSubmitting} type='submit'>
                  Modifier la question-réponse
                </button>

              </Form>
            )}
          </Formik>
        )
      }
    </>
  )
}
export default PopupEditRoutine;
