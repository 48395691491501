export function ConvertObjectToUrlParams(baseUrl, object) {
	const url = Object.entries(object).map(([key, val]) => val ? `${key}=${val}&` : "").join("");
	return `${baseUrl}?${url}`;
}

export function ConvertObjectToParams(baseUrl, object) {
	const url = Object.entries(object).map(([key, val]) => val ? `${key}=${val}&` : "").join('');
	return `${baseUrl}&${url}`;
}

export function ConvertStringToFloat(string) {
	return parseFloat(string).toFixed(2) + " €";
}