import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import ProjectHeader from '../../Partials/ProjectHeader';
import ProjectNav from '../../Partials/ProjectNav';
import ProjectSectionMarketplace from '../../Partials/SectionMarketplace';
import PopupValidatePRICustomer from './Popup/PopupValidatePRI';
import PopupDisprovePRICustomer from './Popup/PopupDisprovePRI';

import { ProjectStageService } from '../../../../services/ProjectStageService';

const ProjectStagePrincipeStructure = () => {

  const { projectId } = useParams();
  const navigate = useNavigate();
  const [projectStepVerificator, setProjectStepVerificator] = useState(false);
  const [project, setProject] = useState(null);
  const [documentReady, setDocumentReady] = useState(false);
  const [showPopupValidation, setShowPopupValidation] = useState(false);
  const [showPopupDisprove, setShowPopupDisprove] = useState(false);

  useEffect(() => {
    getProjectData();
  }, []);

  const getProjectData = async () => {
    let projectInfos = await ProjectStageService.getSingleProject(projectId);
    if (projectInfos.stage !== 'ING_pri') {
      navigate("/projet/" + projectId, { replace: true });
    } else {
      if (projectInfos.priValidation?.validation) {
        setDocumentReady(true);
      } 
      setProject(projectInfos);
      setProjectStepVerificator(true);
    }
  }


  const downloadDocument = async () => {
    await ProjectStageService.getPRI(project.priValidation.id, project.priValidation.filename);
    toast.success("Téléchargement du document en cours");
  }

  const openPopupValidation = () => {
    setShowPopupValidation(true);
  }
  const openPopupDisprove = () => {
    setShowPopupDisprove(true);
  }
  const closePopup = () => {
    setShowPopupValidation(false);
    setShowPopupDisprove(false);
  }

  return (
    <>
      {
        !projectStepVerificator ? (
          <>
            <Skeleton height={300} />
            <Skeleton height={40} />
          </>
        ) : (
          <>
            <ProjectHeader
              project={project}
              projectStep={documentReady && "priValidation"}
              priValidationAction={openPopupValidation}
              priDisproveAction={openPopupDisprove}
            />

            <div className='project-container'>
              <ProjectNav
                step={project.visitType ? 3 : 2}
                visit={project.visitType}
              />

              <div className="project-container-step__header">
                <h3 className="color-black">Validation principe de structure</h3>
                {
                  documentReady ? (
                    <p className="text-primary">Cette étape de votre projet, consiste à valider le principe de structure que votre ingénieur chargé(e) d'affaires a réalisé. Nous vous invitons à prendre connaissance du document pour le valider ou le refuser en expliquant les ajustements à faire.</p>
                  ) : (
                    <p className="text-primary">L'ingénieur chargé(e) d'affaires est train de rédiger votre principe de structure. Vous recevrez un e-mail quand vous devrez le valider.</p>
                  )
                }
              </div>

              <section className='project-step project-step-pri'>
                {
                  documentReady ? (
                    <div className='project-step-pri__document'>
                      {/* <div>
                        <img src={'/images/pictos/mime-types/pdf.svg'} />
                        <p className="text-primary color-primary paragraph_bold">principe-structure</p>
                      </div> */}
                      <button onClick={downloadDocument} className='btn-default btn-default-primary'>Télécharger</button>
                    </div>
                  ) : (
                    <img className='project-step-pri__banner' src='/images/medias/banner-pri.jpeg' />
                  )
                }
                <ProjectSectionMarketplace />
              </section>
            </div>

            <PopupValidatePRICustomer
              projectId={projectId}
              showPopup={showPopupValidation}
              closeAction={closePopup}
            />

            <PopupDisprovePRICustomer
              projectId={projectId}
              showPopup={showPopupDisprove}
              closeAction={closePopup}
            />
          </>
        )
      }
    </>
  )
};

export default ProjectStagePrincipeStructure;