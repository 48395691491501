import { Form, Formik, Field, ErrorMessage } from "formik";
import AsyncMultiSelect from "../../../../../../components/Atomes/Select/AsyncMultiSelect";
import { clientService } from "../../../../../../services/ClientService";
import { useEffect, useState } from "react";
import ButtonSubmit from "../../../../../../components/Atomes/Buttons/ButtonSubmit";
import { initialDevisCreateDevis } from "../../../../../../config/InitialValues/InitialValuesCreateDevis";
import { CreateDevisSchema } from "../../../../../../schemas/commercial/CreateDevisSchema";
import MultiSelect from "../../../../../../components/Atomes/Select/MultiSelect";
import { dataRegions } from "../../../../../../utils/RegionsUtils";
import { dataTypologies } from "../../../../../../utils/Typologie";
import Skeleton from "react-loading-skeleton";
import ProjectService from "../../../../../../services/ProjectService";
import { toast } from "react-toastify";


const FormDevisRefonte = () => {

    const [clientOptions, setClientOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    const getClientOptions = async (search) => {
        if(search !== undefined) {
            if (search.length < 3) {
                return;
            }
            let request = await clientService.getClient({search: search});
            if (request.status === 200) {
                let clientOptions = request.data['hydra:member'].map((client) => {
                    return {
                        label: client.niceNames,
                        value: `/api/users/${client.id}`
                    }
                });
                setClientOptions(clientOptions);
                return clientOptions;
            }
        } else {
            let request = await clientService.getClient({});
            if (request.status === 200) {
                let clientOptions = request.data['hydra:member'].map((client) => {
                    return {
                        label: client.niceNames,
                        value: `/api/users/${client.id}`
                    }
                });
                setClientOptions(clientOptions);
                return clientOptions;
            }
        }

    }

    useEffect(() => {
        if(isLoading) {
            getClientOptions()
            setIsLoading(false)
        }
    }, [])


    const onSubmit = async (values) => {
        if(values.clientIri && values.clientIri && values.categoryType) {
        const request = await ProjectService.CreateNewProject({
            clientIri: values.clientIri,
            region: values.region,
            categoryType: values.categoryType,
        })

        if (request.request.status === 201){
            toast.success("Le projet a été crée avec succès")
            window.location.href = `/devis/${request.data.id}`
        } else {
            toast.error("Une erreur est survenue")
        }
    }
    }

    return (

        <>
            {
                isLoading ? (
                    <Skeleton height={500} />
                ) : (

        <Formik enableReinitialize={true} onSubmit={onSubmit} initialValues={initialDevisCreateDevis} validationSchema={CreateDevisSchema}>
            <Form>
                <div className={"form-row-label-input form-devis-refonte"}>
                    <label htmlFor={"clientIri"} className={"text-micro paragraph_bold color-dark-grey "}>Sélectionner le nom du
client</label>
                    <Field
                        name={'clientIri'}
                        id={'clientIri'}
                        placeholder='Sélection du client'
                        isMulti={false}
                        callback={getClientOptions}
                        component={AsyncMultiSelect}
                        defaultOptions={clientOptions}
                    />
                    <ErrorMessage name={`clientIri`} component={"p"} className={"color-error text-primary"} />

                    <label htmlFor={"region"} className={"text-micro paragraph_bold color-dark-grey"}>Sélectionner la zone
géographique du projet</label>
                    <Field
                        name={'region'}
                        id={'region'}
                        placeholder='Sélection de la région'
                        isMulti={false}
                        component={MultiSelect}
                        options={dataRegions}
                    />
                    <ErrorMessage name={`region`} component={"p"} className={"color-error text-primary"} />
                    <label htmlFor={"categoryType"} className={"text-micro paragraph_bold color-dark-grey"}>Sélectionner la
typologie du projet</label>
                    <Field
                    name={'categoryType'}
                    id={'categoryType'}
                    placeholder='Sélection de la typologie'
                    isMulti={false}
                    component={MultiSelect}
                    options={dataTypologies}
                    />
                    <ErrorMessage name={`categoryType`} component={"p"} className={"color-error text-primary"} />
                </div>
                <ButtonSubmit title={"Créer le projet"} isActive={false} onClick={onSubmit} styleButton={"btn-default btn-default-secondary plain"} />
            </Form>
        </Formik>
                )
            }
        </>
    )
}

export default FormDevisRefonte;