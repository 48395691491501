import React, {useContext, useState} from 'react';
import {Form, Formik} from "formik";
import InputLabelErrorMessage from "../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../Atomes/Buttons/ButtonSubmit";
import {initialValuesEditQuotePartner} from "../../../config/InitialValues/initialValuesEditQuotePartner";
import moment from "moment/moment";
import {EditPartnerQuotesSchema} from "../../../schemas/commercial/EditPartnerQuotesSchema";
import CollaboratorService from "../../../services/CollaboratorService";
import {StateRequest} from "../../../views/Pages/Coms/Res/Home/ManagerHome";
import {StateRequest as StateRequestCom} from "../../../views/Pages/Coms/Com/Archive/Archive";

const FormEditQuotesPartner = ({cellinfo, setIsActive}) => {

	const [valueQuotePatner, setValueQuotePatner] = useState(cellinfo);
	const {stateRequest, setStateRequest} = useContext(StateRequest["_currentValue"] === undefined ? StateRequestCom  : StateRequest);

	const onSubmit = async (values) => {
		const request = await CollaboratorService.updateQuotesPartner(`partner-quotes/${valueQuotePatner.id}`, values)
		setIsActive(false)
		if (request.request.status === 200) {
			setStateRequest("success edit")
		} else {
			setStateRequest("error")
		}
	}
	
	
	
	initialValuesEditQuotePartner.status = valueQuotePatner.status || "";
	initialValuesEditQuotePartner.total = valueQuotePatner.total || "";
	initialValuesEditQuotePartner.profit = valueQuotePatner.profit || "";
	initialValuesEditQuotePartner.signedAt = moment(valueQuotePatner.signedAt).format('yyyy-MM-DD');
	
	
	return (
		<>
			<Formik
				initialValues={initialValuesEditQuotePartner}
				validationSchema={EditPartnerQuotesSchema}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => (
					<Form>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} data={[{label: "Annulé", value: "canceled"}, {label: "En cours", value: "processing"}, {label: "Signé", value: "paid"}]} label={"État demande"} name={"status"}/>
							<InputLabelErrorMessage type={"date"} label={"Date signature"} name={"signedAt"}/>
						</div>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"number"} label={"Montant apport affaire (HT)"} name={"profit"}/>
							<InputLabelErrorMessage type={"number"} label={"Montant devis (HT)"} name={"total"}/>
						</div>
						<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Modifier"}/>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormEditQuotesPartner;
