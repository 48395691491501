import React, {useState} from "react";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import CardCircuitDeValidationStatus from "./CardCircuitDeValidationStatus";
import {Link} from "react-router-dom";
import PopUpValidApprovalCircuit from "../../PopUp/PopUpValidApprovalCircuit";
import exportFileService from "../../../../services/ExportFileService";

export function CardCircuitDeValidation({title, styleButton, niceNames, img, numberFolder, status, color, id, devisId}) {

	const [showPopUp, setShowPopUp] = useState(false);

	const downloadFile = () => {
		exportFileService.exportFile(`/quotes/${devisId}`, 'export-devis', {}, "pdf")
	}

	return (
		<>
			<div className={"card-circuit-de-validation"}>
				 <div className={"card-circuit-de-validation-img"}>
					<img src={`${process.env.REACT_APP_PROD_URL}${img}`} alt={niceNames ? niceNames : ""}/>
				 </div>
				<div className={"card-circuit-de-validation-name"}>
					<h4>{niceNames}</h4>
				</div>
				<div className={"card-circuit-de-validation-dossier"}>
					<p className="text-primary">{numberFolder}</p>
					<CardCircuitDeValidationStatus  title={status} color={color}/>
				</div>
				<ButtonSubmit onClick={() => setShowPopUp(true)} title={title} styleButton={`btn-default ${styleButton}`}/>
				<Link onClick={downloadFile}>Télécharger le devis</Link>
			</div>

			{
				showPopUp ? (
					<PopUpValidApprovalCircuit onClick={() => setShowPopUp(false)} id={id}/>
				) : null
			}
		</>
	)
}

export default CardCircuitDeValidation;
