import React, {useEffect, useState} from 'react';
import ChartBarHorizontal from "../../../../../components/Atomes/Charts/ChartBarHorizontal";
import ChartBar from "../../../../../components/Atomes/Charts/ChartBar";
import ChartDonut from "../../../../../components/Atomes/Charts/ChartDonut";
import {ChartPie} from "../../../../../components/Atomes/Charts/ChartPie";
import FilterTwoSelectsDatepicker from "../../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";
import TitleSectionWithButton from "../../../../../components/Molecules/Title/TitleSectionWithButton";
import KpiService from "../../../../../services/KpiService";
import exportFileService from '../../../../../services/ExportFileService';
import {ConvertObjectToUrlParams} from "../../../../../utils/StringUtils";
import CollaboratorService from "../../../../../services/CollaboratorService";
import moment from "moment/moment";
import {toast} from "react-toastify";

import KpiSimpleCard from '../../../../../components/Atomes/Kpis/KpiSimpleCard';
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

const KPIView = () => {

	const month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
	const options = dataRegionsArray
	const [date, setDate] = useState(new Date());
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [averageBasket, setAverageBasket] = useState(0);
	const [allType, setAllType] = useState([{type: "", total: 0}]);
	const [revenueByMonth, setRevenueByMonth] = useState([{month: "", year: "", total: 0}]);
	const [transformRate, setTransformRate] = useState("");
	const [revenueByType, setRevenueByType] = useState([{type: "", total: 0}]);
	const [revenue, setRevenue] = useState(0);
	const [region, setRegion] = useState("");
	const [monthlyObjective, setMonthlyObjective] = useState(0);
	const [userFilter, setUserFilter] = useState("")
	const [collaborators, setCollaborators] = useState([]);

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}
	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Tous les collaborateurs") {
			setUserFilter("")
		} else {
			setUserFilter(event.target.value)
		}
	}

	const getCollaborators = async () => {
		const urlCom = ConvertObjectToUrlParams("users", {regions: region, roles: "ROLE_COM"})
		const request = await CollaboratorService.getCollaborators(urlCom)
		if (request.request.status === 200) {
			let coms = request.data['hydra:member']
			let availableComs = []
			coms.forEach(com => {
				if (com.roles[0] !== "ROLE_COM_DIR") {
					availableComs.push(com)
				}
			})
			setCollaborators(availableComs)
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	const getAverageBasket = async () => {
		let request = ""
		if (userFilter === "") {
			request = await KpiService.getAverageBasket({region: region, dateStart: dateStart, dateEnd: dateEnd})
		} else {
			request = await CollaboratorService.getCollaboratorAverageBasket(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}
		if (request.request.status === 200) {
			let total = parseFloat(request.data.total);
			setAverageBasket(total.toFixed(2))
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste du panier moyen")
		}
	}

	const getTransformRate = async () => {
		let request
		if (userFilter === "") {
			request = await KpiService.getTransformRate({region: region, dateStart: dateStart, dateEnd: dateEnd})

		} else {
			request = await CollaboratorService.getCollaboratorTransformRate(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}
		if (request.request.status === 200) {
			setTransformRate(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du taux de transformation")
		}
	}

	const getRevenueByType = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getRevenueByType({region: region, dateStart: dateStart, dateEnd: dateEnd})
		} else {
			request = await CollaboratorService.getCollaboratorRevenueByType(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}
		if (request.request.status === 200) {
			setRevenueByType(request.data)
			setAllType(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des revenues par type")
		}
	}

	const getRevenueByMonth = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getRevenueByMonth({region: region, dateStart: dateStart, dateEnd: dateEnd})
		} else {
			request = await CollaboratorService.getCollaboratorRevenueByMonth(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}
		if (request.request.status === 200) {
			setMonthlyObjective(request.data.monthlyObjectives.toFixed(2))
			let data = request.data.revenues
			data.sort((a, b) => parseInt(a.month) - parseInt(b.month))
			data.sort((a, b) => parseInt(a.year) - parseInt(b.year))
			setRevenueByMonth([])
			data.map((item) => {
				setRevenueByMonth(revenueByMonth => [...revenueByMonth, {
					date: month[item.month - 1],
					data: parseInt(item.total)
				}])

			})

		} else {
			toast.error("Une erreur est survenue lors de la récupération des revenues par mois")
		}
	}

	const getRevenue = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getRevenue({region: region, dateStart: dateStart, dateEnd: dateEnd})
		} else {
			request = await CollaboratorService.getCollaboratorRevenue(userFilter, {dateStart: dateStart, dateEnd: dateEnd})
		}

		if (request.request.status === 200) {
			let caTotal = parseInt(request.data.total);
			setRevenue(caTotal.toFixed(2))
		} else {
			toast.error("Une erreur est survenue lors de la récupération du revenue")
		}
	}

	const [signedQuotesCount, setSignedQuotesCount] = useState('')
	const getSignedQuotesCount = async () => {
		let request = ""
		if (userFilter === "") {
			request = await KpiService.getSignedQuotesCount({region: region, dateStart: dateStart, dateEnd: dateEnd})
		} else {
			request = await KpiService.getCollaborateurSignedQuotesCount(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}
		if (request.request.status === 200) {
			setSignedQuotesCount(request.data.total)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de devis signés")
		}
	}

	const [processingQuotesCount, setProcessingQuotesCount] = useState('')
	const getProcessingQuotesCount = async () => {
		let request = ""
		if (userFilter === "") {
			request = await KpiService.getProcessingQuotesCount({region: region, dateStart: dateStart, dateEnd: dateEnd})
		} else {
			request = await KpiService.getCollaborateurProcessingQuotesCount(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}
		if (request.request.status === 200) {
			setProcessingQuotesCount(request.data.total)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de devis en cours d'édition")
		}
	}

	const [requestsCount, setRequestsCount] = useState("")
	const getRequestsCount = async () => {
		let request;
		if (userFilter === "") {
			request = await KpiService.getRequestsCount({region: region, dateStart: dateStart, dateEnd: dateEnd})
		} else {
			request = await KpiService.getCollaboratorRequestsCount(userFilter, {dateStart: dateStart, dateEnd: dateEnd})
		}
		if (request.request.status === 200) {
			let total = 0
			request.data.map((request) => {
				total += request.total
			})
			setRequestsCount(total)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du revenue")
		}
	}

	const [requestsCountByType, setRequestsCountByType] = useState([])
	const getRequestsCountByType = async () => {
		let request;
		if (userFilter === "") {
			request = await KpiService.getRequestsCount({region: region, dateStart: dateStart, dateEnd: dateEnd})
		} else {
			request = await KpiService.getCollaboratorRequestsCount(userFilter, {dateStart: dateStart, dateEnd: dateEnd})
		}
		if (request.request.status === 200) {
			setRequestsCountByType(request.data);
		} else {
			toast.error("Une erreur est survenue lors de la récupération des sources de demande entrante")
		}
	}

	useEffect(() => {
		getCollaborators()
		getAverageBasket()
		getTransformRate()
		getRevenueByType()
		getRevenueByMonth()
		getRevenue()
		getSignedQuotesCount()
		getProcessingQuotesCount()
		getRequestsCount()
		getRequestsCountByType()
	}, [region, userFilter, date])

	const downloadFile = () => {
		exportFileService.exportFile("/sales/kpis", 'export-kpis', {
			dateStart: dateStart,
			dateEnd: dateEnd,
			region: region,
			user: userFilter
		}, "xlsx")
	}
	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}
	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	return (
		<>
			<FilterTwoSelectsDatepicker
				defaultValue={"Toutes les agences"}
				options={options}
				firstValue={options}
				secondDefaultValue={"Tous les collaborateurs"}
				secondOptions={collaborators.map(collaborators => collaborators.lastname)}
				secondValue={collaborators.map(collaborators => collaborators.id)}
				styledButton={"btn-default btn-default-primary btn-default-primary color-primary"}
				titleButton={"Selectionnez une période"}
				date={date}
				onChangeDate={(date) => changeDate(date)}
				onChange={changeRegionFilter}
				onSecondChange={changeCollaboratorFilter}
				resetDateSelected={resetDate}
			/>

			<div className={"section-kpi-title"}>
				<TitleSectionWithButton title={"Indicateurs de performance"} buttonTitle={"Exporter"} isActive={true}
				                        styledButton={"btn-default btn-default-blue btn-download color-primary"}
				                        onClick={downloadFile}
				/>
			</div>

			<div className={"section-kpi-container"}>
				<ChartBar className={"chiffre-affaires custom-chart"} title={"Chiffre d’Affaires"}
				          legend={[{title: "CA Moyen", color: "purple"}, {
					          title: "Objectif Mensuel", color: "green"
				          }]}
				          monthlyObjective={monthlyObjective}
				          content={revenueByMonth}
				/>

				<div className="kpi-container-simpleCards element-kpi-l">
					<KpiSimpleCard title={"Chiffre d’affaires global"} value={!isNaN(revenue) ? `${revenue} €` : 0}/>
					<KpiSimpleCard title={"Panier moyen"} value={!isNaN(averageBasket) ? `${averageBasket} €` : 0}/>
				</div>

				<ChartDonut className={"taux-transformation custom-chart"} data1={transformRate.total * transformRate.rate}
				            data2={transformRate.total} rate={transformRate.rate}
				            legend={[{title: "Devis validés", color: "primary"}, {
					            title: "Devis en cours d'édition", color: "blue-jeans"
				            }]} title={"Taux de transformation"}/>

				{/* <ChartBarBolder className={"type-etude-realise custom-chart"} title={"Type d’études réalisé"}
					legend={[{ title: "CA Moyen 2022", color: "purple" }, {
						title: "Objectif Mensuel", color: "green"
					}]}
					content={allType}
				/> */}

				<div className="kpi-container-simpleCards element-kpi-l">
					<KpiSimpleCard title={"Devis en cours d'édition"} value={processingQuotesCount}/>
					<KpiSimpleCard title={"Devis signés"} value={signedQuotesCount}/>
				</div>


				<ChartPie className={"ca-type-etude custom-chart"}
				          content={revenueByType}
				          title={"Chiffre d'affaires par typologie"}
				/>

				<ChartBarHorizontal className={"type-etude custom-chart"}
				                    title={"Nombre d'études en fonction de la typologie"}
				                    legend={
					                    [
						                    {title: "CA Moyen 2022", color: "purple"}, {
						                    title: "Objectif Mensuel", color: "green"
					                    }
					                    ]
				                    }
				                    content={allType}
				/>

				<ChartPie className={"ca-type-etude custom-chart"}
				          title={"Source demandes entrantes"}
				          content={requestsCountByType}
				/>

				<div className="kpi-container-simpleCards element-kpi-l">
					<KpiSimpleCard title={"Demandes entrantes"} value={requestsCount}/>
				</div>


			</div>
		</>
	)
}
export default KPIView;
