import api from "./APIService";
import {ConvertObjectToParams, ConvertObjectToUrlParams} from "../utils/StringUtils";

const getNewRequests = async (param) => {
	return await api.getAllData(ConvertObjectToParams("projects/context/sales?stage.title[]=COM_commercial-exchange&stageStatus=processing", param))
}

const getCallbackRequests = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("optins", param))
}

const getFolderInProgress = async (param) => {
	return await api.getAllData(ConvertObjectToParams("quotes?status[]=processing&status[]=follow-up&category.type=bill-quote", param))
}

const getSignedFiles = async (param) => {
	return await api.getAllData(ConvertObjectToParams("quotes?status=signed", param))
}

const getFilesInValidation = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("projects/context/sales", param))
}

const getPartnerQuotes = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("partner-quotes", param))
}

const getAdditionalQuote = async (param) => {
	return await api.getAllData(ConvertObjectToParams("quotes?category.type=bill-quote-additional", param))
}

const getSingleRequest = async (id) => {
	return await api.getAllData(`projects/${id}/quotes`)
}

const getFileToSend = async (param) => {
	return await api.getAllData(ConvertObjectToParams("quotes?status=open&project.stage.title=COM_quotation", param))
}

const getFileArchived = async(userId, param) => {
	return await api.getAllData(ConvertObjectToParams(`quotes?status=archived&project.users.id=${userId}&pagination=true`, param))
}

const reopenFileArchived = async(id, param) => {
	return await api.patch(`quotes/${id}`, param)
}

const getClient = async(id) => {
	return await api.getAllData(`clients/${id}`)
}

const getProjectInformations = async(id) => {
	return await api.getAllData(`projects/${id}/context/sales`)
}

const patchClientInformations = async(id, params) => {
	return await api.patch(`clients/${id}`, params)
}

const postQuotesInformations = async(params) => {
	return await api.postBody(`quotes`, params)
}

const patchQuotesInformations = async(id, params) => {
	return await api.patch(`quotes/${id}`, params)
}

const deleteQuotesInformations = async(id) => {
	return await api.del(`bills/${id}`)
}

const getPartnerInformations = async (id) => {
	return await api.getAllData(`partner-quotes?project.id=${id}`)
}

const postPartnerInformations = async (params) => {
	return await api.postBody(`partner-quotes`, params)
}

const patchPartnerInformations = async (id, params) => {
	return await api.patch(`partner-quotes/${id}`, params)
}

const deletePartnerInformations = async (id) => {
	return await api.del(`bills/${id}`)
}

const postProjectInformations = async (params) => {
	return await api.postBody(`projects/context/sales`, params)
}

const patchProjectInformations = async (id, params) => {
	return await api.patch(`projects/${id}/context/sales`, params)
}

const RequestService = {
	getNewRequests,
	getCallbackRequests,
	getFolderInProgress,
	getSignedFiles,
	getPartnerQuotes,
	getAdditionalQuote,
	getSingleRequest,
	getFilesInValidation,
	getFileToSend,
	getFileArchived,
	reopenFileArchived,
	getClient,
	getProjectInformations,


	patchClientInformations,
	postQuotesInformations,
	patchQuotesInformations,
	deleteQuotesInformations,
	postPartnerInformations,
	patchPartnerInformations,
	deletePartnerInformations,
	postProjectInformations,
	patchProjectInformations,
	getPartnerInformations
}

export default RequestService;
