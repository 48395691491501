import React, {useEffect, useState} from 'react';
import {useFormikContext} from "formik";
import ButtonAddElementForm from "../../../../../../../components/Atomes/Buttons/ButtonAddElementForm";
import TitleSection from "../../../../../../../components/Atomes/Title/TitleSection";
import ButtonWithOnClickAction from "../../../../../../../components/Atomes/Buttons/ButtonWithOnClickAction";
import ButtonDeleteElementForm from "../../../../../../../components/Atomes/Buttons/ButtonDeleteElementForm";

const FormOptionalContract = ({project}) => {
	const {values, setFieldValue} = useFormikContext()

	/*useState of optionalContract*/
	const [optionalContract, setOptionalContract] = useState(values.optionalContract);
	/*Function to add  or remove optional Contract*/
	const handleChangeOptionalContract = () => {
		setOptionalContract(!optionalContract)
		setFieldValue("optionalContract", project.optionalContract)
	}


	useEffect(() => {
		if(project.optionalContract) {
			setFieldValue("optionalContract", project.optionalContract)
			setOptionalContract(true)
		}
	}, [project])


	/*Initial Values*/
	useEffect(() => {
		values.optionalContract = optionalContract
	}, [optionalContract]);

	return (<>
			{!optionalContract ?
				<div className={"form-devis-container-optional-contract"}>
					<ButtonAddElementForm onClick={(e) => handleChangeOptionalContract()} title={"Ajouter un contrat optionnel"}/>
				</div> :
				<div className={"form-devis-container-optional-contract-selected"}>
					<TitleSection title={"Contrat Optionnel"}/>
					<div className={"form-devis-container-optional-contract-selected-description-button"}>
						<p className={"text-primary color-primary"}>Le contrat optionnel est généré automatiquement grâce aux
							informations renseignées lors de ce devis</p>
						<ButtonWithOnClickAction title={"Télécharger le contrat"} isActive={true}
						                         styleButton={"btn-default btn-default-secondary"}/>
					</div>
					<div className={"form-devis-container-optional-contract-selected-delete"}>
						<ButtonDeleteElementForm onClick={(e) => handleChangeOptionalContract()}
						                         title={"Supprimer le contrat optionnel"}/>
					</div>
				</div>

			}
		</>
	);
};

export default FormOptionalContract;
