import React, { createContext, useEffect, useState } from 'react';
import TableSearchToggleTitle from "../../../../../components/Molecules/Tables/TableSearchToggleTitle";
import ProjectService from "../../../../../services/ProjectService";
import moment from "moment";
import exportFileService from "../../../../../services/ExportFileService";
import { toast } from "react-toastify";
import { LIST_PROJECTS_COLUMNS } from "../../../../../config/ArrayColumns/Engineer/ListProjectsColumns";
import { LIST_PROJECTS_COLUMNS_RES } from "../../../../../config/ArrayColumns/Engineer/ListProjectsColumnsRes";


export const StateRequest = createContext()
const ProjectsListIng = () => {
	const [search, setSearch] = useState("");
	const [projectCollectionInProgress, setProjectCollectionInProgress] = useState([]);
	const [projectCollectionDone, setProjectCollectionDone] = useState([]);
	const [stateRequest, setStateRequest] = useState("");
	const [loading, setLoading] = useState(true);

	const getProjectsDone = async () => {
		const request = await ProjectService.getProjectsArchived({ search: search, "pagination": "true" })
		if (request.request.status === 200) {
			let data = request.data
			setProjectCollectionDone(data)
			setLoading(false)
		} else (
			toast.error("Une erreur est survenue lors de la récupération des projets archivés")
			)
		}

		const getProjectsInProgress = async () => {
			const request = await ProjectService.getProjectsInProgress({ search: search, "pagination": "true" })
			if (request.request.status === 200) {
				let data = request.data
			setProjectCollectionInProgress(data)
			setLoading(false)
		} else (
			toast.error("Une erreur est survenue lors de la récupération des projets en cours")
		)
	}

	const downloadFile = () => {
		const date = moment().format("L").split("/").join("-")
		exportFileService.exportFile(`/admin/projects`, `export-projects-${date}`, { search: search }, "xlsx")
	}

	const DisplayToast = (type) => {
		if (type === "success cloture") {
			toast.success("Le dossier a bien été clôturé")
		} else if (type === "success correction request"){
			toast.success("La demande de correction a bien été envoyé")
		}else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	useEffect(() => {
		setLoading(true)
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getProjectsDone()
		getProjectsInProgress()
	}, [search, stateRequest]);


	const userRole = localStorage.getItem("role")
	let columnTable = []
	userRole === '["ROLE_ING_RES"]' || userRole === '["ROLE_ING_DIR"]' ?
		columnTable = LIST_PROJECTS_COLUMNS_RES
		: columnTable = LIST_PROJECTS_COLUMNS

	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<TableSearchToggleTitle
				loading={loading}
				title={"Bibliothèque projets"}
				dataActived={projectCollectionInProgress}
				dataArchived={projectCollectionDone}
				columnTable={columnTable}
				placeHolder={"Rechercher un projet"}
				onChangeSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
				onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
			/>
		</StateRequest.Provider>
	);
};

export default ProjectsListIng;
