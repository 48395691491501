import React, {useEffect, useState} from 'react';
import Skeleton from "react-loading-skeleton";

import HomeIngManTeams from './Teams/HomeIngManTeams';
import HomeIngManCalendar from './Calendar/HomeIngManCalendar';

import DirRespService from '../../../../../services/Ing/DirResp/DirRespService';
import HomeIngProdTasks from "../../Prod/Home/Tasks/HomeIngProdTasks";
import {TasksInProgressService} from "../../../../../services/Engineer/TasksInProgressService";
import {toast} from "react-toastify";
import jwt_decode from "jwt-decode";

const HomeIngManager = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [projects, setProjects] = useState([])

	const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
	const decodedToken = jwt_decode(user.token)
	const userId = decodedToken.id

	const getUserProjects = async (id) => {
		const request = await TasksInProgressService.getEngineerProdTasks(id)
		if (request.request.status === 200) {
			setProjects(request.data["hydra:member"])
			setIsLoading(false)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des tâches.")
		}
	}

	useEffect(() => {
		getUserProjects(userId)
	}, []);

	return (
		<section className='mbe-section'>
			{
				isLoading ?
					(
						<Skeleton height={500}/>
					) : (
						<>
							<HomeIngManTeams/>
							{
								projects.length === 0 ?
										<h3 className='color-primary margin-bottom-25'>Aucune tâche en cours</h3>
									:
									<HomeIngProdTasks projects={projects}/>
							}
							<HomeIngManCalendar/>
						</>
					)
			}
		</section>
	);
};

export default HomeIngManager;
