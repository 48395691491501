const HomeCustomerBannerClose = ({ toggleBanner }) => {
  return (
    <section className="homeCustomerBanner homeCustomerBanner-close">
      <div>
        <h1 className="color-primary">Vos projets étape par étape</h1>
        <p className="color-primary text-primary">Nous vous
          accompagnons en tant que bureau d’étude structure durant toute la durée de nos
          missions. Découvrez les étapes clés d’un projet chez mon bureau d’études.</p>
        <span className="color-primary text-primary" onClick={toggleBanner}>
          En savoir plus
          <img src="/images/pictos/arrow-left.svg" alt="Arrow Right" />
        </span>
      </div>
      <figure>
        <img src="/images/medias/intro-client.png" />
      </figure>
    </section>
  )
}

export default HomeCustomerBannerClose;
