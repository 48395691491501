import React, {useEffect, useState} from 'react';
import {ErrorMessage, Form, Formik} from 'formik';
import ButtonSubmit from "../../../../../../components/Atomes/Buttons/ButtonSubmit";
import {InitialValuesProfilJobAccount} from "./Config/InitialValuesProfilJobAccount";
import {SchemaProfileIngJobAccount} from "./Config/SchemaProfileIngJobAccount";
import api from "../../../../../../services/APIService";
import InputLabelTagIngErrorMessage from "../../../../../../components/Atomes/Formik/InputLabelTagIngErrorMessage";
import axios from "axios";

const ProfileJobForm = () => {

	const [preview, setPreview] = useState(null);
	const [userJob, setUserJob] = useState(null);
	const [tags, setTags] = useState([])
	const [userRole, setUserRole] = useState(null);

	const allCompetences = [
		{"name": "Autocad"},
		{"name": "Autocad LT"},
		{"name": "Robot Structural Analysis"},
		{"name": "Graitec Arche Hybride"},
		{"name": "SCIA Engineer"},
		{"name": "Béton"},
		{"name": "Bois"},
		{"name": "Mixte"},
		{"name": "Charpentes métalliques"},
		{"name": "Métal"},
		{"name": "Sika CarboDur"},
		{"name": "Débutant"},
		{"name": "Junior"},
		{"name": "Confirmé"},
		{"name": "Senior"},
		{"name": "Béton précontraint"},
		{"name": "Diagnostic pathologie du bâtiment"},
		{"name": "Ingénieur diagnostiqueur"}
	]


	const handleFileChange = (event, setFieldValue) => {
		const file = event.target.files[0];
		setFieldValue('signature', file);
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreview(reader.result);
			};
			reader.readAsDataURL(file);
		} else {
			setPreview(null);
		}
	};

	const deleteCompetenceTag = (title) => {
		let competencesTagUpdate = tags.filter(competenceTag => competenceTag.title !== title)
		setTags(competencesTagUpdate)
	}

	const updateCompetenceTag = (e) => {
		setTags([...tags, {title: e, color: "red"}])
	}

	const getUserData = async () => {
		const userId = localStorage.getItem("userId");
		if (userId != null) {
			let request = await api.get(`ings/${userId}`);
			setUserJob(request);
			setTags(request.tags)
			setUserRole(request.roles[0]);

			if (request.urlSignature) {
				InitialValuesProfilJobAccount.signature = request.urlSignature;
				setPreview(`${process.env.REACT_APP_PROD_URL}${request.urlSignature}`);
			}
		}
	}

	const onSubmit = async (values, actions) => {
		const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
		const userId = localStorage.getItem("userId");
		values.tags = tags

		const updatedData = {
			tags: values.tags.map(tag => {
				const {color, ...rest} = tag; // Utilisation de la déstructuration pour extraire la propriété "color"
				return rest; // Retourner l'objet sans la propriété "color"
			})
		};

		await api.patch(`ings/${userId}`, {tags: updatedData.tags, roles: [userRole]})

		if (typeof values.signature !== 'string') {

			const file = new FileReader();
			file.readAsDataURL(values.signature);

			const requete = axios.create({
				baseURL: process.env.REACT_APP_PROD_API_URL,
				headers: {
					Authorization: `Bearer ${user ? user.token : ""}`,
					'Content-Type': `multipart/form-data`
				}
			})

			const updateSignature = await requete.post(`ings/${userId}/signature`, {file: values.signature})
			if (updateSignature.status === 201) {
				window.location.reload();
			}
		}
	};

	useEffect(() => {
		getUserData();
	}, []);


	return (
		<div className='mbe-profile-container mbe-section'>
			<h3 className='color-primary'>Mes informations professionnelles</h3>
			<Formik
				initialValues={InitialValuesProfilJobAccount}
				validationSchema={SchemaProfileIngJobAccount}
				onSubmit={onSubmit}
			>
				{({isSubmitting, setFieldValue}) => (
					<Form>

						{/*<div className={"form-row-element"}>
							<InputLabelErrorMessage
								label="Intitulé de poste"
								name="job"
								type="text"
							/>
						</div>*/}

						<InputLabelTagIngErrorMessage
							type={"select"}
							name={"tags"}
							label={"Compétences techniques"}
							placeholder={"Ajouter une compétence"}
							keyLabel={"title"}
							keyValue={"title"}
							keyType={"color"}
							dataTag={tags}
							data={allCompetences}
							onClick={(title) => deleteCompetenceTag(title)}
							onChange={(e) => updateCompetenceTag(e.target.value)}
						/>

						<div className={"form-row-element signature"}>
							<label className={"text-micro paragraph_bold color-dark-grey"}>Signature</label>
							<div className={"signature-input"}>
								<img src={"/images/pictos/ing-job-signature.svg"} alt={"img edit signature"}
								     className={"signature-input-absolute"}/>
								<label htmlFor="signature" style={{cursor: 'pointer'}}>
									{preview ? (
										<img
											className={"signature-input-card"}
											style={{width: '344px', height: '94px', border: '2px solid #648DE5', objectFit: 'contain'}}
											src={preview}
											alt="Signature preview"
										/>
									) : (
										<div style={{width: '344px', height: '94px', border: '2px solid #648DE5'}}
										     className={"signature-input-card"}>
											<p className={"text-primary color-primary"}>Cliquez pour sélectionner une image</p>
										</div>
									)}
									<input
										id="signature"
										name="signature"
										type="file"
										style={{display: 'none'}}
										onChange={(event) => handleFileChange(event, setFieldValue)}
									/>
								</label>
							</div>
							<ErrorMessage name="signature" component="p" className={"color-error text-primary"}/>
						</div>

						<ButtonSubmit
							styleButton={"btn-default btn-default-secondary plain"}
							isActive={isSubmitting} title={"Sauvegarder"}
						/>
					</Form>
				)
				}
			</Formik>
		</div>
	)
}

export default ProfileJobForm;