import React, {useState} from "react";
import {IsLoggedIn} from "../../../utils/RolesChecker";
import ChatbotPopupLeave from "../Popup/ChatbotPopupLeave";

const ChatbotHeader = () => {

	const IsLoggedInConst = IsLoggedIn();

	const [showPopup, setShowPopup] = useState(false);
	const togglePopup = () => {
		setShowPopup(!showPopup);
	}
	const leaveChatbot = () => {
		if (IsLoggedInConst) {
			window.location.href = "/";
		} else {
			window.location.href = process.env.REACT_APP_VITRINE_URL;
		}
	}

	return (
		<>
			<div className="mbe-chatbot__header">
				<h3 className="color-primary">Bienvenue sur le chatbot de mon bureau d’études.</h3>
				<img
					src={"/images/pictos/cross-close.svg"}
					onClick={() => togglePopup()}
				/>
			</div>

			{
				showPopup && (
					<ChatbotPopupLeave
						showPopup={showPopup}
						closeAction={togglePopup}
						validAction={leaveChatbot}
					/>
				)
			}
		</>
	);
}

export default ChatbotHeader;
