import React, { useEffect, useState } from 'react';
import ChartDonut from "../../../../../../components/Atomes/Charts/ChartDonut";
import ChartBar from "../../../../../../components/Atomes/Charts/ChartBar";
import Cart from "../../../../../../components/Atomes/Charts/Cart";
import KpiSimpleCard from '../../../../../../components/Atomes/Kpis/KpiSimpleCard';
import { ChartPie } from "../../../../../../components/Atomes/Charts/ChartPie";

import TitleSection from "../../../../../../components/Atomes/Title/TitleSection";

const Overview = ({ monthlyObjective, kpi, transformRate, revenueByType, revenueByMonth, requestsCount, recoveryCount }) => {
	const month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
	const [revenueByMonthData, setRevenueByMonthData] = useState([]);

	useEffect(() => {
		revenueByMonth.sort((a, b) => parseInt(a.month) - parseInt(b.month))
		revenueByMonth.sort((a, b) => parseInt(a.year) - parseInt(b.year))
		setRevenueByMonthData([])
		revenueByMonth.map((item) => {
			if (item.total !== 0) {
				setRevenueByMonthData(revenueByMonthData => [...revenueByMonthData, {
					date: month[item.month - 1],
					data: parseInt(item.total)
				}])
			}
		})

	}, [revenueByMonth])
	return (
		<div className={"mbe-section"}>
			<TitleSection title={"Tableau de bord"} />

			<div className={"section-kpi-overview"}>
				<ChartBar
					monthlyObjective={monthlyObjective}
					title={"Chiffre d’affaires en HT"}
					legend={
						[
							{ title: "CA Moyen", color: "purple" },
							{ title: "Objectif Mensuel", color: "green" }
						]
					}
					content={revenueByMonthData}

				/>


				<ChartDonut data1={transformRate.total * transformRate.rate} data2={transformRate.total}
				            rate={transformRate.rate} legend={[{ title: "Devis validés", color: "primary" }, {
					title: "Devis en cours d'édition", color: "blue-jeans"
				}]} title={"Taux de transformation"} />

				<div className="kpi-container-simpleCards">
					<KpiSimpleCard title={"Panier moyen"} value={`${kpi} €`} />
				</div>

				<ChartPie content={revenueByType} title={"Chiffre d’affaires par Typologie"} />

				<div className="kpi-container-simpleCards">
					<KpiSimpleCard title={"Dossiers en recouvrement"} value={recoveryCount} />
					<KpiSimpleCard title={"Demandes entrantes (Chatbot)"} value={requestsCount} />
				</div>

			</div>
		</div>);
};

export default Overview;
