import React from 'react';
import ButtonSubmit from "../../Atomes/Buttons/ButtonSubmit";
import {Form, Formik} from "formik";
import InputLabelErrorMessage from "../../Atomes/Formik/InputLabelErrorMessage";
import {initialValuesAddArchitect} from "../../../config/InitialValues/initialValuesAddArchitect";
import {SchemaArchitect} from "../../../schemas/Architect/SchemaArchitect";
import architectService from "../../../services/ArchitectService";

const FormAddArchitect = ({setRequestArchForm, project, setShowPopUp}) => {
  
  const onSubmit = async (values, actions) => {
    values.projectIri = `/api/projects/${project.id}`
    setShowPopUp(false)
    const request = await architectService.postNewArch(values)
    if (request.request.status === 201){
      setRequestArchForm("success add")
    }else{
      setRequestArchForm("error add")
    }
    actions.resetForm();
  }

  return (
    <Formik initialValues={initialValuesAddArchitect} validationSchema={SchemaArchitect} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
      <Form>
        <div className={"form-row-element form-row-two-elements"}>
          <InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"}  name={"gender"} data={[{value : "M" , label :"M"},{value : "W" , label :"Mme"}]} label={"Civilité"}/>
        </div>
        <div className={"form-row-element form-row-two-elements"}>
          <InputLabelErrorMessage label={"Adresse e-email"} name={"email"} type={"text"}/>
          <InputLabelErrorMessage label={"Numéro de téléphone"} name={"phone"} type={"text"}/>
        </div>
        <div className={"form-row-element form-row-two-elements"}>
          <InputLabelErrorMessage label={"Prénom"} name={"firstname"} type={"text"}/>
          <InputLabelErrorMessage label={"Nom"} name={"lastname"} type={"text"}/>
        </div>
        <div>
          <ButtonSubmit title={"Ajouter un nouveau gestionnaire"} isActive={false} styleButton={"btn-default btn-default-secondary plain"}/>
        </div>
      </Form>)}
    </Formik>
  );
};

export default FormAddArchitect;
