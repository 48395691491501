import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

import ProjectHeader from '../../Partials/ProjectHeader';
import ProjectNav from '../../Partials/ProjectNav';
import PopupProjectStageStudyValidation from './Popup/PopupStudyValidation';
import PopupProjectStageStudyModification from './Popup/PopupStudyModification';

import { ProjectStageService } from '../../../../services/ProjectStageService';
import exportFileService from '../../../../services/ExportFileService';
import ButtonWithOnClickAction from "../../../../components/Atomes/Buttons/ButtonWithOnClickAction";
import { useAuth } from '../../../../services/useAuth';

const ProjectStageStudyValidation = () => {

  const { projectId } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [projectStudy, setProjectStudy] = useState(null);
  const [projectStepVerificator, setProjectStepVerificator] = useState(false);
  const [isDefaultStep, setIsDefaultStep] = useState(false);
  const [isStepEditing, setIsStepEditing] = useState(false);
  const [isStepUpdated, setIsStepUpdated] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [isStepValidated, setIsStepValidated] = useState(false);

  const {role} = useAuth()

  const getProjectData = async () => {
    let projectInfos = await ProjectStageService.getSingleProject(projectId);
    if (projectInfos.stage === "COM_delivery" || projectInfos.stage === "ING_correction-study") {
      if (projectInfos.stage === "ING_correction-study") {
        setIsStepEditing(true);
      } else {
        setIsDefaultStep(true);
      }
      setProjectStudy(projectInfos.study.content);
      setProject(projectInfos);
      setProjectStepVerificator(true);
    } else {
      navigate("/projet/" + projectId, { replace: true });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectData();
  }, []);

  const closeAction = () => {
    setShowPopup(false);
    setShowEditPopup(false);
    if (isStepValidated) {
      navigate("/projet/" + projectId, { replace: true });
    }
  };
  const editAction = () => {
    setShowEditPopup(true);
  }
  const submitAnswerEditStudy = async (message) => {
    console.log(message);
    /* if (isStepValidated) {
      navigate("/projet/" + projectId, { replace: true });
    }
    const studyId = project.study.id;
    const body = {
      "validation": false
    }
    let request = await ProjectStageService.patchStudy(studyId, body);
    if (request.status === 200) {
      setShowEditPopup(false);
      setIsStepEditing(true);
      setIsDefaultStep(false);
      toast.success("Votre demande de modification a été envoyée avec succès");
    } */
  }

  const downloadAction = () => {
    exportFileService.exportFile('/studies/' + project.study.id, 'dossier-etude-structure', {}, "pdf")
    setTimeout(() => {
      navigate("/projet/" + projectId, { replace: true });
    }, 5000);
  }

  const submitAnswerStudy = async () => {
    const studyId = project.study.id;
    const body = {
      "validation": true
    }
    let request = await ProjectStageService.patchStudy(studyId, body);
    if (request.status === 200) {
      toast.success("Votre dossier d'étude structure a été validé avec succès");
      setShowPopup(true);
      setIsStepValidated(true);
    }
  }

  const downloadFile = () => {
    toast.info("Votre dossier d'étude structure est en cours de téléchargement");
    exportFileService.exportFile(`/studies/${project.study.id}`, 'dossier-etude', {}, "pdf")
  }

    return (
      <>
        {
          !projectStepVerificator ? (
            <>
              <Skeleton height={300} />
              <Skeleton height={40} />
            </>
          ) : (
            <>
              <ProjectHeader
                projectStep={"studyValidation"}
                project={project}
                studyValidationStep={isStepEditing ? "editing" : "default"}
                studyValidationStepEditAction={editAction}
                studyValidationStepValidAction={submitAnswerStudy}
              />
              <div className={"project-container"}>
                <ProjectNav
                  step={6}
                  visit={project.visitType}
                />

                <div className="project-container-step__header">
                  <div>
                    {isDefaultStep && (
                      <>
                        <h3>Rendu dossier d'étude structure</h3>
                        <p className="text-primary">Nous vous invitons à prendre connaissance de votre dossier d'étude structure. Si vous voulez apporter des modifications, vous pouvez le faire maintenant en cliquant sur l’icône du chat interne en bas à droite. Nous sommes à votre écoute si vous avez des demandes.</p>
                      </>
                    )}

                    {isStepEditing && (
                      <>
                        <h3>Votre dossier d'étude structure est en cours de modification</h3>
                        <p className="text-primary">Vous recevrez une notification lorsque l'ingénieur aura terminé les modifications sur votre dossier d'étude structure. Vous pouvez continuer à consulter la version précédente ci-dessous.</p>
                      </>
                    )}

                    {isStepUpdated && (
                      <>
                        <h3>Votre dossier à été mis à jour</h3>
                        <p className="text-primary">Vous pouvez consulter ci-dessous la nouvelle version.</p>
                      </>
                    )}

                  </div>
                </div>

                <section className='project-step-validation'>

                  <ButtonWithOnClickAction onClick={() =>  downloadFile()}
                                           title={"Télécharger dossier d'étude structure"} styleButton={"margin-bottom-40 button-align-center btn-default btn-default-secondary plain"}
                                           isActive={true}/>


                  <div className="project-container-step__header">
                    <h3>Merci d'avoir choisi mon bureau d’études. pour vous accompagner dans votre projet.</h3>
                    <p className="text-primary">
                    Votre retour d'expérience est primordial, nous vous invitons à nous laisser un  <a
                      href='https://g.page/r/CVZbFQ7ZFDCPEAg/review' target='_blank' rel="noreferrer">avis</a>. Retrouvez l'ensemble de
                      vos documents dans l'onglet "Documents".<br/><br/> Pour toute question complémentaire,
                      nous vous invitons à nous envoyer une demande depuis le chat interne en cliquant
                      en bas à droite sur l’icône bleu. L’ingénieur chargé(e) d’affaires pourra répondre à
                      vos questions et apporter des modifications sur votre dossier d’étude structure si
                      nécessaire.
                    </p>
                  </div>

                  <div className='project-step project-step-finished'>
                    <div className='project-step-finished__item'>
                      <h4>Vous avez un autre projet à réaliser ?</h4>
                      <img src='/images/medias/indicators-project-finished/starter.svg' alt='start new project'/>
                      <p className='text-primary color-black'>Nous serions ravis de vous accompagner.</p>
                      <Link to={"/chatbot"} className='btn-default btn-default-secondary'>Commencer un nouveau projet</Link>
                    </div>

                    <div className='project-step-finished__item'>
                      <h4>Vous avez des questions sur votre dossier ?</h4>
                      <img src='/images/medias/indicators-project-finished/documents.svg' alt='start new project'/>
                      <p className='text-primary color-black'>Poser vos questions depuis le chat interne.</p>
                    </div>

                    {
                      role[0] === "ROLE_CLIENT" || role[0] === "ROLE_ARCH" ? (
                        <div className='project-step-finished__item'>
                          <h4>Laissez nous un avis.</h4>
                          <img src='/images/medias/indicators-project-finished/marketplace.svg' alt='start new project'/>
                          <p className='text-primary color-black'>Votre retour d’expérience est primordial.</p>
                          <Link to={"https://g.page/r/CVZbFQ7ZFDCPEAg/review"} target={"_blank"}
                                className='btn-default btn-default-secondary'>Laissez un avis</Link>
                        </div>
                      ) : null
                    }

                  </div>

                </section>

              </div>

              <PopupProjectStageStudyValidation
                showPopup={showPopup}
                closeAction={closeAction}
                downloadAction={downloadAction}
              />

              <PopupProjectStageStudyModification
                showPopup={showEditPopup}
                closeAction={closeAction}
                validAction={(message) => submitAnswerEditStudy(message)}
              />

            </>
          )
        }
      </>
    )
  }

  export default ProjectStageStudyValidation;
