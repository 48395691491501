import React from 'react';
import {Form, Formik} from "formik";
import {InitialValuesIngDPGF} from "../../../../config/InitialValues/Engineer/InitialValuesIngDPGF";
import {ingDPGFSchema} from "../../../../schemas/Engineer/IngDPGFSchema";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";

const FormAddDPGF = ({onSubmit, onClick}) => {
	const handleSubmit = async (values, actions) => {
		onSubmit(values)
		actions.resetForm()
		onClick()
	}

	InitialValuesIngDPGF.title = ""
	InitialValuesIngDPGF.origin = ""
	InitialValuesIngDPGF.unit = ""
	InitialValuesIngDPGF.quantity = ""
	InitialValuesIngDPGF.total = ""

	return (
		<Formik
			initialValues={InitialValuesIngDPGF}
			validationSchema={ingDPGFSchema}
			onSubmit={handleSubmit}
		>

			{({isSubmitting}) => (
				<Form>
					<div className={"form-row-element form-row-two-elements"}>
						<InputLabelErrorMessage type={"text"} label={"Désignation ouvrages"} name={"title"}/>
						<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"}
						                        data={[{
							                        label: "3.1 Sujétions de l'entreprise - Options",
							                        value: "3"
						                        }, {label: "3.2 Variantes", value: "4"}]}
						                        label={"Choix du sujet"} name={"origin"}/>
					</div>
					<div className={"form-row-element form-row-two-elements"}>
						<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"}
						                        data={
							                        [{label: 'U', value: 'U'}, {label: 'M2', value: 'M2'}, {
								                        label: 'M3',
								                        value: 'M3'
							                        }, {label: 'KG', value: 'KG'}, {label: 'ML', value: 'ML'}, {
								                        label: 'ENS.',
								                        value: 'ENS.'
							                        }, {label: 'EUR', value: 'EUR'}]
						                        } label={"Unité"} name={"unit"}/>
						<InputLabelErrorMessage type={"number"} label={"Quantité"} name={"quantity"}/>
					</div>

					<div className={"form-row-element form-row-two-elements"}>
						<InputLabelErrorMessage type={"number"} label={"P.V HT"} name={"total"}/>

					</div>

					<div>
						<ButtonSubmit title={"Ajouter"} isActive={false} styleButton={"btn-default btn-default-secondary plain"}/>
					</div>
				</Form>
			)}

		</Formik>
	)
}

export default FormAddDPGF;
