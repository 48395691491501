import AsyncSelect from 'react-select/async';

const AsyncMultiSelect = ({
	form,
	field,
	callback,
	defaultOptions,
	isMulti = false,
	placeholder = 'Select',
}) => {

	const setFieldValue = (option) => {
		form.setFieldValue(
			field.name,
			option.value
		)
	}

	return (
		<AsyncSelect
			className={isMulti ? "react-select-container mbe-multiselect" : "mbe-multiselect-one"}
			classNamePrefix="react-select"
			name={field.name}
			isMulti={isMulti}
			onBlur={field.onBlur}
			placeholder={placeholder}
			onChange={setFieldValue}
			loadOptions={callback}
			defaultOptions={defaultOptions}
			noOptionsMessage={ () => 'Aucune option' }
			loadingMessage={ () => 'Chargement ...' }
		/>
	)

}

export default AsyncMultiSelect;