import React from 'react';

const ChatMessage = ({date, title, isNew, isActive, onClick, id}) => {

	const convertDate = (date) => {
		if(date !== null) {
			const dateObject = new Date(date * 1000)
			let day = dateObject.getDate()
			if (day < 10) {
				day = `0${day}`
			}
			let month = dateObject.getMonth() + 1
			if (month < 10) {
				month = `0${month}`
			}
			const year = dateObject.getFullYear()

			return `${day}/${month}/${year}`
		} else {
			return null
		}

	}

	return (
		<div className={`chat-message-component ${isActive ? "active" : null}`} onClick={() => onClick(id)}>
			<div className={"chat-message-component-title"}>
				<div className={`chat-message-component-title-marquer ${isNew ? "new" : null}`}></div>
				<p className={"text-primary color-black"}>{title}</p>
			</div>
			<p className={"color-grey text-primary"}>{convertDate(date)}</p>
		</div>
	);
};

export default ChatMessage;
