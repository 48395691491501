import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import InputSearch from "../../../../../../components/Atomes/Inputs/InputSearch";
import ButtonSubmit from "../../../../../../components/Atomes/Buttons/ButtonSubmit";

import ProjectService from "../../../../../../services/ProjectService";
import exportFileService from "../../../../../../services/ExportFileService";

const AccessToFolder = () => {

	const [projectId, setProjectId] = useState(null);
	const [noProject, setNoProject] = useState(true);

	const searchProject = (value) => {
		if (value !== "") {
			ProjectService.getProjectByRef(value).then((response) => {
				if (response.data["hydra:member"].length === 0) {
					setProjectId(null);
					setNoProject(true);
					toast.error("Aucun projet trouvé");
				} else {
					setProjectId(response.data["hydra:member"][0].id);
					setNoProject(false);
					toast.success("Projet trouvé");
				}
			});
		}
	}

	const resetSearch = () => {
		setProjectId(null);
		setNoProject(true);
	}

	const downloadProject = () => {
		if (projectId !== null) {
			exportFileService.exportFile(`/projects/${projectId}`, `dossier-projet-${projectId}`, {} , "zip")
		}
	}



	return (

		<div className={"section-access-to-folder mbe-section"}>
			<div className={"section-access-to-folder-title"}>
				<h3 className={"color-primary"}>Accéder à un dossier</h3>
			</div>
			<div className={"section-access-to-folder-container"}>
				<InputSearch
					placeholder={"Référence du projet"}
					onChangeSearch={(event) => event.key === "Enter" ? searchProject(event.target.value.toUpperCase()) : null}
					onChangeSearchValue={(event) => event.target.value.length === 0 ? resetSearch() : null}
				/>
				<Link to={noProject ? "#" : `projet/${projectId}`}  className={`btn-default btn-default-primary color-primary ${noProject ? "disabled" : "" }`}>Accéder au dossier</Link>
				<ButtonSubmit isActive={noProject} title={"Télécharger le dossier"} styleButton={"btn-default btn-default-green"} onClick={downloadProject} />
			</div>
		</div>
	);
}

export default AccessToFolder;
