import React from 'react';
import TitleSection from "../../../../../../components/Atomes/Title/TitleSection";

const KpisViewTitle = () => {

	return (
        <div className={"section-kpi-title"}>
			<TitleSection title={"Indicateurs de performance"}/>
		</div>
    )

}
export default KpisViewTitle;
