import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import TaskService from "../../../../services/TaskService";

const MbePopupVisit = ({ event, showPopup, closeAction }) => {

  const [task, setTask] = useState([]);
  const [taskLoading, setTaskLoading] = useState(true);

  const getTask = async () => {
    setTaskLoading(true);
    let request = await TaskService.getAllTasks("visits/" + event.id)
    if (request.request.status === 200) {
      let task = request.data;
      setTask(task);
      setTaskLoading(false);
    }
  }

  useEffect(() => {
    getTask();
  }, []);

  return (
    <div className={`pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"popup-visit__calendar"}>
        {
          taskLoading ? (
            <Skeleton height={225} width={500} />
          ) : (
            <>
              <div className="popup-visit__calendar-header">
                {/* <h3 className="color-primary">{task.title}</h3> */}
                <img src={"/images/pictos/cross-close.svg"} alt={"cross"} onClick={closeAction} />
              </div>

              <div className="popup-visit__calendar-client">
                <img src={process.env.REACT_APP_PROD_URL + task.client.urlAvatar} />
                <article>
                <p className="text-primary">
                  <span>Adresse :</span> {task.address.street}, {task.address.postcode} {task.address.city}
                </p>
                <p className="text-primary">
                  <span>Date :</span> {moment(task.startAt).format("DD/MM/YYYY")} de {moment(task.startAt).format("HH:mm")} à {moment(task.endAt).format("HH:mm")}
                </p>
                  <p className="text-primary">
                    <span>Client :</span> {task.client.firstname} {task.client.lastname}
                  </p>
                  <p className="text-primary">
                    <span>Téléphone :</span> {task.client.phone}
                  </p>
                  <p className="text-primary">
                    <span>Email :</span> {task.client.email}
                  </p>
                </article>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
}

export default MbePopupVisit;