import React from 'react';
import {toast} from "react-toastify";
import exportFileService from "../../../../../../services/ExportFileService";

const PDFCard = ({filename, uploadPdfFile}) => {

	const downloadPdf = () => {
		exportFileService.exportFile(`/studies/reference-file/${encodeURI(filename.filename)}`, (filename.filename).replace(".pdf", ""), {}, 'pdf');
		toast.success('Téléchargement en cours');
	}

	return (
		<div className={"pdf-card"}>
			<div className={"pdf-card-container"}>
				<img src="/images/pictos/mime-types/pdf.svg" alt="pdf icon" className={"pdf-img"}  onClick={downloadPdf}/>
				<div className={"upload-pdf-study-folder"} onClick={() => uploadPdfFile(filename.filename)}>
					<img src={"/images/pictos/add-plus-circle-primary.svg"} alt={"file"} className={"img"}/>
				</div>
			</div>
			<p className={"color-white"}>{filename.filename}</p>

		</div>
	);
};

export default PDFCard;
