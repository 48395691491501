import React from 'react';
import ChatContainer from "./ChatContainer";

const ChatOpen = ({onClickClose, onChangeSearch, onChangeSearchValue, chat, newClientMessages, newInterneMessages}) => {
	return (
		<div className={"section-chat-is-open"}>
			<ChatContainer onClickClose={onClickClose}
			               chat={chat}
			               name={"Recherche"}
			               onChangeSearch={onChangeSearch}
			               onChangeSearchValue={onChangeSearchValue}
			               newInterneMessages={newInterneMessages}
			               newClientMessages={newClientMessages}
			/>
		</div>
	);
};

export default ChatOpen;
