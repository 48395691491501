import api from "../APIService";
import axios from "axios";

const getEngineerProjects = async () => {
    return await api.getAllData(`/projects/`)
}
const getEngineerSingleProject = async (id) => {
    return await api.getAllData(`/projects/${id}/context/engineer`)
}
const skipValidationPri = async (id) => {
    return await api.patch(`/projects/${id}/pri-skip`, {validation: true})
}
const postPriFile = async (values, id) => {
    let user = JSON.parse(JSON.parse(localStorage.getItem("user")))
    const formData = new FormData();
    formData.append('file', values.file);
    const media = axios.create({
        baseURL: process.env.REACT_APP_PROD_API_URL,
        headers: {
            Authorization: `Bearer ${user ? user.token : ""}`,
            'Content-Type': `multipart/form-data`
        }
    })
    return await media.post(`/projects/${id}/upload-pri-validation`, formData)
}

export const PriService = {
    getEngineerProjects,
    getEngineerSingleProject,
    skipValidationPri,
    postPriFile,
}