import React from 'react';

const ChatLogo = ({onClick, newMessage, numberUnreadMessageVariable}) => {
	return (
		<section className={"chat-logo"} onClick={onClick}>
			<img src={"/images/pictos/chat.svg"} alt={"logo image chat"}/>
			{
				newMessage ? (
					<div className={"rounded-red-circle"}><span>{numberUnreadMessageVariable}</span></div>
				) : null
			}

		</section>
	);
};

export default ChatLogo;
