import React from 'react';
import {SortingTable} from "./SortingTable";
import InputSearch from "../../Atomes/Inputs/InputSearch";

const TableSearchTitle = ({onChangeSearch, data, columnTable, title, placeHolder, onChangeSearchValue}) => {
	
	return (
		<div className={"table-search-title"}>
			<h3 className={"color-primary"}>{title}</h3>
			<InputSearch onChangeSearchValue={onChangeSearchValue} placeholder={placeHolder} onChangeSearch={onChangeSearch}/>
			<div className={`table-search-title-container`}>
				<SortingTable columnsTable={columnTable} dataTable={data}/>
			</div>
		</div>
	);
};

export default TableSearchTitle;
