import * as Yup from "yup"

export const SchemaDevisForm = Yup.object().shape({
	address: Yup.object().shape({
		street: Yup.string().required("Ce champ est obligatoire"),
		city: Yup.string().required("Ce champ est obligatoire"),
		postcode: Yup.number().required("Ce champ est obligatoire"),
	}),
	description: Yup.string().required("Ce champ est obligatoire"),
	client: Yup.object().shape({
		firstname: Yup.string().required("Ce champ est obligatoire"),
		lastname: Yup.string().required("Ce champ est obligatoire"),
		/*
				gender : Yup.string().required("Ce champ est obligatoire"),
		*/
		email: Yup.string().email("Le format n'est pas bon").required("Ce champ est obligatoire"),
		phone: Yup.string()
			.min(9, "La longueur doit être de minimum 10 caractères")
			.max(13, "La longueur doit être de maximum 14 caractères")
			.required('Veuillez renseigner un numéro de téléphone'),
		billingAddress: Yup.object().shape({
			street: Yup.string(),
			city: Yup.string(),
			postcode: Yup.number(),
		}),
		agencyName: Yup.string(),
	}),
	quotes: Yup.array().of(Yup.object().shape({
		orders: Yup.array().of(Yup.object().shape({
			quantity: Yup.number("Le format n'est pas bon"),
			spent: Yup.number("Le format n'est pas bon").required("Ce champ est obligatoire"),
			price: Yup.number("Le format n'est pas bon").required("Ce champ est obligatoire"),
			margin: Yup.number("Le format n'est pas bon").required("Ce champ est obligatoire")
		})),
	})),
	partnerQuotes: Yup.array().of(Yup.object().shape({
		partnerIri: Yup.string().required("Ce champ est obligatoire"),
		title: Yup.string().required("Ce champ est obligatoire"),
		total: Yup.number().required("Ce champ est obligatoire"),
		profit: Yup.number().required("Ce champ est obligatoire"),
		status: Yup.string().required("Ce champ est obligatoire"),
		signedAt: Yup.date("Le format n'est pas bon").required("Ce champ est obligatoire"),
	})),
})
