import React from 'react';
import TitleIconNavLink from "../../../../../../../components/Atomes/Title/TitleIconNavLink";
import StudyFolderProjectSheetCardUser from "./StudyFolderProjectSheetCardUser";

const StudyFolderProjetSheet = () => {

	const fakeData = [
		{
			"id": 1,
			"job": "Maître d’ouvrage",
			"adress": "Suite 6",
			"email": "quentin@gmail.com",
			"phone": "9865158012",
			"name": "Bartolemo Tafani",
			"image": "http://dummyimage.com/152x100.png/dddddd/000000"
		}, {
			"id": 2,
			"job": "Maître d’ouvrage",
			"adress": "PO Box 94079",
			"email": "quentin@gmail.com",
			"phone": "4876690745",
			"name": "Salem Trayes",
			"image": "http://dummyimage.com/224x100.png/5fa2dd/ffffff"
		}, {
			"id": 3,
			"job": "Maître d’ouvrage",
			"adress": "Apt 1853",
			"email": "quentin@gmail.com",
			"phone": "5826658599",
			"name": "Nikkie Bertson",
			"image": "http://dummyimage.com/242x100.png/cc0000/ffffff"
		}]

	return (
		<div className={"study-folder-projet-sheet"}>
			<div className={"section-study-folder-top-img"}>
				<img src={"/images/uploads/fond-a-supprimer-architecte.png"} alt={"title img"}/>
				<div className={"section-study-folder-top-img-gradiant"}></div>
			</div>
			<TitleIconNavLink title={`2 bis passage Ramey - 00123`} link={"/dossier-etude/1/redaction-du-dossier-etude"}/>
			<div className='project-documents__header'>
				<div className='project-documents__header-action'>
					<div className='project-documents__header-action__indicator'>
						<div>
							<img src="/images/pictos/open-file-white.svg" alt="icon file"/>
						</div>
						<p className="text-primary color-black">Avis de passage</p>
					</div>
					<div className='project-documents__header-action-icons'>
						<img src="/images/pictos/download-black.svg" alt="icon download"/>
					</div>
				</div>
				<div className='project-documents__header-action'>
					<div className='project-documents__header-action__indicator'>
						<div>
							<img src="/images/pictos/file-white.svg" alt="icon file"/>
						</div>
						<p className="text-primary color-black">Dossier d'étude structure</p>
					</div>
					<div className='project-documents__header-action-icons'>
						<img src="/images/pictos/download-black.svg" alt="icon download"/>
					</div>
				</div>
			</div>
			<div className={"study-folder-projet-sheet-container"}>
				<div className={"study-folder-projet-sheet-container-left"}>
					{
						fakeData.map((user, index) => {
							return (
								<StudyFolderProjectSheetCardUser job={user.job} adress={user.adress} email={user.email}
								                                 phone={user.phone} name={user.name} image={user.image}/>
							)
						})
					}
				</div>
				<div className={"study-folder-projet-sheet-container-right"}>
					<div className={"study-folder-projet-sheet-container-right-element"}>
						<div className={"study-folder-projet-sheet-container-right-element-time"}>
							<p className={"text-primary color-black paragraph_bold"}>Commercial</p>
							<p className={"text-primary color-black"}>2 jours</p>
						</div>

						<p className={"text-primary color-primary information"}>Antoine Gouteix</p>
					</div>

					<div className={"study-folder-projet-sheet-container-right-element"}>
						<div className={"study-folder-projet-sheet-container-right-element-time"}>
							<p className={"text-primary color-black paragraph_bold"}>Visite et compte rendu</p>
							<p className={"text-primary color-black"}>3 jours</p>
						</div>

						<p className={"text-primary color-primary information"}>Berengere Denier</p>
					</div>

					<div className={"study-folder-projet-sheet-container-right-element"}>
						<div className={"study-folder-projet-sheet-container-right-element-time"}>
							<p className={"text-primary color-black paragraph_bold"}>Réalisation de l’étude</p>
							<p className={"text-primary color-black"}>10 jours</p>
						</div>

						<p className={"text-primary color-primary information"}>Joud Abdulrahim</p>
					</div>

					<div className={"study-folder-projet-sheet-container-right-element"}>
						<div className={"study-folder-projet-sheet-container-right-element-time"}>
							<p className={"text-primary color-black paragraph_bold"}>Vérification de l'étude</p>
							<p className={"text-primary color-black"}>5 jours</p>
						</div>

						<p className={"text-primary color-primary information"}>Étienne Ricci</p>
					</div>
				</div>

			</div>
		</div>
	);
};

export default StudyFolderProjetSheet;
