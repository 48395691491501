import React from "react";
import {NavLink} from "react-router-dom";

import ProjectNiceType from "../../../../utils/ProjectNiceType";
import ProjectNiceStage from "../../../../utils/ProjectNiceStage";

import exportFileService from "../../../../services/ExportFileService";

const CardProject = ({id, project}) => {

	const downloadAvisDePassage = (endpoint) => {
		exportFileService.exportMedia(endpoint, 'avis-de-passage', "pdf")
	}

	const downloadStudy = (id) => {
		exportFileService.exportFile('/studies/' + id, 'dossier-etude-structure', {}, "pdf")
	}

	return (
		<article className="card-project">

			<div className="card-project-banner">
				{ProjectNiceType({type: project.type, value: "cardImg"})}
				<p className={`${project.stage === 'COM_feedback' ? "finished" : ""} text-primary color-white`}>
					{(project.stageStatus === 'problem' && !(project.stage === 'COM_feedback' && project.stageStatus === 'valid')) &&
						<img src="/images/pictos/warning.svg" alt="warning icon"/>}
					{ProjectNiceStage({stage: project.stage, niceStage: project.niceStage})}
				</p>
			</div>

			<div className="card-project-address">
				<NavLink to={`/projet/${id}`} className="text-primary color-black">
					<h4
						className="color-black">{`${project.address.street} ${project.address.postcode} ${project.address.city} `}</h4>
					<p>
						{`${project.niceType} - ${project.reference}`}
					</p>
					<span className="invisible-link"></span>
				</NavLink>
			</div>

			{
				project.reportingNoticeIri ? (
					<div className="card-project-action">
						<div className="card-project-action-indicator">
							<div>
								<img src="/images/pictos/open-file-white.svg" alt="icon file"/>
							</div>
							<p className="text-micro paragraph-micro paragraph_bold color-black">Avis de passage</p>
						</div>
						<div className="card-project-action-icons">
							<img
								onClick={() => downloadAvisDePassage(project.reportingNoticeIri)}
								src="/images/pictos/download-black.svg"
								alt="icon download"/>
						</div>
					</div>
				) : (
					<div className="card-project-action">
						<div className="card-project-action-indicator">
							<div className="unavailable">
								<img src="/images/pictos/open-file-white.svg" alt="icon file"/>
							</div>
							<p className="text-micro paragraph-micro paragraph_bold color-black">Avis de passage - Non disponible</p>
						</div>
					</div>
				)
			}

			{
				project.study && project.stage !== 'COM_payment' && project.stage !== 'ADMIN_payment-follow-up' && project.stage !== 'ADMIN_payment-validation' && project.stage !== 'ING_correction-study' ? (
					project.study.status === "valid" && project.study.filename ? (
						<div className="card-project-action">
							<div className="card-project-action-indicator">
								<div>
									<img src="/images/pictos/open-file-white.svg" alt="icon file"/>
								</div>
								<p className="text-micro paragraph-micro paragraph_bold color-black">Dossier d'étude structure</p>
							</div>
							<div className="card-project-action-icons">
								<img
									onClick={() => downloadStudy(project.study.id)}
									src="/images/pictos/download-black.svg"
									alt="icon download"
								/>
							</div>
						</div>
					) : (
						<div className="card-project-action">
							<div className="card-project-action-indicator">
								<div className="unavailable">
									<img src="/images/pictos/open-file-white.svg" alt="icon file"/>
								</div>
								<p className="text-micro paragraph-micro paragraph_bold color-black">Dossier d'étude structure -
									Rédaction</p>
							</div>
						</div>
					)
				) : (
					<div className="card-project-action">
						<div className="card-project-action-indicator">
							<div className="unavailable">
								<img src="/images/pictos/open-file-white.svg" alt="icon file"/>
							</div>
							<p className="text-micro paragraph-micro paragraph_bold color-black">Dossier d'étude structure - Non
								disponible</p>
						</div>
					</div>
				)
			}
		</article>
	)
}

export default CardProject;
