import React, {createContext, useEffect, useState} from 'react';
import AdminBillsTab from "./Partials/AdminBillsTab";
import {SortingTable} from "../../../../components/Molecules/Tables/SortingTable";
import {InvoicesDownPayments} from "../../../../config/ArrayColumns/Admin/InvoicesDownPayments";
import ButtonWithOnClickAction from "../../../../components/Atomes/Buttons/ButtonWithOnClickAction";
import TitleWithExport from "../../../../components/Molecules/Title/TItleWIthExport";
import FilterSearchSelectDatepicker from "../../../../components/Molecules/Filter/FilterSearchSelectDatepicker";
import moment from "moment";
import {BillService} from "../../../../services/BillService";
import {ConvertObjectToParams, ConvertObjectToUrlParams} from "../../../../utils/StringUtils";
import {AssetsPayments} from "../../../../config/ArrayColumns/Admin/AssetsPayments";
import {toast} from "react-toastify";
import {StateProjectPayments} from "../../../../config/ArrayColumns/Admin/StateProjectPayments";
import exportFileService from "../../../../services/ExportFileService";
import {dataRegionsArray} from "../../../../utils/RegionsUtils";

export const StateRequest = createContext()

const AdminBills = () => {

	const [activeSection, setActiveSection] = useState("invoices-down-payments");

	//Search
	const [search, setSearch] = useState("");

	//State request
	const [stateRequest, setStateRequest] = useState("");

	//Invoices
	const [invoicesDownPayments, setInvoicesDownPayments] = useState([]);
	const [invoicesFinal, setInvoicesFinal] = useState([]);
	const [assets, setAssets] = useState([]);
	const [stateProject, setStateProject] = useState([]);

	//Date
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [date, setDate] = useState(new Date());

	const options = dataRegionsArray
	const [region, setRegion] = useState("");

	const [urlToExport, setUrlToExport] = useState("");

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).add(1, "days").format("YYYY-MM-DD"))
	}

	const sectionToReturn = () => {
		if (activeSection === "invoices-down-payments") {
			return (
				<SortingTable columnsTable={InvoicesDownPayments} dataTable={invoicesDownPayments}/>
			)
		} else if (activeSection === "invoices-final") {
			return (
				<SortingTable columnsTable={InvoicesDownPayments} dataTable={invoicesFinal}/>
			)
		} else if (activeSection === "asset") {
			return (
				<SortingTable columnsTable={AssetsPayments} dataTable={assets}/>
			)
		} else if (activeSection === "state-project") {
			return (
				<SortingTable columnsTable={StateProjectPayments} dataTable={stateProject}/>
			)
		}
	}

	const setUrl = () => {
		if (activeSection === "invoices-down-payments") {
			setUrlToExport(ConvertObjectToUrlParams("/admin/deposits", {
				region: region,
				dateStart: dateStart,
				dateEnd: dateEnd,
				search: search
			}))
		} else if (activeSection === "invoices-final") {
			setUrlToExport(ConvertObjectToUrlParams("/admin/invoices", {
				region: region,
				dateStart: dateStart,
				dateEnd: dateEnd,
				search: search
			}))
		} else if (activeSection === "asset") {
			setUrlToExport(ConvertObjectToUrlParams("/admin/credits", {
				region: region,
				dateStart: dateStart,
				dateEnd: dateEnd,
				search: search
			}))
		} else if (activeSection === "state-project") {
			setUrlToExport("#")
		}
	}

	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	const getInvoiceOnAccount = async () => {
		const request = await BillService.getInvoiceOnAccount(ConvertObjectToParams("deposits?exists[payment]=true&exists[paidAt]=true", {
			"project.region": region,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			"pagination": "true"
		}))
		if (request.status === 200) {
			setInvoicesDownPayments(request.data)
		}
	}

	const getInvoiceFinal = async () => {
		const request = await BillService.getInvoiceOnAccount(ConvertObjectToParams("final-invoices?exists[payment]=true&exists[paidAt]=true", {
			"project.region": region,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			"pagination": "true"
		}))
		if (request.status === 200) {
			setInvoicesFinal(request.data)
		}
	}

	const getAssets = async () => {
		const request = await BillService.getInvoiceOnAccount(ConvertObjectToUrlParams("credits", {
			"project.region": region,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			"pagination": "true"
		}))
		if (request.status === 200) {
			setAssets(request.data)
		}
	}

	const getStateProject = async () => {
		const request = await BillService.getInvoiceOnAccount(ConvertObjectToParams("final-invoices?exists[payment]=true", {
			"project.region": region,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			"pagination": "true"
		}))
		if (request.status === 200) {
			setStateProject(request.data)
		}
	}

	const todayDate = moment().format("L").split("/").join("-")

	const downloadFile = () => {
		const date = moment().format("L").split("/").join("-")
		exportFileService.exportFile(`/fec`, `export-fec-${date}`, {
			dateStart: dateStart,
			dateEnd: dateEnd,
			search: search,
			region: region
		}, "zip")
	}

	const downloadFileZipAcomptes = () => {
		const date = moment().format("L").split("/").join("-")
		exportFileService.exportFile(`/zip/deposits`, `export-acompte-finale-${date}`, {
			dateStart: dateStart,
			dateEnd: dateEnd,
			search: search,
			region: region
		}, "zip")
	}
	const downloadFileZipFInales = () => {
		const date = moment().format("L").split("/").join("-")
		exportFileService.exportFile(`/zip/invoices`, `export-facture-finale-${date}`, {
			dateStart: dateStart,
			dateEnd: dateEnd,
			search: search,
			region: region
		}, "zip")
	}
	const downloadFileZipAvoirs = () => {
		const date = moment().format("L").split("/").join("-")
		exportFileService.exportFile(`/zip/credits`, `export-avoirs-${date}`, {
			dateStart: dateStart,
			dateEnd: dateEnd,
			search: search,
			region: region
		}, "zip")
	}
	const downloadFileXmlAvoirs = () => {
		const date = moment().format("L").split("/").join("-")
		exportFileService.exportFile(`/xml/credits`, `export-avoir-xml-${date}`, {
			dateStart: dateStart,
			dateEnd: dateEnd,
			search: search,
			region: region
		}, "xml")
	}

	const DisplayToast = (type) => {
		if (type === "success edit") {
			toast.success("Le commentaire a bien été modifié")
		} else if (type === "success add asset") {
			toast.success("L'avoir a bien été ajouté")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getInvoiceOnAccount()
		getInvoiceFinal()
		getAssets()
		getStateProject()
		setUrl()
	}, [dateStart, dateEnd, search, region, stateRequest, activeSection]);

	const changeRegion = (region) => {
		if (region === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(region)
		}
	}

	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<FilterSearchSelectDatepicker
				onChange={(event) => changeRegion(event.target.value)}
				firstValue={options}
				date={date}
				placeHolderSearch={"Entrer la recherche puis valider"}
				onChangeDate={(date) => changeDate(date)}
				options={options}
				value={options}
				defaultValue={"Toutes les agences"}
				onSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
				onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
				resetDateSelected={resetDate}
			/>

			<TitleWithExport dark={true} buttonExport={activeSection === "state-project"} title={"Facturation"}
			                 titleButton={"Exporter"} url={urlToExport} fileName={`export-facture-${todayDate}`}
			                 fileType={"xlsx"}/>
			<div className={"admin-bills-content "}>
				<div className={"admin-bills-content-container mbe-section "}>
					<div>
						<AdminBillsTab activeSection={activeSection} setActiveSection={setActiveSection}/>
						{sectionToReturn()}
					</div>
					{
						activeSection === "invoices-down-payments" ? (
							<div className={"admin-bills-content-container-button"}>
								<ButtonWithOnClickAction onClick={() => urlToExport !== "#" ? downloadFileZipAcomptes() : null}
								                         title={"Exporter factures acomptes"}
								                         styleButton={"btn-default btn-default-secondary plain"} isActive={true}/>
								<ButtonWithOnClickAction onClick={() => urlToExport !== "#" ? downloadFile() : null}
								                         title={"Exporter FEC"} styleButton={"btn-default btn-default-secondary plain"}
								                         isActive={true}/>
							</div>
						) : null
					}

					{
						activeSection === "invoices-final" ? (
							<div className={"admin-bills-content-container-button"}>
								<ButtonWithOnClickAction onClick={() => urlToExport !== "#" ? downloadFileZipFInales() : null}
								                         title={"Exporter factures finales"}
								                         styleButton={"btn-default btn-default-secondary plain"} isActive={true}/>
								<ButtonWithOnClickAction onClick={() => urlToExport !== "#" ? downloadFile() : null}
								                         title={"Exporter FEC"} styleButton={"btn-default btn-default-secondary plain"}
								                         isActive={true}/>
							</div>
						) : null
					}

					{
						activeSection === "asset" ? (
							<div className={"admin-bills-content-container-button"}>
								<ButtonWithOnClickAction onClick={() => urlToExport !== "#" ? downloadFileXmlAvoirs() : null}
								                         title={"Exporter paiement avoir"}
								                         styleButton={"btn-default btn-default-secondary plain"} isActive={true}/>
								<ButtonWithOnClickAction onClick={() => urlToExport !== "#" ? downloadFileZipAvoirs() : null}
								                         title={"Exporter avoirs"}
								                         styleButton={"btn-default btn-default-secondary plain"} isActive={true}/>
							</div>
						) : null
					}

				</div>
			</div>
		</StateRequest.Provider>
	);
};

export default AdminBills;
