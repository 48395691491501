import { ConvertObjectToUrlParams } from "../utils/StringUtils";
import api from "./APIService";

const getArchitectCollection = async (params = null) => {
	let url = 'archs';
	if (params) url = ConvertObjectToUrlParams(url, params)
	return await api.getAllData(url)
}

const postNewArch = async (data) => {
	return await api.postBody("archs", data)
}

const getBookings = async () => {
	return await api.getAllData("bookings")
}

const ArchitectService = {
	getArchitectCollection,
	postNewArch,
	getBookings
}

export default ArchitectService
