import * as Yup from "yup";

export const SchemaCollaborator = Yup.object().shape({
  gender: Yup.string().required("Veuillez choisir un genre"),
  roles: Yup.object().shape({
    additionalProp1: Yup.string().required("Veuillez choisir un rôle"),
  }),
  regions: Yup.object().shape({
    additionalProp1: Yup.string().required("Veuillez choisir une zone géographique"),
  }),
  firstname: Yup.string().required("Veuillez renseigner un prénom"),
  lastname: Yup.string().required("Veuillez renseigner un nom"),
  email: Yup.string().email('Le format de l\'adresse e-mail n\'est pas bon').required("Veuillez renseigner une adresse e-mail"),
  phone: Yup.string()
    .min(10, "La longueur doit être de minimum 10 caractères")
    .max(14, "La longueur doit être de maximum 14 caractères")
    .required('Veuillez renseigner un numéro de téléphone'),
  objective: Yup.number().required("Veuillez renseigner un objectif chiffre d’affaires mensuel (HT)"),
  bonus: Yup.number()
    .required("Veuillez renseigner un bonus sur marge")
    .min(0, "Le bonus sur marge ne peut pas être négatif")
    .max(1, "Le bonus sur marge ne peut pas être supérieur à 1"),
  package: Yup.number().required("Veuillez choisir un forfait"),
  wl: Yup.string().required("Veuillez choisir un niveau de travail")
})
