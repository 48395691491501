import React from 'react';
import {useNavigate} from "react-router-dom";
import { useAuth } from '../../../../../../services/useAuth';

const TabNavigationStudyFolder = ({theStep, id, needCCTP, needSummary, needExecutionClass}) => {

	const navigate = useNavigate()

	const {allInformations} = useAuth()

	const elements = [
		{
			title: "Rédaction du dossier d'étude",
			state: "REDACTION",
			url: `/dossier-etude/${id}/redaction-du-dossier-etude`
		},
		{
			title: "Classe d'éxecution",
			state: "CLASSE",
			url: `/dossier-etude/${id}/classe-execution`,
		},
		{
			title: "TSR - partie 1",
			state: "TSR1",
			url: `/dossier-etude/${id}/tsr-premiere-partie`
		},
		{
			title: "TSR - partie 2",
			state: "TSR2",
			url: `/dossier-etude/${id}/tsr-seconde-partie`
		},
		{
			title: "DPGF",
			state: "DPGF",
			url: `/dossier-etude/${id}/dpgf`
		},
		{
			title: "CCTP",
			state: "CCTP",
			url: `/dossier-etude/${id}/cctp`
		},
		{
			title: "Annexe",
			state: "ANNEXE",
			url: `/dossier-etude/${id}/annexe`
		}
	]

	return (
		<section className={"tab-navigation-study-folder"}>
			<div className={"tab-navigation-study-folder-container"}>
				<ul className={"tab-navigation-study-folder-list"}>
					{elements.map((element, index) => {
						if (element.state === "CCTP" && !needCCTP) return null
						if (element.state === "DPGF" && !needCCTP) return null
						if (element.state === "TSR2" && !needSummary) return null
						if (element.state === "TSR1" && !needSummary) return null
						if (element.state === "CLASSE" && !needExecutionClass && !allInformations.roles.includes('ROLE_ING_AFF')) return null

						return (
							<li key={index} onClick={() => navigate(element.url, {replace: true})}
							    className={element.state === theStep ? "tab-navigation-study-folder-list-item active" : "tab-navigation-study-folder-list-item"}>
								{element.title}
							</li>
						)
					})}
				</ul>
			</div>
		</section>
	);
};

export default TabNavigationStudyFolder;
