import React, {useEffect, useState} from 'react';
import FilterSelectDatepicker from "../../../../../components/Molecules/Filter/FilterSelectDatepicker";
import TableSearchTitle from "../../../../../components/Molecules/Tables/TableSearchTitle";
import {PAST_STUDIES_COLUMNS} from "../../../../../config/ArrayColumns/PastStudiesColumns";
import moment from "moment";
import CollaboratorService from "../../../../../services/CollaboratorService";
import {toast} from "react-toastify";

const PastStudiesView = () => {

	const userId = localStorage.getItem("userId")

	//Search
	const [search, setSearch] = useState("")

	//Date
	const [date, setDate] = useState(new Date());
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");

	const [pastStudies, setPastStudies] = useState([])
	const options = ["1 mois", "2 mois", "3 mois"]
	const values = [1, 2, 3]

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).add(1, "days").format("YYYY-MM-DD"))
	}

	const changeDateMonthLater = (date) => {
		if (date === "Toutes durées") {
			setDateStart("")
			setDateEnd("")
			setDate(new Date())
		} else {
			let dateEnd = moment().add(1, "days").format("YYYY-MM-DD")
			let dateBegin = moment().subtract(date, 'months').format("YYYY-MM-DD")
			setDate([new Date(dateBegin), new Date(dateEnd)])
			setDateStart(dateBegin)
			setDateEnd(dateEnd)
		}
	}

	const getPastStudies = async () => {
		let request = await CollaboratorService.getPastStudies({
			"com.id": userId,
			"active": "false",
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"search": search,
			"pagination": "true"
		})
		if (request.request.status === 200) {
			setPastStudies(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des études passées")
		}
	}

	useEffect(() => {
		getPastStudies()
	}, [date, search]);

	return (
		<>
			<FilterSelectDatepicker
				onChangeDate={(date) => changeDate(date)}
				onChange={(event) => changeDateMonthLater(event.target.value)}
				defaultValue={"Toutes durées"}
				values={values}
				options={options}
				date={date}
			/>
			<div className={"section-past-studies-view-container"}>
				{
					pastStudies["hydra:member"] && pastStudies["hydra:member"].length > 0 ? (
						<TableSearchTitle
							title={"Dossier d'étude structure"}
							placeHolder={"Recherche"}
							onChangeSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
							data={pastStudies}
							columnTable={PAST_STUDIES_COLUMNS}
							onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
						/>
					) : (
						<p className='text-primary'>Il n'y a pas de données pour le moment</p>
					)
				}
			</div>
		</>
	);
};

export default PastStudiesView;
