import React from 'react';
import ButtonSubmit from "../../../../../../../components/Atomes/Buttons/ButtonSubmit";
import ButtonWithOnClickAction from "../../../../../../../components/Atomes/Buttons/ButtonWithOnClickAction";
import {useFormikContext} from "formik";
import {toast} from "react-toastify";
import exportFileService from "../../../../../../../services/ExportFileService";

const FormDevisButtons = ({ project, setUpdate, update }) => {
  const { isSubmitting, values } = useFormikContext();

  /*Change the type of submit*/
  const changeValueOfTypeSubmit = (type) => {
    values.sendOnSubmit = type;
    setUpdate(0);
  };

  /*Function onClick on "Visualiser le devis" who download quote pdf*/
  const visualize = async () => {
    if (project.quotes.length > 0 && update < 1) {
      const promises = project.quotes.map(async (quote) => {
        await exportFileService.exportFile(
          `/quotes/${quote.id}`,
          `quoteitem${quote.id}`,
          {},
          "pdf"
        );
      });
    } else {
      toast.error("Veuillez enregistrer vos devis avant de les visualiser.");
    }
  };

  return (
    <div className={"padding-bottom-100 text-align-center"}>
      <div className={"form-devis-container-buttons"}>
        <ButtonSubmit
          onClick={(e) => changeValueOfTypeSubmit(false)}
          title={"Enregistrer"}
          isActive={isSubmitting}
          styleButton={"btn-default btn-default-secondary plain"}
        />
        <ButtonSubmit
          onClick={(e) => changeValueOfTypeSubmit(true)}
          title={"Envoyer le devis au client"}
          isActive={
            (project.stage === "COM_discount-validation" &&
              project.stageStatus === "problem") ||
            (project.stage === "COM_discount-validation" &&
              project.stageStatus === "cancelled")
              ? true
              : false
          }
          styleButton={"btn-default btn-default-green"}
        />
        <ButtonWithOnClickAction
          onClick={(e) => visualize()}
          title={"Télécharger le devis"}
          isActive={!isSubmitting}
          styleButton={"btn-default btn-default-secondary"}
        />
      </div>
      {(project.stage === "COM_discount-validation" &&
        project.stageStatus === "problem") ||
      (project.stage === "COM_discount-validation" &&
        project.stageStatus === "cancelled") ? (
        <p className={"text-primary color-primary"}>
          La remise commerciale doit être validée par le directeur commercial
          avant envoi
        </p>
      ) : null}
    </div>
  );
};

export default FormDevisButtons;
