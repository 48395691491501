import api from "./APIService";
import {ConvertObjectToParams, ConvertObjectToUrlParams} from "../utils/StringUtils";

const getProjects = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`projects/context/admin`, param))
}
const getProjectsEngineer = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`projects/context/engineer`, param))
}

const getProjectsInProgress = async (param) => {
	return await api.getAllData(ConvertObjectToParams(`projects/context/engineer?stage.title=ING`, param))
}

const getProjectsArchived = async (param) => {
	return await api.getAllData(ConvertObjectToParams(`projects/context/engineer?stage.title[]=COM_payment&stage.title[]=COM_delivery&stage.title[]=COM_feedback`, param))
}

const getProjectByRef = async (ref) => {
	return await api.getAllData(`projects?reference=${ref}`)
}

const getAllProjects = async (url, params) => {
	return await api.getAllData(ConvertObjectToUrlParams(url, params))
}

const generateLetter = async (id) => {
	return await api.getAllData(`/exports/projects/${id}/recovery-letter`)
}

const ClosingTheRelaunch = async (id) => {
	return await api.patch(`projects/${id}/context/admin`, {active: false})
}

const ClosingTheProject = async (id) => {
	return await api.patch(`projects/${id}/context/admin`, {archive: true})
}

const DeleteNewRequest = async (id) => {
	return await api.del(`projects/${id}`)
}

const CorrectionRequest = async (studyId, data) => {
	return await api.patch(`studies/${studyId}/validate`, data)
}

const CreateNewProject = async (params) => {
	return await api.postBody("projects/context/sales", params)
}

const ProjectService = {
	getProjects,
	getProjectsEngineer,
	getProjectByRef,
	getAllProjects,
	generateLetter,
	ClosingTheRelaunch,
	ClosingTheProject,
	getProjectsInProgress,
	getProjectsArchived,
	DeleteNewRequest,
	CorrectionRequest,
	CreateNewProject
}

export default ProjectService;
