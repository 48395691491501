import React, {useEffect, useState} from "react";
import FilterTwoSelectsDatepicker from "../../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";
import CommercialObjectivesArray from "./Partials/CommercialObjectivesArray";
import CollaboratorService from "../../../../../services/CollaboratorService";
import {ConvertObjectToUrlParams} from "../../../../../utils/StringUtils";
import getUserService from "../../../../../services/getUser";
import moment from "moment/moment";
import {toast} from "react-toastify";
import PriceCalculated from "./Partials/PriceCalculated";
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

const Objectives = () => {

	const options = dataRegionsArray
	const [date, setDate] = useState(new Date());
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [region, setRegion] = useState("");
	const [quotes, setQuotes] = useState([])
	const [userFilterId, setUserFilterId] = useState(getUserService.getUserId())
	const [userFilter, setUserFilter] = useState(null)
	const [collaborators, setCollaborators] = useState([])

	const [objectiveLoading, setObjectiveLoading] = useState(false)

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}
	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Tous les collaborateurs") {
			setUserFilterId(getUserService.getUserId())
		} else {
			let userSelectedId = parseInt(event.target.value);
			setUserFilterId(userSelectedId);
			setUserFilter(collaborators.find((user) => user.id === userSelectedId));
		}
	}

	const getCollaborators = async () => {
		const url = ConvertObjectToUrlParams("coms", {regions: region, roles: "ROLE_COM"})
		const request = await CollaboratorService.getCollaborators(url)
		if (request.request.status === 200) {
			setCollaborators(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	const getQuotes = async () => {
		const url = ConvertObjectToUrlParams("quotes", {
			"project.com.id": userFilterId,
			"paidAt[before]": dateEnd,
			"paidAt[after]": dateStart,
			"status": "signed",
			"pagination": "true",
		})
		const request = await CollaboratorService.getObjectifsCollaborator(url)
		if (request.request.status === 200) {
			setQuotes(request.data)
			setObjectiveLoading(true)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des données")
		}
	}

	useEffect(() => {
		getCollaborators()
		getQuotes()
	}, [region, userFilterId, userFilter, date]);

	return (
		<>
			<FilterTwoSelectsDatepicker
				defaultValue={"Toutes les agences"}
				options={options}
				firstValue={options}
				onChange={changeRegionFilter}
				onSecondChange={changeCollaboratorFilter}
				secondDefaultValue={"Tous les collaborateurs"}
				secondOptions={collaborators.map(collaborators => collaborators.lastname)}
				secondValue={collaborators.map(collaborators => collaborators.id)}
				styledButton={"btn-default btn-default-primary btn-default-primary color-primary"}
				titleButton={"Selectionnez une période"}
				date={date}
				onChangeDate={(date) => changeDate(date)}
				resetDateSelected={resetDate}
			/>

			{
				objectiveLoading ? (
					<CommercialObjectivesArray quotes={quotes} id={userFilterId}
											   userFilterId={userFilterId} dateEnd={dateEnd} dateStart={dateStart}
					/>
				) : null
			}

			{
				quotes["hydra:member"] ?

					quotes["hydra:member"].length > 0 ? (
						<PriceCalculated userFilterId={userFilterId} userFilter={userFilter}
										 quotes={quotes["hydra:member"]} id={userFilterId}
										 dateEnd={dateEnd} dateStart={dateStart}/>
					) :
					null
				: null
			}

		</>
	);
};
export default Objectives;
