import React, {useState} from 'react';
import InputSearch from "../../Atomes/Inputs/InputSearch";
import Skeleton from "react-loading-skeleton";
import {SortingTable} from "./SortingTable";

const TableSearchToggleTitle = ({
	                                loading,
	                                onChangeSearch,
	                                dataActived,
	                                dataArchived,
	                                columnTable,
	                                title,
	                                placeHolder,
	                                onChangeSearchValue
                                }) => {

	const [isActiveInProgress, setIsActiveInProgress] = useState(true);
	const [isActiveComplete, setIsActiveComplete] = useState(false);

	return (
		<div className={"table-search-toggle"}>
			<div className="table-search-toggle-intro marginbottom-68">
				<h3 className={"color-primary"}>{title}</h3>
				<div className="table-search-toggle-intro-filter">
					<InputSearch onChangeSearchValue={onChangeSearchValue} placeholder={placeHolder}
					             onChangeSearch={onChangeSearch}/>
					<div className='toggletabs'>
						<p className={`text-primary toggletabs__tab ${isActiveInProgress ? 'active' : ''}`}
						   onClick={() => {
							   setIsActiveInProgress(true);
							   setIsActiveComplete(false);
						   }}
						>
							En cours
						</p>
						<p className={`text-primary toggletabs__tab ${isActiveComplete ? 'active' : ''}`}
						   onClick={() => {
							   setIsActiveInProgress(false);
							   setIsActiveComplete(true);
						   }}
						>
							Terminés
						</p>
					</div>
				</div>
			</div>
			<div className={`table-search-title-container`}>
				{
					loading ? <Skeleton height={500}/> :
						isActiveInProgress ? (
							<SortingTable columnsTable={columnTable}
							              dataTable={dataActived}
							/>
						) : (
							<SortingTable columnsTable={columnTable}
							              dataTable={dataArchived}
							/>
						)
				}
			</div>
		</div>
	);
};

export default TableSearchToggleTitle;
