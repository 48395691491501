import React, {useEffect, useState} from 'react';
import FilterTwoSelectsDatepicker from "../../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";
import {ConvertObjectToUrlParams} from "../../../../../utils/StringUtils";
import CollaboratorService from "../../../../../services/CollaboratorService";
import {toast} from "react-toastify";
import OverviewIngRes from "./partials/OverviewIngRes";
import HomeIngResTeams from "./partials/HomeIngResTeams";
import MyCollaboratorIngRes from "../../Man/Calendar/Partials/MyCollaboratorIngRes";
import ApprovalCircuitIngRes from "./partials/ApprovalCircuitIngRes";
import IngCalendarPart from "./partials/IngCalendarPart";
import DirRespService from "../../../../../services/Ing/DirResp/DirRespService";
import moment from "moment";
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

const HomeResponsableIng = () => {

	const [approvalCircuit, setApprovalCircuit] = useState([]);
	const [timeToRealod, setTimeToRealod] = useState(false)

	const [collaborators, setCollaborators] = useState([]);
	const [region, setRegion] = useState("");
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [userFilter, setUserFilter] = useState("")
	const [date, setDate] = useState(new Date());
	const options = dataRegionsArray

	// Const for kpi
	const [projectsInProgress, setProjectsInProgress] = useState(0);
	const [projectsFinished, setProjectsFinished] = useState(0);
	const [projectsWaitingPayment, setProjectsWaitingPayment] = useState(0);
	const [projectsInRecovery, setProjectsInRecovery] = useState(0);
	const [projectsFolderLate, setProjectsFolderLate] = useState(0);

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}

	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}
	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Tous les collaborateurs") {
			setUserFilter("")
		} else {
			setUserFilter(event.target.value)
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}

	const getProjectInProgress = async () => {
		const request = await DirRespService.getProjectInProgress({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter
		})
		if (request.request.status === 200) {
			setProjectsInProgress(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets en cours")
		}
	}

	const getProjectFinished = async () => {
		const request = await DirRespService.getProjectFinished({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter
		})
		if (request.request.status === 200) {
			setProjectsFinished(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets terminés")
		}
	}

	const getProjectWaitingPayment = async () => {
		const request = await DirRespService.getNumberFolderWaitingPayment({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter
		})
		if (request.request.status === 200) {
			setProjectsWaitingPayment(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets en attente de paiement")
		}
	}

	const getProjectInRecovery = async () => {
		const request = await DirRespService.getNumberProjectInRecovery({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter
		})
		if (request.request.status === 200) {
			setProjectsInRecovery(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets en recouvrement")
		}
	}

	const getProjectFolderLate = async () => {
		const request = await DirRespService.getNumberFoderLate({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter
		})
		if (request.request.status === 200) {
			setProjectsFolderLate(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets en retard")
		}
	}


	const [projectUnassigned, setProjectUnassigned] = React.useState([])
	const getProjectUnassigned = async () => {
		const request = await DirRespService.getAllUnassignedProjects({})

		if (request.status === 200) {
			setProjectUnassigned(request.data["hydra:member"])
		}
	}

	useEffect(() => {
		getProjectUnassigned()
	}, [timeToRealod]);


	useEffect(() => {
		getCollaborators()

		// KPI
		getProjectInProgress()
		getProjectFinished()
		getProjectFolderLate()
		getProjectInRecovery()
		getProjectWaitingPayment()

		getApprovalCircuit()
	}, [region, userFilter, dateStart, dateEnd])

	const getApprovalCircuit = async () => {
		let request = await DirRespService.getApprovalCircuit()
		if (request.request.status === 200) {
			setApprovalCircuit(request.data["hydra:member"])
		} else {
			toast.error("Une erreur est survenue lors de la récupération du circuit d'approbation")
		}
	}

	const getCollaborators = async () => {
		const urlCom = ConvertObjectToUrlParams("ings", {region: region})
		const request = await CollaboratorService.getCollaborators(urlCom)
		if (request.request.status === 200) {
			setCollaborators(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	return (
		<>
			<FilterTwoSelectsDatepicker
				defaultValue={"Toutes les agences"}
				options={options}
				firstValue={options}
				secondDefaultValue={"Tous les collaborateurs"}
				secondOptions={collaborators.map(collaborator => collaborator.lastname)}
				secondValue={collaborators.map(collaborator => collaborator.id)}
				onChange={changeRegionFilter}
				onSecondChange={changeCollaboratorFilter}
				date={date}
				onChangeDate={(date) => changeDate(date)}
				resetDateSelected={resetDate}
			/>

			<OverviewIngRes
				projectsInProgress={projectsInProgress}
				projectsFinished={projectsFinished}
				projectsWaitingPayment={projectsWaitingPayment}
				projectsInRecovery={projectsInRecovery}
				projectsFolderLate={projectsFolderLate}
			/>
			<ApprovalCircuitIngRes approvalCircuit={approvalCircuit}/>
			<HomeIngResTeams teams={projectUnassigned} timeToRealod={() => setTimeToRealod(!timeToRealod)}/>
			<MyCollaboratorIngRes/>
			<h3 className={"color-primary"}>Planification</h3>
			<IngCalendarPart/>
		</>
	);
};

export default HomeResponsableIng;
