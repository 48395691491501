import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

import HomeIngProdTasks from "./Tasks/HomeIngProdTasks";
import HomeIngProdCalendar from "./Calendar/HomeIngProdCalendar";

import { TasksInProgressService } from "../../../../../services/Engineer/TasksInProgressService";

const HomeIngProd = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [taskValidated, setTaskValidated] = useState(false);
	const [projects, setProjects] = useState([])
	const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
	const decodedToken = jwt_decode(user.token)
	const userId = decodedToken.id

	const getUserProjects = async (id) => {
		setIsLoading(true)
		const request = await TasksInProgressService.getEngineerProdTasks(id)
		if (request.request.status === 200) {
			setProjects(request.data["hydra:member"])
			setIsLoading(false)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des tâches.")
		}
	}

	useEffect(() => {
		getUserProjects(userId)
	}, [])

	const handleTaskValidated = () => {
		setTaskValidated(true)
		getUserProjects(userId)
		setTaskValidated(false)
	}

	return (
		<section className="mbe-section">
			{
				isLoading ?
					<Skeleton height={500} />
					:
					<>
						{
							projects.length === 0 ?
								<h3 className='color-primary'>Aucune tâche en cours</h3>
								:
								<HomeIngProdTasks
									projects={projects}
									taskValidated={handleTaskValidated}
								/>
						}
						<HomeIngProdCalendar />
					</>
			}

		</section>
	);
};

export default HomeIngProd;
