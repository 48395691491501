import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export const FILE_IN_PROGRESS_COLUMNS = [
  {
    Header: "Numéro client",
    accessor: "project.client.reference",
  },
  {
    Header: "Nom client",
    accessor: "project.client.lastname",
    Cell: (cellInfo) => {
      return (
        <Link to={`/client/${cellInfo.row.original.project.client.id}`}>
          {cellInfo.row.original.project.client.firstname}{" "}
          {cellInfo.row.original.project.client.lastname}
        </Link>
      );
    },
  },
  {
    Header: "Typologie projet",
    accessor: "project.type",
  },
  {
    Header: "Date demande",
    accessor: "createdAt",
    Cell: (cellInfo) => {
      let date = new Date(cellInfo.row.original.createdAt);
      date = moment(date).format("DD/MM/YYYY");
      return date;
    },
  },
  {
    Header: "Commercial affilié",
    accessor: "project.com.lastname",
  },
  {
    Header: "Type devis",
    accessor: "niceAdditionalQuote",
  },
  {
    Header: "Montant devis (HT)",
    accessor: "total",
    Cell: (cellInfo) => {
      let total = 0;
      if (cellInfo.row.original.total) {
        total = cellInfo.row.original.total.toFixed(2) + " €";
      } else {
        total = "0.00 €";
      }
      return total;
    },
  },
  {
    Header: "Statut projet",
    accessor: "niceStatus",
    Cell: (cellInfo) => {
      if (cellInfo.row.original.niceStatus === "En cours") {
        return (
          <div className="badge badge-waiting">
            <div className={"pin-wainting"}></div>
            {cellInfo.row.original.niceStatus}
          </div>
        );
      } else if (cellInfo.row.original.niceStatus === "Ouvert") {
        return (
          <div className="badge badge-success">
            <div className={"pin-success"}></div>
            {cellInfo.row.original.niceStatus}
          </div>
        );
      } else {
        return (
          <div className="badge badge-error">
            <div className={"pin-error"}></div>
            {cellInfo.row.original.niceStatus}
          </div>
        );
      }
    },
  },
  {
    Header: "",
    accessor: "details",
    Cell: (cellInfo) => {
      return (
        <Link className="btn-little btn-default-primary color-primary" to={`/projet/${cellInfo.row.original.project.id}`}>
          Accéder aux documents
        </Link>
      );
    },
  },
];
