import ButtonWithOnClickAction from "../../../Atomes/Buttons/ButtonWithOnClickAction";

const CardRoutine = ({ routine, openDeletePopup, openEditPopup }) => {
  return (
    <div className="card-routine">
      {
        !routine.mandatory && (
          <img
            className="card-routine__delete"
            src={"/images/pictos/cross-background-red.svg"}
            onClick={() => openDeletePopup(routine.id, routine.title)}
          />
        )
      }

      <p className="text-primary color-primary paragraph_bold">
        {routine.title}
      </p>

      <div className="card-routine__question">
        <p className="text-secondary color-white paragraph_bold">
          <img src={"/images/pictos/help-white.svg"} />
          {routine.question}
        </p>
      </div>

      {
        routine.chatbotAnswers.length > 0 ? (
          routine.chatbotAnswers.map((reponse, index) => (
            <div key={index} className="card-routine__answer">
              <span className="text-secondary color-primary paragraph_bold">
                <img src={"/images/pictos/chatbot-primary.svg"} />
                {
                  reponse.answer !== "" ? (
                    reponse.answer
                  ) : (
                    routine.type === "file" ? ("Sélection de fichiers") : ("Saisie libre de texte")
                  )
                }
              </span>
              {
                reponse.chatbotMiniRoutine && (
                  <span className="text-secondary color-primary paragraph_bold">
                    <img src={"/images/pictos/chatbotLink-primary.svg"} />
                    Mini-routine : {reponse.chatbotMiniRoutine.title}
                  </span>
                )
              }
            </div>
          ))
        ) : (
          <div className="card-routine__answer">
            <span className="text-secondary color-primary paragraph_bold">
              <img src={"/images/pictos/chatbot-primary.svg"} />
              {
                routine.type === "file" ? ("Sélection de fichiers") : ("Saisie libre de texte")
              }
            </span>
          </div>
        )
      }

      {
        !routine.mandatory && (
          <ButtonWithOnClickAction
            title={"Modifier la QR"}
            styleButton={"btn-default btn-default-primary"}
            isActive={true}
            onClick={() => openEditPopup(routine.id)}
          />
        )
      }

    </div>
  )
}

export default CardRoutine;