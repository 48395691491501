import React, { useState, useEffect } from 'react';
import BookingService from '../../../../../../services/BookingService';
import { toast } from 'react-toastify';

import MbeBookSlotSlider from '../../../../../../components/Molecules/BookSlot/MbeBookSlotSlider';
import Select from '../../../../../../components/Atomes/Select/Select';
import {dataRegionsArray} from "../../../../../../utils/RegionsUtils";

const SlotsBookingPart = ({ }) => {

  const [bookings, setBookings] = useState([]);

  const selectValues = dataRegionsArray;
  const selectOptions = dataRegionsArray;
  const [region, setRegion] = useState("");
  const filterByRegion = (event) => {
    if (event.target.value === "Toutes les agences") {
      setRegion("")
    } else {
      setRegion(event.target.value)
    }
  }

  useEffect(() => {
    const getBookings = async () => {
      const request = await BookingService.getBookings({ "region" : region})
      if (request.request.status === 200) {
        setBookings(request.data.shifts)
      } else {
        toast.error("Une erreur est survenue lors de la récupération de la liste des Bookings")
      }
    }
    getBookings()
  }, [region])

  return (
    <>
      <Select 
        defaultValue={"Toutes les agences"}
        styleSelect={"select-custom-secondary"}
        data={selectOptions}
        value={selectValues}
        onChange={filterByRegion}
      />
      <MbeBookSlotSlider 
        bookings={bookings}
        bookingSlotCreator={false} 
      />
    </>
  )
}

export default SlotsBookingPart;