import * as Yup from 'yup';

export const IngTSRSchema = Yup.object().shape({
	zipCode: Yup.string()
		.required('Veillez entrer un code postal')
		.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'code postal invalide'),
	dateConstuction: Yup.string()
		.required('Veillez entrer une date de construction'),
	yearBuilt: Yup.string()
		.required('Veillez entrer une date de construction')
		.matches(/^[0-9]{4}$/, 'Date invalide'),
	numberOfFloors: Yup.string()
		.required('Veillez entrer le nombre d\'étages'),
	loceauxDestination: Yup.string()
		.required('Veillez entrer la destination des locaux'),
	wallConstructionMaterial: Yup.string()
		.required('Veillez entrer le matériau de construction des murs'),
	homogenousWall: Yup.string()
		.required('Veillez entrer le matériau de construction des murs'),
	floorConstructionAndFinishing: Yup.string()
		.required('Veillez entrer le matériau de construction du plancher'),
	floorConstructionAndFinishingMateriel: Yup.string()
		.required('Veillez entrer le matériau de construction du plancher'),
	roofType: Yup.string()
		.required('Veillez entrer le type de toiture'),
	ifTerrace: Yup.string()
		.required('Veillez entrer le type de terrasse'),
	roofComposition: Yup.string()
		.required('Veillez entrer la composition de la toiture'),
	charpente: Yup.string()
		.required('Veillez entrer le type de charpente'),
	chimney: Yup.string()
		.required('Veillez entrer le type de cheminée'),
	currentFloorToBeOpened: Yup.string()
		.required('Veillez entrer l\'étage à ouvrir'),
	floorSpanDirection: Yup.string()
		.required('Veillez entrer le sens de portée des planchers'),
	OPMToBeCarriedOut: Yup.string()
		.required('Veillez entrer les OMP à effectuer'),
	convertibleAtticOrTerrace: Yup.string()
		.required('Veillez entrer le type de combles'),
});
