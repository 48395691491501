import React, { createContext, useState, useEffect } from "react";
import FilterSearchSelectDatepicker from "../../../../../components/Molecules/Filter/FilterSearchSelectDatepicker";
import moment from "moment/moment";
import RequestService from "../../../../../services/RequestService";
import {toast} from "react-toastify";
import Skeleton from "react-loading-skeleton";
import {PARTNER_QUOTES_COLUMNS} from "../../../../../config/ArrayColumns/PartnerQuotesColumns";
import {ADDITIONAL_QUOTE_COLUMNS} from "../../../../../config/ArrayColumns/AdditionalQuoteColumns";
import TableTitle from "../../../../../components/Molecules/Tables/TalbeTitle";
import { COMMERCIAL_FILE_ARCHIVED_COLUMNS } from "../../../../../config/ArrayColumns/CommercialFileArchivedColumns";



export const StateRequest = createContext()
const ArchiveCom = () => {

    const [stateRequest, setStateRequest] = useState("");
    const [date, setDate] = useState(new Date());
    const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");


    const [additionalQuotes, setAdditionalQuotes] = useState([]);
	const [additionalQuotesLoading, setAdditionalQuotesLoading] = useState(true);

	const [partnerQuotes, setPartnerQuotes] = useState([]);
	const [partnerQuotesLoading, setPartnerQuotesLoading] = useState(true);

    const [fileArchived, setFileArchived] = useState([]);
	const [fileArchivedLoading, setfFileArchivedLoading] = useState(true);

    const secondOptions = ["1 mois", "2 mois", "3 mois"]
	const secondValues = [1, 2, 3]

    const id = localStorage.getItem("userId")

    //Search
	const [search, setSearch] = useState("");

    const getPartnerQuotes = async () => {
		const request = await RequestService.getPartnerQuotes({
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"project.users.id": id,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			setPartnerQuotes(request.data);
			setPartnerQuotesLoading(false);
		} else {
			toast.error('Une erreur est survenue lors de la récupération des devis partenaires')
		}
	}

	const getAdditionalQuote = async () => {
		const request = await RequestService.getAdditionalQuote({
			"project.users.id": id,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			setAdditionalQuotes(request.data);
			setAdditionalQuotesLoading(false)
		} else {
			toast.error('Une erreur est survenue lors de la récupération des devis complémentaires')
		}
	}

    useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
            getAdditionalQuote()
            getFileArchived()
            getPartnerQuotes()
		}
	}, [stateRequest])

    const DisplayToast = (type) => {
		if (type === "success reopen") {
			toast.success("Le dossier a bien été rouvert")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

    const getFileArchived = async () => {
        const request = await RequestService.getFileArchived(id, {
            "createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			pagination: "true"
        })
        if (request.request.status === 200) {
			setFileArchived(request.data);
			setfFileArchivedLoading(false)
		} else {
			toast.error('Une erreur est survenue')
		}
    }

    useEffect(() => {
		getAdditionalQuote()
        getPartnerQuotes()
        getFileArchived()
	}, [search, date])


    const changeDateMonthLater = (date) => {
		if (date === "Toutes durées") {
			setDateStart("")
			setDateEnd("")
			setDate(new Date())
		} else {
			let dateEnd = moment().add(1, "days").format("YYYY-MM-DD")
			let dateBegin = moment().subtract(date, 'months').format("YYYY-MM-DD")
			setDate([new Date(dateBegin), new Date(dateEnd)])
			setDateStart(dateBegin)
			setDateEnd(dateEnd)
		}
	}

    const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).add(1, "days").format("YYYY-MM-DD"))
	}

    return (
        <StateRequest.Provider value={{stateRequest, setStateRequest}}>
            <FilterSearchSelectDatepicker
				onChange={(event) => changeDateMonthLater(event.target.value)}
				firstValue={secondValues}
				date={date}
				onChangeDate={(date) => changeDate(date)}
				options={secondOptions}
				value={secondValues}
				defaultValue={"Toutes durées"}
				onSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
				onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
			/>

            {
                !fileArchivedLoading ? (
                    <TableTitle className={"section-array-partner-quotes mbe-section"} title={"Dossiers archivés"}
                    columnTable={COMMERCIAL_FILE_ARCHIVED_COLUMNS} data={fileArchived}/>
                ) : (
                    <Skeleton height={500}/>
                )
            }

            {
				!additionalQuotesLoading ? (
					<TableTitle className={"section-array-partner-quotes mbe-section"} title={"Devis complémentaire"}
					            columnTable={ADDITIONAL_QUOTE_COLUMNS} data={additionalQuotes}/>
				) : (
					<Skeleton height={500}/>
				)
			}
			{
				!partnerQuotesLoading ? (
					<TableTitle className={"section-array-partner-quotes mbe-section"} title={"Devis partenaires"}
					            columnTable={PARTNER_QUOTES_COLUMNS} data={partnerQuotes}/>
				) : (
					<Skeleton height={500}/>
				)
			}
        </StateRequest.Provider>
    )

};

export default ArchiveCom