import React, { useEffect, useState } from 'react';
import TitleSection from "../../../../../../../components/Atomes/Title/TitleSection";
import InputLabelErrorMessage from "../../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import { FieldArray, useFormikContext } from "formik";
import ButtonAddElementForm from "../../../../../../../components/Atomes/Buttons/ButtonAddElementForm";
import ButtonDeleteElementForm from "../../../../../../../components/Atomes/Buttons/ButtonDeleteElementForm";
import { partenerQuoteService } from "../../../../../../../services/PartenerQuoteService";
import { toast } from "react-toastify";
import PopUpDeletePartnerQuotes from "../../../../../../../components/Molecules/PopUp/Devis/PopUpDeletePartnerQuotes";
import moment from "moment";
import RequestService from '../../../../../../../services/RequestService';

const FormPartenershipRequest = ({ setUpdate, update, project }) => {
	

  /*Get all partner*/
  const [partenerCollection, setPartenerCollection] = useState([]);
  const getPartners = async () => {
    const request = await partenerQuoteService.getPartenerQuote();
    if (request.request.status === 200) {
      let data = request.data['hydra:member'];
      data.map((item) => {
        item.serviceIri = `/api/partners/${item.id}`;
      });
      setPartenerCollection(data);
    } else {
      toast.error('Une erreur est survenue lors de la récupération de la liste des partenaires');
    }
  };
  useEffect(() => {
    getPartners();
  }, []);

  const { values, setFieldValue } = useFormikContext();
	const [partnerQuotes, setPartnerQuotes] = useState(values.partnerQuotes);
  /*Function to add PartnerQuote*/
  const addPartnerQuotes = () => {
    const partnerQuotesCopy = [...partnerQuotes];
    const newItem = {
      partnerIri: "",
      title: "",
      status: "",
      signedAt: "",
      profit: "",
      total: "",
    };
    partnerQuotesCopy.push(newItem);
    setPartnerQuotes(partnerQuotesCopy);
    setFieldValue("partnerQuotes", partnerQuotesCopy); // Update Formik values
    setUpdate(update + 1);
  };

  /*Initial Values*/
  useEffect(() => {
    if (values.partnerQuotes && values.partnerQuotes.length > 0) {
      setPartnerQuotes(values.partnerQuotes);
    }
  }, [values.partnerQuotes]);

  /*Function to delete a partnerQuote*/
  const deleteElement = async (index, id) => {
    setDeletePopUpIsActive(false);
    if (id) {
      const request = await RequestService.deletePartnerInformations(id);
      if (request.request.status === 204) {
        const partnerQuotesCopy = [...partnerQuotes];
        partnerQuotesCopy.splice(index, 1);
        setPartnerQuotes(partnerQuotesCopy);
        setFieldValue("partnerQuotes", partnerQuotesCopy); // Update Formik values
        setUpdate(update + 1);
        toast.success("La demande de partenariat a bien été supprimée");
      } else {
        toast.error("Une erreur est survenue lors de la suppression de la demande de partenariat");
      }
    } else {
      const partnerQuotesCopy = [...partnerQuotes];
      partnerQuotesCopy.splice(index, 1);
      setPartnerQuotes(partnerQuotesCopy);
      setFieldValue("partnerQuotes", partnerQuotesCopy); // Update Formik values
      setUpdate(update + 1);
    }
  };

  /*UseState for return or not the popUp (display)*/
  const [deletePopUpIsActive, setDeletePopUpIsActive] = useState(false);

  /*Function to open the pop up of delete*/
  const openPopUpDelete = () => {
    setDeletePopUpIsActive(true);
  };

  /*Initial Values*/
  useEffect(() => {
    if (project.partnerQuotes && project.partnerQuotes.length > 0) {
      let projectCopy = JSON.parse(JSON.stringify(project));
      projectCopy.partnerQuotes.forEach((itemPartnerQuote, indexPartnerQuote) => {
        for (const key in itemPartnerQuote) {
          if (Object.prototype.hasOwnProperty.call(itemPartnerQuote, key)) {
            if (key !== 'serviceIri' && key !== 'title' && key !== 'id' && key !== 'partnerIri' && key !== 'profit' && key !== 'total' && key !== 'status' && key !== "signedAt" && key !== "partner") {
              delete itemPartnerQuote[key];
            }
          }
        }
      });

      projectCopy.partnerQuotes.forEach((item) => {

        item.signedAt = moment(item.signedAt).format('yyyy-MM-DD');
		item.partnerIri = `/api/partners/${item.partner.id}`
      });
      setUpdate(update + 1);
      setPartnerQuotes(projectCopy.partnerQuotes);
      setFieldValue("partnerQuotes", projectCopy.partnerQuotes); // Update Formik values
    }
  }, []);

  return (
    <>
      <FieldArray name={"partnerQuotes"} render={arrayHelper => (
        partnerQuotes.map((item, index) => {
          if (item.length === 0) {
          } else {
            return (<div key={index}>
                <>
                  <TitleSection title={"Demande de partenaire"}/>
                  <div className={"form-row-element form-row-single-element"}>
                    <InputLabelErrorMessage
                      name={`partnerQuotes.${index}.partnerIri`}
                      keyLabel={"title"} keyValue={"serviceIri"} data={partenerCollection}
                      label={"Patenaire"} type={"select"}/>
                  </div>
                  <div className={"form-row-element form-row-single-element"}>
                    <InputLabelErrorMessage name={`partnerQuotes.${index}.title`}
                                            type={"text"} label={"Commentaire sur la demande"}/>
                  </div>
                  <div className={"form-row-element form-row-two-elements"}>
                    <InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"}
                                            data={[{
                                              label: "Annulé",
                                              value: "canceled"
                                            }, {
                                              label: "En cours",
                                              value: "processing"
                                            }, {label: "Signé", value: "paid"}]}
                                            label={"État demande"}
                                            name={`partnerQuotes.${index}.status`}/>
                    <InputLabelErrorMessage type={"date"} label={"Date signature"}
                                            name={`partnerQuotes.${index}.signedAt`}/>
                  </div>
                  <div className={"form-row-element form-row-two-elements"}>
                    <InputLabelErrorMessage type={"number"} label={"Montant apport affaire (HT)"}
                                            name={`partnerQuotes.${index}.profit`}/>
                    <InputLabelErrorMessage type={"number"} label={"Montant devis (HT)"}
                                            name={`partnerQuotes.${index}.total`}/>
                  </div>
                </>
                <ButtonDeleteElementForm onClick={(e) => openPopUpDelete()}
                                         title={"Supprimer une demande de partenaire"}/>

                {deletePopUpIsActive ? <PopUpDeletePartnerQuotes setIsActive={setDeletePopUpIsActive}
                                                                  action={(e) => deleteElement(index, item.id)}/> : ""}
              </div>
            )
          }
        })
      )}/>
      <ButtonAddElementForm onClick={addPartnerQuotes} title={"Ajouter une demande de partenariat"}/>
    </>
  );
};

export default FormPartenershipRequest;