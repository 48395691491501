import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useAuth } from "../../services/useAuth";

const MenuBurger = ({ allowedSidebar, toggleSidebar }) => {

    const { logout } = useAuth();

    /* const [avatarPath, setAvatarPath] = useState("");

    const getAvatarPath = () => {
        let avatar = localStorage.getItem("avatar").replace(/"/g, "")
        setAvatarPath(process.env.REACT_APP_PROD_URL + avatar)
    } */

    /* useEffect(() => {
        getAvatarPath()
    }, []) */

    const handleNavLinkClick = () => {
        // Décochez l'input de type checkbox
        document.getElementById("burger").checked = false;
    };


    return (
        <section className='burger-menu'>

            <input id="burger" type="checkbox" />

            <label for="burger">
                <span></span>
                <span></span>
                <span></span>
            </label>

            <nav>
                <ul>
                    {allowedSidebar.map((item, index) => {
                        return (
                            <li className={"section-side-bar-container-nav-list-item"} key={index}>
                                <NavLink to={item.path} activeclassname={"active"} onClick={handleNavLinkClick}>
                                    <span className='text-primary'>{item.title}</span>
                                </NavLink>
                            </li>
                        )
                    })}

                    <li className={"section-side-bar-container-nav-list-item"}>
                        <NavLink to="/profil" onClick={handleNavLinkClick}>
                            <span className='text-primary'>Mon compte</span>
                        </NavLink>
                    </li>
                    <li className={"section-side-bar-container-nav-list-item"}>
                        <NavLink to="/profil" onClick={logout}>
                            <span className='text-primary'>Déconnexion</span>
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </section>
    )



}

export default MenuBurger;