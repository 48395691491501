import React from 'react';

const StudyFolderProjectSheetCardUser = ({image, job, name, phone, email, adress}) => {
	return (
		<div className={"study-folder-project-sheet-card-user"}>
			<img src={image} alt={"img user"}/>
			<div className={"study-folder-project-sheet-card-user-name"}>
				<p className={"text-primary color-black"}>{job}</p>
				<p className={"text-primary bold color-black"}>{name}</p>
			</div>
			<div className={"study-folder-project-sheet-card-user-informations"}>
				<p className={"text-primary color-black"}>{phone}</p>
				<p className={"text-primary color-black"}>{email}</p>
				<p className={"text-primary color-black"}>{adress}</p>
			</div>
		</div>
	);
};

export default StudyFolderProjectSheetCardUser;
