import React, { useContext } from 'react';
import { ChatbotContext } from '../Chatbot';
import { toast } from 'react-toastify';

import { Formik, Form } from 'formik';
import InputLabelErrorMessage from '../../../components/Atomes/Formik/InputLabelErrorMessage';
import { LoginSchema } from './Config/LoginSchema';

import { ChatbotService } from '../../../services/ChatbotService';

const ChatbotPopupLogin = () => {

  const contextValue = useContext(ChatbotContext);

  const submitForm = async (values) => {
    await ChatbotService.getUserToken(values)
      .then((response) => {
        if (response.status === 200) {
          contextValue.setUserToken(response.data.token);
          contextValue.setActivePopup(false);
        }
      })
      .catch((error) => {
        toast.error('Identifiant ou mot de passe incorrect');
      });
  }

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={LoginSchema}
      onSubmit={
        (values) => submitForm(values)
      }
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="form-row-element form-row-single-element">
            <InputLabelErrorMessage
              name="username"
              type="text"
              label="Identifiant"
              placeholder="Adresse e-mail"
            />
          </div>
          <div className="form-row-element form-row-element__pwd form-row-single-element">
            <InputLabelErrorMessage
              name="password"
              type="password"
              label="Mot de passe"
            />
          </div>
          <div className="form-row-element form-row-single-element">
            <button
              type="submit"
              className="btn-default btn-default-secondary plain btn-default-full-width"
            >
              Connexion
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ChatbotPopupLogin;