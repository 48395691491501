import React from 'react';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { blueJeans, green, purple } from "../../../config/Color";
import Annotation from "chartjs-plugin-annotation";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Annotation);


function average(ctx) {
	const values = ctx.chart.data.datasets[0].data;
	return values.reduce((a, b) => a + b, 0) / values.length;
}


export function ChartBar({ monthlyObjective, title, legend, content }) {

	const data = {
		labels: content.map((item) => item.date),
		datasets: [{
			label: '',
			data: content.map((item) => item.data),
			backgroundColor: blueJeans(),
		},]

	}
	const annotationMean = {
		type: 'line',
		borderColor: purple(),
		borderDash: [10, 10],
		borderDashOffset: 0,
		borderWidth: 3,
		scaleID: 'y',
		value: (ctx) => average(ctx)
	};
	const annotationObjectif = {
		type: 'line',
		borderColor: green(),
		borderDash: [10, 10],
		borderDashOffset: 0,
		borderWidth: 3,
		scaleID: 'y',
		value: monthlyObjective
	};
	const options = {
		responsive: true, aspectRatio: 1, barThickness: 11.89, borderRadius: 2, scales: {
			x: {
				grid: {
					display: false, lineWidth: 0.3, color: blueJeans()
				}, max: 12, display: true, ticks: {
					borderWidth: 90, autoSkip: true, maxRotation: 90
				}
			}, y: {
				grid: {
					borderColor: "transparent",
				}, beginAtZero: true,
				ticks: {
					color: blueJeans(),
					fontFamily: "Helvetica Neue",
					padding: 10,
					borderColor: blueJeans(),
					callback: function (value, index, ticks) {
						if (index % 2 === 0) {
							return String(value) + " €"
						}
					}
				}
			}
		}, plugins: {
			annotation: {
				annotations: monthlyObjective <= 0 ? {
					annotationMean,
				} : {
					annotationMean, annotationObjectif,
				}
			}, legend: {
				display: false,
			}, title: {
				display: false,
			},
		},
	};

	return (
		<div className={`element-kpi element-kpi-l bars`}>
			<div className={"element-kpi-title"}>
				<h4 className={"color-primary"}>{title}</h4>
			</div>
			<div className={"element-kpi-legend"}>
				{legend.map((item, index) => <p key={index}
					className={"text-primary color-" + item.color}>{item.title}</p>)}
			</div>
			<div className={"chart-bar"}>
				<Bar options={options} data={data} />
			</div>
		</div>
	)
}

export default ChartBar;
