import React from 'react';
import ChartBar from "../../../../../../components/Atomes/Charts/ChartBar";
import ChartDonut from "../../../../../../components/Atomes/Charts/ChartDonut";
import KpiSimpleCard from '../../../../../../components/Atomes/Kpis/KpiSimpleCard';

const KpisViewCharts = ({
	dateStart, monthlyObjective, transformRateTotal, transformRateToday, transformRateLastMonth,
	revenue, revenueToday, revenueCurrentMonth,
	averageBasket, revenueByMonth, requestsCount, processingQuotesCount, signedQuotesCount, processingQuotes
}) => {
	return (
		<div className={"section-kpi-container"}>

			{/* {
				(revenueToday.total !== null || revenueCurrentMonth.total !== null) ? (
					dateStart === "" ? (

						<div className={"element-kpi-grid-double-element"}>
							{
								revenueToday.total ?
									(
										<Cart className={"panier-moyen custom-chart"} title={"CA du jour"} value={revenueToday.total} />
									) : (null)
							}
							{
								revenueCurrentMonth.total ?
									(
										<Cart className={"ca-total custom-chart"} title={"CA du mois"} value={revenueCurrentMonth.total} />
									) : (null)
							}
						</div>
					) : null
				) : null

			} */}

			<ChartBar className={"chiffre-affaires custom-chart"} title={"Chiffre d’Affaires"}
				monthlyObjective={monthlyObjective}
				legend={[{ title: "Chiffre affaires moyen", color: "purple" }, {
					title: "Objectif Mensuel", color: "green"
				}]}
				content={revenueByMonth}
			/>

			{/* <div className={"element-kpi-grid-double-element"}>
				<Cart className={"panier-moyen custom-chart"}  />
				<Cart className={"ca-total custom-chart"} title={"CA Total"} value={revenue.total} />
			</div>*/}

			<div className="kpi-container-simpleCards element-kpi-l">
				<KpiSimpleCard title={"Chiffre d’affaires global"} value={ revenue.total != null ? `${revenue.total} €` : 0} />
				<KpiSimpleCard title={"Panier moyen"} value={ !isNaN(averageBasket) ? `${averageBasket} €` : 0} />
			</div>

			{
				transformRateToday.total && dateStart === "" ?
					(
						<ChartDonut className={"taux-transformation custom-chart"}
							data1={transformRateToday.valid}
							data2={transformRateToday.total}
							rate={transformRateToday.rate}
							legend={[{ title: "Devis signés", color: "primary" }, {
								title: "Devis en cours d'édition", color: "blue-jeans"
							}]} title={"Taux de transformation journalier"}
						/>
					) : null
			}

			{
				transformRateLastMonth.total && dateStart === "" ?
					(
						<ChartDonut className={"taux-transformation custom-chart"}
							data1={transformRateLastMonth.valid}
							data2={transformRateLastMonth.total}
							rate={transformRateLastMonth.rate}
							legend={[{ title: "Devis signés", color: "primary" }, {
								title: "Devis en cours d'édition", color: "blue-jeans"
							}]} title={"Taux de transformation mensuel"}
						/>
					) : (null)
			}

			{/* <ChartDonut className={"taux-transformation custom-chart"}
				data1={transformRateTotal.valid}
				data2={transformRateTotal.total}
				rate={transformRateTotal.rate}
				legend={[{ title: "Devis validés", color: "primary" }, {
					title: "Devis a réaliser", color: "blue-jeans"
				}]} title={"Taux de transformation"} /> */}


			<div className="kpi-container-simpleCards element-kpi-l">
				<KpiSimpleCard title={"Devis en cours d'édition"} value={processingQuotesCount ? processingQuotesCount : "0"} />
				<KpiSimpleCard title={"Devis signés"} value={signedQuotesCount ? signedQuotesCount : "0" } />
			</div>
			<div className="kpi-container-simpleCards element-kpi-l">
				<KpiSimpleCard title={"Demandes entrantes"} value={requestsCount ? requestsCount : "0"} />
				<KpiSimpleCard title={"Montant des devis édités (HT)"} value={ processingQuotes.total ? `${processingQuotes.total} €` : "0"}/>
			</div>
		</div>
	)

}
export default KpisViewCharts;
