import api from "./APIService";
import {ConvertObjectToUrlParams} from "../utils/StringUtils";

const getProjectInProgress = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("kpi/admin/active-projects-count", param))
}

const getFolderDone = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("kpi/admin/ing-projects-count", param))
}

const getNumberAvoir = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("kpi/admin/credits-count", param))
}

const getNumberFolderWaitingPayment = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("kpi/admin/processing-final-invoices-count", param))
}

const getNumberProjectRecouvrement = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("kpi/admin/canceled-projects-count", param))
}

const getNumberFolderLate = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("kpi/admin/overdue-studies-count", param))
}

const getNumberFolderWithProblems = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("kpi/admin/studies-by-control", param))
}

const KpiAdminService = {
	getProjectInProgress,
	getFolderDone,
	getNumberAvoir,
	getNumberFolderWaitingPayment,
	getNumberProjectRecouvrement,
	getNumberFolderLate,
	getNumberFolderWithProblems
}

export default KpiAdminService;