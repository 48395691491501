import React, {useEffect, useState} from 'react';
import TitleSectionWithButton from "../../../../../components/Molecules/Title/TitleSectionWithButton";
import exportFileService from '../../../../../services/ExportFileService';
import moment from "moment/moment";
import {toast} from "react-toastify";

import KpiSimpleCard from '../../../../../components/Atomes/Kpis/KpiSimpleCard';
import DirRespService from "../../../../../services/Ing/DirResp/DirRespService";
import {ChartPie} from "../../../../../components/Atomes/Charts/ChartPie";
import ChartBarHorizontal from "../../../../../components/Atomes/Charts/ChartBarHorizontal";
import FilterThreeSelectsDatepicker from "../../../../../components/Molecules/Filter/FilterThreeSelectsDatepicker";
import {ConvertObjectToUrlParams} from "../../../../../utils/StringUtils";
import CollaboratorService from "../../../../../services/CollaboratorService";
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

const KpiViewIngDir = () => {

	const options = dataRegionsArray
	const [date, setDate] = useState(new Date());
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [region, setRegion] = useState("");
	const [userFilter, setUserFilter] = useState("")
	const [collaborators, setCollaborators] = useState([]);
	const [projectsFilter, setProjectsFilter] = useState("");


	// Const for kpi
	const [projectsInProgress, setProjectsInProgress] = useState(0);
	const [projectsFinished, setProjectsFinished] = useState(0);
	const [projectsWaitingPayment, setProjectsWaitingPayment] = useState(0);
	const [projectsInRecovery, setProjectsInRecovery] = useState(0);
	const [projectsFolderLate, setProjectsFolderLate] = useState(0);

	const [numberAssetsEdited, setNumberAssetsEdited] = useState(0);
	const [projectStatus, setProjectStatus] = useState([]);
	const [caByProject, setCaByProject] = useState([]);
	const [timeProduction, setTimeProduction] = useState(0);
	const [caGlobal, setCaGlobal] = useState(0);

	const [allProjects, setAllProjects] = useState([]);

	const getProjectInProgress = async () => {
		const request = await DirRespService.getProjectInProgress({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter,
			"project.id": projectsFilter
		})
		if (request.request.status === 200) {
			setProjectsInProgress(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets en cours")
		}
	}

	const getProjectFinished = async () => {
		const request = await DirRespService.getProjectFinished({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter,
			"project.id": projectsFilter
		})
		if (request.request.status === 200) {
			setProjectsFinished(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets terminés")
		}
	}

	const getProjectWaitingPayment = async () => {
		const request = await DirRespService.getNumberFolderWaitingPayment({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter,
			"project.id": projectsFilter
		})
		if (request.request.status === 200) {
			setProjectsWaitingPayment(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets en attente de paiement")
		}
	}

	const getProjectInRecovery = async () => {
		const request = await DirRespService.getNumberProjectInRecovery({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter,
			"project.id": projectsFilter
		})
		if (request.request.status === 200) {
			setProjectsInRecovery(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets en recouvrement")
		}
	}

	const getProjectFolderLate = async () => {
		const request = await DirRespService.getNumberFoderLate({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter,
			"project.id": projectsFilter
		})
		if (request.request.status === 200) {
			setProjectsFolderLate(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets en retard")
		}
	}

	const getNumberAssetsEdited = async () => {
		const request = await DirRespService.getNumberAssetsEdited({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter,
			"project.id": projectsFilter
		})
		if (request.request.status === 200) {
			setNumberAssetsEdited(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre d'avoir édité")
		}
	}

	const getStatusStudyFolder = async () => {
		const request = await DirRespService.getStatusStudyFolder({
				region: region,
				dateStart: dateStart,
				dateEnd: dateEnd,
				"user.id": userFilter,
				"project.id": projectsFilter
			}
		)
		if (request.request.status === 200) {
			setProjectStatus(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des status")
		}
	}

	const getCAByProject = async () => {
		const request = await DirRespService.getCAByProject({
				region: region,
				dateStart: dateStart,
				dateEnd: dateEnd,
				"user.id": userFilter,
				"project.id": projectsFilter
			}
		)
		if (request.request.status === 200) {
			setCaByProject(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du CA par projet")
		}
	}

	const getTimeProduction = async () => {
		const request = await DirRespService.getTimeProduction({
				region: region,
				dateStart: dateStart,
				dateEnd: dateEnd,
				"user.id": userFilter,
				"project.id": projectsFilter
			}
		)
		if (request.request.status === 200) {
			setTimeProduction(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du temps de production")
		}
	}

	const getCaGlobal = async () => {
		const request = await DirRespService.getCAGlobal({
				region: region,
				dateStart: dateStart,
				dateEnd: dateEnd,
				"user.id": userFilter,
				"project.id": projectsFilter
			}
		)
		if (request.request.status === 200) {
			setCaGlobal(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du CA global")
		}
	}

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}
	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Tous les collaborateurs") {
			setUserFilter("")
		} else {
			setUserFilter(event.target.value)
		}
	}

	const getCollaborators = async () => {
		const url = ConvertObjectToUrlParams("coms", {regions: region, roles: "ROLE_COM"})
		const request = await CollaboratorService.getCollaborators(url)
		if (request.request.status === 200) {
			setCollaborators(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}


	useEffect(() => {
		getCollaborators()

		// KPI
		getProjectInProgress()
		getProjectFinished()
		getProjectFolderLate()
		getProjectInRecovery()
		getProjectWaitingPayment()
		getNumberAssetsEdited()
		getStatusStudyFolder()
		getCAByProject()
		getTimeProduction()
		getCaGlobal()

		getAllProjects()

	}, [region, userFilter, date, projectsFilter, dateStart, dateEnd])


	const downloadFile = () => {
		exportFileService.exportFile("/engineer/kpis", 'export-kpis', {
			dateStart: dateStart,
			dateEnd: dateEnd,
			region: region,
			"users.id": userFilter,
			"project.id": projectsFilter
		}, "xlsx")
	}

	const getAllProjects = async () => {
		const request = await DirRespService.getAllProjects({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"user.id": userFilter,
			"stage.title": "ING",
			pagination: "false"
		})
		if (request.request.status === 200) {
			setAllProjects(request.data["hydra:member"])
		} else {
			toast.error("Une erreur est survenue lors de la récupération des projets")
		}
	}
	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}

	const changeProjectFilter = (event) => {
		if (event.target.value === "Tous les projets") {
			setProjectsFilter("")
		} else {
			setProjectsFilter(event.target.value)
		}
	}

	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	return (
		<>
			<FilterThreeSelectsDatepicker
				defaultValue={"Toutes les agences"}
				options={options}
				firstValue={options}
				secondDefaultValue={"Tous les collaborateurs"}
				secondOptions={collaborators.map(collaborators => collaborators.lastname)}
				secondValue={collaborators.map(collaborators => collaborators.id)}
				styledButton={"btn-default btn-default-primary btn-default-primary color-primary"}
				titleButton={"Selectionnez une période"}
				date={date}
				onChangeDate={(date) => changeDate(date)}
				onChange={changeRegionFilter}
				onSecondChange={changeCollaboratorFilter}
				resetDateSelected={resetDate}
				thirdDefaultValue={"Tous les projets"}
				thirdOptions={allProjects.map(project => project.reference)}
				thirdValue={allProjects.map(project => project.id)}
				onThirdChange={changeProjectFilter}
			/>

			<div className={"section-kpi-title"}>
				<TitleSectionWithButton title={"Indicateurs de performance"} buttonTitle={"Exporter"} isActive={true}
				                        styledButton={"btn-default btn-default-blue btn-download color-primary"}
				                        onClick={downloadFile}
				/>
			</div>

			<div className={"section-kpi-container"}>

				<div className="kpi-container-simpleCards">
					<KpiSimpleCard title={"Projets en cours"} value={projectsInProgress.total}/>
					<KpiSimpleCard title={"Dossiers rendu "} value={projectsFinished.total}/>
				</div>
				<div className="kpi-container-simpleCards">
					<KpiSimpleCard title={"Dossiers en attente de paiement final"} value={projectsWaitingPayment.total}/>
					<KpiSimpleCard title={"Projets en recouvrement"} value={projectsInRecovery.total}/>
				</div>
				<div className={"kpi-container-simpleCards"}>
					<KpiSimpleCard title={"Dossiers en retard de rendu"} value={projectsFolderLate.total}/>
					<KpiSimpleCard title={"Nombre d'avoir édité"} value={numberAssetsEdited.total}/>
				</div>
				<div className={"kpi-container-simpleCards"}>
					<KpiSimpleCard title={"Temps de production en heure"} value={timeProduction.total}/>
					<KpiSimpleCard title={"CA global HT"} value={caGlobal.total}/>
				</div>

				<ChartPie className={"ca-type-etude custom-chart"}
				          content={caByProject}
				          title={"CA par stade d'étude"}
				/>

				<ChartBarHorizontal className={"type-etude custom-chart"}
				                    title={"Statut dossier d’étude"}
				                    legend={
					                    [
						                    {title: "CA Moyen 2022", color: "purple"}, {
						                    title: "Objectif Mensuel", color: "green"
					                    }
					                    ]
				                    }
				                    content={projectStatus}
				/>


			</div>
		</>
	)
}
export default KpiViewIngDir;
