import React, {useEffect, useState} from 'react';
import {CCTP_COLUMNS} from "../../../../config/ArrayColumns/Admin/CctpColumns";
import CctpService from "../../../../services/CctpService";
import {toast} from "react-toastify";
import {BasicSortingTable} from "../../../../components/Molecules/Tables/BasicSortingTable";

const AdminCctpView = () => {
	const [cctp, setCctp] = useState([])

	const getCctp = async () => {
		const request = await CctpService.getCctp({pagination: "false"})
		if (request.request.status === 200) {
			const cctpTries = request.data['hydra:member'].sort((a, b) => {
				return a.position.localeCompare(b.position, 'fr', {numeric: true});
			});

			setCctp(cctpTries)

		} else {
			toast.error("Une erreur est survenue lors des CCTP")
		}
	}

	useEffect(() => {
		getCctp()
	}, [])

	return (
		<section className={"mbe-section"}>
			<h3 className='color-primary'>Annuaire - Cahier des Clauses Techniques Particulières (CCTP)</h3>
			<BasicSortingTable
				dataTable={cctp}
				columnsTable={CCTP_COLUMNS}
			/>
		</section>
	);
};

export default AdminCctpView;
