import React from "react";
import ButtonPopupDatepicker from "../../Atomes/Buttons/ButtonPopupDatepicker";
import Select from "../../Atomes/Select/Select";

const FilterThreeSelectsDatepicker =
	({
		 defaultValue,
		 options,
		 firstValue,
		 secondDefaultValue,
		 secondOptions,
		 secondValue,
		 thirdDefaultValue,
		 thirdOptions,
		 thirdValue,
		 onChange,
		 onSecondChange,
		 onThirdChange,
		 onChangeDate,
		 date,
		 resetDateSelected

	 }) => {
		return (
			
			<div className={"filter-datepicker filter-datepicker-three-selects"}>
				<Select
					onChange={onChange}
					styleSelect={"select-custom-secondary"}
					value={firstValue}
					defaultValue={defaultValue}
					data={options}
				/>

				<Select
					styleSelect={"select-custom-secondary"}
					onChange={onSecondChange}
					defaultValue={secondDefaultValue}
					data={secondOptions}
					value={secondValue}
				/>

				<Select
					styleSelect={"select-custom-secondary"}
					onChange={onThirdChange}
					defaultValue={thirdDefaultValue}
					data={thirdOptions}
					value={thirdValue}
				/>
				<ButtonPopupDatepicker
					date={date}
					onChange={onChangeDate}
					resetDateSelected={resetDateSelected}
				/>

			</div>
		)
	}
export default FilterThreeSelectsDatepicker;
