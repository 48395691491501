import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import Select from '../../../../../components/Atomes/Select/Select';
import ButtonSubmit from '../../../../../components/Atomes/Buttons/ButtonSubmit';
import MbeBookSlotSlider from '../../../../../components/Molecules/BookSlot/MbeBookSlotSlider';

import BookingService from '../../../../../services/BookingService';
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

const AdminBookingView = () => {

	const [bookings, setBookings] = useState([]);
	const [region, setRegion] = useState("");
	const [isRegionSelected, setIsRegionSelected] = useState(false);

	const [bookingType, setBookingType] = useState(null);
	const [bookingAction, setBookingAction] = useState(null);

	const [isBookingSlotSelected, setIsBookingSlotSelected] = useState(false);

	const [bookingSlotDaySelected, setBookingSlotDaySelected] = useState(null);
	const [isBookingSlotDaySelected, setIsBookingSlotDaySelected] = useState(false);

	const [bookingPeriodSelectedFirst, setBookingPeriodSelectedFirst] = useState(null);
	const [bookingPeriodSelectedSecond, setBookingPeriodSelectedSecond] = useState(null);
	const [isBookingSlotPeriodSelected, setIsBookingSlotPeriodSelected] = useState(false);

	const [bookingSlotRequestPost, setBookingSlotRequestPost] = useState(false);
	const [bookingSlotStartAt, setBookingSlotStartAt] = useState("");
	const [bookingSlotEndAt, setBookingSlotEndAt] = useState("");

	const [bookingSelectPeriod, setBookingSelectPeriod] = useState([])


	const changeRegionFilter = (event) => {
		setBookingSlotStartAt("")
		setBookingSlotEndAt("")
		if (event.target.value != "Sélectionner une zone géographique") {
			setIsRegionSelected(true)
			setBookingType(null)
			setRegion(event.target.value)
		} else {
			setIsRegionSelected(false)
			setBookingType(null)
			setRegion("")
		}
		setBookingAction(null)
		setIsBookingSlotSelected(false)
		setIsBookingSlotDaySelected(false)
		setIsBookingSlotPeriodSelected(false)
	}
	const changeBookingType = (event) => {
		if (event.target.value === "SPE") {
			setBookingType(0)
		} else if (event.target.value === "DAY") {
			setBookingType(1)
		} else if (event.target.value === "PER") {
			setBookingType(2)
		} else {
			setBookingType(null)
		}
		setIsBookingSlotSelected(false);
		setIsBookingSlotDaySelected(false);
	}
	const changeBookingAction = (event) => {
		if (event.target.value === "ADD") {
			setBookingAction(0)
		} else if (event.target.value === "DEL") {
			setBookingAction(1)
		} else {
			setBookingAction(null)
		}
	}

	useEffect(() => {
		setBookingSelectPeriod([])
	}, [bookingAction])

	const getBookings = async () => {
		const request = await BookingService.getBookings({'region': region})
		if (request.status === 200) {
			setBookings(request.data.shifts)
		} else {
			toast.error("Une erreur est survenue lors de la récupération de la liste des créneaux de réservations")
		}
	}
	useEffect(() => {
		getBookings();
	}, [bookingSlotRequestPost, region]);

	const selectBookingSlot = (e) => {
		if((e.target.attributes["attr-blocked"].value === "blocked" && bookingAction === 1) || (e.target.attributes["attr-blocked"].value === "free" && bookingAction === 0)) {
		setIsBookingSlotSelected(true);
	
		const newStartDate = e.target.attributes["attr-startat"].value;
		const newEndDate = e.target.attributes["attr-endat"].value;
	
		const isDateAlreadySelected = bookingSelectPeriod.some(slot => {
			return slot.startAt === newStartDate && slot.endAt === newEndDate;
		});
	
		if (!isDateAlreadySelected) {
			const newSlot = {
				startAt: newStartDate,
				endAt: newEndDate,
				region: region
			};
	
			setBookingSelectPeriod(prevPeriods => [...prevPeriods, newSlot]);
			setBookingSlotStartAt(newStartDate);
			setBookingSlotEndAt(newEndDate);
		} else {
			const updatedPeriods = bookingSelectPeriod.filter(slot => {
				return !(slot.startAt === newStartDate && slot.endAt === newEndDate);
			});
	
			setBookingSelectPeriod(updatedPeriods);
		}
	}
	}

	useEffect(() => {
		if(bookingSelectPeriod.length === 0) {
			setIsBookingSlotSelected(false)
		}
	}, [bookingSelectPeriod])
	
	const selectBookingSlotDay = (e) => {
		setIsBookingSlotDaySelected(true)
		setBookingSlotDaySelected(e.target.value);
	}
	const selectBookingSlotPeriodFirst = (e) => {
		setBookingPeriodSelectedFirst(e.target.value);
		if (bookingPeriodSelectedSecond != null) {
			setIsBookingSlotPeriodSelected(true)
		}
	}
	const selectBookingSlotPeriodSecond = (e) => {
		setBookingPeriodSelectedSecond(e.target.value);
		if (bookingPeriodSelectedFirst != null) {
			setIsBookingSlotPeriodSelected(true)
		}
	}

	const submitBooking = async () => {
		let data = {};
		if (bookingType === 0) {
			data = {
				"shifts": bookingSelectPeriod,
				"region": region,
				"block": true
			}
		} else if (bookingType === 1) {
			data = {
				"periodStart": bookingSlotDaySelected + " T00:00:00.000Z",
				"periodEnd": bookingSlotDaySelected + " T23:59:59.000Z",
				"region": region,
				"block": true
			}
		} else if (bookingType === 2) {
			data = {
				"periodStart": bookingPeriodSelectedFirst + " T00:00:00.000Z",
				"periodEnd": bookingPeriodSelectedSecond + " T23:59:59.000Z",
				"region": region,
				"block": true
			}
		}
		let request = await BookingService.bookingBlockedService(data);
		if (request.request.status === 201) {
			toast.success("Le créneau sélectionné a bien été réservé")
		} else {
			toast.error("Une erreur est survenue lors de la réservation de la visite")
		}
		setBookingSlotRequestPost(true)
		setIsBookingSlotSelected(false)
		setIsBookingSlotDaySelected(false)
		setIsBookingSlotPeriodSelected(false)
		setBookingSlotDaySelected(null)
		setBookingPeriodSelectedFirst(null)
		setBookingPeriodSelectedSecond(null)
		setBookingSlotStartAt("")
		setBookingSlotEndAt("")
		setBookingType(null)
		setRegion("")
		setTimeout(() => {
			setBookingSlotRequestPost(false)
		}, 2000);
		setIsRegionSelected(false)
	}
	const delBooking = async () => {
		let data = {};
		if (bookingType === 0) {
			data = {
				"shifts": bookingSelectPeriod,
				"region": region,
				"block": false,
			}
		} else if (bookingType === 1) {
			data = {
				"periodStart": bookingSlotDaySelected + " T00:00:00.000Z",
				"periodEnd": bookingSlotDaySelected + " T23:59:59.000Z",
				"region": region,
				"block": false,
			}
		} else if (bookingType === 2) {
			data = {
				"periodStart": bookingPeriodSelectedFirst + " T00:00:00.000Z",
				"periodEnd": bookingPeriodSelectedSecond + " T23:59:59.000Z",
				"region": region,
				"block": false,
			}
		}
		let request = await BookingService.bookingBlockedService(data);
		if (request.request.status === 201) {
			toast.success("Le créneau sélectionné a bien été débloqué")
		} else {
			toast.error("Une erreur est survenue lors du deblocage de la visite")
		}
		setBookingSlotRequestPost(true)
		setIsBookingSlotSelected(false)
		setIsBookingSlotDaySelected(false)
		setIsBookingSlotPeriodSelected(false)
		setBookingSlotDaySelected(null)
		setBookingPeriodSelectedFirst(null)
		setBookingPeriodSelectedSecond(null)
		setBookingSlotStartAt("")
		setBookingSlotEndAt("")
		setBookingType(null)
		setRegion("")
		setTimeout(() => {
			setBookingSlotRequestPost(false)
		}, 2000);
		setIsRegionSelected(false)
	}

	return (
		<>

			{bookingSlotRequestPost && (<Skeleton height={200}/>)}

			{!bookingSlotRequestPost && (
				<div className='booking-admin-steps__select'>
					<p className={"text-primary color-dark-grey"}>Sélectionner une zone géographique</p>
					<Select
						defaultValue={"Sélectionner une zone géographique"}
						data={dataRegionsArray}
						value={dataRegionsArray}
						onChange={changeRegionFilter}
						styleSelect={"select-custom-secondary"}
					/>
				</div>
			)}

			{isRegionSelected && !bookingSlotRequestPost && (
				<div className='booking-admin-steps__select'>
					<p className={"text-primary color-dark-grey"}>Quelle action voulez-vous effectuer ?</p>
					<Select
						defaultValue={"Sélectionner une action"}
						data={[
							"Réserver un créneau",
							"Débloquer un créneau",
						]}
						value={["ADD", "DEL"]}
						onChange={changeBookingAction}
						styleSelect={"select-custom-secondary"}
					/>
				</div>
			)}

			{isRegionSelected && (bookingAction != null) && !bookingSlotRequestPost && (
				<div className='booking-admin-steps__select'>
					<p className={"text-primary color-dark-grey"}>Sélectionner le type de créneau ?</p>
					<Select
						defaultValue={"Sélectionner un type de créneau"}
						data={[
							"Sélectionner un créneau spécifique",
							"Sélectionner un jour complet",
							"Sélectionner une période"
						]}
						value={["SPE", "DAY", "PER"]}
						onChange={changeBookingType}
						styleSelect={"select-custom-secondary"}
					/>
				</div>
			)}

			{isRegionSelected && (bookingAction != null) && (bookingType === 0) && !bookingSlotRequestPost && (
				<section className='mbe-bookingSlot-steps'>
					<MbeBookSlotSlider
						selectedPeriod={bookingSelectPeriod}
						bookings={bookings}
						selectBookingSlot={selectBookingSlot}
						bookingSlotCreator={true}
						bookingSlotDeletor={bookingAction === 0 ? false : true}
					/>
					{isBookingSlotSelected && (
						<ButtonSubmit
							title={bookingAction === 0 ? "Réserver le créneau" : "Débloquer le créneau"}
							onClick={bookingAction === 0 ? submitBooking : delBooking}
							isActive={false}
							styleButton={"btn-default btn-default-secondary plain"}
						/>
					)}
				</section>
			)}

			{isRegionSelected && (bookingAction != null) && (bookingType === 1) && !bookingSlotRequestPost && (
				<section className='booking-admin-steps'>
					<div className='booking-admin-steps__select'>
						<p className={"text-primary color-dark-grey"}>Choisir un jour</p>
						<input className={'booking-admin-steps__date'}
						       type="date"
						       onChange={selectBookingSlotDay}
						/>
					</div>
					{isBookingSlotDaySelected && (
						<ButtonSubmit
							title={bookingAction === 0 ? "Réserver le créneau" : "Débloquer le créneau"}
							onClick={bookingAction === 0 ? submitBooking : delBooking}
							isActive={false}
							styleButton={"btn-default btn-default-secondary plain"}
						/>
					)}
				</section>
			)}

			{isRegionSelected && (bookingType === 2) && !bookingSlotRequestPost && (
				<section className='booking-admin-steps'>
					<div className='booking-admin-steps__select period'>
						<p className={"text-primary color-dark-grey"}>Choisir une période</p>
						<div>
							<input className={'booking-admin-steps__date'}
							       type="date"
							       onChange={selectBookingSlotPeriodFirst}
							/>
							<input className={'booking-admin-steps__date'}
							       type="date"
							       onChange={selectBookingSlotPeriodSecond}
							/>
						</div>
					</div>
					{isBookingSlotPeriodSelected && (
						<ButtonSubmit
							title={bookingAction === 0 ? "Réserver le créneau" : "Débloquer le créneau"}
							onClick={bookingAction === 0 ? submitBooking : delBooking}
							isActive={false}
							styleButton={"btn-default btn-default-secondary plain"}
						/>
					)}
				</section>
			)}

		</>
	);
};

export default AdminBookingView;
