import React, { useState } from 'react';

import { ProjectStageService } from '../../../../../../services/ProjectStageService';

const PopupProjectStageDepositVir = ({ showPopup, closeAction, projectInvoice }) => {

  const [projectInvoiceVirProof, setProjectInvoiceVirProof] = useState(null);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isFileValidated, setIsFileValidated] = useState(false);

  const [isErrorOnUpload, setIsErrorOnUpload] = useState(false);
  const [notValidFileErrorMessage, setNotValidFileErrorMessage] = useState(null);


  const validFileExtensions = { file: ['pdf', 'xlsx', 'xls', 'png', 'jpeg', 'jpg'] };
  function isValidFileType(fileName, fileType) {
    return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
  }

  const uploadFile = (e) => {
    setFileUploadLoading(true);

    if (!isValidFileType(e.target.files[0].name, 'file')) {
      setIsErrorOnUpload(true);
      setNotValidFileErrorMessage('Le format du fichier n\'est pas valide, format accepté : pdf, xlsx, xls ,png, jpeg, jpg');
      setFileUploadLoading(false);
      return;
    }
    if (e.target.files[0].size > 15000000) {
      setIsErrorOnUpload(true);
      setNotValidFileErrorMessage('La taille de l\'image ne doit pas dépasser 15Mo');
      setFileUploadLoading(false);
      return;
    }
    setFileName(e.target.files[0].name);
    setFileUploadLoading(false);
    setIsFileUploaded(true);
    setProjectInvoiceVirProof(e.target.files[0]);
  }

  const editFile = () => {
    setIsFileUploaded(false);
    setNotValidFileErrorMessage(null);
    setFileName('');
  }

  const validFile = async () => {
    let request = await ProjectStageService.postPayVir(projectInvoice.id, projectInvoice);
    let requestPostBillVirProof = await ProjectStageService.postBillVirProof(projectInvoice.id, projectInvoiceVirProof);
    if (request.status === 201) {
      if (requestPostBillVirProof.status === 201) {
        setIsFileValidated(true);
      }
    }
  }

  const refreshIfValidated = () => {
    if (isFileValidated) {
      window.location.reload();
    }
  }

  return (
    <div className={`project-step-payment__popup pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"pop-up-simple center"}>
        <img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} alt={"cross"} onClick={ isFileValidated ? refreshIfValidated : closeAction } />
        <img className={"pop-up-simple-img"} src='/images/medias/popup.svg' alt='popup forms' />
        {
          !isFileUploaded && !isFileValidated && !fileUploadLoading && (
            <>
              <p className={"text-primary color-primary"}>
                Pour que nous puissions valider votre virement bancaire, veuillez charger votre preuve de virement qui sera vérifié par nos équipes.
              </p>

              {
                isErrorOnUpload && (
                  <p className='color-red text-primary'>{notValidFileErrorMessage}</p>
                )
              }

              <div className={"pop-up-simple-btns"}>
                {
                  fileUploadLoading ? (
                    <button className={"btn-default btn-valid"}>Chargement...</button>
                  ) : (
                    <>
                      <input type={"file"} name='virProof' id='virProof' onChange={uploadFile} />
                      <label htmlFor='virProof' className={"btn-default btn-valid"}>Charger preuve de virement</label>
                    </>
                  )
                }
              </div>
            </>
          )
        }
        {
          isFileUploaded && !isFileValidated && !fileUploadLoading && (
            <>
              <span className='text-primary color-primary'>
                Voici le nom du fichier chargé : {fileName}
              </span>
              <div className={"pop-up-simple-btns"}>
                <button className={"btn-default btn-cancel"} onClick={editFile}>Modifier le fichier </button>
                <button className={"btn-default btn-valid"} onClick={validFile}>Valider la preuve de paiement</button>
              </div>
            </>
          )
        }
        {
          isFileValidated && (
            <>
              <span className='text-primary color-primary'>
                Votre fichier a bien été importé, nos équipes vont vérifier votre preuve de virement.
              </span>
            </>
          )
        }

      </div>
    </div>
  )
}

export default PopupProjectStageDepositVir;
