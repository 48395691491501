import React, {useEffect, useState} from 'react';
import TitleIconNavLink from "../../../../../../../components/Atomes/Title/TitleIconNavLink";
import {HOME_PATH} from "../../../../../../../config/UrlConstants";
import Button from "../../../../../../../components/Atomes/Buttons/Button";
import PopUpForm from "../../../../../../../components/Molecules/PopUp/PopUpForm";
import FormValidatePri from "../../../../../../../components/Molecules/Form/Engineer/FormValidatePri";
import FormValidatePriRefusClient
	from "../../../../../../../components/Molecules/Form/Engineer/FormValidatePriRefusClient";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {PriService} from "../../../../../../../services/Engineer/PriService";
import PopUpSkipValidationPriClient
	from "../../../../../../../components/Molecules/PopUp/Engineer/PopUpSkipValidationPriClient";
import Skeleton from "react-loading-skeleton";
import DirRespService from "../../../../../../../services/Ing/DirResp/DirRespService";
import PopUpInformationsPri from "../../Partials/PopUpInformationsPri";
import FormValidatePriEdit from '../../../../../../../components/Molecules/Form/Engineer/FormValidatePriEdit';
import { ProjectStageService } from '../../../../../../../services/ProjectStageService';

const StudyFolderPRI = ({}) => {
	const {id} = useParams()
	const [priProject, setPriProject] = useState([])

	const [showInformation, setShowInformation] = useState(false)
	const [showConfirmValidation, setShowConfirmValidation] = useState(false)
	const [showPopUpPriRefus, setShowPopUpPriRefus] = useState(false)
	const [showSkipValidation, setShowSkipValidation] = useState(false)
	const [showPopUpValidatePriClient, setShowPopUpValidatePriClient] = useState(false)
	const [submitForm, setSubmitForm] = useState(false)

	const [isLoading, setIsLoading] = useState(true);

	const [idStudyFolder, setIdStudyFolder] = useState(null)

	const getProjectsInformations = async () => {
		const request = await DirRespService.getStudyFolderInformations(id)
		if (request.status === 200) {
			if (request.data.project) {
				getPri(request.data.project.id)
				setIdStudyFolder(request.data.project.id)
			} else {
				toast.error("Une erreur est survenue lors de la récupération des tâches.")
			}
		}
	}

	const getPri = async (id) => {
		const request = await PriService.getEngineerSingleProject(id)
		if (request.request.status === 200) {
			setPriProject(request.data)
			setIsLoading(false)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des tâches.")
		}
	}

	useEffect(() => {
		getProjectsInformations()
	}, [setPriProject, setSubmitForm, setShowSkipValidation])

	/* const showPopUpConfirmation = (newValue) => {
		setShowPopUpValidatePriClient(newValue)
	} */

	const downloadDocument = async () => {
		await ProjectStageService.getPRI(priProject.priValidation.id, priProject.priValidation.filename);
		toast.success("Téléchargement du document en cours");
	  }

	return (
		<div className={"section-study-folder-pri mbe-section"}>
			<div className={"section-study-folder-pri-title"}>
				<TitleIconNavLink title=
					                  {priProject.visit && priProject.visit.address
						                  ? `${priProject.client.niceNames} - ${priProject.visit.address.street}, ${priProject.visit.address.nicePostcode}, ${priProject.visit.address.city}`
						                  : null}
				                  link={HOME_PATH}
				                  information={true}
				                  onClick={() => setShowInformation(true)}
				/>
			</div>
			<h3 className={"text-primary color-primary"}>Validation principe de structure (PRI)</h3>
			{isLoading ?
				<Skeleton height={400}/>
				:
				!priProject.priValidation ? (
					<>
						<PopUpInformationsPri
							show={showInformation}
							onClick={() => setShowInformation(false)}
							large={false}
							title={"Informations projet"}
							content={priProject}
						/>
						<div className="section-study-folder-pri-container">
							<p className={"text-primary color-primary"}>
								La validation du principe de structure est une étape primordiale pour certaines typologies
								d'étude
								structure. Vous trouverez au sein des documents de référence, le document type pour faire
								valider
								aux clients le principe de structure. À la suite de la validation du principe de structure, vous
								pourrez passer à l'étape de rédaction du dossier d'étude structure.
							</p>
							<p className={"text-primary color-primary paragraph_bold"}>
								Voulez-vous faire valider un principe de structure aux clients ?
							</p>
							<div className="section-study-folder-pri-container-buttons">
								<Button
									styleButton={"btn-default btn-border btn-border-blue_jeans"}
									isActive={true}
									onClick={() => setShowSkipValidation(true)}
									title={"Non"}
								/>
								<Button
									styleButton={"btn-default btn-default-secondary plain"}
									isActive={true}
									onClick={() => setShowConfirmValidation(true)}
									title={"Oui"}
								/>
							</div>
						</div>
						{
							showSkipValidation ? (
								<PopUpSkipValidationPriClient
									onClickClose={() => setShowSkipValidation(false)}
									setSubmitForm={setSubmitForm}
									projectId={idStudyFolder}
								/>
							) : null
						}
						{
							showConfirmValidation ? (
								<PopUpForm onClick={() => setShowConfirmValidation(false)}
								           large={false}
								           form={<FormValidatePri
									           id={idStudyFolder}
									           setShowConfirmValidation={setShowConfirmValidation}
									           //collaborator={collaborator}
									           setSubmitForm={setSubmitForm}
								           />}
								           show={showConfirmValidation}
								/>
							) : null
						}
					</>
				) : 
				/* priProject.priValidation && priProject.stageStatus === "processing" ? (
					<>
						<div className="section-study-folder-pri-container">
							<p className={"text-primary color-primary"}>
								Le client est en train de prendre connaissance du principe de structure (PRI). Vous recevrez une
								notification par e-mail lorsqu'il aura fait son choix. Vous pourrez alors mettre à jour le
								principe de structure ou passer à l'étape de rédaction du dossier d'étude structure.
							</p>
						</div>

						<PopUpInformationsPri
							show={showInformation}
							onClick={() => setShowInformation(false)}
							large={false}
							title={"Informations projet"}
							content={priProject}
						/>
					</>
				) 
				:  */
				priProject.priValidation && priProject.stageStatus === "processing" ? (
					<>
						<div className="section-study-folder-pri-container">
							<p className={"text-primary color-primary paragraph_bold"}>
							Le client est en train de prendre connaissance du principe de structure (PRI). Vous recevrez une
								notification par e-mail lorsqu'il aura fait son choix. Vous pourrez alors mettre à jour le
								principe de structure ou passer à l'étape de rédaction du dossier d'étude structure.
							</p>
							<p className={"text-primary color-primary"}>
								Voulez-vous soumettre un nouveau principe de structure au client ?
							</p>
							<div className="section-study-folder-pri-container-buttons">
								<Button
									styleButton={"btn-default btn-border btn-border-blue_jeans"}
									isActive={true}
									onClick={() => downloadDocument()}
									title={"Télécharger le PRI"}
								/>
								<Button
									styleButton={"btn-default btn-default-secondary plain"}
									isActive={true}
									onClick={() => setShowPopUpPriRefus(true)}
									title={"Oui"}
								/>
							</div>
						</div>
						<PopUpInformationsPri
							show={showInformation}
							onClick={() => setShowInformation(false)}
							large={false}
							title={"Informations projet"}
							content={priProject}
						/>
						{
							showSkipValidation ? (
								<PopUpSkipValidationPriClient
									onClickClose={() => setShowSkipValidation(false)}
									setSubmitForm={setSubmitForm}
									projectId={idStudyFolder}
								/>
							) : null
						}
						{
							showPopUpPriRefus ? (
								<PopUpForm onClick={() => setShowPopUpPriRefus(false)}
								           large={false}
								           form={<FormValidatePriEdit
									           idProject={idStudyFolder}
									           setShowPopUpPriRefus={setShowPopUpPriRefus}
									           priProject={priProject}
									           setSubmitForm={setSubmitForm}
								           />}
								           show={showPopUpPriRefus}
								/>
							) : null
						}
					</>
				): priProject.priValidation && priProject.stageStatus === "problem" ? (
					<>
						<div className="section-study-folder-pri-container">
							<p className={"text-primary color-primary paragraph_bold"}>
								Le client a refusé le Principe de Structure (PRI)
							</p>
							<p className={"text-primary color-primary"}>
								Voulez-vous soumettre un nouveau principe de structure au client ?
							</p>
							<div className="section-study-folder-pri-container-buttons">
								<Button
									styleButton={"btn-default btn-border btn-border-blue_jeans"}
									isActive={true}
									onClick={() => setShowSkipValidation(true)}
									title={"Non"}
								/>
								<Button
									styleButton={"btn-default btn-default-secondary plain"}
									isActive={true}
									onClick={() => setShowPopUpPriRefus(true)}
									title={"Oui"}
								/>
							</div>
						</div>
						<PopUpInformationsPri
							show={showInformation}
							onClick={() => setShowInformation(false)}
							large={false}
							title={"Informations projet"}
							content={priProject}
						/>
						{
							showSkipValidation ? (
								<PopUpSkipValidationPriClient
									onClickClose={() => setShowSkipValidation(false)}
									setSubmitForm={setSubmitForm}
									projectId={idStudyFolder}
								/>
							) : null
						}
						{
							showPopUpPriRefus ? (
								<PopUpForm onClick={() => setShowPopUpPriRefus(false)}
								           large={false}
								           form={<FormValidatePriRefusClient
									           idProject={idStudyFolder}
									           setShowPopUpPriRefus={setShowPopUpPriRefus}
									           priProject={priProject}
									           setSubmitForm={setSubmitForm}
								           />}
								           show={showPopUpPriRefus}
								/>
							) : null
						}
					</>
				) : null
			}

			{

			}
		</div>

	);
};

export default StudyFolderPRI;
