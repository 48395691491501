import React, {useEffect, useState} from 'react';
import CollaboratorService from "../../../../../../services/CollaboratorService";
import {parseInt} from "lodash";

const CollaboratorDetailTable = (collaborator) => {
	const collaboratorData = collaborator.collaborator
	const currentYear = new Date().getFullYear();
	const initialObjectiveByMonth = [
		{month: 1, value: 0},
		{month: 2, value: 0},
		{month: 3, value: 0},
		{month: 4, value: 0},
		{month: 5, value: 0},
		{month: 6, value: 0},
		{month: 7, value: 0},
		{month: 8, value: 0},
		{month: 9, value: 0},
		{month: 10, value: 0},
		{month: 11, value: 0},
		{month: 12, value: 0},
	];
	const [objectiveByMonth, setObjectiveByMonth] = useState(initialObjectiveByMonth);

	const resetData = () => {
		setObjectiveByMonth(initialObjectiveByMonth);
	}

	useEffect(() => {
		CollaboratorService.getCollaboratorObjective(`users/${collaboratorData.id}/objectives`)
			.then((response) => {
				resetData()
				if (response.data.monthlyObjectives.length > 0) {
					setObjectiveByMonth(initialObjectiveByMonth);
					response.data.monthlyObjectives
						.filter((monthlyObjective) => monthlyObjective.year === currentYear.toString())
						.map((monthlyObjective) => {
							const updatedObjectiveByMonth = [...objectiveByMonth];
							updatedObjectiveByMonth.forEach((obj, index) => {
								if (obj.month === parseInt(monthlyObjective.month)) {
									updatedObjectiveByMonth[index].value = parseFloat(monthlyObjective.total);
								}
							});
							setObjectiveByMonth(updatedObjectiveByMonth);
						});
				}
			});
	}, [collaborator]);



	return (
		<div className='collaborators-detailTable'>
			<table className='customTable collaborators-detailTable-container'>
				<thead>
				<tr>
					<th className='customTable-cellMaxWidth'>
						<img className='customTable-profilPicture'
								 src={`${process.env.REACT_APP_PROD_URL}${collaboratorData.urlAvatar}`} alt="pp default"/>
					</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Reference</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Nom</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Prénom</th>
					<th className='customTable-cellMaxWidth text-tertiary'>Niveau</th>
					<th className='customTable-cellMaxWidth text-tertiary'>Taux horaire</th>
					<th className='customTable-cellMaxWidth text-tertiary'>Base horaire</th>
					<th className='customTable-cellMaxWidth text-tertiary'>Forfait</th>
					<th className='customTable-cellMaxWidth text-tertiary'>Obj Prev. An.</th>
					<th className='customTable-cellMaxWidth text-tertiary'>Présence</th>
					<th className='customTable-cellMaxWidth text-tertiary'>Obj Prev. Mens.</th>
					<th></th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td></td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary tableText-noWrap'>{collaboratorData.reference}</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>{collaboratorData.lastname}</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>{collaboratorData.firstname}</td>
					<td className='text-tertiary'>{collaboratorData.wl}</td>
					<td className='text-tertiary'>{collaboratorData.rateH ? collaboratorData.rateH : "N/A"}</td>
					<td className='text-tertiary'>{collaboratorData.baseH ? collaboratorData.baseH : "N/A"}</td>
					<td className='text-tertiary'>{collaboratorData.package}</td>
					<td className='text-tertiary'>{collaboratorData.objective * 12}</td>
					<td className='text-tertiary'>{collaboratorData.presence}</td>
					<td className='text-tertiary'>{(collaboratorData.objective).toFixed(2)}</td>
					<td></td>
				</tr>
				</tbody>
			</table>

			<table className='customTable collaborators-detailTable-container collaborators-detailTable-container-2'>
				<thead>
				<tr>
					<th className='customTable-cellMaxWidth'></th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Janvier
					</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Février</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Mars</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Avril</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Mai</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Juin</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Juillet
					</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Août</th>
					<th
						className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Septembre
					</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Octobre</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Novembre
					</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Décembre</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td></td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
					<td
						className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>{collaboratorData.objective.toFixed(2)} €
					</td>
				</tr>
				</tbody>
			</table>

			<p className='text-tertiary collaborators-detailTable-tableText'>Objectif chiffre d’affaires mensuel (HT) : {collaboratorData.lastname + " " + collaboratorData.firstname} </p>

			<table className='customTable collaborators-detailTable-container collaborators-detailTable-container-3'>
				<thead>
				<tr>
					<th className='customTable-cellMaxWidth'></th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Janvier
					</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Février</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Mars</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Avril</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Mai</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Juin</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Juillet
					</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Août</th>
					<th
						className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Septembre
					</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Octobre</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth customTable-cellBg text-tertiary'>Novembre
					</th>
					<th className='customTable-cellAlignLeft customTable-cellMaxWidth text-tertiary'>Décembre</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td></td>
					{
						objectiveByMonth.map((oneObjective, index) => {
							return (
								<td key={index}
										className={`customTable-cellAlignLeft customTable-cellMaxWidth ${(index + 1) % 2 ? "customTable-cellBg" : ""} text-tertiary`}>
									{oneObjective.value.toFixed(2)} €
									<span className={(oneObjective.value - (collaboratorData.objective)) >= 0 ? 'color-green' : "color-red"}>
										{
											(oneObjective.value - (collaboratorData.objective)) >= 0 ? "+" : ""
										}
										{(oneObjective.value.toFixed(2) - (collaboratorData.objective)).toFixed(2)} €
									</span>
								</td>
							)
						})
					}
				</tr>
				</tbody>
			</table>


		</div>
	);
};

export default CollaboratorDetailTable;