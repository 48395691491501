import React, {useEffect, useState} from 'react';
import {Form, Formik} from "formik";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import userService from "../../../../services/UserService";
import {initialValuesIngCollaborator} from "../../../../config/InitialValues/Engineer/initialValuesIngCollaborator";
import {ingCollaboratorSchema} from "../../../../schemas/Engineer/ingCollaboratorSchema";
import Button from "../../../Atomes/Buttons/Button";
import PopUpDeleteProjectCollaborator from "../../PopUp/PopUpDeleteProjectCollaborator";
import DirRespService from "../../../../services/Ing/DirResp/DirRespService";
import InputLabelTagIngErrorMessage from "../../../Atomes/Formik/InputLabelTagIngErrorMessage";
import api from "../../../../services/APIService";
import {toast} from "react-toastify";
import Skeleton from "react-loading-skeleton";

const FormIngCollaborator = ({
	                             collaborator,
	                             allProjects,
	                             allRoles,
	                             setShowPopUpAssignProject,
	                             selectedProgressItem,
	                             updateData
                             }) => {
	const {addUser} = userService

	const [showPopUpDeleteProject, setShowPopUpDeleteProject] = useState(false);

	const [collaboratorData, setCollaboratorData] = useState(collaborator)
	const [projectData, setProjectData] = useState(collaborator)
	const [tags, setTags] = useState([])
	const [tagsUpdate, setTagsUpdate] = useState([])
	const [loading, setLoading] = useState(true)
	const [selectedProject, setSelectedProject] = useState("")

	const [currentProject, setCurrentProject] = useState([])


	const deleteProjectProgress = async () => {
		const request = await DirRespService.unassignProject(selectedProject)

		if (request.request.status === 204) {
			toast.success("Le projet a bien été supprimé", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 3000,
			})
			setShowPopUpDeleteProject(false)
			updateData()
		} else {
			toast.error("Une erreur est survenue", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 3000,
			})
		}
	}

	const allCompetences = [
		{"name": "Autocad"},
		{"name": "Autocad LT"},
		{"name": "Robot Structural Analysis"},
		{"name": "Graitec Arche Hybride"},
		{"name": "SCIA Engineer"},
		{"name": "Béton"},
		{"name": "Bois"},
		{"name": "Mixte"},
		{"name": "Charpentes métalliques"},
		{"name": "Métal"},
		{"name": "Sika CarboDur"},
		{"name": "Débutant"},
		{"name": "Junior"},
		{"name": "Confirmé"},
		{"name": "Senior"},
		{"name": "Béton précontraint"},
		{"name": "Diagnostic pathologie du bâtiment"},
		{"name": "Ingénieur diagnostiqueur"}
	]

	const deleteCompetenceTag = (title) => {
		let competencesTagUpdate = tags.filter(competenceTag => competenceTag.title !== title)
		setTags(competencesTagUpdate)
	}

	const onSubmit = async (values, actions) => {
		values.tags = tags
		if (tagsUpdate !== tags) {
			const updatedData = {
				tags: values.tags.map(tag => {
					const {color, ...rest} = tag; // Utilisation de la déstructuration pour extraire la propriété "color"
					return rest; // Retourner l'objet sans la propriété "color"
				})
			};

			const request = await api.patch(`ings/${collaborator.id}`, {
				tags: updatedData.tags,
				roles: [collaborator.roles[0]]
			})

			if (request.request.status === 200) {
				updateData()
				actions.resetForm();
				toast.success("Les compétences ont bien été modifiées", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
				})

			} else {
				toast.error("Une erreur est survenue", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
				})
			}
		}
	}

	const getUserProject = async () => {
		const request = await DirRespService.getCurrentProject(collaborator.id)

		if (request.request.status === 200) {
			setCurrentProject(request.data["hydra:member"])
			setTags(collaborator.tags)
			setTagsUpdate(collaborator.tags)
			setLoading(false)
		}
	}

	useEffect(() => {
		setSelectedProject("")
		setLoading(true)
		setTags([])
		getUserProject()
	}, [setProjectData, updateData]);

	const updateCompetenceTag = (e) => {
		setTags([...tags, {title: e, color: "red"}])
	}

	return (
		<>
			{
				loading ? <Skeleton height={500}/> : (
					<>
						<Formik
							initialValues={initialValuesIngCollaborator}
							validationSchema={ingCollaboratorSchema}
							onSubmit={onSubmit}
						>

							{({isSubmitting, isValid}) => (
								<Form className={"pop-up-form-container-collaborator"}>
									<div className={"card-collaborator-title"}>
										<img src={`${process.env.REACT_APP_PROD_URL}/${collaborator.urlAvatar}`}/>
										<div className={"card-collaborator-title-text"}>
											<h4 className={"color-primary"}>{collaboratorData.niceNames}</h4>
											<p className={"text-primary color-primary"}>{collaboratorData.job}</p>
										</div>
									</div>
									<div className={"card-collaborator-contact"}>
										<div className={"card-collaborator-contact-email"}>
											<img src={"/images/pictos/letter.svg"} alt={"email img"}/>
											<a href={`mailto:${collaboratorData.email}`} style={{textDecoration:"none"}} className={"text-micro paragraph_bold color-primary"}>{collaboratorData.email}</a>
										</div>
										<div className={"card-collaborator-contact-phone"}>
											<img src={"/images/pictos/phone.svg"} alt={"phone img"}/>
											<a href={`tel:${collaboratorData.phone}`} style={{textDecoration:"none"}} className={"text-micro paragraph_bold color-primary"}>{collaboratorData.phone}</a>
										</div>
									</div>
									<div className="card-collaborator-project card-collaborator-project-progress">
										<p className={"text-micro paragraph_bold color-dark-grey marginbottom-8"}>Projets en cours</p>
										<div className="card-collaborator-project-list">
											{currentProject.length > 0 ?
												currentProject.map((progressItem, progressIndex) => {
													return (
														<div key={progressIndex}
														     className="card-collaborator-project-list-item"
														     onClick={() => {
															     selectedProgressItem(progressItem);
															     //setSelectedProgressItem,
														     }}
														>
															<p className="text-primary color-dark-grey">
																{`#${progressItem.project.client.reference} - ${progressItem.project.address.street}, ${progressItem.project.address.nicePostcode}, ${progressItem.project.address.city}  - ${progressItem.title}`}
															</p>
															<div className="form-row-label-input-tag-item-cross"
															     onClick={(e) => {
																     e.stopPropagation();
																     setShowPopUpDeleteProject(true);
																     selectedProgressItem(progressItem);
																     setSelectedProject(progressItem.id)
															     }}>
																<img src="/images/pictos/cross-background-red.svg" alt=""/>
															</div>
														</div>
													)
												}) : <p className="text-primary color-primary">Aucun projet en cours</p>}
											<Button
												title={"Attribuer un projet"}
												isActive={true}
												styleButton={"btn-default btn-default-mini btn-default-secondary"}
												onClick={setShowPopUpAssignProject}
											/>
										</div>
									</div>
									{/*<div className="card-collaborator-project card-collaborator-project-attente">
										<p className={"text-micro paragraph_bold color-dark-grey marginbottom-8"}>Projets en attente</p>
										<div className="card-collaborator-project-list">
										</div>
									</div>*/}
									<div className={"form-row-element"}>
										<InputLabelTagIngErrorMessage
											type={"select"}
											name={"tags"}
											label={"Compétences techniques"}
											placeholder={"Ajouter une compétence"}
											keyLabel={"title"}
											keyValue={"title"}
											keyType={"color"}
											dataTag={tags}
											data={allCompetences}
											onClick={(title) => deleteCompetenceTag(title)}
											onChange={(e) => updateCompetenceTag(e.target.value)}
										/>
									</div>
									<ButtonSubmit title={"Attribuer"}
									              isActive={false}
									              styleButton={"btn-default btn-default-secondary plain"}
									/>
								</Form>)}
						</Formik>
						{
							showPopUpDeleteProject ? (
								<PopUpDeleteProjectCollaborator
									onClickClose={() => setShowPopUpDeleteProject(false)}
									onClickDelete={() => deleteProjectProgress()}
								/>
							) : null
						}
					</>
				)
			}

		</>
	);
};

export default FormIngCollaborator;
