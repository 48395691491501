import * as Yup from 'yup';

export const IngTSR2Schema = Yup.object().shape({
	g: Yup.number()
		.required('Veillez entrer une valeur'),
	q: Yup.number()
		.required('Veillez entrer une valeur'),
	wallThicknessToBeOpened: Yup.number()
		.required('Veillez entrer l\'épaisseur des murs à ouvrir'),
	ClearBayWidth: Yup.number()
		.required('Veillez entrer la largeur de baie libre'),
	clearanceHeight: Yup.number()
		.required('Veillez entrer la hauteur de baie libre'),
	floorLoadStrip: Yup.number()
		.required('Veillez entrer la bande de charge de plancher'),
	methodUsed: Yup.string()
		.required('Veillez entrer la méthode utilisée'),
	supportLengthsLeft: Yup.number()
		.required('Veillez entrer la longueur d\'appui gauche'),
	supportLengthsRight: Yup.number()
		.required('Veillez entrer la longueur d\'appui droite'),
	counterPosts: Yup.string()
		.required('Veillez entrer le contre poteaux'),
	lintel: Yup.string()
		.required('Veillez entrer le linteau'),
	numberOfBackToBackLintels: Yup.number()
		.required('Veillez entrer le nombre de linteaux dos à dos'),
	QtyTF: Yup.number()
		.required('Veillez entrer la quantité de TF'),
	lintelInstallation: Yup.string()
		.required('Veillez entrer le mode de pose linteau'),
	bedBases: Yup.string()
		.required('Veillez entrer le sommier'),
	stools: Yup.string()
		.required('Veillez entrer le tabouret'),
	stoolsQty: Yup.string()
		.required('Veillez entrer la quantité de tabouret'),
	concreteOrSteelBeamClass: Yup.string()
		.required('Veillez entrer la classe de poutre béton ou acier'),
	concreteOrSteelPostClass: Yup.string()
		.required('Veillez entrer la classe de poteau béton ou acier'),
	HABeamUpperAxisSteels: Yup.string()
		.required('Veillez entrer les aciers supérieurs de poutre HA'),
	HABeamLowerAxisSteels: Yup.string()
		.required('Veillez entrer les aciers inférieurs de poutre HA'),
	HAPostSteels: Yup.string()
		.required('Veillez entrer les aciers de poteau HA'),
	counterPostsMade: Yup.string()
		.required('Veillez entrer les contre poteaux'),
	counterPostsLength: Yup.string()
		.required('Veillez entrer la longueur des contre poteaux'),
	beamPostAssBoltsQty: Yup.string()
		.required('Veillez entrer la quantité de boulons d\'assemblage poutre poteau'),
	beamPostAssBoltsDiameter: Yup.string()
		.required('Veillez entrer le diamètre de boulons d\'assemblage poutre poteau'),
	beamPostAssBoltsCLass: Yup.string()
		.required('Veillez entrer la classe de boulons d\'assemblage poutre poteau'),
	beamSplicingPosition: Yup.string()
		.required('Veillez entrer l\'eclissage de poutre'),
	beamSplicingQty: Yup.string()
		.required('Veillez entrer la quantité d\'eclissage de poutre'),
	beamSplicingDiameter: Yup.string()
		.required('Veillez entrer le diamètre d\'eclissage de poutre'),
	beamSplicingClass: Yup.string()
		.required('Veillez entrer la classe d\'eclissage de poutre'),
	typicalSplicingPosition: Yup.string()
		.required('Veillez entrer l\'eclissage type'),
	postFootPlate: Yup.string()
		.required('Veillez entrer le plat pied de poteau'),
	beamWeldingBeads: Yup.string()
		.required('Veillez entrer les cordons de soudure sur poutre'),
	postWeldingBeads: Yup.string()
		.required('Veillez entrer les cordons de soudure sur poteau'),
	fireStabilityRequired: Yup.string()
		.required('Veillez entrer la stabilité au feu demandée'),
});
