import React from 'react';
import CardProject from '../../../../../components/Molecules/Cards/Project/CardProject';

const ProjectsInProgress = ({ projects }) => {
	return (
		<div className={"projects-in-progress"}>
			<h3 className={"color-primary"}>Projets en cours</h3>
			<div className={"section-home-architect__wrapper"}>
				{projects.map((project, index) => {
					return (
						<CardProject
							key={index}
							id={project.id}
							project={project}
						/>
					)
				})}
			</div>
		</div>
	);
};

export default ProjectsInProgress;
