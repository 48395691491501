import React from 'react';

const CardPartnerMarketplace = (
	{
		partner,
		index,
		openPopup,
	}
) => {

	return (
		<div className='customer-marketplace__category__items__item' key={index}>
			<img className='customer-marketplace__category__items__item_img'
				 src={`${process.env.REACT_APP_PROD_URL}/${partner.imageUrl}`} alt={partner.title}/>
			<div className='customer-marketplace__category__items__item_content'>
				<img src={`${process.env.REACT_APP_PROD_URL}/${partner.logoUrl}`} alt={partner.title}/>
				<h4>{partner.title}</h4>

			</div>
			<p className={"text-primary"}>{partner.description}</p>

			<a className="btn-default btn-default-primary margintop-15" style={{display: "flex", margin: "15px auto" }} onClick={() => openPopup(partner)}>Être
				contacté</a>
		</div>
	)
}

export default CardPartnerMarketplace;
