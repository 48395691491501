import React from "react";
import ButtonWithOnClickAction from "../../Atomes/Buttons/ButtonWithOnClickAction";

const TitleSectionWithButton = ({title, buttonTitle, styledButton, isActive, onClick, className}) => {
	return (
		<div className={`top-title-button ${className}`}>
			<h3 className={"color-primary"}>{title}</h3>
			<ButtonWithOnClickAction onClick={onClick} title={buttonTitle} isActive={isActive} styleButton={styledButton}/>
		</div>

	)
}
export default TitleSectionWithButton;
