import React from 'react';

const InputSearchChat = ({onChangeSearch, placeholder, name, onChangeSearchValue}) => {
	return (
		<div className={"input-search-chat"}>
			<input onChange={onChangeSearchValue} onKeyDown={onChangeSearch} placeholder={placeholder} name={name}
			       type={"text"}/>
		</div>
	);
};

export default InputSearchChat;
