import React from "react";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import CardCircuitDeValidationStatus from "./CardCircuitDeValidationStatus";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import exportFileService from "../../../../services/ExportFileService";

export function CardCircuitDeValidationIng({
	id,
	title,
	styleButton,
	niceNames,
	img,
	numberFolder,
	status,
	color,
	niceStudyStatus
}) {

	const downloadFile = () => {
		exportFileService.exportFile(`/projects/${id}`, `dossier-projet-${id}`, {}, "zip")
		toast.success("Le dossier est en cours de téléchargement")
	}

	return (
		<>
			<div className={"card-circuit-de-validation"}>
				<div className={"card-circuit-de-validation-img"}>
					<img src={`${process.env.REACT_APP_PROD_URL}${img}`} alt={niceNames ? niceNames : ""} />
				</div>
				<div className={"card-circuit-de-validation-name"}>
					<h4>{niceNames}</h4>
				</div>
				<div className={"card-circuit-de-validation-dossier"}>
					<p className="text-primary">{numberFolder}</p>
					<CardCircuitDeValidationStatus title={status} color={color} />
				</div>
				<ButtonSubmit title={niceStudyStatus} styleButton={`btn-default ${styleButton}`} />
				<Link onClick={downloadFile}>Télécharger le dossier</Link>
			</div>
		</>
	)
}

export default CardCircuitDeValidationIng;
