import * as Yup from 'yup';

const fileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/pdf'];

export const IngPriRefusValidateDocSchema = Yup.object().shape({
	file: Yup.mixed().required('Veuillez renseigner un document pour le client')
		.test('fileFormat', 'Le fichier doit être un PNG, JPEG, JPG ou PDF', (value) =>
			value ? fileTypes.includes(value.type) || (value.name.endsWith('.png') || value.name.endsWith('.jpeg') || value.name.endsWith('.jpg') || value.name.endsWith('.pdf')) : true
		).test("fileSize", "Le fichier est trop volumineux <= 250ko", (value) =>
			value ? value.size ? value.size <= 250000 : true : true
		),
})