import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";

import FilterTwoSelects from '../../../../../components/Molecules/Filter/FilterTwoSelects';
import MbeCalendar from '../../../../../components/Molecules/Calendar/MbeCalendar';

import { ConvertObjectToUrlParams } from "../../../../../utils/StringUtils";
import { ConvertVisitsToFullCalendarEvents } from '../../../../../utils/TaskUtils';

import CollaboratorService from '../../../../../services/CollaboratorService';
import TaskService from '../../../../../services/TaskService';
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

const AdminCalendarView = () => {


  // "Regions" filter settings
  const firstSelectDefaultValue = "Toutes les agences";
  const firstSelectValue = dataRegionsArray;
  const firstSelectOptions = dataRegionsArray;
  const [region, setRegion] = useState("");
  const filterByRegion = (event) => {
    if (event.target.value === "Toutes les agences") {
      setRegion("")
    } else {
      setRegion(event.target.value)
    }
  }

  // "ObjectifsViewIngRes" filter settings
  const secondSelectDefaultValue = "Tous les collaborateurs";
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorFilter, setCollaboratorFilter] = useState("");
  const filterByCollaborator = (event) => {
    if (event.target.value === "Tous les collaborateurs") {
      setCollaboratorFilter("")
    } else {
      setCollaboratorFilter(event.target.value)
    }
  }
  const getCollaborators = async () => {
    const urlIngDiag = ConvertObjectToUrlParams("ings", { regions: region })
    const request = await CollaboratorService.getCollaborators(urlIngDiag)
    if (request.request.status === 200) {
      let ings = request.data['hydra:member']
      let availableIngs = []
      ings.forEach(ing => {
        if (ing.roles[0] === "ROLE_ING_DIAG") {
          availableIngs.push(ing)
        }
      })
      setCollaborators(availableIngs)
    } else {
      toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
    }
  }

  // Tasks
  const [tasks, setTasks] = useState([]);
  const getTasks = async () => {
    const request = await TaskService.getTasksUser("visits", { 'pagination': 'false', 'user.id': collaboratorFilter, 'region': region })
    if (request.request.status === 200) {
      let tasks = request.data['hydra:member'];
      tasks = ConvertVisitsToFullCalendarEvents(tasks)
      setTasks(tasks)
    } else {
      toast.error("Une erreur est survenue lors de la récupération des tâches")
    }
  };

  // "PopUp edit" settings
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [editTask, setEditTask] = useState([]);
  const DisplayEditModalTask = (event) => {
    setShowPopupEdit(!showPopupEdit)
    if (!showPopupEdit) {
      setEditTask(event)
    }
  }

  useEffect(() => {
    getCollaborators();
    getTasks();

  }, [collaboratorFilter, region, showPopupEdit]);


  return (
    <>

      <FilterTwoSelects
        defaultValue={firstSelectDefaultValue}
        firstValue={firstSelectValue}
        options={firstSelectOptions}
        firstOnChange={filterByRegion}
        secondDefaultValue={secondSelectDefaultValue}
        secondValue={collaborators.map(collaborator => collaborator.id)}
        secondOptions={collaborators.map(collaborator => collaborator.niceNames)}
        secondOnChange={filterByCollaborator}
      />

      <MbeCalendar
        tasks={tasks}
        taskManager={false}
        withNotifications={false}
      />

      {/* <PopUpForm
        large={true}
        show={showPopupEdit}
        title={showPopupEdit ? "Gestion de la tâche " + editTask.event.title : "Gestion de la tâche"}
        onClick={DisplayEditModalTask}
        form={<FormDeleteTask setShowPopUp={setShowPopupEdit} editTask={editTask} />}
      /> */}
    </>
  );
}

export default AdminCalendarView;
