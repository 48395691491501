import React, { useContext} from 'react';
import { StateRequest } from "../../../../views/Pages/Admin/Users/UsersAdmin";
import { Form, Formik } from 'formik';
import { initialValuesEditMdp } from '../../../../config/InitialValues/InitialValuesEditMdp';
import { editMdpUsersSchema } from '../../../../schemas/Admin/Users/EditMdpUsersSchema';
import InputLabelErrorMessage from '../../../Atomes/Formik/InputLabelErrorMessage';
import ButtonSubmit from '../../../Atomes/Buttons/ButtonSubmit';
import UserService from '../../../../services/UserService';

const FormEditPassword = ({ id, setIsActive, name }) => {

    const { setStateRequest } = useContext(StateRequest);

    /* Edit user password */
    const onSubmit = async (values) => {
        const request = await UserService.editPassword(id, {
            newPassword: values.newPassword
        })
        if(request.request.status === 200) {
            setIsActive(false)
            setStateRequest("success mdp edit")
        } else {
            setStateRequest("error")
        }
    }

    return (
        <>
            <Formik
                initialValues={initialValuesEditMdp}
                validationSchema={editMdpUsersSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }) => {
                    return (
                        <Form>
                            <p className={"text-primary"} style={{marginBottom: 20 + "px"}}>Ajouter le nouveau mot de passe pour {name}</p>
                            <div className={"form-row-element"}>
                                <InputLabelErrorMessage type={"password"} label={"Nouveau mot de passe"} name={"newPassword"} />
                            </div>
                            <div className={"form-row-element"}>
                                <InputLabelErrorMessage type={"password"} label={"Confirmation nouveau mot de passe"} name={"newPasswordConfirmation"}/>
                            </div>
                            <ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Modifier"} />
                        </Form>
                    )
                }}

            </Formik>
        </>
    )
};

export default FormEditPassword;