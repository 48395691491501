import React, {useState} from "react";
import PopUpDeletePartnerMarketPlace from "../../../components/Molecules/PopUp/PopUpDeletePartnerMarketPlace";
import PopUpForm from "../../../components/Molecules/PopUp/PopUpForm";
import FormEditPartnerMarketPlace from "../../../components/Molecules/Form/Admin/FormEditPartnerMarketPlace";

export const PartnersMarketPlace = [
	{
		Header: "Partenaire",
		accessor: "title",
	},
	{
		Header: "Logo",
		accessor: "logoUrl",
		Cell: cellInfo => {
			if (cellInfo.row.original.logoUrl !== null) {
				return (
					<img className={"partner-image"} src={`${process.env.REACT_APP_PROD_URL}${cellInfo.row.original.logoUrl}`}
					     alt={"Logo"}/>
				)
			}
		}
	},
	{
		Header: "Illustration",
		accessor: "imageUrl",
		Cell: cellInfo => {
			if (cellInfo.row.original.imageUrl !== null) {
				return (
					<img className={"partner-image"} src={`${process.env.REACT_APP_PROD_URL}${cellInfo.row.original.imageUrl}`}
					     alt={"Illustration"}/>
				)
			}
		}
	},
	{
		Header: "Description",
		accessor: "description",
	},
	{
		Header: "Catégorie",
		accessor: "niceCategory",
	},
	{
		Header: "Adresse e-mail",
		accessor: "email",
	},
	{
		Header: "Transmission Lead",
		accessor: "transmission",
		Cell: cellInfo => {
			let result = ""
			if (cellInfo.row.original.transmission === "partner") {
				result = "Partenaire"
			} else {
				result = "Commerciaux mon bureau d'études"
			}
			return (
				<div className={"market-place-transmission"}>
					{result}
				</div>
			)
		}
	},
	{
		Header: "Actif",
		accessor: "active",
		Cell: cellInfo => {
			return (
				<div className={"statut"}>
					{cellInfo.row.original.active === false ? <div className={"red-info"}>non</div> :
						<div className={"green-info"}>oui</div>}
				</div>
			)
		}
	},
	{
		Header: "Actions",
		accessor: "",
		Cell: cellInfo => {
			const [edit, setEdit] = useState(false);
			const [deletePartner, setDeletePartner] = useState(false);
			return (
				<div className={"actions-controller"}>
					<img onClick={() => setEdit(true)} src={"/images/pictos/pencil-blue.svg"}/>
					<img onClick={() => setDeletePartner(true)} src={"/images/pictos/red-cross.svg"}/>

					{
						edit ? (
							<PopUpForm show={edit} onClick={() => setEdit(false)} title={"Modifier le partenaire"} large={true}
							           form={<FormEditPartnerMarketPlace id={cellInfo.row.original.id} setShowPopUp={setEdit}/>}/>
						) : null
					}

					{
						deletePartner ? (
							<PopUpDeletePartnerMarketPlace name={cellInfo.row.original.title} id={cellInfo.row.original.id}
							                               onClick={() => setDeletePartner(false)}/>
						) : null
					}
				</div>
			)
		}
	},
]
