import React, {useContext, useEffect, useState} from "react";
import ApprovalCircuitService from "../../../services/ApprovalCircuitService";
import {StateRequest} from "../../../views/Pages/Admin/Home/Home";
import FormAssetGenerator from "../Form/FormAssetGenerator";
import PopUpForm from "./PopUpForm";
import {useNavigate} from "react-router-dom";
import moment from "moment";

const PopUpAdminHome = ({id, stage, onClick, item}) => {

	const navigate = useNavigate();

	const [itemStage, setItemStage] = useState(null)

	const {setStateRequest} = useContext(StateRequest);
	const [showPopUp, setShowPopUp] = useState(false);

	const edith4 = () => {
		if (stage === "ADMIN_validate-visit") {

			const date = moment(item.visit.startAt).format('LL');
			const startAt = moment(item.visit.startAt).format('LT');
			const endAt = moment(item.visit.endAt).format('LT');
			setItemStage(<h4>Voulez-vous débloquer le créneau du {date} entre {startAt} et {endAt} pour le
				projet {item.reference} de {item.client.niceGender} {item.client.niceNames} ?</h4>)
		} else if (stage === "ADMIN_payment-validation") {
			setItemStage(<h4>Voulez-vous valider le paiement par virement bancaire
				<br/>de {item.client.niceGender} {item.client.niceNames} pour le projet {item.reference} ?</h4>)
		} else if (stage === "ADMIN_payment-follow-up") {
			setItemStage(<h4>Voulez-vous valider<br/>la relance de paiement ?</h4>)
		} else if (stage === "ADMIN_refund") {
			setItemStage(<h4>Voulez-vous générer un avoir pour le
				projet {item.reference} de {item.client.niceGender} {item.client.niceNames} ?</h4>)
		}
	}

	useEffect(() => {
		edith4()
	}, [id])

	const handleSubmit = async (event) => {
		if (stage === "ADMIN_validate-visit") {

			if (event.target.innerText === "Oui") {
				const request = await ApprovalCircuitService.validateVisit(item.visit.id, true)
				onClick()
				if (request.request.status === 200) {
					setStateRequest('success add visit')
				} else {
					setStateRequest('error')
				}
			} else {
				const request = await ApprovalCircuitService.validateVisit(item.visit.id, false)
				onClick()
				if (request.request.status === 200) {
					setStateRequest('success add visit error')
				} else {
					setStateRequest('error')
				}
			}


		} else if (stage === "ADMIN_payment-validation") {
			if (event.target.innerText === "Oui") {
				const request = await ApprovalCircuitService.validatePayment(item.validatePaymentId, "paid")
				onClick()
				if (request.request.status === 200) {
					setStateRequest('success add payment')
				} else {
					setStateRequest('error')
				}
			} else {
				const request = await ApprovalCircuitService.validatePayment(item.validatePaymentId, "error")
				onClick()
				if (request.request.status === 200) {
					setStateRequest('success add payment error')
				} else {
					setStateRequest('error')
				}
			}
		} else if (stage === "ADMIN_payment-follow-up") {
			if (event.target.innerText === "Oui") {
				const request = await ApprovalCircuitService.validatePaymentReminder(id, "valid")
				onClick()
				if (request.request.status === 200) {
					setStateRequest('success add payment reminder')
				} else {
					setStateRequest('error')
				}
			} else {
				onClick()
			}
		} else if (stage === "ADMIN_refund") {
			if (event.target.innerText === "Oui") {
				navigate(`facturation`)
			} else {
				const request = await ApprovalCircuitService.patchAsset(id)
				if (request.request.status === 200) {
					setStateRequest('success refuse asset')
				} else {
					setStateRequest('error')
				}
			}
		}
	}

	return (
		<>
			<div className={"pop-up-base-style active"}>
				<div className={"pop-up-user-delete"}>
					<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"}/>
					<img src={"/images/medias/popup.svg"} alt="img form"/>
					{itemStage}
					<div className={"pop-up-user-delete-buttons"}>
						<button className={"btn btn-cancel"} onClick={(event) => handleSubmit(event)}>Non</button>
						<button className={"btn btn-delete"} onClick={(event) => handleSubmit(event)}>Oui</button>
					</div>
				</div>
			</div>
			{
				showPopUp ? (
					<PopUpForm large={true} onClick={onClick} show={showPopUp} title={"Génération d'avoir"}
					           form={<FormAssetGenerator row={item} setShow={setShowPopUp}/>}/>
				) : null
			}
		</>
	);
}

export default PopUpAdminHome
