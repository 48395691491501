import React, {useEffect, useState} from 'react';
import Skeleton from "react-loading-skeleton";
import ButtonWithOnClickAction from "../../../../../../components/Atomes/Buttons/ButtonWithOnClickAction";

const DevisIntroChatBot = ({request, isLoading}) => {

	const [moreInformationIsActvie, setMoreInformationIsActvie] = useState(false);
	const [chatBotResponse, setChatBotResponse] = useState([]);

	useEffect(() => {
		if (isLoading === false) {
			setChatBotResponse(request.chatbot.answers)
		}
	}, [request]);


	const titleButton = () => {
		if (moreInformationIsActvie) {
			return "Voir moins"
		} else return "Voir plus"
	}

	return (
		<div className={"section-intro-chat-bot"}>
			<h4 className={"color-primary"}>Réponses du chatbot</h4>
			<div>
				<p className={"text-primary color-primary"}>Type de Travaux :&nbsp;</p>
				<p className={"text-primary color-primary paragraph_bold"}> {isLoading ?
					<Skeleton height={20}/> : request.type}</p>
			</div>
			<div className={"section-intro-chat-bot-responses"}>
				{isLoading ? "loading" : chatBotResponse.map((item, index) => {
					return (
						<div key={index}
						     className={"section-intro-chat-bot-responses-item" + (index < 3 ? " active" : " inactive" && moreInformationIsActvie ? " active" : " inactive")}>
							<p className={"text-primary color-primary"}>{item.question}</p>
							{Array.isArray(item.answer) ? item.answer.map((itemAnswer, indexAnswer) => {
								return (<p className={"text-primary color-primary paragraph_bold"} key={indexAnswer}>Preview</p>)
							}) : <p className={"text-primary color-primary paragraph_bold"}>{item.answer}</p>}
						</div>
					)
				})}
				<div className={"section-intro-chat-bot-responses-button-see-more"}>
					<ButtonWithOnClickAction onClick={(e) => setMoreInformationIsActvie(!moreInformationIsActvie)}
					                         title={titleButton()} styleButton={"btn-default btn-default-secondary plain"}
					                         isActive={true}/>
				</div>
			</div>
		</div>
	);
};

export default DevisIntroChatBot;
