import React from 'react';

const PopUpResetForm = ({onClick, handleSubmit, message, yes, no}) => {
	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"}/>
				<h4 className={"text-primary color-black"}>{message}</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={onClick}>{no}</button>
					<button className={"btn btn-send"} onClick={handleSubmit}>{yes}</button>
				</div>
			</div>
		</div>
	);
};

export default PopUpResetForm;
