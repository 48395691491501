import * as Yup from 'yup';

export const RoutineSchema = Yup.object().shape({
  title: Yup.string().trim().required('Ce champ est requis'),
  question: Yup.string().trim().required('Ce champ est requis'),
  type: Yup.string().required('Ce champ est requis'),
  answers: Yup.array().of(
    Yup.object().shape({
      chatbotMiniRoutine: Yup.boolean(),
      chatbotMiniRoutineIri: Yup.string().trim().when('chatbotMiniRoutine', {
        is: true,
        then: Yup.string().trim().required('Ce champ est requis'),
      }),
    })
  ),
})