import React, {useContext, useEffect, useState} from "react";
import PrestationService from "../../../../services/PrestationService";
import {initialValuesEditPrestation} from "../../../../config/InitialValues/InitialValuesEditPrestation";
import {Form, Formik} from "formik";
import Skeleton from "react-loading-skeleton";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import CategorieService from "../../../../services/CategoriesService";
import {toast} from "react-toastify";
import {convertFromHTML, convertToRaw, EditorState} from "draft-js";
import ContentState from "draft-js/lib/ContentState";
import draftToHtmlPuri from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";

import {StateRequest as StateRequestComDir} from "../../../../views/Pages/Coms/Dir/Prestations/PrestationsComsDir";
import {StateRequest as StateRequestComRes} from "../../../../views/Pages/Coms/Res/Prestations/PrestationsComRes";
import {useAuth} from "../../../../services/useAuth";
import {EditPrestationSchema} from "../../../../schemas/Prestation/EditPrestationSchema";
import {dataRegions} from "../../../../utils/RegionsUtils";

const FormEditPrestation = ({id, setShowPopUp}) => {

	const {role} = useAuth();

	const { setStateRequest } = useContext(
		role[0] === "ROLE_COM_RES" ? StateRequestComRes : StateRequestComDir
	);

	const [categories, setCategories] = useState([])

	const onSubmit = async (values) => {
		values.categoryIri = `/api/categories/${categories.filter(category => category.title === values.categoryIri)[0].id}`
		setShowPopUp(false)
		const request = await PrestationService.updateService(`services/${id}`, values)
		if (request.request.status === 200) {
			setStateRequest("success edit")
		} else {
			setStateRequest("error")
		}
	}

	const [isLoading, setIsLoading] = useState(true);

	const getCategories = async () => {
		let request = await CategorieService.getServicePrestation()
		if (request.request.status === 200) {
			setCategories(request.data["hydra:member"])
		} else {
			toast.error("Une erreur est survenue lors de la récupération des catégories")
		}
	}
	const [initialValueTitle, setInitialValueTitle] = useState("");

	const getService = async () => {
		const request = await PrestationService.getSingleService(id)
		if (request.request.status === 200) {
			let prestation = request.data
			initialValuesEditPrestation.price = prestation.price || ""
			initialValuesEditPrestation.reference = prestation.reference || ""
			initialValuesEditPrestation.zone = prestation.zone || ""
			initialValuesEditPrestation.spent = prestation.spent || ""
			initialValuesEditPrestation.recommendedPrice = prestation.recommendedPrice || ""
			initialValuesEditPrestation.categoryIri = prestation.niceType || ""
			initialValuesEditPrestation.title = prestation.title || ""
			initialValuesEditPrestation.description = prestation.description || ""
			setInitialValueTitle(prestation.description)
			setIsLoading(false)
		}
	}

	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromBlockArray(
				convertFromHTML(initialValueTitle)
			)
		)
	);

	useEffect(() => {
		setEditorState(EditorState.createWithContent(
			ContentState.createFromBlockArray(
				convertFromHTML(initialValueTitle)
			)
		))
	}, [initialValueTitle]);


	const handleChangeEditorState = (valueOfEditor) => {
		setEditorState(valueOfEditor)
		let value = draftToHtmlPuri(convertToRaw(valueOfEditor.getCurrentContent()))
		initialValuesEditPrestation.description = value
	}

	const isInt = (num) => {
		return num % 1 === 0;
	}

	useEffect(() => {
		setIsLoading(true)
		if (id !== 0 && isInt(id)) {
			getService()
			setCategories([])
			getCategories()
		}
	}, [id]);


	return (
		<>
			<Formik
				initialValues={initialValuesEditPrestation}
				validationSchema={EditPrestationSchema}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => (
					isLoading ? <Skeleton height={340}/> :
						<Form>
							<div className={"form-row-element"}>
								<div className={"form-row-label-input"}>
									<label className={"text-micro paragraph_bold color-dark-grey"}>Description prestation
										<Editor
											initialEditorState={editorState}
											editorState={editorState}
											wrapperClassName="demo-wrapper"
											localization={{
												locale: 'fr',
											}}
											toolbar={{
												options: ["inline", "colorPicker", 'link', "list", 'fontSize', 'fontFamily', 'textAlign'],
												inline: {
													options: ["bold", "italic", "underline", "strikethrough"],
												},
												colorPicker: {
													colors: ["#000000", "#ffffff", "#090754", "#4AA9C2", "#9DDFF1", "#648DE5", "#c13e39", "#5FD086", "#eed128"],
												}
											}}
											onEditorStateChange={(e) => {
												handleChangeEditorState(e)
											}
											}
										/>
									</label>
								</div>
							</div>

							<div className={"form-row-element"}>
								<InputLabelErrorMessage type={"select"} label={"Typologie"} name={"categoryIri"} keyLabel={"title"}
								                        keyValue={"title"} data={categories}/>
							</div>
							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} label={"Zone"}
								                        data={dataRegions} name={"zone"}/>
								<InputLabelErrorMessage type={"number"} label={"Prix de vente (HT)"} name={"price"}/>

							</div>
							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"number"} label={"Prix déboursé (HT)"} name={"spent"}/>
								<InputLabelErrorMessage type={"number"} label={"Marge (HT)"} name={"recommendedPrice"}/>
							</div>
							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"text"} label={"Titre"} name={"title"}/>
								<InputLabelErrorMessage type={"text"} label={"Code article"} name={"reference"} />
							</div>
							<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
							              title={"Modifier"}/>
						</Form>
				)}
			</Formik>
		</>
	)
}

export default FormEditPrestation
