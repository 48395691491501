export const userRoleTranslate = (role) => {
	switch (role) {
		case 'ROLE_CLIENT':
			return "Client"
		case 'ROLE_COM_DIR':
			return "Directeur Commercial"
		case 'ROLE_ARCH':
			return "Architecte"
		case 'ROLE_ING':
			return "Ingénieur"
		case 'ROLE_ADMIN':
			return "Admin"
		case 'ROLE_COM_RES':
			return "Responsable commercial"
		case 'ROLE_COM':
			return "Commercial"
		case 'ROLE_ING_DIR':
			return "Directeur ingénieur"
		case 'ROLE_ING_MANAGER':
			return "Ingénieur manager"
		case 'ROLE_ING_RES':
			return "Responsable ingénieur"
		case 'ROLE_ING_DIAG':
			return "Ingénieur diagnostiqueur"
		case 'ROLE_ING_AFF':
			return "Ingénieur chargé d'affaire"
		case 'ROLE_ING_VERIF':
			return "Ingénieur chargé d'affaire"
		case 'ROLE_ING_EXTRA':
			return "Ingénieur chargé d'affaire"
		default:
			break;
	}
}

export const dataChatInterneAllowed = [
	'ROLE_ING_MANAGER',
	'ROLE_ING_AFF',
	'ROLE_ING_DIAG',
	'ROLE_ING_VERIF',
	'ROLE_ING_EXTRA',
	'ROLE_COM',
	'ROLE_ARCH',
	'ROLE_CLIENT',
]

export const dataRolesIngSelectCollaborators = [
	{
		label: "Ingénieur chargé d'affaire",
		value: "task-project-ing-study"
	}, {
		label: "Ingénieur chargé d'affaire support",
		value: "task-project-ing-study-extra"
	}, {
		label: "Ingénieur vérificateur",
		value: "task-project-ing-study-check"
	}, {
		label: "Ingénieur vérificateur support",
		value: "task-project-ing-study-check-extra"
	}, {
		label: "Ingénieur manager",
		value: "task-project-ing-manager"
	},
	{
		label: "Ingénieur manager support",
		value: "task-project-ing-manager-extra"
	}
]

export const dataRolesSelect = [
	{
		label: "Client",
		value: "ROLE_CLIENT"
	},
	{
		label: "Architecte",
		value: "ROLE_ARCH"
	},
	{
		label: "Directeur Commercial",
		value: "ROLE_COM_DIR"
	},
	{
		label: "Commercial",
		value: "ROLE_COM"
	},
	{
		label: "Ingénieur",
		value: "ROLE_ING"
	},
	{
		label: "Administrateur",
		value: "ROLE_ADMIN"
	},
	{
		label: "Responsable commercial",
		value: "ROLE_COM_RES"
	},
	{
		label: "Directeur technique",
		value: "ROLE_ING_DIR"
	},
	{
		label: "Responsable de production",
		value: "ROLE_ING_RES"
	},
	{
		label: "Ingénieur manager",
		value: "ROLE_ING_MANAGER"
	},
	{
		label: "Ingénieur chargé d'affaire",
		value: "ROLE_ING_AFF"
	},
	{
		label: "Ingénieur diagnostiqueur",
		value: "ROLE_ING_DIAG"
	},
	{
		label: "Ingénieur vérificateur",
		value: "ROLE_ING_VERIF"
	},
	{
		label: "Ingénieur associé",
		value: "ROLE_ING_EXTRA"
	}
]

export const dataRolesSelectIng = [
	{
		label: "Directeur technique",
		value: "ROLE_ING_DIR"
	},
	{
		label: "Responsable de production",
		value: "ROLE_ING_RES"
	},
	{
		label: "Ingénieur manager",
		value: "ROLE_ING_MANAGER"
	},
	{
		label: "Ingénieur chargé d'affaire",
		value: "ROLE_ING_AFF"
	},
	{
		label: "Ingénieur diagnostiqueur",
		value: "ROLE_ING_DIAG"
	},
	{
		label: "Ingénieur vérificateur",
		value: "ROLE_ING_VERIF"
	},
	{
		label: "Ingénieur associé",
		value: "ROLE_ING_EXTRA"
	}
]


export const dataRolesSelectCollaborator = [
	{
		label: "Directeur Commercial",
		value: "ROLE_COM_DIR"
	},
	{
		label: "Responsable commercial",
		value: "ROLE_COM_RES"
	},
	{
		label: "Commercial",
		value: "ROLE_COM"
	}
]
