import CategoryCard from "./CategoryCard";


const MarketPlaceCategoriesContainer = ({categories}) => {


	return (
		<div className={"categories-market-place-container"}>
			{
				categories["hydra:member"].map((category, index) => {
					return (
						<CategoryCard category={category} key={index}/>
					)
				})
			}
		</div>
	)

}

export default MarketPlaceCategoriesContainer;