import React from "react";
import moment from "moment";
import {Link} from "react-router-dom";


export const FOLDER_LATE_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Nom client",
		accessor: "nom_du_client",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.niceNames}</Link>
			)
		}
	},
	{
		Header: "Téléphone",
		accessor: "client.phone"
	},
	{
		Header: "Adresse e-mail",
		accessor: "client.email"
	},
	{
		Header: "Date de rendu",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = "N/A";
			if (cellInfo.row.original.createdAt) {
				date =  new Date(cellInfo.row.original.createdAt);
				date = moment(date).format("DD/MM/YYYY");
			}
			return date
		}
	}
	]
