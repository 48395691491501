import React, { useState, useEffect, createContext } from "react";
import ChatbotHeader from "./Partials/ChatbotHeader";
import ChatbotMessage from "./Partials/ChatbotMessage";
import ChatbotAbout from "./Partials/ChatbotAbout";
import ChatbotAnswers from "./Partials/ChatbotAnswers";
import ChatbotRecap from "./Partials/ChatbotRecap";
import ChatbotFooter from "./Partials/ChatbotFooter";

import PopUpForm from "../../components/Molecules/PopUp/PopUpForm";
import ChatbotPopupLogin from "./Popup/ChatbotPopupLogin";
import ChatbotPopupEnd from "./Popup/ChatbotPopupEnd";

import { ChatbotService } from "../../services/ChatbotService";
export const ChatbotContext = createContext();

const Chatbot = () => {

  const [questionLoaded, setQuestionLoaded] = useState(false);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [activePopup, setActivePopup] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [status, setStatus] = useState("pending");
  const [firstQuestionLoaded, setFirstQuestionLoaded] = useState(false);

  const getFirstQuestion = async (partnerToken) => {
    const response = await ChatbotService.getFirstQuestion( partnerToken ? partnerToken : null);
    if (response.status === 201) {
      let data = response.data;
      setActiveQuestion(data);
      setAllQuestionsAnswered([
        {
          id: data.id,
          data,
          answer: null
        }
      ]);
    }
    setQuestionLoaded(true);
  }

  useEffect(() => {
    if(firstQuestionLoaded === false) {
      setFirstQuestionLoaded(true)
      const urlParams = new URLSearchParams(window.location.search);
      const afl = urlParams.get('afl');
      if (afl) {
        getFirstQuestion(afl);
      } else {
        getFirstQuestion();
      }
    }
  }, []);

  const contextValue = {
    activeQuestion,
    setActiveQuestion,
    allQuestionsAnswered,
    setAllQuestionsAnswered,
    questionLoaded,
    setQuestionLoaded,
    activePopup,
    setActivePopup,
    userToken,
    setUserToken,
    status,
    setStatus
  }

  const closePopup = () => {
    setActivePopup(false);
  }

  return (
    <section className="mbe-chatbot">
      <aside>
        <img src="/images/pictos/logo-mbe-simple.svg" />
      </aside>

      <div>
        <ChatbotHeader />

        <ChatbotContext.Provider value={contextValue} >
          <>

            {
              activeQuestion === false ? (
                <ChatbotRecap />
              ) : (
                <section className="mbe-chatbot__content">
                  <ChatbotMessage />
                  <ChatbotAnswers />
                  <ChatbotAbout />
                </section>
              )
            }

            {
              allQuestionsAnswered.length > 1 && activeQuestion.id !== 1 && (
                <ChatbotFooter />
              )
            }

            <PopUpForm
              title="Connexion"
              form={<ChatbotPopupLogin />}
              show={activePopup}
              onClick={closePopup}
              large={true}
            />

            {
              status === "success" && (
                <ChatbotPopupEnd />
              )
            }

          </>
        </ChatbotContext.Provider>

      </div>
    </section>
  );
}

export default Chatbot;
