import ApiService from "./APIService";

const refreshToken = async () => {
	if (localStorage.getItem("refresh_token") !== undefined) {
		const url = `token/fresh`
		const refreshToken = localStorage.getItem("refresh_token")
		const refreshTokenWithoutQuote = refreshToken.substring(1, refreshToken.length - 1)
		const data = {
			refresh_token: refreshTokenWithoutQuote
		}
		const request = await ApiService.postLogin(url, data)
		if (request.request.status === 200) {
			localStorage.setItem("user", JSON.stringify(JSON.stringify(request.data)))
			window.location.reload()
		} else {
			console.log("error")
		}
	} else {
		localStorage.removeItem("user");
		localStorage.removeItem("refresh_token");
		localStorage.removeItem("role");
		window.location.reload();
	}
}

const refreshTokenService = {
	refreshToken
}

export default refreshTokenService;