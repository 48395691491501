import React, { useState, useEffect, useContext } from 'react'
import { ChatbotAdminActionsContext } from '../ChatbotAdmin';

import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'

import InputSearch from '../../../../../components/Atomes/Inputs/InputSearch'
import Select from '../../../../../components/Atomes/Select/Select'
import CardMiniroutine from '../../../../../components/Molecules/Cards/Chatbot/CardMiniroutine'
import PopUpForm from '../../../../../components/Molecules/PopUp/PopUpForm';
import PopupDeleteMiniroutine from './Popup/PopupDeleteMiniroutine'
import PopupEditMiniroutine from './Popup/PopupEditMiniroutine'

import { AdminService } from '../../../../../services/AdminService'

const ChatbotMiniRoutineAdmin = () => {

  const chatbotContext = useContext(ChatbotAdminActionsContext)

  const selectFilterOptions = [
    "Définition du profil du demandeur",
    "Type de projet avec étage",
    "Compléments de document et prestations additionnelles",
    "Informations clients",
    "Fin du chatbot",
    "Autre",
  ]
  const selectFilterValues = [
    "mini-routine-profile",
    "mini-routine-profile-floors",
    "mini-routine-administrative-complement",
    "mini-routine-administrative-client-info",
    "mini-routine-administrative-final",
    "mini-routine-others",
  ]
  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [miniroutines, setMiniroutines] = useState([])
  const [miniroutinesLoading, setMiniroutinesLoading] = useState(true)
  const [showPopupDelete, setShowPopupDelete] = useState(false)
  const [miniroutineToDelete, setMiniroutineToDelete] = useState(null)
  const [showPopupEdit, setShowPopupEdit] = useState(false)
  const [miniroutineToEdit, setMiniroutineToEdit] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (filter === "Catégorie") {
      setFilter("")
    }
    getMiniroutines();
  }, [filter, search])

  useEffect(() => {
    if (chatbotContext.chatbotAdminActionsContext.name === "mini-routine") {
      getMiniroutines()
      chatbotContext.chatbotAdminActionsContext = {}
    }
  }, [chatbotContext])

  const getMiniroutines = async () => {
    setMiniroutinesLoading(true)
    let request = await AdminService.getWithParams('/chatbot-mini-routines', { 'search': search, 'category.type': filter, 'pagination': 'false' })
    if (request.status === 200) {
      setMiniroutines(request.data['hydra:member'])
      setMiniroutinesLoading(false)
    }
  }

  const closePopup = () => {
    setShowPopupDelete(false)
    setMiniroutineToDelete(null)
    setShowPopupEdit(false)
    setMiniroutineToEdit(null)
  }

  const openPopupDelete = (id, name) => {
    setMiniroutineToDelete({
      id: id,
      title: name,
    })
    setShowPopupDelete(true)
  }
  const confirmDeleteMiniroutine = async () => {
    let request = await AdminService.deleteById('/chatbot-mini-routines', miniroutineToDelete.id)
    if (request.status === 204) {
      toast.success("La mini-routine a bien été supprimée")
      closePopup()
      getMiniroutines()
    }
  }

  const openPopupEdit = (miniroutineId) => {
    setMiniroutineToEdit(miniroutineId)
    setShowPopupEdit(true)
  }


  return (
    <section className='adminChatbot-miniroutine'>
      <>
        <div className='adminChatbot-miniroutine__header'>
          <Select
            styleSelect={"select-custom-secondary"}
            defaultValue={"Catégorie"}
            data={selectFilterOptions}
            value={selectFilterValues}
            onChange={(e) => setFilter(e.target.value)}
          />
          <InputSearch
            name="search"
            placeholder="Rechercher une mini-routine"
            onChangeSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
            onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
          />
        </div>
      </>
      {
        miniroutinesLoading ? (
          <>
            <div className='adminChatbot-routine__skeleton'>
              <Skeleton height={500} />
              <Skeleton height={500} />
              <Skeleton height={500} />
              <Skeleton height={500} />
              <Skeleton height={500} />
              <Skeleton height={500} />
            </div>
          </>
        ) : (
          <>
            <div className='adminChatbot-miniroutine__wrapper'>
              {
                miniroutines.length === 0 && (
                  <p className='text-primary paragraph_bold'>Aucune mini-routine trouvée</p>
                )
              }
              {
                miniroutines.length > 0 && (
                  miniroutines.map((miniroutine, index) => (
                    <CardMiniroutine
                      key={index}
                      miniroutine={miniroutine}
                      openPopupDelete={openPopupDelete}
                      openPopupEdit={openPopupEdit}
                    />
                  ))
                )
              }
            </div>

            {
              showPopupDelete && (
                <PopupDeleteMiniroutine
                  showPopup={showPopupDelete}
                  closeAction={closePopup}
                  validAction={confirmDeleteMiniroutine}
                  miniroutine={miniroutineToDelete}
                />
              )
            }

            {
              showPopupEdit && (
                <PopUpForm
                  large={true}
                  show={showPopupEdit}
                  form={<PopupEditMiniroutine miniroutineId={miniroutineToEdit} />}
                  onClick={() => setShowPopupEdit(false)}
                  title={"Modifier une mini-routine"}
                />
              )
            }
          </>
        )}
    </section>
  )
}

export default ChatbotMiniRoutineAdmin