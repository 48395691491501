import React, {useState} from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import PopUpForm from "../../components/Molecules/PopUp/PopUpForm";
import FormDeleteNewRequest from "../../components/Molecules/Form/FormDeleteNewRequest";
import PopUpDeleteNewRequest from "../../components/Molecules/PopUp/PopUpDeleteNewRequest";

export const COMMERCIAL_NEW_REQUEST_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Nom client",
		accessor: "client.lastname",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.firstname} {cellInfo.row.original.client.lastname}</Link>
			)
		}
	},
	{
		Header: "Typologie projet",
		accessor: "type"
	},
	{
		Header: "Documents",
		accessor: "chatbotDocument",
		Cell: cellInfo => {
			return cellInfo.row.original.chatbotDocument === true ? "Transmis" : "Non transmis";
		}
	},
	{
		Header: "Date demande",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = new Date(cellInfo.row.original.createdAt);
			date = moment(date).format("DD/MM/YYYY");
			return date
		}
	},
	{
		Header: "Demande de rappel",
		accessor: "chatbotContact"
	},
	{
		Header: "",
		accessor: "details",
		Cell: cellInfo => {

			const [isActive, setIsActive] = useState(false)

			return (
				<div className={"actions-controller"}>
					<Link className="btn-little btn-default-primary color-primary" to={`/devis/${cellInfo.row.original.id}`}>Faire un devis</Link>
					<img onClick={() => setIsActive(!isActive)} src={"images/pictos/red-cross.svg"} alt={"delete new demande"} title={"Supprimer une nouvelle demande"} />

					{
						isActive ? (
							<PopUpDeleteNewRequest onClick={() => setIsActive(false)} clientName={ cellInfo.row.original.client.firstname + " " + cellInfo.row.original.client.lastname} idProject={cellInfo.row.original.id} />
						) : ""
					}
				</div>
			)
		}
	}
]

