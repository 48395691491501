import React, { useState, useEffect, useContext } from 'react';
import { ChatbotAdminActionsContext } from '../ChatbotAdmin';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import InputSearch from '../../../../../components/Atomes/Inputs/InputSearch';
import CardRoutine from '../../../../../components/Molecules/Cards/Chatbot/CardRoutine';
import PopUpForm from '../../../../../components/Molecules/PopUp/PopUpForm';
import PopupDeleteRoutine from "./Popup/PopupDeleteRoutine"
import PopupEditRoutine from './Popup/PopupEditRoutine';

import { AdminService } from '../../../../../services/AdminService';

const ChatbotRoutineAdmin = () => {

  const chatbotContext = useContext(ChatbotAdminActionsContext)

  const [search, setSearch] = useState("")
  const [routines, setRoutines] = useState([])
  const [routinesLoading, setRoutinesLoading] = useState(true)
  const [routineToDelete, setRoutineToDelete] = useState(null)
  const [showPopupDelete, setShowPopupDelete] = useState(false)
  const [routineToEdit, setRoutineToEdit] = useState(null)
  const [showPopupEdit, setShowPopupEdit] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    getRoutines()
  }, [search])

  useEffect(() => {
    if (chatbotContext.chatbotAdminActionsContext.name === "question-réponse") {
      getRoutines()
      chatbotContext.chatbotAdminActionsContext = {}
    }
  }, [chatbotContext])

  const getRoutines = async () => {
    let request = await AdminService.getWithParams('/chatbot-questions', { search: search, pagination: 'false' })
    if (request.status === 200) {
      setRoutines(request.data['hydra:member'])
      setRoutinesLoading(false)
    } else {
      toast.error("Une erreur est survenue lors de la récupération des questions-réponses")
    }
  }

  const closePopup = () => {
    setShowPopupDelete(false)
    setRoutineToDelete(null)
    setRoutineToEdit(null)
    setShowPopupEdit(false)
  }
  const openDeletePopup = (id, title) => {
    setRoutineToDelete({
      id: id,
      title: title,
    })
    setShowPopupDelete(true)
  }
  const confirmDeleteRoutine = async () => {
    let request = await AdminService.deleteById('/chatbot-questions', routineToDelete.id)
    if (request.status === 204) {
      toast.success("La question-réponse a bien été supprimée")
      getRoutines()
      closePopup()
    }
  }

  const openEditPopup = (id) => {
    setRoutineToEdit(id)
    setShowPopupEdit(true)
  }

  return (
    <section className='adminChatbot-routine'>
      {
        routinesLoading ? (
          <>
            <Skeleton height={50} width={"100%"} />
            <div className='adminChatbot-routine__skeleton'>
              <Skeleton height={350} />
              <Skeleton height={350} />
              <Skeleton height={350} />
              <Skeleton height={350} />
              <Skeleton height={350} />
              <Skeleton height={350} />
            </div>
          </>
        ) : (
          <>
            <InputSearch
              name="search"
              placeholder="Rechercher une question"
              onChangeSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
              onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
            />

            <div className="adminChatbot-routine__wrapper">
              {
                routines.length === 0 && (
                  <p className='text-primary paragraph_bold'>Aucune question-réponse trouvée</p>
                )
              }
              {
                routines.length > 0 && routines.map((routine, index) => (
                  <CardRoutine
                    key={index}
                    routine={routine}
                    openDeletePopup={openDeletePopup}
                    openEditPopup={openEditPopup}
                  />
                ))
              }
            </div>

            {
              showPopupDelete && (
                <PopupDeleteRoutine
                  showPopup={showPopupDelete}
                  closeAction={closePopup}
                  validAction={confirmDeleteRoutine}
                  routine={routineToDelete}
                />
              )
            }

            {
              showPopupEdit && (
                <PopUpForm
                  large={true}
                  show={showPopupEdit}
                  form={<PopupEditRoutine routineId={routineToEdit} />}
                  onClick={() => setShowPopupEdit(false)}
                  title={"Modifier une question-réponse"}
                />
              )
            }
          </>
        )
      }
    </section>
  )
}

export default ChatbotRoutineAdmin
