import React, {useContext} from "react";
import {StateRequest} from "../../../views/Pages/Admin/MarketPlace/MarketPlace";
import MarketPlaceService from "../../../services/MarketPlaceService";

const PopUpDeleteCategoryMarketPlace = ({id, onClick, title}) => {
	const {setStateRequest} = useContext(StateRequest);

	const handleSubmit = async () => {
		const request = await MarketPlaceService.deleteCategory(id)
		onClick()
		if (request.request.status == 204) {
			setStateRequest('success delete category')
		} else {
			setStateRequest('error')
		}
	}

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"}/>
				<img src={"/images/medias/popup.svg"} alt="img form"/>
				<h4>Voulez-vous supprimer la catégorie "{title}" ?</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={() => onClick()}>Non</button>
					<button className={"btn btn-delete"} onClick={(event) => handleSubmit(event)}>Oui</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpDeleteCategoryMarketPlace;
