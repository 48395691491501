import React, {useEffect, useState} from 'react';
import {HOME_PATH} from "../../../../../../../config/UrlConstants";
import TitleIconNavLink from "../../../../../../../components/Atomes/Title/TitleIconNavLink";
import PopUpInformations from "../../Partials/PopUpInformations";
import TabNavigationStudyFolder from "../../Partials/TabNavigationStudyFolder";
import StudyFolderMenu from "../../Partials/StudyFolderMenu";
import PopUpForm from "../../../../../../../components/Molecules/PopUp/PopUpForm";
import FormAddDPGF from "../../../../../../../components/Molecules/Form/Engineer/FormAddDPGF";
import PopUpResetForm from "../../Partials/PopUpResetForm";
import {BasicSortingTable} from "../../../../../../../components/Molecules/Tables/BasicSortingTable";
import {useParams} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import DirRespService from "../../../../../../../services/Ing/DirResp/DirRespService";
import {toast} from "react-toastify";
import FormEditDPGFIng from "../../../../../../../components/Molecules/Form/Engineer/FormEditDPGFIng";

const StudyFolderDPGF = () => {

	const [showInformation, setShowInformation] = useState(false)
	const [studyFolderStep] = useState("DPGF")

	const [content, setContent] = useState("");

	const [addDPGF, setAddDPGF] = useState(false)
	const [editDPGF, setEditDPGF] = useState(false)
	const [idEditDPGF, setIdEditDPGF] = useState(null)
	const [sendDPGF, setSendDPGF] = useState(false)

	const [deleteDPGF, setDeleteDPGF] = useState(false)
	const [idDeleteDPGF, setIdDeleteDPGF] = useState(null)

	const [generateDPGFWithCoin, setGenerateDPGFWithCoin] = useState(false)
	const [generateDPGFWithoutCoin, setGenerateDPGFWithoutCoin] = useState(false)
	const [showGenerateDPGF, setShowGenerateDPGF] = useState(false)

	const [totalHT, setTotalHT] = useState(0)
	const [totalTTC, setTotalTTC] = useState(0)
	const [loading, setLoading] = useState(true);
	const [loadingDPGF, setLoadingDPGF] = useState(true);
	const [currentCCTP, setCurrentCCTP] = useState(null)

	const [allDPGF, setAllDPGF] = useState(null)
	const [allCCTP, setAllCCTP] = useState(null)

	const {id} = useParams()

	const resetForm = () => {
		window.location.reload()
	}

	const getDPGF = async () => {
		const request = await DirRespService.getDPGF(id);
		if (request.status === 200) {
			setAllDPGF(request.data);

			let ttTtc = 0
			let ttHt = 0
			request.data.studyDpgfs.map((item) => {
				ttTtc += item.total * (1 + item.vta) * item.quantity
				ttHt += item.total * item.quantity
			})
			setTotalTTC(ttTtc)
			setTotalHT(ttHt)
			setCurrentCCTP(request.data.studyDpgfs.filter((item) => item.origin === 2)[0].cctpCategoryTitle)
			setLoadingDPGF(false)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des DPGF");
		}
	}

	const deleteSelectedDPGF = () => {
		const newData = [...allDPGF.studyDpgfs.filter((item) => item.id !== idDeleteDPGF)];

		setAllDPGF({
			...allDPGF,
			studyDpgfs: newData
		})

		setDeleteDPGF(!deleteDPGF)

		let ttTtc = 0
		let ttHt = 0
		newData.map((item) => {
			ttTtc += item.total * (1 + item.vta) * item.quantity
			ttHt += item.total * item.quantity
		})
		setTotalTTC(ttTtc)
		setTotalHT(ttHt)

		toast.success("Le DPGF a bien été supprimé")
	}

	const getCCTP = async () => {
		const request = await DirRespService.getCCTP({pagination: "false"});
		if (request.status === 200) {

			const cctpTries = request.data['hydra:member'].sort((a, b) => {
				return a.title.localeCompare(b.title, 'fr', {numeric: true});
			});

			setAllCCTP(cctpTries);
		} else {
			toast.error("Une erreur est survenue lors de la récupération des DPGF");
		}
	}


	const handleSubmit = (values) => {
		values.origin = parseInt(values.origin)
		values.vta = 0.1
		const newData = [...allDPGF.studyDpgfs, values]; // Ajoutez les nouvelles valeurs à l'ensemble des données existantes

		setAllDPGF({
			...allDPGF,
			studyDpgfs: newData
		})
		toast.success("Le DPGF a bien été ajouté")
		//Changer le total TTC et HT
		let ttTtc = 0
		let ttHt = 0
		newData.map((item) => {
			ttTtc += item.total * (1 + item.vta) * item.quantity
			ttHt += item.total * item.quantity
		})
		setTotalTTC(ttTtc)
		setTotalHT(ttHt)
	};

	const handleEdit = (values) => {
		const test = allDPGF.studyDpgfs.filter((item) => item.id === idEditDPGF)[0]
		test.title = values.title
		test.unit = values.unit
		test.quantity = values.quantity
		test.total = values.total

		const newData = [...allDPGF.studyDpgfs.filter((item) => item.id !== idEditDPGF), test];

		let ttTtc = 0
		let ttHt = 0
		newData.map((item) => {
			ttTtc += item.total * (1 + item.vta) * item.quantity
			ttHt += item.total * item.quantity
		})
		setTotalTTC(ttTtc)
		setTotalHT(ttHt)

		setAllDPGF({
			...allDPGF,
			studyDpgfs: newData
		})

		toast.success("Le DPGF a bien été modifié")

	}

	const getProjectsInformations = async () => {
		const request = await DirRespService.getStudyFolderInformations(id)
		if (request.status === 200) {
			setContent(request.data)
			setLoading(false)
		}
	}

	const onChangeTVA = (event) => {
		let ttTtc = 0
		let ttHt = 0
		setAllDPGF(
			{
				...allDPGF,
				studyDpgfs: allDPGF.studyDpgfs.map((item) => {
					ttTtc += item.total * (1 + parseFloat(event.target.value)) * item.quantity
					ttHt += item.total * item.quantity
					return {
						...item,
						vta: parseFloat(event.target.value)
					}
				})
			}
		)
		setTotalTTC(ttTtc)
		setTotalHT(ttHt)

		toast.success("La TVA a bien été modifiée")
	}

	const onChangeCCTP = async (event) => {
		setCurrentCCTP(event.target.value)

		const currentCCTP = allCCTP.filter((item) => item.title === event.target.value)[0].id

		const request = await DirRespService.getDPGFForTable2(currentCCTP);

		if (request.status === 200) {
			let receive = request.data['hydra:member'];
			receive = receive.map((item) => {
				return {
					...item,
					origin: 2,
					vta: 0.1,
					title: item.cctpTitle
				}
			})

			let newData = [...allDPGF.studyDpgfs]
			newData = newData.filter((item) => item.origin !== 2)
			newData = [...newData, ...receive]

			setAllDPGF(
				{
					...allDPGF,
					studyDpgfs: newData
				})
		} else {
			toast.error("Une erreur est survenue lors de la récupération des DPGF");
		}
	}

	const sendAllDPGFWithoutPrice = async () => {

		const request = await DirRespService.sendDPGF(id, {
			...allDPGF,
			withPrice: false
		})
		if (request.status === 200) {
			toast.success("Le DPGF a bien été envoyé")
		} else {
			toast.error("Une erreur est survenue lors de l'envoi du DPGF")
		}
		setGenerateDPGFWithoutCoin(!generateDPGFWithoutCoin)
	}

	const sendAllDPGFWithPrice = async () => {
		const request = await DirRespService.sendDPGF(id, {
			...allDPGF,
			withPrice: true
		})
		if (request.status === 200) {
			toast.success("Le DPGF a bien été envoyé")
		} else {
			toast.error("Une erreur est survenue lors de l'envoi du DPGF")
		}
		setGenerateDPGFWithCoin(!generateDPGFWithCoin)
	}

	useEffect(() => {
		setLoading(true)
		setLoadingDPGF(true)
		getProjectsInformations()
		getDPGF()
		getCCTP()
	}, [id])

	return (
		<>
			{
				loading || loadingDPGF ? <Skeleton height={500}/> : (
					<div className={"section-study-folder-dpgf mbe-section"}>
						<div className={"section-study-folder-dpgf-title"}>
							<TitleIconNavLink title={`${content.project.client.niceNames} - ${content.project.address.street}, ${content.project.address.nicePostcode}, ${content.project.address.city}`} link={HOME_PATH} information={true}
							                  onClick={() => setShowInformation(true)}/>
							<div className={"section-study-folder-introduction-title-icons"}>
								<img alt={"img download"} src={"/images/pictos/download-primary.svg"}/>
								<img alt={"img send"} src={"/images/pictos/send.svg"} onClick={() => setShowPopUp(!showPopUp)}/>
							</div>
						</div>

						<TabNavigationStudyFolder theStep={studyFolderStep} id={id} needExecutionClass={content.needExecutionClass} needCCTP={content.needCctp}
						                          needSummary={content.needSummary}/>

						<div className={"section-study-folder-dpgf-container"}>

							<div className={"section-study-folder-dpgf-container-editor-right"}>
								<div className={"section-study-folder-dpgf-container-editor"}>
									<div className={"section-study-folder-dpgf-container-editor-title"}>
										<h3 className={"text-primary color-primary"}>DPGF</h3>
										<div className={"section-study-folder-dpgf-container-editor-title-actions"}>
											<img alt={"img add"} className={"btn"} src={"/images/pictos/add-cctp.svg"}
											     onClick={() => setAddDPGF(!addDPGF)}/>
											<img alt={"img refresh"} className={"btn"} src={"/images/pictos/refresh-cctp.svg"}
											     onClick={() => setShowGenerateDPGF(!showGenerateDPGF)}/>
											<img alt={"img generate without money"} className={"btn"}
											     src={"/images/pictos/dpgf-without-money.svg"}
											     onClick={() => setGenerateDPGFWithoutCoin(!generateDPGFWithoutCoin)}/>
											<img alt={"img generate with money"} className={"btn"}
											     src={"/images/pictos/dpgf-coin-with-money.svg"}
											     onClick={() => setGenerateDPGFWithCoin(!generateDPGFWithCoin)}/>
										</div>
									</div>

									<div className={"section-study-folder-dpgf-container-editor-content"}>
										<div className={"section-study-folder-dpgf-container-editor-content-title"}>
											<h3 className={"text-primary color-black"}>1. GENERALITES</h3>
											<select className={"select-custom-secondary"} onChange={(event) => onChangeTVA(event)}>
												<option value="0.1">TVA - 10%</option>
												<option value="0.2">TVA - 20%</option>
											</select>
										</div>
										<BasicSortingTable
											dataTable={allDPGF?.studyDpgfs?.filter((item) => item.origin === 1)}
											columnsTable={[
												{
													Header: "Désignation ouvrages",
													accessor: "content",
													Cell: cellInfo => {
														return (
															<div dangerouslySetInnerHTML={{__html: cellInfo.row.original.title}}></div>
														)
													}
												},
												{
													Header: "Référence CCTP",
													accessor: "cctpReference"
												},
												{
													Header: "Unité",
													accessor: "unit"
												},
												{
													Header: "Quantité",
													accessor: "quantity"
												},
												{
													Header: "P.V. HT",
													accessor: "total",
													Cell: cellInfo => {
														let total = 0
														if (cellInfo.row.original.total) {
															total = cellInfo.row.original.total.toFixed(2) + " €"
														} else {
															total = "0.00 €"
														}
														return <div className="nowrap">{total}</div>
													}
												},
												{
													Header: "TVA",
													accessor: "vta",
													Cell: cellInfo => {
														let vta = cellInfo.row.original.vta * 100
														vta = vta.toFixed(0) + " %"
														return <div className="nowrap">{vta}</div>
													}
												},
												{
													Header: "Total HT",
													accessor: "",
													Cell: cellInfo => {
														let total = 0
														if (cellInfo.row.original.total) {
															total = cellInfo.row.original.quantity * cellInfo.row.original.total.toFixed(2) + " €"
														} else {
															total = "0.00 €"
														}
														return <div className="nowrap">{total}</div>
													}
												},
												{
													Header: "Total TTC",
													accessor: "total_ttc",
													Cell: cellInfo => {
														let total = 0
														if (cellInfo.row.original.total) {
															total = cellInfo.row.original.quantity * cellInfo.row.original.total * (1 + cellInfo.row.original.vta)
															total = total.toFixed(2) + " €"
														} else {
															total = "0.00 €"
														}
														return <div className="nowrap">{total}</div>
													}
												},
												{
													Header: "Actions",
													accessor: "",
													Cell: cellInfo => {
														return (
															<>
																<div className={"actions-controller"}>
																	<img src={"/images/pictos/pencil-blue.svg"} alt="pencil blue"
																	     onClick={() => {
																		     setEditDPGF(!editDPGF)
																		     setIdEditDPGF(cellInfo.row.original.id)
																	     }}/>
																</div>
															</>
														)
													}
												}]}
										/>
										<div className={"section-study-folder-dpgf-container-editor-content-title"}>
											<h3 className={"text-primary color-black"}>{currentCCTP}</h3>
											<select className={"select-custom-secondary"} onChange={(event) => onChangeCCTP(event)}>
												{
													allCCTP ? allCCTP.map((item) => (
														<option selected={item.title === currentCCTP}
														        value={item.title}>{item.title}</option>
													)) : null
												}
											</select>
										</div>
										<BasicSortingTable
											dataTable={allDPGF.studyDpgfs.filter((item) => item.origin === 2)}
											columnsTable={
												[
													{
														Header: "Désignation ouvrages",
														accessor: "content",
														Cell: cellInfo => {
															return (
																/*<div>
																						<h3 className="adminDpgfView-sortingTable--title">{ cellInfo.row.original.designation_ouvrages_title }</h3>
																						<p>{ cellInfo.row.original.designation_ouvrages }</p>
																				</div>*/
																<div dangerouslySetInnerHTML={{__html: cellInfo.row.original.title}}></div>
															)
														}
													},
													{
														Header: "Référence CCTP",
														accessor: "cctpReference"
													},
													{
														Header: "Unité",
														accessor: "unit"
													},
													{
														Header: "Quantité",
														accessor: "quantity"
													},
													{
														Header: "P.V. HT",
														accessor: "total",
														Cell: cellInfo => {
															let total = 0
															if (cellInfo.row.original.total) {
																total = cellInfo.row.original.total.toFixed(2) + " €"
															} else {
																total = "0.00 €"
															}
															return <div className="nowrap">{total}</div>
														}
													},
													{
														Header: "TVA",
														accessor: "vta",
														Cell: cellInfo => {
															let vta = cellInfo.row.original.vta * 100
															vta = vta.toFixed(0) + " %"
															return <div className="nowrap">{vta}</div>
														}
													},
													{
														Header: "Total HT",
														accessor: "",
														Cell: cellInfo => {
															let total = 0
															if (cellInfo.row.original.total) {
																total = cellInfo.row.original.quantity * cellInfo.row.original.total.toFixed(2) + " €"
															} else {
																total = "0.00 €"
															}
															return <div className="nowrap">{total}</div>
														}
													},
													{
														Header: "Total TTC",
														accessor: "total_ttc",
														Cell: cellInfo => {
															let total = 0
															if (cellInfo.row.original.total) {
																total = cellInfo.row.original.quantity * cellInfo.row.original.total * (1 + cellInfo.row.original.vta)
																total = total.toFixed(2) + " €"
															} else {
																total = "0.00 €"
															}
															return <div className="nowrap">{total}</div>
														}
													},
													{
														Header: "Actions",
														accessor: "",
														Cell: cellInfo => {
															return (
																<>
																	<div className={"actions-controller"}>
																		<img src={"/images/pictos/pencil-blue.svg"} alt="pencil blue"
																		     onClick={() => {
																			     setEditDPGF(!editDPGF)
																			     setIdEditDPGF(cellInfo.row.original.id)
																		     }}/>
																	</div>
																</>
															)
														}
													}]
											}
										/>

										{
											allDPGF.studyDpgfs.filter((item) => item.origin === 3).length > 0 || allDPGF.studyDpgfs.filter((item) => item.origin === 4).length > 0 ? (
												<h3 className={"text-primary color-black"}>3. SUJETIONS DE L'ENTREPRISE ET OPTIONS</h3>
											) : null
										}


										{
											allDPGF.studyDpgfs.filter((item) => item.origin === 3).length > 0 ? (
												<>
													<h3 className={"text-primary color-black"}>3.1 Sujétions de l'entreprise - Options</h3>
													<BasicSortingTable
														dataTable={allDPGF.studyDpgfs.filter((item) => item.origin === 3)}
														columnsTable={[{
															Header: "Désignation ouvrages",
															accessor: "title",
															Cell: cellInfo => {
																return (
																	/*<div>
																							<h3 className="adminDpgfView-sortingTable--title">{ cellInfo.row.original.designation_ouvrages_title }</h3>
																							<p>{ cellInfo.row.original.designation_ouvrages }</p>
																					</div>*/
																	<div dangerouslySetInnerHTML={{__html: cellInfo.row.original.title}}></div>
																)
															}
														},
															{
																Header: "CCTP",
																accessor: "cctpReference",
																Cell: cellInfo => {
																	let cctp = ""
																	if (cellInfo.row.original.cctpReference) {
																		cctp = cellInfo.row.original.cctpReference
																	} else {
																		cctp = "N/A"
																	}

																	return <div className="nowrap">{cctp}</div>
																}
															},
															{
																Header: "Unité",
																accessor: "unit"
															},
															{
																Header: "Quantité",
																accessor: "quantity"
															},
															{
																Header: "P.V. HT",
																accessor: "total",
																Cell: cellInfo => {
																	let total = 0
																	if (cellInfo.row.original.total) {
																		total = cellInfo.row.original.total.toFixed(2) + " €"
																	} else {
																		total = "0.00 €"
																	}
																	return <div className="nowrap">{total}</div>
																}
															},
															{
																Header: "TVA",
																accessor: "vta",
																Cell: cellInfo => {
																	let vta = cellInfo.row.original.vta * 100
																	vta = vta.toFixed(0) + " %"
																	return <div className="nowrap">{vta}</div>
																}
															},
															{
																Header: "Total HT",
																accessor: "",
																Cell: cellInfo => {
																	let total = 0
																	if (cellInfo.row.original.total) {
																		total = cellInfo.row.original.quantity * cellInfo.row.original.total.toFixed(2) + " €"
																	} else {
																		total = "0.00 €"
																	}
																	return <div className="nowrap">{total}</div>
																}
															},
															{
																Header: "Total TTC",
																accessor: "total_ttc",
																Cell: cellInfo => {
																	let total = 0
																	if (cellInfo.row.original.total) {
																		total = cellInfo.row.original.quantity * cellInfo.row.original.total * (1 + cellInfo.row.original.vta)
																		total = total.toFixed(2) + " €"
																	} else {
																		total = "0.00 €"
																	}
																	return <div className="nowrap">{total}</div>
																}
															},
															{
																Header: "Actions",
																accessor: "",
																Cell: cellInfo => {
																	return (
																		<div className={"actions-controller"}>
																			<img src={"/images/pictos/red-cross.svg"} alt="red cross" onClick={() => {
																				setIdDeleteDPGF(cellInfo.row.original.id)
																				setDeleteDPGF(!deleteDPGF)
																			}}/>
																		</div>
																	)
																}
															}]}
													/>
												</>
											) : null
										}


										{
											allDPGF.studyDpgfs.filter((item) => item.origin === 4).length > 0 ? (
												<>
													<h3 className={"text-primary color-black"}>3.2 Variantes</h3>
													<BasicSortingTable
														dataTable={allDPGF.studyDpgfs.filter((item) => item.origin === 4)}
														columnsTable={[{
															Header: "Désignation ouvrages",
															accessor: "title",
															Cell: cellInfo => {
																return (
																	/*<div>
																							<h3 className="adminDpgfView-sortingTable--title">{ cellInfo.row.original.designation_ouvrages_title }</h3>
																							<p>{ cellInfo.row.original.designation_ouvrages }</p>
																					</div>*/
																	<div dangerouslySetInnerHTML={{__html: cellInfo.row.original.title}}></div>
																)
															}
														},
															{
																Header: "CCTP",
																accessor: "cctpReference",
																Cell: cellInfo => {
																	let cctp = ""
																	if (cellInfo.row.original.cctpReference) {
																		cctp = cellInfo.row.original.cctpReference
																	} else {
																		cctp = "N/A"
																	}

																	return <div className="nowrap">{cctp}</div>
																}
															},
															{
																Header: "Unité",
																accessor: "unit"
															},
															{
																Header: "Quantité",
																accessor: "quantity"
															},
															{
																Header: "P.V. HT",
																accessor: "total",
																Cell: cellInfo => {
																	let total = 0
																	if (cellInfo.row.original.total) {
																		total = cellInfo.row.original.total.toFixed(2) + " €"
																	} else {
																		total = "0.00 €"
																	}
																	return <div className="nowrap">{total}</div>
																}
															},
															{
																Header: "TVA",
																accessor: "vta",
																Cell: cellInfo => {
																	let vta = cellInfo.row.original.vta * 100
																	vta = vta.toFixed(0) + " %"
																	return <div className="nowrap">{vta}</div>
																}
															},
															{
																Header: "Total HT",
																accessor: "",
																Cell: cellInfo => {
																	let total = 0
																	if (cellInfo.row.original.total) {
																		total = cellInfo.row.original.quantity * cellInfo.row.original.total.toFixed(2) + " €"
																	} else {
																		total = "0.00 €"
																	}
																	return <div className="nowrap">{total}</div>
																}
															},
															{
																Header: "Total TTC",
																accessor: "total_ttc",
																Cell: cellInfo => {
																	let total = 0
																	if (cellInfo.row.original.total) {
																		total = cellInfo.row.original.quantity * cellInfo.row.original.total * (1 + cellInfo.row.original.vta)
																		total = total.toFixed(2) + " €"
																	} else {
																		total = "0.00 €"
																	}
																	return <div className="nowrap">{total}</div>
																}
															},
															{
																Header: "Actions",
																accessor: "",
																Cell: cellInfo => {
																	return (
																		<div className={"actions-controller"}>
																			<img src={"/images/pictos/red-cross.svg"} alt="red cross" onClick={() => {
																				setIdDeleteDPGF(cellInfo.row.original.id)
																				setDeleteDPGF(!deleteDPGF)
																			}}/>
																		</div>
																	)
																}
															}]}
													/>
												</>
											) : null
										}

									</div>
								</div>

								<div className={"section-study-folder-dpgf-container-summer"}>
									<p className={"color-primary paragraph_bold text-primary"}>Totaux</p>
									<div className={"section-study-folder-dpgf-container-summer-right"}>
										<p className={"color-primary paragraph_bold text-primary"}>{totalHT.toFixed(2)} €</p>
										<p className={"color-primary paragraph_bold text-primary"}>{totalTTC.toFixed(2)} €</p>
									</div>
								</div>
							</div>
							<StudyFolderMenu content={content} projectId={content.project.id}/>
						</div>


						<PopUpInformations
							show={showInformation}
							onClick={() => setShowInformation(false)}
							large={false}
							title={"Informations projet"}
							content={content}
						/>

						{
							generateDPGFWithCoin ? (
								<PopUpResetForm
									onClick={() => setGenerateDPGFWithCoin(!generateDPGFWithCoin)}
									handleSubmit={() => sendAllDPGFWithPrice()}
									message={
										<React.Fragment>
											Le DPGF est sur le point d'être généré <span className="color-red">AVEC PRIX</span>. Êtes-vous sûr
											de
											vouloir le générer ? Cette action est irréversible.
										</React.Fragment>
									}
									no={"Non, je vais revérifier"}
									yes={"Oui, générer"}
								/>
							) : null
						}

						{
							generateDPGFWithoutCoin ? (
								<PopUpResetForm
									onClick={() => setGenerateDPGFWithoutCoin(!generateDPGFWithoutCoin)}
									handleSubmit={() => sendAllDPGFWithoutPrice()}
									message={
										<React.Fragment>
											Le DPGF est sur le point d'être généré <span className="color-red">SANS PRIX</span>. Êtes-vous sûr
											de
											vouloir le générer ? Cette action est irréversible.
										</React.Fragment>
									}
									no={"Non, je vais revérifier"}
									yes={"Oui, générer"}
								/>
							) : null
						}

						{
							showGenerateDPGF ? (
								<PopUpResetForm
									onClick={() => setShowGenerateDPGF(!showGenerateDPGF)}
									handleSubmit={() => resetForm()}
									message={"Les DPGF que vous avez ajouté sont sur le point d’être éffacées. Êtes-vous sûr de vouloir les réinitialiser ? Cette action est irréversible."}
									no={"Non, ne rien effacer"}
									yes={"Oui, réinitialiser"}
								/>
							) : null
						}

						{
							addDPGF ? (
								<PopUpForm form={<FormAddDPGF onSubmit={handleSubmit} onClick={() => setAddDPGF(!addDPGF)}/>}
								           onClick={() => setAddDPGF(!addDPGF)} title={"Ajouter une DPGF"} large={true} show={addDPGF}/>
							) : null
						}

						{
							editDPGF && idEditDPGF !== null ? (
								<PopUpForm
									form={<FormEditDPGFIng onSubmit={handleEdit}
									                       values={allDPGF.studyDpgfs.filter((item) => item.id === idEditDPGF)[0]}
									                       onClick={() => setEditDPGF(!editDPGF)}/>}
									onClick={() => setEditDPGF(!editDPGF)}
									title={"Modifier un DPGF"}
									large={true} show={editDPGF}/>
							) : null
						}

						{
							deleteDPGF && idDeleteDPGF !== null ? (
								<PopUpResetForm
									onClick={() => setDeleteDPGF(!deleteDPGF)}
									handleSubmit={() => deleteSelectedDPGF()}
									message={"Le DPGF est sur le point d’être éffacé. Êtes-vous sûr de vouloir le supprimer ?"}
									no={"Non, je vais revérifier"}
									yes={"Oui, supprimer"}
								/>
							) : null
						}

						{
							sendDPGF ? (
								<PopUpResetForm
									onClick={() => setSendDPGF(!sendDPGF)}
									handleSubmit={() => sendAllDPGF()}
									message={"Le DPGF est sur le point d’être envoyé. Êtes-vous sûr de vouloir l’envoyer ?"}
									no={"Non, je vais revérifier"}
									yes={"Oui, envoyer"}
								/>
							) : null
						}
					</div>
				)
			}
		</>


	);
};

export default StudyFolderDPGF;
