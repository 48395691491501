import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

import ProjectHeader from '../../Partials/ProjectHeader';
import ProjectNav from '../../Partials/ProjectNav';
import ButtonSubmit from '../../../../components/Atomes/Buttons/ButtonSubmit';

import {ProjectStageService} from '../../../../services/ProjectStageService';
import exportFileService from '../../../../services/ExportFileService';
import {forEach} from "lodash";
import {toast} from "react-toastify";

const ProjectStageSignature = () => {

	const {projectId} = useParams();
	const [projectStepVerificator, setProjectStepVerificator] = useState(false);
	const [project, setProject] = useState(null);
	const [cgvu, setCgvu] = useState(true);
	const [CgvuContent, setCgvuContent] = useState(null);
	const [isCgvuValidate, setIsCgvuValidate] = useState(false);
	const [devis, setDevis] = useState(null);
	const [quoteSelected, setQuoteSelected] = useState([]);

	const [clicked, setClicked] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		getProjectData();
		getProjectCGVU();
	}, []);

	const getProjectData = async () => {
		let projectInfos = await ProjectStageService.getSingleProject(projectId);
		if (projectInfos.stage !== 'COM_signature') {
			navigate("/projet/" + projectId, {replace: true});
		} else {
			projectInfos.quotes.forEach(element => {
				if (element.signatureTry) {
					navigate("/projet/" + projectId + "?event=signing_complete", {replace: true});
				}
			});
			setProjectStepVerificator(true);
			setProject(projectInfos);
			setDevis(projectInfos.quotes);
		}
	}

	const getProjectCGVU = async () => {
		let request = await ProjectStageService.getCGVU()
		if (request.status === 200) {
			setCgvuContent(request.data.content);
		}
	}

	// const scrollText = (e) => {
	// 	const element = e.target;
	// 	const scrollValue = Math.round(element.scrollHeight - element.scrollTop);
	// 	if (scrollValue === element.clientHeight || scrollValue <= element.clientHeight) {
	// 		setCgvu(true);
	// 	}
	// }

	const downloadFile = (file, nb) => {
		if (file === undefined) {
			forEach(devis, (devis, index) => {
				exportFileService.exportFile(`/quotes/${devis.id}`, `devis-${index + 1}`, {}, "pdf");
			})
		} else {
			exportFileService.exportFile(`/quotes/${file}`, `devis-${nb}`, {}, "pdf");
		}
	}

	const changeQuoteSelected = (devis) => {
		if (quoteSelected.includes(devis)) {
			setQuoteSelected(quoteSelected.filter(item => item !== devis));
		} else {
			setQuoteSelected([...quoteSelected, devis]);
		}
	}

	const validSignature = () => {
		setIsCgvuValidate(true);
	}

	const submitSignature = async () => {
		setClicked(true)
		let quotesIri = [];
		quoteSelected.map((quote) => {
			quotesIri.push(`/api/bills/${quote}`);
		})
		toast.loading("Veuillez patienter votre demande est en cours");
		let request = await ProjectStageService.billCreateSignature(projectId, {
			'redirectUrl': `${process.env.REACT_APP_PROD_URL}/projet/${projectId}`,
			'quotesIri': quotesIri
		});
		if (request.status === 201) {
			window.location.replace(request.data.signatureUrl);
		} else {
			setClicked(false)
			toast.error("Une erreur est survenue lors de la demande de signature du devis");
		}
	}

	return (
		<>
			{
				!projectStepVerificator ? (
					<>
						<Skeleton height={300}/>
						<Skeleton height={40}/>
					</>
				) : (
					<>
						<ProjectHeader
							project={project}
						/>

						<div className='project-container'>

							<ProjectNav
								step={0}
								visit={project.visitType}
							/>

							<div className="project-container-step__header">
								<h3 className="color-black">Signer votre devis en ligne</h3>
								<p className="text-primary">Vous êtes à l'étape de signature du devis. Si vous avez des questions sur
									votre devis, vous pouvez contacter votre commercial.</p>
							</div>

							<div>

								<div className='project-step-signature__devis'>
									<section>
										{
											devis && (
												devis.map((devis, index) => {
													return (
														<div key={index} onClick={() => downloadFile(devis.id, index + 1)}>
															<img src={'/images/pictos/mime-types/pdf.svg'}/>
															<p className="text-primary color-primary paragraph_bold">devis-{index + 1}.pdf</p>
														</div>
													)
												})
											)
										}
									</section>
									<button className='btn-default btn-add-project' onClick={() => downloadFile()}>
										{
											devis.length > 1 ? `Télécharger les devis` : 'Télécharger le devis'
										}
									</button>
								</div>

							</div>

							<section className='project-step project-step-signature'>
								{/* <div className='project-step-signature__content' onScroll={scrollText}>
									{
										CgvuContent &&
										<>
											<div dangerouslySetInnerHTML={{__html: CgvuContent}}></div>
										</>
									}
								</div> */}

								<div className='project-step-signature__content'>
									{
										CgvuContent &&
										<>
											<div dangerouslySetInnerHTML={{__html: CgvuContent}}></div>
										</>
									}
								</div>

								<div className={`project-step-choice ${isCgvuValidate ? 'valid' : ''}`}>
									<label htmlFor="cgvu" className='text-tertiary color-dark-grey'>

										J’ai lu et j’accepte <span><a href={"https://monbureaudetudes.com/conditions-generales-vente"} target={"_blank"}>les Conditions Générales de Vente et d’Utilisation (CGVU)</a></span>

									</label>
									<input
										type='radio' id='cgvu' name="cgvu"
										onChange={validSignature} disabled={!cgvu}
									/>
								</div>

								<div className='project-step-choice  project-step-signature__checks'>
									<p className='text-tertiary color-dark-grey'>Quels devis souhaitez-vous signer ?</p>
									<div className='project-step-choice__checks'>
										{
											devis.map((devis, index) => {
												return (
													<div key={index}>
														<input
															onClick={() => changeQuoteSelected(devis.id)}
															type='checkbox'
															id={`devis-${index + 1}`}
														/>
														<label
															htmlFor={`devis-${index + 1}`}
															className='text-tertiary color-dark-grey'>
															Devis-{index + 1}
														</label>
													</div>
												)
											})
										}
									</div>
								</div>


								<div className='project-step-signature__actions'>
									{
										!clicked && (
											<ButtonSubmit
												styleButton='btn-default btn-default-primary'
												title='Signer'
												isActive={!isCgvuValidate || quoteSelected.length === 0}
												onClick={submitSignature}
											/>
										)
									}

								</div>
							</section>
						</div>
					</>
				)
			}
		</>
	);
}

export default ProjectStageSignature;
