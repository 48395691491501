import React, { useEffect, useState } from 'react';
import moment from 'moment';

import MbeCalendar from '../../../../../../components/Molecules/Calendar/MbeCalendar';
import { toast } from "react-toastify";
import { ConvertTasksToFullcalendarEvents } from '../../../../../../utils/TaskUtils';

import TaskService from '../../../../../../services/TaskService';
import getUserService from '../../../../../../services/getUser';

import PopUpForm from '../../../../../../components/Molecules/PopUp/PopUpForm';
import FormAddAssignment from '../../../../../../components/Molecules/Form/MbeCalendar/FormAddAssignment';
import FormEditAssignment from '../../../../../../components/Molecules/Form/MbeCalendar/FormEditAssignment';

const ComCalendarPart = () => {
  const userId = getUserService.getUserId();
  const nextYear = moment().add(1, 'y').format('YYYY-MM-DD')

  const [tasks, setTasks] = useState([]);
  const [taskManager, setTaskManager] = useState(true);

  // "PopUp add" settings
  const [showPopupAdd, setShowPopupAdd] = useState(false);
  const [newTask, setNewTask] = useState([]);
  const DisplayCreateModalTask = (event) => {
    setShowPopupAdd(!showPopupAdd)
    if (!showPopupAdd) {
      setNewTask(event)
    }
  }

  // "PopUp edit" settings
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [editTask, setEditTask] = useState([]);
  const DisplayEditModalTask = (event) => {
    setShowPopupEdit(!showPopupEdit)
    if (!showPopupEdit) {
      setEditTask(event)
    }
  }

  useEffect(() => {
    const getTasks = async () => {
      let extraParams = "category.type[]=task-project-com-quote&category.type[]=task-project-com-prospection&category.type[]=task-project-com-meeting&category.type[]=task-project-com-call"
      let request = await TaskService.getAllTasks("tasks?" + extraParams + "&user.id=" + userId + "&pagination=false&endAt[before]=" + nextYear)
      if (request.request.status === 200) {
        let tasks = request.data['hydra:member'];
        tasks = ConvertTasksToFullcalendarEvents(tasks)
        setTasks(tasks)
      } else {
        toast.error("Une erreur est survenue lors de la récupération des tâches")
      }
    };
    getTasks()
  }, [userId, showPopupAdd, showPopupEdit]);

  return (
    <>
      <MbeCalendar
        tasks={tasks}
        taskManager={taskManager}
        taskCreatorFunction={DisplayCreateModalTask}
        taskEditorFunction={DisplayEditModalTask}
        withNotifications={false}
      />
      <PopUpForm
        large={true}
        show={showPopupAdd}
        onClick={DisplayCreateModalTask}
        title={"Ajouter une nouvelle tâche"}
        form={<FormAddAssignment setShowPopUp={setShowPopupAdd} newTask={newTask} role="COMS" />}
      />

      <PopUpForm
        large={true}
        show={showPopupEdit}
        title={showPopupEdit ? "Gestion de la tâche " + editTask.event.title : "Gestion de la tâche"}
        onClick={DisplayEditModalTask}
        form={<FormEditAssignment setShowPopUp={setShowPopupEdit} editTask={editTask} role="COMS" />}
      />
    </>
  );
}

export default ComCalendarPart;
