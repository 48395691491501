export const blueJeans = () => {
	return "#4AA9C2"
}
export const purple = () => {
	return "#648DE5"
}
export const green = () => {
	return "#5FD086"
}
export const primary = () => {
	return "#090754"
}

export const yellow = () => {
	return "#FFF474"
}

export const lightBlueJeans = () => {
	return "#9DDFF1"
}

