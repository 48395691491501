export const initialValuesAddPrestation = {
	title: "",
	reference: "",
	zone: "",
	spent: "",
	recommendedPrice: "",
	categoryIri: "",
	description: "",
	price: ""
}
