import * as Yup from 'yup';

export const ingCollaboratorProjectEditSchema = Yup.object().shape({
    roleAttribue1: Yup.string().required('Veuillez renseigner un role'),
    roleHour1: Yup.string().required('Veuillez renseigner une durée'),
    roleSupport1: Yup.string(),
    roleAttribue2: Yup.string(),
    roleHour2: Yup.string(),
    roleSupport2: Yup.string(),
    roleAttribue3: Yup.string(),
    roleHour3: Yup.string(),
    roleSupport3: Yup.string(),
    roleAttribue4: Yup.string(),
    roleHour4: Yup.string(),
    roleSupport4: Yup.string(),
    commentaireProjectCollaborator: Yup.string(),
})