import React from 'react';
import InputSearch from "../../Atomes/Inputs/InputSearch";
import Select from "../../Atomes/Select/Select";
import ButtonPopupDatepicker from "../../Atomes/Buttons/ButtonPopupDatepicker";

const FilterSearchSelectDatepicker =
	({
		 onSearch,
		onChangeSearchValue,
		 defaultValue,
		 firstValue,
		placeHolderSearch,
		 options,
		 onChange,
		 onChangeDate,
		 date,
		 resetDateSelected
	 }) => {
		return (
			<div className={"filter-search-select-button"}>
				<InputSearch placeholder={placeHolderSearch ? placeHolderSearch : "Recherche"} name={"Recherche"} onChangeSearch={onSearch} onChangeSearchValue={onChangeSearchValue}/>
				<Select defaultValue={defaultValue} value={firstValue} data={options} styleSelect={"select-custom-secondary"} onChange={onChange}/>
				<ButtonPopupDatepicker date={date} onChange={onChangeDate} resetDateSelected={resetDateSelected}/>
			</div>
		);
	};

export default FilterSearchSelectDatepicker;
