import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from "react-toastify";

import MbeCalendar from '../../../../../../components/Molecules/Calendar/MbeCalendar';
import { ConvertTasksToFullcalendarEvents } from '../../../../../../utils/TaskUtils';

import TaskService from '../../../../../../services/TaskService';
import getUserService from '../../../../../../services/getUser';

import PopUpForm from '../../../../../../components/Molecules/PopUp/PopUpForm';
import FormAddAssignment from '../../../../../../components/Molecules/Form/MbeCalendar/FormAddAssignment';
import FormEditAssignment from '../../../../../../components/Molecules/Form/MbeCalendar/FormEditAssignment';
import MbePopupVisit from '../../../../../../components/Molecules/Calendar/Popup/PopupVisit';

const HomeIngProdCalendar = () => {

    const userId = getUserService.getUserId();

    const nextYear = moment().add(1, 'y').format('YYYY-MM-DD')
    const [tasks, setTasks] = useState([]);

    // "PopUp add" settings
    const [showPopupAdd, setShowPopupAdd] = useState(false);
    const [newTask, setNewTask] = useState([]);
    const DisplayCreateModalTask = (event) => {
        setShowPopupAdd(!showPopupAdd)
        if (!showPopupAdd) {
            setNewTask(event)
        }
    }

    // "PopUp edit" settings
    const [showPopupEdit, setShowPopupEdit] = useState(false);
    const [editTask, setEditTask] = useState([]);
    const [visitPopup, setVisitPopup] = useState(false);
    const [visitTask, setVisitTask] = useState([]);

    const DisplayEditModalTask = (event) => {
        if (event.event) {
            if (event.event.extendedProps.type === "task-project-ing-visit") {
                setVisitPopup(!visitPopup)
                if (!visitPopup) {
                    setVisitTask(event.event)
                }
            } else {
                setShowPopupEdit(!showPopupEdit)
                if (!showPopupEdit) {
                    setEditTask(event)
                }
            }
        } else {
            setShowPopupEdit(false)
            setEditTask([])
            setVisitPopup(false)
            setVisitTask([])
        }
    }

    const closeAction = () => {
        setVisitPopup(false)
        setVisitTask([])
    }

    useEffect(() => {
        const getTasks = async () => {
            let extraParams = "?category.type[]=task-project-ing-writing&category.type[]=task-project-ing-measure&category.type[]=task-project-ing-projecting&category.type[]=task-project-ing-meeting&category.type[]=task-project-ing-analyse&category.type[]=task-project-ing-supervision&category.type[]=task-project-ing-additional-request&category.type[]=task-project-ing-customer-service&category.type[]=task-project-ing-visit";
            let urlParams = `${extraParams}&pagination=false&endAt[before]=${nextYear}&user.id=${userId}`;
            let request = await TaskService.getAllTasks("tasks" + urlParams )
            if (request.request.status === 200) {
                let tasks = request.data['hydra:member'];
                tasks = ConvertTasksToFullcalendarEvents(tasks)
                setTasks(tasks)
            } else {
                toast.error("Une erreur est survenue lors de la récupération des tâches")
            }
        };
        getTasks()
    }, [showPopupAdd, showPopupEdit]);

    return (
        <section className="ing-prod-calendar-section">
            <h3 className={"color-primary ing-prod-calendar-section__header"}>Planning</h3>
            <MbeCalendar
                tasks={tasks}
                taskManager={true}
                withNotifications={false}
                taskCreatorFunction={DisplayCreateModalTask}
                taskEditorFunction={DisplayEditModalTask}
            />

            <PopUpForm
                large={true}
                show={showPopupAdd}
                onClick={DisplayCreateModalTask}
                title={"Ajouter une nouvelle tâche"}
                form={<FormAddAssignment setShowPopUp={setShowPopupAdd} newTask={newTask} role="INGS" />}
            />

            <PopUpForm
                large={true}
                show={showPopupEdit}
                title={showPopupEdit ? "Gestion de la tâche " + editTask.event.title : "Gestion de la tâche"}
                onClick={DisplayEditModalTask}
                form={<FormEditAssignment setShowPopUp={setShowPopupEdit} editTask={editTask} role="INGS" />}
            />
            {
                visitPopup && (
                    <MbePopupVisit event={visitTask} showPopup={visitPopup} closeAction={closeAction} />
                )
            }
        </section>
    );
};

export default HomeIngProdCalendar;

