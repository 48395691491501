import React, {useContext} from "react";
import {StateRequest} from "../../../../views/Pages/Admin/Partners/Partners";
import {Form, Formik} from "formik";
import {initialValuesEditPartner} from "../../../../config/InitialValues/InitialValuesEditPartner";
import {PartnersSchema} from "../../../../schemas/Admin/Partners/PartnersSchema";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import PartnerService from "../../../../services/PartnerService";

const FormAddPartnerAdmin = ({setIsActive}) => {
	const {setStateRequest} = useContext(StateRequest);

	const onSubmit = async (values) => {
		values.active = Boolean(values.active)

		const request = await PartnerService.addPartnerLink(values)
		if (request.request.status === 201) {
			setIsActive(false)
			setStateRequest("success add")
		} else {
			setStateRequest("error")
		}
	}

	initialValuesEditPartner.active = ""
	initialValuesEditPartner.title = ""

	return (
		<div className={"form-partner"}>
			<div className={"form-partner"}>
				<Formik
					initialValues={initialValuesEditPartner}
					validationSchema={PartnersSchema}
					onSubmit={onSubmit}
				>
					{({isSubmitting}) => {
						return (
							<Form>
								<div className={"form-row-element"}>
									<InputLabelErrorMessage type={"select"} label={"État du lien"} name={"active"} keyValue={"value"}
									                        keyLabel={"label"}
									                        data={[{label: "Activé", value: true}, {label: "Désactivé", value: false}]}/>
								</div>
								<div className={"form-row-element"}>
									<InputLabelErrorMessage type={"text"} label={"Identifiants"} name={"title"}/>
								</div>
								<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
								              title={"Ajouter le lien partenaire"}/>
							</Form>
						)
					}}
				</Formik>
			</div>
		</div>
	);
}

export default FormAddPartnerAdmin
