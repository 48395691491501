import React, {createContext, useEffect, useState} from 'react';
import {CATALOG_PAGE_COLUMNS} from "../../../../../config/ArrayColumns/CatalogPageColumns";
import PrestationService from "../../../../../services/PrestationService";
import {toast} from "react-toastify";
import TableSearchTitleCreate from "../../../../../components/Molecules/Tables/TableSearchTitleCreate";
import PopUpForm from "../../../../../components/Molecules/PopUp/PopUpForm";
import FormAddPrestation from "../../../../../components/Molecules/Form/Prestations/FormAddPrestation";

export const StateRequest = createContext()

const PrestationsComsRes = () => {

	const [prestations, setPrestations] = useState([]);
	const [search, setSearch] = useState("");
	const [stateRequest, setStateRequest] = useState("");
	const [showPopUp, setShowPopUp] = useState(false);

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("La prestation a bien été ajoutée")
		} else if (type === "success edit") {
			toast.success("La prestation a bien été modifiée")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		} else if (type === "success delete") {
			toast.success("La prestation a bien été supprimée")
		}
		setStateRequest("")
	}

	const DisplayCreateModalPrestation = () => {
		setShowPopUp(!showPopUp)
	}

	const getPrestations = async () => {
		let request = await PrestationService.getService({search: search, pagination: "true"})
		if (request.request.status === 200) {
			setPrestations(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du catalogue de prestations")
		}
	}

	useEffect(() => {
		if (stateRequest !== "") {
			setPrestations([])
			getPrestations()
			window.scrollTo(0, 0);
			DisplayToast(stateRequest)
		}
		getPrestations()

	}, [search, stateRequest])

	return (
		<>
			<StateRequest.Provider value={{stateRequest, setStateRequest}}>
				<TableSearchTitleCreate
					title={"Annuaire des prestations"}
					placeHolder={"Recherche"}
					data={prestations}
					columnTable={CATALOG_PAGE_COLUMNS}
					onChangeSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
					onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
					onClick={DisplayCreateModalPrestation}
					createName={"Ajouter une prestation"}
					numberElement={20}
				/>
				<PopUpForm large={true} title={"Ajouter une prestation"} form={<FormAddPrestation setShowPopUp={setShowPopUp}/>}
				           onClick={DisplayCreateModalPrestation} show={showPopUp}/>
			</StateRequest.Provider>
		</>
	);
};

export default PrestationsComsRes;
