import React, {createContext, useEffect, useState} from 'react';
import Overview from "./Partials/Overview";
import ApprovalCircuit from "./Partials/ApprovalCircuit";
import AccessToFolder from "./Partials/AccessToFolder";
import FilterTwoSelectsDatepicker from "../../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";
import KpiService from "../../../../../services/KpiService";
import ApprovalCircuitService from "../../../../../services/ApprovalCircuitService";
import CollaboratorService from "../../../../../services/CollaboratorService";
import { ConvertObjectToUrlParams } from "../../../../../utils/StringUtils";
import moment from "moment";
import { toast } from "react-toastify";
import { parseInt } from "lodash";
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

export const StateRequest = createContext()

const Home = () => {
	const [date, setDate] = useState(new Date());
	const options = dataRegionsArray

	const [dateStart, setDateStart] = useState("");
	const [monthlyObjective, setMonthlyObjective] = useState(0);
	const [dateEnd, setDateEnd] = useState("");
	const [collaborators, setCollaborators] = useState([]);
	const [averageBasket, setAverageBasket] = useState(0);
	const [transformRate, setTransformRate] = useState("");
	const [revenueByType, setRevenueByType] = useState([{ type: "", total: 0 }]);
	const [revenueByMonth, setRevenueByMonth] = useState([{ month: "", year: "", total: 0 }]);
	const [approvalCircuit, setApprovalCircuit] = useState([]);
	const [region, setRegion] = useState("");
	const [userFilter, setUserFilter] = useState("")
	const [stateRequest, setStateRequest] = useState("");

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}

	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Tous les collaborateurs") {
			setUserFilter("")
		} else {
			setUserFilter(event.target.value)
		}
	}

	const getCollaborators = async () => {
		const urlCom = ConvertObjectToUrlParams("users", { regions: region, roles: "ROLE_COM"})
		const request = await CollaboratorService.getCollaborators(urlCom)
		if (request.request.status === 200) {
			let coms = request.data['hydra:member']
			let availableComs = []
			coms.forEach(com => {
				if (com.roles[0] !== "ROLE_COM_DIR") {
					availableComs.push(com)
				}
			})
			setCollaborators(availableComs)
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	const getApprovalCircuit = async () => {
		const request = await ApprovalCircuitService.getTenApprovalCircuits()
		if (request.request.status === 200) {
			setApprovalCircuit(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des circuits de validation")
		}
	}

	const [requestsCount, setRequestsCount] = useState("")
	const getRequestsCount = async () => {
		let request;
		if (userFilter === "") {
			request = await KpiService.getRequestsCount({ region: region, dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await KpiService.getCollaboratorRequestsCount(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}
		if (request.request.status === 200) {
			setRequestsCount(request.data[0].total);

		} else {
			toast.error("Une erreur est survenue lors de la récupération du revenue")
		}
	}

	const [recoveryCount, setRecoveryCount] = useState("")
	const getRecoveryCount = async () => {
		let request;
		if (userFilter === "") {
			request = await KpiService.getRecoveryCount({ region: region, dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await KpiService.getCollaborateurRecoveryCount(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}
		if (request.request.status === 200) {
			setRecoveryCount(request.data.total);
		} else {
			toast.error("Une erreur est survenue lors de la récupération du revenue")
		}
	}

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("La remise a été effectuée avec succès")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	const getAverageBasket = async () => {
		let request = ""
		if (userFilter === "") {
			request = await KpiService.getAverageBasket({ region: region, dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await CollaboratorService.getCollaboratorAverageBasket(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}
		if (request.request.status === 200) {
			let total = parseFloat(request.data.total);
			setAverageBasket(total.toFixed(2))
		} else {
			toast.error("Une erreur est survenue lors de la récupération du panier moyen")
		}
	}

	const getTransformRate = async () => {
		let request
		if (userFilter === "") {
			request = await KpiService.getTransformRate({ region: region, dateStart: dateStart, dateEnd: dateEnd })

		} else {
			request = await CollaboratorService.getCollaboratorTransformRate(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}
		if (request.request.status === 200) {
			setTransformRate(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du taux de transformation")
		}
	}

	const getRevenueByType = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getRevenueByType({ region: region, dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await CollaboratorService.getCollaboratorRevenueByType(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}
		if (request.request.status === 200) {
			setRevenueByType(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des revenues par type")
		}
	}

	const getRevenueByMonth = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getRevenueByMonth({ region: region, dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await CollaboratorService.getCollaboratorRevenueByMonth(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}
		if (request.request.status === 200) {
			setRevenueByMonth(request.data.revenues)
			setMonthlyObjective(request.data.monthlyObjectives.toFixed(2))
		} else {
			toast.error("Une erreur est survenue lors de la récupération des revenues par mois")
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}
	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getCollaborators()
		getAverageBasket()
		setApprovalCircuit([])
		getApprovalCircuit()
		getTransformRate()
		getRevenueByType()
		getRevenueByMonth()
		getRequestsCount()
		getRecoveryCount()
	}, [region, userFilter, date, stateRequest])


	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<FilterTwoSelectsDatepicker
				defaultValue={"Toutes les agences"}
				options={options}
				firstValue={options}
				secondDefaultValue={"Tous les collaborateurs"}
				secondOptions={collaborators.map(collaborator => collaborator.lastname)}
				secondValue={collaborators.map(collaborator => collaborator.id)}
				onChange={changeRegionFilter}
				onSecondChange={changeCollaboratorFilter}
				date={date}
				onChangeDate={(date) => changeDate(date)}
				resetDateSelected={resetDate}
			/>

			<Overview
				kpi={averageBasket}
				transformRate={transformRate}
				revenueByType={revenueByType}
				revenueByMonth={revenueByMonth}
				monthlyObjective={monthlyObjective}
				requestsCount={requestsCount}
				recoveryCount={recoveryCount}
			/>

			<ApprovalCircuit approvalCircuit={approvalCircuit}/>
			<AccessToFolder/>
		</StateRequest.Provider>
	);
};

export default Home;
