import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { ProjectStageService } from "../../../../../services/ProjectStageService";

const PopupValidatePRI = ({ projectId, showPopup, closeAction }) => {

  const navigate = useNavigate();
  const validPRI = async () => {
    let request = await ProjectStageService.patchPRI(projectId, {
      validation: true,
      reason: ''
    });
    if (request.status === 200) {
      toast.success("Le principe de structure a bien été validé");
      navigate("/projet/" + projectId, { replace: true });
    }
  }

  return (
    <div className={` pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"pop-up-simple container-s center"}>
        <img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} onClick={closeAction} />
        <img className="pop-up-simple-img" src={"/images/medias/popup.svg"} />
        <p className={"text-primary color-primary paragraph_bold"}>Vous êtes sur le point de valider le principe de structure de votre projet.</p>
        <p className={"text-primary color-primary paragraph_bold"}>Souhaitez-vous valider le principe de structure ?</p>
        <div className={"pop-up-simple-btns"}>
          <button className={"btn-default btn-cancel"} onClick={closeAction}>Non</button>
          <button className={"btn-default btn-valid"} onClick={validPRI}>Oui</button>
        </div>
      </div>
    </div>
  )
}

export default PopupValidatePRI;