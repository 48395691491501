import React, { useContext } from 'react';
import { ChatbotContext } from '../Chatbot';

const ChatbotAbout = () => {

  const contextValue = useContext(ChatbotContext);

  return (
    <aside className="mbe-chatbot__content-about">
      <h4 className="color-green">Bon à savoir</h4>
      <div>
        {
          !contextValue.questionLoaded ? (
            <p className="text-primary">Chargement ...</p>
          ) : (
            contextValue.activeQuestion && (
              <>
                {
                  contextValue.activeQuestion.about ? (
                    <div dangerouslySetInnerHTML={{ __html: contextValue.activeQuestion.about }}></div>
                  ) : (
                    <p className='text-primary'>La question n'a pas de bon à savoir</p>
                  )
                }
              </>
            )
          )
        }
      </div>
    </aside>
  )
}

export default ChatbotAbout;