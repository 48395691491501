import React, {useEffect, useState} from 'react';


export function Cart({title, value, className}) {

	const [price, setPrice] = useState(value);

	useEffect(() => {
		setPrice(value)
	}, [value]);


	return (<div className={`${className} element-kpi element-kpi-m align-center cart`}>
		<div className={"element-kpi-title"}>
			<h4 className={"color-primary"}>{title}</h4>
		</div>
		<div className={"cart-count"}>
			<h1 className={"color-blue-jeans"}>
				{price}
			</h1>
		</div>
	</div>)
}

export default Cart;
