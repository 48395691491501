import React from 'react';
import {useFormikContext} from "formik";
import {initialValuesEditPartner} from "../../../../config/InitialValues/InitialValuesEditPartner";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";

const FormEditPartnersAdmin = ({partner}) => {

	const {isSubmitting} = useFormikContext();

	if (partner.length !== 0) {
		initialValuesEditPartner.title = partner.title;
		initialValuesEditPartner.active = partner.active;
	}

	return (
		<>
			<div className={"form-row-element"}>
				<InputLabelErrorMessage type={"select"} label={"État du lien"} name={"active"} keyValue={"value"}
				                        keyLabel={"label"}
				                        data={[{label: "Activé", value: "true"}, {label: "Désactivé", value: "false"}]}/>
			</div>
			<div className={"form-row-element"}>
				<InputLabelErrorMessage type={"text"} label={"Identifiants"} name={"title"}/>
			</div>
			<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
			              title={"Enregistrer"}/>
		</>
	);
};

export default FormEditPartnersAdmin;
