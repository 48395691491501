import React, {useMemo, useState} from "react";
import {usePagination, useSortBy, useTable} from "react-table";
import api from "../../../services/APIService";

export const SortingTable = ({dataTable, columnsTable, change, setShowPopUp, numberElement}) => {
	const columns = useMemo(() => columnsTable, [columnsTable]);
	const [data, setData] = useState(dataTable["hydra:member"] ? dataTable["hydra:member"] : []);
	const [pageNumber, setPageNumber] = useState(0);

	const pageOptions = useMemo(() => {
		let options = [];
		for (let i = 0; i < dataTable["hydra:totalItems"] / 20; i++) {
			options.push(i);
		}
		setData(dataTable["hydra:member"] ? dataTable["hydra:member"] : [])
		return options;
	}, [dataTable, numberElement]);


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
	} = useTable(
		{
			columns,
			data,
			pageOptions,
			initialState: {pageIndex: 0, pageSize: numberElement ? numberElement : 20},
		},
		useSortBy,
		usePagination
	);
	const changePage = async (pageIndex) => {

		let updatedUrl = dataTable["hydra:view"]["hydra:first"].replace(/&?page=\d+/i, "");
		updatedUrl = updatedUrl.replace("/api/", "")
		const searchParams = new URLSearchParams();
		setPageNumber(pageIndex);
		if (pageIndex > pageNumber) {
			setPageNumber(pageIndex);
			searchParams.append("page", pageIndex + 1);
		} else {
			setPageNumber(pageIndex - 1);
			searchParams.append("page", pageIndex);
		}
		const newUrl = `${updatedUrl}&${searchParams.toString()}`;

		return await api.getAllData(newUrl).then((response) => {
			setData(response.data["hydra:member"]);
		});
	};

	const switchPage = async (pageIndex) => {
		let updatedUrl = dataTable["hydra:view"]["hydra:first"].replace(/&?page=\d+/i, "");
		updatedUrl = updatedUrl.replace("/api/", "")
		const searchParams = new URLSearchParams();
		setPageNumber(pageIndex - 1);
		searchParams.append("page", pageIndex);
		const newUrl = `${updatedUrl}&${searchParams.toString()}`;
		return await api.getAllData(newUrl).then((response) => {
			setData(response.data["hydra:member"]);
		});
	}

	return (
		<>
			<div className={"table-general-style-sortingTable"}>
				<table {...getTableProps()}>
					<thead>
					{headerGroups.map((headerGroup, index) => (
						<tr key={index} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, index) => (
								<td
									key={index}
									unselectable={"on"}
									className={"unselectable"}
									{...column.getHeaderProps(column.getSortByToggleProps())}
								>
									{column.render("Header")}
									<span>
                      {column.isSorted ? (
	                      column.isSortedDesc ? (
		                      <img src={"/images/pictos/sort-down-arrow.svg"} alt={"sort-down-arrow"}/>
	                      ) : (
		                      <img src={"/images/pictos/sort-up-arrow.svg"} alt={"sort-up-arrow"}/>
	                      )
                      ) : (
	                      ""
                      )}
                    </span>
								</td>
							))}
						</tr>
					))}
					</thead>
					<tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row);
						return (
							<tr key={index} {...row.getRowProps()}>
								{row.cells.map((cell, index) => {
									return (
										<td key={index} {...cell.getCellProps()}>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
					</tbody>
				</table>
			</div>
			{pageOptions.length > 1 ? (
				<div className="pagination">
					<button onClick={() => changePage(pageNumber)} disabled={!pageNumber > 0}>
						{"<"}
					</button>
					{" "}
					<span>
            {pageOptions.map((option, index) => {
	            if (index === pageOptions.length - 1 || (index >= pageNumber - 2 && index <= pageNumber + 2)) {
		            return (
			            <button
				            key={option}
				            onClick={() => switchPage(option + 1)}
				            className={pageNumber === index ? "active" : ""}
			            >
				            {option + 1}
			            </button>
		            );
	            } else if (index === pageOptions.length - 3 && option - pageNumber > 1) {
		            return <span key={index}>{' '}...{' '}</span>;
	            }
	            return null;
            })}
          </span>
					{" "}
					<button onClick={() => changePage(pageNumber + 1)} disabled={pageNumber + 1 >= pageOptions.length}>
						{">"}
					</button>
				</div>
			) : null}
		</>
	);
};
