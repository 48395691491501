import React from 'react';
import TitlePopUpWithCloseArrow from "../../../../../../components/Atomes/Title/TitlePopUpWithCloseArrow";
import {Link} from "react-router-dom";

const PopUpInformations = ({show, large, title, onClick, content}) => {

	return (
		<div className={"pop-up-base-style " + (show ? "active" : "")}>
			<div className={"pop-up-form " + (show ? "active " : "") + (large ? "large " : "")}>
				<div className={"pop-up-form-container-study-folder"}>
					<TitlePopUpWithCloseArrow onClick={onClick} title={title}/>
					<div className={"pop-up-form-container-study-folder-id"}>
						<p className={"text-primary color-primary"}>{content.project.client?.reference}</p>
					</div>

					<div className={"pop-up-form-container-study-folder-status"}>
						<p className={"text-primary color-dark-grey"}>{content.project.niceStage}</p>
					</div>
					<Link style={{margin:"0 auto 20px", display:"flex"}} className={"btn-default btn-default-primary color-primary"} to={`/projet/${content.project.id}`}>Fiche projet</Link>


					<div className={"pop-up-form-container-study-folder-info"}>
						<div className={"pop-up-form-container-study-folder-info-left"}>

							<div className={"pop-up-form-container-study-folder-info-element"}>
								<p className={"text-micro color-dark-grey"}>Informations client</p>
								<p className={"text-micro paragraph_bold color-primary"}>{content.project.client.niceNames}</p>
								<a href={`tel:${content.project.client.phone}`} className={"text-micro paragraph_bold color-primary"} style={{textDecoration:"none", color:"#090754"}}>{content.project.client.phone}</a>
							</div>

							<div className={"pop-up-form-container-study-folder-info-element"}>
								<p className={"text-micro color-dark-grey"}>Adresse du chantier</p>
								<p className={"text-micro paragraph_bold color-primary"}>{content.project.address.street}, {content.project.address.nicePostcode}, {content.project.address.city}</p>
								<a href={`https://www.google.fr/maps/place/${content.project.address.street}, ${content.project.address.nicePostcode}, ${content.project.address.city}`} target={"_blank"}>Ouvrir avec Google Maps</a>
							</div>

							<div className={"pop-up-form-container-study-folder-info-element"}>
								<p className={"text-micro color-dark-grey"}>Typologie de projet</p>
								<p className={"text-micro paragraph_bold color-primary"}>{content.project.type}</p>
							</div>

							<div className={"pop-up-form-container-study-folder-info-element"}>
								<p className={"text-micro color-dark-grey"}>Architecte</p>
								<p className={"text-micro paragraph_bold color-primary"}>{content.project.arch ? content.project.arch.niceNames : "N/A"}</p>
							</div>

						</div>

						<div className={"pop-up-form-container-study-folder-info-right"}>
							<div className={"pop-up-form-container-info-element"}>
								<p className={"text-micro color-dark-grey"}>Commercial</p>
								<p className={"text-micro paragraph_bold color-primary"}>{content.project.com ? content.project.com.niceNames : "N/A"}</p>
							</div>

							<div className={"pop-up-form-container-study-folder-info-element"}>
								<p className={"text-micro color-dark-grey"}>Ingénieur diagnostiqueur</p>
								<p className={"text-micro paragraph_bold color-primary"}>{content.project.ingDiag ? content.project.ingDiag.niceNames : "N/A"}</p>
							</div>

							<div className={"pop-up-form-container-study-folder-info-element"}>
								<p className={"text-micro color-dark-grey"}>Ingénieur chargé(e) d’affaires</p>
								<p className={"text-micro paragraph_bold color-primary"}>{content.project.ingAff ? content.project.ingAff.niceNames : "N/A"}</p>
							</div>

							<div className={"pop-up-form-container-study-folder-info-element"}>
								<p className={"text-micro color-dark-grey"}>Ingénieur vérificateur</p>
								<p className={"text-micro paragraph_bold color-primary"}>{content.project.ingChecker ? content.project.ingChecker.niceNames : "N/A"}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PopUpInformations;
