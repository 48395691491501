export const InitialValuesArchitectAccount = {
  profilePicture: "",
  gender : "",
  lastname: "",
  firstname: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  postalCode: "",
  addressComplement: "",
  agencyName: "",
};