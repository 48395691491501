import React from 'react';
import ProfileAccountForm from './Partials/Account/Form';
import ProfilePasswordForm from './Partials/Password/FormING';
import TitleIconNavLink from "../../../../components/Atomes/Title/TitleIconNavLink";
import ProfileJobForm from "./Partials/Job/Form";

const ProfileING = () => {
  return (
    <section>
      <TitleIconNavLink title={"Tableau de bord"} link={"/"} />
      <ProfileAccountForm />
      <ProfileJobForm />
      <ProfilePasswordForm />
    </section>
  );
}

export default ProfileING;