import {useState} from "react";
import PopUpDeleteCategoryMarketPlace from "../../../../../components/Molecules/PopUp/PopUpDeleteCategoryMarketPlace";

const CategoryCard = ({category, onclick}) => {
	const [deleteCategory, setDeleteCategory] = useState(false)

	return (
		<>
		<div className={"category-card"}>
			<h3 className="color-primary">{category.title}</h3>
			<p className={"text-primary color-primary"}>Description de la catégorie</p>
			<div className={"category-card-paragraph"}>
				<p>{category.description}</p>
			</div>
			<img src={"images/pictos/red-cross.svg"} onClick={() => setDeleteCategory(true)}/>
		</div>
			{
				deleteCategory ? (
					<PopUpDeleteCategoryMarketPlace id={category.id} title={category.title} onClick={() => setDeleteCategory(false)}/>
				) : null
			}
		</>
	)
}

export default CategoryCard;