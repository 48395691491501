import React, {useContext} from "react";
import {StateRequest} from "../../../views/Pages/Admin/Bill/AdminBills";
import {BillService} from "../../../services/BillService";
import {Form, Formik} from "formik";
import {InitialValuesAssetGenerator} from "../../../config/InitialValues/Admin/InitialValuesAssetGenerator";
import {AddAssetGeneratorSchema} from "../../../schemas/Admin/Bills/AssetGeneratorSchema";
import InputLabelErrorMessage from "../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../Atomes/Buttons/ButtonSubmit";
import jwt_decode from "jwt-decode";

const FormAssetGenerator = ({setShow, row}) => {
	const {stateRequest, setStateRequest} = useContext(StateRequest);

	const onSubmit = async (values) => {
		let date = values.date.split("/")
		date = date[2] + "-" + date[1] + "-" + date[0]
		const data = {
			"relatedInvoiceIri": `/api/bills/${row.id}`,
			"creditEditor": values.assetEditor,
			"amount": values.amountRefund * -1,
			"reason": values.reasonForTheAsset,
			"paidAt": date,
		}
		setShow(false)
		const request = await BillService.postAssetGenerator(`credits`, data)
		if (request.request.status === 201) {
			setStateRequest("success add asset")
		} else {
			setStateRequest("error")
		}
	}

	const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
	const decodedToken = jwt_decode(user.token)
	const niceNames = decodedToken.ln + " " + decodedToken.fn

	InitialValuesAssetGenerator.reference = row.reference
	InitialValuesAssetGenerator.assetEditor = niceNames
	InitialValuesAssetGenerator.datePayment = new Date(row.payment.createdAt).toLocaleDateString("fr-FR")
	InitialValuesAssetGenerator.date = new Date().toLocaleDateString("fr-FR")


	return (
		<>
			<Formik
				initialValues={InitialValuesAssetGenerator}
				validationSchema={AddAssetGeneratorSchema}
				onSubmit={onSubmit}
			>
				{({isSubmitting}) => (
					<Form>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"text"} label={"Numéro de facture"} name={"reference"}/>
							<InputLabelErrorMessage type={"text"} label={"Date du paiement"} name={"datePayment"}/>
						</div>
						<div className={"form-row-element form-row-two-elements"}>
							<InputLabelErrorMessage type={"text"} label={"Éditeur de l'avoir"} name={"assetEditor"}/>
							<InputLabelErrorMessage type={"number"} label={"Montant du remboursement HT"} name={"amountRefund"}/>
						</div>
						<div className={"form-row-element form-row-two-elements"}>
							{/*<InputLabelErrorMessage type={"text"} label={"Date"} name={"date"} />*/}
							<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} label={"Motif de l'avoir"}
							                        data={[{
								                        value: 'Retard rendu dossier d’étude structure',
								                        label: 'Retard rendu dossier d’étude structure'
							                        },
								                        {
									                        value: 'Changement prestation mur non-porteur',
									                        label: 'Changement prestation mur non-porteur'
								                        },
								                        {
									                        value: 'Date rendez-vous trop éloignée',
									                        label: 'Date rendez-vous trop éloignée'
								                        },
								                        {
									                        value: 'ESDE abandonné pas d’ouverture de mur porteur',
									                        label: 'ESDE abandonné pas d’ouverture de mur porteur'
								                        },
								                        {value: 'Projet annulé', label: 'Projet annulé'},
								                        {
									                        value: 'Prestation chiffrée devis complémentaire',
									                        label: 'Prestation chiffrée devis complémentaire'
								                        },
								                        {value: 'Signature mauvais devis', label: 'Signature mauvais devis'},
								                        {value: 'Prestation non réalisée', label: 'Prestation non réalisée'},
								                        {value: 'Changement prestation projet', label: 'Changement prestation projet'},
								                        {
									                        value: 'Droit rétractation 14 jours ouvrés',
									                        label: 'Droit rétractation 14 jours ouvrés'
								                        },
								                        {value: 'Avoir litige client', label: 'Avoir litige client'},
								                        {value: 'Erreur diagnostic structure', label: 'Erreur diagnostic structure'},
								                        {value: 'Erreur planification projet', label: 'Erreur planification projet'},
								                        {
									                        value: 'Prestation annulé mur non-porteur',
									                        label: 'Prestation annulé mur non-porteur'
								                        },
								                        {value: 'Erreur prise de rendez-vous', label: 'Erreur prise de rendez-vous'},
								                        {value: 'Prestation ESDE non réalisée', label: 'Prestation ESDE non réalisée'},
								                        {value: 'Prestation EDFT non réalisée', label: 'Prestation EDFT non réalisée'},
								                        {
									                        value: 'Projet annulé délai créneau trop long',
									                        label: 'Projet annulé délai créneau trop long'
								                        },
								                        {
									                        value: 'Projet annulé client non-propriétaire',
									                        label: 'Projet annulé client non-propriétaire'
								                        },
								                        {
									                        value: 'Remise commerciale prestation', label: 'Remise commerciale prestation'
								                        },
								                        {value: 'Geste commercial partenariat', label: 'Geste commercial partenariat'},
								                        {
									                        value: 'Erreur génération facture acompte',
									                        label: 'Erreur génération facture acompte'
								                        },
								                        {
									                        value: 'Erreur génération facture finale',
									                        label: 'Erreur génération facture finale'
								                        }
							                        ]
							                        }
							                        name={"reasonForTheAsset"}/>
						</div>
						<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting}
						              title={"Générer l'avoir"}/>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default FormAssetGenerator;
