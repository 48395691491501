import React, { useEffect, useState } from "react";
import moment from "moment/moment";

import KpisViewTitle from "./partials/Title";
import KpisViewCharts from "./partials/Charts";
import KpisViewFilters from "./partials/Filters";

import KpiService from "../../../../../services/KpiService";
import { toast } from "react-toastify";
import { ConvertObjectToUrlParams } from "../../../../../utils/StringUtils";
import CollaboratorService from "../../../../../services/CollaboratorService";

const ManagerKPIView = () => {

	// Dates
	let currentDate = moment().format('D-M-YYYY');
	let startDateCurrentMonth = moment().startOf('month').format('D-M-YYYY')
	let dateMonthLater = moment().subtract(1, 'months').format('D-M-YYYY')
	const [date, setDate] = useState(new Date());
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");

	// Kpis
	const month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
	const [revenueByMonth, setRevenueByMonth] = useState([{ month: "", year: "", total: 0 }]);
	const [monthlyObjective, setMonthlyObjective] = useState(0);
	const [transformRateToday, setTransformRateToday] = useState("");
	const [transformRateLastMonth, setTransformRateLastMonth] = useState("");
	const [transformRateTotal, setTransformRateTotal] = useState("");
	const [userFilter, setUserFilter] = useState("");
	const [revenue, setRevenue] = useState(0);
	const [collaborators, setCollaborators] = useState([]);
	const [revenueToday, setRevenueToday] = useState(0)
	const [revenueCurrentMonth, setRevenueCurrentMonth] = useState(0)
	const [averageBasket, setAverageBasket] = useState(0);

	const changeCollaboratorFilter = (event) => {
		if (event === "Tous les collaborateurs") {
			setUserFilter("")
		} else {
			setUserFilter(event)
		}
	}
	const getTransformRateToday = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getTransformRate()
		} else {
			request = await CollaboratorService.getCollaboratorTransformRate(userFilter, {})
		}

		if (request.request.status === 200) {
			setTransformRateToday(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du taux de transformation du jour")
		}
	}

	const getTransformRateTotal = async () => {
		let request
		if (userFilter === "") {
			request = await KpiService.getTransformRate({ dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await CollaboratorService.getCollaboratorTransformRate(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}

		if (request.request.status === 200) {
			setTransformRateTotal(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du taux de transformation total")
		}
	}

	const getTransformLastMonth = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getTransformRate({ dateStart: dateMonthLater, dateEnd: currentDate })
		} else {
			request = await CollaboratorService.getCollaboratorTransformRate(userFilter, { dateStart: dateMonthLater, dateEnd: currentDate })
		}

		if (request.request.status === 200) {
			setTransformRateLastMonth(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du taux de transformation du dernier mois")
		}
	}


	const getRevenue = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getRevenue({ dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await CollaboratorService.getCollaboratorRevenue(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}
		if (request.request.status === 200) {
			setRevenue(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du revenue")
		}
	}

	const getRevenueToday = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getRevenue({
				dateStart: currentDate,
				dateEnd: currentDate
			})
		} else {
			request = await CollaboratorService.getCollaboratorRevenue(userFilter, {
				dateStart: currentDate,
				dateEnd: currentDate
			})
		}
		if (request.request.status === 200) {
			setRevenueToday(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du revenue du jour")
		}
	}

	const getRevenueCurrentMonth = async () => {
		let request

		if (userFilter === "") {
			request = await KpiService.getRevenue({
				dateStart: startDateCurrentMonth,
				dateEnd: currentDate
			})
		} else {
			request = await CollaboratorService.getCollaboratorRevenue(userFilter, {
				dateStart: startDateCurrentMonth,
				dateEnd: currentDate
			})
		}

		if (request.request.status === 200) {
			setRevenueCurrentMonth(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du taux de transformation du mois actuel")
		}
	}

	const getAverageBasket = async () => {
		let request
		if (userFilter === "") {
			request = await KpiService.getAverageBasket({ dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await CollaboratorService.getCollaboratorAverageBasket(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}

		if (request.request.status === 200) {
			let total = parseFloat(request.data.total);
			setAverageBasket(total.toFixed(2))
		} else {
			toast.error("Une erreur est survenue lors de la récupération du panier moyen")
		}
	}

	const getRevenueByMonth = async () => {
		let request
		if (userFilter === "") {
			request = await KpiService.getRevenueByMonth({ dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await CollaboratorService.getCollaboratorRevenueByMonth(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}
		if (request.request.status === 200) {
			setMonthlyObjective(request.data.monthlyObjectives.toFixed(2))
			let data = request.data.revenues
			data.sort((a, b) => parseInt(a.month) - parseInt(b.month))
			data.sort((a, b) => parseInt(a.year) - parseInt(b.year))
			setRevenueByMonth([])
			data.map((item) => {
				if (item.total !== 0) {
					setRevenueByMonth(revenueByMonth => [...revenueByMonth, {
						date: month[item.month - 1],
						data: parseInt(item.total)
					}])
				}
			})

		} else {
			toast.error("Une erreur est survenue lors de la récupération des revenues par mois")
		}
	}

	const getCollaborators = async () => {
		const URL = ConvertObjectToUrlParams("coms", {})
		const request = await CollaboratorService.getCollaborators(URL)
		if (request.request.status === 200) {
			let coms = request.data['hydra:member']
			let availableComs = []
			coms.forEach(com => {
				if (com.roles[0] !== "ROLE_COM_DIR") {
					availableComs.push(com)
				}
			})
			setCollaborators(availableComs)
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	const [signedQuotesCount, setSignedQuotesCount] = useState('')
	const getSignedQuotesCount = async () => {
		let request = ""
		if (userFilter === "") {
			request = await KpiService.getSignedQuotesCount({ dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await KpiService.getCollaborateurSignedQuotesCount(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}
		if (request.request.status === 200) {
			setSignedQuotesCount(request.data.total)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de devis signés")
		}
	}

	const [processingQuotesCount, setProcessingQuotesCount] = useState('')
	const getProcessingQuotesCount = async () => {
		let request = ""
		if (userFilter === "") {
			request = await KpiService.getProcessingQuotesCount({ dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await KpiService.getCollaborateurProcessingQuotesCount(userFilter, {
				dateStart: dateStart,
				dateEnd: dateEnd
			})
		}
		if (request.request.status === 200) {
			setProcessingQuotesCount(request.data.total)
		} else {
		}
	}

	const [requestsCount, setRequestsCount] = useState("")
	const getRequestsCount = async () => {
		let request;
		if (userFilter === "") {
			request = await KpiService.getRequestsCount({ dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await KpiService.getCollaboratorRequestsCount(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}
		if (request.request.status === 200) {
			let total = 0
			request.data.map((request) => {
				total += request.total
			})
			setRequestsCount(total)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de demande entrantes")
		}
	}

	const [processingQuotes, setProcessingQuotes] = useState('')
	const getProcessingQuotes = async () => {
		let request;
		if (userFilter === "") {
			request = await KpiService.getProcessingQuotes({ dateStart: dateStart, dateEnd: dateEnd })
		} else {
			request = await KpiService.getCollaborateurProcessingQuotes(userFilter, { dateStart: dateStart, dateEnd: dateEnd })
		}
		if (request.request.status === 200) {
			setProcessingQuotes(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du montant des devis édités (HT) ")
		}
	}


	useEffect(() => {
		getCollaborators()
		getTransformRateToday()
		getTransformRateTotal()
		getTransformLastMonth()
		getRevenue()
		getRevenueToday()
		getRevenueCurrentMonth()
		getRevenueByMonth()
		getAverageBasket()
		getSignedQuotesCount()
		getProcessingQuotesCount()
		getRequestsCount()
		getProcessingQuotes()
	}, [currentDate, dateMonthLater, startDateCurrentMonth, date, userFilter])

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}
	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	const changeDateMonthLater = (date) => {
		if (date === "Toutes durées") {
			setDateStart("")
			setDateEnd("")
			setDate(new Date())
		} else {
			let dateEnd = moment().format("YYYY-MM-DD")
			let dateBegin = moment().subtract(date, 'months').format("YYYY-MM-DD")
			setDate([new Date(dateBegin), new Date(dateEnd)])
			setDateStart(dateBegin)
			setDateEnd(dateEnd)
		}
	}

	return (
		<>
			<KpisViewFilters
				collaborators={collaborators} onChangeDate={event => changeDate(event)}
				onChangeDuree={(event) => changeDateMonthLater(event.target.value)}
				onChangeCollaborator={(event) => changeCollaboratorFilter(event.target.value)} date={date}
				resetDateSelected={resetDate}
			/>
			<div className={"mbe-section"}>

				<KpisViewTitle />
				<KpisViewCharts
					dateStart={dateStart}
					monthlyObjective={monthlyObjective}
					transformRateTotal={transformRateTotal}
					transformRateLastMonth={transformRateLastMonth}
					transformRateToday={transformRateToday}
					revenue={revenue}
					revenueToday={revenueToday}
					revenueCurrentMonth={revenueCurrentMonth}
					averageBasket={averageBasket}
					revenueByMonth={revenueByMonth}
					signedQuotesCount={signedQuotesCount}
					processingQuotesCount={processingQuotesCount}
					requestsCount={requestsCount}
					processingQuotes={processingQuotes}
				/>
			</div>
		</>
	)

}
export default ManagerKPIView;
