import React, { useState } from "react";
import HomeCustomerBannerClose from "./Partials/BannerClose";
import HomeCustomerBannerOpen from "./Partials/BannerOpen";

const HomeCustomerBanner = () => {

  const [isBannerClose, setIsBannerClose] = useState(true);
  const toggleBanner = () => {
    setIsBannerClose(!isBannerClose);
  }

  return (
    <>
      {
        isBannerClose ? (
          <HomeCustomerBannerClose toggleBanner={toggleBanner}/>
        ) : (
          <HomeCustomerBannerOpen toggleBanner={toggleBanner}/>
        )
      }
    </>
  );
}

export default HomeCustomerBanner;