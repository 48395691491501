import React, {useEffect, useState} from 'react';
import TitleIconNavLink from "../../../../../../../components/Atomes/Title/TitleIconNavLink";
import {HOME_PATH} from "../../../../../../../config/UrlConstants";
import DirRespService from "../../../../../../../services/Ing/DirResp/DirRespService";
import {useParams} from "react-router-dom";
import TabNavigationStudyFolder from "../../Partials/TabNavigationStudyFolder";
import StudyFolderMenu from "../../Partials/StudyFolderMenu";
import Skeleton from "react-loading-skeleton";
import StudyFolderMenuShowElement from "../../Partials/StudyFolderMenuShowElement";
import PopupDeleteMediaAdmin from "../../../../../Admin/DocumentView/Popup/PopupDeleteMedia";
import {toast} from "react-toastify";
import PopUpInformations from "../../Partials/PopUpInformations";

const StudyFolderAnnexe = () => {

	const [showInformation, setShowInformation] = useState(false)
	const [studyFolderStep] = useState("ANNEXE")
	const [showPopupDelete, setShowPopupDelete] = useState(false);
	const [mediaToDelete, setMediaToDelete] = useState(null);
	const [change, setChange] = useState(false);


	const [content, setContent] = useState("");

	const [loading, setLoading] = useState(true);
	const [loadingAnnexe, setLoadingAnnexe] = useState(true);
	const [allAnnexe, setAllAnnexe] = useState([]);

	const {id} = useParams()

	const getProjectsInformations = async () => {
		const request = await DirRespService.getStudyFolderInformations(id)
		if (request.status === 200) {
			setContent(request.data)
			setLoading(false)
		}
	}

	const getAnnexe = async () => {
		const request = await DirRespService.getAnnexe(id)
		if (request.status === 200) {
			setAllAnnexe(request.data)
			setLoadingAnnexe(false)
		}
	}

	const deleteMedia = async () => {
		const mediaWithoutCurrentSelected = allAnnexe.appendicesIri.filter((media) => media !== `/api/medias/${mediaToDelete}`)
		const newMedia = mediaWithoutCurrentSelected
		setAllAnnexe({...allAnnexe, appendicesIri: newMedia})
		const request = await DirRespService.patchAnnexe(id, {appendicesIri: newMedia})
		if (request.status === 200) {
			toast.success("Document supprimé avec succès");
			closePopup()
			setChange(!change)
		} else {
			toast.error("Une erreur est survenue");
			closePopup()
		}

	}

	const closePopup = () => {
		setShowPopupDelete(false);
	}

	useEffect(() => {
		setLoadingAnnexe(true)
		setLoading(true)
		getProjectsInformations()
		getAnnexe()
	}, [id, change])

	const handleDeleteFile = async (id) => {
		setMediaToDelete(id);
		setShowPopupDelete(true);
	}

	return (
		<>
			{
				loading || loadingAnnexe ? <Skeleton height={500}/> : (
					<div className={"section-study-folder-cctp mbe-section"}>
						<div className={"section-study-folder-cctp-title"}>
							<TitleIconNavLink title={`${content.project.client.niceNames} - ${content.project.address.street}, ${content.project.address.nicePostcode}, ${content.project.address.city}`} link={HOME_PATH} information={true}
							                  onClick={() => setShowInformation(true)}/>
						</div>

						<TabNavigationStudyFolder theStep={studyFolderStep} id={id} needExecutionClass={content.needExecutionClass} needCCTP={content.needCctp}
						                          needSummary={content.needSummary}/>

						<div className={"section-study-folder-cctp-container"}>
							<div className={"section-study-folder-cctp-container-editor"}>
								<div className={"section-study-folder-cctp-container-editor-title"}>
									<h3 className={"text-primary color-primary"}>Annexe</h3>
								</div>

								<div className={"section-study-folder-cctp-container-editor-content-annexe"}>
									{
										allAnnexe.appendices.length > 0 ? (
											<>
												{
													<StudyFolderMenuShowElement fakeDataProject={allAnnexe.appendices}
													                            onClickDelete={handleDeleteFile}
													                            className={"color-black"}

													/>
												}
											</>
										) : (
											<div className={"section-study-folder-cctp-container-editor-content-empty"}>
												<h3 className={"text-primary color-primary"}>Aucune annexe</h3>
											</div>
										)
									}
								</div>
							</div>
							<StudyFolderMenu content={content} projectId={content.project.id} change={() => setChange(!change)}/>
						</div>
						<PopupDeleteMediaAdmin
							showPopup={showPopupDelete}
							closeAction={closePopup}
							validAction={deleteMedia}
						/>

						<PopUpInformations
							show={showInformation}
							onClick={() => setShowInformation(false)}
							large={false}
							title={"Informations projet"}
							content={content}
						/>
					</div>
				)
			}
		</>
	);
};

export default StudyFolderAnnexe;
