import React from 'react'

const ProjectNav = ({step, visit}) => {

  const actualStep = step

  const steps = [
    'Signature du devis',
    'Paiement accompte',
    'Prise de rendez-vous',
    'Principe de structure',
    'Édition dossier d\'étude structure',
    'Paiement final',
    /* 'Validation dossier d\'étude structure', */
    'Rendu dossier d\'étude structure',
  ]

  if (visit === "" || visit === undefined) {
    steps.splice(2, 1)
  }

  return (
    <>
      <div className='project-container-nav'>
        {steps.map((step, index) => {
          return (
            <div className={`project-container-nav__item ${index < actualStep ? 'valid' : index === actualStep ? 'active' : ''}`}
              key={index}
            >
              <span className='text-tertiary'>{step}</span>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ProjectNav;