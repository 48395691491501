import React from 'react';

const InputCheckBox = ({name, id, label, styleSelected, onClick}) => {

	return (<div className={"form-row-label-input form-row-label-input-checkbox "}>
		<input id={id} onClick={onClick} className={styleSelected} type={"checkbox"}/>
		<label className={"text-primary color-blue-jeans"} htmlFor={name}>{label}</label>
	</div>);
};

export default InputCheckBox;
