import React, {useContext} from "react";
import {StateRequest} from "../../../views/Pages/Coms/Dir/Home/Home";
import ApprovalCircuitService from "../../../services/ApprovalCircuitService";
import {StateRequest as StateRequestApprovalCircuit} from "../../../views/Pages/Coms/Dir/ApprovalCircuit/ApprovalCircuitView";

const PopUpValidApprovalCircuit = ({id, onClick}) => {
	const { stateRequest, setStateRequest } = useContext(StateRequest["_currentValue"] === undefined ? StateRequestApprovalCircuit  : StateRequest);

	const handleSubmit = async () => {
		const request = await ApprovalCircuitService.validateApprovalCircuit(id)
		onClick()
		if (request.request.status == 200){
			setStateRequest('success add')
		}else {
			setStateRequest('error')
		}
	}

	const handleRefuse = async () => {
		const request = await ApprovalCircuitService.refuseApprovalCircuit(id)
		onClick()
		if (request.request.status == 200){
			setStateRequest('success delete')
		}else {
			setStateRequest('error')
		}
	}

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"}/>
				<img src={"/images/medias/popup.svg"} alt="img form"/>
				<h4>Voulez-vous valider <br/>la remise ?</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={handleRefuse}>Non</button>
					<button className={"btn btn-delete"} onClick={handleSubmit}>Oui</button>
				</div>
			</div>
		</div>
	);
}

export default PopUpValidApprovalCircuit