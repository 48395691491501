import React, { useEffect, useState } from 'react';
import PopUpUsersAdmin from "../../../../components/Molecules/PopUp/PopUpUsersAdmin";
import PopUpForm from "../../../../components/Molecules/PopUp/PopUpForm";
import FormEditUsers from "../../../../components/Molecules/Form/FormEditUserAdmin/FormEditUsers";
import FormEditPassword from '../../../../components/Molecules/Form/FormEditUserAdmin/FormEditPassword';

const UsersActions = ({ cellInfo }) => {
	const [isActive, setIsActive] = useState(false);
	const [deleteUser, setDeleteUser] = useState("");
	const [isMdpActive, setIsMdpActive] = useState(false);
	const [name, setName] = useState("");

	useEffect(() => {
		return () => {
			setName(`${cellInfo.genderString} ${cellInfo.firstname} ${cellInfo.lastname}`)
		};
	}, [isActive]);

	return (
		<>
			<div className={"actions-controller"}>
				<a className="btn-little btn-default-primary color-primary" onClick={() => {
					setIsMdpActive(true)
				}}>
					Modifier mot de passe
				</a>
				<img src={"/images/pictos/pencil-blue.svg"} title={"Clôturer le dossier"} onClick={() => {
					setIsActive(true)
					setDeleteUser("edit")
				}} />

				<img src={"/images/pictos/red-cross.svg"} title={"Télécharger le dossier"} onClick={() => {
					setIsActive(true)
					setDeleteUser("delete")
				}} />

			</div>

			{
				isMdpActive ? (
					<PopUpForm title={"Modification de mot de passe"} large={true}
						form={<FormEditPassword name={`${cellInfo.niceGender} ${cellInfo.niceNames}`} setIsActive={setIsMdpActive} id={cellInfo.id} />}
						onClick={() => setIsMdpActive(false)} show={isMdpActive} />
				) : null
			}

			{
				isActive ? (
					deleteUser === "delete" ? (
						<PopUpUsersAdmin onClick={() => setIsActive(false)} idUser={cellInfo.id} />
					) : (
						<PopUpForm title={"Modifier un utilisateur"} large={true}
							form={<FormEditUsers setIsActive={setIsActive} id={cellInfo.id} />}
							onClick={() => setIsActive(false)} show={isActive && deleteUser === "edit"} />
					)
				) : null
			}
		</>
	);
};

export default UsersActions;
