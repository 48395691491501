import React from 'react';
import ButtonPopupDatepicker from "../../Atomes/Buttons/ButtonPopupDatepicker";

const FilterDatePicker = ({
														onChangeDate,
														date,
														resetDateSelected
													}) => {
	return (
		<div className={"filter-search-select-button"}>
			<ButtonPopupDatepicker date={date} onChange={onChangeDate} resetDateSelected={resetDateSelected}/>
		</div>
	);
};

export default FilterDatePicker;
