import React from 'react';

import KpiSimpleCard from '../../../../../../components/Atomes/Kpis/KpiSimpleCard';
import ChartBar from "../../../../../../components/Atomes/Charts/ChartBar";
import ChartDonut from "../../../../../../components/Atomes/Charts/ChartDonut";

const KpisViewCharts = ({ transformRateTotal, transformRateToday, transformRateLastMonth,
	revenueToday, revenueCurrentMonth, revenueByMonth, monthlyObjective, dateStart,
	requestsCount, processingQuotesCount, signedQuotesCount, processingQuotes, averageBasketByMonth
}) => {

	return (
		<div className={"section-kpi-container"}>

			<ChartBar className={"chiffre-affaires custom-chart"} title={"Chiffre d'Affaires"}
				legend={[{ title: "CA Moyen", color: "purple" }, {
					title: "Objectif Mensuel", color: "green"
				}]}
				content={revenueByMonth}
				monthlyObjective={monthlyObjective}
			/>

			<ChartBar className={"chiffre-affaires custom-chart"} title={"Panier Moyen"}
				legend={[{ title: "Panier Moyen", color: "purple" }]}
				content={averageBasketByMonth}
				monthlyObjective={0}
			/>

			{
				dateStart === "" && revenueCurrentMonth.total !== null ?
					(
						<ChartDonut className={"taux-transformation custom-chart"}
							data1={revenueCurrentMonth.total * (monthlyObjective / revenueCurrentMonth.total)}
							data2={revenueCurrentMonth.total}
							rate={monthlyObjective / revenueCurrentMonth.total}
							legend={[{ title: "CA du mois en cours", color: "primary" }, {
								title: "Objectif mensuel", color: "blue-jeans"
							}]}
							title={"% atteinte objectif mensuel"}
						/>
					) : null
			}

			{
				transformRateToday.total && dateStart === "" ?
					(
						<ChartDonut className={"taux-transformation custom-chart"}
							data1={transformRateToday.total * transformRateToday.rate}
							data2={transformRateToday.total} rate={transformRateToday.rate}
							legend={[{ title: "Devis signés", color: "primary" }, {
								title: "Devis en cours d'édition", color: "blue-jeans"
							}]} title={"Taux de transformation journalier"}
						/>
					) : null
			}

			{
				transformRateLastMonth.total && dateStart === "" ?
					(
						<ChartDonut className={"taux-transformation custom-chart"}
							data1={transformRateLastMonth.total * transformRateLastMonth.rate}
							data2={transformRateLastMonth.total} rate={transformRateLastMonth.rate}
							legend={[{ title: "Devis signés", color: "primary" }, {
								title: "Devis en cours d'édition", color: "blue-jeans"
							}]} title={"Taux de transformation mensuel"}
						/>
					) : (
						<ChartDonut className={"taux-transformation custom-chart"}
							data1={transformRateTotal.total * transformRateTotal.rate}
							data2={transformRateTotal.total} rate={transformRateTotal.rate}
							legend={[{ title: "Devis validés", color: "primary" }, {
								title: "Devis en cours d'édition", color: "blue-jeans"
							}]} title={"Taux de transformation total"}
						/>
					)
			}

			{/* {
				dateStart === "" ? (
					<ChartDonut className={"taux-transformation custom-chart"}
						data1={transformRateTotal.total * transformRateTotal.rate}
						data2={transformRateTotal.total} rate={transformRateTotal.rate}
						legend={[{ title: "Devis validés", color: "primary" }, {
							title: "Devis totaux", color: "blue-jeans"
						}]} title={"Taux de transformation total"}
					/>
				) : <div></div>
			} */}

			<div className="kpi-container-simpleCards element-kpi-l">
				<KpiSimpleCard title={"Devis en cours d'édition"} value={processingQuotesCount ? processingQuotesCount : 0} />
				<KpiSimpleCard title={"Devis signés"} value={signedQuotesCount ? signedQuotesCount : 0} />
			</div>

			<div className="kpi-container-simpleCards element-kpi-l">
				<KpiSimpleCard title={"Demandes entrantes"} value={requestsCount ? requestsCount : 0} />
				<KpiSimpleCard title={"Montant des devis édités (HT)"} value={processingQuotes.total ? `${processingQuotes.total} €` : 0} />
			</div>

		</div >
	)

}
export default KpisViewCharts;
