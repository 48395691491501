import React from 'react';

const PopUpGenerateCCTP = ({onClick, handleSubmit}) => {
	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"}/>
				<h4 className={"text-primary color-black"}>Le CCTP est sur le point d’être généré. Êtes-vous sûr de vouloir le générer ?<br/>Cette action est irréversible.</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={onClick}>Non, je vais revérifier</button>
					<button className={"btn btn-send"} onClick={handleSubmit}>Oui, générer</button>
				</div>
			</div>
		</div>
	);
};

export default PopUpGenerateCCTP;
