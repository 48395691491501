import * as Yup from "yup";

export const MegaroutineSchema = Yup.object().shape({
  title: Yup.string().required("Ce champ est requis"),
  triggerTitle: Yup.string().required("Ce champ est requis"),
  macroroutines: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Ce champ est requis"),
    })
  ),
});

