import React, { useContext } from "react";
import ProjectService from "../../../services/ProjectService";
import { toast } from "react-toastify";
import { StateRequest } from "../../../views/Pages/Admin/Projects/ProjectsAdmin";

const PopUpProjectsAdmin = ({ id, onClick }) => {
	const { setStateRequest } = useContext(StateRequest);

	const handleSubmit = async () => {
		const request = await ProjectService.ClosingTheProject(id)
		if (request.request.status === 200) {
			setStateRequest("success cloture")
		} else {
			toast.error("Une erreur est survenue")
		}
		onClick()
	}

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"} />
				<img src={"/images/medias/popup.svg"} alt="img form" />
				<h4>Voulez-vous vraiment clôturer le projet ?</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={() => onClick()}>Non</button>
					<button className={"btn btn-delete"} onClick={(event) => handleSubmit(event)}>Oui</button>
				</div>
			</div>
		</div>
	);
}

export default PopUpProjectsAdmin
