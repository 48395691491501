import React from 'react';
import ButtonWithOnClickAction from "../../../../../../components/Atomes/Buttons/ButtonWithOnClickAction";
import ButtonSubmit from '../../../../../../components/Atomes/Buttons/ButtonSubmit';
import exportFileService from "../../../../../../services/ExportFileService";

const TableListingHeading = ({onClick, title, region, dark, search, titleButton}) => {

	const downloadFile = () => {
		exportFileService.exportFile("/engineer/users", 'export-users', {region: region}, "xlsx")
	}


	return (
		<div className='collaborators-tableListingHeading'>
			<h3 className={"color-primary"}>{title}</h3>
			<div className='collaborators-tableListingHeading-btns'>
				<ButtonSubmit onClick={downloadFile} title={"Exporter"} isActive={false}
				              styleButton={"btn-default btn-default-blue btn-download color-primary"}/>
				<ButtonWithOnClickAction onClick={onClick} isActive={true}
				                         title={titleButton ? titleButton : "Ajouter un collaborateur"}
				                         styleButton={"btn-default" + (dark ? " btn-default-blue btn-download" : " btn-default-primary")}/>
			</div>
		</div>
	);
};

export default TableListingHeading;
