import React from "react";
import {SortingTable} from "../../../../../../components/Molecules/Tables/SortingTable";
import {BUSINESS_OBJECTIVES_COLUMNS} from "../../../../../../config/ArrayColumns/BusinessObjectivesColumns";
import exportFileService from '../../../../../../services/ExportFileService';
import ButtonWithOnClickAction from "../../../../../../components/Atomes/Buttons/ButtonWithOnClickAction";


const CommercialObjectivesArray = ({ quotes, dateStart, dateEnd, userFilterId }) => {
	const allQuotes = quotes
	const downloadFile = () => {
		exportFileService.exportFile(`/sales/${userFilterId}/objectives`, 'export-objectifs-commerciaux', {
			dateStart: dateStart,
			dateEnd: dateEnd
		}, "xlsx")
	}

	return (
		<div className={"section-commercial-objectives-array mbe-section"}>
			<div className={"section-commercial-objectives-array-title"}>
				<h3 className={"color-primary"}>Objectifs commerciaux</h3>
				{allQuotes["hydra:member"].length > 0 &&
					<div className='section-price-calculated-button'>
						<ButtonWithOnClickAction onClick={downloadFile} title={"Exporter"} isActive={true}
						                         styleButton={"btn-default btn-default-blue btn-download"}/>
					</div>
				}
			</div>

			<div className={"section-commercial-objectives-array-container"}>
				{
					allQuotes["hydra:member"].length > 0 ? (
						<SortingTable dataTable={allQuotes} columnsTable={BUSINESS_OBJECTIVES_COLUMNS}/>
					) : (
						<h4>Aucun résultat pour l'utilisateur sélectionné</h4>
					)
				}
			</div>
		</div>

	);
};
export default CommercialObjectivesArray;
