import React, { createContext, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import Skeleton from 'react-loading-skeleton';
import Select from "../../../../../components/Atomes/Select/Select";
import TableListing from "./Partials/CollaboratorsListingTable";
import TableListingHeading from "./Partials/CollaboratorsHeadingListingTable";
import CollaboratorDetailView from "../../../Coms/Dir/Collaborators/Partials/CollaboratorDetailView";
import PopUpForm from "../../../../../components/Molecules/PopUp/PopUpForm";
import FormAddCollaborator from "./Forms/FormAddCollaborator";
import DirRespService from "../../../../../services/Ing/DirResp/DirRespService";
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

export const StateRequest = createContext()
const CollaboratorsViewIngDir = () => {
	const [stateRequest, setStateRequest] = useState("");
	const [showPopUp, setShowPopUp] = useState(false);
	const [collaborators, setCollaborators] = useState([]);
	const [regionFilter, setRegionFilter] = useState("");

	const [dataLoading, setDataLoading] = useState(false);

	const DisplayCreateModalCollaborator = () => {
		setShowPopUp(!showPopUp)
	}

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("Le collaborateur a bien été ajouté")
		} else if (type === "success edit") {
			toast.success("Le collaborateur a bien été modifié")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	const changeRegionFilter = (event) => {

		if (event.target.value === "Toutes les agences") {
			setRegionFilter("")
		} else {
			setRegionFilter(event.target.value)
		}
	}

	const getCollaborators = async () => {
		setDataLoading(true)
		const request = await DirRespService.getAllCollaborators({ regions: regionFilter, pagination: "false" })
		if (request.request.status === 200) {
			let ings = request.data["hydra:member"]
			ings.forEach((ing) => {
				if (!ing.objective) ing.objective = 0
			})
			setCollaborators(request.data["hydra:member"])
			setDataLoading(false)
		} else {
			toast.error("Une erreur est survenue lors de la récupération de la liste des collaborateurs")
		}
	}

	useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getCollaborators()
	}, [stateRequest, regionFilter]);

	return (
		<StateRequest.Provider value={{ stateRequest, setStateRequest }}>
			<Select onChange={changeRegionFilter} defaultValue={"Toutes les agences"} data={dataRegionsArray}
				value={dataRegionsArray} styleSelect={"select-custom-secondary"} />
			<TableListingHeading onClick={DisplayCreateModalCollaborator} title={"Collaborateurs"} />

			{
				dataLoading ?
					(
						<Skeleton height={500} />
					) : (
						<TableListing collaborators={collaborators} />
					)
			}

			{
				!dataLoading && (
					<CollaboratorDetailView collaborators={collaborators} />
				)
			}

			<PopUpForm large={true} title={"Ajouter un collaborateur"}
				form={<FormAddCollaborator setShowPopUp={setShowPopUp} />} onClick={DisplayCreateModalCollaborator}
				show={showPopUp} />
		</StateRequest.Provider>
	);
};

export default CollaboratorsViewIngDir;
