import React, {useEffect, useState} from 'react';
import TitleSection from "../../../../../../../components/Atomes/Title/TitleSection";
import InputCheckBox from "../../../../../../../components/Atomes/Inputs/InputCheckBox";
import InputLabelErrorMessage from "../../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import {useFormikContext} from "formik";


const FormDevisInformationClient = ({project}) => {

	const {setFieldValue, values} = useFormikContext()
	const [sameShippingAddress, setSameShippingAddress] = useState(false);

	const toggleSameAddress = () => {
		setSameShippingAddress(!sameShippingAddress)
	}

	useEffect(() => {
		if (sameShippingAddress) {
			setFieldValue('client.billingAddress', values.address)
		} else {
			if (project.client.billingAddress) {
				setFieldValue('client.billingAddress.street', project.client.billingAddress.street)
				setFieldValue('client.billingAddress.city', project.client.billingAddress.city)
				setFieldValue('client.billingAddress.postcode', project.client.billingAddress.postcode)
			}
		}
	}, [sameShippingAddress]);


	/*Initial Values*/
	useEffect(() => {
		if (project.client) {
			setFieldValue('client.firstname', project.client.firstname)
			setFieldValue('client.gender', project.client.gender)
			if (project.description) {
				setFieldValue('description', project.description)
			}
			if(project.phase) {
				setFieldValue("phase", project.phase)
			}
			setFieldValue('client.lastname', project.client.lastname)
			setFieldValue('client.email', project.client.email)
			setFieldValue('client.phone', project.client.phone)
			if(project.address) {
				setFieldValue('address.street', project.address.street)
				setFieldValue('address.city', project.address.city)
				setFieldValue('address.postcode', project.address.postcode)
			}
			
			if (project.client.billingAddress) {
				setFieldValue('client.billingAddress.street', project.client.billingAddress.street)
				setFieldValue('client.billingAddress.city', project.client.billingAddress.city)
				setFieldValue('client.billingAddress.postcode', project.client.billingAddress.postcode)
			}
		}
	}, [project]);


	/*Initial Values for agency if is gender is S*/
	useEffect(() => {
		if (project.client.agencyName) {
			setFieldValue('client.agencyName', project.client.agencyName)
		}
	}, [project]);


	/*UseState of gender to make conditionnal return if is Société -> return code agency input*/
	const [gender, setGender] = useState(values.client.gender);
	useEffect(() => {
		setGender(values.client.gender)
	}, [values.client.gender]);

	return (
	<div className={"form-devis-container-information-client"}>
		<div className={"form-section-title"}>
			<TitleSection title={"Informations administrations"}/>
		</div>
		<div className={"form-devis-container-information-client-start"}>

			<div className={"form-row-element"}>
				<InputLabelErrorMessage type={"text"} name={"description"} label={"Description du projet"}/>
			</div>
			<div className={"form-row-element form-row-single-element form-row-label-input-width-20"}>
				<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} name={"client.gender"}
				                        data={[{value: "M", label: "Monsieur"}, {value: "W", label: "Madame"}, {
					                        value: "Miss",
					                        label: "Mademoiselle"
				                        }, {value: "NA", label: "Non genré"}, {value: "S", label: "Raison sociale"}]}
				                        label={"Civilité"}/>
			</div>
			{gender === "S" ?
				<div className={"form-row-element form-row-single-element"}><InputLabelErrorMessage label={"Société"}
				                                                                                    type={"text"}
				                                                                                    name={"client.agencyName"}/>
				</div> : ""}
			<div className={"form-row-element form-row-two-elements"}>
				<InputLabelErrorMessage type={"text"} name={"client.lastname"} label={"Nom"}/>
				<InputLabelErrorMessage type={"text"} name={"client.firstname"} label={"Prénom"}/>
			</div>
			<div className={"form-row-element form-row-two-elements"}>
				<InputLabelErrorMessage type={"text"} name={"client.email"} label={"Adresse e-mail"}/>
				<InputLabelErrorMessage type={"text"} name={"client.phone"} label={"Téléphone"}/>
			</div>
			<div className={"form-row-element form-row-single-element"}>
				<InputLabelErrorMessage type={"text"} name={"address.street"} label={"Adresse du projet"}/>
			</div>
			<div className={"form-row-element form-row-two-elements"}>
				<InputLabelErrorMessage type={"number"} name={"address.postcode"} label={"Code postal"}/>
				<InputLabelErrorMessage type={"text"} name={"address.city"} label={"Ville"}/>
			</div>


			{/*Billing Address*/}
			<div className={"form-row-element form-row-single-element"}>
				<InputCheckBox
					styleSelectedLabel={"color-dark-grey"}
					onClick={(e) => toggleSameAddress()}
					name={"billingAddress"}
					id={"billingAddress"}
					label={"Adresse de facturation identique à l’adresse du projet"}
				/>
			</div>
			{sameShippingAddress ? <></> : <>
				<div className={"form-section-title"}>
					<TitleSection title={"Adresse de facturation"}/>
				</div>
				<div className={"form-row-element form-row-single-element"}>
					<InputLabelErrorMessage type={"text"} name={"client.billingAddress.street"} label={"Adresse"}/>
				</div>
				<div className={"form-row-element form-row-two-elements"}>
					<InputLabelErrorMessage type={"number"} name={"client.billingAddress.postcode"} label={"Code postal"}/>
					<InputLabelErrorMessage type={"text"} name={"client.billingAddress.city"} label={"Ville"}/>
				</div>
			</>}
		</div>
	</div>
	);
};

export default FormDevisInformationClient;
