import React, { createContext, useEffect, useState } from "react";
import FilterTwoSelectsDatepicker from "../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";
import ApprovalCircuitService from "../../../../services/ApprovalCircuitService";
import ToDo from "./Partials/ToDo";
import AccessToFolder from "../../Coms/Dir/Home/Partials/AccessToFolder";
import TableAdminHome from "./Partials/TableAdminHome";
import { toast } from "react-toastify";
import { ConvertObjectToUrlParams } from "../../../../utils/StringUtils";
import CollaboratorService from "../../../../services/CollaboratorService";
import moment from "moment";
import KpiAdminService from "../../../../services/KpiAdminService";
import OverviewAdmin from "../KPI/partials/overviewAdmin";
import {dataRegionsArray} from "../../../../utils/RegionsUtils";

export const StateRequest = createContext()

const HomeAdmin = () => {
	const options = dataRegionsArray
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [date, setDate] = useState(new Date());
	const [userFilter, setUserFilter] = useState("")
	const [approvalCircuit, setApprovalCircuit] = useState([]);
	const [region, setRegion] = useState("");
	const [collaborators, setCollaborators] = useState([]);
	const [stateRequest, setStateRequest] = useState("");

	// Const for kpi
	const [numberProjectRecouvrement, setNumberProjectRecouvrement] = useState(0);
	const [projectInProgress, setProjectInProgress] = useState(0);
	const [projectValidated, setProjectValidated] = useState(0);
	const [numberProjectWaitingPayment, setNumberProjectWaitingPayment] = useState(0);
	const [numberProjectInLate, setNumberProjectInLate] = useState(0);

	const getProjectInProgress = async () => {
		const request = await KpiAdminService.getProjectInProgress({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"users.id": userFilter
		})
		if (request.request.status === 200) {
			setProjectInProgress(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet en cours")
		}
	}

	const getNumberProjectRecouvrement = async () => {
		const request = await KpiAdminService.getNumberProjectRecouvrement({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"users.id": userFilter
		})
		if (request.request.status === 200) {

			setNumberProjectRecouvrement(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet en recouvrement")
		}
	}

	const getProjectValidated = async () => {
		const request = await KpiAdminService.getFolderDone({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"users.id": userFilter
		})
		if (request.request.status === 200) {
			setProjectValidated(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet validé")
		}
	}
	const getNumberProjectWaitingPayment = async () => {
		const request = await KpiAdminService.getNumberFolderWaitingPayment({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"users.id": userFilter
		})
		if (request.request.status === 200) {
			setNumberProjectWaitingPayment(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet en attente de paiement")
		}
	}
	const getNumberProjectInLate = async () => {
		const request = await KpiAdminService.getNumberFolderLate({
			region: region,
			"users.id": userFilter,
		})
		if (request.request.status === 200) {
			setNumberProjectInLate(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet en cours")
		}
	}

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}

	const getApprovalCircuit = async () => {
		const request = await ApprovalCircuitService.getAdminApprovalCircuit({
			region: region,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"users.id": userFilter
		})
		if (request.request.status === 200) {
			setApprovalCircuit(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des circuits de validation")
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}


	const DisplayToast = (type) => {
		if (type === "success add visit") {
			toast.success("Le créneau a bien été débloqué")
		} else if (type === "success add visit error") {
			toast.success("Le créneau a été refusé")
		} else if (type === "success add payment") {
			toast.success("Le paiement a bien été validé")
		} else if (type === "success add payment error") {
			toast.success("Le paiement a bien été refusé")
		} else if (type === "success cloture") {
			toast.success("Le dossier a bien été clôturé")
		} else if (type === "success letter") {
			toast.success("La lettre de recouvrement a bien été envoyée")
		} else if (type === "success refuse asset") {
			toast.success("La demande d'avoir a bien été refusée")
		} else if (type === "success add payment reminder") {
			toast.success("La relance de paiement a été prise en compte")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}


	const getCollaborators = async () => {
		const urlCom = ConvertObjectToUrlParams("coms", { regions: region })
		const request = await CollaboratorService.getCollaborators(urlCom)
		if (request.request.status === 200) {
			setCollaborators(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Tous les collaborateurs") {
			setUserFilter("")
		} else {
			setUserFilter(event.target.value)
		}
	}

	useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getApprovalCircuit()
		getCollaborators()

		//get KPI
		getNumberProjectRecouvrement()
		getProjectInProgress()
		getProjectValidated()
		getNumberProjectWaitingPayment()
		getNumberProjectInLate()

	}, [region, userFilter, date, stateRequest])

	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	return (
		<StateRequest.Provider value={{ stateRequest, setStateRequest }}>
			<FilterTwoSelectsDatepicker
				defaultValue={"Toutes les agences"}
				options={options}
				firstValue={options}
				secondDefaultValue={"Tous les collaborateurs"}
				secondValue={collaborators.map(collaborators => collaborators.id)}
				secondOptions={collaborators.map(collaborators => collaborators.lastname)}
				onChange={changeRegionFilter}
				onChangeDate={(date) => changeDate(date)}
				onSecondChange={changeCollaboratorFilter}
				date={date}
				resetDateSelected={resetDate}

			/>
			<OverviewAdmin numberProjectRecouvrement={numberProjectRecouvrement} numberProjectInLate={numberProjectInLate}
				dateEnd={dateEnd} numberProjectWaitingPayment={numberProjectWaitingPayment}
				projectValidated={projectValidated} projectInProgress={projectInProgress} />
			<ToDo approvalCircuit={approvalCircuit} />
			<TableAdminHome stateRequest={stateRequest} region={region} userFilter={userFilter} dateStart={dateStart}
				dateEnd={dateEnd} />
			<AccessToFolder />
		</StateRequest.Provider>
	);
};

export default HomeAdmin;
