import React, {useEffect, useState} from 'react';
import TitleSectionWithButton from "../../../../components/Molecules/Title/TitleSectionWithButton";
import Skeleton from "react-loading-skeleton";
import {SortingTable} from "../../../../components/Molecules/Tables/SortingTable";
import {PARTNER_STAT_COLUMNS} from "../../../../config/ArrayColumns/Admin/PartnersStatColumns";
import FilterDatePicker from "../../../../components/Molecules/Filter/FilterDatePicker";
import moment from "moment";
import {useParams} from "react-router-dom";
import PartnerService from "../../../../services/PartnerService";
import {toast} from "react-toastify";
import exportFileService from "../../../../services/ExportFileService";

const PartnersStats = () => {

	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [date, setDate] = useState(new Date());
	const [partnerLinkStats, setPartnerLinkStats] = useState([]);
	const [loading, setLoading] = useState(true)

	const {id} = useParams();

	const getPartnerLinkStats = async () => {
		const request = await PartnerService.getPartnerLinkStats(id, {
			pagination: "true",
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd
		})
		if (request.request.status === 200) {
			setPartnerLinkStats(request.data)
			setLoading(false)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des statistiques du lien partenaire")
		}
	}

	useEffect(() => {
		setLoading(true)
		getPartnerLinkStats()
	}, [id, dateStart, dateEnd]);

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}

	const downloadFile = () => {
		exportFileService.exportFile(`/stats/${id}/trackings`, 'export-partners-link-stat', {
			dateStart: dateStart,
			dateEnd: dateEnd
		}, "xlsx")
	}

	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	return (
		<>
			<FilterDatePicker
				date={date}
				onChangeDate={(date) => changeDate(date)}
				resetDateSelected={resetDate}
			/>
			<TitleSectionWithButton title={"Lien partenaires"} buttonTitle={"Exporter"} onClick={downloadFile} isActive={true}
			                        styledButton={"btn-default btn-default-blue btn-download color-primary"}/>
			<div className={"section-partners-stats-container"}>
				{loading ? <Skeleton height={1000}/> :
					<div className="mbe-section-row adminProjectsView-sortingTable"><SortingTable
						columnsTable={PARTNER_STAT_COLUMNS} dataTable={partnerLinkStats}/></div>}
			</div>
		</>
	);
};

export default PartnersStats;
