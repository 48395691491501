import React, {createContext, useEffect, useState} from "react";
import CardTeam from "../../../../../../components/Molecules/Cards/Teams/CardTeam";
import DirRespService from "../../../../../../services/Ing/DirResp/DirRespService";


const HomeIngResTeams = ({teams, timeToRealod}) => {
  return (
      <div className="projects-in-progress">
          <h3 className="color-primary">Projets à attribuer</h3>
          <div className="projects-in-progress-container projects-in-progress-engineer-tasks">
              {teams.map((team, index) => {
                      return (
                          <CardTeam
                              key={index}
                              team={team}
                              timeToRealod={timeToRealod}
                          />
                      )
                  }
              )}
          </div>
      </div>
  )
}

export default HomeIngResTeams;
