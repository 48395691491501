import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

import HomeCustomerProjectsHeading from "./Partials/ProjectsHeading";
import HomeCustomerProjectsListing from "./Partials/ProjectsListing";

import { CustomerService } from "../../../../../services/CustomerService";

export const FilterContext = createContext();

const HomeProjectsCustomer = () => {

  const [filterSelected, setFilterSelected] = useState("Tous les projets");

  const [search, setSearch] = useState(null);

  const getSearchValue = (value) => {
    setSearch(value);
  }

  const [projects, setProjects] = useState([]);
  const [errorProjects, setErrorProjects] = useState({
    status: false,
  });
  const getProjects = async () => {
    const request = await CustomerService.getProjects({ search: search });
    if (request.request.status === 200) {
      let datas = request.data['hydra:member'];
      if (datas.length > 0) {
        setProjects(datas);
        setErrorProjects(false);
      } else {
        setProjects([]);
        setErrorProjects({
          status: true,
          message: "Aucun projet n'a été trouvé"
        });
        toast.error("Aucun projet n'a été trouvé");
      }
    }
  }

  useEffect(() => {
    getProjects();
  }, [search]);

  return (
    <section className="mbe-section">
      <FilterContext.Provider value={{ filterSelected, setFilterSelected }}>

        <HomeCustomerProjectsHeading
          getSearchValue={getSearchValue}
        />

        <HomeCustomerProjectsListing
          projects={projects}
          errorProjects={errorProjects}
        />

      </FilterContext.Provider>
    </section>
  )
}

export default HomeProjectsCustomer;