import React from "react";

const MarketPlaceTab = ({setActiveSection,activeSection}) => {
	return (
		<div className={"admin-market-place-tab"}>
			<div className={"admin-market-place-tab-container"}>
				<div onClick={(e)=>setActiveSection("partners")} className={"admin-market-place-tab-container-item" +  (activeSection === "partners" ? " active" : "")}>
					<p className={"text-tertiary"}>Partenaires</p>
				</div>
				<div onClick={(e)=>setActiveSection("categories")} className={"admin-market-place-tab-container-item" +  (activeSection === "categories" ? " active" : "")}>
					<p className={"text-tertiary "}>Catégories</p>
				</div>
			</div>
		</div>
	);
}

export default MarketPlaceTab;