import React from 'react';

const AdminBillsTab = ({setActiveSection,activeSection}) => {
	
	
	return (
		<div className={"admin-bills-tab"}>
			<div className={"admin-bills-tab-container"}>
				<div onClick={(e)=>setActiveSection("invoices-down-payments")} className={"admin-bills-tab-container-item" +  (activeSection === "invoices-down-payments" ? " active" : "")}>
					<p className={"text-tertiary"}>Facture acompte</p>
				</div>
				<div onClick={(e)=>setActiveSection("invoices-final")} className={"admin-bills-tab-container-item" +  (activeSection === "invoices-final" ? " active" : "")}>
					<p className={"text-tertiary "}>Facture finale</p>
				</div>
				<div onClick={(e)=>setActiveSection("asset")} className={"admin-bills-tab-container-item" +  (activeSection === "asset" ? " active" : "")}>
					<p className={"text-tertiary "}>Avoir</p>
				</div>
				<div onClick={(e)=>setActiveSection("state-project")} className={"admin-bills-tab-container-item" +  (activeSection === "state-project" ? " active" : "")}>
					<p className={"text-tertiary "}>Statut projet</p>
				</div>
			</div>
		</div>
	);
};

export default AdminBillsTab;
