const ProjectNiceStage = ({ stage, niceStage }) => {
  switch (stage) {
    case 'COM_commercial-exchange':
    case 'COM_quotation':
    case 'COM_discount-validation':
      return 'Édition du devis'
    case 'ADMIN_validate-visit':
      return 'Réservation de visite'
    case 'ING_study':
    case 'ING_verification-study':
    case 'ING_validation-study':
      return 'Rédaction du dossier d\'étude structure'
    case 'ADMIN_refund':
      return "Génération d'avoir"
    /* case 'COM_delivery':
      return 'Validation du dossier d\'étude structure' */
    case 'COM_delivery':
      return 'Rendu dossier d\'étude structure'
    case 'COM_feedback':
      return 'Projet finalisé'
    default:
      return niceStage;
  }
}

export default ProjectNiceStage;