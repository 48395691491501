import React from "react";
import {useAuth} from "../services/useAuth";
import {intersection} from "lodash";

export function IsLoggedIn() {
	const user = useAuth();
	return !!user.user;
}

export function isArrayWithLength(arr) {
	return (Array.isArray(arr) && arr.length);
}

export function GetAllowedRoutes(routes) {
	const {role} = useAuth();

	return routes.filter(({permission}) => {
		if (!permission) {
			return true;
		} else if (!isArrayWithLength(permission)) {
			return true;
		} else {
			return intersection(permission, role).length;
		}
	});
}

export function GetAllowedSidebarElement(sidebar) {
	const {role} = useAuth();

	return sidebar.filter(({permission}) => {
		if (!permission) {
			return true;
		} else if (!isArrayWithLength(permission)) {
			return true;
		} else {
			return intersection(permission, role).length;
		}
	});
}