import moment from "moment";

export function ConvertTasksToFullcalendarEvents(tasks) {

	let tasksFullCalendarFormat = [];
	tasks.forEach(task => {

		let classNames = [];

		switch (task.niceType) {
			case "Devis":
			case "Prise de connaissance projet":
				classNames.push('mbeCalendar-event primary')
				break;
			case "Prospection":
			case "Visite de l'ingénieur":
				classNames.push('mbeCalendar-event green')
				break;
			case "Rendez-vous physique":
			case "Échange client":
				classNames.push('mbeCalendar-event purple')
				break;
			case "Rendez-vous téléphonique":
			case "Visite de suivi de travaux":
				classNames.push('mbeCalendar-event yellow')
				break;
			case "Rédaction dossier d'étude structure":
				classNames.push('mbeCalendar-event dark-purple')
				break;
			case "Dimensionnement":
				classNames.push('mbeCalendar-event red')
				break;
			case "Projetage":
				classNames.push('mbeCalendar-event tag')
				break;
			case "Demande complémentaire":
				classNames.push('mbeCalendar-event orange')
				break;
			case "Service Après-Vente (SAV)":
				classNames.push('mbeCalendar-event gray')
				break;
			default:
				classNames.push('mbeCalendar-event primary')
				break;
		}


		task = {
			'id': task.id,
			'isVisit': false,
			'title': task.title,
			'start': task.startAt,
			'end': task.endAt,
			'className': classNames,
			'type': task.type === "task-project-ing-visit" || task.type === "task-project-ing-visit-extra" ? 'task-project-ing-visit-custom' : task.type,
		}

		tasksFullCalendarFormat.push(task)
	});
	return tasksFullCalendarFormat
}

export function ConvertVisitsToFullCalendarEvents(visits) {
	let visitsFullCalendarFormat = [];
	visits.forEach(visit => {

		visit = {
			'id': visit.id,
			'isVisit': true,
			'title': visit.title,
			'ing': visit.ing?.niceNames,
			'client': visit.client.niceNames,
			'address': visit.address.street + " " + visit.address.postcode + " " + visit.address.city,
			'start': visit.startAt,
			'end': visit.endAt,
			'className': 'mbeCalendar-event primary',
			'type': visit.type
		}

		visitsFullCalendarFormat.push(visit)
	});
	return visitsFullCalendarFormat
}

export function SortTasksByDate(tasks) {
	tasks.sort(
		function (a, b) {
			return moment(a.startAt).diff(moment(b.startAt));
		}
	);
	return tasks
}
