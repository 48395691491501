import React, {useEffect, useState} from 'react';
import {useFormikContext} from "formik";
import {priceFormatter} from "../../../../../../../utils/PriceFormtter";

const FormPriceCurrentDevis = ({currentQuote, update}) => {

	/*All useState of all Price*/
	const [currentDevisTotalHT, setCurrentDevisTotalHT] = useState(0);
	const [currentDevisTotalTTC, setCurrentDevisTotalTTC] = useState(0);
	const [currentDevisRemise, setCurrentDevisRemise] = useState(0);
	const [currentDevisTVA, setCurrentDevisTVA] = useState(0);
	const [currentDevisPriceWithDiscount, setCurrentDevisPriceWithDiscount] = useState(0);

	const {values} = useFormikContext()

	/*Calcul all price currentQuote*/
	useEffect(() => {
		/*Check if we have orders*/
		if (values.quotes.length !== 0) {
			if (values.quotes[0].orders.length !== 0) {
				/*If we have orders*/
				let allPriceCurrentQuote = 0
				/*Calcul*/
				values.quotes[currentQuote].orders.map((item) => {
					if (item !== undefined) {
						if (item.price !== undefined && item.price > 0 && item.discount === false) {
							allPriceCurrentQuote = allPriceCurrentQuote + (item.price * item.quantity)

						}
					}
				})
				setCurrentDevisTotalHT(priceFormatter.format(allPriceCurrentQuote))

				let allPriceDiscount = 0
				values.quotes[currentQuote].orders.map((item) => {
					if (item !== undefined) {
						if (item.price !== undefined && item.price > 0 && item.discount === true) {
							allPriceDiscount = allPriceDiscount + item.price
						}
					}
				})
				setCurrentDevisRemise(priceFormatter.format(allPriceDiscount))

				let allPriceTTC = (allPriceCurrentQuote - allPriceDiscount) * 1.2
				setCurrentDevisTotalTTC(priceFormatter.format(allPriceTTC))

				let TVAprice = allPriceTTC - allPriceCurrentQuote
				setCurrentDevisTVA(priceFormatter.format(TVAprice))


				let totalHTWithDiscount = allPriceCurrentQuote - allPriceDiscount
				setCurrentDevisPriceWithDiscount(priceFormatter.format(totalHTWithDiscount))
			}
		} else {
			setCurrentDevisTotalHT(priceFormatter.format(0))
			setCurrentDevisRemise(priceFormatter.format(0))
			setCurrentDevisTotalTTC(priceFormatter.format(0))
			setCurrentDevisTVA(priceFormatter.format(0))
			setCurrentDevisPriceWithDiscount(priceFormatter.format(0))
		}


	}, [values, currentQuote, update]);


	return (<div className={"current-devis-price"}>
		<div>
			<div className={"row-price"}>
				<p className={"color-primary paragraph_bold text-primary"}>Total HT sans remise</p>
				<p className={"color-primary paragraph_bold text-primary"}>{currentDevisTotalHT}</p>
			</div>
			<div className={"row-price"}>
				<p className={"color-primary paragraph_bold text-primary"}>Remise HT</p>
				<p className={"color-primary paragraph_bold text-primary"}>{currentDevisRemise}</p>
			</div>
			<div className={"row-price"}>
				<p className={"color-primary paragraph_bold text-primary"}>TVA(20%)</p>
				<p className={"color-primary paragraph_bold text-primary"}>{currentDevisTVA}</p>
			</div>
			<div className={"row-price"}>
				<p className={"color-primary paragraph_bold text-primary"}>Total avec remise HT</p>
				<p className={"color-primary paragraph_bold text-primary"}>{currentDevisPriceWithDiscount}</p>
			</div>
			<div className={"row-price"}>
				<h4 className={"color-primary paragraph_bold text-primary"}>Total TTC</h4>
				<h4 className={"color-primary"}>{currentDevisTotalTTC}</h4>
			</div>
		</div>
	</div>);
};

export default FormPriceCurrentDevis;
