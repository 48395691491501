import React, { useState } from 'react';

import AdminBookingView from './Partials/BookingView';
import AdminCalendarView from './Partials/CalendarView';

const AdminCalendar = () => {

  const [isCalendarView, setIsCalendarView] = useState(true);

  const setCalendarView = () => {
    setIsCalendarView(true)
  }
  const setBookingView = () => {
    setIsCalendarView(false)
  }

  return (
    <>
      <div className='calendar-tabs'>
        <button onClick={setCalendarView} className={` ${isCalendarView ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Calendrier</button>
        <button onClick={setBookingView} className={` ${!isCalendarView ? 'calendar-tabs-item-active' : ''} calendar-tabs-item`}>Créneaux de visites</button>
      </div>
      {isCalendarView ? (
        <>
          <AdminCalendarView />
        </>
      ) : (
        <>
          <section className='mbe-section mbe-bookingSlot-container'>
            <AdminBookingView />
          </section>
        </>
      )}
    </>
  )
}

export default AdminCalendar;
