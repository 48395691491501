import React from 'react';
import ProjectService from '../../../services/ProjectService';

const PopUpDemandeDeCorrectionStudyFolder = ({onClickNo, onClickYes, idProject}) => {


    const handleDemandeDeCorrection = async () => {
        const request = await ProjectService.CorrectionRequest(idProject, {validation: false})
        onClick()
        if(request.request.status === 200) {
            setStateRequest("success correction request")
        } else {
            setStateRequest("error")
        }
    }


    return (
        <div className={"pop-up-base-style active"}>
            <div className={"pop-up-user-delete"}>
                <img onClick={onClickNo} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"fermer la pop up"}/>
                <img src={"/images/medias/popup.svg"} alt="test"/>
                <h4>
                    Voulez-vous envoyer une demande de correction pour le projet {idProject} ?
                </h4>

                <div className={"pop-up-user-delete-buttons"}>
                    <button className={"btn btn-cancel"} onClick={onClickNo}>Non</button>
                    <button className={`btn btn-primary`} onClick={onClickYes}>Oui</button>
                </div>


            </div>
        </div>
    );

}

export default PopUpDemandeDeCorrectionStudyFolder;