import React, {useState} from 'react';
import {Form, Formik} from "formik";
import {InitialValuesIngCCTP} from "../../../../config/InitialValues/Engineer/InitialValuesIngCCTP";
import {ingCCTPSchema} from "../../../../schemas/Engineer/IngCCTPSchema";
import InputLabelErrorMessage from "../../../Atomes/Formik/InputLabelErrorMessage";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const FormAddCCTP = ({onSubmit, onClick}) => {

	const [data, setData] = useState("")

	const handleSubmit = async (values, actions) => {
		values.content = data
		onSubmit(values)
		actions.resetForm()
		onClick()
	}

	const editorConfig = {
		licenseKey: 'ZEFhU2ZQSlo4TkFHSE9SMXcrcDU5dnhldzVRVjMvdEE2UjZsRTIrR1ZXK3g5cjhUWCtaZHNUbS9UeUpaLU1qQXlOREEwTVRrPQ=='
	};

	return (
		<Formik
			initialValues={InitialValuesIngCCTP}
			validationSchema={ingCCTPSchema}
			onSubmit={handleSubmit}
		>
			{({isSubmitting}) => (
				<Form>
					<div className={"form-row-element form-row-two-elements"}>
						<InputLabelErrorMessage
							label="Titre de la prescription"
							name="title"
							type="text"
						/>
						<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"}
						                        data={[{
							                        label: "3.1 Sujétions de l'entreprise - Options",
							                        value: "3"
						                        }, {label: "3.2 Variantes", value: "4"}]}
						                        label={"Choix du sujet"} name={"origin"}/>
					</div>

					<div className={"form-row-element big-element"}>
						<div className={"form-row-label-input"}>
							<div>
								<CKEditor
									config={editorConfig}
									editor={Editor}
									onChange={(event, editor) => {
										setData(editor.getData());
									}}
								/>
							</div>
						</div>
					</div>

					<div>
						<ButtonSubmit title={"Ajouter"} isActive={false} styleButton={"btn-default btn-default-secondary plain"}/>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormAddCCTP;
