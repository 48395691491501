import React, {useContext} from 'react';
import {toast} from "react-toastify";
import {PriService} from "../../../../services/Engineer/PriService";

const PopUpSkipValidationPriClient = ({ onClickClose, setSubmitForm, projectId }) => {
	const handleSubmit = async () => {
		const request = await PriService.skipValidationPri(projectId)
		if (request.request.status == 200){
			toast.success("La validation a été passée avec succèes")
			setSubmitForm(true)
			onClickClose(false)
		}else {
			toast.error("Une erreur est survenue")
		}
	}

	return (
		<div className="pop-up-base-style pop-up-confirmation active">
			<div className="pop-up-confirmation-container">
				<img alt={"img close"} onClick={onClickClose} className={"cross-close"} src={"/images/pictos/cross-close.svg"}/>
				<img src={"/images/medias/popup.svg"} alt="test"/>
				<h4>Voulez-vous passer l'étape de validation du principe de structure par les clients ?</h4>
				<div className="pop-up-confirmation-container-buttons">
					<button className={"btn btn-default btn-default-secondary"} onClick={onClickClose}>Non</button>
					<button className={"btn btn-default btn-default-primary"} onClick={handleSubmit}>Oui</button>
				</div>
			</div>
		</div>
	);
}

export default PopUpSkipValidationPriClient;