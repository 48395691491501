import React, {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import DirRespService from "../../../../../../../services/Ing/DirResp/DirRespService";
import TableSearchTitle from "../../../../../../../components/Molecules/Tables/TableSearchTitle";
import {ARCHITECT_CLIENT_COLUMNS} from "../../../../../../../config/ArrayColumns/Architect/ClientColumns";

const CustomerListingIng = () => {

	const [search, setSearch] = useState("");
	const [customers, setCustomers] = useState([]);
	const [customersLoading, setCustomersLoading] = useState(true);

	useEffect(() => {
		getAllCustomers();
	}, [search]);

	const getAllCustomers = async () => {
		let request = await DirRespService.getAllClients({search: search});
		if (request.status === 200) {
			setCustomers(request.data);
		}
		setCustomersLoading(false);
	}

	return (
		<div className={"archi-clients"}>
			<div className={"archi-clients-container"}>
				{
					customersLoading ?
						<Skeleton count={10} height={40}/>
						:
						<TableSearchTitle
							title={"Mes clients"}
							placeHolder={"Rechercher un client"}
							data={customers}
							columnTable={ARCHITECT_CLIENT_COLUMNS}
							onChangeSearch={(event) => (event.key === "Enter" || event.target.value.length === 0) ? setSearch(event.target.value.toLowerCase()) : null}
							onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
						/>
				}
			</div>
		</div>
	);
};

export default CustomerListingIng;
