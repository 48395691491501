import api from "./APIService";
import {ConvertObjectToParams, ConvertObjectToUrlParams} from "../utils/StringUtils";

const deletePartner = async (id) => {
	return await api.del(`/stats/${id}`);
}

const getSinglePartner = async (id) => {
	const request = await api.getAllData(`/stats/${id}`)
	return request
}

const getAllPartnersLink = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams("stats", param))
}

const getPartnerLinkStats = async (id, param) => {
	return await api.getAllData(ConvertObjectToParams(`sessions?stat.id=${id}`, param))
}

const editPartnerLink = async (id, data) => {
	return await api.patch(`/stats/${id}`, data)
}

const addPartnerLink = async (data) => {
	return await api.postBody("/stats", data)
}

const PartnerService = {
	deletePartner,
	getSinglePartner,
	getAllPartnersLink,
	editPartnerLink,
	getPartnerLinkStats,
	addPartnerLink
}

export default PartnerService;