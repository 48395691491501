import * as Yup from "yup";

export const EditPrestationSchema = Yup.object().shape({
	zone : Yup.string().required("Veuillez renseigner une zone"),
	spent : Yup.number().required("Veuillez renseigner un prix déboursé"),
	recommendedPrice : Yup.number().required("Veuillez renseigner une marge"),
	categoryIri : Yup.string().required("Veuillez renseigner une catégorie"),
	price : Yup.number().required("Veuillez renseigner un prix"),
	description : Yup.string().required("Veuillez renseigner une description"),
	title : Yup.string().required("Veuillez renseigner un titre"),
	reference: Yup.string().required("Veuillez renseigner une reference"),
})
