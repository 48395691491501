import * as Yup from 'yup';

const fileTypes = ['image/png', 'image/jpeg', 'image/jpg'];

export const MarketPlaceSchema = Yup.object().shape({
	title: Yup.string().required('Veuillez renseigner un nom pour le partenaire'),
	logoUrl: Yup.mixed().required('Veuillez renseigner un logo pour le partenaire')
		.test('fileFormat', 'Le fichier doit être un PNG, JPEG ou JPG', (value) =>
			value ? fileTypes.includes(value.type) || (value.endsWith('.png') || value.endsWith('.jpeg') || value.endsWith('.jpg')) : true
		).test("fileSize", "Le fichier est trop volumineux <= 250ko", (value) =>
			value ? value.size ? value.size <= 250000 : true : true
		),
	imageUrl: Yup.mixed().required('Veuillez renseigner une image pour le partenaire').test('fileFormat', 'Le fichier doit être un PNG, JPEG ou JPG', (value) =>
		value ? fileTypes.includes(value.type) || (value.endsWith('.png') || value.endsWith('.jpeg') || value.endsWith('.jpg')) : true
	).test("fileSize", "Le fichier est trop volumineux <= 250ko", (value) =>
		value ? value.size ? value.size <= 250000 : true : true
	),
	description: Yup.string().required('Veuillez renseigner une description pour le partenaire').min(1).max(200, 'La description ne doit pas dépasser 50 caractères'),
	/*category: Yup.string().required('Veuillez renseigner une catégorie pour le partenaire'),*/
	email: Yup.string().email("Le format de l'adresse e-mail est incorrect").required('Veuillez renseigner une adresse e-mail pour le partenaire'),
	transmission: Yup.string().required('Veuillez renseigner une transmission pour le partenaire'),
	active: Yup.string().required('Veuillez renseigner un statut pour le partenaire'),
	categoryIri: Yup.string().required('Veuillez renseigner une catégorie pour le partenaire')
});
