import React, {createContext, useEffect, useState} from 'react';
import FilterSearchSelectDatepicker from "../../../../../components/Molecules/Filter/FilterSearchSelectDatepicker";
import TableTitle from "../../../../../components/Molecules/Tables/TalbeTitle";
import {COMMERCIAL_NEW_REQUEST_COLUMNS} from "../../../../../config/ArrayColumns/CommercialNewRequestColumns";

import RequestService from "../../../../../services/RequestService";
import {toast} from "react-toastify";
import moment from "moment/moment";
import {COMMERCIAL_FILE_IN_PROGRESS_COLUMNS} from "../../../../../config/ArrayColumns/CommercialFileInProgressColumns";
import {COMMERCIAL_SIGNED_QUOTES_COLUMNS} from "../../../../../config/ArrayColumns/CommercialSignedQuotesColumns";
import Skeleton from "react-loading-skeleton";
import {COMMERCIAL_FILE_IN_VALIDATION_COLUMNS} from "../../../../../config/ArrayColumns/CommercialFilesInValidationColumns";
import {COMMERCIAL_FILE_TO_SEND_COLUMNS} from "../../../../../config/ArrayColumns/CommercialFileToSendColumns";

export const StateRequest = createContext()
const HomeCommercial = () => {

	const id = localStorage.getItem("userId")

	//Search
	const [search, setSearch] = useState("");

	//Date
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [date, setDate] = useState(new Date());

	const [stateRequest, setStateRequest] = useState("");

	const secondOptions = ["1 mois", "2 mois", "3 mois"]
	const secondValues = [1, 2, 3]

	const [fileInProgress, setFileInProgress] = useState([]);
	const [fileInProgressLoading, setFileInProgressLoading] = useState(true);

	const [fileInValidation, setFileInValidation] = useState([]);
	const [fileInValidationLoading, setFileInValidationLoading] = useState(true);

	const [signedQuotes, setSignedQuotes] = useState([]);
	const [signedQuotesLoading, setSignedQuotesLoading] = useState(true);
	const [dataRequest, setDataRequest] = useState([]);
	const [dataRequestLoading, setDataRequestLoading] = useState(true);

	const [fileToSend, setFileToSend] = useState([]);
	const [fileToSendLoading, setFileToSendLoading] = useState(true);

	const getFolderInProgress = async () => {

		const request = await RequestService.getFolderInProgress({
			"project.users.id": id,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			setFileInProgressLoading(false);
			setFileInProgress(request.data);
		} else {
			toast.error('Une erreur est survenue lors de la récupération des dossiers en cours')
		}
	}

	useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
			getRequest()
			getFileToSend()
			getFolderInProgress()
			getSignedFiles()
			getFilesInValidation()
		}
	}, [stateRequest])

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("La prestation a bien été ajoutée")
		} else if (type === "success edit") {
			toast.success("La prestation a bien été modifiée")
		} else if (type === "success delete") {
			toast.success("La demande a bien été supprimée")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		} else if (type === "success edit coment") {
			toast.success("Le commentaire a bien été enregistré")
		} else if (type === "success archive") {
			toast.success("Le dossier a bien été archivé")
		}
		setStateRequest("")
	}

	const changeDateMonthLater = (date) => {
		if (date === "Toutes durées") {
			setDateStart("")
			setDateEnd("")
			setDate(new Date())
		} else {
			let dateEnd = moment().add(1, "days").format("YYYY-MM-DD")
			let dateBegin = moment().subtract(date, 'months').format("YYYY-MM-DD")
			setDate([new Date(dateBegin), new Date(dateEnd)])
			setDateStart(dateBegin)
			setDateEnd(dateEnd)
		}
	}

	const getFilesInValidation = async () => {
		const request = await RequestService.getFilesInValidation({
			"stage.title": "COM_discount-validation",
			"users.id": id,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			setFileInValidationLoading(false);
			setFileInValidation(request.data);
		} else {
			toast.error('Une erreur est survenue lors de la récupération des devis signés')
		}
	}

	const getSignedFiles = async () => {
		const request = await RequestService.getSignedFiles({
			"project.users.id": id,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			setSignedQuotesLoading(false);
			setSignedQuotes(request.data);
		} else {
			toast.error('Une erreur est survenue lors de la récupération des devis signés')
		}
	}

	const getRequest = async () => {
		const request = await RequestService.getNewRequests({
			"user.id": id,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			setDataRequest(request.data);
			setDataRequestLoading(false);
		} else {
			toast.error('Une erreur est survenue lors de la récupération nouvelles demandes')
		}
	}

	const getFileToSend = async () => {
		const request = await RequestService.getFileToSend({
			"project.user.id": id,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			search: search,
			pagination: "true"
		})
		if (request.request.status === 200) {
			setFileToSend(request.data);
			setFileToSendLoading(false);
		} else {
			toast.error('Une erreur est survenue lors de la récupération des dossiers à envoyer')
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).add(1, "days").format("YYYY-MM-DD"))
	}

	useEffect(() => {
		getRequest()
		getFileToSend()
		getFolderInProgress()
		getSignedFiles()
		getFilesInValidation()
	}, [search, date])

	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<FilterSearchSelectDatepicker
				onChange={(event) => changeDateMonthLater(event.target.value)}
				firstValue={secondValues}
				date={date}
				onChangeDate={(date) => changeDate(date)}
				options={secondOptions}
				value={secondValues}
				defaultValue={"Toutes durées"}
				onSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
				onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
			/>

			{
				!dataRequestLoading ? (
					<>
						<TableTitle className={"section-array-new-request mbe-section"} title={"Nouvelles demandes"}
									columnTable={COMMERCIAL_NEW_REQUEST_COLUMNS} data={dataRequest} link={'/devis'}
									linkTitle={<button  className={"btn-default btn-default-primary color-primary"}>Nouveau projet</button>}/>
					</>
				) : (
					<Skeleton height={500}/>
				)
			}
			{
				!fileToSendLoading ? (
					<TableTitle className={"section-array-file-in-progress mbe-section"} title={"Dossiers à envoyer"}
					            columnTable={COMMERCIAL_FILE_TO_SEND_COLUMNS} data={fileToSend}/>
				) : (
					<Skeleton height={500}/>
				)
			}
			{
				!fileInProgressLoading ? (
					<TableTitle className={"section-array-file-in-progress mbe-section"} title={"Dossiers en cours"}
					            columnTable={COMMERCIAL_FILE_IN_PROGRESS_COLUMNS} data={fileInProgress}/>
				) : (
					<Skeleton height={500}/>
				)
			}
			{
				!signedQuotesLoading ? (
					<TableTitle className={"section-array-signed-quotes mbe-section"} title={"Devis signés"}
					            columnTable={COMMERCIAL_SIGNED_QUOTES_COLUMNS} data={signedQuotes}/>
				) : (
					<Skeleton height={500}/>
				)
			}
			{
				!fileInValidationLoading ? (
					<TableTitle className={"section-array-file-in-validation mbe-section"} title={"Dossiers en validation de remise"}
					            columnTable={COMMERCIAL_FILE_IN_VALIDATION_COLUMNS} data={fileInValidation}/>
				) : (
					<Skeleton height={500}/>
				)
			}

			
		</StateRequest.Provider>
	);
};

export default HomeCommercial;
