import React, { useContext, useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";

import { StateRequest } from "../Collaborators";
import { Form, Formik } from "formik";
import ButtonSubmit from "../../../../../../components/Atomes/Buttons/ButtonSubmit";
import InputLabelErrorMessage from "../../../../../../components/Atomes/Formik/InputLabelErrorMessage";

import CollaboratorService from "../../../../../../services/CollaboratorService";
import { SchemaCollaborator } from './Config/SchemaCollaborator';
import { InitialValuesCollaborator } from './Config/InitialValuesCollaborator';
import { dataWLSelectCollaborator } from "../../../../../../utils/UserWLUtils";
import { dataRolesSelectCollaborator } from "../../../../../../utils/UserRolesUtils";
import {dataRegions} from "../../../../../../utils/RegionsUtils";

const FormEditCollaborator = ({ id, setShowPopUp }) => {

	const { stateRequest, setStateRequest } = useContext(StateRequest);
	const [isLoading, setIsLoading] = useState(true);

	const onSubmit = async (values, actions) => {
		if (values.bonus === "") delete values.bonus
		if (values.objective === "") delete values.objective

		values.baseH = parseInt(values.baseH)
		values.package = parseInt(values.package)
		values.regions = [values.regions.additionalProp1]
		values.roles = [values.roles.additionalProp1]
		const request = await CollaboratorService.updateCollaborator(`coms/${id}`, values)
		if (request.request.status === 200) {
			setStateRequest("success edit")
		} else {
			setStateRequest("error")
		}
		setShowPopUp(false)
		actions.resetForm()
	}

	const getUser = async () => {
		const request = await CollaboratorService.getCollaborators(`coms/${id}`)
		if (request.request.status === 200) {
			let user = request.data

			if (user.regions) {
				let regionsToPush = user.regions[0]
				user.regions = {
					additionalProp1: regionsToPush
				}
			}

			if (user.roles) {
				let rolesToPush = user.roles[0]
				user.roles = {
					additionalProp1: rolesToPush
				}
			}

			if (user.wl !== undefined) {
				let wl = dataWLSelectCollaborator.filter((item) => item.label === user.wl)
				InitialValuesCollaborator.Edit.wl = wl[0].value
			} else {
				InitialValuesCollaborator.Edit.wl = "N/A"
			}
			InitialValuesCollaborator.Edit.baseH = user.baseH ? user.baseH : ""
			InitialValuesCollaborator.Edit.package = user.package ? user.package.toString() || "" : ""
			InitialValuesCollaborator.Edit.roles = user.roles || ""
			InitialValuesCollaborator.Edit.firstname = user.firstname || ""
			InitialValuesCollaborator.Edit.lastname = user.lastname || ""
			InitialValuesCollaborator.Edit.email = user.email || ""
			InitialValuesCollaborator.Edit.phone = user.phone || ""
			InitialValuesCollaborator.Edit.gender = user.gender || ""
			InitialValuesCollaborator.Edit.objective = user.objective || ""
			InitialValuesCollaborator.Edit.bonus = user.bonus !== 0 ? user.bonus || "" : 0
			InitialValuesCollaborator.Edit.regions = user.regions || ""
			setIsLoading(false)
		}
	}
	const isInt = (num) => {
		return num % 1 === 0;
	}

	useEffect(() => {
		setIsLoading(true)
		if (id !== 0 && isInt(id)) {
			getUser()
		}
	}, [id, stateRequest, setShowPopUp]);

	const dataRoles = dataRolesSelectCollaborator
	const dataWL = dataWLSelectCollaborator

	return (
		<>
			<Formik
				initialValues={InitialValuesCollaborator.Edit}
				validationSchema={SchemaCollaborator}
				onSubmit={onSubmit}
			>
				{({ isSubmitting }) => (
					isLoading ? <Skeleton height={600} /> :
						<Form>
							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"text"} label={"Nom"} name={"lastname"} />
								<InputLabelErrorMessage type={"text"} label={"Prénom"} name={"firstname"} />
							</div>
							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"text"} label={"Téléphone"} name={"phone"} />
								<InputLabelErrorMessage type={"text"} label={"Adresse e-mail"} name={"email"} />
							</div>
							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} data={dataRoles} label={"Rôle"} name={"roles.additionalProp1"} />
								<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} label={"Niveau de travail"} data={dataWL} name={"wl"} />
							</div>
							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} label={"Base horaire"} data={[{ label: "N/A", value: "0" }, { label: "7", value: "7" }, { label: "8", value: "8" }]} name={"baseH"} />
								<InputLabelErrorMessage type={"select"} label={"Forfait"} name={"package"} keyLabel={"label"} keyValue={"value"} data={[{label: "N/A", value: 0},{label: "Base horaire 8 (Jour)", value: 8},{label : "Base horaire 7 (Etam, Alternance, Stage)", value:7}]} />
							</div>
							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"number"} label={"Bonus sur marge"} name={"bonus"} />
								<InputLabelErrorMessage type={"number"} label={"Objectif chiffre d’affaires mensuel (HT)"} name={"objective"} />
							</div>
							<div className={"form-row-element form-row-two-elements"}>
								<InputLabelErrorMessage type={"select"} keyLabel={"label"} keyValue={"value"} label={"Zone géographique"} data={dataRegions} name={"regions.additionalProp1"} />
								<InputLabelErrorMessage type={"select"} label={"Genre"} keyLabel={"label"} keyValue={"value"} name={"gender"} data={[{ label: "Homme", value: "M" }, { label: "Femme", value: "W" }, { label: "Non-genrée", value: "NA" }]} />
							</div>
							<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Modifier le collaborateur"} />
						</Form>
				)}
			</Formik>
		</>
	)
};

export default FormEditCollaborator;
