import * as Yup from 'yup';

export const addUsersSchema = Yup.object().shape({
	user: Yup.object().shape({
		gender: Yup.string().required('Ce champ est requis'),
		roles: Yup.object().shape({
			additionalProp1: Yup.string().required('Ce champ est requis'),
		}),
		firstname: Yup.string().required('Ce champ est requis'),
		lastname: Yup.string().required('Ce champ est requis'),
		email: Yup.string().email('Le format de l\'adresse mail n\'est pas bon').required('Ce champ est requis'),
		phone: Yup.string()
			.min(10, "La longueur doit être de minimum 10 caractères")
			.max(14, "La longueur doit être de maximum 14 caractères")
			.required('Veuillez renseigner un numéro de téléphone'),
		agencyName: Yup.string().when("gender", {
			is: "S",
			then: Yup.string().required("Veuillez renseigner la raison sociale")
		})
	}),
});
