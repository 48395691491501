import React, {createContext, useEffect, useState} from 'react';
import {SortingTable} from "../../../../components/Molecules/Tables/SortingTable";
import {DPGF_COLUMNS} from "../../../../config/ArrayColumns/Admin/DpgfColumns";
import {toast} from "react-toastify";
import DpgfService from "../../../../services/DpgfService";
import TableHeadingAdd from "../../Coms/Dir/Collaborators/Partials/TableHeadingAdd";
import PopUpForm from "../../../../components/Molecules/PopUp/PopUpForm";
import FormAddDPGF from "../../../../components/Molecules/Form/Admin/FormAddDPGF";

export const StateRequest = createContext()
const AdminDpgfView = () => {
	const [stateRequest, setStateRequest] = useState("");
	const [dpgf, setDpgf] = useState([])
	const [addDpgf, setAddDpgf] = useState(false)

	const getDpgf = async () => {
		const request = await DpgfService.getDpgf({pagination: "true"})
		if (request.request.status === 200) {
			setDpgf(request.data)
		} else {
			toast.error("Une erreur est survenue lors des DPGF")
		}
	}

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("Le DPGF a bien été ajouté")
		} else if (type === "success edit") {
			toast.success("Le DPGF a bien été modifié")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		} else if (type === "success delete") {
			toast.success("Le DPGF a bien été supprimé")
		}
		setStateRequest("")
	}

	useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getDpgf()
	}, [stateRequest])

	const DisplayCreateModalDpgf = () => {
		setAddDpgf(!addDpgf)
	}

	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<section className={"mbe-section"}>
				<TableHeadingAdd addTitle={"Ajouter une DPGF"}
				                 title={"Annuaire - Décomposition des Prix Globales et Forfaitaire (DPGF)"}
				                 onClick={() => setAddDpgf(true)}/>
				<SortingTable
					dataTable={dpgf}
					columnsTable={DPGF_COLUMNS}
				/>
			</section>
			<PopUpForm large={true} title={"Ajouter une DPGF"} show={addDpgf} showPopUp={addDpgf} setShowPopUp={setAddDpgf}
			           form={<FormAddDPGF setAddDpgf={setAddDpgf}/>} onClick={DisplayCreateModalDpgf}/>
		</StateRequest.Provider>
	);
};

export default AdminDpgfView;
