import React, { useContext, useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { FilterContext } from "../HomeCustomerProjects";

import CardProject from "../../../../../../components/Molecules/Cards/Project/CardProject";

const HomeCustomerProjectsListing = ({projects, errorProjects}) => {

  const filterContext = useContext(FilterContext);
  const [projectsGroupedByCity, setProjectsGroupedByCity] = useState([]);
  const groupProjectsByCity = (projects) => {
    const projectsGroupedByCity = projects.reduce((acc, project) => {
      const city = project.address.city;
      if (!acc[city]) {
        acc[city] = [];
      }
      acc[city].push(project);
      return acc;
    }, {});
    setProjectsGroupedByCity(projectsGroupedByCity)
  }

  const [projectsGroupedByType, setProjectsGroupedByType] = useState([]);
  const groupProjectsByType = (projects) => {
    const projectsGroupedByType = projects.reduce((acc, project) => {
      const type = project.niceType;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(project);
      return acc;
    }, {});
    setProjectsGroupedByType(projectsGroupedByType)
  }

  useEffect(() => {
    if (filterContext.filterSelected === "Trier par ville") {
      groupProjectsByCity(projects)
    } else if (filterContext.filterSelected === "Trier par typologie de projet") {
      groupProjectsByType(projects)
    } 
  }, [filterContext.filterSelected]);

  return (
    <>
      {projects.length === 0 && !errorProjects.status  && <Skeleton height={300} />}

      {errorProjects.status && <p className="text-primary text-bold">{errorProjects.message}</p>}

      {filterContext.filterSelected === "Tous les projets" &&
        <section className="homeCustomerProjects-section">
          <div className="homeCustomerProjects-section-listing">
            {projects.map((project, index) => (
              <CardProject
                id={project.id}
                key={index}
                project={project}
              />
            ))}
          </div>
        </section>
      }

      {filterContext.filterSelected === "Trier par ville" &&
        Object.keys(projectsGroupedByCity).map((project, index) => (
          <section className="mbe-section homeCustomerProjects-section" key={index}>
            <h3>{project}</h3>
            <div className="homeCustomerProjects-section-listing">
              {
                projectsGroupedByCity[project].map((project, index) => (
                  <CardProject
                    id={project.id}
                    key={index}
                    projectFinished={project.statut === "finished"}
                    readyFolder={project.study_folder === "ready"}
                    userActionRequired={project.statut === "en cours"}
                    project={project}
                  />
                ))
              }
            </div>
          </section>
        ))
      }

      {filterContext.filterSelected === "Trier par typologie de projet" &&
        Object.keys(projectsGroupedByType).map((project, index) => (
          <section className="mbe-section homeCustomerProjects-section" key={index}>
            <h3>{project}</h3>
            <div className="homeCustomerProjects-section-listing">
              {
                projectsGroupedByType[project].map((project, index) => (
                  <CardProject
                    id={project.id}
                    key={index}
                    projectFinished={project.statut === "finished"}
                    readyFolder={project.study_folder === "ready"}
                    userActionRequired={project.statut === "en cours"}
                    project={project}
                  />
                ))
              }
            </div>
          </section>
        ))
      }
    </>
  )
}

export default HomeCustomerProjectsListing;