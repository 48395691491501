import * as Yup from "yup";
import moment from "moment/moment";

export const SchemaAssignment = Yup.object().shape({
    taskName: Yup.string().required("Veuillez renseigner un nom de tâche"),
    // taskDuration: Yup.string().required("Veuillez renseigner une durée de tâche"),
    taskType: Yup.string().required("Veuillez renseigner un type de tâche"),
    startDate: Yup.string().required("Veuillez renseigner une date de début"),
    startTime: Yup.string().required("Veuillez renseigner une heure de début"),
    endDate: Yup.string().required("Veuillez renseigner une date de fin").test(
      "is-endDate-greater-than-or-equal",
      "La date de fin ne peut pas être antérieure à la date de début",
      function (endDate) {
        const { startDate } = this.parent;
        return moment(endDate).isSameOrAfter(startDate);
      }
    ),
    endTime: Yup.string().required("Veuillez renseigner une heure de fin"),
});
