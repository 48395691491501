const CardMegaroutine = ({ megaroutine, openPopupEdit }) => {
  return (
    <div className="card-megaroutine">
      <span className="card-megaroutine__name text-primary color-white">
        {megaroutine.title}
      </span>

      <div className="card-megaroutine__question">
        <p className="text-primary color-white">
          <img src={"/images/pictos/help-white.svg"} />
          {megaroutine.triggerTitle}
        </p>
      </div>
      {
        megaroutine.chatbotMacroRoutines.map((macroroutine, index) => (
          <div key={index} className="card-megaroutine__macroroutine">
            <span className="text-tertiary color-primary paragraph_bold">
              <img src={"/images/pictos/route-primary.svg"} />
              {macroroutine.title}
            </span>
          </div>
        ))
      }

      {
        !megaroutine.mandatory && (
          <button
            className={"btn-default btn-default-primary card-megaroutine__button"}
            onClick={() => openPopupEdit(megaroutine.id)}
            type={"button"}
          >
            Modifier la méga-routine
          </button>
        )
      }

    </div>
  )
}

export default CardMegaroutine;