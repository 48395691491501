import React, {useEffect, useState} from 'react';
import Skeleton from "react-loading-skeleton";

import BookingService from '../../../../../../services/BookingService';
import ProjectService from '../../../../../../services/ProjectService';
import getUserService from '../../../../../../services/getUser';
import {toast} from 'react-toastify';

import MbeBookSlotSlider from '../../../../../../components/Molecules/BookSlot/MbeBookSlotSlider';
import Select from '../../../../../../components/Atomes/Select/Select';
import {Form, Formik} from "formik";
import {initialValuesBookingSlot} from '../../../../../../config/InitialValues/BookingSlot/initialiValuesBookingSlot';
import {SchemaBookSlot} from '../../../../../../schemas/BookSlot/SchemaBookSlot';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import ButtonSubmit from '../../../../../../components/Atomes/Buttons/ButtonSubmit';

const ComBookingPart = ({}) => {

	const userId = getUserService.getUserId();
	const [region, setRegion] = useState("");

	const [bookings, setBookings] = useState([]);
	const [projects, setProjects] = useState([]);

	const [isProjectSelected, setIsProjectSelected] = useState(false);
	const [projectSelected, setProjectSelected] = useState("");

	const [isBookingSlotSelected, setIsBookingSlotSelected] = useState(false);
	const [bookingSlotRequestPost, setBookingSlotRequestPost] = useState(false);
	const [bookingSlotStartAt, setBookingSlotStartAt] = useState("");
	const [bookingSlotEndAt, setBookingSlotEndAt] = useState("");

	const [isCustomerPresent, setIsCustomerPresent] = useState(true);

	const selectProject = (e) => {
		if (e.target.value !== "Choisir un projet") {
			const myProject = projects.filter((projet) => projet.id == e.target.value)[0]
			setRegion(myProject.region)
			setIsProjectSelected(true)
			setIsCustomerPresent(true)
			setProjectSelected(
				{
				id: e.target.value,
				projectIri: "/api/projects/" + e.target.value
			}
			)
		} else {
			setIsProjectSelected(false)
			setIsCustomerPresent(true)
		}
	}

	const selectBookingSlot = (e) => {
		setIsBookingSlotSelected(true)
		setBookingSlotStartAt(e.target.attributes["attr-startat"].value);
		setBookingSlotEndAt(e.target.attributes["attr-endat"].value);
	}

	const bookSlot = async (values) => {
		let data = {
			"startAt": bookingSlotStartAt,
			"endAt": bookingSlotEndAt,
			"valid": false,
			"projectIri": projectSelected.projectIri,
			"clientPresent": isCustomerPresent,
			"contactFirstname": !isCustomerPresent ? values.firstName : null,
			"contactLastname": !isCustomerPresent ? values.lastName : null,
			"contactPhone": !isCustomerPresent ? values.phone : null,
			"contactMore": !isCustomerPresent ? values.additionalInformation : null
		}
		let request = await BookingService.postBooking("bookings", data)
		if (request.request.status === 201) {
			setIsBookingSlotSelected(false)
			setIsProjectSelected(false)
			setIsCustomerPresent(true)
			setProjectSelected("")
			setBookingSlotRequestPost(true)
			setTimeout(() => {
				setBookingSlotRequestPost(false)
			}, 2000)
			toast.success("Votre demande de réservation de visite a été envoyée avec succès")
		} else {
			toast.error("Une erreur est survenue lors de la création du rendez-vous")
		}
	}


	useEffect(() => {
		const getProjects = async () => {
			const request = await ProjectService.getAllProjects(`projects?com.id=${userId}&active=true&stage.title[]=COM_deposit&stage.title[]=ING_visit&exists[ingDiag]=false&`, {})
			if (request.request.status === 200) {
				setProjects(request.data['hydra:member'])
			} else {
				toast.error("Une erreur est survenue lors de la récupération de la liste des projets")
			}
		}
		getProjects();

		/* const getUserDatas = async () => {
			const request = await getUserService.getUser();
			if (request.request.status === 200) {
				setRegion(request.data.regions[0])
			}
		}
		getUserDatas(); */
	}, [bookingSlotRequestPost])

	useEffect(() => {
		const getBookings = async () => {
			const request = await BookingService.getBookings({'region': region, 'project.id': projectSelected.id})
			if (request.request.status === 200) {
				setBookings(request.data.shifts)
			} else {
				toast.error("Une erreur est survenue lors de la récupération de la liste des Bookings")
			}
		}
		getBookings();
	}, [projectSelected])

	return (
		<>
			{bookingSlotRequestPost && (<Skeleton height={200}/>)}

			{!bookingSlotRequestPost && (
				<>
					<div className='mbe-bookingSlot-steps-selectProject'>
						<p className={"text-primary color-dark-grey"}>Sélection du projet</p>
						<Select
							defaultValue={"Choisir un projet"}
							styleSelect={"select-custom-secondary"}
							data={projects && projects.map((project) => {
								return project.client.lastname + " " + project.client.firstname + " / " + project.reference
							})}
							value={projects && projects.map((project) => {
								return project.id
							})}
							onChange={selectProject}
						/>
					</div>
					{
						isProjectSelected && (
							<MbeBookSlotSlider bookings={bookings} selectBookingSlot={selectBookingSlot} bookingSlotCreator={true}/>
						)
					}
				</>
			)}

			<section className='mbe-bookingSlot-steps'>

				{isProjectSelected && isBookingSlotSelected && (
					<div className='mbe-bookingSlot-steps-choice'>
						<p className={"text-primary color-dark-grey"}>Le client sera-t-il lui-même présent pour la visite ?</p>
						<div className='mbe-bookingSlot-steps-choice-inputs'>
							<div>
								<input type="radio" name='mbe-bookingSlot-input' id="mbe-bookingSlot-inputYes"
								       onClick={() => {
									       setIsCustomerPresent(true)
								       }}
								       checked={isCustomerPresent}
								       onChange={e => {
								       }}
								/>
								<label htmlFor="mbe-bookingSlot-inputYes" name='mbe-bookingSlot-input'
								       className={"text-primary color-primary"}
								       onClick={() => {
									       setIsCustomerPresent(true)
								       }}> Oui </label>
							</div>
							<div>
								<input type="radio" id="mbe-bookingSlot-inputNo" name="mbe-bookingSlot-input"
								       onClick={() => {
									       setIsCustomerPresent(false)
								       }}
								/>
								<label htmlFor="mbe-bookingSlot-inputNo" name='mbe-bookingSlot-input'
								       className={"text-primary color-primary"}
								       onClick={() => {
									       setIsCustomerPresent(false)
								       }}> Non </label>
							</div>
						</div>
					</div>
				)}

				{isProjectSelected && isBookingSlotSelected && isCustomerPresent &&
					<ButtonSubmit title={"Valider rendez-vous"} isActive={false}
					              styleButton={"btn-default btn-default-secondary plain"}
					              onClick={bookSlot}
					/>
				}

				{isProjectSelected && !isCustomerPresent &&
					<div className='mbe-bookingSlot-steps-form'>
						<h4>Veuillez saisir les informations de la personne présente lors de la visite :</h4>
						<Formik
							initialValues={initialValuesBookingSlot}
							validationSchema={SchemaBookSlot}
							onSubmit={bookSlot}
						>
							{() => (
								<Form>
									<div className={"form-row-element form-row-two-elements"}>
										<InputLabelErrorMessage label={"Prénom"} name={"firstName"}
										                        type={"text"}/>
										<InputLabelErrorMessage label={"Nom"} name={"lastName"} type={"text"}/>
									</div>

									<div className={"form-row-element form-row-two-elements"}>
										<InputLabelErrorMessage label={"Téléphone"} name={"phone"} type={"text"}/>
										<InputLabelErrorMessage label={"Informations complémentaires"}
										                        name={"additionalInformation"} type={"text"}/>
									</div>

									<ButtonSubmit title={"Valider rendez-vous"} isActive={false}
									              styleButton={"btn-default btn-default-secondary plain"}/>
								</Form>
							)}
						</Formik>
					</div>
				}

			</section>
		</>
	)
}

export default ComBookingPart;
