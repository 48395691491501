import api from "./APIService";
import {ConvertObjectToUrlParams} from "../utils/StringUtils";

const getTenApprovalCircuits = async () => {
	return await api.getAllData("projects/context/com-validation?paginate=10")
}

const getAllApprovalCircuit = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`projects/context/com-validation`, param))
}

const validateApprovalCircuit = async (id) => {
	return await api.patch(`projects/${id}/context/sales`, {stageStatus: "valid"})
}
const refuseApprovalCircuit = async (id) => {
	return await api.patch(`projects/${id}/context/sales`, {stageStatus: "canceled"})
}

const getAdminApprovalCircuit = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`projects/context/admin-validation`, param))
}

const validatePayment = async (id, status) => {
	return await api.patch(`payments/${id}`, {status: status})
}

const validateVisit = async (id, status) => {
	return await api.patch(`visits/${id}`, {valid: status})
}

const validatePaymentReminder = async (id, status) => {
	return await api.patch(`projects/${id}/context/admin`, {stageStatus: status})
}

const patchAsset = async (id) => {
	return await api.patch(`projects/${id}/context/admin`, {refundRequested: false})
}

const ApprovalCircuitService = {
	getTenApprovalCircuits,
	getAllApprovalCircuit,
	validateApprovalCircuit,
	refuseApprovalCircuit,
	getAdminApprovalCircuit,
	validatePayment,
	validateVisit,
	validatePaymentReminder,
	patchAsset
}

export default ApprovalCircuitService;
