import React, { useEffect, useState } from 'react';
import TableTitle from "../../../../../components/Molecules/Tables/TalbeTitle";
import { REVIVAL_COMMERCIAL_COLUMNS } from "../../../../../config/ArrayColumns/Admin/RevivalCommercialColumns";
import { CONTROL_COLUMNS } from "../../../../../config/ArrayColumns/Admin/ControlColumns";
import TaskService from "../../../../../services/TaskService";
import { toast } from "react-toastify";
import { ConvertObjectToUrlParams } from "../../../../../utils/StringUtils";
import { FOLDER_LATE_COLUMNS } from "../../../../../config/ArrayColumns/Admin/FolderLateColumns";
import ProjectService from "../../../../../services/ProjectService";
import TitleSectionWithButton from "../../../../../components/Molecules/Title/TitleSectionWithButton";
import moment from "moment";
import exportFileService from "../../../../../services/ExportFileService";
import Skeleton from "react-loading-skeleton";

const TableAdminHome = ({ region, dateStart, userFilter, dateEnd, stateRequest }) => {

	const [controls, setControls] = useState([]);
	const [folderLate, setFolderLate] = useState([]);
	const [followUpAndLitigation, setFollowUpAndLitigation] = useState([]);

	// Loading
	const [controlsLoading, setControlsLoading] = useState(true);
	const [folderLateLoading, setFolderLateLoading] = useState(true);
	const [followUpAndLitigationLoading, setFollowUpAndLitigationLoading] = useState(true);

	let todayDate = new Date();
	todayDate = moment(todayDate).format("YYYY-MM-DD");

	const getControls = async () => {
		const request = await TaskService.getControls(ConvertObjectToUrlParams("projects/context/engineer-validation", {
			"region": region,
			"users.id": userFilter,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"pagination": "true",
		}))
		if (request.status === 200) {
			setControls(request.data);
			setControlsLoading(false)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des contrôles")
		}
	}

	const getFolderLate = async () => {

		const request = await ProjectService.getAllProjects(`projects/context/engineer`, {
			"region": region,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"users.id": userFilter,
			"study.estimatedEndAt[before]": todayDate,
		})

		if (request.status === 200) {
			setFolderLate(request.data);
			setFolderLateLoading(false)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des dossier en retard de rendu")
		}
	}

	const getFollowUpAndLitigation = async () => {
		const request = await ProjectService.getAllProjects(`projects/context/admin`, {
			"stage.title": "ADMIN_payment-follow-up",
			"region": region,
			"users.id": userFilter,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"active": "true",
			"pagination": "true",
		})
		if (request.status === 200) {
			setFollowUpAndLitigation(request.data)
			setFollowUpAndLitigationLoading(false)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des relances et suivi litige")
		}
	}

	const downloadFile = () => {
		const date = moment().format("L").split("/").join("-")
		exportFileService.exportFile(`/admin/controls`, `export-controles-${date}`, {
			region: region,
			dateStart: dateStart,
			dateEnd
		}, "xlsx")
	}

	useEffect(() => {
		getFolderLate();
		getFollowUpAndLitigation()
		getControls();
	}, [region, dateStart, dateEnd, userFilter, stateRequest])

	return (
		<>
			{
				followUpAndLitigationLoading ? <Skeleton height={500} /> : (
					<TableTitle title={"Relance et suivi litige"} className={"section-array-revival mbe-section"}
						data={followUpAndLitigation} columnTable={REVIVAL_COMMERCIAL_COLUMNS} />
				)
			}
			{
				folderLateLoading ? <Skeleton height={500} /> : (
					<TableTitle title={"Dossiers en retard de rendu"} className={"section-array-revival mbe-section"} data={folderLate}
						columnTable={FOLDER_LATE_COLUMNS} />
				)
			}

			<TitleSectionWithButton title={"Contrôles qualités"} buttonTitle={"Exporter"} isActive={true}
				styledButton={"btn-default btn-default-blue btn-download color-primary"}
				onClick={downloadFile}
			/>
			{
				controlsLoading ? <Skeleton height={500} /> : (
					<TableTitle title={""} className={"section-array-revival mbe-section"} data={controls}
						columnTable={CONTROL_COLUMNS} numberElement={20} />
				)
			}
		</>
	);
};

export default TableAdminHome;
