import React from 'react';
import TitlePopUpWithCloseArrow from "../../Atomes/Title/TitlePopUpWithCloseArrow";


const PopUpForm = ({show, onClick, form, title, large}) => {
	return (
		<div className={"pop-up-base-style " + (show ? "active" : "")}>
			<div className={"pop-up-form " + (show ? "active " : "") + (large ? "large " : "")}>
				<div className={"pop-up-form-container"}>
					<TitlePopUpWithCloseArrow onClick={onClick} title={title}/>
					{/*Call to form pass in props*/}
					{form}
				</div>
			</div>
		</div>
	);
};

export default PopUpForm;
