import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from "react-router-dom";

import { toast } from 'react-toastify';
import { Form, Formik } from "formik";
import { initialValuesBookingSlot } from '../../../../config/InitialValues/BookingSlot/initialiValuesBookingSlot';
import { SchemaBookSlot } from '../../../../schemas/BookSlot/SchemaBookSlot';

import ProjectHeader from '../../Partials/ProjectHeader';
import ProjectNav from '../../Partials/ProjectNav';
import MbeBookSlotSlider from '../../../../components/Molecules/BookSlot/MbeBookSlotSlider';
import InputLabelErrorMessage from '../../../../components/Atomes/Formik/InputLabelErrorMessage';
import ButtonSubmit from '../../../../components/Atomes/Buttons/ButtonSubmit';

import { ProjectStageService } from '../../../../services/ProjectStageService';
import BookingService from '../../../../services/BookingService';

const ProjectStageVisite = () => {

  const { projectId } = useParams();
  const [projectStepVerificator, setProjectStepVerificator] = useState(false);
  const [project, setProject] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [bookingsLoading, setBookingsLoading] = useState(true);
  const [isBookingSlotSelected, setIsBookingSlotSelected] = useState(false);
  const [bookingSlotRequestPost, setBookingSlotRequestPost] = useState(false);
  const [bookingSlotStartAt, setBookingSlotStartAt] = useState("");
  const [bookingSlotEndAt, setBookingSlotEndAt] = useState("");
  const [isCustomerPresent, setIsCustomerPresent] = useState(true);

  const navigate = useNavigate();

  const getProjectData = async () => {
    let projectInfos = await ProjectStageService.getSingleProject(projectId);
    if (projectInfos.stage === 'ING_report-visit' || ((projectInfos.stage === 'ING_visit' || projectInfos.stage === 'ADMIN_validate-visit') && !projectInfos.visit)) {
      setProject(projectInfos);
      let request = await BookingService.getBookings({ 'region': projectInfos.region, 'project.id' : projectId , 'longVisit': projectInfos.visitType === 't2' ? "true" : "false" })
      if (request.request.status === 200) {
        setBookings(request.data.shifts)
        setBookingsLoading(false)
      }
      setProjectStepVerificator(true);
    } else {
      navigate(`/projet/${projectId}/`, { replace: true });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectData();
  }, []);


  const selectBookingSlot = (e) => {
    setIsBookingSlotSelected(true)
    setBookingSlotStartAt(e.target.attributes["attr-startat"].value);
    setBookingSlotEndAt(e.target.attributes["attr-endat"].value);
  }

  const bookSlot = async (values) => {
    let data = {
      "startAt": bookingSlotStartAt,
      "endAt": bookingSlotEndAt,
      "projectIri": `/api/projects/${projectId}`,
      "clientPresent": isCustomerPresent,
      "contactFirstname": !isCustomerPresent ? values.firstName : null,
      "contactLastname": !isCustomerPresent ? values.lastName : null,
      "contactPhone": !isCustomerPresent ? values.phone : null,
      "contactMore": !isCustomerPresent ? values.additionalInformation : null
    }
    let request = await BookingService.postBooking("bookings", data)
    if (request.request.status === 201) {
      setIsBookingSlotSelected(false)
      setIsCustomerPresent(true)
      setBookingSlotRequestPost(true)
      setTimeout(() => {
        setBookingSlotRequestPost(false)
      }, 2000)
      toast.success("Votre réservation de visite a été faite avec succès")
      navigate('/projet/' + projectId + '/confirmation-visite/')
    } else {
      toast.error("Une erreur est survenue lors de la création du rendez-vous")
    }
  }

  return (
    <>
      {
        !projectStepVerificator ? (
          <>
            <Skeleton height={300} />
            <Skeleton height={40} />
          </>
        ) : (
          <>
            <ProjectHeader
              project={project}
            />

            <section className='project-container'>
              <ProjectNav
                step={2}
                visit={project.visitType}
              />

              <div className="project-container-step__header">
                <h3 className="color-black">Choisissez le rendez-vous qui vous convient</h3>
                <p className="text-primary">Sélectionner la date et le créneau horaire qui vous convient. Si vous avez une question, nous vous invitons à contacter votre commercial.</p>
              </div>

              {(bookingSlotRequestPost || bookingsLoading) && (<Skeleton height={200} />)}

              {!bookingSlotRequestPost && !bookingsLoading && (
                <MbeBookSlotSlider
                  bookings={bookings}
                  selectBookingSlot={selectBookingSlot}
                  bookingSlotCreator={true}
                />
              )}

              <section className='mbe-bookingSlot-steps'>

                {isBookingSlotSelected &&
                  <div className='mbe-bookingSlot-steps-choice'>
                    <p className={"text-primary color-dark-grey"}>Serez-vous présent lors de la visite ?</p>
                    <div className='mbe-bookingSlot-steps-choice-inputs'>
                      <div>
                        <input type="radio" name='mbe-bookingSlot-input' id="mbe-bookingSlot-inputYes"
                          onClick={() => {
                            setIsCustomerPresent(true)
                          }}
                          checked={isCustomerPresent}
                          onChange={e => { }}
                        />
                        <label htmlFor="mbe-bookingSlot-inputYes" name='mbe-bookingSlot-input'
                          className={"text-primary color-primary"}
                          onClick={() => { setIsCustomerPresent(true) }}> Oui </label>
                      </div>
                      <div>
                        <input type="radio" id="mbe-bookingSlot-inputNo" name="mbe-bookingSlot-input"
                          onClick={() => {
                            setIsCustomerPresent(false)
                          }}
                        />
                        <label htmlFor="mbe-bookingSlot-inputNo" name='mbe-bookingSlot-input'
                          className={"text-primary color-primary"}
                          onClick={() => { setIsCustomerPresent(false) }}> Non </label>
                      </div>
                    </div>
                  </div>
                }
                {isCustomerPresent && isBookingSlotSelected &&
                  <ButtonSubmit title={"Valider rendez-vous"} isActive={!isBookingSlotSelected}
                    styleButton='btn-default btn-default-primary'
                    onClick={bookSlot}
                  />
                }

                {!isCustomerPresent &&
                  <div className='mbe-bookingSlot-steps-form'>
                    <h4>Veuillez renseigner les coordonnées de la personne présente lors de la visite :</h4>
                    <Formik
                      initialValues={initialValuesBookingSlot}
                      validationSchema={SchemaBookSlot}
                      onSubmit={bookSlot}
                    >
                      {() => (
                        <Form>
                          <div className={"form-row-element form-row-two-elements"}>
                            <InputLabelErrorMessage label={"Prénom"} name={"firstName"}
                              type={"text"} />
                            <InputLabelErrorMessage label={"Nom"} name={"lastName"} type={"text"} />
                          </div>

                          <div className={"form-row-element form-row-two-elements"}>
                            <InputLabelErrorMessage label={"Téléphone"} name={"phone"} type={"text"} />
                            <InputLabelErrorMessage label={"Informations complémentaires"}
                              name={"additionalInformation"} type={"text"} />
                          </div>

                          <ButtonSubmit title={"Valider rendez-vous"} isActive={false}
                            styleButton={"btn-default btn-default-secondary plain"} />
                        </Form>
                      )}
                    </Formik>
                  </div>
                }

              </section>

            </section>
          </>
        )
      }
    </>

  )
}

export default ProjectStageVisite;