import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

import CardPartnerMarketplace from '../../../components/Molecules/Cards/PartnerMarketplace/CardPartnerMarketplace';
import PopupMarketplaceCustomer from './Popup/PopupMarketplace';

import { ProjectStageService } from '../../../services/ProjectStageService';

const ProjectSectionMarketplace = () => {

  const projectId = useParams().projectId;
  const [showPopup, setShowPopup] = useState(false);
  const [partnerSelected, setPartnerSelected] = useState(null);
  const [partners, setPartners] = useState([]);
  const [partnersLoaded, setPartnersLoaded] = useState(false);

  useEffect(() => {
    getPartners();
  }, []);

  const getPartners = async () => {
    const request = await ProjectStageService.getPartnersActive();
    if (request.status === 200) {
      let datas = request.data['hydra:member'];
      let partnersSorted = [];
      datas.map((data) => {
        if (partnersSorted[data.niceCategory] === undefined) {
          partnersSorted[data.niceCategory] = [];
        }
        partnersSorted[data.niceCategory].push(data);
      });
      setPartnersLoaded(true);
      setPartners(partnersSorted);
    }
  }

  const openPopup = (partner) => {
    setPartnerSelected(partner);
    setShowPopup(true);
  };

  const closeAction = () => {
    setShowPopup(false);
  };

  const validAction = async () => {
    let request = await ProjectStageService.postDataToPartner(partnerSelected.id, { "projectIri": `/api/projects/${projectId}` });
    if (request.status === 200) {
      toast.success('Votre demande a bien été envoyée');
      setShowPopup(false);
    }
  }

  return (
    <>
      <div className='customer-marketplace'>
        <div className='customer-marketplace__heading'>
          <h3>C'est le moment de parcourir notre marketplace 🎉</h3>
          <p className="text-primary">Découvrez nos partenaires qui peuvent vous accompagner dans la suite de votre projet. Cliquez sur un de nos partenaires pour qu'il puisse vous recontacter et échanger avec vous sur vos besoins.</p>
        </div>
        <div className='customer-marketplace__category'>
          {
            !partnersLoaded ? <Skeleton count={3} height={400} /> : (
              Object.keys(partners).map((partner, index) => (
                <div className='customer-marketplace__category' key={index}>
                  <h3>{partner}</h3>
                  <div className='customer-marketplace__category__items'>
                    {
                      partners[partner].map((partner, index) => (
                        <CardPartnerMarketplace
                          key={index}
                          partner={partner}
                          index={index}
                          openPopup={openPopup}
                        />
                      ))
                    }
                  </div>
                </div>
              ))
            )}
        </div>
      </div>

      {
        showPopup && (
          <PopupMarketplaceCustomer
            showPopup={showPopup}
            closeAction={closeAction}
            validAction={validAction}
            titlePartner={partnerSelected.title}
          />
        )
      }
    </>

  )
};

export default ProjectSectionMarketplace;