const PopUpAddPdfMedia = ({showPopup, closeAction, validAction}) => {

	return (
		<div className={`pop-up-base-style ${showPopup ? 'active' : ""}`}>
			<div className={"pop-up-simple container-s center"}>
				<img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} onClick={closeAction}/>
				<img className="pop-up-simple-img" src={"/images/medias/popup.svg"}/>
				<p className={"text-primary color-primary paragraph_bold"}>Vous êtes sur le point d'ajouter un pdf a
					l'annexe</p>
				<p className={"text-primary color-primary paragraph_bold"}>Souhaitez-vous valider votre action ?</p>
				<div className={"pop-up-simple-btns"}>
					<button className={"btn-default btn-cancel"} onClick={closeAction}>Annuler</button>
					<button className={"btn-default btn-valid"} onClick={validAction}>Ajouter</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpAddPdfMedia;
