import React from "react";
import { Link } from "react-router-dom";

export const ARCHITECT_CLIENT_COLUMNS = [
	{
		Header: "",
		accessor: "avatar",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.id}`}>
					<img src={process.env.REACT_APP_PROD_URL + cellInfo.row.original.urlAvatar} alt="avatar" />
				</Link> 
			)
		}
	},
	{
		Header: "Numéro client",
		accessor: "reference"
	},
	{
		Header: "Civilité",
		accessor: "niceGender",
		Cell: cellInfo => {
			return (
				<span> {cellInfo.row.original.niceGender !== "" ? cellInfo.row.original.niceGender : "NA"}</span>
			)
		}
	},
	{
		Header: "Nom client",
		accessor: "client",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.id}`}>{cellInfo.row.original.niceNames}</Link>
			)
		}
	},
	{
		Header: "Nombre de projets",
		accessor: "projectCount"
	},
	{
		Header: "Téléphone",
		accessor: "phone"
	},
	{
		Header: "Adresse e-mail",
		accessor: "email"
	},
	// {
	// 	Header: "Adresse du projet",
	// 	accessor: "address"
	// }
]