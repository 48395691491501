import { NavLink } from "react-router-dom";
import React from "react";
import moment from "moment";

export const ARCHITECT_QUOTES_IN_PROGRESS_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Nom client",
		accessor: "nom_client",
		Cell: cellInfo => {
			return (
				<NavLink to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.firstname + ' ' + cellInfo.row.original.client.lastname}</NavLink>
			)
		}
	},
	{
		Header: "Typologie dossier d'étude",
		accessor: "type"
	},
	{
		Header: "Date devis",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = "NA";
			if (cellInfo.row.original.createdAt) {
				date = moment(new Date(cellInfo.row.original.createdAt)).format("DD/MM/YYYY");
			}
			return date
		}
	},
	{
		Header: "Téléphone",
		accessor: "client.phone"
	},
	// {
	// 	Header: "Adresse e-mail",
	// 	accessor: "client.email"
	// },
	{
		Header: "Adresse projet",
		accessor: "address.street",
		Cell: cellInfo => {
			return (
				<span>{cellInfo.row.original.address.street}, {cellInfo.row.original.address.postcode} {cellInfo.row.original.address.city}</span>
			)
		}
	}
]