import React, {useEffect, useState} from 'react';
import InputSearchChat from "../Partials/InputSearchChat";
import ChatMessage from "../Partials/ChatMessage";
import ChatAllMessage from "./ChatAllMessage";
import DirRespService from "../../../services/Ing/DirResp/DirRespService";
import {toast} from "react-toastify";
import {useAuth} from "../../../services/useAuth";

const ChatContainer = ({
	                       onClickClose,
	                       onChangeSearchValue,
	                       onChangeSearch,
	                       name,
	                       chat,
	                       newClientMessages,
	                       newInterneMessages
                       }) => {
	const [activeChat, setActiveChat] = useState("")
	const [allChat, setAllChat] = useState(chat)

	const {role} = useAuth();

	const [messageClientContent, setMessageClientContent] = useState({messages: []})
	const [messageInterneContent, setMessageInterneContent] = useState({messages: []})
	const [title, setTitle] = useState("")
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setAllChat(chat)
	}, [chat]);

	const changeActiveConversation = async (id) => {
		setLoading(true)
		setActiveChat(id)

		let title = allChat.find((item) => item.id === id)
		title.read = true
		title = `${title.clientGender ? title.clientGender : ""} ${title.clientName} - ${title.projectReference} - ${title.projectType}`
		setTitle(title)

		if (role[0] !== "ROLE_CLIENT") {
			const requestInternes = await DirRespService.getInterneMessages(id)
			if (requestInternes.status === 200) {
				setMessageInterneContent(requestInternes.data)
			} else {
				toast.error("Erreur lors de la récupération des messages internes")
			}
		}
		const requestClients = await DirRespService.getClientMessages(id)
		if (requestClients.status === 200) {
			setMessageClientContent(requestClients.data)

			setLoading(false)
		} else {
			toast.error("Erreur lors de la récupération des messages clients")
		}
	}

	useEffect(() => {
		if (role[0] !== "ROLE_CLIENT") {
			if (newInterneMessages !== "" && newInterneMessages.chatId === activeChat) {
				setMessageInterneContent(prevState => ({
					...prevState,
					messages: [...prevState.messages, newInterneMessages]
				}));

				DirRespService.getInterneMessages(activeChat)
			}

			if (newInterneMessages !== "" && newInterneMessages.chatId !== activeChat) {
				setAllChat(prevState => {
					let index = prevState.findIndex(item => item.id === newInterneMessages.chatId)
					prevState[index].read = false
					return [...prevState]
				})
			}
		}
	}, [newInterneMessages]);

	useEffect(() => {
		if (newClientMessages !== "" && newClientMessages.chatId === activeChat) {
			setMessageClientContent(prevState => ({
				...prevState,
				messages: [...prevState.messages, newClientMessages]
			}));

			DirRespService.getClientMessages(activeChat)
		}

		if (newClientMessages !== "" && newClientMessages.chatId !== activeChat) {
			setAllChat(prevState => {
				let index = prevState.findIndex(item => item.id === newClientMessages.chatId)
				prevState[index].read = false
				return [...prevState]
			})
		}
	}, [newClientMessages]);

	return (
		<div className={"section-chat-container"}>
			<div className={"section-chat-container-left"}>
				<h4 className={"text-primary color-black"}>Conversations ({allChat.length})</h4>
				<InputSearchChat placeholder={"Recherchez un nom client ou un mot"} onChangeSearchValue={onChangeSearchValue}
				                 onChangeSearch={onChangeSearch}
				                 name={name}/>
				<div className={"section-chat-container-left-all-messages"}>
					{

						allChat.map((message, index) => {
							return <ChatMessage key={index} id={message.id}
							                    title={`${message.clientGender ? message.clientGender : ""} ${message.clientName} - ${message.projectReference} - ${message.projectType}`}
							                    date={message.lastMessage ? message.lastMessage.sentAt : null}
							                    isNew={!message.read}
							                    isActive={message.id === activeChat}
							                    onClick={changeActiveConversation}/>
						})
					}

				</div>

			</div>

			<div className={"section-chat-container-right"}>

				{
					activeChat !== "" ? (
						<>
							<div className={"section-chat-container-right-container"}>

								<h4 className={"text-primary color-black"}>{title}</h4>
								{
									!loading ? (
										<ChatAllMessage idChat={activeChat} messagesClient={messageClientContent}
										                messagesInterne={messageInterneContent}/>
									) : null
								}

							</div>


						</>
					) : (
						<div className={"section-chat-container-right-container-no-message"}>
							<h4 className={"text-primary color-black"}>Aucune conversation sélectionnée</h4>
						</div>
					)
				}

				<div className={"section-chat-container-right-close"}>
					<img src={"/images/pictos/cross-close.svg"} alt={"close chat"} onClick={onClickClose}/>
				</div>


			</div>
		</div>
	);
};

export default ChatContainer;
