import api from "./APIService";

const getInvoiceOnAccount = async (url) => {
	return await api.getAllData(url)
}

const patchBills = async (url, data) => {
		return await api.patch(url, data)
}

const postAssetGenerator = async (url, data) => {
	return await api.postBody(url, data)
}

export const BillService  = {
	getInvoiceOnAccount,
	patchBills,
	postAssetGenerator
}