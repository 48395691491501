import { useFormikContext } from "formik";
import ButtonSubmit from "../../../../../../../components/Atomes/Buttons/ButtonSubmit";
import InputLabelErrorMessage from "../../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import { useEffect } from "react";

const StudyFolderExecutionPartial = ({executionClass}) => {

    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
        setFieldValue("question0", executionClass.question0)
        setFieldValue("question1", executionClass.question1)
        setFieldValue("question2", executionClass.question2)
        setFieldValue("question3", executionClass.question3)
        setFieldValue("executionClassResult1", executionClass.executionClassResult1 )
        setFieldValue("executionClassResult2", executionClass.executionClassResult2)

    }, [executionClass])

    useEffect(() => {
        calculateMatrixFirstPart(question1Select[values.question0]?.note, question2Select[values.question1]?.note)
    }, [values.question1, values.question0])

    useEffect(() => {
        if(values.question2 !== "" && values.question3 !== "") {
            calculateMatrixSecondPart(question3Select[values.question2]?.note, question4Select[values.question3]?.note)
        }
    }, [values.question0, values.question1, values.question2, values.question3])

    const calculateMatrixFirstPart = (question0Note, question1Note) => {
        const matrix = {
            'CCO.1': { A: 'CC1', B: 'CC1', C: 'CC1', D: 'CC1', 'D+': 'CC2', E: 'CC1', 'E+': 'CC2' },
            'CCO.2a': { A: 'CC1', B: 'CC1', C: 'CC2', D: 'CC1', 'D+': 'CC2', E: 'CC2', 'E+': 'CC2' },
            'CCO.2b': { A: 'CC1', B: 'CC1', C: 'CC2', D: 'CC2', 'D+': 'CC2', E: 'CC2', 'E+': 'CC3' },
            'CCO.3': { A: 'CC1', B: 'CC1', C: 'CC2', D: 'CC2', 'D+': 'CC3', E: 'CC3', 'E+': 'CC3' }
        };

        if (matrix[question1Note] && matrix[question1Note][question0Note]) {
            setFieldValue("executionClassResult1", matrix[question1Note][question0Note])
            document.getElementById('answer1').innerText = "La classes de conséquences à retenir est donc la suivante : " + matrix[question1Note][question0Note];
        }
        return '';
    };

    const calculateMatrixSecondPart = (question2Note, question3Note) => {
        const matrix = {
            CC1: {
                SC1: {
                    PC1: "EXC1",
                    PC2: "EXC2"
                },
                SC2: {
                    PC1: "EXC2",
                    PC2: "EXC2"
                }
            },
            CC2: {
                SC1: {
                    PC1: "EXC2",
                    PC2: "EXC2"
                },
                SC2: {
                    PC1: "EXC3",
                    PC2: "EXC3"
                }
            },
            CC3: {
                SC1: {
                    PC1: "EXC3",
                    PC2: "EXC3"
                },
                SC2: {
                    PC1: "EXC3",
                    PC2: "EXC4"
                }
            }
        }
        if (matrix[values.executionClassResult1] && matrix[values.executionClassResult1][question2Note][question3Note]) {
            setFieldValue("executionClassResult2", matrix[values.executionClassResult1][question2Note][question3Note])
            document.getElementById('answer2').innerText = "La classe d'exécution à respecter pour ce projet est la suivante : " + matrix[values.executionClassResult1][question2Note][question3Note];
        }
    }

    const question1Select = [
        {
            "value": 0,
            "label": "Eléments secondaires ne participant pas à la stabilité générale",
            "note": "A"
        },
        {
            "value": 1,
            "label": "Eléments de circulation courants",
            "note": "B"
        },
        {
            "value": 2,
            "label": "Eléments de plancher",
            "note": "C"
        },
        {
            "value": 3,
            "label": "Supports d’équipements industriels",
            "note": "D"
        },
        {
            "value": 4,
            "label": "Supports d’équipements industriels lourds",
            "note": "D+"
        },
        {
            "value": 5,
            "label": "Eléments courants de structure principale",
            "note": "E"
        },
        {
            "value": 6,
            "label": "Eléments complexes de structure",
            "note": "E+"
        }
    ]

    const question2Select = [
        {
            "value": 0,
            "label": "Maisons individuelles",
            "note": "CCO.1"
        },
        {
            "value": 1,
            "label": "Bâtiments agricoles",
            "note": "CCO.1"
        },
        {
            "value": 2,
            "label": "Bâtiments peu fréquentés, dont aucune partie ne se situe à une distance d’un autre bâtiment ou d’une zone fréquentée, inférieure à 1,5 fois leur hauteur (par exemple petit stockage, activité artisanale unique)",
            "note": "CCO.1"
        },
        {
            "value": 3,
            "label": "Bâtiments d’habitation collective, d’hôtellerie, et de bureaux jusqu’à R+3",
            "note": "CCO.2a"
        },
        {
            "value": 4,
            "label": "Bâtiments industriels de hauteur jusqu’à 8 m à la sablière",
            "note": "CCO.2a"
        },
        {
            "value": 5,
            "label": "Locaux de vente au détail jusqu’à R+2, surface de plancher par niveau inférieure à 1000 m2",
            "note": "CCO.2a"
        },
        {
            "value": 6,
            "label": "Autres bâtiments recevant du public, jusqu’à R+1, surface de plancher par niveau inférieure à 2000 m2",
            "note": "CCO.2a"
        },
        {
            "value": 7,
            "label": "Parkings aériens couverts à simple rez-de-chaussée",
            "note": "CCO.2a"
        },
        {
            "value": 8,
            "label": "Bâtiments d’habitation, d’hôtellerie, de bureaux, et locaux de vente au détail jusqu’à 28 mètres de hauteur",
            "note": "CCO.2b"
        },
        {
            "value": 9,
            "label": "Bâtiments scolaires",
            "note": "CCO.2b"
        },
        {
            "value": 10,
            "label": "Bâtiments industriels de hauteur au-delà de 8 m à la sablière",
            "note": "CCO.2b"
        },
        {
            "value": 11,
            "label": "Autres bâtiments recevant du public, jusqu’à 28 mètres de hauteur, surface de plancher par niveau inférieure à 5000 m2",
            "note": "CCO.2b"
        },
        {
            "value": 12,
            "label": "Autres bâtiments accueillant plus de 300 personnes en fonctionnement normal",
            "note": "CCO.2b"
        },
        {
            "value": 13,
            "label": "Parkings aériens jusqu’à R+5",
            "note": "CCO.2b"
        },
        {
            "value": 14,
            "label": "Bâtiments définis en CCO.2b en dehors des limites fixées",
            "note": "CCO.3"
        },
        {
            "value": 15,
            "label": "Tous bâtiments de catégorie d’importance IV au sens de l’arrêté du 22 octobre 2010* [7]",
            "note": "CCO.3"
        },
        {
            "value": 16,
            "label": "Bâtiments abritant des substances ou produits dangereux (SEVESO seuil haut et bas)",
            "note": "CCO.3"
        }
    ]

    const question3Select = [
        {
            "value": 0,
            "label": "Eléments structuraux calculés pour des actions quasi statiques (a), sauf cas définis en SC2",
            "note": "SC1"
        },
        {
            "value": 1,
            "label": "Eléments structuraux calculés pour des actions de fatigue exercées par des ponts roulants de classe S0 (b)",
            "note": "SC1"
        },
        {
            "value": 2,
            "label": "Eléments structuraux avec leurs assemblages calculés pour des actions sismiques dans la classe de ductilité DCL et DCL+©",
            "note": "SC1"
        },
        {
            "value": 3,
            "label": "Eléments structuraux calculés pour des actions de fatigue exercées par des ponts roulants de classe S1 à S9 (b)",
            "note": "SC2"
        },
        {
            "value": 4,
            "label": "Eléments structuraux calculés pour des actions dynamiques induites par la foule (d) ou les machines tournantes",
            "note": "SC2"
        },
        {
            "value": 5,
            "label": "Eléments structuraux avec leurs assemblages, calculés pour des actions sismiques dans les classes de ductilité DCM et DCH€",
            "note": "SC2"
        },
        {
            "value": 6,
            "label": "Structures sensibles aux instabilités aéroélastiques ou au détachement tourbillonnaire",
            "note": "SC2"
        }
    ]

    const question4Select = [
        {
            "value": 0,
            "label": "Éléments non soudés fabriqués à partir de produits en acier, quelles que soient leurs nuances",
            "note": "PC1"
        },
        {
            "value": 1,
            "label": "Éléments soudés fabriqués à partir de produits de nuance d'acier inférieure à S355",
            "note": "PC1"
        },
        {
            "value": 2,
            "label": "Soudures âme /semelle de PRS de nuance d'acier inférieure ou égale à S355",
            "note": "PC1"
        },
        {
            "value": 3,
            "label": "Eléments soudés (toutes nuances) comportant des assemblages de continuité par soudures bout à bout",
            "note": "PC2"
        },
        {
            "value": 4,
            "label": "Éléments soudés fabriqués à partir de produits de nuance d'acier supérieure ou égale à S355",
            "note": "PC2"
        },
        {
            "value": 5,
            "label": "Éléments essentiels à l'intégrité de la structure qui sont assemblés par soudage sur le chantier de construction",
            "note": "PC2"
        },
        {
            "value": 6,
            "label": "Éléments devant subir un formage à chaud ou un traitement thermique au cours de la fabrication",
            "note": "PC2"
        },
        {
            "value": 7,
            "label": "Éléments de treillis tubulaires nécessitant des découpes en gueule de loup",
            "note": "PC2"
        },
        {
            "value": 8,
            "label": "Assemblages particuliers tels que certains inserts à goujons",
            "note": "PC2"
        }
    ]

    return (
        <>
            <h4 className={"color-primary text-primary"}>Choix de la classe de conséquences de l'ouvrage</h4>

            <div className={"form-row-element "}>

                <InputLabelErrorMessage type={"select"} label={"Choix de la classe de conséquences de l'ouvrage"}
                    name={"question0"}
                    keyLabel={"label"} keyValue={"value"} data={question1Select}
                />
            </div>

            <h4 className={"color-primary text-primary"}>Définition des familles d’éléments structuraux</h4>


            <div className={"form-row-element "}>

                <InputLabelErrorMessage type={"select"} label={"Définition des familles d’éléments structuraux"}
                    name={"question1"}
                    keyLabel={"label"} keyValue={"value"} data={question2Select} />
            </div>

            <div className={"form-row-element"}>
                <p id="answer1" className="text-primary"></p>
            </div>


            <h4 className={"color-primary text-primary"}>Choix de la catégorie de service</h4>

            <div className={"form-row-element "}>

                <InputLabelErrorMessage type={"select"} label={"Choix de la catégorie de service"}
                    name={"question2"}
                    keyLabel={"label"} keyValue={"value"} data={question3Select} />
            </div>

            <h4 className={"color-primary text-primary"}>Choix de la catégorie de production</h4>

            <div className={"form-row-element "}>

                <InputLabelErrorMessage type={"select"} label={"Choix de la catégorie de production"}
                    name={"question3"}
                    keyLabel={"label"} keyValue={"value"} data={question4Select} />
            </div>

            <div className={"form-row-element"}>
                <p id="answer2" className="text-primary"></p>
            </div>


            <ButtonSubmit styleButton={"btn-default btn-default-secondary plain"}
                title={"Sauvegarder"} />
        </>

    )
}

export default StudyFolderExecutionPartial;