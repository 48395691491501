import React, {useState} from 'react';
import PopUpForm from "../../PopUp/PopUpForm";
import FormIngCollaborator from "../../Form/Engineer/FormIngCollaborator";
import FormIngAssignProject from "../../Form/Engineer/FormIngAssignProject";
import FormIngEditProject from "../../Form/Engineer/FormIngEditProject";
import {dataRolesSelect} from "../../../../utils/UserRolesUtils";

const CardCollaborator = ({collaborator, allCompetences, allProjects, allRoles, updateData, updateDataProjet}) => {
	const [showPopUp, setShowPopUp] = useState(false);
	const [showPopUpAssignProject, setShowPopUpAssignProject] = useState(false);
	const [showPopUpEditProject, setShowPopUpEditProject] = useState(false);
	const [selectedProgressItem, setSelectedProgressItem] = useState(null);

	const role = dataRolesSelect.find(role => role.value === collaborator.highestRole);

	return (
		<>
			<div className={"card-collaborator"} onClick={() => setShowPopUp(true)}>
				<div className={"card-collaborator-title"}>
					<img src={`${process.env.REACT_APP_PROD_URL}${collaborator.urlAvatar}`}/>
					<div className={"card-collaborator-title-text"}>
						<h4 className={"color-primary"}>{collaborator.niceNames}</h4>
						<p className={"text-primary color-primary"}>{collaborator.job}</p>
					</div>
				</div>
				<div className={"card-collaborator-contact"}>
					<div className={"card-collaborator-contact-email"}>
						<img src={"/images/pictos/letter.svg"} alt={"email img"}/>
						<p className={"text-micro paragraph_bold color-primary"}>{collaborator.email}</p>
					</div>
					<div className={"card-collaborator-contact-phone"}>
						<img src={"/images/pictos/phone.svg"} alt={"phone img"}/>
						<p className={"text-micro paragraph_bold color-primary"}>{collaborator.phone}</p>
					</div>
				</div>
				<div className={"card-collaborator-activity"}>
					{
						collaborator.tags ?
							collaborator.tags.map((activity, index) => {
								return (
									<div key={index} className={"card-collaborator-activity-item"}>
										<span className={`pin-${activity.color}`}/>
										<p className={"text-micro color-primary"}>{activity.title}</p>
									</div>
								)
							}) : null
					}
				</div>
			</div>
			{
				showPopUp ? (
					<PopUpForm onClick={() => setShowPopUp(false)}
					           large={false}
					           form={<FormIngCollaborator
						           setShowPopUp={setShowPopUp}
						           collaborator={collaborator}
						           allProjects={allProjects}
						           allRoles={allRoles}
						           updateData={updateData}
						           setShowPopUpAssignProject={() => setShowPopUpAssignProject(true)}
						           showPopUpEditProject={() => setShowPopUpEditProject(true)}
						           selectedProgressItem={(progressItem) => setSelectedProgressItem(progressItem)}
					           />}
					           show={showPopUp}
					/>
				) : null
			}
			{
				showPopUpAssignProject ? (
					<PopUpForm onClick={() => setShowPopUpAssignProject(false)}
					           large={false}
					           form={<FormIngAssignProject setShowPopUpAssignProject={setShowPopUpAssignProject}
					                                       collaborator={collaborator}
					                                       updateDataProjet={updateDataProjet}
					                                       allProjects={allProjects}
					                                       allRoles={allRoles}
					           />}
					           show={showPopUpAssignProject}
					/>
				) : null
			}
			{
				showPopUpEditProject ? (
					<PopUpForm onClick={() => setShowPopUpEditProject(false)}
					           large={false}
					           form={<FormIngEditProject setShowPopUpEditProject={setShowPopUpEditProject}
					                                     collaborator={collaborator}
					                                     allProjects={allProjects}
					                                     allRoles={allRoles}
					                                     progressItem={selectedProgressItem}
					           />}
					           show={showPopUpEditProject}
					/>
				) : null
			}
		</>

	);
};

export default CardCollaborator;
