import React from 'react';
import Calendar from 'react-calendar';

const SelectRangeDates = ({ isActive, onChange, date, 
							validDateSelected, resetDateSelected}) => {
	return (
		<>
			{isActive && 
				<div className='popup-range-dates'>
					<Calendar
						onChange={onChange}
						defaultActiveStartDate={new Date()}
						value={date}
						selectRange={true}
						showDoubleView={true}
						locale={"fr-FR"}
						shouldCloseOnSelect={true}
					/>
				
					<div className='popup-range-dates-validBtn' onClick={validDateSelected}>
						<span onClick={resetDateSelected}>
							Restaurer par défaut
						</span>
						<span onClick={validDateSelected}>
							Valider la sélection
						</span>
					</div>
					
				</div>
			}
		</>
	)
};

export default SelectRangeDates;
