import React from 'react';
import {Route, Routes, Navigate} from "react-router-dom";
import * as URL from "../config/UrlConstants";
import Login from "../views/User/Login/Login";
import ForgetPassword from '../views/User/Password/Forget/ForgetPassword';
import NewPassword from '../views/User/Password/New/NewPassword';


const PublicRoutes = () => {
	return (
		<Routes>
			<Route exact path={URL.LOGIN_VIEW_PATH} element={<Login/>}/>
			<Route exact path={URL.FORGOT_PASSWORD_VIEW_PATH} element={<ForgetPassword/>}/>
			<Route exact path={URL.NEW_PASSWORD_VIEW_PATH} element={<NewPassword/>}/>
			{/* Route pour toutes les autres URL */}
            <Route path="*" element={<Navigate to={URL.LOGIN_VIEW_PATH} replace />} />
		</Routes>
	);
};

export default PublicRoutes;
