import { useContext } from "react"

import RequestService from "../../../services/RequestService";
import { StateRequest } from "../../../views/Pages/Coms/Com/Home/Home";

const PopUpArchiveFile = ({idProject, reference, onClickNo}) => {
    const {stateRequest, setStateRequest} = useContext(StateRequest)

    const archiveFile = async () => {
        const request = await RequestService.reopenFileArchived(idProject, {
            "status" : "archived"
        })
        onClickNo()
        if (request.request.status === 200) {
			setStateRequest('success archive')
		} else {
			setStateRequest('error')
		}
    }

    return (
        <div className={"pop-up-base-style active"}>
            <div className={"pop-up-user-delete"}>
                <img onClick={onClickNo} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"fermer la pop up"}/>
                <img src={"/images/medias/popup.svg"} alt="test"/>
                <h4>
                    Êtes-vous sur de vouloir archiver le projet {reference} ?
                </h4>
                <div className={"pop-up-user-delete-buttons"}>
                    <button className={"btn btn-cancel"} onClick={onClickNo}>Non</button>
                    <button className={"btn btn-primary"} onClick={() => archiveFile()}>Oui</button>
                </div>


            </div>
        </div>
    );
};

export default PopUpArchiveFile