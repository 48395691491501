import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import InputSearch from '../../../../components/Atomes/Inputs/InputSearch';
import Select from '../../../../components/Atomes/Select/Select';

import QuotesInProgress from "./Partials/QuotesInProgress";
import ProjectsInProgress from "./Partials/ProjectsInProgress";
import ProjectsDone from "./Partials/ProjectsDone";

import {ArchService} from '../../../../services/ArchService';

const HomeArchitect = () => {

	const [search, setSearch] = useState("");
	const [quotes, setQuotes] = useState([]);
	const [quotesLoading, setQuotesLoading] = useState(true);
	const [projectsLoading, setProjectsLoading] = useState(true);
	const [projectsInProgress, setProjectsInProgress] = useState([]);
	const [projectsDone, setProjectsDone] = useState([]);
	const [projectFilterValue, setProjectFilterValue] = useState("Tous les projets");
	const projectFilterOptions = ["Devis en cours", "Projet en cours", "Projets terminés"];

	useEffect(() => {
		getProjects();
		getQuotes();
	}, [search]);

	const getSearchValue = (value) => {
		setSearch(value);
	}

	const getProjects = async () => {
		setProjectsLoading(true);
		const request = await ArchService.getProjects({search: search});
		if (request.request.status === 200) {
			let datas = request.data['hydra:member'];
			if (datas.length > 0) {
				let projectsInProgress = [];
				let projectsDone = [];
				datas.map((project) => {
					if (project.stage === "COM_feedback") {
						projectsDone.push(project);
					} else {
						projectsInProgress.push(project);
					}
				});
				setProjectsInProgress(projectsInProgress);
				setProjectsDone(projectsDone);
			}
			setProjectsLoading(false);
		}
	}

	const getQuotes = async () => {
		setQuotesLoading(true);
		const request = await ArchService.getQuotes({search: search});
		if (request.request.status === 200) {
			let datas = request.data;
			if (datas["hydra:member"].length > 0) {
				setQuotes(datas);
			}
			setQuotesLoading(false);
		}
	}

	const projectFilter = (projectFilterOption) => {
		setProjectFilterValue(projectFilterOption);
	}

	return (
		<div className={"section-home-architect mbe-section"}>
			<div className='section-home-architect__heading'>
				<InputSearch
					placeholder={"Rechercher un projet"}
					name={"SearchProject"}
					onChangeSearch={(event) => event.key === "Enter" ? getSearchValue(event.target.value.toLowerCase()) : null}
					onChangeSearchValue={(event) => event.target.value.length === 0 ? getSearchValue("") : null}
				/>
				<Select
					styleSelect={"select-custom-secondary"}
					defaultValue={"Tous les projets"}
					value={projectFilterOptions}
					data={projectFilterOptions}
					onChange={(event) => projectFilter(event.target.value)}
				/>
				<Link to={"/chatbot"} className={"btn btn-add-project"}>
					Demande de nouveau projet
					<img src="/images/pictos/add-plus-circle-primary.svg" alt="cross add blue"/>
				</Link>
			</div>

			{
				(projectFilterValue === "Devis en cours" || projectFilterValue === "Tous les projets") && (
					quotesLoading ? (
						<Skeleton height={200}/>
					) : (
						<QuotesInProgress quotes={quotes}/>
					)
				)
			}

			{
				(projectFilterValue === "Projet en cours" || projectFilterValue === "Tous les projets") && (
					projectsLoading ? (
						<Skeleton height={200}/>
					) : (
						projectsInProgress.length > 0 && (
							<ProjectsInProgress projects={projectsInProgress}/>
						)
					)
				)
			}

			{
				(projectFilterValue === "Projets terminés" || projectFilterValue === "Tous les projets") && (
					projectsLoading ? (
						<Skeleton height={200}/>
					) : (
						projectsDone.length > 0 && (
							<ProjectsDone projects={projectsDone}/>
						)
					)
				)
			}

		</div>
	);
};

export default HomeArchitect;
