import React from 'react';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {blueJeans} from "../../../config/Color";
import Annotation from "chartjs-plugin-annotation";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Annotation);


export function ChartBar({title, legend, content, className}) {

	const data = {
		labels: content.map((item) => item.type ? item.type : item.control), datasets: [{
			label: 'CA',
			data: content.map((item) => item.quantity ? item.quantity : item.total),
			backgroundColor: blueJeans(),
		},]

	}
	const options = {
		indexAxis: 'y', responsive: true, aspectRatio: 1, barThickness: 30, borderRadius: 2, tickInterval: 1, scales: {
			x: {
				display: true,
				grid: {
					display: true, lineWidth: 0.3, color: blueJeans()
				},
				ticks: {
					stepSize: 1, borderWidth: 90, autoSkip: true, maxRotation: 90, color: blueJeans()
				}
			},
			y: {
				grid: {
					display: false
				}
			}
		}, plugins: {
			legend: {
				display: false,
			}, title: {
				display: false,
			},
		},
	};


	return (
		<div className={`${className} element-kpi element-kpi-l bars-horizontal`}>
			<div className={"element-kpi-title"}>
				<h4 className={"color-primary"}>{title}</h4>
			</div>
			<div className={"chart-bar"}>
				<Bar options={options} data={data}/>
			</div>
		</div>
	)
}

export default ChartBar;
