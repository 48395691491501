import React, {useEffect, useState} from "react";
import KpiService from "../../../../services/KpiService";
import exportFileService from "../../../../services/ExportFileService";
import FilterTwoSelectsDatepicker from "../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";
import TitleSectionWithButton from "../../../../components/Molecules/Title/TitleSectionWithButton";
import Card from "../../../../components/Atomes/Charts/Card";
import {toast} from "react-toastify";
import moment from "moment/moment";
import {ConvertObjectToUrlParams} from "../../../../utils/StringUtils";
import CollaboratorService from "../../../../services/CollaboratorService";
import KpiAdminService from "../../../../services/KpiAdminService";
import ChartBarHorizontalAdmin from "../../../../components/Atomes/Charts/ChartBarHorizontalAdmin";
import {dataRegionsArray} from "../../../../utils/RegionsUtils";

const KpiAdmin = () => {

	//VAR
	const options = dataRegionsArray

	//Date
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [date, setDate] = useState(new Date());

	const [userFilter, setUserFilter] = useState("")

	//Const
	const [collaborators, setCollaborators] = useState([]);
	const [region, setRegion] = useState("");

	//const KPI
	const [projectInProgress, setProjectInProgress] = useState(0);
	const [projectValidated, setProjectValidated] = useState(0);
	const [numberProjectWaitingPayment, setNumberProjectWaitingPayment] = useState(0);
	const [numberProjectInLate, setNumberProjectInLate] = useState(0);

	const [numberAvoir, setNumberAvoir] = useState(0);
	const [numberProjectRecouvrement, setNumberProjectRecouvrement] = useState(0);
	const [numberFolderWithProblems, setNumberFolderWithProblems] = useState([]);


	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}

	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Tous les collaborateurs") {
			setUserFilter("")
		} else {
			setUserFilter(event.target.value)
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}

	const getCollaborators = async () => {
		const urlCom = ConvertObjectToUrlParams("coms", {regions: region})
		const request = await CollaboratorService.getCollaborators(urlCom)
		if (request.request.status === 200) {
			setCollaborators(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	const getProjectInProgress = async () => {
		const request = await KpiAdminService.getProjectInProgress({region: region, dateStart: dateStart, dateEnd: dateEnd, "users.id": userFilter})
		if (request.request.status === 200) {
			setProjectInProgress(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet en cours")
		}
	}
	const getProjectValidated = async () => {
		const request = await KpiAdminService.getFolderDone({region: region, dateStart: dateStart, dateEnd: dateEnd, "users.id": userFilter})
		if (request.request.status === 200) {
			setProjectValidated(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet validé")
		}
	}
	const getNumberProjectWaitingPayment = async () => {
		const request = await KpiAdminService.getNumberFolderWaitingPayment({
			region: region,
			dateStart: dateStart,
			dateEnd: dateEnd,
			"users.id": userFilter
		})
		if (request.request.status === 200) {
			setNumberProjectWaitingPayment(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet en attente de paiement")
		}
	}
	const getNumberProjectInLate = async () => {
		const request = await KpiAdminService.getNumberFolderLate({region: region, dateStart: dateStart, dateEnd: dateEnd, "users.id": userFilter})
		if (request.request.status === 200) {
			setNumberProjectInLate(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet en cours")
		}
	}

	const getNumberAvoir = async () => {
		const request = await KpiAdminService.getNumberAvoir({region: region, dateStart: dateStart, dateEnd: dateEnd, "users.id": userFilter})
		if (request.request.status === 200) {
			setNumberAvoir(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre d'avoir")
		}
	}

	const getNumberProjectRecouvrement = async () => {
		const request = await KpiAdminService.getNumberProjectRecouvrement({region: region, dateStart: dateStart, dateEnd: dateEnd, "users.id": userFilter})
		if (request.request.status === 200) {
			setNumberProjectRecouvrement(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de projet en recouvrement")
		}
	}

	const getNumberFolderWithProblems = async () => {
		const request = await KpiAdminService.getNumberFolderWithProblems({region: region, dateStart: dateStart, dateEnd: dateEnd, "users.id": userFilter})
		if (request.request.status === 200) {
			setNumberFolderWithProblems(request.data)
		} else {
			toast.error("Une erreur est survenue lors de la récupération du nombre de dossier avec problème")
		}
	}


	useEffect(() => {
		getCollaborators()
		getProjectInProgress()
		getProjectValidated()
		getNumberProjectWaitingPayment()
		getNumberProjectInLate()
		getNumberProjectRecouvrement()
		getNumberAvoir()
		getNumberFolderWithProblems()


	}, [region, date, userFilter])

	const downloadFile = () => {
		exportFileService.exportFile("/admin/kpis", 'export-kpis', {region: region, dateStart: dateStart, dateEnd: dateEnd, "users.id": userFilter}, "xlsx")
	}

	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	return (<>
		<div className={"section-kpi-filter"}>
			<FilterTwoSelectsDatepicker
				defaultValue={"Toutes les agences"}
				options={options}
				firstValue={options}
				secondDefaultValue={"Tous les collaborateurs"}
				secondValue={collaborators.map(collaborators => collaborators.id)}
				secondOptions={collaborators.map(collaborators => collaborators.lastname)}
				styledButton={"btn-default btn-default-primary btn-default-primary color-primary"}
				titleButton={"Selectionnez une période"}
				onChange={changeRegionFilter}
				onChangeDate={(date) => changeDate(date)}
				onSecondChange={changeCollaboratorFilter}
				date={date}
				resetDateSelected={resetDate}
			/>
		</div>
			<TitleSectionWithButton title={"Indicateurs de performance"} buttonTitle={"Exporter"} isActive={true}
															styledButton={"btn-default btn-default-blue btn-download color-primary"}
															onClick={downloadFile}
			/>
		<div className={"section-kpi-container"}>
			<ChartBarHorizontalAdmin className={"type-etude custom-chart"}
			                         title={"Contrôle qualité"}
			                         legend={
				                         [
					                         {title: "CA Moyen 2022", color: "purple"}, {
					                         title: "Objectif Mensuel", color: "green"
				                         }
				                         ]
			                         }
			                         content={numberFolderWithProblems}
			/>

			<div className={"element-kpi-grid-double-element"}>
				<Card className={"devis-totaux custom-chart"} title={"Dossiers en attente de paiement final"} value={numberProjectWaitingPayment.total}/>
				<Card className={"devis-valide custom-chart"} title={"Dossiers en retard de rendu"}
							value={numberProjectInLate.total}/>
			</div>
			<div className={"element-kpi-grid-double-element"}>
				<Card className={"devis-totaux custom-chart"} title={"Projets en cours"} value={projectInProgress.total}/>
				<Card className={"devis-valide custom-chart"} title={"Dossiers rendu"}
							value={projectValidated.total}/>
			</div>

			<div className={"element-kpi-grid-double-element"}>
				<Card className={"devis-totaux custom-chart"} title={"Avoirs édité"} value={numberAvoir.total}/>
				<Card className={"devis-valide custom-chart"} title={"Projets en recouvrement"}
				      value={numberProjectRecouvrement.total}/>
			</div>



		</div>
	</>)

}

export default KpiAdmin;
