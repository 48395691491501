import * as Yup from 'yup';

export const editMdpUsersSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(12, "Le mot de passe doit avoir une longueur minimale de 12 caractères.")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%?&])[A-Za-z\d@$!#%?&]+$/,
            "Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre, et un caractère spécial (@$!#%?&)"
        )
        .required("Le mot de passe est requis"),
    newPasswordConfirmation: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], "Les mots de passe doivent être identiques")
        .required("La confirmation du mot de passe est requise"),
});