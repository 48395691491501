import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import { ProjectStageService } from '../../../../../services/ProjectStageService';

const PopupDisprovePRI = ({ projectId, showPopup, closeAction }) => {
  
  const navigate = useNavigate();
  const [message, setMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const getMessage = (e) => {
    setMessage(e.target.value)
  }

  const disprovePRI = async () => {
    setIsSubmitting(true)
    if (message !== '') {
      let request = await ProjectStageService.patchPRI(projectId, {
        validation: false,
        reason: message
      });
      if (request.status === 200) {
        toast.success("Votre message a bien été envoyé");
        navigate("/projet/" + projectId, { replace: true });
      }
    }
    setIsSubmitting(false)
  }
  
  return (
    <div className={`project-step-pri__popup pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"pop-up-simple container-s center"}>
        <img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} onClick={closeAction} />
        <img className={"pop-up-simple-img"} src={"/images/medias/popup.svg"} />
        <p className={"text-primary color-primary paragraph_bold"}>
          Nous vous invitons à détailler le refus du principe de structure, pour que l'ingénieur chargé(e) d'affaire puisse mettre à jour le principe de structure pour vous le renvoyer pour validations.
        </p>

        <textarea 
          className={"pop-up-simple-textarea"} 
          placeholder={"Détaillez le refus du principe de structure"} 
          onChange={getMessage}
        />

        <div className={"pop-up-simple-btns"}>
          <button className={"btn-default btn-cancel"} onClick={closeAction}>Annuler</button>
          <button className={"btn-default btn-valid"} onClick={disprovePRI} disabled={
            isSubmitting || message === ''
          }>Envoyer</button>
        </div>
      </div>
    </div>
  )
}

export default PopupDisprovePRI;