import React, { useEffect, useState, useContext } from 'react'
import { ChatbotAdminActionsContext } from "../../ChatbotAdmin";
import { toast } from 'react-toastify';

import { Formik, Form, FieldArray, Field, ErrorMessage } from 'formik';
import { MacroroutineSchema } from './Config/MacroroutineSchema';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import MultiSelect from '../../../../../../components/Atomes/Select/MultiSelect';

import { AdminService } from '../../../../../../services/AdminService';

const PopupAddMacroroutine = ({ closePopup }) => {

  const chatbotContext = useContext(ChatbotAdminActionsContext)

  const chatbotCategories = [
    {
      label: "Définition du profil",
      value: "macro-routine-profile"
    },
    {
      label: "Informations administratives",
      value: "macro-routine-administrative"
    },
    {
      label: "Autre",
      value: "macro-routine-others"
    }
  ];

  const [miniroutinesOptions, setMiniroutinesOptions] = useState([]);

  useEffect(() => {
    getMiniroutinesOptions();
  }, []);

  const getMiniroutinesOptions = async () => {
    let request = await AdminService.get(`/chatbot-mini-routines?pagination=false`);
    if (request.status === 200) {
      let miniroutinesOptions = request.data['hydra:member'].map((miniroutine) => {
        return {
          label: miniroutine.title,
          value: `/api/chatbotMiniRoutines/${miniroutine.id}`
        }
      });
      setMiniroutinesOptions(miniroutinesOptions);
    }
  }

  const submitForm = async (values, setSubmitting) => {
    setSubmitting(true);

    let data = {
      categoryType: values.categoryType,
      title: values.title,
      triggerTitle: values.triggerTitle,
      chatbotMiniRoutinesIri: values.miniroutines.map((miniroutine) => {
        return miniroutine.name;
      })
    }

    let request = await AdminService.post(`/chatbot-macro-routines`, data);
    if (request.status === 201) {
      toast.success('La Macro-Routine a bien été créée !');
      chatbotContext.setChatbotAdminActionsContext({
        "name": "macro-routine"
      });
      closePopup();
    }
    setSubmitting(false);
  }

  return (
    <Formik
      onSubmit={(values, { setSubmitting }) => {
        submitForm(values, setSubmitting);
      }}
      initialValues={{
        categoryType: '',
        title: '',
        triggerTitle: '',
        miniroutines: [{
          id: 1,
          name: ''
        }]
      }}
      validationSchema={MacroroutineSchema}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className='form-row-two-elements'>
            <div className='form-row-label-input'>
              <label htmlFor='categoryType' className={"text-micro paragraph_bold color-dark-grey"}>Catégorie de la Macro-Routine</label>
              <Field
                name={`categoryType`}
                id={`categoryType`}
                placeholder='Catégorie de la Macro-Routine'
                options={chatbotCategories}
                isMulti={false}
                component={MultiSelect}
              />
              <ErrorMessage name={`categoryType`} component={"p"} className={"color-error text-primary"} />
            </div>
            <div className='form-row-element'>
              <InputLabelErrorMessage
                name="title"
                type="text"
                label="Titre de la Macro-Routine"
              />
            </div>
          </div>
          <div className='form-row-element'>
            <InputLabelErrorMessage
              name="triggerTitle"
              type="text"
              label="Réponse qui déclenche la Macro-Routine"
            />
          </div>
          <FieldArray
            name="miniroutines"
            render={arrayHelpers => (
              <div className={`adminChatbot-popup__manyanswers ${values.miniroutines.length > 1 ? 'adminChatbot-popup__manyanswers-true' : ''} `}>
                {
                  values.miniroutines.map((routine, index) => (
                    <span key={index}>
                      <label className={"text-micro paragraph_bold color-dark-grey"}>Mini-Routine n°{index + 1}</label>
                      <div className='form-row-element' >
                        <div className='adminChatbot-popup__manyanswers-arrows'>
                          {
                            values.miniroutines.length > 1 && (
                              <>
                                <button onClick={() => arrayHelpers.move(index, index - 1)} type='button'>
                                  <img src='/images/pictos/arrow-up-white.svg' />
                                </button>
                                <button onClick={() => arrayHelpers.move(index, index + 1)} type='button'>
                                  <img src='/images/pictos/arrow-down-white.svg' />
                                </button>
                              </>
                            )
                          }
                        </div>
                        <div className='form-row-label-input'>
                          <Field
                            name={`miniroutines.${index}.name`}
                            id={`miniroutines.${index}.name`}
                            placeholder='Nom de la Mini-Routine'
                            options={miniroutinesOptions}
                            isMulti={false}
                            component={MultiSelect}
                          />
                        </div>
                        {
                          values.miniroutines.length > 1 && (
                            <img
                              className='adminChatbot-popup__manyanswers-delete'
                              src="/images/pictos/delete-file.svg"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          )
                        }
                      </div>
                      <ErrorMessage name={`miniroutines.${index}.name`} component={"p"} className={"color-error text-primary"} />
                    </span>
                  ))
                }
                <button
                  onClick={() => arrayHelpers.push({ id: values.miniroutines.length + 1, name: '' })}
                  className={"btn-default btn-default-secondary plain"}
                  type='button'>
                  Ajouter une Mini-Routine à la Macro-Routine
                </button>
              </div>
            )}
          />
          <button className={"btn-default btn-default-secondary plain"} disabled={isSubmitting} type='submit'>
            Creér la macro-routine
          </button>
        </Form>
      )}
    </Formik >
  )
}

export default PopupAddMacroroutine;