import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { Formik, Form } from 'formik';
import { InitialValuesArchitectAccount } from './Config/InitialValuesArchitectAccount';
import { SchemaArchitectAccount } from './Config/SchemaArchitectAccount';
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';
import ButtonSubmit from '../../../../../../components/Atomes/Buttons/ButtonSubmit';

import api from "../../../../../../services/APIService";
import axios from 'axios';

const ProfileArchitectAccountForm = ({ accountFormData }) => {

  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };
  const [notValidFileErrorMessage, setNotValidFileErrorMessage] = useState(null);

  function isValidFileType(fileName, fileType) {
    return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
  }

  const changeProfilePicture = (event) => {
    if (!isValidFileType(event.target.files[0].name, 'image')) {
      setNotValidFileErrorMessage('Le format de l\'image n\'est pas valide');
      return;
    }
    if (event.target.files[0].size > 15000000) {
      setNotValidFileErrorMessage('La taille de l\'image ne doit pas dépasser 15Mo');
      return;
    }
    setProfilePicture(event.target.files[0]);
    setProfilePicturePreview(URL.createObjectURL(event.target.files[0]));
  };

  const getUrlAvatar = () => {
    let avatar = localStorage.getItem("avatar").replace(/"/g, "")
    setProfilePicture(avatar);
    setIsLoading(false);
  }

  const setInitialValues = () => {
    InitialValuesArchitectAccount.gender = accountFormData.gender;
    if (accountFormData.gender === "S") {
      InitialValuesArchitectAccount.agencyName = accountFormData.agencyName;
    } else {
      InitialValuesArchitectAccount.agencyName = "";
    }
    InitialValuesArchitectAccount.firstname = accountFormData.firstname;
    InitialValuesArchitectAccount.lastname = accountFormData.lastname;
    InitialValuesArchitectAccount.email = accountFormData.email;
    InitialValuesArchitectAccount.phone = accountFormData.phone;
    InitialValuesArchitectAccount.address = accountFormData.billingAddress.street;
    InitialValuesArchitectAccount.city = accountFormData.billingAddress.city;
    InitialValuesArchitectAccount.postalCode = accountFormData.billingAddress.postalCode;
    InitialValuesArchitectAccount.addressComplement = accountFormData.billingAddress.detail;
  }

  const onSubmit = async (values) => {
    let user = JSON.parse(JSON.parse(localStorage.getItem("user")))
    let userId = localStorage.getItem("userId");
    let data = {
      agencyName: values.agencyName,
      gender: values.gender,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phone: values.phone,
      billingAddress: {
        street: values.address,
        city: values.city,
        postcode: parseInt(values.postalCode),
        detail: values.addressComplement,
      },
      roles: ["ROLE_ARCHITECT"]
    };

    let request = null;
    request = await api.patch(`archs/${userId}`, data)

    if (request.status === 200) {
      if (profilePicturePreview !== null) {
        const file = new FileReader();
        file.readAsDataURL(profilePicture);
        const requestAvatar = axios.create({
          baseURL: process.env.REACT_APP_PROD_API_URL,
          headers: {
            Authorization: `Bearer ${user ? user.token : ""}`,
            'Content-Type': `multipart/form-data`
          }
        })
        const requestAvatarPatch = await requestAvatar.post(`users/${userId}/avatar`, { file: profilePicture })
        if (requestAvatarPatch.status === 201) {
          localStorage.setItem("avatar", requestAvatarPatch.data.urlAvatar);
          setProfilePicture(requestAvatarPatch.data.urlAvatar);
          window.location.reload();
        }
      }
      toast.success("Vos informations ont bien été modifiées");
    } else {
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUrlAvatar();
    setInitialValues();
  }, []);

  return (
    <div className='mbe-profile-container mbe-section'>
      <h3 className='color-primary'>Mes informations personnelles</h3>

      <Formik
        initialValues={InitialValuesArchitectAccount}
        validationSchema={SchemaArchitectAccount}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div className={"form-row-element mbe-profile-form-picture"}>
              <label
                htmlFor='profilePicture'
              >
                {isLoading ?
                  (
                    <Skeleton circle={true} height={120} width={120} />
                  ) : (
                    <img src={profilePicturePreview ? profilePicturePreview : `${process.env.REACT_APP_PROD_URL}/${profilePicture}`} />
                  )
                }
              </label>
              <input type='file' name='profilePicture' id='profilePicture' accept="image/*" onChange={changeProfilePicture} />
              <p className='color-red text-primary'>{notValidFileErrorMessage}</p>
            </div>
            <div className={"form-row-element"}>
              <InputLabelErrorMessage
                keyLabel={"label"}
                label="Civilité"
                keyValue={"value"}
                name="gender"
                type="select"
                data={[
                  { label: "Monsieur", value: "M" },
                  { label: "Madame", value: "W" },
                  { label: "Mademoiselle", value: "Miss" },
                  { label: "Société", value: "S" },
                  { label: "Non-genrée", value: "NA" }
                ]}
              />
            </div>

            {
              values.gender === "S" && (
                <div className={"form-row-element"}>
                  <InputLabelErrorMessage
                    label="Raison sociale"
                    name="agencyName"
                    type="text"
                  />
                </div>
              )
            }

            <div className={"form-row-element form-row-two-elements"}>
              <InputLabelErrorMessage
                label="Nom"
                name="lastname"
                type="text"
              />
              <InputLabelErrorMessage
                label="Prénom"
                name="firstname"
                type="text"
              />
            </div>

            <div className={"form-row-element form-row-two-elements"}>
              <InputLabelErrorMessage
                label="Adresse e-mail"
                name="email"
                type="text"
              />
              <InputLabelErrorMessage
                label="Téléphone"
                name="phone"
                type="text"
              />
            </div>

            <div className={"form-row-element form-row-two-elements"}>
              <InputLabelErrorMessage
                label="Adresse"
                name="address"
                type="text"
              />
              <InputLabelErrorMessage
                label="Ville"
                name="city"
                type="text"
              />
            </div>

            <div className={"form-row-element form-row-two-elements"}>
              <InputLabelErrorMessage
                label="Code postal"
                name="postalCode"
                type="text"
              />
              <InputLabelErrorMessage
                label="Complément d'adresse"
                name="addressComplement"
                type="text"
              />
            </div>

            <ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Enregistrer"} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ProfileArchitectAccountForm;
